import React from "react";

import {
  get5WordsForTitle,
  formatActivityDate,
  getIconByEvent,
} from "../../utils/helpers";

/**
 * @module PastActivityWalkInListViewModule
 */
/**
 * this component is used to display the walk-ins in the past activities
 * @param {object} pastActivitiesData contains information related to the walk-ins
 */
const PastActivityWalkInListView = (props) => {
  const { pastActivitiesData } = props;

  /**
   * this function renders the toggle part of the walk-ins  in past activities
   * @function
   * @inner
   * @memberof module:PastActivityWalkInListViewModule
   * @param {object} item contains information related to the walk-ins
   * @returns {JSX.Element}
   */
  const toggle = (item) => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <div
            className="d-flex align-items-center"
            style={{ marginRight: "2px" }}
          >
            {getIconByEvent(pastActivitiesData.typeTitle)}
            <span style={{ fontSize: "12px", display: "d-flex" }}>
              {pastActivitiesData.typeTitle}:
            </span>
          </div>
          {get5WordsForTitle(item.title)}
        </div>
        <div className="d-flex align-items-center  toggle-date-time">
          {formatActivityDate(item.created_at)}
        </div>
      </div>
    );
  };

  return (
    <div className="notes-card">
      {props.render(toggle(pastActivitiesData))}
    </div>
  );
};

export default PastActivityWalkInListView;