import React from "react";
import * as util from "../../utils/helpers";

/**
 * @module PastActivityEbombsListViewModule
 */
/**
 * this component is used to display the ebombs  in the past activities
 * @param {object} pastActivitiesData contains information related to the ebombs
 */
const PastActivityEbombsListView = (props) => {
  const { pastActivitiesData } = props;

  /**
   * this function renders the toggle part of the ebombs  in past activities
   * @function
   * @inner
   * @memberof module:PastActivityEbombsListViewModule
   * @param {object} item contains information related to the ebombs
   * @returns {JSX.Element}
   */
  const toggle = (item) => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <div
            className="d-flex align-items-center"
            style={{ marginRight: "2px" }}
          >
            {util.getIconByEvent(pastActivitiesData.typeTitle)}
            <span style={{ fontSize: "12px", display: "d-flex" }}>
              {pastActivitiesData.typeTitle}:
            </span>
          </div>
          {util.get5WordsForTitle(item.subject)}
        </div>
        <div className="d-flex align-items-center  toggle-date-time">
          {util.formatActivityDate(item.datetime)}
        </div>
      </div>
    );
  };

  /**
   * this function renders the collapse part of the ebombs  in past activities
   * @function
   * @inner
   * @memberof module:PastActivityEbombsListViewModule
   * @param {object} item contains information related to the ebombs
   * @returns {JSX.Element}
   */
  const collapse = (item) => {
    return (
      <div className="accordion-body-inner">
        SUBJECT: {item.subject}
        ADDRESS: {item.email_addresses.toString()}
        FROM: {item.email_from}
        DATE: {util.formatActivityDate(item.datetime)}
        <hr className="light" />
        CONTENT URL:
        <a href={item.content_url} target="_blank" rel="noopener noreferrer">
          {item.content_url}
        </a>
      </div>
    );
  };
  return (
    <div className="notes-card">
      {props.render(
        toggle(pastActivitiesData.email),
        collapse(pastActivitiesData.email)
      )}
    </div>
  );
};
export default PastActivityEbombsListView;
