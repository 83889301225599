import gql from 'graphql-tag'

const FETCH_SMS_ATTACHMENT = gql`
  query getSmsAttachment($session_id: ID!, $message_id: ID!) {
    getSmsAttachment(session_id: $session_id, message_id: $message_id) {
      message
      code
      data
    }
  }
`;

export default FETCH_SMS_ATTACHMENT