import React, { useContext } from "react";
import { compose, graphql } from "react-apollo";
import DashboardContextProvider from "../../context/dashboard-context-provider";
import { GET_FORM_SUBMISSION_COUNT } from "../../graphql/queries";
import WebFormTable from "./webform-container";
import * as ids from './customer-types'
import { Auth } from "../../rbac/rbac";
import { GET_PREVIOUS_CARD } from "../../cacheql/queries";

/**
 * Array of cards that are rendered on the webform dashboard page. Each card contains title of the card which is to 
 * be shown on the card, filter, customPath which is used to access the count,
 */
const activityCards = [
  {
    title: "New",
    description:
      "Total # of new web form submissions that do not match any records in pulse",
    filter: { customer_type_id: ids.NEW_TYPE_ID, unresponded: true },
    customPath: "getFormSubmissionCount",
    secondaryDataQuery: {
      filter: { customer_type_id: ids.NEW_TYPE_ID, unconverted: true },
      dataQuery: GET_FORM_SUBMISSION_COUNT,
      name: 'getNewUnconvertedFormSubmissions',
      customPath: "getFormSubmissionCount"
    }
  },
  {
    title: "Leads",
    description:
      "Total # of new web form submissions that match an existing lead record in pulse",
    filter: { customer_type_id: ids.EXISTING_LEAD_TYPE_ID, unresponded: true },
    customPath: "getFormSubmissionCount",
    secondaryDataQuery: {
      filter: { customer_type_id: ids.EXISTING_LEAD_TYPE_ID, unconverted: true },
      dataQuery: GET_FORM_SUBMISSION_COUNT,
      name: 'getLeadUnconvertedFormSubmissions',
      customPath: "getFormSubmissionCount"
    }
  },
  {
    title: "Prospects",
    description:
      "Total # of new web form submissions that match an existing prospect record in pulse",
    filter: { customer_type_id: ids.EXISTING_PROSPECT_TYPE_ID, unresponded: true },
    customPath: "getFormSubmissionCount",
    secondaryDataQuery: {
      filter: { customer_type_id: ids.EXISTING_PROSPECT_TYPE_ID, unconverted: true },
      dataQuery: GET_FORM_SUBMISSION_COUNT,
      name: 'getProspectUnconvertedFormSubmissions',
      customPath: "getFormSubmissionCount"
    }
  },
  {
    title: "Homeowners",
    description:
      "Total # of new web form submissions that match an existing homeowner record in pulse",
    filter: { customer_type_id: ids.HOMEOWNER_TYPE_ID, unresponded: true },
    customPath: "getFormSubmissionCount",
    secondaryDataQuery: {
      filter: { customer_type_id: ids.HOMEOWNER_TYPE_ID, unconverted: true },
      dataQuery: GET_FORM_SUBMISSION_COUNT,
      name: 'getHomeownerUnconvertedFormSubmissions',
      customPath: "getFormSubmissionCount"
    }
  },
  {
    title: "Archived",
    description:
      "Total # of new web form submissions that match an deleted record in pulse",
    filter: { customer_type_id: ids.DELETED_TYPE_ID, unresponded: true },
    customPath: "getFormSubmissionCount",
    secondaryDataQuery: {
      filter: { customer_type_id: ids.DELETED_TYPE_ID, unconverted: true },
      dataQuery: GET_FORM_SUBMISSION_COUNT,
      name: 'getArchivedUnconvertedFormSubmissions',
      customPath: "getFormSubmissionCount"
    }
  }
];

/**
 * This component is used to render webform dashboard with 5 cards and a table. This dashboard contains division and communities dropdown,communities dropdown for all the rols and the division just for 
 * manager role only.
 * @param {*} props 
 */
const WebFormDashboard = props => {
  const { is_manager, has_multiple_divisions } = useContext(Auth);

  const getDefaultCard = () => {
    const cardTitle = props.getPreviousCard.previousCard.title;
    const index = activityCards.findIndex(item => item.title === cardTitle);
    if (index === -1) {
      return activityCards[0]
    }
    return activityCards[index];
  };
  return (
    <DashboardContextProvider
      type="Web Forms"
      text="New Web Forms"
      startingDefaultCard={getDefaultCard()}
      dataQuery={GET_FORM_SUBMISSION_COUNT}
      activityCards={activityCards}
      tableView={<WebFormTable />}
      withCommunity
      withDivision={is_manager() || has_multiple_divisions()}
      {...props}
    />
  );
};

export default compose(
  graphql(GET_PREVIOUS_CARD, { name: "getPreviousCard" })
)(WebFormDashboard);
