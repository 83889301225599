import React, { useState, useEffect } from "react";
import Drawer from "../../../hoc/Drawer";
import ListPopup from "../../../components/list-popup/list-popup";
import CustomButton from "../../../components/custom-button";
import EditProspect from "../dashboard/edit-prospect";
import HistoryButton from "../../common-lead-prospect-details/history-button";
import DetailsSectionTable from "../../../components/table/custom-details-table";
import AddCommunityContainer from "../forms/add-community-container";
import DetailsLastSectionTable from "../../../components/table/custom-details-table/details-last-section";
import SubscriptionHistoryContainer from "../../common-lead-prospect-details/subscription-history-button";
import { FaPlus } from "react-icons/fa";
import { Card, Col } from "react-bootstrap";
import { ClickableIcon } from "../../admin/email-associations";
import {
  formatDate,
  checkDisabledEmailsLink,
  phoneLink,
  inActiveMapper,
  InactivityIcon,
  handleSecondaryEmails,
  getAllEmails
} from "../../../utils/helpers";
import "./prospect-details.css";

/**
 * This component is used to render card details section on the right side of the page. It divides the details card into
 * four sections. Buyer 1, buyer 2, general, and other optional fields. It also contains 3 buttons to view history
 * and subscription history of the prospect and also the edit button. 
 * @param {Object} props 
 * @param {Object} prospect prospect info 
 */
const ProspectDetail = ({ data: prospect, ...props }) => {
  const [showSideBar, setShowSideBar] = useState(false);
  const [showBuyer2, setShowBuyer2] = useState(true);
  const [showAddCommunity, setShowAddCommunity] = useState(false)

  const [buyer1, setBuyer1] = useState([]);
  const [buyer2, setBuyer2] = useState([]);
  const [general, setGeneral] = useState([]);
  const [realtor, setRealtor] = useState([]);
  const [optional, setOptional] = useState([]);
  const [lastField, setLastField] = useState([]);
  const [stepsAndNotes, setStepsAndNotes] = useState([]);

  useEffect(() => {
    if (prospect) {
      setBuyer1({
        "First Name": prospect.first_name_1,
        "Last Name": prospect.last_name_1,
        "Primary Email": checkDisabledEmailsLink(prospect, "primary_email_1", getAllEmails, "primary_email_1_status"),
        "Secondary Email(s)":
          prospect.secondary_emails_1.length
            ? handleSecondaryEmails(prospect, "secondary_emails_1", getAllEmails)
            : null,
        "Cell#": phoneLink(prospect, "cell_phone_1")
      });
      setBuyer2({
        "First Name": prospect.first_name_2,
        "Last Name": prospect.last_name_2,
        "Primary Email": checkDisabledEmailsLink(prospect, "primary_email_2", getAllEmails, "primary_email_2_status"),
        "Secondary Email(s)":
          prospect.secondary_emails_1.length
            ? handleSecondaryEmails(prospect, "secondary_emails_2", getAllEmails)
            : null,
        "Cell#": phoneLink(prospect, "cell_phone_2")
      });
      setGeneral({
        "Registration Date": formatDate(prospect.created_at),
        "Address": prospect.street_address,
        "City": prospect.city,
        "State": prospect.state?.name ?? null,
        "Zip": prospect.zip,
        "Prospect Source": prospect.customer_origination_area?.name ?? null,
        "Division": prospect.division?.name,
        "OSC Notes": prospect.osc_notes
      });
      setRealtor({
        "Realtor Name": prospect.realtor && `${prospect.realtor.first_name} ${(prospect.realtor?.last_name ?? "")}`,
        "Realtor Email": prospect.realtor && checkDisabledEmailsLink(prospect.realtor, "primary_email", null, "primary_email_status"),
        "Realtor Cell#": prospect.realtor && phoneLink(prospect.realtor, "cell_phone")
      })
      setOptional({
        "Community(ies)": handleCommunities(prospect),
        "CSM Name": prospect.csm
          ? InactivityIcon(
            prospect.csm,
            `${prospect.csm.first_name} ${prospect.csm.last_name}`,
            null,
            12
          )
          : null,
        "Home Sites":
          prospect.home_sites.length
            ? handlePopUpFields(prospect.home_sites)
            : null,
        "Floor Plans":
          prospect.floor_plans.length
            ? handlePopUpFields(prospect.floor_plans)
            : null,
        Others:
          prospect.others.length
            ? handlePopUpFields(prospect.others)
            : null,
        Ranking: prospect.rank?.name ?? null,

      });

      setLastField({
        "Motivation/What Changed?": {
          isMwc: true,
          note: prospect.motivation_what_changed,
          questions: [
            {
              question: "Motivation Uncovered: Is the life change clear and recent?",
              value: prospect.motivation_uncovered
            },
          ]
        },
        "Current Dissatisfaction": {
          note: prospect.current_dissatisfaction,
        },
        "Future Promise": {
          note: prospect.future_promise,
        },
        "Cost + Fear/Objections": {
          isInverse: true,
          note: prospect.cost_feat_objections,
        }
      });
      setStepsAndNotes({
        "Next Steps": prospect.next_step,
        "Other Notes": prospect.other_notes
      })
    }
    if (prospect?.first_name_2 ||
      prospect?.last_name_2 ||
      prospect?.primary_email_2 ||
      prospect?.cell_phone_2
    ) {
      setShowBuyer2(true);
    } else {
      setShowBuyer2(false);
    }
  }, [prospect, props.Realtor]);

  const handleCommunities = data => {
    return (
      <div className='d-flex'>
        <ListPopup
          data={data.all_communities}
          community={data.community}
          mapper={inActiveMapper}
        />
        <ClickableIcon
          tooltip='Add Community(s)'
          id="add-community-icn"
          className="custom-add-btn"
          icon={FaPlus}
          callback={() => {
            setShowAddCommunity(!showSideBar);
          }}
        />
      </div>
    );
  };

  const handlePopUpFields = data => {
    return (
      <ListPopup
        data={data}
        mapper={field =>
          field.map((item, index) => <div key={index}>{item.name}</div>)
        }
      />
    );
  };

  return (
    <Card className="details-card pt-2" as={Col}>
      <Card.Body style={{ padding: "0.5rem", overflow: "hidden" }}>
        <div className="d-flex justify-content-between flex-wrap">
          <h3>Prospect Details</h3>
          <div className="d-flex justify-content-center align-content-center">
            <SubscriptionHistoryContainer
              name={
                prospect
                  ? `${prospect.first_name_1} ${prospect.last_name_1}`
                  : ""
              }
              customer_id={prospect?.customer_id ?? null}
              primaryEmail1={prospect?.primary_email_1 ?? null}
              primaryEmail2={prospect?.primary_email_2 ?? null}
            />
            <HistoryButton
              name={
                prospect
                  ? `${prospect.first_name_1} ${prospect.last_name_1}`
                  : ""
              }
              id={prospect?.customer_id ?? null}
            />
            <div className="edit-button">
              <CustomButton
                size="sm"
                color="green"
                btnValue="Edit"
                onClick={() => setShowSideBar(true)}
              />
            </div>
          </div>

          {showSideBar && (
            <EditProspect
              {...props}
              submitHandler={() => {
                setShowSideBar(false);
                props.onSubmit();
              }}
              close={() => setShowSideBar(false)}
              isDetails
            />
          )}
          {showAddCommunity && (
            <Drawer
              show={showAddCommunity}
              toggleSideBar={() => {
                setShowAddCommunity(!showAddCommunity);
              }}
            >
              <AddCommunityContainer
                prospectData={prospect}
                onSubmit={() => {
                  setShowAddCommunity(false);
                  props.onSubmit();
                }}
                toggleSideBar={setShowAddCommunity}
              />
            </Drawer>
          )}
        </div>
        <div className="details-side-responsive">
          <div className="details-side-section1">
            <DetailsSectionTable data={buyer1} tableHeader="Buyer#1" />
            {showBuyer2 && (
              <DetailsSectionTable data={buyer2} tableHeader="Buyer#2" />
            )}
          </div>
          <div className="details-side-section3">
            <DetailsSectionTable data={general} />
          </div>
          <div className="details-side-section4 mt-2">
            <DetailsSectionTable data={optional} />
          </div>
          {prospect?.realtor &&
            (<div className="details-side-section3">
              <DetailsSectionTable data={realtor} tableHeader="Realtor" />
            </div>
            )}
          <div className="details-side-section2 mt-2">
            <DetailsLastSectionTable data={lastField} />
          </div>
          <div className="details-side-section4 mt-2">
            <DetailsSectionTable data={stepsAndNotes} />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
export default ProspectDetail
