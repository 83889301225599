import gql from "graphql-tag";

export const ADD_OTHERS_VALUES = gql`
  mutation addOtherValues($input: AddOtherDropdownValueInput!) {
    addOtherDropdownValue(input: $input) {
      code
      success
      message
    }
  }
`;

export const UPDATE_OTHERS_AVAILABLE = gql`
  mutation makeOtherValuesAvailable(
    $input: MakeOtherDropdownValueAvailableInput!
  ) {
    makeOtherDropdownValuesAvailable(input: $input) {
      code
      success
      message
    }
  }
`;

export const UPDATE_OTHERS_UNAVAILABLE = gql`
  mutation makeOtherValuesUnavailable(
    $input: MakeOtherDropdownValueAvailableInput!
  ) {
    makeOtherDropdownValuesUnavailable(input: $input) {
      code
      success
      message
    }
  }
`;
