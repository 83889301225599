import gql from "graphql-tag";

const GET_LOGGED_IN_COMMUNITIES = gql`
  query getLoggedInUserCommunities($division_ids: [Int]) {
    getLoggedInUserCommunities(division_ids: $division_ids) {
      id
      name
      is_active
      division_id
    }
  }
`;

export default GET_LOGGED_IN_COMMUNITIES;