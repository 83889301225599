
import gql from 'graphql-tag';

const MARK_PROSPECT_FOLLOW_UP_DONE = gql` 
mutation markDoneProspectFollowUp($input: ProspectFollowUpMarkDoneInput!) {
  markDoneProspectFollowUp(input: $input){
      message
      code
    }
  }
`;

export default MARK_PROSPECT_FOLLOW_UP_DONE;