import gql from "graphql-tag";

const GET_HOMEOWNER_FILTER_BYID = gql`
  query getHomeownerFilters($id: ID) {
    getHomeownerFilters(id: $id) {
      id
      filter_name
      settled_start_date
      settled_end_date
      purchase_start_date
      purchase_end_date
      brand_ambassador
      salesperson_id
    }
  }
`;

export default GET_HOMEOWNER_FILTER_BYID;
