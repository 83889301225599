import React, { useState, useEffect } from "react";
import { compose, graphql } from "react-apollo";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { isNotNULL } from "../../utils/helpers";
import { UPDATE_WEBFORM_TYPE_AUTO_CONVERT } from "../../graphql/mutations";
import OtherCustomizationCard from "../../components/customziation/other-customization-card";
import CustomizationOptionBox from "../../components/customziation/customizations-option-box";
import CustomButton from "../../components/custom-button";

/**
 * This component is used to render manage auto convert section in the Web Form Reply Email Associations section where you can set webform auto conversion into either
 * prospect, lead or marketing only by moving the webform type into the relevant tab. Webform types with community can only be converted into a prospect and similarly webform types 
 * without community can only be converted into leads and marketing only (as prospect cannot be created without community being specified)
 */
const AutoConvertSelector = ({ convertType, ...props }) => {
  const [selectedFormTypes, setSelectedFormTypes] = useState([]);
  const [selectedCustomerTypes, setSelectedCustomerTypes] = useState([]);

  /**
   * This function is used to check whether selected item in the list does have community of not
   * @param {String} id 
   */
  const checkIsWithCommunity = (id) => {
    if (isNotNULL(id))
      return id.split("_")[1] === "withCommunity" ? true : false
  }

  /**
   * This object contains a list of fields to carry out relevant functionality in the {@link handleUpdate} function
   */
  const defaultField = {
    webform_to_lead: {
      mutation: "updateAutoConvert",
      resMutation: "updateWebFormTypeAutoConvert",
      selectedId: selectedFormTypes,
      customer_type: "lead",
      callback: setSelectedFormTypes
    },
    lead_to_webform: {
      mutation: "updateAutoConvert",
      resMutation: "updateWebFormTypeAutoConvert",
      selectedId: selectedCustomerTypes,
      customer_type: null,
      callback: setSelectedCustomerTypes
    },
    webform_to_prospect: {
      mutation: "updateAutoConvert",
      resMutation: "updateWebFormTypeAutoConvert",
      selectedId: selectedFormTypes,
      customer_type: "prospect",
      callback: setSelectedFormTypes
    },
    prospect_to_webform: {
      mutation: "updateAutoConvert",
      resMutation: "updateWebFormTypeAutoConvert",
      selectedId: selectedCustomerTypes,
      customer_type: null,
      callback: setSelectedCustomerTypes
    },
    webform_to_marketing_only: {
      mutation: "updateAutoConvert",
      resMutation: "updateWebFormTypeAutoConvert",
      selectedId: selectedFormTypes,
      customer_type: "marketing_only",
      callback: setSelectedFormTypes
    },
    marketing_only_to_webform: {
      mutation: "updateAutoConvert",
      resMutation: "updateWebFormTypeAutoConvert",
      selectedId: selectedCustomerTypes,
      customer_type: null,
      callback: setSelectedCustomerTypes
    }
  }

  /**
   * This function is used to handle click on the option windows. A single click will add the clicked item into the relevant selected list.
   * Whereas a double click will call the hanldeUpdate function.  
   * @param {Object} val Value selected on click
   * @param {Object} doubleClick A Boolean check to confirm if the click was a double click or not
   * @param {Object} callback The callback function to handle the data
   * @param {Object} field An instance of defaultField object containing mutation, response mutation, 
   * selected types and the callback function to handle the data
   */
  const handleSelection = (val, doubleClick, callback, field) => {
    callback(val)
    if (doubleClick) {
      handleUpdate(field)
    }
  }

  /**
   * This function takes the selected items and sends a mutation to update the server with the selected types. If response is a success then a success message received from the mutation response is shown
   * @param {Object} field An instance of defaultField object obtaining the mutation to run, response mutation, selected types of webform, customer type based on the request
   * and the callback function to handle the data
   */
  const handleUpdate = (field) => {
    if (!isNotNULL(field.selectedId) && field.selectedId !== 0) return

    const id = field.selectedId[0]

    props[field.mutation]({
      variables: {
        web_form_type_id: parseInt(id.split("_")[0]),
        community_status: checkIsWithCommunity(id),
        customer_type: field.customer_type
      }
    }).then((res) => {
      if (res.data[field.resMutation].code === 200) {
        field.callback([])
        props.update(res.data[field.resMutation].message)
      }
    })
  }

  useEffect(() => {
    setSelectedCustomerTypes([])
  }, [convertType])


  return (
    <OtherCustomizationCard
      firstCardTitle="Webform Types"
      firstCardView={
        <CustomizationOptionBox
          singleSelect={true}
          selected={selectedFormTypes}
          list={props.webFormList}
          handleSelection={(val, e) => handleSelection(val, e, setSelectedFormTypes, defaultField[`webform_to_${convertType}`])}
        />
      }
      moveToFirstCardView={
        <CustomButton
          color="black"
          btnIcon={<FaChevronLeft />}
          className="mb-1 border-0 custom-btn-arrows arrow-up"
          onClick={() => handleUpdate(defaultField[`${convertType}_to_webform`])}
          disabled={!selectedCustomerTypes.length}
        />
      }
      moveToSecondCardView={
        <CustomButton
          color="black"
          btnIcon={<FaChevronRight />}
          className="border-0 custom-btn-arrows arrow-down"
          onClick={() => handleUpdate(defaultField[`webform_to_${convertType}`])}
          disabled={
            selectedFormTypes.length > 0 && convertType
              ? convertType === "prospect"
                ? !checkIsWithCommunity(selectedFormTypes[0])
                : false
              : true
          }
        />
      }
      secondCardTitle={props.secondaryTitle}
      secondCardView={
        <CustomizationOptionBox
          singleSelect={true}
          selected={selectedCustomerTypes}
          list={props.secondaryList}
          handleSelection={(val, e) => handleSelection(val, e, setSelectedCustomerTypes, defaultField[`${convertType}_to_webform`])}
        />
      }
    />
  )
}

export default compose(
  graphql(UPDATE_WEBFORM_TYPE_AUTO_CONVERT, { name: "updateAutoConvert" })
)(AutoConvertSelector);