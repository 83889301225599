import { graphql, compose } from "react-apollo";
import { UpsertFlagForm } from "./upsert-flag-form";
import { UPSERT_RED_FLAG, DELETE_RED_FLAG } from "../../../graphql/mutations";
import { SET_NOTIFICATION_PROPS } from "../../../cacheql/mutations";

const AddUpdateFlagForm = compose(
  graphql(UPSERT_RED_FLAG, { name: "upsertRedFlag" }),
  graphql(DELETE_RED_FLAG, { name: "deleteRedFlag" }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" })
)(UpsertFlagForm);

export default AddUpdateFlagForm;
