import React, { useContext } from "react";
import { Auth } from "../../../rbac/rbac";
import { compose, graphql } from "react-apollo";
import { FaUser } from "react-icons/fa";
import { GET_PREVIOUS_CARD } from "../../../cacheql/queries";
import { getCreateDateFilters } from "../../../utils/helpers";
import { NewRealtorRegistrationsTable } from "../realtor-registrations/new-realtor-registrations-table";
import { GET_REALTOR_REGISTRATION_COUNT, GET_ALL_REALTOR_REGISTRATIONS_COUNT } from "../../../graphql/queries"
import DashboardContextProvider from "../../../context/dashboard-context-provider";
import AllRegistrationsTable from "../realtor-registrations/all-realtor-registrations-container";
import { CreateEditRealtor } from "../realtor-form-container";

const activityCards = [
  {
    title: "New",
    uniqueTitle: "New Realtor Registrations",
    description:
      "This table shows all new registrations without a client of a Realtor",
    isSubscriptionTab: true,
    customTable: <NewRealtorRegistrationsTable />,
    customDataQuery: GET_REALTOR_REGISTRATION_COUNT,
    customPath: 'getRealtorRegistrationCount'
  },
  {
    title: "YTD",
    uniqueTitle: "YTD Realtor Registrations",
    description:
      "This table shows all realtors registered successfully without a client (YTD)",
    customDataQuery: GET_ALL_REALTOR_REGISTRATIONS_COUNT,
    filter: {
      ...getCreateDateFilters("year"),
      expired: false
    },
    customPath: 'getAllRealtorRegistrations.total_count',
    customTable: <AllRegistrationsTable />
  },
  {
    title: "All",
    uniqueTitle: "All Realtor Registrations",
    description:
      "This table shows all realtors registered successfully without a client",
    customDataQuery: GET_ALL_REALTOR_REGISTRATIONS_COUNT,
    customPath: 'getAllRealtorRegistrations.total_count',
    customTable: <AllRegistrationsTable />
  }
];

/**
 * This component is used to render realtor dashboard with 3 cards and a table. 2 of the cards render
 * the same table whereas the "New Realtor Registrations" renders a different table.
 * Dropdown and Division Dropdown is there for manager role only. 
 * By default New Realtor Registrations card is preselected.
 * @param {*} props 
 */
const RealtorsRegistrationDashboardView = props => {
  const { is_manager, has_multiple_divisions } = useContext(Auth);

  const getDefaultCard = () => {
    const cardTitle = props.getPreviousCard.previousCard.title;
    const index = activityCards.findIndex(item => item.title === cardTitle);
    if (index === -1) {
      return activityCards[0];
    }
    return activityCards[index];
  }

  return (
    <DashboardContextProvider
      type="Realtor Registrations"
      pageTitle={is_manager() ? null : "Dashboard"}
      startingDefaultCard={getDefaultCard()}
      activityCards={activityCards}
      withCommunity={false}
      text="New Realtor"
      formContent={CreateEditRealtor}
      icon={FaUser}
      withDivision={is_manager() || has_multiple_divisions()}
      {...props}
    />
  );
};

export default compose(
  graphql(GET_PREVIOUS_CARD, { name: "getPreviousCard" })
)(RealtorsRegistrationDashboardView);
