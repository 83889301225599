import React, { useState, useEffect } from "react";
import { compose, graphql } from "react-apollo";
import RichTextEditor from "../../components/rich-text-editor/rich-text-editor";
import {
  GET_EMAIL_TEMPLATE,
  GET_EMAIL_TEMPLATES_TYPES
} from "../../graphql/queries";
import {
  UPDATE_EMAIL_TEMPLATE,
  SEND_TEST_EMAIL
} from "../../graphql/mutations";
import { SET_NOTIFICATION_PROPS } from "../../cacheql/mutations";
import { SelectBar } from "../../containers";
import SelectionListPopup from "../../components/list-popup/selection-list-popup";
import { isNotNULL } from "../../utils/helpers";
import { goBack } from "../../utils/helpers";

import { ListGroup } from "react-bootstrap";
import OverlayWrapper from "../../components/custom-page-overlay/overlay-wrapper";
import ic_emailTemplate from "../../images/ic_email_template.svg";
import ContentGrid from "../../components/grid/content-grid";
import CustomButton from "../../components/custom-button";
import { FaChevronLeft } from "react-icons/fa";
import { DefaultQuery } from "../../graphql/default-query";

/**
 * this component renders the email templates page. it contains email templates dropdown where u can choose a template, it has also preview and test email buttons, where u can preview the email and test it by sending it to desired email address.
 * this component also contains 2 main section, on the left side the parts of the emails, and on the right side is where you edit the template as you like.
 * @param {*} object contains all the important informations related to email templates including the queries to get the templates and mutation to update them 
 */
const TemplateEdit = ({
  emailTemplate,
  emailTemplates,
  updateEmailTemplate,
  sendTestEmail,
  ...props
}) => {
  const [templateTypeID, setTemplateTypeID] = useState(null);
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    return () => {
      //unmounting
      document.body.classList.remove("email-templates");
    };
  }, []);

  useEffect(() => {
    if (isNotNULL(emailTemplates) && emailTemplates.getEmailTemplateTypes) {
      setTemplates(emailTemplates.getEmailTemplateTypes);
      document.body.classList.add("email-templates");
    }
  }, [emailTemplates]);
  const handleTemplateItemSelection = item => {
    const id = item.id;
    setTemplateTypeID(id);
  };

  const handleUpdate = (bodyContent, subject, to) => {
    if (!isNotNULL(subject)) {
      notify("Subject cannot be empty.");
      return;
    }
    if (to) {
      sendTestEmail({
        variables: {
          input: {
            to: to,
            body: bodyContent,
            subject: subject
          }
        }
      }).then(res => {
        notify(res.data.sendTestEmail.message);
      });
    } else {
      updateEmailTemplate({
        variables: {
          id: templateTypeID,
          input: {
            body: bodyContent,
            subject: subject
          }
        }
      }).then(res => {
        if (res.data.updateEmailTemplate.code === 200) {
          notify(res.data.updateEmailTemplate.message);
        }
      });
    }
  };

  const notify = message => {
    if (message) {
      props.setNotificationProps({
        variables: { open: true, message: message }
      });
    }
  };

  const mapCategories = (list) => {
    let webform = []
    let non_webform = []
    list && list.map(item => {
      item.is_web_form_template ? webform.push(item) : non_webform.push(item)
    })

    return [
      ...webform.length ? [{label: "Webform Templates", list: webform}] : [],
      ...non_webform.length ? [{label: "Other Templates", list: non_webform}] : []
    ]
  }

  return (
    <ContentGrid
      id="email-templates"
      classes="test"
      selectbar={
        <SelectBar
          id="email-templates-selectbar"
          style={{ justifyContent: "unset" }}
        >
          <CustomButton
            onClick={() => {
              goBack(props.history);
            }}
            id="back-to-dashboard-btn"
            color="dark"
            btnIcon={<FaChevronLeft />}
            className="btn-back"
          />
          <SelectionListPopup
            title="Email Templates"
            label="Select a Template"
            list={templates}
            optionsMapper={mapCategories}
            mapper={field => {
              return (
                isNotNULL(field) &&
                field.map((item, key) => (
                  <ListGroup.Item
                    className="list-item"
                    onClick={() => handleTemplateItemSelection(item)}
                    key={key}
                  >
                    {item.name}
                  </ListGroup.Item>
                ))
              );
            }}
          />
        </SelectBar>
      }
      content={{
        data: (
          <div className="position-relative overlay-emailtemplate template-editor ">
            <OverlayWrapper
              background
              height="100%"
              Image={ic_emailTemplate}
              message={`Select a template from "Email Templates" list above.`}
              show={templateTypeID || false}
              component={
                <DefaultQuery
                  query={GET_EMAIL_TEMPLATE}
                  skip={templateTypeID ? false : true}
                  variables={{
                    id: templateTypeID
                  }}
                  fetchPolicy="cache-and-network"
                >
                  {({ data, loading, error }) => {
                    return (
                      <RichTextEditor
                        loading={templateTypeID && loading}
                        baseHtmlContent={
                          isNotNULL(data) && data.getEmailTemplate
                            ? data.getEmailTemplate
                            : null
                        }
                        save={handleUpdate}
                      />
                    );
                  }}
                </DefaultQuery>
              }
            />
          </div>
        )
      }}
    />
  );
};

export default compose(
  graphql(GET_EMAIL_TEMPLATES_TYPES, {
    name: "emailTemplates"
  }),
  graphql(SEND_TEST_EMAIL, { name: "sendTestEmail" }),
  graphql(UPDATE_EMAIL_TEMPLATE, { name: "updateEmailTemplate" }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" })
)(TemplateEdit);
