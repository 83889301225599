import React from "react";
import PropTypes from "prop-types";
import DatePicker from "react-date-picker";

import "./fields.css";

/**
 * This field uses react date-picker, which opens calendar in a popup from which a date can be selected.
 * @param {Object} props 
 * @param {Date} props.value value of the date
 * @param {Date} props.minDate restricting to select date earlier than a specific date
 * @param {Date} props.maxDate restricting to select date after a specific date
 * @param {Boolean} props.disabled to disable date selection
 */
const DatePickerView = props => {
  const {
    id,
    value,
    label,
    minDate,
    maxDate,
    required,
    field,
    disabled,
    form: { touched, errors, setFieldValue },
    ...rest
  } = props;

  const error = touched[field.name] && errors[field.name];
  return (
    <div id={`${id}-form-group`} className="form-group">
      <label htmlFor={id + "-input"} className="control-label">
        {label}
      </label>
      <div className={required ? "date-required" : "date"}>
        <DatePicker
          onChange={e => {
            setFieldValue(field.name, e);
          }}
          disabled={disabled}
          calendarIcon={null}
          clearIcon={null}
          value={field.value && new Date(field.value)}
          minDate={minDate}
          maxDate={maxDate}
          format="MM/dd/y"
          onKeyDown={e => e.preventDefault()}
          {...rest}
        />
      </div>
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

DatePickerView.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

export default DatePickerView;
