import React, { useContext } from "react";
import { Form, Field, withFormik } from "formik";
import SelectFieldNonInt from "../../components/fields/select-field-non-int";
import TextField from "../../components/fields/text-field";
import DatePickerView from "../../components/fields/date-picker";
import {
  realtorFilterSchema,
  realtorFilterForm
} from "./realtor-filter-schema";
import CustomButton from "../../components/custom-button";
import { isNotNULL, sanitizeFilterObject, isEmpty, hasValue, getCreateDateFilters } from "../../utils/helpers";
import { DashboardContext } from "../../context";
import moment from "moment";

/**
 * This component is used to render all realtors, YOY sales and registrations cards table filter form. the fields are different for each card. Apply filter button remains disabled unless some
 * value on filter fields are input. On submit setFilter is called, which triggers table refetch. 
 * @param {Object} props 
 */
const RealtorForm = props => {
  const { currentCard } = useContext(DashboardContext);
  const { values } = props;

  const enableFormSubmit = ({ filter_name: _, ...valueCompareFrom }) => {
    const filters = sanitizeFilterObject(Object.assign({}, valueCompareFrom));
    return isEmpty(filters);
  };

  return (
    <Form className="filter-menu">
      <div className="row">
        <div className="col-md-12">
          <Field
            label="Agency Name"
            name="agency_name"
            id="agency_name"
            type="text"
            component={TextField}
            className="form-control"
          />
        </div>
      </div>
      {currentCard.title === "All Realtors" && (
        <>
          <div className="row">
            <div className="col-md-6">
              <Field
                label="Is listed agent?"
                name="is_listing_agent_of_sb"
                id="is_listing_agent_of_sb"
                classStyle="filter-select-field"
                component={SelectFieldNonInt}
              >
                <option key="0" value={""}>
                  Select...
                </option>
                <option key="1" value={"true"}>
                  Yes
                </option>
                <option key="2" value={"false"}>
                  No
                </option>
              </Field>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Field
                label="Has client registrations"
                name="has_client_reg"
                id="has_client_reg"
                classStyle="filter-select-field"
                component={SelectFieldNonInt}
              >
                <option key="0" value={""}>
                  Select...
                </option>
                <option key="1" value={"true"}>
                  Yes
                </option>
                <option key="2" value={"false"}>
                  No
                </option>
              </Field>
            </div>
          </div>
        </>
      )}
      {["YOY Registrations", "YOY Sales"].includes(currentCard.title) && (
        <div className="row">
          <div className="col-md-6">
            <Field
              label={`${currentCard.title === "YOY Registrations" ? 'Registered' : 'Sale'} From`}
              name={`${currentCard.title === "YOY Registrations" ? 'register' : 'sale'}_start_date`}
              id={`${currentCard.title === "YOY Registrations" ? 'register' : 'sale'}_start_date`}
              minDate={new Date(getCreateDateFilters('trailing_year').create_start_date)}
              maxDate={new Date(new Date().getFullYear(), 11, 31)}
              component={DatePickerView}
            />
          </div>
          <div className="col-md-6">
            <Field
              label={`${currentCard.title === "YOY Registrations" ? 'Registered' : 'Sale'} To`}
              name={`${currentCard.title === "YOY Registrations" ? 'register' : 'sale'}_end_date`}
              id={`${currentCard.title === "YOY Registrations" ? 'register' : 'sale'}_end_date`}
              minDate={new Date(getCreateDateFilters('trailing_year').create_start_date)}
              maxDate={new Date(new Date().getFullYear(), 11, 31)}
              component={DatePickerView}
            />
          </div>
        </div>
      )}
      <CustomButton
        type="submit"
        btnValue="APPLY FILTERS"
        className="filter-apply-btn"
        disabled={enableFormSubmit(values)}
      />
    </Form>
  );
};

const setInput = ({ is_listing_agent_of_sb, has_client_reg, sale_start_date, sale_end_date, ...rest }) => {
  let returnInput = {
    is_listing_agent_of_sb: hasValue(is_listing_agent_of_sb) ?
      is_listing_agent_of_sb === true || is_listing_agent_of_sb === "true" ?
        true : false
      : null,
    has_client_reg: hasValue(has_client_reg) ?
      has_client_reg === true || has_client_reg === "true" ?
        true : false
      : null,
    sale_start_date: sale_start_date && moment(sale_start_date).format('LL'),
    sale_end_date: sale_end_date && moment(sale_end_date).format('LL'),
    ...rest
  };

  return returnInput;
};
const RealtorFilterForm = withFormik({
  mapPropsToValues: props => {
    const { filter } = props;
    return isNotNULL(filter) ? filter : realtorFilterForm;
  },
  handleSubmit: (values, { props, setSubmitting }) => {
    const { setFilter, setShow } = props;
    const inputs = setInput(values);
    setFilter(inputs);
    setShow(false);
    setSubmitting(false);
  },
  displayName: "homeowner-filter-form",
  enableReinitialize: true,
  validationSchema: realtorFilterSchema
})(RealtorForm);

export default RealtorFilterForm;
