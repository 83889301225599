import gql from "graphql-tag";

const GET_COMMUNITIES = gql`
  query getAllCommunities($division_ids: [Int]) {
    getAllCommunities(division_ids: $division_ids) {
      id
      name
      is_active
      division_id
    }
  }
`;

export default GET_COMMUNITIES;