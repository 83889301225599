import gql from 'graphql-tag'

const FETCH_CALL_RECORDING = gql`
  query getPhoneCallRecording($phone_call_id: ID!) {
    getPhoneCallRecording(phone_call_id: $phone_call_id) {
      message
      code
    }
  }
`;

export default FETCH_CALL_RECORDING