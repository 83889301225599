
import gql from 'graphql-tag';

const ADD_LEAD_APPOINTMENT = gql` 
  mutation addLeadAppointment($input: LeadAppointmentInput!) {
    addLeadAppointment(input: $input) {
      message
      code
    }
  }
`;

export default ADD_LEAD_APPOINTMENT;