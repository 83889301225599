export const getInitialState = ({ loading, cache }) => {
    return {
      filter: {
        ...(cache && cache.filter)
      },
      search: (cache && cache.search) || "",
      limit: (cache && cache.limit) || 10,
      pageNum: (cache && cache.pageNum) || 0,
      pageSize: (cache && cache.limit) || 10,
      sort: (cache && cache.sort) || {
        id: -1,
        name: "",
        comparator: "",
        sort: ""
      },
      userPageCount: 0,
      showSideBar: false,
      showConfirmationDialog: false,
      agencies: [],
      deleteId: null,
      selectedAgency: null,
      loading: loading,
      render: !loading
    };
  };
  
  export default function reducer(state, action) {
    switch (action.type) {
      case "UPDATE":
        return Object.assign({}, state, { ...action.payload });
  
      case "RESET":
        return Object.assign({}, state, {
          ...action.payload,
          filter: {},
          search: "",
          searchTerm: "",
          limit: 10,
          pageSize: 10,
          pageNum: 0,
          sort: {
            id: -1,
            name: "",
            comparator: "",
            sort: ""
          },
          userPageCount: 0
        });
  
      default:
        return state;
    }
  }
  