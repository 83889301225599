import gql from "graphql-tag";

export const GET_WEBFORM_TYPE_AUTO_CONVERT = gql`
  {
    getWebFormTypeAutoConvert {
      id
      name
      with_community
      without_community
    }
  }
`;
