import { graphql, compose } from "react-apollo";
import {
  CreateLeadNote,
  UpdateLeadNote,
  CreateProspectNote,
  UpdateProspectNote,
  CreateRealtorNote,
  UpdateRealtorNote,
  CreateHomeownerNote,
  UpdateHomeownerNote
} from "./add-edit-note";
import {
  ADD_LEAD_NOTE,
  UPDATE_LEAD_NOTE,
  ADD_PROSPECT_NOTE,
  UPDATE_PROSPECT_NOTE,
  ADD_REALTOR_NOTE,
  UPDATE_REALTOR_NOTE,
  ADD_HOMEOWNER_NOTE,
  UPDATE_HOMEOWNER_NOTE,
  ADD_NOTE_ATTACHMENT
} from "../../graphql/mutations";

export const CreateLeadNoteContainer = compose(
  graphql(ADD_LEAD_NOTE, { name: "addLeadNote" }),
  graphql(ADD_NOTE_ATTACHMENT, { name: "addNoteAttachment" }),
)(CreateLeadNote);

export const UpdateLeadNoteContainer = compose(
  graphql(UPDATE_LEAD_NOTE, { name: "updateLeadNote" }),
  graphql(ADD_NOTE_ATTACHMENT, { name: "addNoteAttachment" })
)(UpdateLeadNote);

export const CreateProspectNoteContainer = compose(
  graphql(ADD_PROSPECT_NOTE, { name: "addProspectNote" }),
  graphql(ADD_NOTE_ATTACHMENT, { name: "addNoteAttachment" }),
)(CreateProspectNote);

export const UpdateProspectNoteContainer = compose(
  graphql(ADD_NOTE_ATTACHMENT, { name: "addNoteAttachment" }),
  graphql(UPDATE_PROSPECT_NOTE, { name: "updateProspectNote" })
)(UpdateProspectNote);

export const CreateRealtorNoteContainer = compose(
  graphql(ADD_REALTOR_NOTE, { name: "addRealtorNote" }),
  graphql(ADD_NOTE_ATTACHMENT, { name: "addNoteAttachment" })
)(CreateRealtorNote);

export const UpdateRealtorNoteContainer = compose(
  graphql(UPDATE_REALTOR_NOTE, { name: "updateRealtorNote" }),
  graphql(ADD_NOTE_ATTACHMENT, { name: "addNoteAttachment" })
)(UpdateRealtorNote);

export const CreateHomeownerNoteContainer = compose(
  graphql(ADD_HOMEOWNER_NOTE, { name: "addHomeownerNote" }),
  graphql(ADD_NOTE_ATTACHMENT, { name: "addNoteAttachment" }),
)(CreateHomeownerNote);

export const UpdateHomeownerNoteContainer = compose(
  graphql(UPDATE_HOMEOWNER_NOTE, { name: "updateHomeownerNote" }),
  graphql(ADD_NOTE_ATTACHMENT, { name: "addNoteAttachment" })
)(UpdateHomeownerNote);
