import gql from "graphql-tag";

const GET_SELECTED_SINGLE_USER = gql`
  query {
    selectedSingleUserId @client {
      id
    }
  }
`;

export default GET_SELECTED_SINGLE_USER;
