import React from "react";

import {
  get5WordsForTitle,
  formatActivityDate,
  getIconByEvent,
} from "../../utils/helpers";

/**
 * @module PastActivityFollowupListViewModule
 */
/**
 * this component is used to display the follow ups  in the past activities
 * @param {object} pastActivitiesData contains information related to the follow ups
 */
const PastActivityFollowupListView = (props) => {
  const { pastActivitiesData } = props;

  /**
   * this function renders the toggle part of the follow ups  in past activities
   * @function
   * @inner
   * @memberof module:PastActivityFollowupListViewModule
   * @param {object} item contains information related to the follow ups
   * @returns {JSX.Element}
   */
  const toggle = (item) => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <div
            className="d-flex align-items-center"
            style={{ marginRight: "2px" }}
          >
            {getIconByEvent(pastActivitiesData.typeTitle)}
            <span style={{ fontSize: "12px", display: "d-flex" }}>
              {pastActivitiesData.typeTitle}:
            </span>
          </div>
          {get5WordsForTitle(item.title)}
        </div>
        <div className="d-flex align-items-center  toggle-date-time">
          {formatActivityDate(item.follow_up_datetime)}
        </div>
      </div>
    );
  };

  /**
   * this function renders the collapse part of the follow ups  in past activities
   * @function
   * @inner
   * @memberof module:PastActivityFollowupListViewModule
   * @param {object} item contains information related to the follow ups
   * @returns {JSX.Element}
   */
  const collapse = (item) => {
    return (
      <ul className="noted-descr-body">
        <li>
          <label>DETAILS:</label>
          <span>{item.details}</span>
        </li>
        <li>
          <label>ASSIGNED TO:</label>
          {item.csm && (
            <span>
              {item.csm.first_name} {item.csm.last_name}
            </span>
          )}
        </li>
        <li>
          <label>SET BY:</label>
          {item.creator && (
            <span>
              {`${item.creator.first_name} ${item.creator.last_name} at ${formatActivityDate(item.created_at)}`}
            </span>
          )}
        </li>
      </ul>
    );
  };
  return (
    <div className="notes-card">
      {props.render(toggle(pastActivitiesData), collapse(pastActivitiesData))}
    </div>
  );
};
export default PastActivityFollowupListView;
