import React, { useContext } from "react";
import { Auth } from "../../../rbac/rbac";
import { compose, graphql } from "react-apollo";
import { NewClientRegistrations } from "../realtor-client-registrations/new-client-registrations-table";
import DashboardContextProvider from "../../../context/dashboard-context-provider";
import AllClientRegistrationsTable from '../realtor-client-registrations/all-client-registrations-container'
import { getCreateDateFilters } from "../../../utils/helpers";
import { GET_PREVIOUS_CARD } from "../../../cacheql/queries";
import { GET_UNREAD_COUNT, GET_ALL_REALTOR_CLIENT_REGISTRATIONS_COUNT } from "../../../graphql/queries";

const activityCards = [
  {
    title: "New",
    uniqueTitle: "New Client Registrations",
    description:
      "This table shows all new client registrations via a Realtor",
    isSubscriptionTab: true,
    customTable: <NewClientRegistrations />,
    customDataQuery: GET_UNREAD_COUNT,
    customPath: 'getRealtorClientRegistrationCount'
  },
  {
    title: "Active",
    uniqueTitle: "Active Client Registrations",
    description:
      "This table shows all the active clients that have successfully been converted to Prospects",
    customDataQuery: GET_ALL_REALTOR_CLIENT_REGISTRATIONS_COUNT,
    filter: { expired: false },
    customPath: 'getAllRealtorClientRegistrations.total_count',
    customTable: <AllClientRegistrationsTable />
  },
  {
    title: "All",
    uniqueTitle: "All Client Registrations",
    description:
      "This table shows all the clients that have successfully been converted to Prospects",
    customDataQuery: GET_ALL_REALTOR_CLIENT_REGISTRATIONS_COUNT,
    customPath: 'getAllRealtorClientRegistrations.total_count',
    customTable: <AllClientRegistrationsTable />
  }
];

/**
 * This component is used to render realtor registrations with client dashboard with 3 cards and a table. 
 * 2 of the cards render the same table whereas the "New Client Registrations" renders a different table.
 * Dropdown and Division Dropdown is there for manager role only. 
 * By default New Client Registrations card is preselected.
 * @param {*} props 
 */
const ClientsRegistrationDashboardView = props => {

  const { is_manager, has_multiple_divisions } = useContext(Auth);

  const getDefaultCard = () => {
    const cardTitle = props.getPreviousCard.previousCard.title;
    const index = activityCards.findIndex(item => item.title === cardTitle);
    if (index === -1) {
      return activityCards[0];
    }
    return activityCards[index];
  }

  return (
    <DashboardContextProvider
      type="Client Registrations"
      pageTitle={is_manager() ? null : "Dashboard"}
      startingDefaultCard={getDefaultCard()}
      activityCards={activityCards}
      withCommunity={false}
      withDivision={is_manager() || has_multiple_divisions()}
      {...props}
    />
  );
};

export default compose(
  graphql(GET_PREVIOUS_CARD, { name: "getPreviousCard" })
)(ClientsRegistrationDashboardView);