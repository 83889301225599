export const stringComparator = field => (a, b) => {
  const aVal = a[field] ? a[field] : "";
  const bVal = b[field] ? b[field] : "";
  return aVal.toString().localeCompare(bVal);
};

export const numberComparator = field => (a, b) => {
  const aVal = a[field] ? a[field] : 0;
  const bVal = b[field] ? b[field] : 0;
  return aVal - bVal;
};

export const boolComparator = field =>(a, b)=>{
  return a[field] ? -1 : 1;
}

export const dateComparator = field => (a, b) => {
  const dateA = new Date(a[field]).getTime();
  const dateB = new Date(b[field]).getTime();
  if (dateA === dateB){ 
    return 0;}
  if (dateA < dateB) {
    return -1;}
  return 1;
};

export const dateTimeComparator = field => (a, b) => {
  const dateA = new Date(a[field][a[field].length - 1].created_at);
  const dateB = new Date(b[field][b[field].length - 1].created_at);
  if (dateA === dateB){ 
    return 0;}
  if (dateA < dateB) {
    return -1;}
  return 1;
};
