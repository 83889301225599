import {
  GET_SELECTED_COMMUNITY,
  GET_SELECTED_DIVISION,
  GET_TITLE_CUSTOMIZATIONS,
  GET_NOTIFICATION_PROPS,
  GET_USER,
  GET_TABLE_PARAMS,
  GET_PREVIOUS_CARD,
  GET_AUTH_STATUS,
  GET_SELECTED_CSM,
  GET_SELECTED_OSC,
  GET_SELECTED_USER,
  GET_SELECTED_SINGLE_COMMUNITY,
  GET_DATE_RANGE,
  GET_DEFAULT_VIEW,
  GET_SELECTED_SINGLE_USER,
  GET_CALENDAR_DATE_RANGE,
  GET_SHOW_MY_APPOINTMENTS_ONLY
} from "../cacheql/queries";
import defaults from "./defaults";

export default {
  Mutation: {
    selectCommunity: (_, input, { cache }) => {
      const query = GET_SELECTED_COMMUNITY;
      const previous = cache.readQuery({ query });
      const data = {
        selectedCommunityIds: {
          ...previous.selectedCommunityIds,
          ids: input.selectedCommunity
        }
      };
      cache.writeQuery({ query, data });
    },

    selectDefaultView: (_, input, { cache }) => {
      const query = GET_DEFAULT_VIEW;

      const data = {
        defaultView: {
          __typename: "DefaultView",
          tableView: input.tableView
        }
      }

      cache.writeQuery({ query, data })
    },

    selectCsm: (_, input, { cache }) => {
      const query = GET_SELECTED_CSM;
      const previous = cache.readQuery({ query });
      const data = {
        selectedCsmIds: {
          ...previous.selectedCsmIds,
          ids: input.selectedCsm
        }
      };
      cache.writeQuery({ query, data });
    },
    selectOsc: (_, input, { cache }) => {
      const query = GET_SELECTED_OSC;
      const previous = cache.readQuery({ query });
      const data = {
        selectedOscIds: {
          ...previous.selectedOscIds,
          ids: input.selectedOsc
        }
      };
      cache.writeQuery({ query, data });
    },
    selectUser: (_, input, { cache }) => {
      const query = GET_SELECTED_USER;
      const previous = cache.readQuery({ query });
      const data = {
        selectedUserIds: {
          ...previous.selectedUserIds,
          ids: input.selectedUsers
        }
      };
      cache.writeQuery({ query, data });
    },

    selectSingleUser: (_, input, { cache }) => {
      let inputToUse = input.id ? { ...input } : { ...defaults.selectedSingleUserId }
      const query = GET_SELECTED_SINGLE_USER;
      const previous = cache.readQuery({ query });
      const data = {
        selectedSingleUserId: {
          ...previous.selectedSingleUserId,
          id: inputToUse.id
        }
      };
      cache.writeQuery({ query, data });
    },

    selectCommunityId: (_, input, { cache }) => {
      let inputToUse = input.id
        ? { ...input }
        : { ...defaults.selectedSingleCommunityId };

      const query = GET_SELECTED_SINGLE_COMMUNITY;
      const previous = cache.readQuery({ query });
      const data = {
        selectedSingleCommunityId: {
          ...previous.selectedSingleCommunityId,
          id: inputToUse.id
        }
      };
      cache.writeQuery({ query, data });
    },

    selectDateRange: (_, input, { cache }) => {

      const query = GET_DATE_RANGE;
      const previous = cache.readQuery({ query });
      const data = {
        selectedDateRange: {
          ...previous.selectedDateRange,
          startDate: input.startDate,
          endDate: input.endDate
        }
      };
      cache.writeQuery({ query, data });
    },

    selectCalendarDateRange: (_, input, { cache }) => {

      const query = GET_CALENDAR_DATE_RANGE;
      const previous = cache.readQuery({ query });
      const data = {
        selectedCalendarDateRange: {
          ...previous.selectedCalendarDateRange,
          start: input.start,
          end: input.end
        }
      };
      cache.writeQuery({ query, data });
    },

    selectDivision: (_, input, { cache }) => {
      let inputToUse = input.id
        ? { ...input }
        : { ...defaults.selectedDivision };

      const query = GET_SELECTED_DIVISION;
      const previous = cache.readQuery({ query });
      const data = {
        selectedDivision: {
          ...previous.selectedDivision,
          id: inputToUse.id,
          name: inputToUse.name
        }
      };
      cache.writeQuery({ query, data });
    },

    setPreviousCard: (_, input, { cache }) => {
      const query = GET_PREVIOUS_CARD;

      const data = {
        previousCard: {
          ...defaults.previousCard,
          ...input
        }
      };

      cache.writeQuery({ query, data });
    },

    updateTitleCustomizations: (_, { titleCustomizations }, { cache }) => {
      let inputToUse = titleCustomizations.length
        ? [...titleCustomizations]
        : [...defaults.titleCustomizations];

      cache.writeQuery({
        query: GET_TITLE_CUSTOMIZATIONS,
        data: { titleCustomizations: inputToUse }
      });
    },

    setNotificationProps: (_, { open, message }, { client }) => {
      client.writeQuery({
        query: GET_NOTIFICATION_PROPS,
        data: {
          notificationProps: { open, message, __typename: "TitleCustomization" }
        }
      });
    },

    setAuthStatus: (_, input, { client }) => {
      client.writeQuery({
        query: GET_AUTH_STATUS,
        data: {
          authStatus: { ...input, __typename: "AuthStatus" }
        }
      });
    },

    setUser: (_, { role, name }, { client }) => {
      client.writeQuery({
        query: GET_USER,
        data: { user: { role, name, __typename: "User" } }
      });
    },

    setTableParams: (_, input, { cache }) => {
      const previous = cache.readQuery({
        query: GET_TABLE_PARAMS
      });
      const cardsParams = [];
      /*  */

      input.cardsParams.forEach((item, index) => {
        cardsParams.push({
          ...previous.cardsParams,
          cardTitle: item.cardTitle,
          params: {
            ...item.params,
            __typename: "CardParams",
            filter: {
              ...item.params && item.params.filter
            }
          }
        });
      });
      cache.writeQuery({
        query: GET_TABLE_PARAMS,
        data: {
          tableParams: {
            __typename: "CardState",
            cardsParams
          }
        }
      });
    },

    setShowMyAppointmentsOnly: (_, input, { cache }) => {
      const query = GET_SHOW_MY_APPOINTMENTS_ONLY;
      const previous = cache.readQuery({ query })
      const data = {
        showMyAppointmentsOnly: {
          ...previous.showMyAppointmentsOnly,
          show: input.show !== undefined ? input.show
            : defaults.showMyAppointmentsOnly.show
        }
      }

      cache.writeQuery({ query, data })
    }
  }
};
