
import gql from 'graphql-tag';

const APPROVE_DELETE_REQUEST = gql` 
  mutation approveDeleteRequests($input: ApproveDeleteRequestInput!) {
    approveDeleteRequests(input: $input) {
      message
      code
    }
  }
`;

export default APPROVE_DELETE_REQUEST;

