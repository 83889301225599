import React, { useState, useEffect } from "react";
import { compose, graphql } from "react-apollo";
import {
  GET_AUTO_REPLY_WEBFORM_TYPES,
  GET_DEFAULT_DIVISION_OSC_WEBFORM_AUTO_REPLY,
  GET_DEFAULT_OSC_WEBFORM_AUTO_REPLY,
  GET_DIVISIONS,
  GET_OSC,
} from "../../graphql/queries";
import { isNotNULL, sortArray, getWeekdays } from "../../utils/helpers";
import AutoReplySelector from "./auto-reply-selector";
import { ListGroup } from "react-bootstrap";
import SelectionListPopup from "../../components/list-popup/selection-list-popup";
import { SET_NOTIFICATION_PROPS } from "../../cacheql/mutations";
import { SET_DEFAULT_OSC_WEBFORM_AUTO_REPLY } from "../../graphql/mutations";
import CustomTable from "../../components/table/custom-table";
import { useQuery } from "react-apollo-hooks";

/**
 * This component renders the Manage Auto-Reply section in the Web From Reply Email Associations tab. It contains a {@link SelectionListPopup} to select the default 
 * OSC from a list of all available OSC with whose email all responses will automatically go out. It also contains a table which will show a {@link SelectionListPopup} against each 
 * division in the application, showing the default OSC in each division. In the bottom there is a {@link AutoReplySelector} which allows users to change a webform type to a 
 * auto-reply type and vice-versa. 
 * @param {Object} props
 * @param {Object} props.getOSC A list of all OSCs available
 * @param {Object} props.getDivision A list of all the divisions
 * @param {Object} props.getDefaultOSC A list of all the default OSC's
 * @param {Object} props.getAutoReplyWebFormTypes A list of all webform types with a boolean specifying if the type is auto-reply or not
 * @param {object} props.defaultOSC Details of the default OSC
 */
const ManageAutoReply = ({
  getOSC: { getAllOSCs },
  getDivision: { getAllDivisions },
  getDefaultOSC: { getWebFormAutoReplyDefaultOsc },
  getAutoReplyWebFormTypes,
  defaultOSC,
  ...props
}) => {
  const [webFormTypes, setWebFormTypes] = useState([]);
  const [autoReplyWebFormTypes, setAutoReplyWebFormTypes] = useState([]);
  const [divisionDefaultList, setDivisionDefaultList] = useState([]);
  const weekdays = getWeekdays();

  const { refetch } = useQuery(GET_DEFAULT_DIVISION_OSC_WEBFORM_AUTO_REPLY, {
    skip: true
  })

  /**
   * This function is used to fetch default OSC's of divisions per day
   * @returns array consisting of division-wise default OSC data per day
   */
  async function processDefaultDivisionOscs() {
    let divisionOscList = [];
    for (const division of getAllDivisions) {
      let { data } = await refetch({
        division_id: division.id
      });
      divisionOscList.push(data);
    }
    return divisionOscList;
  }

  useEffect(() => {
    if (getAllDivisions) {
      processDefaultDivisionOscs().then(function (result) {
        setDivisionDefaultList(result);
      });
    }
  }, [getAllDivisions])

  useEffect(() => {
    if (!getAutoReplyWebFormTypes.loading) {
      let webform_types = []
      let auto_reply_types = []

      let all_types = sortArray(getAutoReplyWebFormTypes.getAutoReplyWebFormTypes, 'name', 'asc')
      all_types.map(webform_type => {
        webform_type.auto_reply ? auto_reply_types.push(webform_type) : webform_types.push(webform_type)
      })

      setWebFormTypes(webform_types)
      setAutoReplyWebFormTypes(auto_reply_types)
    }
  }, [getAutoReplyWebFormTypes]);

  /**
   * This function is used to refetch the data whenever there is an update on the {@link AutoReplySelector} component
   * @param {String} msg The response message to show in the notification 
   */
  const refetchAutoReply = (msg) => {
    notify(msg);
    getAutoReplyWebFormTypes.refetch();
  };

  /**
   * This function updates the server whenever the user changes the OSC type, in either default OSC or division OSC 
   * @param {Object} variables Variables to pass to the mutation in order to carry out the update
   * @param {String} mutation An accessor defining the mutation to run
   * @param {String} resMutation  An accessor to obtain the response from the mutation
   */
  const handleUpdate = (variables, mutation, resMutation) => {
    props[mutation]({
      variables,
    }).then((res) => {
      if (res.data[resMutation].code === 200) {
        notify(res.data[resMutation].message);
      }
    });
  };

  /**
   * A function to show a notification window on the bottom of the screen. 
   * @param {String} message The message to show
   */
  const notify = (message) => {
    if (message) {
      props.setNotificationProps({
        variables: { open: true, message: message },
      });
    }
  };

  const getDefaultTableData = () => {
    let tableData = []
    if (getWebFormAutoReplyDefaultOsc) {
      const defaultOscSelectList = weekdays.map((weekday) => {
        weekday = weekday.toLowerCase();
        const first_name = getWebFormAutoReplyDefaultOsc[`${weekday}_osc`]?.first_name;
        const last_name = getWebFormAutoReplyDefaultOsc[`${weekday}_osc`]?.last_name;
        return (
          <SelectionListPopup
            label={
              getWebFormAutoReplyDefaultOsc[`${weekday}_osc`] ?
                `${first_name} ${last_name}` : "Select OSC"
            }
            key={weekday}
            list={isNotNULL(getAllOSCs) ? getAllOSCs : null}
            mapper={(field) => {
              return (
                isNotNULL(field) &&
                field.map((item, key) => (
                  <ListGroup.Item
                    className="list-item"
                    onClick={() => {
                      const variables = {
                        osc_id: item.id,
                        day_of_week: weekday
                      };
                      handleUpdate(
                        variables,
                        "setDefaultOSC",
                        "setDefaultOscForWebFormAutoReply"
                      );
                    }}
                    key={key}
                  >
                    {`${item.first_name} ${item.last_name}`}
                  </ListGroup.Item>
                ))
              );
            }}
          />
        )
      })
      tableData.push(["Default OSC", ...defaultOscSelectList])
    }

    return tableData
  }

  const getTableData = () => {
    let tableData = []
    if (isNotNULL(getAllDivisions)) {
      getAllDivisions.map((division, idx) => {
        tableData.push([<span>{division.name}</span>])
        const oscSelectList = weekdays.map((weekday) => {
          weekday = weekday.toLowerCase();
          return (
            <div className="read-only-item">
              {divisionDefaultList[idx] && divisionDefaultList[idx].getWebFormAutoReplyDefaultOscForDivision
                && divisionDefaultList[idx].getWebFormAutoReplyDefaultOscForDivision[`${weekday}_osc`] ?
                `${divisionDefaultList[idx].getWebFormAutoReplyDefaultOscForDivision[`${weekday}_osc`].first_name} ${divisionDefaultList[idx].getWebFormAutoReplyDefaultOscForDivision[`${weekday}_osc`].last_name}`
                : '-'}
            </div>
          )
        })
        tableData[idx].push(...oscSelectList);
      })
    }
    return tableData
  }

  return (
    <>
      <h3 style={{ fontSize: '15px', marginLeft: '0', marginBottom: '1.5rem' }}>Manage Auto-Reply</h3>
      <div className="table-compact">
        <CustomTable
          tableHead={["", ...weekdays]}
          tableData={getDefaultTableData()}
        />
      </div>
      <div className="table-compact">
        <span className="top-caption" style={{ float: "right" }}> ** To change a Division's OSC for a specific day, please visit Heartbeat </span>
        <CustomTable
          tableHead={["Division", ...weekdays]}
          tableData={getTableData()}
        />
      </div>
      <div className="col-xl-6">
        <AutoReplySelector
          unavailable={isNotNULL(webFormTypes) ? webFormTypes : null}
          available={
            isNotNULL(autoReplyWebFormTypes) ? autoReplyWebFormTypes : null
          }
          update={refetchAutoReply}
        />
      </div>
    </>
  );
};

export default compose(
  graphql(GET_OSC, { name: "getOSC" }),
  graphql(GET_DIVISIONS, { name: "getDivision" }),
  graphql(GET_AUTO_REPLY_WEBFORM_TYPES, { name: "getAutoReplyWebFormTypes" }),
  graphql(GET_DEFAULT_OSC_WEBFORM_AUTO_REPLY, { name: "getDefaultOSC" }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" }),
  graphql(SET_DEFAULT_OSC_WEBFORM_AUTO_REPLY, { name: "setDefaultOSC" }),
)(ManageAutoReply);
