import React from "react";
import { getIn } from 'formik';
import PropTypes from 'prop-types';
import "./fields.css";

/**
 * This select field is used to select single value. It is used for non-integer options only
 * @param {Object} props 
 */
const SelectFieldNonInt = props => {
  const {
    id,
    label,
    field,
    field: { onChange, ...otherProps },
    form: { touched, errors },
    children,
    type,
    required,
    disabled,
    classStyle,
    ...rest
  } = props;
  const error = getIn(errors, field.name);
  const touch = getIn(touched, field.name);
  const errorMessage = touch && error;
  const style = required ? { borderLeftColor: "red", borderLeftWidth: 2, background: "#262628" } : { background: "" }


  return (
    <div id={id + "-form-group"} className="form-group">
      <label htmlFor={id + "-input"} className="control-label">
        {label}
      </label>
      <select
        disabled={disabled}
        style={style}
        id={id + "-input"}
        type={type}
        onChange={onChange}
        className={classStyle + ' form-control' + (errorMessage ? ' is-invalid' : '')}
        {...otherProps}
        {...rest}
      >
        {children}
      </select>
      {error && <div className="invalid-feedback">{errorMessage}</div>}
    </div>
  );
};

SelectFieldNonInt.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  children: PropTypes.array
}

export default SelectFieldNonInt;
