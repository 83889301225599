import { compose, graphql } from "react-apollo";
import {
  CreateLeadActivity,
  UpdateLeadActivity,
  CreateProspectActivity,
  UpdateProspectActivity,
  CreateHomeownerActivity,
  UpdateHomeownerActivity
} from "./add-edit-activity";
import {
  ADD_PROSPECT_APPOINTMENT,
  ADD_PROSPECT_FOLLOW_UP,
  UPDATE_PROSPECT_FOLLOW_UP,
  UPDATE_PROSPECT_APPOINTMENT,
  ADD_LEAD_APPOINTMENT,
  ADD_LEAD_FOLLOW_UP,
  UPDATE_LEAD_APPOINTMENT,
  UPDATE_LEAD_FOLLOW_UP,
  ADD_HOMEOWNER_APPOINTMENT,
  ADD_HOMEOWNER_FOLLOW_UP,
  UPDATE_HOMEOWNER_APPOINTMENT,
  UPDATE_HOMEOWNER_FOLLOW_UP
} from "../../graphql/mutations";
import { GET_COMMUNITIES, GET_APPOINTMENT_TYPES } from "../../graphql/queries";

export const AddLeadActivityContainer = compose(
  graphql(ADD_LEAD_FOLLOW_UP, { name: "addLeadFollowUp" }),
  graphql(ADD_LEAD_APPOINTMENT, { name: "addLeadAppointment" }),
  graphql(GET_APPOINTMENT_TYPES, { name: "getAllProspectAppointmentTypes" }),
  graphql(GET_COMMUNITIES, { name: "getAllCommunities" }),
)(CreateLeadActivity);

export const UpdateLeadActivityContainer = compose(
  graphql(UPDATE_LEAD_APPOINTMENT, { name: "updateLeadAppointment" }),
  graphql(UPDATE_LEAD_FOLLOW_UP, { name: "updateLeadFollowUp" }),
  graphql(GET_APPOINTMENT_TYPES, { name: "getAllProspectAppointmentTypes" }),
  graphql(GET_COMMUNITIES, { name: "getAllCommunities" }),
)(UpdateLeadActivity);

export const AddActivityContainer = compose(
  graphql(GET_APPOINTMENT_TYPES, { name: "getAllProspectAppointmentTypes" }),
  graphql(ADD_PROSPECT_APPOINTMENT, { name: "addProspectAppointment" }),
  graphql(ADD_PROSPECT_FOLLOW_UP, { name: "addProspectFollowUp" }),
)(CreateProspectActivity);

export const UpdateActivityContainer = compose(
  graphql(GET_APPOINTMENT_TYPES, { name: "getAllProspectAppointmentTypes" }),
  graphql(UPDATE_PROSPECT_FOLLOW_UP, { name: "updateProspectFollowUp" }),
  graphql(UPDATE_PROSPECT_APPOINTMENT, { name: "updateProspectAppointment" })
)(UpdateProspectActivity);

export const AddHomeownerActivityContainer = compose(
  graphql(GET_APPOINTMENT_TYPES, { name: "getAllProspectAppointmentTypes" }),
  graphql(ADD_HOMEOWNER_APPOINTMENT, { name: "addHomeownerAppointment" }),
  graphql(ADD_HOMEOWNER_FOLLOW_UP, { name: "addHomeownerFollowUp" }),
)(CreateHomeownerActivity);

export const UpdateHomeownerActivityContainer = compose(
  graphql(GET_APPOINTMENT_TYPES, { name: "getAllProspectAppointmentTypes" }),
  graphql(UPDATE_HOMEOWNER_APPOINTMENT, { name: "updateHomeownerAppointment" }),
  graphql(UPDATE_HOMEOWNER_FOLLOW_UP, { name: "updateHomeownerFollowUp" })
)(UpdateHomeownerActivity);