import gql from "graphql-tag";

const GET_CUSTOMER_SUBSCRIPTION_HISTORY = gql`
  query getCustomerSubscriptionHistoryById(
    $customer_id: Int
  ) {
    getCustomerSubscriptionHistoryById(
        customer_id: $customer_id
    ) {
        customer_subscription_history{
            id,
            email,
            action,
            title,
            created_at
          }
    }
  }
`;
export default GET_CUSTOMER_SUBSCRIPTION_HISTORY;
