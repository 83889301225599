import React from "react";
import { get } from "lodash"

import "./activity-card.css";
import ActivityCard from "./activity-card";

/**
 * This function uses {@link ActivityCard} to generate cards using acticityCards array. Only the cards that do not contain hidden property are rendered. 
 * @param {Object} props Component props
 * @param {Array.<Object>} props.activityCards It contains cards with details (e.g. title, filter, etc)
 * @param {String} props.type Type of page (Prospects/Leads etc)
 * @param {Object} props.currentCard Current selected card
 * @param {Function} props.updateCurrentCard Used to update current selected card
 */
const ActivityCardGenerator = ({
  activityCards,
  type,
  currentCard,
  updateCurrentCard,
  ...props
}) => {
  return (
    <>
      {activityCards &&
        activityCards
          .filter(({ hidden }) => !hidden)
          .map(
            (
              {
                title,
                filter,
                description,
                customTable,
                percentPath,
                customPath,
                isDetail,
                isSubscriptionTab,
                mode,
                type: cardType,
                secondaryDataQuery,
                ...rest
              },
              key
            ) => {

              const data = props[title];
              const count = (get(data, customPath) || (data[`get${type}`] && data[`get${type}`].total_count)) || 0
              const prevCount = secondaryDataQuery ?
                customPath && !secondaryDataQuery.customPath ? get(props[secondaryDataQuery.name], customPath)
                  : (props[secondaryDataQuery.name][`get${type}`] && props[secondaryDataQuery.name][`get${type}`].total_count) : undefined
              const percent = get(data, percentPath)
              const secondaryCount = secondaryDataQuery?.customPath ? get(props[secondaryDataQuery.name], secondaryDataQuery.customPath) : null

              return (
                <ActivityCard
                  key={key}
                  selectedCard={currentCard.title}
                  onClick={() =>
                    updateCurrentCard({ title, filter, mode, customTable, isDetail, type: cardType, isSubscriptionTab, ...rest })
                  }
                  description={description}
                  counter={title}
                  count={count}
                  secondaryCount={secondaryCount}
                  prevCount={prevCount}
                  percentValue={percent}
                />
              );
            }
          )}
    </>
  );
};

export default ActivityCardGenerator;
