import { compose, graphql } from "react-apollo";

import { GET_AGENCY_BY_ID } from "../../../graphql/queries";
import { SET_NOTIFICATION_PROPS } from "../../../cacheql/mutations";
import AgencyDetailsView from './agency-details'

export default compose(
    graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" }),
    graphql(GET_AGENCY_BY_ID, { name: "getAgenciesById" , 
        options: props => {
            return {
                variables: {
                    filter: {id: props.match.params.id}
                },
                fetchPolicy: 'no-cache'
            }
        }
    })
)(AgencyDetailsView);