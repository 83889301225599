import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import CustomButton from "../../../components/custom-button";
import { isNotNULL } from "../../../utils/helpers";


/**
 * This component is used to render upsert flag form. It contains only "reason" text area field.  
 * @param {Object} props 
 */
export const UpsertFlagForm = ({
  setShow,
  id,
  red_flag,
  refetchEditData,
  ...props
}) => {
  const [reason, setReason] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isNotNULL(red_flag)) {
      setReason(red_flag);
    }
  }, [red_flag]);

  return (
    <Form className="filter-menufilter-flag">
      <h3 className="ml-0">Add Flag</h3>

      <Form.Label>Reason</Form.Label>
      <Form.Control
        className={error ? "mb-2 is-invalid" : "mb-2"}
        as="textarea"
        // className="mb-2"
        cols="35"
        onChange={e => {
          setError("");
          setReason(e.target.value);
        }}
        value={reason}
      />
      {isNotNULL(error) && <div className="invalid-feedback">{error}</div>}

      <div className="filter-flag-controls">
        <CustomButton
          color="black"
          onClick={e => setShow(false)}
          btnValue="CANCEL"
        />
        <CustomButton
          /* disabled={isNotNULL(reason.trim()) ? false : true} */
          onClick={e => {
            if (isNotNULL(reason.trim())) {
              props
                .upsertRedFlag({
                  variables: { input: { prospect_id: id, description: reason } }
                })
                .then(res => {
                  if (res.data.upsertRedFlag.code === 200) {
                    setShow(false);
                    props.setNotificationProps({
                      variables: {
                        open: true,
                        message: res.data.upsertRedFlag.message
                      }
                    });
                    refetchEditData(reason);
                  }
                });
            } else {
              setError("This field is empty");
            }
          }}
          btnValue={isNotNULL(red_flag) ? "UPDATE" : "ADD"}
        />

        {isNotNULL(red_flag) && (
          <CustomButton
            onClick={() => {
              props
                .deleteRedFlag({
                  variables: { input: { prospect_id: id } }
                })
                .then(res => {
                  if (res.data.deleteRedFlag.code === 200) {
                    props.setNotificationProps({
                      variables: {
                        open: true,
                        message: res.data.deleteRedFlag.message
                      }
                    });
                    refetchEditData("");
                    setShow(false);
                    setReason("");
                  }
                });
            }}
            color="black"
            className="float-right"
            btnValue={"CLEAR FLAG"}
          />
        )}
      </div>
    </Form>
  );
};
