import gql from 'graphql-tag';

const ADD_HOMEOWNER_NOTE = gql` 
  mutation addHomeownerNote($input: HomeownerNoteInput) {
    addHomeownerNote(input: $input) {
      message
      code
      data
    }
  }
`;

export default ADD_HOMEOWNER_NOTE;