import React, { useState } from "react";
import CustomButton from "../custom-button";
import Dialog from "../dialog/dialog";
import Help from "../help";
import {
  FaAlignLeft,
  FaAlignCenter,
  FaAlignRight,
  FaCog,
  FaTimes
} from "react-icons/fa";
import { renderToStaticMarkup } from "./editor-helpers";
import { isNotNULL } from "../../utils/helpers";
import { Button } from "react-bootstrap";


/**
 * @module EditorControlsModule
 */

 /**
  * 
  * @param {object} props component props 
  * @param {string} emailSubject Subject of the Email
  * @param {object} settings contains information related to the settings style
  * @param {function} handleSettings it handles the setting style on change
  */
export const EditorControls = ({ ...props }) => {
  const [allignment, setAllignment] = useState("left");
  const [showPreviewEmailDialog, setShowPreviewEmailDialog] = useState(false);
  const [showTestEmailDialog, setShowTestEmailDialog] = useState(false);
  const [emailD, setEmailD] = useState("");
  const [showTemplateSettingsDialog, setShowTemplateSettingsDialog] = useState(
    false
  );
  const handleChange = value => {
    setEmailD(value);
  };

  /**
   * this function is being used to render the test email dialog
   * @function
   * @inner
   * @memberof module:EditorControlsModule
   * @returns {JSX.Element}
   */
  const testEmailDailogContent = () => {
    return (
      <input
        type="text"
        value={emailD}
        className="form-control"
        placeholder="Add your email"
        onChange={e => handleChange(e.target.value)}
      />
    );
  };

    /**
   * this function is being used to render the help description popup in settings dialog
   * @function
   * @inner
   * @memberof module:EditorControlsModule
   * @param {string} colorLabel its label name of help section
   * @returns {JSX.Element}
   */
  const getHelpDescription = ({ colorLabel }) => {
    return (
      <div className="tooltip-description small muted p-3">
        <p>Assign {colorLabel} color using:</p>
        <ul className="text-monospace">
          <li className="bullets-li">Color name (e.g: White, black etc)</li>
          <li className="bullets-li">Hex code(e.g: #FFFFFF, #000000 etc)</li>
          <li className="bullets-li">
            RGB code (e.g: rgb(255, 255, 255), rgb(0,0,0) etc)
          </li>
        </ul>
      </div>
    );
  };

      /**
   * this function is being used to render the preview email dialog
   * @function
   * @inner
   * @memberof module:EditorControlsModule
   * @param {string} emailSubject Subject of the email
   * @returns {JSX.Element}
   */
  const previewEmailDailogBody = () => {
    return (
      <div id="text-preview-panel" className="text-preview-panel col-padding">
        <div className="text-preview-wrapper">
        <div className="d-flex justify-content-between align-items-center mt-2 mb-2">
          <div className="subject-section-preview d-flex mr-2 ml-2">
            <div className="text-muted mr-2 mt-0">Subject:</div>
            {!isNotNULL(props.emailSubject) ? (
              <span style={{ color: "red" }}>Subject is Required</span>
            ) : null}
            <p className="m-0" style={{color:'black'}}>{props.emailSubject}</p>
          </div>
            <Button
              className="btn-close"
              color="black"
              style={{fontSize: '16px', marginRight: '0.5rem', color:'black'}} 
              onClick={() => {
                setShowPreviewEmailDialog(false);
              }}  
            >
              <FaTimes />
            </Button>
          </div>
          <div className="body-section-preview">
            <div
              dangerouslySetInnerHTML={{
                __html: renderToStaticMarkup(props.emailContent())
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const closeTestEmail = () => {
    setShowTestEmailDialog(false);
    setEmailD("");
  };

  const getActiveClass = align => {
    return allignment === align
      ? "btn btn-secondary active-allignment"
      : "btn btn-secondary";
  };

  /**
   * this function is being used to render the settings dialog
   * @function
   * @inner
   * @memberof module:EditorControlsModule
   * @param {function} handleSettings handles the setting styles with specified inputs
   * @returns {JSX.Element}
   */
  const emailBodySettingsDailogContent = () => {
    return (
      <>
        <p className="mb-3">Settings will apply on email preview. </p>
        <hr className="light d-none d-md-block" />
        <div className="d-md-flex align-items-end mt-2">
          <div className="form-group">
            <label>Width (px)</label>
            <input
              onChange={e => props.handleSettings({ width: e.target.value })}
              type="text"
              value={props.settings.width}
              className="form-control mr-md-2"
            />
          </div>
          <div className="form-group ml-md-2">
            <label className="d-block">Text Alignment:</label>
            <div className="btn-group">
              <button
                className={getActiveClass("left")}
                onClick={() => {
                  setAllignment("left");
                  props.handleSettings({ align: "left" });
                }}
              >
                <FaAlignLeft />
              </button>
              <button
                className={getActiveClass("center")}
                onClick={() => {
                  setAllignment("center");
                  props.handleSettings({ align: "center" });
                }}
              >
                <FaAlignCenter />
              </button>
              <button
                className={getActiveClass("right")}
                onClick={() => {
                  setAllignment("right");
                  props.handleSettings({ align: "right" });
                }}
              >
                <FaAlignRight />
              </button>
            </div>
          </div>
        </div>

        <div className="d-md-flex">
          <div className="form-group">
            <div className="d-flex">
              <label className="mr-2">Background:</label>
              <Help
                description={getHelpDescription({ colorLabel: "background" })}
              />
            </div>
            <input
              onChange={e =>
                props.handleSettings({ background: e.target.value })
              }
              type="text"
              value={props.settings.background}
              className="form-control mr-2"
            />
          </div>
          <div className="form-group ml-md-2">
            <div className="d-flex">
              <label className="mr-2">Font Color:</label>
              <Help description={getHelpDescription({ colorLabel: "font" })} />
            </div>
            <input
              onChange={e =>
                props.handleSettings({ fontColor: e.target.value })
              }
              type="text"
              value={props.settings.color}
              className="form-control mr-2"
            />
          </div>
          <div className="form-group ml-md-2 mb-0 mb-md-2">
            <div className="d-flex">
              <label className="mr-2">Button Color</label>
              <Help
                description={getHelpDescription({
                  colorLabel: "buttonBackground"
                })}
              />
            </div>
            <input
              onChange={e => props.handleSettings({ linkBg: e.target.value })}
              type="text"
              value={props.settings.linkBg}
              className="form-control mr-2"
            />
          </div>
        </div>
        <hr className="light d-none d-md-block" />
      </>
    );
  };
  return (
    <div className="temp-controls d-flex justify-content-between align-items-end w-100 mb-3">
      <div className="d-flex flex-grow-1 justify-content-center">
        <CustomButton
          btnValue="Preview"
          color="black"
          onClick={() => {
            setShowPreviewEmailDialog(true);
          }}
          className="btn mr-2"
        />
        <CustomButton
          btnValue="Test Email"
          color="black"
          onClick={() => {
            setShowTestEmailDialog(true);
          }}
          className="btn"
        />
      </div>
      <div>
        <button
          title="Settings"
          className="btn btn-icon"
          onClick={() => {
            setShowTemplateSettingsDialog(true);
          }}
        >
          <FaCog />
        </button>
      </div>
      {showPreviewEmailDialog && (
        <Dialog
          backdrop={true}
          size="email-preview"
          show={showPreviewEmailDialog}
          onHide={() => {
            setShowPreviewEmailDialog(false);
          }}
          noCentered={true}
          noHeader={true}
          body={previewEmailDailogBody()}
        />
      )}
      <Dialog
        size="sm"
        show={showTestEmailDialog}
        onHide={closeTestEmail}
        title="Test Email"
        body={testEmailDailogContent()}
        click={() => {
          props.save(
            renderToStaticMarkup(props.emailContent(props.templateSettings)),
            props.emailSubject,
            emailD
          );
          setEmailD("");
          setShowTestEmailDialog(false);
        }}
        clickname="Send Email"
        closename="Cancel"
      />
      <Dialog
        size="md"
        show={showTemplateSettingsDialog}
        onHide={() => setShowTemplateSettingsDialog(false)}
        title="Template Settings"
        body={emailBodySettingsDailogContent()}
        click={() => {
          setShowTemplateSettingsDialog(false);
          props.handleSave();
        }}
        clickname="Apply"
      />
    </div>
  );
};
