import gql from "graphql-tag";

const GET_PROSPECTS_FOR_ALL_PROSPECTS = gql`
  query getProspectsForAllProspects(
    $limit: Int
    $pageNum: Int
    $filter: ProspectFilter
    $search: String
    $sort: [String]
  ) {
    getProspects(
      limit: $limit
      pageNum: $pageNum
      filter: $filter
      search: $search
      sort: $sort
    ) {
      Prospects {
        id
        customer_id
        first_name_1
        last_name_1
        first_name_2
        last_name_2
        primary_email_1
        primary_email_2
        secondary_emails_1
        secondary_emails_2
        primary_email_1_status
        primary_email_2_status
        cell_phone_1
        cell_phone_2
        next_step
        red_flag {
          description
        }
        division{
          id
          name
        }
        all_communities {
          id
          name
          is_active
        }
        community {
          id
          name
          is_active
        }
        rank {
          id
          name
        }
        csm {
          id
          first_name
          last_name
          is_active
        }
        realtor{
          id
          first_name
          last_name
        }
        state {
          id
          name
        }
        others {
          id
          name
        }
        customer_origination_area {
          id
          name
        }
        motivation_uncovered
        reservation
        video_text
        created_at
        updated_at
        street_address
        zip
        city
        last_activity
        last_activity_datetime
      }
      total_count
    }
  }
`;
export default GET_PROSPECTS_FOR_ALL_PROSPECTS;
