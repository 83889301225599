import gql from 'graphql-tag';

const ADD_REALTOR = gql`
  mutation addRealtor($input: RealtorInput!) {
    addRealtor(input: $input) {
      message
      code
    }
  }
`;

export default ADD_REALTOR;