import React from 'react'
import { compose, graphql } from "react-apollo";
import DateRange from "../../components/date-range-picker";
import { GET_DATE_RANGE } from "../../cacheql/queries";
import { SELECT_DATE_RANGE } from "../../cacheql/mutations";

/**
 * This container makes a query to client store to get the selected date range and renders the {@link DateRange} 
 * component by passing down start, end date and a callback function to set the new values to the client store.
 * @param {Object} props 
 * @param {Object} props.data selected date range info
 * @param {Function} props.selectDateRange to set new selected date range
 */
const DateRangeContainer = ({ data: { selectedDateRange }, ...props }) => {

  const setStore = (startDate, endDate) => {
    props.selectDateRange({
      variables: {
        startDate: startDate?.toISOString() ?? null,
        endDate: endDate?.toISOString() ?? null
      }
    })
  }

  return (
    <DateRange
      startDate={selectedDateRange.startDate && new Date(selectedDateRange.startDate)}
      endDate={selectedDateRange.endDate && new Date(selectedDateRange.endDate)}
      onChange={setStore}
    />
  )
}

export default compose(
  graphql(GET_DATE_RANGE),
  graphql(SELECT_DATE_RANGE, { name: "selectDateRange" }),
)(DateRangeContainer);