import React from "react";
import ManageAutoConvert from "./manage-auto-convert";
import ManageAutoReply from "./manage-auto-reply";

/**
 * This component is used to render the Web Form Reply Email Association section in Customizations tab. It contains two components, one is the manage auto-reply selector and the second is the manage auto-convert selector.
 */
const EmailReplyAssociations = ({ ...props }) => {
  return (
    <>
      <div className="col-padding col-12 other-customization-root">
        <div className="customization-box auto-reply-customization">
          <ManageAutoReply />
        </div>
      </div>
      <div className="customization-box col-md-10 col-lg-8 auto-reply-customization">
        <ManageAutoConvert />
      </div>
    </>
  );
};

export default EmailReplyAssociations;
