import React, { useEffect, useMemo, useContext, useState } from "react";
import { Dropdown } from "react-bootstrap";
import _ from "lodash";
import Dialog from "./../../../components/dialog/dialog";
import { FaEnvelope, FaMailBulk } from "react-icons/fa";

import TableView, {
  SortableHeader,
  stringComparator,
  TextCell,
  TextHeader,
  TextCellWithMapper,
  EmailCell,
  PhoneCell,
  betweenDates,
  dateComparator,
  buyerTwoIconCellObject,
  DetailLinkCell,
  boolComparator,
  CheckboxCell
} from "../../../components/new-table";
import { DashboardContext } from "../../../context";
import FadeIn from "../../../components/fade-in";
import CustomLoader from "../../../components/custom-loader";
import routeConstants from "../../layout/routes";
import HomeOwnerFilter from "../forms/homeowner-filters";
import {
  formatDate,
  getUpdatedParams,
  getCardParams,
  phoneDisplay,
  isBuyerTwo,
  getSubscribedEmails,
  getAllEmails
} from "../../../utils/helpers";
import reducer, { getInitialState } from "../dashboard/homeowner-reducer";
import EditHomeownerView from "./edit-homeowner-view";
import SendMassEmail from "../../../components/dialog/send-email"

/** @module HomeOwnerModule */

/**
 * This component is a table used to display all the homeowners. It contains pagination, searchbar, filtering, 
 * mass-update and export to csv options. It has no hover-actions. It shows multiple columns displaying basic 
 * information related to homeowner. Table uses reducer to maintain its state. It also accesses store to check if 
 * table parameters are already stored or not.
 * 
 * @param {Object} props it contains all the mutations/queries in the conatiner as props
 * @param {Object} props.data it contains array of homeowners to be shown on table
 * @param {Object} props.getTableParams it contains cards info stored inside cache
 * @returns {JSX.Element} It returns jsx containing the table 
 */
const HomeOwner = ({
  data,
  selectedCommunity,
  selectedDivision,
  getTableParams: { tableParams },
  ...props
}) => {
  const [massEmailSubmission, setMassEmailSubmission] = useState(false)
  const { currentCard } = useContext(DashboardContext);
  const cacheParams = getCardParams(currentCard.title, tableParams);
  const [state, dispatch] = React.useReducer(
    reducer,
    getInitialState({ loading: data.loading, cache: cacheParams })
  );

  const {
    render,
    userPageCount,
    pageSize,
    homeOwners,
    loading,
    editItemId,
    showMassEmailModal,
    homeownerEmails,
    isMassEmailSubmitted
  } = state;

  const [showEdit, setShowEdit] = useState(false);

  /** Refetch table data on any update like create, edit, delete */
  useEffect(() => {
    if (props.updated !== null) {
      data.refetch();
    }
  }, [props.updated]);

  useEffect(() => {
    if (!cacheParams) {
      dispatch({ type: "RESET", payload: { render: false } });
    } else {
      dispatch({
        type: "UPDATE",
        payload: { ...cacheParams, pageSize: cacheParams.limit, render: false }
      });
    }
  }, [props.currentCard.title]);

  /**
   * Either set the data in state or set the loading to inform the table what to render.
   */
  useEffect(() => {
    if (
      !data.loading &&
      data.getAllHomeOwners &&
      data.getAllHomeOwners.Home_Owner
    ) {
      dispatch({
        type: "UPDATE",
        payload: {
          render: true,
          homeOwners: data.getAllHomeOwners.Home_Owner,
          loading: false,
          userPageCount: Math.ceil(data.getAllHomeOwners.total_count / pageSize)
        }
      });
    }
    if (data.loading) {
      dispatch({ type: "UPDATE", payload: { loading: data.loading } });
    }
  }, [data]);

  const onSaveFilter = message => {
    props.setNotificationProps({ variables: { open: true, message: message } });
  };

  const ehancedFilter = (onSaveFilter, ...rest) => Component => {
    const WrappedComponent = props => {
      return <Component {...props} onSaveFilter={onSaveFilter} {...rest} />;
    };
    return WrappedComponent;
  };

  /**
    * This function hanldes the dialog for sending mass emails
    * 
    * @param {Array} homeownerEmails Homeowner IDs
    */
  const handleMassEmail = (homeownerEmails) => {
    dispatch({
      type: "UPDATE",
      payload: {
        showMassEmailModal: !showMassEmailModal,
        homeownerEmails
      },
    });
  }

  /**
   * This function handles the submitted state for when mass
   * emails are sent
   */
  const handleMassEmailSubmitted = () => {
    dispatch({
      type: "UPDATE",
      payload: {
        isMassEmailSubmitted: !isMassEmailSubmitted,
        showMassEmailModal: !showMassEmailModal
      },
    });
  };

  const onMassEmailSuccess = (message) => {
    props.setNotificationProps({
      variables: {
        open: true,
        message: message,
      },
    });
  }

  /**
   * This function is used by the Dialog as well as the send-email component.
   * It sets the value true when the Dialog is submitted and is resetted to
   * false in the send-email component. 
   * 
   * @param {Boolean} state Changes the state of massEmailSubmission 
   */
  const checkMassEmailSubmission = (state) => {
    setMassEmailSubmission(state)
  }

  /**
   * This function is responsible to generate the entire content
   * of the Dialog. It renders the 'SendMassEmail' component
   * with an array of emails of selected Leads and a Boolean variable
   * to check the form submission state.
   * 
   * @param {Array} emails Homeowner Emails
   * @param {Boolean} checkSubmission Is form submitted
   * @param {Function} onFormSubmit Updated the states when form submitted successfully
   * @param {Function} onSubmitNotification Notifies user of the email submission
   * @param {Funciton} handleCheckSubmission Updates the status of checkSubmission  
   * @returns 
   */
  const sendMassEmailBody = (
    homeOwnerEmails
  ) => {
    return (
      homeOwnerEmails && (
        <SendMassEmail
          emails={homeOwnerEmails.split("%3B%20")}
          checkSubmission={massEmailSubmission}
          onFormSubmit={handleMassEmailSubmitted}
          onSubmitNotification={onMassEmailSuccess}
          handleCheckSubmission={checkMassEmailSubmission}
        />
      )
    )
  }

  /**
   * It is a callback function which is called on any table change like: pagination, page-size, filters, search term.
   * Table passes its current state so that we can update the table parameters in store and state, which are used in 
   * refetching the data with correct parameters.  
   * 
   * @function
   * @inner
   * @memberof module:HomeOwnerModule
   * @see {@link module:HomeOwnerModule~HomeOwner}
   */
  const fetchData = React.useCallback(tableProps => {
    if (loading) {
      return;
    }

    //variables to be stored in cache/store
    const variables = {
      filter: {
        ...tableProps.filter
      },
      limit: tableProps.pageSize,
      pageNum: tableProps.currentPage,
      search: tableProps.searchTerm,
      order: tableProps.sortColumn ? tableProps.sortColumn : state.order
    };
    const pageCount = Math.ceil(
      data.getAllHomeOwners.total_count / tableProps.pageSize
    );

    // if search term or filter is changed, bring the page to 0
    if (
      tableProps.searchTerm !== state.search ||
      !_.isEqual(variables.filter, state.filter)
    ) {
      variables.pageNum = 0;
    }
    if (tableProps.currentPage > pageCount - 1) {
      variables.pageNum = pageCount ? pageCount - 1 : 0;
    }

    // update the state
    dispatch({ type: "UPDATE", payload: { ...variables, pageSize: tableProps.pageSize, loading: true } });
    // update total page count in the state
    if (pageCount !== userPageCount) {
      dispatch({
        type: "UPDATE",
        payload: { userPageCount: pageCount }
      });
    }

    // update the store
    props
      .setTableParams({
        variables: {
          cardsParams: getUpdatedParams(tableParams.cardsParams, {
            cardTitle: currentCard.title,
            params: variables
          })
        }
      })
      .then(res => {
        dispatch({ type: "UPDATE", payload: { loading: false } });
      });
  });

  /**
   *  This defines columns of the table. It has multiple columns, displaying information of the homeowners. Most of
   * the columns are sortable. Clicking the first name column opens the details page of homeowner. Buyer two icon 
   * shows up as the first column if the hoemowner has buyer 2 data as well.

   * @constant
   * @memberof module:HomeOwnerModule~HomeOwner
   */
  const columns = useMemo(
    () => [
      {
        id: "0",
        header: "",
        accessor: "",
        component: TextHeader,
        cell: buyerTwoIconCellObject
      },
      {
        id: "1",
        header: "First Name",
        accessor: "first_name_1",
        component: SortableHeader,
        comparator: stringComparator,
        cell: {
          component: DetailLinkCell,
          path: (field, accessor) => routeConstants.HOME_OWNER_DETAIL
        }
      },
      {
        id: "2",
        header: "Last Name",
        accessor: "last_name_1",
        component: SortableHeader,
        comparator: stringComparator,
        cell: { component: TextCell }
      },
      {
        id: "3",
        header: "Community",
        accessor: "community",
        component: TextHeader,
        sortable: false,
        csvMapper: (field, accessor) => field[accessor].name,
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) => field[accessor].name,
          check: true
        }
      },
      {
        id: "4",
        header: "Lot",
        accessor: "lot",
        component: TextHeader,
        sortable: false,
        cell: { component: TextCell }
      },
      {
        id: "5",
        header: "Email(s)",
        accessor: "primary_email_1",
        component: SortableHeader,
        comparator: stringComparator,
        cell: { component: EmailCell, check: "primary_email_1_status", callback: getAllEmails }
      },
      {
        id: "6",
        header: "Mailchimp Subscription",
        accessor: "primary_email_1_status",
        hidden: true,
        csvMapper: (field, accessor) => field[accessor] ? "Subscribed" : "Unsubscribed",
        cell: { component: null },
      },
      {
        id: "7",
        header: "Phone",
        accessor: "cell_phone_1",
        component: TextHeader,
        sortable: false,
        csvMapper: (field, accessor) => phoneDisplay(field[accessor]) || "",
        cell: { component: PhoneCell }
      },
      {
        id: "8",
        header: "First Name 2",
        accessor: "first_name_2",
        hidden: true,
        cell: { component: null },
      },
      {
        id: "9",
        header: "Last Name 2",
        accessor: "last_name_2",
        hidden: true,
        cell: { component: null },
      },
      {
        id: "10",
        header: "Primary Email 2",
        accessor: "primary_email_2",
        hidden: true,
        cell: { component: null },
      },
      {
        id: "11",
        header: "Mailchimp 2 Subscription",
        accessor: "primary_email_2_status",
        hidden: true,
        csvMapper: (field, accessor) => isBuyerTwo(field) ? field[accessor] ? "Subscribed" : "Unsubscribed" : "",
        cell: { component: null },
      },
      {
        id: "12",
        header: "Phone 2",
        accessor: "cell_phone_2",
        hidden: true,
        csvMapper: (field, accessor) => phoneDisplay(field[accessor]) || "",
        cell: { component: null },
      },
      {
        id: "13",
        header: "Date Purchased",
        accessor: "purchased_date",
        component: SortableHeader,
        comparator: dateComparator,
        filter: betweenDates,
        csvMapper: (field, accessor) =>
          field[accessor] ? formatDate(field[accessor]) : "",
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) => formatDate(field[accessor])
        }
      },
      {
        id: "14",
        header: "Date Settled",
        accessor: "settled_date",
        component: SortableHeader,
        comparator: dateComparator,
        filter: betweenDates,
        csvMapper: (field, accessor) =>
          field[accessor] ? formatDate(field[accessor]) : "",
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) => formatDate(field[accessor])
        }
      },
      {
        id: "15",
        header: "Salesperson",
        accessor: "salesperson",
        component: TextHeader,
        sortable: false,
        csvMapper: (field, accessor) =>
          field[accessor]
            ? field[accessor].first_name + " " + field[accessor].last_name
            : "",
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) =>
            field[accessor] &&
            field[accessor].first_name + " " + field[accessor].last_name,
        },
      },
      {
        id: "16",
        header: "Brand Ambassador",
        accessor: "brand_ambassador",
        component: SortableHeader,
        csvMapper: (field, accessor) => (field[accessor] ? true : false),
        comparator: boolComparator,
        cell: { component: CheckboxCell }
      }
    ],
    []
  );

  const hoverActions = useMemo(
    () => [],
    []
  );

  /**
   * This is an object that defines table title, and massActions and filters for the table. It contains only one 
   * mass action i.e. send email.
   * 
   * @constant
   * @memberof module:HomeOwnerModule~HomeOwner
   */
  const tableOptions = {
    title: currentCard.title,
    massActions: {
      render: getSelectedRows => {
        let emails = getSubscribedEmails(getSelectedRows());
        let mailTo = "mailto:?bcc=" + emails;

        return (
          <>
            <div style={{ display: "none" }}>
              <a href={mailTo} id="send-homeowner-email">{""}</a>
            </div>
            <Dropdown.Item
              eventKey="1"
              id="dropdown-1"
              // onClick={() => handleMassEmail(emails)} // mass email using SendGrid (currently disabled)
              onClick={() => document.getElementById("send-homeowner-email").click()} // mass email using Outlook
            >
              {" "}
              <FaEnvelope id="drop-down-icn-3" /> Send Email BCC
            </Dropdown.Item>
          </>
        );
      }
    },
    FilterItems: ehancedFilter(onSaveFilter)(HomeOwnerFilter)
  };

  return (
    <div>
      <Dialog
        show={showMassEmailModal}
        onHide={() =>
          dispatch({
            type: "UPDATE",
            payload: { showMassEmailModal: !showMassEmailModal },
          })
        }
        title="Send Email To"
        body={sendMassEmailBody(
          homeownerEmails
        )}
        click={() => checkMassEmailSubmission(true)}
        clickname="Send Email"
        closename="Cancel"
        size="lg"
      />

      <FadeIn show={render}>
        <TableView
          columns={columns}
          data={homeOwners}
          tableOptions={tableOptions}
          hoverActions={hoverActions}
          loading={loading}
          userPageCount={userPageCount}
          userTotalCount={
            !loading && data.getAllHomeOwners
              ? data.getAllHomeOwners.total_count
              : null
          }
          fetchData={fetchData}
          currentPage={state.pageNum}
          pageSize={state.pageSize}
          filter={state.filter}
          searchTerm={state.search}
          sortColumn={state.order}
          controlled={true}
        />
      </FadeIn>
      {!render && <CustomLoader />}
      {showEdit && (
        <EditHomeownerView
          id={editItemId}
          submitHandler={() => {
            setShowEdit(false);
            props.update(true);
          }}
          close={() => setShowEdit(false)}
        />
      )}
    </div>
  );
};

export default HomeOwner;
