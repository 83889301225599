import gql from "graphql-tag";

const DELETE_REALTOR_REGISTRATION = gql`
  mutation deleteRealtorRegistration($realtor_registration_id: Int!) {
    deleteRealtorRegistration(realtor_registration_id: $realtor_registration_id){
        success
        message
        code
    }
  }
`;

export default DELETE_REALTOR_REGISTRATION
