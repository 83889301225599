import gql from 'graphql-tag';

const MERGE_REALTOR = gql`
  mutation mergeRealtors($ids: [ID!], $realtor_data: RealtorInput!) {
    mergeRealtors(ids: $ids, input: $realtor_data) {
      message
      code
    }
  }  
`;
export default MERGE_REALTOR