import gql from "graphql-tag";

const GET_PROSPECT_DETAIL = gql`
  query getProspectsById($input: ProspectFilter) {
    getProspects(filter: $input) {
      Prospects {
        id
        customer_id
        first_name_1
        last_name_1
        primary_email_1
        cell_phone_1
        secondary_emails_1
        first_name_2
        last_name_2
        primary_email_2
        cell_phone_2
        secondary_emails_2
        primary_email_1_status
        primary_email_2_status
        motivation_uncovered
        motivation_what_changed
        current_dissatisfaction
        future_promise
        cost_feat_objections
        other_notes
        next_step
        zip
        city
        osc_notes
        form_submissions{
          id
          interested_in
          other_data
          response_phone
          response_sms
          response_email
          created_at
          updated_at
          responded_at
          response_medium
          domain
          url
          lot
          form_type
          division {
            id
            name
          }
          community{
            name
            is_active
          }
          url
          responder{
            first_name
            last_name
          }
        }
        customer_origination_area {
          id
          name
        }
        division{
          id
          name
        }
        state {
          id
          name
        }
        street_address
        realtor{
          id
          first_name
          last_name
          primary_email
          cell_phone
          primary_email_status
        }
        red_flag {
          description
        }
        all_communities {
          id
          name
          is_active
        }
        community {
          id
          name
          division_id
          is_active
        }
        rank {
          id
          name
        }
        home_sites {
          id
          name
        }
        floor_plans {
          id
          name
        }
        others {
          id
          name
        }
        csm {
          id
          first_name
          last_name
          is_active
        }
        notes {
          id
          description
          createdAt
          updatedAt
          creator {
            id
            first_name
            last_name
          }
          note_attachment {
            id
            file_path
            createdAt
            updatedAt
          }
        }
        phone_calls {
          phone_call_id
          session_id
          call_details
          call_duration
          call_datetime
          attachments {
            id
            name
            type
            download_url
           }
          call_type {
            id
            name
          }
          creator{
            first_name
            last_name
          }
          recording
        }
        appointments {
          id
          title
          details
          done
          tentative
          tentative_date
          send_email
          zoom_meeting_link
          createdAt
          creator {
            first_name
            last_name
          }
          csm {
            id
            first_name
            last_name
            is_active
          }
          Appointment_Type{
            id
            name
          }
          start_datetime
          end_datetime
        }
        follow_ups {
          id
          title
          details
          done
          createdAt
          creator {
            first_name
            last_name
          }
          csm {
            id
            first_name
            last_name
            is_active
          }
          follow_up_datetime
        }
        walk_ins {
          id
          title
          details
          createdAt
          creator {
            first_name
            last_name
          }
          csm {
            id
            first_name
            last_name
            is_active
          }
        }
        mailchimp_emails {
          mailchimp_email_id
          datetime
          subject
          email_addresses
          email_from
          content_url
          count
        }
        emails{
          id
          subject
          body
          body_preview
          date_time
          hasAttachments
          isIndividual
          email_type
          to_recipients
          cc_recipients
          bcc_recipients
          csms{
            id
            first_name
            last_name
            email_address
            cell_phone
            role
            createdAt
            updatedAt
            is_active
          }
        }
        reservation
        video_text
        created_at
        updated_at
      }
      total_count
    }
  }
`;

export default GET_PROSPECT_DETAIL;
