import gql from "graphql-tag";

const UPDATE_FORM_SUBMISSION_STATUS = gql`
  mutation updateFormSubmissionStatus($formSubmission_id: [Int!], $status_id: Int!) {
    updateFormSubmissionStatus(formSubmission_id: $formSubmission_id, status_id: $status_id) {
      message
      code
    }
  }
`;

export default UPDATE_FORM_SUBMISSION_STATUS;
