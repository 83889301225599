import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { compose, graphql } from "react-apollo";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";

import {
  ADD_HOMESITE,
  UPDATE_HOMESITE_AVAILABLE,
  UPDATE_HOMESITE_UNAVAILABLE
} from "../../graphql/mutations";
import OtherCustomizationCard from "../../components/customziation/other-customization-card";
import CustomizationOptionBox from "../../components/customziation/customizations-option-box";
import CustomButton from "../../components/custom-button";
import { isNotNULL } from "../../utils/helpers";

/**
 * this component is used to render the homesites section in the other customizations tab. its used to add homesites, make homesites available/unavailable.
 * @param {*} param0 
 */
const Homesites = ({ ...props }) => {
  const [newHomesiteValue, setNewHomesiteValue] = useState("");
  const [
    selectedUnavailableHomesite,
    setSelectedUnavailableHomesite
  ] = useState([]);
  const [selectedAvailableHomesite, setSelectedAvailableHomesite] = useState(
    []
  );
  const defaultField = [
    {
      mutation: "updateUnavailableHomesite",
      resMutation: "makeHomeSitesUnavailable",
      selctedId: selectedAvailableHomesite,
      callback: setSelectedAvailableHomesite
    },
    {
      mutation: "updateAvailableHomesite",
      resMutation: "makeHomeSitesAvailable",
      selctedId: selectedUnavailableHomesite,
      callback: setSelectedUnavailableHomesite
    }
  ];

  useEffect(() => {
    setSelectedAvailableHomesite([]);
    setSelectedUnavailableHomesite([]);
  }, [props.communityId]);

  const handleSelection = (val, doubleClick, field) => {
    field.callback(val);
    if (doubleClick) {
      handleUpdate(field);
    }
  };

  const handleChange = value => {
    setNewHomesiteValue(value);
  };

  const handleAddClick = () => {
    props
      .addHomesite({
        variables: {
          input: {
            name: newHomesiteValue
          }
        }
      })
      .then(res => {
        if (res.data.addHomeSite.code === 200) {
          handleChange("");
          props.update(null, res.data.addHomeSite.message);
        }
      });
  };

  const handleUpdate = field => {
    if (!isNotNULL(field.selctedId) && field.selctedId !== 0) return;
    props[field.mutation]({
      variables: {
        input: {
          community_id: props.communityId,
          home_site_ids: field.selctedId
        }
      }
    }).then(res => {
      if (res.data[field.resMutation].code === 200) {
        field.callback([]);
        props.update(null, res.data[field.resMutation].message);
      }
    });
  };

  return (
    <OtherCustomizationCard
      cardTitle="Homesites"
      firstCardTitle="All Homesites"
      firstCardView={
        <CustomizationOptionBox
          selected={selectedUnavailableHomesite}
          list={props.unavailable}
          handleSelection={(val, e) => handleSelection(val, e, defaultField[1])}
        />
      }
      addNewView={{
        children: (
          <>
            <input
              type="text"
              value={newHomesiteValue}
              disabled={props.disabled}
              className="form-control"
              placeholder="New Homesite"
              onChange={e => handleChange(e.target.value)}
            />
            <CustomButton
              color="green"
              btnValue="Add"
              onClick={() => handleAddClick()}
              disabled={isNotNULL(newHomesiteValue) ? props.disabled : true}
            />
          </>
        )
      }}
      moveToFirstCardView={
        <CustomButton
          color="black"
          btnIcon={<FaChevronLeft />}
          className="mb-1 border-0 custom-btn-arrows arrow-up"
          onClick={() => handleUpdate(defaultField[0])}
          disabled={selectedAvailableHomesite.length > 0 ? false : true}
        />
      }
      moveToSecondCardView={
        <CustomButton
          color="black"
          btnIcon={<FaChevronRight />}
          className="border-0 custom-btn-arrows arrow-down"
          onClick={() => handleUpdate(defaultField[1])}
          disabled={selectedUnavailableHomesite.length > 0 ? false : true}
        />
      }
      secondCardTitle="Available Homesites"
      secondCardView={
        <CustomizationOptionBox
          leftBox
          selected={selectedAvailableHomesite}
          list={props.available}
          handleSelection={(val, e) => handleSelection(val, e, defaultField[0])}
        />
      }
    />
  );
};

Homesites.propTypes = {
  communityId: PropTypes.number,
  update: PropTypes.func,
  disbaled: PropTypes.bool,
  available: PropTypes.array,
  unavailable: PropTypes.array
};

export default compose(
  graphql(ADD_HOMESITE, { name: "addHomesite" }),
  graphql(UPDATE_HOMESITE_AVAILABLE, { name: "updateAvailableHomesite" }),
  graphql(UPDATE_HOMESITE_UNAVAILABLE, { name: "updateUnavailableHomesite" })
)(Homesites);
