import gql from 'graphql-tag';

const UPDATE_HOMEOWNER_FOLLOW_UP = gql` 
  mutation updateHomeownerFollowUp($input: HomeownerFollowUpUpdateInput!) {
    updateHomeownerFollowUp(input: $input) {
      message
      code
    }
  }
`;

export default UPDATE_HOMEOWNER_FOLLOW_UP;