import React from 'react';
import { Dropdown } from "react-bootstrap";
import { FaEnvelope } from "react-icons/fa";

/**
 * this component renders ther mass update of the all registrations, all realtors, YOY sales and registrations cards tables. it contains 1 action which is send email.
 * @param {Array} emails, selected rows emails 
 */
const RealtorsMassUpdateItems = ({ emails }) => {
  let mailTo = "mailto:?bcc=" + emails;
  return (
    <div >
      <div style={{ display: "none" }}>
        <a href={mailTo} id="send-realtor-email" >{""}</a>
      </div>
      <Dropdown.Item
        eventKey="1"
        id="dropdown-1"
        onClick={() => document.getElementById("send-realtor-email").click()} >
        <FaEnvelope id={"dropdown-icn-1"} /> Send Email
      </Dropdown.Item>
    </div>
  )
}

export { RealtorsMassUpdateItems };
