import gql from 'graphql-tag';

export default gql`
query getAgencies($limit: Int, $search: String, $pageNum: Int, $filter: agencyFilter, $sort: [String], $mode: AgenciesMode){
    getAgencies(limit: $limit, search: $search, pageNum: $pageNum, filter: $filter, sort: $sort, mode: $mode){
        agencies {
        name
        address
        city
        state{
            id
            name
        }
        zip
        registration_count
        sales_count
      }
      total_count
    }
  }
`

export const GET_SALES_BY_AGENCIES_COUNT = gql`
  query getSalesByAgenciesCount($input: reportFilter) {
    getSalesByAgenciesCount(filter: $input){
      count
      percent
    }
  }
`;

export const GET_REGISTRATIONS_BY_AGENCIES_COUNT = gql`
  query getRegistrationsByAgenciesCount($input: reportFilter) {
    getRegistrationsByAgenciesCount(filter: $input)
  }
`;

export const GET_AGENCY_SALES_BY_COMMUNITY_COUNT = gql`
  query getAgenciesSalesByCommunityCount($input: reportFilter) {
    getAgenciesSalesByCommunityCount(filter: $input)
  }
`;


export const GET_AGENCIES_COUNT = gql`
query getAgencies($input: agencyFilter){
    getAgencies(filter: $input){
      total_count
    }
  }
`
