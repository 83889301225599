import React, { useEffect, useState, useRef } from "react"
import { goBack } from "../../../utils/helpers"
import { routes } from "../../index"
import { ButtonToolbar, Dropdown, DropdownButton } from "react-bootstrap";
import { SelectBar } from "../../../containers/"
import { LEAD, PROSPECT, MARKETING_ONLY, REALTOR } from "../../../utils/constants"
import { FaChevronLeft, FaFilter, FaStar } from "react-icons/fa"
import CustomButton from "../../../components/custom-button"
import Header from "../../../components/dashboard-title"
import MarketingOnlyConvertView from "../dashboard/marketing-only-convert-view"
import DetailsDeleteButton from "../../common-lead-prospect-details/details-delete-button-container"
import "./marketing-only-details.css"
import ConvertRealtorView from "../../web-form/convert-realtor-form";

/**
 * this component is used to render the marketing only details select bar which contains the name and communitites of the marketing only and the convert to lead/prospect buttons.
 * @param {object} props component props contains all the necessary including the mutations.
 * @param {object} props.history contains information about the path, to be used in go back button
 * @param {object} props.data contains all the informations related to the marketing only
 * @param {number} props.id marketing only id
//  * @param {object} dataField 
 */
const MarketingOnlyDetailSelectBar = ({ ...props }) => {
  const { history } = props;

  const [
    showMarketingOnlyConvertDrawer,
    setShowMarketingOnlyConvertDrawer
  ] = useState({
    show: false,
    type: ""
  })
  const [showConvertRealtor, setShowConvertRealtor] = useState(false);

  const selectBarComp = useRef(null)
  const focusComp = () => selectBarComp.current.scrollIntoView({ block: "end", behavior: "smooth" })
  const notify = (message) => props.setNotificationProps({ variables: { open: true, message: message } })

  const toggleMarketingOnlyToLeadDrawer = () =>
    setShowMarketingOnlyConvertDrawer((prevState) => ({ show: !prevState.show, type: LEAD }))

  const toggleMarketingOnlyToProspectDrawer = () =>
    setShowMarketingOnlyConvertDrawer((prevState) => ({ show: !prevState.show, type: PROSPECT }))

  const toggleMarketingOnlyToRealtorDrawer = () =>
    setShowConvertRealtor((prevState) => ({ show: !prevState.show, type: REALTOR }))

  const onConvertSubmit = (message) => {
    if (showMarketingOnlyConvertDrawer.type === LEAD) {
      toggleMarketingOnlyToLeadDrawer()
      history.push({
        pathname: routes.LEAD_DASHBOARD,
        showMarketingOnlyConvertNotification: true,
        MarketingOnlyConvertMessage: "Marketing Only customer(s) converted successfully!",
      })
    } else {
      toggleMarketingOnlyToProspectDrawer()
      history.push({
        pathname: routes.PROSPECT_DASHBOARD,
        showMarketingOnlyConvertNotification: true,
        MarketingOnlyConvertMessage: "Marketing Only customer(s) converted successfully!",
      })
    }

    notify(message)
  }

  const onSubmit = (message, path) => {
    history.push({ pathname: path });
    notify(message);
  }

  useEffect(() => {
    focusComp()
  }, [selectBarComp])

  return (
    <SelectBar>
      <div
        ref={selectBarComp}
        className="d-flex justify-content-between align-items-center"
      >
        <CustomButton
          onClick={() => goBack(history)}
          id="back-to-dashboard-btn"
          color="dark"
          btnIcon={<FaChevronLeft />}
          className="btn-back"
        />
        <Header
          title={MARKETING_ONLY}
          community={props.community || ""}
          subtitle={props.name || ""}
        />
      </div>
      <div
        className="col-padding col-12 btn-container"
        id="marketing-only-dashboard-btn"
      >
        <ButtonToolbar>
          <DropdownButton
            alignRight
            drop="down"
            key="1"
            id="dropdown-item-button"
            title="CONVERT"
            className="past-activity-dropdown"
          >
            <Dropdown.Item
              onSelect={() => toggleMarketingOnlyToProspectDrawer()}
              key={0}
            >
              Prospect
            </Dropdown.Item>
            <Dropdown.Item
              onSelect={() => toggleMarketingOnlyToLeadDrawer()}
              key={1}
            >
              Lead
            </Dropdown.Item>
            <Dropdown.Item
              onSelect={() => toggleMarketingOnlyToRealtorDrawer()}
              key={2}
            >
              Realtor
            </Dropdown.Item>
          </DropdownButton>
          <DetailsDeleteButton
            type={MARKETING_ONLY}
            itemID={props.id}
            customerID={props.data && props.data.customer_id}
            color="green"
            history={history}
          />
        </ButtonToolbar>
        {showMarketingOnlyConvertDrawer.show &&
          <MarketingOnlyConvertView
            show={showMarketingOnlyConvertDrawer.show}
            id={props.id}
            onSubmit={onConvertSubmit}
            marketingOnlyData={props.data}
            type={showMarketingOnlyConvertDrawer.type}
            close={() => setShowMarketingOnlyConvertDrawer({ show: false, type: "" })}
          />
        }
        <ConvertRealtorView
          showSideBar={showConvertRealtor}
          toggleShowDrawer={() => setShowConvertRealtor(false)}
          id={props.id}
          data={props.data}
          onSubmit={(message) => onSubmit(message, routes.REALTOR_DASHBOARD)}
        />
      </div>
    </SelectBar>
  )
}

export default MarketingOnlyDetailSelectBar