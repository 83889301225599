import React from "react";
import PropTypes from "prop-types";
import { ListGroup } from "react-bootstrap";

/**
 * This is a stateless controlled component, which renders a box with a list of items, onClick and onDoubleClick operations are performed defined by the parent component as callback functions. On single click on an item, selection is made.
 * @param {Object} props
 * @param {Array} props.selected it contains ids of already selected items
 * @param {Boolean} props.leftBox identifies position of the box i.e. left or right
 * @param {Array} props.list an array of objects, which is used to list items inside the box
 * @param {Function} props.handleSelection callback function for click handlers
 * @param {Boolean} props.singleSelect Checks if only one item can be selected in the list at a time
 */
const CustomizationOptionBox = ({ ...props }) => {
  const handleSelection = (val, doubleClick) => {
    props.handleSelection(val, doubleClick);
  };

  let itemClasses;
  let selected = props.selected;
  let optionBoxStyles = props.leftBox
    ? "customizations-option-box box-height"
    : "customizations-option-box";
  let listGroupStyles = props.leftBox
    ? "options-group options-height"
    : "options-group";

  // this function checks if item is already in selected items
  const filterCheck = id => {
    return selected.length > 0 && selected.filter(val => val === id);
  };

  return (
    <div className={optionBoxStyles}>
      <ListGroup className={listGroupStyles}>
        {props.list instanceof Array &&
          props.list.map((item, key) => {
            itemClasses =
              filterCheck(item.id).length > 0
                ? "options-item selected"
                : "options-item";
            return (
              <ListGroup.Item
                className={itemClasses}
                onClick={() => {
                  if (filterCheck(item.id).length > 0) return;
                  handleSelection([...(!props.singleSelect ? selected : []), item.id]);
                }}
                onDoubleClick={() => handleSelection(item, true)}
                key={key}
              >
                {item.name}
              </ListGroup.Item>
            );
          })}
      </ListGroup>
    </div>
  );
};

CustomizationOptionBox.propTypes = {
  handleSelection: PropTypes.func,
  leftBox: PropTypes.bool,
  selected: PropTypes.array,
  list: PropTypes.array
};

export default CustomizationOptionBox;
