import React from "react";
import { getIn } from "formik";
import PropTypes from "prop-types";
import Select from "react-select";
import { DropdownIndicator } from "../new-table";
import "./fields.css";

/**
 * This is a select field which is able to select more than one value from a dropdown
 * @param {Object} props 
 * @param {Array} props.children these are list of options to be shown in the dropdown
 * @param {String} props.menuPosition position of the dropdown menu 
 * @param {Array} props.defaultValue pre-selected value
 * @param {Object} props.propsStyle custom style
 * @param {Boolean} props.disabled to disable select field
 */
const MultiSelectField = props => {
  const {
    id,
    label,
    field,
    form: { errors, setFieldValue },
    children,
    type,
    disabled,
    menuPosition,
    defaultValue,
    propsStyle
  } = props;

  const error = getIn(errors, field.name);
  let Children = [];
  children &&
    children.map(item =>
      Children.push({ value: item.id, label: item.name, color: "grey" })
    );

  //prepare default value just like how list of children are prepared
  let DefaultValue = defaultValue &&
    defaultValue.reduce((defaults, item) => {
      const label = children &&
        children.filter(listItem => listItem.id === item).find(item => item);
      if (label) {
        defaults.push({ value: label.id, label: label.name, color: "grey" });
      }
      return defaults;
    }, []);

  // set selected values to the field
  const myOnChange = data => {
    let fieldData = [];
    data && data.map(item => fieldData.push(item.value/* {id: item.value, name: item.label} */));
    setFieldValue(field.name, fieldData);
  };

  // custom styles to prettify multi-select field
  const customStyles = {
    control: (base, state) =>
      error
        ? {
          ...base,
          ...propsStyle,
          minHeight: '35px',
          background: "#121415",
          opacity: disabled ? "0.7" : '1',
          border: "1px solid #dc3545",
          display: "flex",
          borderRadius: "4px",
          boxShadow: null,
          "&:hover": {
            borderColor: "#dc3545"
          }
        }
        : {
          ...base,
          ...propsStyle,
          minHeight: '35px',
          background: "#121415",
          opacity: disabled ? "0.7" : '1',
          border: "1px solid #242527",
          display: "flex",
          borderRadius: "4px",
          boxShadow: null,
          "&:hover": {
            borderColor: "#242527"
          }
        },
    indicatorSeparator: () => ({
      display: 'none'
    }),
    multiValue: () => ({
      background: '#333333',
      display: 'flex',
      borderRadius: '3px',
      lineHeight: '1.4rem',
      padding: '0 0 0 0.3rem',
      margin: '0.1rem',
      height: '20px'
    }),
    multiValueLabel: () => ({
      color: '#ccc',
      fontSize: '12px'
    }),
    menu: base => ({
      ...base,
      // borderRadius: 0,
      marginTop: 0,
      top: menuPosition === 'top' ? "auto" : "",
      bottom: menuPosition === 'top' ? "100%" : "",
      position: "absolute",
      border: '1px solid #333',
      borderRadius: '5px'
    }),
    menuList: base => ({
      ...base,
      padding: 0
    }),
    option: base => ({
      ...base,
      background: "#111",
      '&:hover': {
        background: '#333',
        color: '#80b602'
      }
    })
  };
  return (
    <div tabIndex="0" name={field.name} id={id + "-form-group"} className="form-group div-error-focus">
      <label htmlFor={id + "-input"} className="control-label">
        {label}
      </label>
      <Select
        isMulti
        styles={customStyles}
        components={{ DropdownIndicator }}
        onChange={myOnChange}
        options={Children}
        value={DefaultValue}
        id={id}
        type={type}
        isDisabled={disabled}
      />
      {error && (
        <div style={{ display: "block" }} className="invalid-feedback">
          {error}
        </div>
      )}
    </div>
  );
};

MultiSelectField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  children: PropTypes.array
};

export default MultiSelectField;
