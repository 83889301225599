import * as Yup from "yup";
import * as errorMessages from "./form-errors";
import * as validations from "./common-validations";

export const leadForm = {
  first_name_1: "",
  last_name_1: "",
  primary_email_1: null,
  cell_phone_1: null,
  formatted_cell_phone_1: "",
  secondary_emails_1: [],
  hidden_secondary_emails_1: "",
  first_name_2: "",
  last_name_2: "",
  primary_email_2: null,
  cell_phone_2: null,
  formatted_cell_phone_2: "",
  secondary_emails_2: [],
  hidden_secondary_emails_2: "",
  city: "",
  realtor_id: undefined,
  zip: "",
  street_address: "",
  customer_origination_area_id: undefined,
  osc_notes: "",
  Lead: {
    divisions: [],
    communities: [],
    other_notes: null,
    quality_score: undefined,
    engagement_score: undefined,
  }
};

Yup.addMethod(Yup.mixed, 'stringInArray', function (ref, message) {
  const msg = message;
  return this.test('equalTo', msg, function (value) {
    let refValue = this.resolve(ref);
    return !refValue || !value || refValue.indexOf(value) === -1;
  });
});

//   })
// })

Yup.addMethod(Yup.mixed, 'compareStrings', function (ref, message) {
  const msg = message;
  return this.test('equalTo', msg, function (value) {
    let refValue = this.resolve(ref);
    return !refValue || !value || refValue !== value;
  });
});

//   })
// })

Yup.addMethod(Yup.mixed, 'validateCell', function (ref, message) {
  const msg = message;
  return this.test('equalTo', msg, function (value) {
    let refValue = this.resolve(ref);
    return (
      (refValue && /^[0-9]{10}$/.test(refValue)) ||
      refValue === null ||
      refValue === undefined ||
      refValue === ""
    );
  });
});

export const leadSchema = Yup.object().shape(
  {
    first_name_1: validations.name.required(errorMessages.REQUIRE_FIELD),
    last_name_1: validations.name.required(errorMessages.REQUIRE_FIELD),
    primary_email_1: Yup.string().when(["formatted_cell_phone_1"], {
      is: formatted_cell_phone_1 => !formatted_cell_phone_1,
      then: validations.email
        .required(errorMessages.REQUIRE_FIELD)
        .stringInArray(
          Yup.ref("secondary_emails_1"),
          errorMessages.EMAIL_MATCHED
        )
        .compareStrings(
          Yup.ref("hidden_secondary_emails_1"),
          errorMessages.EMAIL_MATCHED
        )
        .compareStrings(
          Yup.ref("primary_email_2"),
          errorMessages.PRIMARY_MATCH
        ),
      otherwise: validations.email
        .stringInArray(
          Yup.ref("secondary_emails_1"),
          errorMessages.EMAIL_MATCHED
        )
        .compareStrings(
          Yup.ref("hidden_secondary_emails_1"),
          errorMessages.EMAIL_MATCHED
        )
        .compareStrings(Yup.ref("primary_email_2"), errorMessages.PRIMARY_MATCH)
        .nullable()
    }),
    formatted_cell_phone_1: Yup.string().when(["primary_email_1"], {
      is: primary_email_1 => !primary_email_1,
      then: Yup.string()
        .nullable()
        .validateCell(Yup.ref("cell_phone_1"), errorMessages.CELL_LENGTH)
        .required(errorMessages.REQUIRE_FIELD),
      otherwise: Yup.string()
        .nullable()
        .validateCell(Yup.ref("cell_phone_1"), errorMessages.CELL_LENGTH)
    }),
    secondary_emails_1: Yup.array(),
    hidden_secondary_emails_1: validations.email
      .compareStrings(Yup.ref("primary_email_1"), errorMessages.EMAIL_MATCHED)
      .stringInArray(Yup.ref("secondary_emails_1"), errorMessages.EMAIL_EXISTS),

    first_name_2: validations.name,
    last_name_2: validations.name,
    primary_email_2: validations.email
      .stringInArray(Yup.ref("secondary_emails_2"), errorMessages.EMAIL_MATCHED)
      .compareStrings(
        Yup.ref("hidden_secondary_emails_2"),
        errorMessages.EMAIL_MATCHED
      )
      .compareStrings(Yup.ref("primary_email_1"), errorMessages.PRIMARY_MATCH)
      .nullable(),
    formatted_cell_phone_2: Yup.string()
      .nullable()
      .validateCell(Yup.ref("cell_phone_2"), errorMessages.CELL_LENGTH),
    hidden_secondary_emails_2: validations.email
      .compareStrings(Yup.ref("primary_email_2"), errorMessages.EMAIL_MATCHED)
      .stringInArray(Yup.ref("secondary_emails_2"), errorMessages.EMAIL_EXISTS),
    secondary_emails_2: Yup.array(),

    street_address: Yup.string()
      .max(128, errorMessages.ADDRESS_LONG)
      .nullable(),
    city: Yup.string()
      .max(24, errorMessages.CITY_LONG)
      .nullable(),
    state_id: Yup.string()
      .default("Select...")
      .nullable(),
    zip: Yup.string()
      .max(10, errorMessages.ZIP_LONG)
      .nullable(),
    osc_notes: Yup.string().nullable(),
    Lead: Yup.object().shape({
      divisions: Yup.array().min(1, errorMessages.REQUIRE_FIELD).nullable(),
      communities: Yup.array().nullable(),
      quality_score: Yup.string()
        .default("Select...")
        .nullable(),
      engagement_score: Yup.string()
        .default("Select...")
        .nullable(),
      other_notes: Yup.string().nullable()
    }),
    realtor_id: Yup.string()
      .default("Select...")
      .nullable(),
    customer_origination_area_id: Yup.string()
      .default("Select...")
      .nullable()
  },
  ["primary_email_1", "formatted_cell_phone_1"]
);
