import React, { useState } from 'react';
import PropTypes from "prop-types";
import { compose, graphql } from "react-apollo";
import { FaPen } from 'react-icons/fa';

import { UPDATE_TITLE_CUSTOMIZATION } from "../../graphql/mutations";
import Dialog from '../../components/dialog/dialog';
import CustomButton from '../../components/custom-button';
import { isNotNULL } from '../../utils/helpers';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

/**
 * this componen is used to render the more customizations section, where u can edit reservation and video text labels.
 * @param {*} param0 
 */
const MoreCustomizations = ({ titleCustomization, ...props }) => {

  const [newTitleValue, setNewTitleValue] = useState("")
  const [editName, setEditName] = useState("")
  const [showEditDialog, setShowEditDialog] = useState(false)

  const editHandler = (name, val) => {
    setEditName(name)
    setNewTitleValue(val)
    setShowEditDialog(true)
  }

  const cancelEdit = () => {
    setShowEditDialog(false)
    setNewTitleValue("")
  }

  const handleChange = (value) => {
    setNewTitleValue(value);
  }

  const handleEdit = () => {
    if (newTitleValue === "" || newTitleValue === " ") {
      return
    }
    props.updatetitleCustomization({
      variables: {
        name: editName,
        value: newTitleValue
      }
    }).then(res => {
      if (res.data.updateTitleCustomization.code === 200) {
        handleChange("")
        setShowEditDialog(false);
        props.update(res.data.updateTitleCustomization.message)
      }
    })
  }

  const dailogContent = () => {
    return <input
      type="text"
      value={newTitleValue}
      className="form-control"
      placeholder="Add new text value"
      onChange={e => handleChange(e.target.value)}
    />
  }

  return (
    <div className="more-customizations-wrapper mt-4" id="more-customizations-wrapper">
      <h3>More Customizations</h3>
      <p className='ml-2'>Change Reservation and Video text labels</p>
      <Dialog
        size="sm"
        show={showEditDialog}
        onHide={cancelEdit}
        title="More Customization"
        body={dailogContent()}
        click={handleEdit}
        clickname="Update"
        closename="Cancel" />
      <div className='cutomization-text ml-2'>
        {isNotNULL(props.texts) && props.texts.map((item, key) => {
          return <li key={key}>
            <p>{item.value}</p>
            <OverlayTrigger overlay={<Tooltip >Edit</Tooltip>}>
              <CustomButton
                id="edit-icn"
                color="dark"
                btnIcon={<FaPen />}
                onClick={() => editHandler(item.name, item.value)}
              />
            </OverlayTrigger>
          </li>
        })}
      </div>
    </div>
  )
}

MoreCustomizations.propTypes = {
  texts: PropTypes.array,
  update: PropTypes.func,
}

export default compose(
  graphql(UPDATE_TITLE_CUSTOMIZATION, { name: "updatetitleCustomization" })
)(MoreCustomizations)