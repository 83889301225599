import gql from "graphql-tag";

const GET_HOMEOWNER_BY_ID = gql`
  query getHomeOwnerById(
    $limit: Int
    $pageNum: Int
    $filter: HomeOwnersFilter
    $search: String
    $order: [String]
  ) {
    getAllHomeOwners(
      limit: $limit
      pageNum: $pageNum
      filter: $filter
      search: $search
      order: $order
    ) {
      Home_Owner {
        id
        customer_id
        first_name_1
        last_name_1
        first_name_2
        last_name_2
        cell_phone_1
        cell_phone_2
        primary_email_1
        primary_email_2
        secondary_emails_1
        secondary_emails_2
        primary_email_1_status
        primary_email_2_status  
        street_address
        city
        osc_notes
        current_dissatisfaction
        future_promise
        motivation_what_changed
        other_notes
        state{
          id
          name
        }
        zip
        realtor{
          id
          first_name
          last_name
        }
        customer_origination_area{
          id
          name
        }
        stage{
          id
          name
        }
        community {
          id
          name
          is_active
        }
        purchased_date
        settled_date
        lot
        brand_ambassador
        notes{
            id
            description
            createdAt
            updatedAt
            creator {
              id
              first_name
              last_name
            }
            note_attachment {
              id
              file_path
              createdAt
              updatedAt
            }
        }
        phone_calls{
            phone_call_id
            session_id
            call_type{
                id
                name
            }
            call_details
            call_duration
            call_datetime
            attachments {
              id
              name
              type
              download_url
             }
            creator{
              first_name
              last_name
            }
            recording
        }
        form_submissions{
          id
          interested_in
          other_data
          response_phone
          response_sms
          response_email
          created_at
          updated_at
          responded_at
          response_medium
          domain
          url
          lot
          form_type
          division {
            id
            name
          }
          community{
            name
            is_active
          }
          url
          responder{
            first_name
            last_name
          }
        }
        mailchimp_emails {
          mailchimp_email_id
          datetime
          subject
          email_addresses
          email_from
          content_url
          count
        }
        emails{
          id
          subject
          body
          body_preview
          date_time
          hasAttachments
          isIndividual
          email_type
          to_recipients
          cc_recipients
          bcc_recipients
          csms{
            id
            first_name
            last_name
            email_address
            cell_phone
            role
            createdAt
            updatedAt
            is_active
          }
        }
        appointments {
          id
          title
          details
          done
          send_email
          zoom_meeting_link
          createdAt
          creator {
            first_name
            last_name
          }
          csm {
            id
            first_name
            last_name
            is_active
          }
          Appointment_Type{
            id
            name
          }
          start_datetime
          end_datetime
        }
        follow_ups {
          id
          title
          details
          done
          createdAt
          creator {
            first_name
            last_name
          }
          csm {
            id
            first_name
            last_name
            is_active
          }
          follow_up_datetime
        }

      }
      total_count
    }
  }
`;
export default GET_HOMEOWNER_BY_ID;
