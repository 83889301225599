import { compose, graphql } from "react-apollo";
import { GET_ALL_AGENCIES } from "../../../graphql/queries";
import {
  SET_NOTIFICATION_PROPS,
  SET_TABLE_PARAMS,
  SET_PREVIOUS_CARD
} from "../../../cacheql/mutations";
import AgenciesTable from "./all-agencies-table";
import { GET_TABLE_PARAMS, GET_SELECTED_DIVISION } from "../../../cacheql/queries";
import { getCardParams } from "../../../utils/helpers";
import { DELETE_AGENCY } from "../../../graphql/mutations";

export default compose(
  graphql(GET_TABLE_PARAMS, { name: "getTableParams" }),
  graphql(SET_TABLE_PARAMS, { name: "setTableParams" }),
  graphql(SET_PREVIOUS_CARD, { name: "setPreviousCard" }),
  graphql(GET_SELECTED_DIVISION, { name: "selectedDivision" }),
  graphql(GET_ALL_AGENCIES, {
    options: ({
      currentCard: { uniqueTitle, mode },
      getTableParams: { tableParams },
      selectedDivision,
      ...props
    }) => {
      let params = getCardParams(uniqueTitle, tableParams);
      return {
        variables: {
          filter: {
            ...(params && params.filter),
            division_id: selectedDivision.selectedDivision.id,
          },
          pageNum: (params && params.pageNum) || 0,
          limit: (params && params.limit) || 10,
          search: (params && params.search) || "",
          sort:
            params && params.sort && params.sort.id !== -1
              ? [params.sort.name, params.sort.sort]
              : null,
          mode: mode
        },
        fetchPolicy: "cache-and-network"
      };
    }
  }),
  graphql(DELETE_AGENCY, { name: "deleteAgency" }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" })
)(AgenciesTable);
