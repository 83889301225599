import React, { useState } from "react";
import { Field, Form, withFormik } from "formik";
import { FaFilter, FaStar, FaUserFriends } from "react-icons/fa";
import { Table } from "react-bootstrap";
import { phoneDisplay, redirectToDashboard } from "../../utils/helpers";
import { mergeCustomerSchema } from "./merge-customer-schema";
import CustomButton from "../../components/custom-button";
import AddEmailField from "../../components/fields/add-email-field";
import SelectField from "../../components/fields/select-field";
import TextField from "../../components/fields/text-field";
import './merge-customer.css'

/**
 * This component is responsible to render the customer form for the purpose of merge customers. It has text fields, text area, select, and add email fields.
 * First name, last name and either primary or cell phone must be filled to submit the form. Primary emails of buyer one and 2 mst not be similar. Primary
 * email must not match any of the secondary emails. These are the most important validations of this form.
 *
 * @param {Object} props formik-bag, and query/mutations passed from the container
 * @param {Object} props.data data formatted in the form of schema
 */
const MergeCustomerForm = (props) => {
  const [primarySecondaryClicked1, setPrimarySecondaryClicked1] = useState({});
  const [primarySecondaryClicked2, setPrimarySecondaryClicked2] = useState({});
  const [primaryClicked, setPrimaryClicked] = useState({});

  const { values } = props;
  return (
    <Form className={"form-horizontal"} noValidate>
      <div className="form_TitleContainer">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ margin: "0 1.1rem" }}
        >
          <div>
            <div className="form_TitleBig">
              <div className="d-flex justify-content-center align-items-center">
                <h2
                  style={{
                    position: "relative",
                    paddingRight: "0.5em",
                  }}
                >
                  <FaUserFriends /> Merged Customer
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <CustomButton
            size="lg"
            color="green"
            btnValue="Merge"
            type="submit"
          />
        </div>
      </div>
      <div className="form_body">
        <div className="form-row">
          <div className="form-section">
            <h5>Buyer#1</h5>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <Field
              label="First Name"
              name="first_name_1"
              id="first_name_1"
              type="text"
              required={true}
              component={TextField}
            />
          </div>
          <div className="col-md-6">
            <Field
              label="Last Name"
              name="last_name_1"
              id="last_name_1"
              type="text"
              required={true}
              component={TextField}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <div className="col-md-12 padding-less">
              <Field
                label="Primary Email"
                name="primary_email_1"
                id="primary_email_1"
                type="text"
                disable={props.data && props.data.disable_primary_email_1}
                refFields={[
                  {
                    value: primarySecondaryClicked1,
                    setter: setPrimarySecondaryClicked1,
                  },
                  { value: primaryClicked, setter: setPrimaryClicked },
                ]}
                required={!values.formatted_cell_phone_1 || false}
                component={TextField}
              />
            </div>
            <div className="col-md-6 padding-less">
              <Field
                label="Cell #"
                name="formatted_cell_phone_1"
                id="formatted_cell_phone_1"
                isCellPhone={true}
                type="text"
                required={!values.primary_email_1 || false}
                component={TextField}
              />
            </div>
          </div>
          <div className="col-md-6">
            <Field
              label="Secondary Email(s)"
              name="secondary_emails_1"
              id="secondary_emails_1"
              type="text"
              initValue={values.secondary_emails_1}
              refFields={[
                {
                  value: primarySecondaryClicked1,
                  setter: setPrimarySecondaryClicked1,
                },
              ]}
              component={AddEmailField}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-section">
            <h5>Buyer#2</h5>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <Field
              label="First Name"
              name="first_name_2"
              id="first_name_2"
              type="text"
              component={TextField}
            />
          </div>
          <div className="col-md-6">
            <Field
              label="Last Name"
              name="last_name_2"
              id="last_name_2"
              type="text"
              component={TextField}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <div className="col-md-12 padding-less">
              <Field
                label="Primary Email"
                name="primary_email_2"
                id="primary_email_2"
                disable={props.data && props.data.disable_primary_email_2}
                type="text"
                refFields={[
                  {
                    value: primarySecondaryClicked2,
                    setter: setPrimarySecondaryClicked2,
                  },
                  { value: primaryClicked, setter: setPrimaryClicked },
                ]}
                component={TextField}
              />
            </div>
            <div className="col-md-6 padding-less">
              <Field
                label="Cell #"
                name="formatted_cell_phone_2"
                id="formatted_cell_phone_2"
                isCellPhone={true}
                type="text"
                component={TextField}
              />
            </div>
          </div>
          <div className="col-md-6">
            <Field
              label="Secondary Email(s)"
              name="secondary_emails_2"
              id="secondary_emails_2"
              type="text"
              initValue={values.secondary_emails_2}
              refFields={[
                {
                  value: primarySecondaryClicked2,
                  setter: setPrimarySecondaryClicked2,
                },
              ]}
              component={AddEmailField}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <Field
              label="Address"
              name="street_address"
              id="street_address"
              type="text"
              component={TextField}
              disabled={false}
            />
          </div>
          <div className="col-md-3">
            <Field
              label="City"
              name="city"
              id="city"
              type="text"
              component={TextField}
            />
          </div>
          <div className="col-md-3">
            <Field
              label="State"
              name="state_id"
              id="state_id"
              type="text"
              component={SelectField}
            >
              <option value="">Select...</option>
              {props.getAllStates?.getAllStates?.map((state, index) => (
                <option key={index} value={state.id}>
                  {state.name}
                </option>
              ))}
            </Field>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-2">
            <Field
              label="Zip"
              name="zip"
              id="zip"
              type="text"
              component={TextField}
            />
          </div>
          <div className="col-md-4">
            <Field
              label="Realtor"
              name="realtor_id"
              id="realtor_id"
              component={SelectField}
            >
              <option value="">Select...</option>
              {props.getAllRealtors?.getRealtors?.realtors?.map((option, index) => (
                <option key={index} value={option.id}>
                  {option.first_name + " " + option.last_name}
                </option>
              ))}
            </Field>
          </div>
          <div className="col-md-3">
            <Field
              label="Source"
              name="customer_origination_area_id"
              id="customer_origination_area_id"
              component={SelectField}
            >
              <option value="">Select...</option>
              {props.getOriginationAreas?.getAllCustomer_Origination_Areas?.map(
                (option, index) => (
                  <option key={index} value={option.id}>
                    {option.name}
                  </option>
                )
              )}
            </Field>
          </div>
          <div className="col-md-3">
            <Field
              label="Division"
              name="division_id"
              id="division_id"
              component={SelectField}
            >
              <option value="">Select...</option>
              {props.getAllDivisions?.getAllDivisions?.map((option, index) => (
                <option key={index} value={option.id}>
                  {option.name}
                </option>
              ))}
            </Field>
          </div>
        </div>
        <Table borderless className="details-table small" style={{ fontSize: '86%' }}>
          <thead><tr><th className="p-2 pl-1" colSpan="2">Communities</th></tr></thead>
          <tbody>
            <tr>
              <td className="details-table-row p-1 pl-2 w-25" style={{ fontSize: '12px' }}><FaStar className='header-icon' /> Lead</td>
              <td className="details-table-row p-1" >{props.data.lead_communities}</td>
            </tr>
            <tr>
              <td className="details-table-row p-1 pl-2 w-25" style={{ fontSize: '12px' }}><FaFilter className='header-icon' /> Prospect</td>
              <td className="details-table-row p-1" >{props.data.prospect_communities}</td>
            </tr>
          </tbody>
        </Table>

      </div>
    </Form>
  );
};

const normalizeData = (submitValues) => {
  delete submitValues.others;
  delete submitValues.hidden_secondary_emails_2;
  delete submitValues.hidden_secondary_emails_1;
  delete submitValues.formatted_cell_phone_1;
  delete submitValues.formatted_cell_phone_2;
  delete submitValues.red_flag;
  delete submitValues.disable_primary_email_1;
  delete submitValues.disable_primary_email_2;
  delete submitValues.prospect_communities
  delete submitValues.lead_communities

  if (!submitValues.realtor_id) {
    submitValues.realtor_id = null
  }
  if (submitValues.primary_email_1 === "") {
    submitValues.primary_email_1 = null;
  }
  if (submitValues.cell_phone_1 === "") {
    submitValues.cell_phone_1 = null;
  }
  if (submitValues.primary_email_2 === "") {
    submitValues.primary_email_2 = null;
  }
  if (submitValues.cell_phone_2 === "") {
    submitValues.cell_phone_2 = null;
  }

  return submitValues;
};

/**
 * This function handles the onSubmit of the merge customer form. It converts the inputs received from the form into strings and
 * then runs the mutation to merge the customers by customer_ids provided props. If the mutation is a success then a success message
 * is shown and the dialog box closes as well as the global search box, but in case of an error the error message is 
 * shown and the form stays open until the issue is fixed or the user closes the form themselves. 
 * @param {Object} values values coming from the form fields to submit 
 * @param {*} props 
 */
const mergeCustomerSubmit = (values, props) => {
  const submitInputs = JSON.parse(JSON.stringify(values));
  props
    .mergeCustomers({
      variables: {
        input: submitInputs,
        customer_ids: props.customer_ids,
      },
    })
    .then((res) => {
      if (res.data.mergeCustomers.code === 200) {
        props.onSubmit()
        props.setNotificationProps({
          variables: { open: true, message: res.data.mergeCustomers.message },
        });
        redirectToDashboard(window.location)
      } else {
        props.setNotificationProps({
          variables: { open: true, message: res.data.mergeCustomers.message },
        });
      }
    });
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    const { cell_phone_1, cell_phone_2 } = props.data;
    const formatted_cell_phone_1 = phoneDisplay(cell_phone_1);
    const formatted_cell_phone_2 = phoneDisplay(cell_phone_2);
    return { formatted_cell_phone_1, formatted_cell_phone_2, ...props.data };
  },
  handleSubmit: (values, { props }) => {
    let submitValues = Object.assign({}, values);

    /*Normalize input state according to mutation input format*/
    submitValues = normalizeData(submitValues);

    mergeCustomerSubmit(submitValues, props);
  },
  validationSchema: mergeCustomerSchema,
  displayName: "merge-customers-form",
})(MergeCustomerForm);
