import React, { useContext } from "react";
import { compose, graphql } from "react-apollo";

import { ActivityCardContainer } from "..";
import {
  GET_SELECTED_COMMUNITY,
  GET_SELECTED_DIVISION,
  GET_SELECTED_CSM
} from "../../cacheql/queries";
import { DashboardContext } from "../../context";
import { getCommunityIds } from "../../utils/helpers";

/**
 * This is a container for activity cards. It uses dashboard context to access activity card, and current page/view
 * details. Using activityCards array it creates an array of graphql queries and passes respective paramteres to them
 * according to the page-view, and finally renders HOC querying data from backend and passing it down to 
 * {@link ActivityCardContainer} as props 
 */
const ActivityList = () => {
  const {
    dataQuery, // if every card has same query it is passed in 'dataQuery' otherwise card object has its own custom query
    activityCards = [], // array of objects containing cards info
    withCommunity, // if the page has community dropdown
    withDivision, //if the page has division dropdown
    withCsm, //if the page has csm dropdown
  } = useContext(DashboardContext);

  const activityCardGraphQLs = []

  activityCards.forEach(
    ({ title, filter, mode, secondaryDataQuery, ...rest }) => {
      let cardQuery = rest.customDataQuery || dataQuery;

      if (secondaryDataQuery) {
        activityCardGraphQLs.push(
          graphql(secondaryDataQuery.dataQuery,
            {
              name: secondaryDataQuery.name,
              options: props => ({
                variables: {
                  input: {
                    ...secondaryDataQuery.filter,
                    ...(withCommunity
                      ? {
                        community_ids: getCommunityIds(props.selectedCommunity)
                      }
                      : {}),
                    ...(withCsm
                      ? {
                        csm_id: props.selectedCsm.selectedCsmIds.ids,
                      }
                      : {}),
                    ...(withDivision
                      ? { division_id: props.selectedDivision.selectedDivision.id }
                      : {})
                  }
                },
                fetchPolicy: "cache-and-network"
              })
            })
        )
      }

      activityCardGraphQLs.push(
        graphql(cardQuery, {
          name: title,
          options: props => ({
            variables: {
              input: {
                ...filter,
                ...(withCommunity
                  ? {
                    community_ids: getCommunityIds(props.selectedCommunity)
                  }
                  : {}),
                ...(withCsm
                  ? {
                    csm_id: props.selectedCsm.selectedCsmIds.ids,
                  }
                  : {}),
                ...(withDivision
                  ? { division_id: props.selectedDivision.selectedDivision.id }
                  : {})
              }
            },
            fetchPolicy: "cache-and-network"
          })
        })
      )
    }
  );

  const Component = compose(
    graphql(GET_SELECTED_COMMUNITY, { name: "selectedCommunity" }),
    graphql(GET_SELECTED_DIVISION, { name: "selectedDivision" }),
    graphql(GET_SELECTED_CSM, { name: "selectedCsm" }),
    ...activityCardGraphQLs,
  )(ActivityCardContainer);

  return <Component />;
};

/**
 * This component is used to wrap {@link ActivityList} inside a div
 * @param {Object} props 
 */
const ActivityCardsView = props => {
  return (
    <div id="activity-root" className="w-100">
      <ActivityList />
    </div>
  );
};

export default ActivityCardsView;
