import gql from 'graphql-tag';

const UPDATE_HOMEOWNER_PHONE_CALL = gql` 
  mutation updateHomeownerPhoneCall($input: UpdateHomeownerPhoneCallInput!,$phone_call_id:ID!) {
    updateHomeownerPhoneCall(input: $input,phone_call_id:$phone_call_id) {
      message
      code
    }
  }
`;

export default UPDATE_HOMEOWNER_PHONE_CALL;
