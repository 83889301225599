import React, { useState, useEffect, useContext } from "react";
import { compose, graphql } from "react-apollo";
import { Dropdown } from "react-bootstrap";
import { FaEnvelope, FaFilter, FaTrashAlt, FaPoll, FaMailBulk } from "react-icons/fa";
import { Auth } from "../../../rbac/rbac";
import Select from "../../../components/select";
import Dialog from "../../../components/dialog/dialog";
import SendMassEmail from "../../../components/dialog/send-email"
import LoadingWrapper from "../../../components/loading-wrapper";
import DeleteDialogBox from "../../../components/dialog/delete-dialog-box";
import { DefaultQuery } from "../../../graphql/default-query";
import { GET_SELECTED_COMMUNITY } from '../../../cacheql/queries'
import { SET_NOTIFICATION_PROPS } from "../../../cacheql/mutations";
import { GET_DIVISIONS, GET_ALLRANKINGS } from "../../../graphql/queries";
import {
  CONVERT_PROSPECT_TO_LEAD,
  ADD_PROSPECT_DELETE_REQUEST,
  UPDATE_PROSPECT_RANKING
} from "../../../graphql/mutations";
import { ProspectConvertFailedDialog } from './prospect-convert-failed-dialog'

/**
 * this component is used to render the mass action dropdown in the prospects table, which contains 4 actions, convert to lead,
 * send email to, send email bcc and delete actions.
 * @param {object} props contains all component props including the mutations and queries.
 * @param {number} props.id prospect id
 * @param {Array} props.emails contains selected rows emails
 */
const ProspectsListMassUpdate = ({ id, ...props }) => {
  const [note, setNote] = useState("")
  const [rankId, setRankId] = useState(null)
  const [divisionId, setDivisionId] = useState(null)
  const [deleteIndex, setDeleteIndex] = useState(null)
  const [convertMessage, setConvertMessage] = useState("")
  const [deleteProspectID, setDeleteProspectID] = useState([])
  const [showMassEmailModal, setShowMassEmailModal] = useState(false)
  const [showUpdateRankings, setShowUpdateRankings] = useState(false)
  const [massEmailSubmission, setMassEmailSubmission] = useState(false)
  const [showConvertConfirmation, setShowConvertConfirmation] = useState(false)
  const [showConvertDialog, setShowConvertDialog] = useState(false)
  const [showMultipleConvertDialog, setShowMultipleConvertDialog] = useState(false)
  const [convertMultipleFailureData, setConvertMultipleFailureData] = useState([])
  const [convertMultipleSuccessData, setConvertMultipleSuccessData] = useState([])
  const [convertMultipleCommunities, setConvertMultipleCommunities] = useState([])
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [
    showMultipleConvertDeleteConfirmDialog,
    setShowMultipleConvertDeleteConfirmDialog
  ] = useState(false)

  const { is_manager, is_divmgr } = useContext(Auth)

  useEffect(() => {
    if (!showMultipleConvertDialog) {
      setConvertMultipleFailureData([])
      setConvertMultipleSuccessData([])
      props.clearState();
    }
  }, [showMultipleConvertDialog])

  useEffect(() => {
    if (deleteProspectID?.length && (!showMultipleConvertDeleteConfirmDialog && !showConvertDialog))
      handleDeleteConfirmation()
  }, [
    // deleteProspectID,
    convertMultipleFailureData
  ])

  /**
   * Handles Mass Delete Confirmation Modal and sets the id of the deleting prospect
   */
  const handleMassDelete = () => {
    setShowDeleteConfirmation(true)
    setDeleteProspectID(id)
  }

  const handleMultipleConvertDeleteConfirmation = (index, itemID) => {
    setShowMultipleConvertDeleteConfirmDialog(true)
    setDeleteIndex(index)
    setDeleteProspectID([itemID])
    setShowConvertDialog(false)
    setConvertMessage("")
  };

  const handleCancelMultipleConvertDeleteConfirmation = () => {
    setShowMultipleConvertDeleteConfirmDialog(false)
    setDeleteIndex(null)
    setDeleteProspectID(null)
  };

  const handleMultipleConvertDelete = () => {
    setShowMultipleConvertDeleteConfirmDialog(false)
    setConvertMultipleFailureData(convertMultipleFailureData.filter((_, idx) => idx !== deleteIndex))

    if (convertMultipleFailureData.length <= 1) {
      setShowMultipleConvertDialog(false)
      setDeleteIndex(null)
    }
  };

  const handleCancelConvert = () => {
    setShowConvertDialog(false)
    setShowMultipleConvertDialog(false)
    setConvertMessage("")
    setDeleteIndex(null)
    setDeleteProspectID([])
    props.clearState();
  };

  // handles mass email modal display
  const handleMassEmail = () => setShowMassEmailModal(!showMassEmailModal)

  const handleMassEmailSubmitted = () => {
    setMassEmailSubmission(!massEmailSubmission)
    setShowMassEmailModal(!showMassEmailModal)
  };

  const handleDeleteConfirmation = async () => {
    setShowDeleteConfirmation(false);

    try {
      const res = await props.addProspectDeleteRequests({
        variables: {
          input: {
            prospect_ids: deleteProspectID,
            note: note
          }
        }
      });

      if (res.data.addProspectDeleteRequests.code === 200) {
        setShowConvertDialog(false);
        setConvertMessage("");
        setDeleteProspectID(null);
        setShowMultipleConvertDeleteConfirmDialog(false);
        setShowMultipleConvertDialog(deleteIndex !== null ? true : false);
        setDeleteIndex(null);
        props.setNotificationProps({
          variables: {
            open: true,
            message: res.data.addProspectDeleteRequests.message
          }
        });

        props.submitHandler(res.data.addProspectDeleteRequests.message);
      }
    } catch (error) {
      // Handle errors here
      if (error.graphQLErrors) {
        error.graphQLErrors.map(graphQLError => {
          return graphQLError.message;
        });
      }
    }
  };

  const handleConvert = (itemId) => {
    setShowConvertConfirmation(false)

    props.convertProspectToLead({
      variables: {
        input: {
          prospect_ids: itemId,
          division_id: divisionId
        }
      }
    }).then(res => {
      if (res.data.convertProspectToLead.code === 200) {
        setShowConvertDialog(false)
        setConvertMessage("")
        setShowMultipleConvertDialog(false)
        setDeleteIndex(null)
        props.submitHandler("convert");
        props.setNotificationProps({
          variables: {
            open: true,
            message: res.data.convertProspectToLead.message
          }
        });
        props.clearState();
      } else if (res.data.convertProspectToLead.code === 207) {
        if (res.data.convertProspectToLead.data.failureData.length === 1) {
          setShowConvertDialog(true)
          setConvertMessage(`${res.data.convertProspectToLead.data.failureData[0].first_name_1}
             ${res.data.convertProspectToLead.data.failureData[0].last_name_1}
              ${res.data.convertProspectToLead.message}`
          )
          setDeleteProspectID([res.data.convertProspectToLead.data.failureData[0].id])
          setConvertMultipleFailureData(res.data.convertProspectToLead.data.failureData)
          setConvertMultipleSuccessData(res.data.convertProspectToLead.data.successData)
          setConvertMultipleCommunities(res.data.convertProspectToLead.data.communities)
        } else {
          const successCount = res.data.convertProspectToLead.data.successData.length

          successCount && props.setNotificationProps({
            variables: {
              open: true,
              message: `${successCount} prospect(s) converted successfully`
            }
          });

          setShowMultipleConvertDialog(true)
          setConvertMultipleFailureData(res.data.convertProspectToLead.data.failureData)
          setConvertMultipleSuccessData(res.data.convertProspectToLead.data.successData)
          setConvertMultipleCommunities(res.data.convertProspectToLead.data?.communities)
        }
      }
    });
  };

  const handleRankingUpdate = () => {
    setShowUpdateRankings(false)

    props.updateProspectRankings({
      variables: {
        input: {
          prospect_ids: id,
          rank_id: rankId
        }
      }
    }).then(res => {
      props.setNotificationProps({
        variables: {
          open: true,
          message: res.data.updateProspectRankings.message
        }
      });

      if (res.data.updateProspectRankings.code === 200) {
        props.submitHandler();
        props.clearState();
      }
    });
  };

  /**
   * This function displays the message notifying user the email status
   * @param {String} message  
   */
  const onMassEmailSuccess = (message) => {
    props.setNotificationProps({
      variables: {
        open: true,
        message: message,
      },
    });
  }

  /**
   * This function is used by the Dialog as well as the send-email component.
   * It sets the value true when the Dialog is submitted and is resetted to
   * false in the send-email component. 
   * 
   * @param {Boolean} state Changes the state of massEmailSubmission 
   */
  const checkMassEmailSubmission = (state) => setMassEmailSubmission(state)

  /**
   * This function is responsible to generate the entire content
   * of the Dialog. It renders the 'SendMassEmail' component
   * with an array of emails of selected Prospects and a Boolean variable
   * to check the form submission state.
   * 
   * @param {Array} emails Prospect Emails
   * @param {Boolean} checkSubmission Is form submitted
   * @param {Function} onFormSubmit Updated the states when form submitted successfully
   * @param {Function} onSubmitNotification Notifies user of the email submission
   * @param {Function} handleCheckSubmission Updates the status of checkSubmission  
   * @returns 
   */
  const sendMassEmailBody = (prospectEmails) => {
    return prospectEmails ? (
      <SendMassEmail
        emails={prospectEmails.split("%3B%20")}
        checkSubmission={massEmailSubmission}
        onFormSubmit={handleMassEmailSubmitted}
        onSubmitNotification={onMassEmailSuccess}
        handleCheckSubmission={checkMassEmailSubmission}
      />
    )
      : null
  }

  const formatOptions = (list) => {
    let vals = [];
    list && list.forEach(item => vals.push({ value: item.id, label: `${item.name}` }))

    return vals
  }

  const handleChange = (value) => setNote(value)
  return (
    <>
      <Dialog
        show={showConvertConfirmation}
        onHide={() => {
          setShowConvertConfirmation(false)
          setDivisionId(null)
        }}
        title="Prospect to Lead Conversion"
        body={
          <DefaultQuery
            query={GET_DIVISIONS}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }) => {
              const divisionOptions = formatOptions(data?.getAllDivisions)
              return !error ? (
                <div className="backdrop-dark">
                  <LoadingWrapper
                    loading={loading}
                    component={
                      <Select
                        options={divisionOptions}
                        placeholder="Select a Division"
                        onChange={(e) => setDivisionId(e.value)}
                        isSearchable={true}
                      />
                    }
                  />
                </div>
              ) : null
            }}
          </DefaultQuery>
        }
        disabled={!divisionId}
        click={() => handleConvert(id)}
        clickname="CONVERT"
        closename="CANCEL"
      />

      <Dialog
        show={showMassEmailModal}
        onHide={() => setShowMassEmailModal(!showMassEmailModal)}
        title="Send Email To"
        body={sendMassEmailBody(props.emails)}
        click={() => checkMassEmailSubmission(true)}
        clickname="Send Email"
        closename="Cancel"
        size="lg"
      />

      <Dialog
        show={showDeleteConfirmation || showMultipleConvertDeleteConfirmDialog}
        onHide={showDeleteConfirmation
          ? () => setShowDeleteConfirmation(false)
          : handleCancelMultipleConvertDeleteConfirmation
        }
        title="Delete Confirmation"
        body={<DeleteDialogBox setFunc={handleChange} />}
        click={showDeleteConfirmation
          ? handleDeleteConfirmation
          : handleMultipleConvertDelete
        }
        clickname="YES"
        closename="NO"
      />

      <ProspectConvertFailedDialog
        show={showMultipleConvertDialog}
        onHide={handleCancelConvert}
        successData={convertMultipleSuccessData}
        failureData={convertMultipleFailureData}
        communities={convertMultipleCommunities}
        handleDelete={handleMultipleConvertDeleteConfirmation}
      />

      <Dialog
        show={showUpdateRankings}
        onHide={() => {
          setRankId(null)
          setShowUpdateRankings(false)
        }}
        title={"Update Prospect Rank"}
        body={
          <DefaultQuery
            query={GET_ALLRANKINGS}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }) => {
              const rankOptions = formatOptions(data?.getAllRankings)
              return !error ? (
                <div className="backdrop-dark">
                  <LoadingWrapper
                    loading={loading}
                    component={
                      <Select
                        options={rankOptions}
                        placeholder="Select a Rank"
                        onChange={(e) => setRankId(e.value)}
                        isSearchable={true}
                      />
                    }
                  />
                </div>
              ) : null
            }}
          </DefaultQuery>
        }
        disabled={!rankId}
        click={handleRankingUpdate}
        clickname={"UPDATE"}
        closename="CANCEL"
      />

      <div style={{ display: "none" }}>
        <a href={`mailto:${props.emails}`} id="send-email" >{""}</a>
      </div>
      <div style={{ display: "none" }}>
        <a href={`mailto:?bcc=${props.emails}`} id="send-mass-email" >{""}</a>
      </div>

      {(is_manager() || is_divmgr()) &&
        <Dropdown.Item
          eventKey="1"
          id="dropdown-1"
          disabled={props.disable}
          onClick={() => setShowUpdateRankings(true)}
        >
          <FaPoll id={"drop-down-icn-1"} /> Update Rank
        </Dropdown.Item>
      }
      <Dropdown.Item
        eventKey="2"
        id="dropdown-2"
        disabled={props.disable}
        onClick={() => setShowConvertConfirmation(true)}
      >
        <FaFilter id={"drop-down-icn-2"} /> Convert to Lead
      </Dropdown.Item>
      <Dropdown.Item
        eventKey="3"
        id="dropdown-3"
        disabled={props.disable}
        onClick={() => document.getElementById("send-email").click()}
      >
        <FaEnvelope id="drop-down-icn-3" /> Send Email To
      </Dropdown.Item>
      <Dropdown.Item
        eventKey="4"
        id="dropdown-7"
        // onClick={() => handleMassEmail()} // mass email using SendGrid (currently disabled)
        onClick={() => document.getElementById("send-mass-email").click()} // mass email using Outlook
      >
        <FaMailBulk id={"dropdown-icn-1"} /> Send Email BCC
      </Dropdown.Item>
      <Dropdown.Item
        eventKey="5"
        id="dropdown-5"
        onClick={handleMassDelete}
      >
        <FaTrashAlt id="drop-down-icn-5" /> Delete
      </Dropdown.Item>
    </>
  );
}

export default compose(
  graphql(CONVERT_PROSPECT_TO_LEAD, { name: "convertProspectToLead" }),
  graphql(UPDATE_PROSPECT_RANKING, { name: "updateProspectRankings" }),
  graphql(GET_SELECTED_COMMUNITY, { name: "selectedCommunity" }),
  graphql(ADD_PROSPECT_DELETE_REQUEST, { name: "addProspectDeleteRequests" }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" })
)(ProspectsListMassUpdate);
