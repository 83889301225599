import gql from 'graphql-tag';

const GET_ALL_OTHERS = gql`
query  getUnavailableOthersById($community_ids: [Int!]){
  getUnavailableOther_Dropdown_Values(community_ids: $community_ids) {
  	id
    name
  }
 }
`;
export default GET_ALL_OTHERS;
