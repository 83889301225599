import React, { useState, useCallback, useEffect } from "react";
import Drawer from "../../hoc/Drawer";
import CreateEditMarketingOnlyWithMutation from "../marketing-only/forms/create-edit-marketing-only-container";
import { phoneDisplay, removeDuplicates } from "../../utils/helpers";

// this is called for recover and convert to marketing only from webform drawer!

/**
 *@module  ConvertLeadViewModule
 */
/**
 * this component is used while form conversion/recover to a Marketing Only, to render the edit marketing only drawer. It takes the customer information and puts it in marketing only format and pass it to the drawer
 * @param {*} props
 * @param {object} props.data contains information of the customer
 * @param {function} props.onSubmit
 * @param {function} props.toggleShowDrawer closes the drawer
 * @param {boolean} props.showSideBar boolean that defines wether to show the drawer or not
 * @param {boolean} props.convertForm boolean that defines if its conversion
 * @param {boolean} props.recoverForm boolean that defines if its recover
 * @param {string} props.convertFormType type of the page thats beeing called from
 */
const ConvertMarketingOnlyView = props => {
  const {
    data,
    onSubmit,
    toggleShowDrawer,
    showSideBar,
    convertFormType,
    convertForm,
    recoverForm
  } = props;

  const [inActiveCommunities, setInactiveCommunities] = useState([]);
  const [communities, setCommunities] = useState([]);

  /**
* this function takes the communities from the data and checks if they are active or inactive and sets them accordingly
* @param {object} data contains information of the communities
* @function
* @inner
* @memberof module:ConvertLeadViewModule
*/
  const getCommunities = useCallback(
    data => {
      let communities = [];
      let inActive = [];

      if (convertFormType === "WEBFORM") {
        const detailsCommunities = data.community && [data.community]
        communities = removeDuplicates(detailsCommunities, "id");
      }

      communities.map(
        community => !community.is_active && inActive.push(community)
      );
      setInactiveCommunities(inActive);
      setCommunities(communities);
    },
    [convertFormType]
  );

  useEffect(() => {
    if (data) {
      getCommunities(data);
    }
  }, [data, getCommunities]);

  /**
 * this function prepares the data to be passed to the marketing only form, it takes the customer information and puts it in marketing only format.
 * @param {object} marketingOnlyData contains information of the customer
 * @function
 * @inner
 * @memberof module:ConvertLeadViewModule
 * @returns {object}
 */
  const formatData = marketingOnlyData => {
    let dataToReturn = (({
      firstname,
      lastname,
      email,
      phone,
      state,
      osc_notes
    }) => {
      return {
        first_name_1: firstname || marketingOnlyData.first_name_1,
        last_name_1: lastname || marketingOnlyData.last_name_1,
        primary_email_1: email || marketingOnlyData.primary_email_1,
        cell_phone_1: phone || marketingOnlyData.cell_phone_1,
        formatted_cell_phone_1:
          (phone && phoneDisplay(phone)) ||
          (marketingOnlyData.cell_phone_1 && phoneDisplay(marketingOnlyData.cell_phone_1)),
        secondary_emails_1: marketingOnlyData.secondary_emails_1 || [],
        first_name_2: "" || marketingOnlyData.first_name_2,
        last_name_2: "" || marketingOnlyData.last_name_2,
        primary_email_2: null || marketingOnlyData.primary_email_2,
        cell_phone_2: null || marketingOnlyData.cell_phone_2,
        formatted_cell_phone_2:
          "" || (marketingOnlyData.cell_phone_2 && phoneDisplay(marketingOnlyData.cell_phone_2)),
        secondary_emails_2: marketingOnlyData.secondary_emails_2 || [],
        city: "" || marketingOnlyData.city,
        state_id: state && state.id ? state.id : undefined,
        zip: "" || marketingOnlyData.zip,
        street_address: "" || marketingOnlyData.street_address,
        customer_origination_area_id:
          (marketingOnlyData.customer_origination_area &&
            marketingOnlyData.customer_origination_area.id) ||
          undefined,
        osc_notes,
        division_id: marketingOnlyData.division ? marketingOnlyData.division.id : null,
      }
    })(marketingOnlyData)

    dataToReturn.Marketing_Only = {
      communities: communities.filter(item => item.is_active).map(item => item.id),
      other_notes: null,
      quality_score: undefined,
      engagement_score: undefined
    }

    return dataToReturn;
  }

  return (
    <div>
      <Drawer show={showSideBar} toggleSideBar={toggleShowDrawer}>
        <CreateEditMarketingOnlyWithMutation
          close={toggleShowDrawer}
          data={data && formatData(data)}
          onSubmit={onSubmit}
          convertForm={convertForm}
          recoverForm={recoverForm}
          convertFormType={convertFormType}
          inActiveCommunities={inActiveCommunities}
        />
      </Drawer>
    </div>
  );
};

export default ConvertMarketingOnlyView;