import React, { useState, useContext } from "react";
import { useQuery } from "react-apollo-hooks";
import { graphql, compose } from "react-apollo";
import { Auth } from "../../rbac/rbac";
import CustomButton from "../../components/custom-button";
import Dialog from "../../components/dialog/dialog";
import { GET_ALL_USERS, GET_USER } from "../../graphql/queries";
import "./layout.css";
import Select from "../../components/select";
import { ROLE_CSM, ROLE_DIVISION_MANAGER, ROLE_MANAGER, ROLE_OSC } from "../../utils/constants";

/**
 * This component is responsible for the 'View As' functionality for Manager or Division Manager
 * Renders the 'View As' button, user selection modal and 'Exit View' button
 * @param {Object} getUser query to fetch all active users
 */
const ViewAs = ({ getUser }) => {
  const {
    is_manager,
    is_divmgr,
    handle_login_as,
    is_logged_in_as,
    user
  } = useContext(Auth);

  const [openModal, setOpenModal] = useState(false);
  const [loginAs, setLoginAs] = useState();

  const user_div = user.Divisions?.map(div => div.id) //if user is divmgr, then get CSMs/OSCs by division ID

  const { data: getAllUsers } = useQuery(GET_ALL_USERS, {
    skip: getUser.loading,
    variables: { division_ids: user_div },
  });

  /**
  * This function categorizes the list received against the options DIVMGR, CSM or OSC if the list contains role.
  * @param {Array} list 
  * @returns {Array}
  */
  const groupOptions = (list) => {
    let vals = []
    let csm = []
    let osc = []
    let divmgr = []

    list && list.forEach((item) => {
      if (!item.roles.includes(ROLE_MANAGER)) {
        item.roles.includes(ROLE_DIVISION_MANAGER) && user.id !== item.id && divmgr.push({ value: item.id, label: `${item.first_name} ${item.last_name}` })
        item.roles.includes(ROLE_OSC) && osc.push({ value: item.id, label: `${item.first_name} ${item.last_name}` })
        item.roles.includes(ROLE_CSM) && csm.push({ value: item.id, label: `${item.first_name} ${item.last_name}` })
        vals.push({ value: item.id, label: `${item.first_name} ${item.last_name}` })
      }
    })

    return [
      ...divmgr.length ? [{ label: "DIVISION MANAGER", options: divmgr }] : [],
      ...csm.length ? [{ label: "CSM", options: csm }] : [],
      ...osc.length ? [{ label: "OSC", options: osc }] : [],
    ]
  }

  const onChangeHandler = (e) => {
    setLoginAs(e.value);
  }

  const onClickHandler = () => {
    handle_login_as(loginAs)
  }

  // clears loginAs cookie by setting expiry to past date
  const exitViewHandler = () => {
    setLoginAs(null);
    handle_login_as();
  }

  const dialogBody =
    <Select
      options={groupOptions(getAllUsers?.getAllUsers)}
      onChange={onChangeHandler}
    />

  const handleViewClick = () => {
    setOpenModal(true)
  }

  return (
    <>
      {(is_manager() || is_divmgr()) && !is_logged_in_as() &&
        <CustomButton
          color="black"
          onClick={handleViewClick}
          btnValue="View As"
          className={"ml-2 px-2 py-1 btn-sm view-as-btn"}
        />
      }
      {is_logged_in_as() &&
        <CustomButton
          onClick={exitViewHandler}
          btnValue="Exit"
          className={"ml-2 px-2 py-1 btn-sm"}
        />
      }

      {openModal &&
        <Dialog
          title="Please Select a User"
          show={openModal}
          onHide={() => {
            setOpenModal(false);
            setLoginAs(null)
          }
          }
          body={dialogBody}
          clickname="Change View"
          click={onClickHandler}
          disabled={!loginAs && true}
        />
      }
    </>
  )
};

export default compose(
  graphql(GET_USER, { name: "getUser" })
)(ViewAs);