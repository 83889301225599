import React from "react";
import PropTypes from "prop-types";

/**
 * it is used to display and change the number of records to be shown on each page of the tables.
 * @param {number} pageSize number of records to be shown on each page
 * @param {function} setPageSize
 * @returns {JSX.Element}
 */
const PageSize = ({ pageSize, setPageSize }) => {
  return (
    <select
      value={pageSize}
      onChange={e => {
        setPageSize(Number(e.target.value));
      }}
    >
      {[10, 50, 100, 250, 1000, 1050].map((pageSize, index) => (
        <option key={index} value={pageSize}>
          Show {index === 5 ? " All" : pageSize}
        </option>
      ))}
    </select>
  );
};

PageSize.propTypes = {
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired
};

export default PageSize;
