import React from "react";
import { NavLink } from "react-router-dom";
import { routes } from "../";
import { Auth } from "../../rbac/rbac";
import "./layout.css";
var appVersion = require("../../../package.json");

/**
 * This component is used to render a static component with application name and version number. It is displayed on
 * the top left corner.
 */
const HomeNavigation = () => {
  return (
    <div className="navbar-brand" style={{ borderRight: "none" }}>
      <NavLink to={routes.HOME} id="home-nav-link">
        <span>PULSE</span>
      </NavLink>
      <div className="d-flex justify-content-center">
        <NavLink
          id="version-text"
          className="version text-muted"
          to={routes.VERSION_HISTORY}
        >
          v{appVersion.version}
        </NavLink>
      </div>
    </div>
  );
};

export default HomeNavigation;
