import React from "react";
import { Form, Field, withFormik } from "formik";
import { ToggleButton, ButtonGroup } from "react-bootstrap";
import { compose, graphql } from "react-apollo";
import {
  webformFilterSchema,
  webformFilterForm,
} from "./webform-filter-schema";
import SelectField from "../../components/fields/select-field";
import CheckboxField from "../../components/fields/checkbox-field";
import CustomButton from "../../components/custom-button";
import { isNotNULL } from "../../utils/helpers";
import { GET_ALL_WEBFORM_TYPES } from "../../graphql/queries";
import "./style.css";

const transformWebFormTypeObject = (webFormTypes) => {
  if (isNotNULL(webFormTypes.getAllWebFormTypes))
    return webFormTypes.getAllWebFormTypes.map(formType => formType.name)
  return null;
}

/**
 * This component is used to render webform filter form, which has only one field. It contains a select field to 
 * filter on the basis of type of form submissions. 
 * On submit setFilter from the table is called to set the filter inside table state, which is turn triggers the table
 * data refetch.  
 * 
 * @param {Object} props 
 */
const WebformFilterForm = ({ getAllWebFormTypes, ...props }) => {
  const { loading } = getAllWebFormTypes;
  const allFormTypes = transformWebFormTypeObject(getAllWebFormTypes)
  if (loading) {
    return allFormTypes
  }
  return (
    <Form className="filter-menu">
      <div className="form-row">
        <div className="col-md-12">
          <Field
            id="unresponded"
            label="Unresponded"
            name="unresponded"
            component={CheckboxField}
          />
        </div>
        <div className="col-md-12">
          <Field
            id="unconverted"
            label="Unconverted"
            name="unconverted"
            component={CheckboxField}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="col-md-12">
          <Field
            name="type"
            label="Types"
            id="type"
            classStyle="filter-select-field"
            component={SelectField}
          >
            <option value="">All Types</option>
            {allFormTypes.map((type, index) => (
              <option key={index} value={index + 1}>
                {type}
              </option>
            ))}
          </Field>
        </div>
      </div>

      <div className="form-row">
        <CustomButton
          type="submit"
          btnValue="APPLY FILTERS"
          className="filter-apply-btn"
        />
      </div>
    </Form>
  );
};

const WebFormFilterFormWithFormik = withFormik({
  mapPropsToValues: (props) => {
    const { filter } = props;
    const allFormTypes = transformWebFormTypeObject(props.getAllWebFormTypes)
    const index = allFormTypes?.indexOf(filter?.form_type);
    return isNotNULL(filter)
      ? {
        type: index > -1 ? index + 1 : "",
        unresponded: filter.unresponded,
        unconverted: filter.unconverted
      }
      : webformFilterForm;
  },
  handleSubmit: (values, { props }) => {
    const allFormTypes = transformWebFormTypeObject(props.getAllWebFormTypes)
    props.setShow(false);
    props.setFilter({
      form_type: values.type && allFormTypes[values.type - 1],
      unresponded: values.unresponded,
      unconverted: values.unconverted
    });
  },
  displayName: "webform-filter-form",
  enableReinitialize: true,
  validationSchema: webformFilterSchema,
})(WebformFilterForm);

export default compose(
  graphql(GET_ALL_WEBFORM_TYPES, {
    name: "getAllWebFormTypes"
  })
)(WebFormFilterFormWithFormik);