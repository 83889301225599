import { graphql, compose } from "react-apollo";

import MarketingOnlyDetailView from "./marketing-only-details";
import { GET_MARKETING_ONLY_DETAIL } from "../../../graphql/queries";
import { SET_NOTIFICATION_PROPS } from "../../../cacheql/mutations";

const MarketingOnlyDetailContainer = compose(
  graphql(GET_MARKETING_ONLY_DETAIL, {
    name: "getMarketingOnlyDetail",
    options: props => {
      return {
        variables: {
          filter: {
            id: parseInt(props.match.params.id)
          }
        },
        fetchPolicy: "no-cache"
      };
    }
  }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" })
)(MarketingOnlyDetailView);

export default MarketingOnlyDetailContainer;