import { compose, graphql } from "react-apollo";
import HomeownerForm from "./edit-homeowner-form";
import { UPDATE_HOMEOWNER } from "../../../graphql/mutations";
import {
  GET_ALL_STATES,
  GET_CUSTOMER_ORIGINATION_AREA
} from "../../../graphql/queries";
import { SET_NOTIFICATION_PROPS } from '../../../cacheql/mutations'

const EditHomeownerForm = compose(
  graphql(GET_ALL_STATES, { name: "getAllStates" }),
  graphql(GET_CUSTOMER_ORIGINATION_AREA, { name: "getOriginationAreas" }),
  graphql(UPDATE_HOMEOWNER, { name: "updateHomeowner" }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" })
)(HomeownerForm);

export default EditHomeownerForm;