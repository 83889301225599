import * as actionTypes from "./action-types";

/**
 * this custom hook handles selection state on table rows and select all in table headers.
 * @param {object} state 
 * @param {function} dispatch 
 * @returns {object}
 */
export default function useRowSelect(state, dispatch) {
  const { rows, currentPage, pageSize, data, controlled } = state;

  const isSelectedAll = page => {
    const selectedRecords = page.filter(rec => rec.isSelected).length;
    return selectedRecords === page.length && selectedRecords > 0;
  };

  const toggleSelectAll = isSelectedAll => {
    
    const pageStart = controlled? 0 : pageSize * currentPage;
    const pageEnd = controlled ? pageSize : pageStart + pageSize;
    
    for (let i = pageStart; i < pageEnd && i < rows.length; i++) {
      rows[i].isSelected = isSelectedAll;
      data[i].isSelected = isSelectedAll;
    }
    dispatch({
      type: actionTypes.TOGGLE_ALL,
      payload: { rows, isSelectedAll, data }
    });
  };

  const toggleRowSelect = (uuid, rowState) => {
    const index = rows.findIndex(row => row.uuid === uuid);
    const dataIndex = data.findIndex(row => row.uuid === uuid);

    rows[index].isSelected = rowState;
    data[dataIndex].isSelected = rowState;
    dispatch({ type: actionTypes.TOGGLE_ROW, payload: { rows, data } });
  };

  const getSelectedRows = () => {
    return rows.filter(rec => rec.isSelected);
  };

  const canAction = () => {
    const selectRow = rows.find(rec => rec.isSelected);
    return selectRow ? false : true;
  };

  return {
    isSelectedAll,
    toggleSelectAll,
    toggleRowSelect,
    getSelectedRows,
    canAction: canAction()
  };
}
