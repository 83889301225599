import React, { useEffect, useState } from "react";
import _ from "lodash";

import { CardDeck } from "react-bootstrap";
import Dialog from "../../components/dialog/dialog";
import CustomerDetailsCard from "./customer-details-card";
import MergeCustomerFormContainer from "./merge-customer-form-container";

/**
 * This component renders dialog box containing cards showing data of the customers selected to be merged and a form with pre-filled
 * fields from the first two selected customers. User can edit the form by observing the data in the cards and either click the
 * merge button to submit the form or close the dialog box.
 * @param {Object} props
 * @param {Boolean} props.show show or hide dialog box
 * @param {Function} props.onHide callback function which is called when no is pressed
 * @param {Function} props.onSubmit a callback function that closes all dialog boxes when the form is successfully submitted
 * @param {Array} props.data data with which the form will be pre-filled
 */
const MergeCustomersDialog = ({
  show,
  onHide,
  onSubmit,
  data,
  communityData,
}) => {
  const [customerData, setCustomerData] = useState({});

  useEffect(() => {
    if (data && data.length > 1) {
      const customer_one = data[0];
      const customer_two = data[1];

      //Set data from the first two customers into the form
      const defaultCustomer = {
        first_name_1: customer_one.first_name_1,
        last_name_1: customer_one.last_name_1,
        primary_email_1: customer_one.primary_email_1,
        cell_phone_1: customer_one.cell_phone_1,
        secondary_emails_1: customer_one.secondary_emails_1,
        first_name_2: customer_one.first_name_2 || customer_two.first_name_1,
        last_name_2: customer_one.last_name_2 || customer_two.last_name_1,
        primary_email_2:
          customer_one.primary_email_2 || customer_two.primary_email_1,
        cell_phone_2: customer_one.cell_phone_2 || customer_two.cell_phone_1,
        secondary_emails_2:
          customer_one.secondary_emails_2 || customer_two.secondary_emails_1,
        city: customer_one.city || customer_two.city || null,
        state_id: customer_one.state
          ? customer_one.state.id
          : customer_two.state
            ? customer_two.state.id
            : null,
        realtor_id: customer_one.realtor ? customer_one.realtor.id : customer_two.realtor ? customer_two.realtor.id : null,
        customer_origination_area_id: customer_one.customer_origination_area
          ? customer_one.customer_origination_area.id
          : customer_two.customer_origination_area
            ? customer_two.customer_origination_area.id
            : null,
        zip: customer_one.zip || customer_two.zip || null,
        street_address:
          customer_one.street_address || customer_two.street_address || null,
        division_id: customer_one.division ? customer_one.division.id
          : customer_one.divisions ? customer_one.divisions[0].id
            : customer_two.division ? customer_two.division.id
              : customer_two.divisions ? customer_two.divisions[0].id
                : null,
      };

      setCustomerData(defaultCustomer);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      let lead_communities = []
      let prospect_communities = []

      communityData.map(customer_communities => {
        if (customer_communities) {
          customer_communities.lead_communities.length && customer_communities.lead_communities.map(community => lead_communities.push(community))
          customer_communities.prospect_communities.length && customer_communities.prospect_communities.map(community => prospect_communities.push(community))
        }
      })

      //If customer has a prospect community of the same id, then the community is removed from lead
      lead_communities = _.differenceBy(lead_communities, prospect_communities, 'id')

      //Remove duplicates from the lead and prospect communities
      lead_communities = _.uniqBy(lead_communities, 'id')
      prospect_communities = _.uniqBy(prospect_communities, 'id')

      //Append data to customer data
      setCustomerData(prevState => {
        return {
          ...prevState,
          lead_communities: lead_communities.length ? lead_communities.map(community => community.name).join(', ') : "-",
          prospect_communities: prospect_communities.length ? prospect_communities.map(community => community.name).join(', ') : "-"
        }
      })
    }
  }, [communityData]);

  /**
   * A function to obtain the customers ids from customers selected
   * @param {Array} data
   */
  const getCustomerIds = (data) => {
    return data.map((customer) => {
      return customer.customer_id || customer.id;
    });
  };

  return (
    <Dialog
      show={show}
      onHide={onHide}
      title="Merge Customer"
      dialogClassName="modal-98w"
      body={
        <div style={{ overflow: 'hidden', overflowX: 'auto' }}>
          <div className="dialog-merge" style={{ width: `${(350 * data.length) + 800}px` }}> {/*  increment width proerpty here by adding 350px everytime a new card added. */}
            {data.map((customer) => {
              const customerCommunities =
                communityData.find((communities) => communities.id === customer.customer_id || communities.id === customer.id)
                || [];
              return (
                <CustomerDetailsCard
                  data={customer}
                  communityData={customerCommunities}
                />
              );
            })}
            <div className="merge-form" style={{ margin: '0 10px' }}>
              <MergeCustomerFormContainer
                data={customerData}
                closeDialog={onHide}
                customer_ids={getCustomerIds(data)}
                onSubmit={onSubmit}
              />
            </div>
          </div>
        </div>
      }
    />
  );
};

export default MergeCustomersDialog;
