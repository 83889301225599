import gql from 'graphql-tag';

const MERGE_CUSTOMERS = gql` 
  mutation mergeCustomers($input: CustomerMergeInput!, $customer_ids: [Int]) {
    mergeCustomers(input: $input, customer_ids: $customer_ids) {
      message
      code
      success
    }
  }
`;

export default MERGE_CUSTOMERS;