import gql from "graphql-tag";

const GET_PROSPECT_ON_EDIT = gql`
  query getProspectOnEdit($filter: ProspectFilter) {
    getProspects(filter: $filter) {
      Prospects {
        id
        first_name_1
        last_name_1
        primary_email_1
        cell_phone_1
        secondary_emails_1
        first_name_2
        last_name_2
        primary_email_2
        cell_phone_2
        secondary_emails_2
        primary_email_1_status
        primary_email_2_status
        motivation_what_changed
        motivation_uncovered
        current_dissatisfaction
        future_promise
        cost_feat_objections
        other_notes
        other_notes_updated_at
        next_step
        next_step_updated_at
        zip
        city
        division{
          id
          name
        }
        osc_notes
        customer_origination_area {
          id
          name
        }
        state {
          id
          name
        }
        street_address
        realtor{
          id
          first_name
          last_name
          primary_email
          cell_phone
        }
        community {
          id
          name
          division_id
          is_active
        }
        rank {
          id
        }
        home_sites {
          id
          name
        }
        floor_plans {
          id
          name
        }
        others {
          id
          name
        }
        csm {
          id
          is_active
          first_name
          last_name
        }
        reservation
        video_text
        red_flag {
          description
        }
      }
    }
  }
`;
export default GET_PROSPECT_ON_EDIT;
