
import gql from 'graphql-tag';

const GET_LEAD_FILTERS = gql`
   query{
    getLeadFilters{
        id
        filter_name
    }
   }
   `;
export default GET_LEAD_FILTERS;