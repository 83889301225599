import React, {useState} from 'react'
import { FaHistory } from "react-icons/fa";

import CustomButton from "../../components/custom-button";
import HistoryLifeCycle from './history-lifecycle'

/**
 * this component is used to render the history button that opens the history table drawer
 * @param {object} props component object
 * @param {number} props.id id of the customer
 * @param {string} props.name name of the customer
 */
const HistoryButton = (props) =>{
  const [showSideBar, setShowSideBar] = useState(false)

  return(
    <>
      <div className="edit-button">
        <CustomButton
          size="sm"
          color="black"
          btnValue="History"
          btnIcon={<FaHistory />}
          onClick={()=>setShowSideBar(!showSideBar)}
        />
      </div>

      {
        showSideBar && ( 
          <HistoryLifeCycle 
            show={showSideBar}
            name={props.name}
            id={props.id}
            toggleDrawer={() => {
              setShowSideBar(!showSideBar );
            }}
          />
        )
      }
    </>
  )
}

export default HistoryButton