import React, { useState, useEffect, useContext } from "react";
import { Form, Field, withFormik } from "formik";
import { ButtonToolbar, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaBan } from "react-icons/fa";

import Dialog from "../../../components/dialog/dialog";
import TextField from "../../../components/fields/text-field";
import TextAreaField from "../../../components/fields/text-area";
import SelectField from "../../../components/fields/select-field";
import CustomButton from "../../../components/custom-button/index";
import ErrorFocus from "../../../components/error-focus/error-focus";
import AddEmailField from "../../../components/fields/add-email-field";
import MultiSelectField from "../../../components/fields/multi-select-field";
import { phoneDisplay } from "../../../utils/helpers";
import * as constants from "../../../utils/constants";
import { Auth } from "../../../rbac/rbac";

import { marketingOnlyForm, marketingOnlySchema } from "./marketing-only-form-schema";

/**
 * This component is responsible to render the create edit form of the marketing only. It has text fields, text area, multiselect and add email select fields.
 * First name, last name and either primary or cell phone must be filled to submit the form. Primary emails of buyer one and 2 must not be similar.
 * Primary email must not match any of the secondary emails. These are the most important validations of this form.
 * On form submit it checks whether to create, edit, convert or recover and it performs the appropriate mutation accordingly.
 * 
 * @param {Object} props formik-bag, and query/mutations passed from the container
 * @param {Object} props.data data formatted in the form of schema
 */
const MarketingOnlyForm = (props) => {
  const {
    values,
    status,
    getOriginationAreas,
    setFieldValue,
    convertFormType,
    inActiveCommunities,
  } = props;
  const { is_csm } = useContext(Auth)

  const [primarySecondaryClicked1, setPrimarySecondaryClicked1] = useState({})
  const [primarySecondaryClicked2, setPrimarySecondaryClicked2] = useState({})
  const [primaryClicked, setPrimaryClicked] = useState({})
  const [tooltipMessage, setTooltipMessage] = useState("")

  const findAndSetCustomerOrigination = (toFind, originationList) => {
    let result = originationList.find((item) => item.name === toFind);
    result && setFieldValue("customer_origination_area_id", result.id);
  }

  const handleMergeBody = () => {
    return (
      <div>
        {status.showAddMarketingOnlyErrorMessage}
        {status.showAddMarketingOnlyErrorMarketingOnlyCommunities?.length > 0 ? (
          <div>
            <p>
              This customer exists as a Marketing Only customer in the following communities:
            </p>
            {status.showAddMarketingOnlyErrorMarketingOnlyCommunities.map((item, index) => (
              <strong>{item.name}</strong>
            ))}
          </div>
        ) : null}
        {status.showAddMarketingOnlyErrorLeadCommunities?.length > 0 ? (
          <div>
            <p>
              This customer exists as a Lead in the following communities:
            </p>
            {status.showAddMarketingOnlyErrorLeadCommunities.map((item, index) => (
              <strong>{item.name}</strong>
            ))}
          </div>
        ) : null}
        {status.showAddMarketingOnlyErrorProspectCommunities?.length > 0 ? (
          <div>
            <p>
              This customer exists as a Prospect in the following communities:
            </p>
            {status.showAddMarketingOnlyErrorProspectCommunities.map((item, index) => (
              <strong>{item.name}</strong>
            ))}
          </div>
        ) : null}
        <p>Do you want to merge?</p>
      </div>
    )
  }

  const handleCancelMerge = () => {
    props.setStatus({
      showAddMarketingOnlyError: false,
      showAddMarketingOnlyErrorMessage: null,
      showAddMarketingOnlyErrorMarketingOnlyCommunities: null,
      showAddMarketingOnlyErrorProspectCommunities: null,
    })
  }

  const handleCloseArchiveDialog = () => {
    props.setStatus({
      showArchivedMarketingOnlyError: false,
      showArchivedMarketingOnlyErrorMessage: null,
    })
  }

  const handleMerge = () => {
    let submitValues = Object.assign({}, values);
    submitValues = normalizeData(submitValues);

    props.addMarketingOnlyDuplicate({
      variables: { input: submitValues },
    }).then((res) => {
      if (res.data.addMarketingOnlyDuplicate.code === 200) {
        props.onSubmit();
        props.setNotificationProps({
          variables: {
            open: true,
            message: res.data.addMarketingOnlyDuplicate.message,
          }
        })
      }
    })

    handleCancelMerge()
  }

  useEffect(() => {
    if (getOriginationAreas?.getAllCustomer_Origination_Areas) {
      if (convertFormType === "WEBFORM")
        findAndSetCustomerOrigination(
          constants.WEB_ORIGINATION_SOURCE,
          getOriginationAreas.getAllCustomer_Origination_Areas
        );
    }
  }, [getOriginationAreas, convertFormType])

  useEffect(() => {
    let tooltipString = "Inactive Communities: "

    inActiveCommunities && inActiveCommunities.forEach((community, index) => {
      tooltipString = inActiveCommunities.length - 1 === index
        ? tooltipString + community.name
        : tooltipString + community.name + ",";
    })

    setTooltipMessage(tooltipString)
  }, [inActiveCommunities])

  return (
    <Form className={"form-horizontal"} noValidate>
      <Dialog
        show={status ? status.showAddMarketingOnlyError : false}
        size="md"
        title="Creation Issue"
        onHide={handleCancelMerge}
        body={status ? handleMergeBody() : null}
        click={handleMerge}
        clickname="YES"
        closename="NO"
      />
      <Dialog
        show={status ? status.showArchivedMarketingOnlyError : false}
        size="md"
        title="Creation Issue"
        onHide={handleCloseArchiveDialog}
        body={status ? status.showArchivedMarketingOnlyErrorMessage : null}
        click={handleCloseArchiveDialog}
        clickname="OK"
      />

      <div className="form_TitleContainer">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ margin: "0 1.1rem" }}
        >
          <div>
            <div className="form_Title">Marketing Only</div>
            <div className="form_TitleBig mt-1">
              <div className="d-flex justify-content-center align-items-center">
                {inActiveCommunities && inActiveCommunities.length ? (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip style={{ zIndex: 2022 }}>
                        {tooltipMessage}
                      </Tooltip>
                    }
                  >
                    <FaBan size={18} className="td-warning" />
                  </OverlayTrigger>
                ) : null}
                <h2>
                  {props.editForm
                    ? values.first_name_1 + " " + values.last_name_1
                    : "Create New"}
                </h2>
              </div>
            </div>
          </div>
          <div className="d-flex pr-3">
            <ButtonToolbar>
              <CustomButton
                color="black"
                className={`form_CancelButton`}
                onClick={props.close}
                btnValue="CANCEL"
              />
              <CustomButton
                className={`form_SubmitButton`}
                type="submit"
                disabled={props.isSubmitting}
                btnValue={props.editForm ? "UPDATE" : "CREATE"}
              />
            </ButtonToolbar>
          </div>
        </div>
      </div>
      <div className="form_body">
        <div className="form-row">
          <div className="form-section">
            <h5>Buyer #1</h5>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <Field
              label="First Name"
              name="first_name_1"
              id="first_name_1"
              type="text"
              required={true}
              component={TextField}
            />
          </div>
          <div className="col-md-6">
            <Field
              label="Last Name"
              name="last_name_1"
              id="last_name_1"
              type="text"
              required={true}
              component={TextField}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <div className="col-md-12 padding-less">
              <Field
                label="Primary Email"
                name="primary_email_1"
                id="primary_email_1"
                disable={props.data && props.data.disable_primary_email_1}
                type="email"
                refFields={[
                  {
                    value: primarySecondaryClicked1,
                    setter: setPrimarySecondaryClicked1,
                  },
                  { value: primaryClicked, setter: setPrimaryClicked },
                ]}
                required={!values.formatted_cell_phone_1 || false}
                component={TextField}
              />
            </div>
            <div className="col-md-6 padding-less">
              <Field
                label="Cell #"
                name="formatted_cell_phone_1"
                id="formatted_cell_phone_1"
                isCellPhone={true}
                type="text"
                required={!values.primary_email_1 || false}
                component={TextField}
              />
            </div>
          </div>
          <div className="col-md-6">
            <Field
              label="Secondary Email(s)"
              name="secondary_emails_1"
              id="secondary_emails_1"
              initValue={
                props.editForm || props.recoverForm
                  ? values.secondary_emails_1
                  : []
              }
              type="text"
              refFields={[
                {
                  value: primarySecondaryClicked1,
                  setter: setPrimarySecondaryClicked1,
                },
              ]}
              component={AddEmailField}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-section">
            <h5>Buyer #2</h5>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <Field
              label="First Name"
              name="first_name_2"
              id="first_name_2"
              type="text"
              component={TextField}
            />
          </div>
          <div className="col-md-6">
            <Field
              label="Last Name"
              name="last_name_2"
              id="last_name_2"
              type="text"
              component={TextField}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <div className="col-md-12 padding-less">
              <Field
                label="Primary Email"
                name="primary_email_2"
                id="primary_email_2"
                disable={props.data && props.data.disable_primary_email_2}
                type="email"
                refFields={[
                  {
                    value: primarySecondaryClicked2,
                    setter: setPrimarySecondaryClicked2,
                  },
                  { value: primaryClicked, setter: setPrimaryClicked },
                ]}
                component={TextField}
              />
            </div>
            <div className="col-md-6 padding-less">
              <Field
                label="Cell #"
                name="formatted_cell_phone_2"
                id="formatted_cell_phone_2"
                isCellPhone={true}
                type="text"
                component={TextField}
              />
            </div>
          </div>
          <div className="col-md-6">
            <Field
              label="Secondary Email(s)"
              name="secondary_emails_2"
              id="secondary_emails_2"
              initValue={
                props.editForm || props.recoverForm
                  ? values.secondary_emails_2
                  : []
              }
              type="text"
              refFields={[
                {
                  value: primarySecondaryClicked2,
                  setter: setPrimarySecondaryClicked2,
                },
              ]}
              component={AddEmailField}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-section">
            <h5>Optional Fields</h5>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-12">
            <Field
              label="OSC Notes"
              name="osc_notes"
              id="osc_notes"
              type="text"
              disable={is_csm()}
              rows={3}
              component={TextAreaField}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-12">
            <Field
              label="Address"
              name="street_address"
              id="street_address"
              type="text"
              component={TextField}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <Field
              label="City"
              name="city"
              id="city"
              type="text"
              component={TextField}
            />
          </div>
          <div className="col-md-4">
            <Field
              label="State"
              name="state_id"
              id="state_id"
              component={SelectField}
            >
              <option value="">Select...</option>
              {props.getAllStates.getAllStates &&
                props.getAllStates.getAllStates.map((state, index) => (
                  <option key={index} value={state.id}>
                    {state.name}
                  </option>
                ))}
            </Field>
          </div>
          <div className="col-md-2">
            <Field
              label="Zip"
              name="zip"
              id="zip"
              type="text"
              component={TextField}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <Field
              label="Division"
              name="division_id"
              id="division_id"
              required={true}
              fieldsToClear={[{ fieldName: "Marketing_Only.communities", value: [] }]}
              component={SelectField}
            >
              <option value="">Select...</option>
              {props.getAllDivisions.getAllDivisions &&
                props.getAllDivisions.getAllDivisions.map((option, index) => (
                  <option key={index} value={option.id}>
                    {option.name}
                  </option>
                ))}
            </Field>
          </div>
          <div className="col-md-6">
            <Field
              label="Communities"
              name="Marketing_Only.communities"
              id="Marketing_Only.communities"
              menuPosition="top"
              disabled={!values.division_id && (!values.communities?.length || !values.Marketing_Only.communities?.length)}
              component={MultiSelectField}
              children={props.getAllCommunities?.getAllCommunities?.filter(comm =>
                comm.is_active && comm.division_id === values.division_id
              )}
              defaultValue={values.Marketing_Only.communities || null}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <Field
              label="Marketing Only Source"
              name="customer_origination_area_id"
              id="customer_origination_area_id"
              component={SelectField}
            >
              <option value="">Select...</option>
              {props.getOriginationAreas.getAllCustomer_Origination_Areas &&
                props.getOriginationAreas.getAllCustomer_Origination_Areas.map(
                  (option, index) => (
                    <option key={index} value={option.id}>
                      {option.name}
                    </option>
                  )
                )}
            </Field>
          </div>
        </div>
        <div className='form-row'>
          <div className='col-md-6'>
            <Field
              label="Marketing Only Notes"
              name="Marketing_Only.other_notes"
              id="Marketing_Only.other_notes"
              type="text"
              rows={3}
              component={TextAreaField}
            />
          </div>
        </div>
        <ErrorFocus />
      </div>
    </Form>
  );
};
const updateMarketingOnlySubmit = (values, props, setSubmitting) => {
  delete values.communities

  props.updateMarketingOnly({
    variables: { input: values }
  }).then((res) => {

    setSubmitting(false)
    let message = null

    if (res.data.updateMarketingOnly.code === 200) {
      const isPresentInLoggedInCommunities =
        props.getLoggedInCommunities.getLoggedInUserCommunities.filter(item => values.Marketing_Only.communities.indexOf(item.id) !== -1)
      if (values.Marketing_Only.communities.length && !isPresentInLoggedInCommunities.length && props.isDetails) {
        props.history.push('/marketing_only-dashboard')
      } else {
        props.update()
      }
    }

    if (res.data.updateMarketingOnly.code === 409) {
      if (res.data.updateMarketingOnly.data && res.data.updateMarketingOnly.data.length >= 1) {
        let body = []
        body.push(res.data.updateMarketingOnly.message);
        res.data.updateMarketingOnly.data &&
          res.data.updateMarketingOnly.data.forEach((item) => {
            body.push(<div>{item.name}</div>)
          })
        message = body
      }
    }

    props.setNotificationProps({
      variables: { open: true, message: message || res.data.updateMarketingOnly.message },
    })

  })
}

const createMarketingOnlySubmit = (values, props, setStatus, setSubmitting) => {
  props
    .addMarketingOnly({
      variables: { input: values },
    })
    .then((res) => {
      setSubmitting(false);
      if (res.data.addMarketingOnly.code === 200) {
        props.onSubmit();
        props.setNotificationProps({
          variables: { open: true, message: res.data.addMarketingOnly.message },
        });
      } else if (res.data.addMarketingOnly.code === 449) {
        setStatus({
          showArchivedMarketingOnlyError: true,
          showArchivedMarketingOnlyErrorMessage: res.data.addMarketingOnly.message,
        });
      } else {
        setStatus({
          showAddMarketingOnlyError: true,
          showAddMarketingOnlyErrorMessage: res.data.addMarketingOnly.message,
          showAddMarketingOnlyErrorMarketingOnlyCommunities:
            res.data.addMarketingOnly.data.marketingOnlyCommunities,
          showAddMarketingOnlyErrorLeadCommunities:
            res.data.addMarketingOnly.data.leadCommunities,
          showAddMarketingOnlyErrorProspectCommunities:
            res.data.addMarketingOnly.data.prospectCommunities,
        });
      }
    });
};

//this section is used only when we recover archived to marketing only, its being directly call in handlesubmit!

const recoverMarketingOnlySubmit = (values, props, setSubmitting) => {
  props
    .addMarketingOnlyDuplicate({
      variables: { input: values, overwrite: true },
    })
    .then((res) => {
      setSubmitting(false);
      if (res.data.addMarketingOnlyDuplicate.code === 200) {
        props.onSubmit();
        props.setNotificationProps({
          variables: { open: true, message: res.data.addMarketingOnlyDuplicate.message },
        });
      }
    });
};

/**
 * This function is used to format submitted data back to the form in which backend expects it in variables of
 * mutation. delete some properties, reassign string values to  null etc.
 * @param {Object} submitValues 
 */
const normalizeData = (submitValues) => {
  delete submitValues.hidden_secondary_emails_2;
  delete submitValues.hidden_secondary_emails_1;
  delete submitValues.formatted_cell_phone_1;
  delete submitValues.formatted_cell_phone_2;
  delete submitValues.disable_primary_email_1;
  delete submitValues.disable_primary_email_2;

  delete submitValues.customer_id

  if (submitValues.primary_email_1 === "") {
    submitValues.primary_email_1 = null;
  }
  if (submitValues.cell_phone_1 === "") {
    submitValues.cell_phone_1 = null;
  }
  if (submitValues.primary_email_2 === "") {
    submitValues.primary_email_2 = null;
  }
  if (submitValues.cell_phone_2 === "") {
    submitValues.cell_phone_2 = null;
  }

  return submitValues;
};

export default withFormik({
  mapPropsToValues: (props) => {
    if (props.editForm) {
      const { cell_phone_1, cell_phone_2 } = props.data;
      const formatted_cell_phone_1 = phoneDisplay(cell_phone_1);
      const formatted_cell_phone_2 = phoneDisplay(cell_phone_2);
      return { formatted_cell_phone_1, formatted_cell_phone_2, ...props.data };
    } else if (props.convertForm || props.recoverForm) {
      return { ...props.data };
    }
    return { ...marketingOnlyForm };
  },

  handleSubmit: (values, { props, setSubmitting, setStatus }) => {
    let submitValues = Object.assign({}, values);

    submitValues = normalizeData(submitValues);

    props.recoverForm
      ? recoverMarketingOnlySubmit(submitValues, props, setSubmitting)
      : props.editForm
        ? updateMarketingOnlySubmit(submitValues, props, setSubmitting)
        : createMarketingOnlySubmit(submitValues, props, setStatus, setSubmitting);
  },
  validationSchema: marketingOnlySchema,
  displayName: "new-marketing-only-form",
})(MarketingOnlyForm);