import React, { useEffect, useMemo } from "react";
import TableView, {
  TextHeader,
  TextCellWithMapper,
  TextCell,
  ListCell,
} from "../../components/new-table";
import FadeIn from "../../components/fade-in";
import { formatActivityDate, getCardParams, getUpdatedParams } from "../../utils/helpers";
import reducer, { getInitialState } from "./conversion-report-reducer";
import CustomLoader from "../../components/custom-loader";

/**@module ConversionReportTableModule*/

/**
 * This component is a table used to display conversion information. This table does not contain any title, search
 * bar, and filters. It contains basic options like pagination and export to csv. It has no hover-actions. It shows 
 * multiple columns displaying basic information related to conversion. Table uses reducer to maintain its state. It 
 * also accesses store to check if table parameters are already stored or not.
 * 
 * @param {Object} props it contains all the mutations/queries in the conatiner as props
 * @param {Object} props.data it contains array of conversions to be shown on table
 * @param {Object} props.getTableParams it contains table info stored inside cache
 * @returns {JSX.Element} It returns jsx containing the table 
 */
const ConversionReportTable = ({
  data,
  getTableParams: { tableParams },
  ...props
}) => {
  const cacheParams = getCardParams('Conversion Report', tableParams);
  const [state, dispatch] = React.useReducer(
    reducer,
    getInitialState({ loading: data.loading, cache: cacheParams })
  );

  const { report, loading, render, userPageCount, pageSize } = state;

  const callDispatch = (type, payload) => {
    dispatch({
      type,
      payload
    });
  };

  /**
   * Either set the data in state or set the loading to inform the table what to render.
   */
  useEffect(() => {
    if (!data.loading)
      callDispatch("UPDATE", {
        render: true,
        loading: false,
        report:
          data &&
          data.getCustomerConversionReport &&
          data.getCustomerConversionReport.customerConversionReports,
        userPageCount: Math.ceil(
          data.getCustomerConversionReport.total_count / pageSize
        )
      });
    if (data.loading) callDispatch("UPDATE", { loading: data.loading });
  }, [data]);

  /**
   * This defines columns of the table. It has multiple columns, displaying information of the activities. None of
   * the columns are sortable.
   * 
   * @constant
   * @memberof module:ConversionReportTableModule
   */
  const columns = useMemo(
    () => [
      {
        id: "1",
        header: "Conversion Type",
        accessor: "conversion_type",
        component: TextHeader,
        cell: {
          component: TextCell
        }
      },
      {
        id: "2",
        header: "Customer Name",
        accessor: "customer_name",
        component: TextHeader,
        cell: {
          component: TextCell
        }
      },
      {
        id: "3",
        header: "User",
        accessor: "creator",
        component: TextHeader,
        sortable: false,
        csvMapper: (field, accessor) =>
          field[accessor]
            ? field[accessor].first_name + " " + field[accessor].last_name
            : "",
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) =>
            field[accessor] &&
            field[accessor].first_name + " " + field[accessor].last_name
        }
      },
      {
        id: "4",
        header: "Community",
        accessor: "communities",
        component: TextHeader,
        sortable: false,
        csvMapper: (field, accessor) =>
        field[accessor] ? field[accessor].map(({ name }) => name) : "",
        cell: {
          component: ListCell
        }
      },
      {
        id: "5",
        header: "Conversion Date & Time",
        accessor: "conversion_datetime",
        component: TextHeader,
        csvMapper: (field, accessor) => formatActivityDate(field[accessor]),
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) => formatActivityDate(field[accessor])
        }
      }
    ],
    []
  );

  
 /**
   * It is a callback function which is called on any table change like: pagination, page-size, filters, search term.
   * Table passes its current state so that we can update the table parameters in store and state, which are used in 
   * refetching the data with correct parameters.  
   * 
   * @function
   * @inner
   * @memberof module:ConversionReportTableModule
   * @see {@link module:ConversionReportTableModule~ConversionReportTable}
   */
  const fetchData = React.useCallback(tableProps => {
    if (loading) {
      return;
    }

    //variables to be stored in cache/store
    const variables = {
      filter: {
        ...tableProps.filter
      },
      limit: tableProps.pageSize,
      pageNum: tableProps.currentPage,
    };

    // update the state
    callDispatch("UPDATE", { ...variables, pageSize: tableProps.pageSize, loading: true });

    const pageCount = Math.ceil(
      data.getCustomerConversionReport.total_count / tableProps.pageSize
    );
    // update total page count in the state
    if (pageCount !== userPageCount)
      callDispatch("UPDATE", {userPageCount: pageCount});

    // update the store
    props
      .setTableParams({
        variables: {
          cardsParams: getUpdatedParams(tableParams.cardsParams, {
            cardTitle: "Conversion Report",
            params: variables
          })
        }
      })
      .then(res => {
        callDispatch("UPDATE", { loading: false });
      });
  });

  const tableOptions = {
    // title: '',
    // FilterItems: ReportFilter
  };

  return (
    <div>
      <FadeIn show={render}>
        <div>
          <TableView
            columns={columns}
            removeSearchField
            data={report}
            tableOptions={tableOptions}
            loading={loading}
            userPageCount={userPageCount}
            userTotalCount={
              !loading ? data.getCustomerConversionReport.total_count : null
            }
            fetchData={fetchData}
            currentPage={state.pageNum}
            pageSize={state.pageSize}
            filter={state.filter}
            controlled={true}
          />
        </div>
      </FadeIn>
      {!render && (
        <CustomLoader
          style={{ marginLeft: "auto", marginRight: "auto", zIndex: 9999 }}
        />
      )}
    </div>
  );
};

export default ConversionReportTable;
