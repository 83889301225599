import gql from 'graphql-tag';

const GET_TITLE_CUSTOMIZATIONS = gql`
  query {
    titleCustomizations @client{
      name
      value
    }
  }
`;


export default GET_TITLE_CUSTOMIZATIONS;