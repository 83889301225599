import gql from "graphql-tag";

const GET_QUICK_VIEW_PROSPECTS = gql`
  query getProspectsById($input: ProspectFilter){
    getProspects(filter:$input, limit:1) {
      Prospects{
        id
        first_name_1
        last_name_1
        community{
          id
          name
        }
      }
      total_count
    }
  }
`;

export default GET_QUICK_VIEW_PROSPECTS;
