import * as Yup from "yup";

export const upcomingFilterForm = {
  rank: { ids: [] },
  past_activities: false
};

export const upcomingFilterSchema = Yup.object().shape({
  rank: Yup.object().shape({
    ids: Yup.array()
  }),
  past_activities: Yup.bool().default(false)
});
