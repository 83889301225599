export const isObject = obj => {
  return obj && typeof obj === "object" && obj.constructor === Object;
};

export const isEmpty = obj => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export function isFunction(a) {
  if (typeof a === "function") {
    return a;
  }
}

export const sanitizeFilterObject = filter => {
  for (let propName in filter) {
    if (
      isObject(filter[propName])
        ? filter[propName].id === null || filter[propName].id === undefined
        : filter[propName] === null ||
          filter[propName] === undefined ||
          filter[propName] === "" ||
          propName === "advanceFilters" ||
          (Array.isArray(filter[propName]) &&
            filter[propName].every(el => el === null))
    ) {
      delete filter[propName];
    }
  }

  return filter;
};

export const sanitizeObject = obj => {
  delete obj.id;
  delete obj.__typename;

  return obj;
};

export const getObjectRowValue = (row, filterValues) => {
  const keys = Object.keys(filterValues);
  return row[keys[0]];
};

export const getDescendantProp = (obj, path) => {
  const val = path.split(".").reduce((acc, part) => acc && acc[part], obj);
  return (val === undefined || val === null) ? "" : val
};

export const getDescendantElements = (obj, path) => {
  const [first, attribute] = path.split(".");
  const values = obj[first]
    .filter(val => val && val[attribute] && val[attribute].name)
    .map(val => val[attribute].name);

  return [...new Set(values)];
};

export const removeDuplicates = (originalArray, prop) => {
  let newArray = [];
  let lookupObject = {};

  for (const item of originalArray) {
    if (!lookupObject[item[prop]]) {
      lookupObject[item[prop]] = item;
      newArray.push(item)
    }
  }

  return newArray;
};

export const getStringDescendantElementsWithEmptyVals = (arr, attribute) => {
  const values = arr
    .map(val => !val[attribute]? "---": val[attribute]);
  const filteredVals = values.filter(item=>item !== "---")
  return isObject(filteredVals[0])
    ? removeDuplicates(values, "id")
    : [...new Set(values)];
};

export const getStringDescendantElements = (arr, attribute) => {
  const values = arr
    .filter(val => val && val[attribute])
    .map(val => val[attribute]);
  return isObject(values[0])
    ? removeDuplicates(values, "id")
    : [...new Set(values)];
};

export const copyByValue = collection => {
  return JSON.parse(JSON.stringify(collection));
};
