import { compose, graphql } from "react-apollo";

import { GET_HOMEOWNER_BY_ID } from "../../../graphql/queries";
import { SET_NOTIFICATION_PROPS } from "../../../cacheql/mutations";
import HomeownerDetailsView from './homeowner-details'

export default compose(
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" }),
  graphql(GET_HOMEOWNER_BY_ID, {
    name: "getHomeownerDetail",
    options: props => {
      return {
        variables: {
          filter: { id: parseInt(props.match.params.id) }
        },
        fetchPolicy: "no-cache"
      }
    }
  })
)(HomeownerDetailsView);