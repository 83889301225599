import gql from 'graphql-tag';

const GET_HOMESITES = gql`
  {
    getAllHome_Sites {
      id,
      name
    }
  }
`;
export default GET_HOMESITES;