import gql from 'graphql-tag';

const UPDATE_LEAD_FOLLOW_UP = gql` 
  mutation updateLeadFollowUp($input: LeadFollowUpUpdateInput!) {
    updateLeadFollowUp(input: $input) {
      message
      code
    }
  }
`;

export default UPDATE_LEAD_FOLLOW_UP;
