import {
  formatDate,
  formatReportDateTime,
  phoneDisplay,
} from "../../utils/helpers";
import { getDescendantProp } from "./utils";

/**
 * this function exports selected rows to csv files.
 * @param {object} columns
 * @param {object} selectedRows
 */
export const downloadCSV = (columns, selectedRows) => {
  let csv = convertArrayOfObjectsToCSV(columns, selectedRows);
  if (csv == null) return;
  const link = document.createElement("a");
  link.setAttribute(
    "href",
    "data:text/csv; charset=utf-8," + encodeURIComponent(csv)
  );
  link.setAttribute("download", "spreadsheet.csv");
  document.body.appendChild(link);
  link.click();
};

/**
 * it is used to put the elements of the object in a prosper display format before exporting.
 * @param {*} columns
 * @param {*} selectedRows
 * @param {boolean} hideInExport specifies wether to add the column in the export
 */
const convertArrayOfObjectsToCSV = (columns, selectedRows,hideInExport) => {
  const headers = columns
    .map(({ header, csvHeader }) => (!hideInExport && header) || csvHeader)
    .filter((item) => item !== undefined);
  const rows = selectedRows.map((selectedRow) => {
    return columns.map(({ accessor, csvMapper, cell }) => {
      if(!hideInExport){
        if (csvMapper) {
          return `${csvMapper(selectedRow, accessor)}`;
        }
        //we are not using these conditions on production server thats why we added csvMapper to ever formatted columns
        if(cell.component){
          if (cell.component.name === "PhoneCell") {
            return `${phoneDisplay(selectedRow[accessor])}`;
          }
          if (cell.component.name === "DateCell") {
            return `${formatDate(selectedRow[accessor])}`;
          }
          if (cell.component.name === "DateTimeCell") {
            return `${formatReportDateTime(
              getDescendantProp(selectedRow, accessor)
            )}`;
          }
        }
        return selectedRow[accessor];
      }
      return null;

    });
  });

  let result = headers.join(",") + "\n";

  rows.forEach((rowArray) => {
    rowArray.forEach((item, index) => {
      if (
        typeof item === "string" &&
        (item.includes(",") || item.includes("\n"))
      ) {
        rowArray[index] = `"${rowArray[index]}"`;
      }
    });
    result += rowArray.filter((item) => item !== undefined).join(",") + "\r\n";
  });
  return result;
};
