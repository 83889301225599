import gql from "graphql-tag";

const GET_CALENDAR_DATE_RANGE = gql`
  query {
    selectedCalendarDateRange @client {
      start
      end
    }
  }
`;

export default GET_CALENDAR_DATE_RANGE;
