import gql from 'graphql-tag';

const ADD_LEAD_PHONE_CALL = gql` 
  mutation addLeadPhoneCall($input: AddLeadPhoneCallInput!) {
    addLeadPhoneCall(input: $input) {
      message
      code
    }
  }
`;

export default ADD_LEAD_PHONE_CALL;
