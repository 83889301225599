
import gql from 'graphql-tag';

const GET_PROSPECT_FILTERS = gql`
   query{
    getProspectFilters{
        id
        filter_name
    }
   }
   `;
export default GET_PROSPECT_FILTERS;