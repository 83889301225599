import React, { useState, useEffect } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Form, Field, withFormik } from "formik";
import { marketingOnlyFilterSchema, marketingOnlyFilterForm } from "./marketing-only-filter-form-schema";
import SelectField from "../../../components/fields/select-field";
import TextField from "../../../components/fields/text-field";
import DatePickerView from "../../../components/fields/date-picker";
import CustomButton from "../../../components/custom-button";
import ErrorFocus from "../../../components/error-focus/error-focus";
import {
  isEmpty,
  isNotNULL,
  sanitizeFilterObject,
  isDateInRange,
  isDateValid
} from "../../../utils/helpers";
import * as cardsType from "../../lead/dashboard/lead-card-types";
import "./marketing-only-form-styles.css";
import moment from "moment";

/**
 * This component is used to render in the marketing only filter popup.
 * It renders the actual marketing only filter form {@link MarketingOnlyFilterForm}
 * and the dropdown at the top to show the saved filters list.
 * On selection of any saved filter, its values are assigned to the marketing only filter form.
 * @param {Object} props
 */
const MarketingOnlyFilter = (props) => {
  const { currentCard } = props

  const [values, setValues] = useState({})
  const [isValid, setIsValid] = useState(true)
  const [appliedFilterName, setAppliedFilterName] = useState(null)

  const applySaveFilters = (filterId, filterName) => {
    setAppliedFilterName(filterName)

    if (!filterId) {
      setIsValid(true)
      setValues({})
    } else {
      props.getMarketingOnlyFilterDetail.fetchMore({
        variables: { input: filterId },
        updateQuery: (prev, { fetchMoreResult }) => {
          const { created_from, created_to } = fetchMoreResult.getMarketingOnlyFilter

          if (currentCard.type === cardsType.THIS_MONTH) {
            if (
              !isDateInRange(
                created_from,
                currentCard.filter.create_start_date,
                currentCard.filter.create_end_date
              ) ||
              !isDateInRange(
                created_to,
                currentCard.filter.create_start_date,
                currentCard.filter.create_end_date
              )
            ) {
              setIsValid(false)
              setValues({ ...marketingOnlyFilterForm })
            } else {
              setIsValid(true)
              setValues(fetchMoreResult.getMarketingOnlyFilter)
            }
          } else if (currentCard.type === cardsType.TODAY) {
            if (
              !isDateValid(
                created_from,
                currentCard.filter.create_start_date,
                currentCard.filter.create_end_date
              )
            ) {
              setIsValid(false)
              setValues({ ...marketingOnlyFilterForm })
            } else {
              setIsValid(true)
              setValues(fetchMoreResult.getMarketingOnlyFilter)
            }
          } else {
            setValues(fetchMoreResult.getMarketingOnlyFilter)
          }
        },
      })
    }
  }

  return (
    <>
      <div className="form-row align-items-center mb-3">
        <div className="col-6">
          <h6 className="mb-0">Filters</h6>
        </div>
        <div className="col-6" style={{ textAlign: "right" }}>
          <DropdownButton
            variant="secondary"
            id="filter-item-btn"
            key="down"
            title={appliedFilterName
              || props.filter.filter_name
              || "My Saved Filters"
            }
            className="filters-my-saved"
          >
            {isNotNULL(props.getMarketingOnlyFilters.getMarketingOnlyFilters) &&
              props.getMarketingOnlyFilters.getMarketingOnlyFilters.map((item) => {
                return (
                  <Dropdown.Item
                    onSelect={() => applySaveFilters(item.id, item.filter_name)}
                    key={item.id}
                    eventKey={item.id}
                  >
                    {item.filter_name}
                  </Dropdown.Item>
                )
              })}
          </DropdownButton>
        </div>
      </div>

      {!isValid && (
        <div className="filter-error">
          <label className="filter-error-text">
            This filter is not applicable on current tab
          </label>
        </div>
      )}

      <MarketingOnlyFilterFormikForm
        savedValues={values}
        isDisabled={!isValid}
        appliedFilterName={appliedFilterName}
        {...props}
      />
    </>
  )
}

/**
 * This component is used to render marketing only filter form. It contains created from-to fields as well as state, phone, marketing only source and in advance section.
 * At the bottom it contains an input field to name the filter and save it.
 * Apply filter button remains disabled unless some value on filter fields is selected.
 * Advance filter will show some additional fields to add in the filter.
 * On submit setFilter is called, which triggers table refetch.
 * @param {Object} props
 */
const MarketingOnlyFilterForm = (props) => {
  const {
    values,
    setFilter,
    setShow,
    currentCard,
    isDisabled,
    appliedFilterName
  } = props

  useEffect(() => {
    if (appliedFilterName !== null) {
      props.resetForm({ ...marketingOnlyFilterForm })
    }
  }, [appliedFilterName])

  const enableFormSubmit = ({ filter_name: _, ...valueCompareFrom }) => {
    const filters = sanitizeFilterObject(Object.assign({}, valueCompareFrom))
    return isEmpty(filters)
  }

  const handleFilters = () => {
    // const {filter_name: _, ...filterValue} = values
    /*
    To enable front end filtering, do not call this function, simply uncomment above
    code and pass 'filterValue' to 'setFilter'.
    */
    setFilter(getMarketingOnlyFilterInput(values, "APPLY", currentCard))
    setShow(false)
  }

  const advanceFilterHandler = () => {
    if (!values.advanceFilters) {
      props.setFieldValue("state_id", undefined)
      props.setFieldValue("cell_phone_1", "")
      props.setFieldValue("customer_origination_area_id", undefined)
    }
    props.setFieldValue("advanceFilters", !values.advanceFilters)
  }

  return (
    <Form className="filter-menu">
      <div className="form-row">
        <div className="col-6 col-md-6">
          <Field
            label="Created From"
            name="created_at[0]"
            id="created_at[0]"
            disabled={isDisabled}
            minDate={currentCard.type === cardsType.TODAY
              || currentCard.type === cardsType.THIS_MONTH
              || currentCard.type === cardsType.THIS_YEAR
              ? new Date(currentCard.filter.create_start_date)
              : null
            }
            maxDate={currentCard.type === cardsType.TODAY
              ? new Date(currentCard.filter.create_start_date)
              : currentCard.type === cardsType.THIS_MONTH
                || currentCard.type === cardsType.THIS_YEAR
                ? new Date(currentCard.filter.create_end_date)
                : new Date()
            }
            component={DatePickerView}
          />
        </div>
        <div className="col-6 col-md-6">
          <Field
            label="Created To"
            name="created_at[1]"
            id="created_at[1]"
            disabled={isDisabled}
            minDate={currentCard.type === cardsType.TODAY
              || currentCard.type === cardsType.THIS_MONTH
              || currentCard.type === cardsType.THIS_YEAR
              ? new Date(currentCard.filter.create_start_date)
              : null
            }
            maxDate={currentCard.type === cardsType.TODAY
              ? new Date(currentCard.filter.create_start_date)
              : currentCard.type === cardsType.THIS_MONTH
                || currentCard.type === cardsType.THIS_YEAR
                ? new Date(currentCard.filter.create_end_date)
                : new Date()
            }
            component={DatePickerView}
          />
        </div>
      </div>

      <div id="advanceFilters" hidden={!values.advanceFilters}>
        <div className="form-row">
          <div className="col-md-6">
            <Field
              name="state_id"
              label="State"
              id="state_id"
              classStyle="filter-select-field"
              disabled={isDisabled ? true
                : props.getAllStates.getAllStates ? false
                  : true
              }
              component={SelectField}
            >
              <option value="">Select...</option>
              {props.getAllStates.getAllStates &&
                props.getAllStates.getAllStates.map((state) => (
                  <option key={state.id} value={state.id}>
                    {state.name}
                  </option>
                ))}
            </Field>
          </div>
          <div className="col-6 col-md-6">
            <Field
              label="Phone"
              name="cell_phone_1"
              id="cell_phone_1"
              type="text"
              disabled={isDisabled}
              component={TextField}
              className="form-control"
            />
          </div>
        </div>

        <div className="form-row">
          <div className="col-md-6">
            <Field
              label="Marketing Only Source"
              name="customer_origination_area_id"
              id="customer_origination_area_id"
              classStyle="filter-select-field"
              disabled={isDisabled}
              component={SelectField}
              style={{ width: "100%" }}
            >
              <option value="">Select...</option>
              {props.getOriginationAreas.getAllCustomer_Origination_Areas &&
                props.getOriginationAreas.getAllCustomer_Origination_Areas.map(
                  (option, index) => (
                    <option key={index} value={option.id}>
                      {option.name}
                    </option>
                  )
                )}
            </Field>
          </div>
          <div className="col-md-6">
            <Field
              label="Marketing Only Notes"
              name="other_notes"
              id="other_notes"
              type="text"
              disabled={isDisabled}
              component={TextField}
              className="form-control"
            />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-12">
          <div className="form-row d-flex align-items-center pl-1 pr-1">
            <CustomButton
              btnValue="APPLY FILTERS"
              className="filter-apply-btn"
              disabled={isDisabled || enableFormSubmit(values)}
              onClick={handleFilters}
            />

            <CustomButton
              onClick={advanceFilterHandler}
              className="d-block"
              style={{
                border: "none",
                background: "none",
                color: "#80b602",
                opacity: 1,
                fontWeight: 400,
                fontSize: "14px",
                textTransform: "none",
              }}
            >
              {values.advanceFilters ? "Basic Filters" : "Advance Filters"}
            </CustomButton>
          </div>
        </div>
      </div>
      <hr className="light" />
      <h6 className="mb-3">My Filters</h6>
      <div className="form-row px-1 align-content-center">
        <Field
          label="Filter Name"
          name="filter_name"
          id="filter_name"
          type="text"
          disabled={isDisabled}
          component={TextField}
          style={{ borderColor: "rgba(255, 255, 255, .2)" }}
        />
        <div className="d-flex align-items-center ml-1 mt-1">
          <CustomButton
            btnValue="SAVE FILTER"
            type="submit"
            color="green"
            disabled={
              props.isSubmitting || isDisabled || enableFormSubmit(values)
            }
          />
        </div>
      </div>
      <ErrorFocus />
    </Form>
  )
}

/**
 * this function is used, when a filter is selected from the top dropdown, it checks if there are any advance filter values, if there are it shows the the field, otherwise it keeps them hidden.
 * @param {object} state
 * @param {string} phone
 * @param {string} note
 * @param {object} customerOriginationArea
 * @returns {boolean}
 */
const checkAdvanceFilter = (state, phone, note, customerOriginationArea) => {
  if (
    isNotNULL(state) ||
    isNotNULL(phone) ||
    isNotNULL(note) ||
    isNotNULL(customerOriginationArea)
  ) {
    return true
  }
  return false
}

/* 
 This function formats form-values to the expected 'apply-filter' 
 and 'save-filter' input format. Filter Schema saves values 
 differently so we can easily apply front-end filtering if we want. 
*/
const getMarketingOnlyFilterInput = (
  {
    customer_origination_area_id,
    state_id,
    cell_phone_1: phone,
    other_notes: note,
    created_at,
    filter_name
  },
  type,
  currentCard
) => {

  const common = {
    customer_origination_area_id: customer_origination_area_id || null,
    state_id: state_id || null,
    filter_name: filter_name?.trim(),
    phone,
    note
  }

  return type === "APPLY"
    ? {
      ...common,
      create_start_date:
        (created_at ||
          currentCard.type === cardsType.TODAY ||
          currentCard.type === cardsType.THIS_MONTH) &&
        (created_at[0] || currentCard.filter?.create_start_date || null),
      create_end_date:
        (created_at ||
          currentCard.type === cardsType.TODAY ||
          currentCard.type === cardsType.THIS_MONTH) &&
        (created_at[1] || currentCard.filter?.create_end_date || null)
    }
    : {
      ...common,
      created_from:
        created_at &&
        (created_at[0] ? moment(created_at[0]).format("LL") : null),
      created_to:
        created_at &&
        (created_at[1] ? moment(created_at[1]).format("LL") : null)
    }
}

/* 
 This function formats filter/saved values to the 
 expected form-values input format. 
*/
const getMarketingOnlyFormFilterInput = (toExtract, type) => {
  const { phone, note } = toExtract
  const common = {
    ...marketingOnlyFilterForm,
    other_notes: note,
    cell_phone_1: phone,
    filter_name: toExtract.filter_name
  }

  return type === "FILTER"
    ? {
      ...common,
      advanceFilters: checkAdvanceFilter(
        toExtract.state_id,
        phone,
        note,
        toExtract.customer_origination_area_id
      ),
      state_id: toExtract.state_id,
      customer_origination_area_id: toExtract.customer_origination_area_id,
      created_at: [toExtract.create_start_date, toExtract.create_end_date]
    }
    : {
      ...common,
      advanceFilters: checkAdvanceFilter(
        toExtract.State,
        phone,
        note,
        toExtract.Customer_Origination_Area
      ),
      state_id: toExtract.State?.id || null,
      customer_origination_area_id: toExtract.Customer_Origination_Area?.id || null,
      created_at: [
        toExtract.created_from &&
        moment(new Date(toExtract.created_from)).toDate(),
        toExtract.created_to &&
        moment(new Date(toExtract.created_to)).toDate(),
      ],
    }
}

const MarketingOnlyFilterFormikForm = withFormik({
  displayName: "marketing-only-filter-form",
  validationSchema: marketingOnlyFilterSchema,
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    const { filter, savedValues } = props
    const initValues = { ...marketingOnlyFilterForm }
    if (isNotNULL(savedValues) && Object.entries(savedValues).length > 0)
      return getMarketingOnlyFormFilterInput(savedValues, "SAVED")
    if (isNotNULL(filter) && Object.entries(filter).length > 0)
      return getMarketingOnlyFormFilterInput(filter, "FILTER")
    return initValues /*to enable front-end filtering do not format filter values, simply use 'filter'*/
  },
  handleSubmit: (values, { props, setSubmitting }) => {
    props.addMarketingOnlyFilter({
      variables: {
        input: getMarketingOnlyFilterInput(values, "SAVE", props.currentCard),
      },
    })
      .then((res) => {
        setSubmitting(false)
        props.onSaveFilter(res.data.addMarketingOnlyFilter.message)
        if (res.data.addMarketingOnlyFilter.code === 200) {
          props.getMarketingOnlyFilters.refetch()
        }
      })
  },
})(MarketingOnlyFilterForm)

export default MarketingOnlyFilter