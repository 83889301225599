import React, { useState } from "react";
import CustomButton from "../custom-button";
import Drawer from "../../hoc/Drawer";
import * as NoteContainers from "../../views/common-lead-prospect-forms/note-container";

/**
 * This component is used to render a button along with the drawer to open or close lead/prospect/homeowner/realtor notes.
 * On the basis of function it decides whether to render add or edit container.
 * On submit it calls the onSubmit callback and closes the drawer.
 * 
 * @param {Object} props
 * @param {Number} props.type type of entity i.e. lead/prospect/homeowner/realtor
 * @param {Number} props.id id of the entity
 * @param {Number} props.btnIcon icon to shown on button
 * @param {String} props.className classname for custom styles
 * @param {String} props.function to identify if it is edit or create form
 * @param {Object} props.notes notes to edit
 */
const NoteButton = (props) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const toggleShowDrawer = () => setShowSidebar(!showSidebar);

  const NoteContainer = (props.function === 'CREATE' ? "Create" : "Update") + props.type + "NoteContainer";
  const { [NoteContainer]: NoteComponent } = NoteContainers;

  const onSubmit = (message) => {
    setShowSidebar(false);
    props.onSubmit(message);
  };

  return (
    <>
      <CustomButton
        size="sm"
        color="green"
        btnValue={props.btnValue}
        className={props.className}
        btnIcon={props.btnIcon}
        onClick={toggleShowDrawer}
        style={props.style}
      />
      <Drawer show={showSidebar} toggleSideBar={toggleShowDrawer}>
        <NoteComponent
          id={props.id}
          close={toggleShowDrawer}
          onSubmit={onSubmit}
          function={props.function}
          type={props.type}
          notes={props.notes || null}
        />
      </Drawer>
    </>
  );
};

export default NoteButton;