import React from "react";
import ContentGrid from "../../components/grid/content-grid";
import DashboardSelectBar from "../dashboard/dashboard-select-bar";
import VersionHistoryView from "./version-history-view";

/**
 * This component uses {@link ContentGrid} to align the content. This is used to render version history view.
 * @param {Object} props 
 */
const VersionHistory = (props) => {
  return (
    <ContentGrid
      id="version-history"
      selectbar={<DashboardSelectBar type="History" pageTitle="All Versions" {...props} />}
      content={{
        data: <VersionHistoryView />,
      }}
    />
  );
};

export default VersionHistory;
