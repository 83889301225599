import React, { useState, useEffect, useMemo } from "react";
import TableView, {
  SortableHeader,
  stringComparator,
  TextCell,
  TextHeader,
  TextCellWithMapper,
  dateComparator
} from "../../components/new-table";

import { formatActivityDate } from "../../utils/helpers";
import FadeIn from "../../components/fade-in";
import CustomLoader from "../../components/custom-loader";

/**
 * This table has three columns displaying basic information related to the customer's lifecyle. It contains 
 * pagination, searchbar, and export to csv options. All these operations are performed on front end side.
 * @param {Object} props 
 * @param {Object} props.data this object contains data to be shown on details table
 * @returns {JSX.Element} it renders the table component along with loader.
 */
const ArchivedDetailsTable = props => {
  const { data } = props;
  const [cycle, setCycle] = useState([]);
  const [render, setRender] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!data.loading) {
      setRender(true);
      setLoading(false);
      setCycle(data.getCustomerLogById.Customer_Logs);
    }
    if (data.loading) {
      setLoading(true);
      setRender(false);
    }
  }, [data]);
  const columns = useMemo(
    () => [
      {
        id: "1",
        header: "Date",
        accessor: "created_at",
        component: SortableHeader,
        comparator: dateComparator,
        csvMapper: (field, accessor) => formatActivityDate(field[accessor]),
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) => formatActivityDate(field[accessor])
        }
      },
      {
        id: "2",
        header: "Title",
        accessor: "description",
        component: SortableHeader,
        comparator: stringComparator,
        cell: { component: TextCell }
      },
      {
        id: "3",
        header: "Created By",
        accessor: "Csm",
        component: TextHeader,
        sortable: false,
        csvMapper: (field, accessor) =>
          field[accessor]
            ? field[accessor].first_name + " " + field[accessor].last_name
            : "",
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) =>
            field[accessor] &&
            field[accessor].first_name + " " + field[accessor].last_name
        }
      }
    ],
    []
  );

  const tableOptions = {
    title: "Customer Life cycle"
  };
  return (
    <div className="p-3 pt-0">
      <FadeIn show={render}>
      <div className="table-archive-details">
        <TableView
          columns={columns}
          data={cycle}
          tableOptions={tableOptions}
          loading={loading}
        />
      </div>
      </FadeIn>
      {!render && (
        <CustomLoader
          style={{ marginLeft: "auto", marginRight: "auto", zIndex: 9999 }}
        />
      )}
    </div>
  );
};
export default ArchivedDetailsTable;
