import React from "react";
import TableRow from "./table-row";

/**
 * This component is used in {@link CustomTable} to display the body of the table
 */
class TableBody extends React.Component {
/**
 * @param {HTMLBodyElement} tableBodyData Body of the table
 */
  render() {
    const { tableBodyData } = this.props;

    return (
      <tbody>
        {tableBodyData.map((prop, key) => {
          return (
            <TableRow tableRowData={prop} key={key} />
          );
        })}
      </tbody>
    );

  }
}


export default TableBody;