import gql from 'graphql-tag';

export default gql`
  query {
    getAgenciesData {
      name
      address
      city
      state{
        id
        name
      }
      zip
    }
  }`