import React from "react";
import PropTypes from "prop-types";
import "./pagination.css";
import { FaAngleLeft, FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleRight } from "react-icons/fa";

/**
 * this component renders the pagination of the tables. next or previous buttons are disabled based on the canNextPage and canPreviousPage respectively.
 * @param {boolean} canPreviousPage specifies if there is a previous page 
 * @param {boolean} canNextPage specifies if there is a next page 
 * @param {number} pageCount total number of pages
 * @param {function} gotoPage 
 * @param {function} nextPage
 * @param {function} previousPage
 * @param {number} pageIndex
 * @returns {JSX.Element}
 */
const Pagination = ({
  canPreviousPage,
  canNextPage,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  pageIndex
}) => {
  return (
    <div className="d-inline-block">
      <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
        <FaAngleDoubleLeft/>
      </button>{" "}
      <button onClick={() => previousPage()} disabled={!canPreviousPage}>
      <FaAngleLeft/>
      </button>{" "}
      <span className="mr-2 ml-1">
        Page: {" "} {pageIndex + 1} of {pageCount} {" "}
      </span>
      <button onClick={() => nextPage()} disabled={!canNextPage}>
        <FaAngleRight/>
      </button>{" "}
      <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
        <FaAngleDoubleRight/>
      </button>{" "}
      <span className="d-none">
        | Go to page:{" "}
        <input
          type="number"
          defaultValue={pageIndex + 1}
          min="1"
          max={pageCount}
          onChange={e => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(page);
          }}
        />
      </span>{" "}
    </div>
  );
};

Pagination.propTypes = {
  canPreviousPage: PropTypes.bool.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  // pageCount: PropTypes.number.isRequired,
  gotoPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  pageIndex: PropTypes.number.isRequired
};

export default Pagination;
