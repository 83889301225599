import * as Yup from "yup";
import * as errorMessages from "./form-errors";
import {formatDate} from './helpers'
const checkTime = date => {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      return hours + ":" + minutes;
    };
export const phonecallForm =()=> {
    return {
    call_duration: "",
    call_details: "",
    call_type_id: 2,
    call_datetime: new Date(),
    time: checkTime(new Date())}
};
Yup.addMethod(Yup.mixed, 'checkHour', function (ref, message) {

    return this.test('equalTo', message, function (value) {
        let refValue = this.resolve(ref);
        let refHours=checkTime(new Date());
        return !((formatDate(refValue)===formatDate(new Date())&&(refHours<value))) 

    })
  })
export const callSchema = Yup.object().shape({
    call_type_id: Yup.string()
        .default(2)
        .required(errorMessages.REQUIRE_FIELD)
        .nullable(),
    call_details: Yup.string()
        .max(500, errorMessages.DETAILS_LONG).nullable()
        .default(""),
    call_duration: Yup.string()
    .matches(/^[0-9]*$/, "Call duration must be a number type")
    .max(2, 'Call duration must be of two digits')
    .nullable(),
    call_datetime: Yup.date()
        .required(errorMessages.REQUIRE_FIELD)
        .default(new Date()),
    time: Yup.string()
        .checkHour(Yup.ref("call_datetime"),"Correspondence can not be in the future .")
        .required(errorMessages.REQUIRE_FIELD)
        .default("12:00"),
});
