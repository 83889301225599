import React, { useState } from "react";
import { compose, graphql } from "react-apollo";
import { FaPlus, FaPen, FaTrashAlt } from "react-icons/fa";

import { GET_ALL_CUSTOMER_ORIGINATION_AREAS } from "../../graphql/queries";
import { ClickableIcon } from "./email-associations";
import "./admin-styles.css";
import CustomTable from "../../components/table/custom-table";
import { ActionCell } from "../../components/new-table";
import { isNotNULL } from "../../utils/helpers";
import {
  CREATE_CUSTOMER_ORIGINATION_AREA,
  DELETE_CUSTOMER_ORIGINATION_AREA,
  UPDATE_CUSTOMER_ORIGINATION_AREA,
} from "../../graphql/mutations";
import Dialog from "../../components/dialog/dialog";
import { SET_NOTIFICATION_PROPS } from "../../cacheql/mutations";

const SourceTagCustomization = ({
  getAllSourceTags: { getAllCustomer_Origination_Areas, refetch },
  ...props
}) => {
  const [editID, setEditID] = useState();
  const [newSourceTagValue, setNewSourceValue] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [dailogTitle, setDailogTitle] = useState("");

  const closeDailog = (message) => {
    setShowDialog(false);
    setEditID();
    refetch();
    message &&
      props.setNotificationProps({ variables: { open: true, message } });
  };

  /**
   * This function is used for add status mutation
   *@param {string} name name of status
   * @constant
   * @memberof module:EmailAssociationsModule
   */
  const addMutation = ({ name }) => {
    props
      .createCustomerSourceTag({
        variables: {
          input: {
            name,
          },
        },
      })
      .then((res) => {
        res.data.createCustomerOriginationArea &&
          closeDailog(res.data.createCustomerOriginationArea.message);
      });
  };

  /**
   * This function is used for edit status mutation
   * @param {string} name name of status
   * @param {number} id id of status
   * @constant
   * @memberof module:EmailAssociationsModule
   */
  const updateMutation = ({ id, name }) => {
    props
      .updateCustomerSourceTag({
        variables: {
          id,
          input: {
            name,
          },
        },
      })
      .then((res) => {
        res.data.updateCustomerOriginationArea &&
          closeDailog(res.data.updateCustomerOriginationArea.message);
      });
  };

  /**
   * This function is used for delete status mutation
   * @param {number} id id of status
   * @constant
   * @memberof module:EmailAssociationsModule
   */
  const deleteMutation = ({ id }) => {
    props
      .deleteCustomerSourceTags({
        variables: {
          id,
        },
      })
      .then((res) => {
        res.data.deleteCustomerOriginationArea &&
          closeDailog(res.data.deleteCustomerOriginationArea.message);
      });
  };

  /**
   * This function is used to handle the change in input field
   * @param {string} value Value of input
   */
  const handleChange = (value) => {
    setNewSourceValue(value !== " " ? value : null);
  };

  /**
   * This function is used to render the dialogs for add edit and delete.
   * @constant
   * @memberof module:EmailAssociationsModule
   * @returns {JSX.Element}
   */
  const dailogBody = () => {
    return (
      <div className="dailog-body">
        {dailogTitle === "Delete" ? (
          <>
            <p>
              Do you want to delete the customer source ?
            </p>
          </>
        ) : (
          <input
            type="text"
            value={newSourceTagValue}
            className="form-control"
            placeholder="Customer source name"
            onChange={(e) => handleChange(e.target.value)}
          />
        )}
      </div>
    );
  };

  const getTableData = () => {
    const tableData = [];

    if (isNotNULL(getAllCustomer_Origination_Areas)) {
      getAllCustomer_Origination_Areas.forEach((tags) => {
        tableData.push([
          <span>{tags.name}</span>,
          <span>
            <div className="data-actions always-visible">
              <ActionCell
                tooltip="Edit"
                icon={FaPen}
                key={"action-item-1"}
                action={() => {
                  setDailogTitle("Edit");
                  setEditID(tags.id);
                  setNewSourceValue(tags.name);
                  setShowDialog(true);
                }}
              />
              <ActionCell
                tooltip="Delete"
                icon={FaTrashAlt}
                key={"action-item-2"}
                action={() => {
                  setDailogTitle("Delete");
                  setEditID(tags.id);
                  setShowDialog(true);
                }}
              />
            </div>
          </span>,
        ]);
      });
    }

    return tableData;
  };

  return (
    <>
      <div className="col-padding col-md-6 col-lg-4 other-customization-root">
        <div className="customization-box customer-source-tag">
          <div className="d-flex">
            <h3>Customer Sources</h3>
            <div className="ml-3">
              <ClickableIcon
                tooltip="Add Customer Sources"
                className="sources-add-btn"
                id="add-icn"
                icon={FaPlus}
                callback={() => {
                  setDailogTitle("Create New Customer Source");
                  setShowDialog(true);
                }}
              />
            </div>
          </div>
          <div className="table-compact scrollable-table">
            <CustomTable tableData={getTableData()} />
          </div>
        </div>
      </div>
      <Dialog
        size="sm"
        show={showDialog}
        onHide={() => closeDailog()}
        title={dailogTitle}
        body={dailogBody()}
        click={() => {
          editID
            ? dailogTitle === "Edit"
              ? updateMutation({ id: editID, name: newSourceTagValue })
              : deleteMutation({ id: editID })
            : addMutation({ name: newSourceTagValue });
        }}
        clickname="Ok"
        closename="Cancel"
      />
    </>
  );
};

export default compose(
  graphql(GET_ALL_CUSTOMER_ORIGINATION_AREAS, { name: "getAllSourceTags" }),
  graphql(CREATE_CUSTOMER_ORIGINATION_AREA, {
    name: "createCustomerSourceTag",
  }),
  graphql(UPDATE_CUSTOMER_ORIGINATION_AREA, {
    name: "updateCustomerSourceTag",
  }),
  graphql(DELETE_CUSTOMER_ORIGINATION_AREA, {
    name: "deleteCustomerSourceTags",
  }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" })
)(SourceTagCustomization);
