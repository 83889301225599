import gql from "graphql-tag";

const SEND_TEST_EMAIL = gql`
    mutation sendTestEmail($input: SendEmailInput){
        sendTestEmail(input: $input){
            code
            success
            message
        }
    }
`;
export default SEND_TEST_EMAIL;
