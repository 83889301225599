
import gql from 'graphql-tag';

const GET_CUSTOMER_ORIGINATION_AREA = gql`
   query{
    getAllCustomer_Origination_Areas{
        id
        name
    }
   }
   `;
export default GET_CUSTOMER_ORIGINATION_AREA;