import React from 'react'

import { Auth } from '../rbac/rbac'

/**
 * This is a wrapper component to receive an HOC, and passes auth-context to the HOC
 * @param {JSX.Element} Component 
 * @returns {Component}
 */
const withUserContext = Component => {
    const UserContextComponent = (props) => {
        return (
            <Auth.Consumer>
                {auth => <Component {...props} auth={auth} />}
            </Auth.Consumer>
        )
    }

    return UserContextComponent
}

export default withUserContext