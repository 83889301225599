import gql from "graphql-tag";

const SELECT_CSM = gql`
  mutation selectCsm($selectedCsm: [Int]) {
    selectCsm(selectedCsm: $selectedCsm) @client {
      ids
    }
  }
`;

export default SELECT_CSM;
