import gql from "graphql-tag";

const GET_DIVISION_BY_ID = gql`
    query getDivisionById($id: Int!) {
        getDivisionById(id: $id) {
            id
            name
            description
            email
        }
    }
`;

export default GET_DIVISION_BY_ID;
