import React, { useEffect, useMemo, useContext } from "react";
import { FaTrashAlt, FaPen } from "react-icons/fa";
import _ from "lodash";

import TableView, {
  SortableHeader,
  TextCell,
  ActionCell,
  TextHeader,
  DetailLinkCell,
} from "../../../components/new-table";
import reducer, { getInitialState } from "./all-agencies-reducer";
import { getUpdatedParams, getCardParams } from "../../../utils/helpers";
import { DashboardContext } from "../../../context";
import FadeIn from "../../../components/fade-in";
import CustomLoader from "../../../components/custom-loader";
import Drawer from "../../../hoc/Drawer";
import AgencyForm from "../forms/create-update-agency";
import AgencyFilter from "../forms/agencies-filter";
import Dialog from "../../../components/dialog/dialog";
import routeConstants from "../../layout/routes";
import { getDescendantProp } from "../../../components/new-table/utils";

/** @module AgenciesTableModule */

/**
 * This component is a table used to display all the agencies. It contains pagination, searchbar, filtering and export
 * to csv options. It also has some basic hover actions like edit and delete agency. It shows different columns for
 * different card selection. Table uses dashboard context to access current selected card, and uses reducer to
 * maintain its state.
 *
 * @param {Object} props it contains all the mutations/queries in the conatiner as props
 * @param {Object} props.data it contains array of agencies to be shown on table
 * @param {Object} props.getTableParams it contains cards info stored inside cache
 * @returns {JSX.Element} It returns jsx containing the table, dialog box and the side drawer to show agency edit form.
 */
const AgenciesTable = ({
  data,
  getTableParams: { tableParams },
  deleteAgency,
  ...props
}) => {
  const { currentCard } = useContext(DashboardContext);
  const cacheParams = getCardParams(currentCard.uniqueTitle, tableParams);
  const [state, dispatch] = React.useReducer(
    reducer,
    getInitialState({
      loading: data.loading,
      cache: cacheParams,
    })
  );
  const {
    render,
    userPageCount,
    pageSize,
    agencies,
    selectedAgency,
    showSideBar,
    showConfirmationDialog,
    deleteId,
    loading,
  } = state;

  /**
   * refetch table data if any update or delete operation is performed
   */
  useEffect(() => {
    if (props.updated !== null) {
      data.refetch();
    }
  }, [props.updated]);

  /**
   * On every card change, first check if that card is present inside store, if yes apply those paramters as initial
   * values, otherwise reset to initial card state where everything (search, filtering etc...) starts from scratch.
   */
  useEffect(() => {
    if (!cacheParams) {
      dispatch({ type: "RESET", payload: { render: false } });
    } else {
      dispatch({
        type: "UPDATE",
        payload: { ...cacheParams, pageSize: cacheParams.limit, render: false },
      });
    }
    props.setPreviousCard({ variables: { title: currentCard.uniqueTitle } });
  }, [props.currentCard.uniqueTitle]);

  /**
   * Either set the data in state or set the loading to inform the table what to render.
   */
  useEffect(() => {
    if (!data.loading && data.getAgencies && data.getAgencies.agencies) {
      dispatch({
        type: "UPDATE",
        payload: {
          render: true,
          agencies: data.getAgencies.agencies,
          loading: false,
          userPageCount: Math.ceil(data.getAgencies.total_count / pageSize),
        },
      });
    }
    if (data.loading) {
      dispatch({ type: "UPDATE", payload: { loading: data.loading } });
    }
  }, [data]);

  /**
   * It finds if the crrent title is present in the list of cards which do not have access to the column.
   * @param {String} title current card title
   * @param {Array} comparatorList list containing titles from which card should be hidden
   * @function
   * @inner
   * @memberof module:AgenciesTableModule
   * @returns {Boolean}
   * @see {@link module:AgenciesTableModule~AgenciesTable}
   */
  const isHandleHidden = (title, comparatorList) => {
    return comparatorList.some((comparator) => comparator === title);
  };

  /**
   * It is used to set delete id and show hide the dialog box.
   * @param {Number} agencyId id of agency to be deleted
   * @param {Boolean} isShow value to either show/hide delete dialog box
   * @function
   * @inner
   * @memberof module:AgenciesTableModule
   * @see {@link module:AgenciesTableModule~AgenciesTable}
   */
  const deleteHandler = (agencyId, isShow) => {
    dispatch({
      type: "UPDATE",
      payload: {
        deleteId: agencyId || undefined,
        showConfirmationDialog: isShow || false,
      },
    });
  };

  /**
   * This function is called on dialog box click to call the delete mutation and calls the {@link deleteHandler} to
   * close the dialog box on successful response from backend. On delete data of the table is also refetched.
   * @function
   * @inner
   * @memberof module:AgenciesTableModule
   * @see {@link module:AgenciesTableModule~AgenciesTable}
   */
  const handleDelete = () => {
    deleteAgency({
      variables: {
        id: deleteId,
      },
    }).then((res) => {
      if (res.data.deleteAgency.code === 200) {
        props.update();
      }
      props.setNotificationProps({
        variables: { open: true, message: res.data.deleteAgency.message },
      });
      deleteHandler();
    });
  };

  const toggleSideBar = () =>
    dispatch({ type: "UPDATE", payload: { showSideBar: !showSideBar } });

  /**
   * It is used to set agency to be edited and show hide the sidebar.
   * @param {Object} agency agency to be edited
   * @function
   * @inner
   * @memberof module:AgenciesTableModule
   * @see {@link module:AgenciesTableModule~AgenciesTable}
   */
  const editHandler = (agency) => {
    toggleSideBar();
    dispatch({ type: "UPDATE", payload: { selectedAgency: agency } });
  };

  /**
   * It is a callback function which is called on any table change like: pagination, page-size, filters, search term.
   * Table passes its current state so that we can update the table parameters in store and state, which are used in
   * refetching the data with correct parameters.
   *
   * @param {Object} tableProps current state of the table
   * @function
   * @inner
   * @memberof module:AgenciesTableModule
   * @see {@link module:AgenciesTableModule~AgenciesTable}
   */
  const fetchData = React.useCallback((tableProps) => {
    if (loading) {
      return;
    }

    //total pages
    const pageCount = Math.ceil(
      data.getAgencies.total_count / tableProps.pageSize
    );

    //variables to be stored in cache/store
    const variables = {
      filter: {
        ...tableProps.filter,
      },
      search: tableProps.searchTerm,
      limit: tableProps.pageSize,
      pageNum: tableProps.currentPage,
      sort: tableProps.sortColumn ? tableProps.sortColumn : state.sort,
      mode: currentCard.mode,
    };

    // if search term or filter is changed, bring the page to 0
    if (
      tableProps.searchTerm !== state.search ||
      !_.isEqual(variables.filter, state.filter)
    ) {
      variables.pageNum = 0;
    }
    // if current page is greater than total pages then move to the last page or 0
    if (tableProps.currentPage > pageCount - 1) {
      variables.pageNum = pageCount ? pageCount - 1 : 0;
    }

    // update the state
    dispatch({
      type: "UPDATE",
      payload: { ...variables, pageSize: tableProps.pageSize, loading: true },
    });

    // update total page count in the state
    if (pageCount !== userPageCount) {
      dispatch({
        type: "UPDATE",
        payload: { userPageCount: pageCount },
      });
    }

    // update the store
    props
      .setTableParams({
        variables: {
          cardsParams: getUpdatedParams(tableParams.cardsParams, {
            cardTitle: currentCard.uniqueTitle,
            params: variables,
          }),
        },
      })
      .then((res) => {
        dispatch({ type: "UPDATE", payload: { loading: false } });
      });
  });

  /**
   * Columns of the table are memoized and are created again when current card changes, since columns vary according
   * to selected card, some columns are being added or removed from the table based on the cards.
   * @constant
   * @memberof module:AgenciesTableModule
   */
  const columns = useMemo(
    () => [
      {
        id: "1",
        header: "Agency",
        accessor: "name",
        component: SortableHeader,
        cell: {
          component: DetailLinkCell,
          path: (field, accessor) => routeConstants.AGENCY_DETAIL,
        },
      },
      {
        id: "2",
        header: "Street Address",
        accessor: "address",
        component: TextHeader,
        cell: { component: TextCell },
      },
      ...(!isHandleHidden(currentCard.title, ["YTD Registrations", "YTD Sales"])
        ? [
          {
            id: "3",
            header: "City",
            accessor: "city",
            component: SortableHeader,
            cell: { component: TextCell },
          },
          {
            id: "4",
            header: "State",
            accessor: "state.name",
            component: TextHeader,
            csvMapper: (field, accessor) =>
              getDescendantProp(field, accessor),
            cell: { component: TextCell },
          },
          {
            id: "5",
            header: "Zip",
            accessor: "zip",
            component: TextHeader,
            cell: { component: TextCell },
          },
        ]
        : []),
      ...(!isHandleHidden(currentCard.title, ["All Agencies", "YTD Sales"])
        ? [
          {
            id: 6,
            header: "YTD Registrations",
            accessor: "registration_count",
            component: TextHeader,
            cell: { component: TextCell },
          },
        ]
        : []),
      ...(!isHandleHidden(currentCard.title, [
        "YTD Registrations",
        "All Agencies",
      ])
        ? [
          {
            id: 6,
            header: "YTD Sales",
            accessor: "sales_count",
            component: TextHeader,
            cell: { component: TextCell },
          },
        ]
        : []),
    ],
    [currentCard.title]
  );

  /**
   * Array of objects containing different actions which can be performed on row hover.
   * @constant
   * @memberof module:AgenciesTableModule
   */
  const hoverActions = useMemo(
    () => [
      {
        tooltip: "Edit",
        icon: FaPen,
        component: ActionCell,
        action: ({
          isSelected,
          uuid,
          __typename,
          registration_count,
          sales_count,
          ...row
        }) => {
          editHandler(row);
        },
      },
      {
        tooltip: "Delete",
        icon: FaTrashAlt,
        component: ActionCell,
        action: ({ name }) => {
          deleteHandler(name, true);
        },
      },
    ],
    []
  );

  const tableOptions = {
    title: currentCard.title,
    FilterItems: AgencyFilter,
  };

  return (
    <div>
      <Dialog
        show={showConfirmationDialog}
        onHide={deleteHandler}
        title="Delete Confirmation"
        body="Are you sure you want to delete?"
        click={() => handleDelete()}
        clickname="YES"
        closename="NO"
      />

      <FadeIn show={render}>
        <div className="table-realtors">
          <TableView
            columns={columns}
            data={agencies}
            tableOptions={tableOptions}
            loading={loading}
            hoverActions={hoverActions}
            userPageCount={userPageCount}
            userTotalCount={
              !loading && data.getAgencies ? data.getAgencies.total_count : null
            }
            fetchData={fetchData}
            currentPage={state.pageNum}
            pageSize={state.pageSize}
            filter={state.filter}
            searchTerm={state.search}
            sortColumn={state.sort}
            controlled={true}
          />
        </div>
      </FadeIn>
      {!render && <CustomLoader />}

      <Drawer show={showSideBar} toggleSideBar={toggleSideBar}>
        <AgencyForm
          agency={selectedAgency}
          id={selectedAgency?.name}
          onSubmit={() => {
            toggleSideBar();
            props.update();
          }}
          close={toggleSideBar}
        />
      </Drawer>
    </div>
  );
};

export { AgenciesTable as default };
