import PropTypes from "prop-types";
import React from "react";
import { NavItem } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { compose, graphql } from "react-apollo";
import "./nav-item-styles.css";
import { SET_PREVIOUS_CARD, SET_TABLE_PARAMS } from "../../cacheql/mutations";

/**
 * 
 * @param {Object} props
 * @param {Boolean} props.nestedLink item is a sub-nav item
 * @param {String} props.navLink route on which page shouldbe redirected on click
 * @param {String} props.navTitle title of the nav-item
 * @param {JSX.Element} props.icon icon to be shown before title
 * @param {JSX.Element} props.arrow icon to the right most end of nav-item
 * @param {Number} props.count count is a number shown in red circle right next to title
 * @param {Function} props.callback to know when the nav-item is clicked
 */
const SubNavigationItem = ({
  children,
  nestedLink,
  navLink,
  navTitle,
  icon,
  arrow,
  exact,
  count,
  secondaryCount,
  callback = false,//true when nav-item has navLink and sub nav-items
  ...props
}) => {
  const navListItemClasses = nestedLink
    ? "nav-item-nested noselect"
    : "nav-item-direct noselect";

  const navItemChildren = (
    <>
      <div className="nav-sub-items" id="sub-nav-items">
        <div className="text-muted d-flex justify-content-start align-items-center r w-100">
          <div className="d-flex align-items-center" onClick={(e) => callback && callback()}>
            <div className="nav-icon">{icon ? icon : null}</div>
            <div style={{ paddingRight: "0.5rem" }}>{navTitle} </div>
            {(count > 0 || secondaryCount > 0) &&
              <div className="webForms-count">
                {secondaryCount !== undefined ? `${count}/${secondaryCount}` : count}
              </div>
            }
          </div>
        </div>
        <div /* onClick={(e)=>props.callback && e.preventDefault()}*/>{arrow ? arrow : null}</div>
      </div>
      {children}
    </>
  );

  /*This function resets the previous card state in the store
    so when the user shifts between navigation items, the default 
    card is set in the dashboard */
  const resetCardState = () => {
    props.setPreviousCard({ variables: { title: null } });

    /* The following code resets table state management on navigation change*/
    //props.setTableParams({ variables: { cardsParams: [] } });
  };

  return (
    <NavItem id="custom-nav-item">
      {navLink ? (
        <NavLink
          id="sub-nav-link"
          exact={exact}
          onClick={resetCardState}
          to={navLink}
          className={`nav-link ${navListItemClasses}`}
        >
          {navItemChildren}
        </NavLink>
      ) : (
        <div className={`nav-link ${navListItemClasses}`}>
          {navItemChildren}
        </div>
      )}
    </NavItem>
  );
};

SubNavigationItem.propTypes = {
  className: PropTypes.string,
  nestedLink: PropTypes.bool,
  directLink: PropTypes.bool,
  navLink: PropTypes.string,
  navTitle: PropTypes.string,
  icon: PropTypes.object
};

export default compose(
  graphql(SET_PREVIOUS_CARD, { name: "setPreviousCard" }),
  graphql(SET_TABLE_PARAMS, { name: "setTableParams" })
)(SubNavigationItem);
