import React, {useState} from 'react'
import { FaHistory } from "react-icons/fa";

import CustomButton from "../../components/custom-button";
import SubscriptionHistory from './subscription-history'


/**
 * this component is used to render the subscription history button that opens the subscription history table drawer
 * @param {object} props component object
 * @param {number} customer_id id of the customer
 * @param {number} realtor id of the realtor
 * @param {string} name name of the customer
 */
const SubscriptionHistoryContainer = (props) =>{
  const [showSideBar, setShowSideBar] = useState(false)

  return(
    <>
      <div className="edit-button">
        <CustomButton
          size="sm"
          color="black"
          btnValue="Subscription"
          btnIcon={<FaHistory />}
          onClick={()=>setShowSideBar(!showSideBar)}
        />
      </div>

      {
        showSideBar && ( 
          <SubscriptionHistory 
            show={showSideBar}
            name={props.name}
            customer_id={props.customer_id}
            realtor_id={props.realtor_id}
            toggleDrawer={() => {
              setShowSideBar(!showSideBar );
            }}
            {...props}
          />
        )
      }
    </>
  )
}

export default SubscriptionHistoryContainer