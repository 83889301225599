import React from "react";
import { compose, graphql } from "react-apollo";
import { Form, Field, withFormik } from "formik";
import AddEmailField from "../../../components/fields/add-email-field";
import {
  initialValues,
  EmailAssociationSchema
} from "./email-associations-form-schema";
import {
  ADD_STATUS_EMAIL,
  DELETE_STATUS_EMAIL
} from "../../../graphql/mutations";
import { SET_NOTIFICATION_PROPS } from "../../../cacheql/mutations";

/**
 * this component renders the webform status email associations form which contains 1 add email field.
 * @param {object} values contains information related to the form
 * @param {function} submitForm
 */
const EmailAssociationsForm = ({ values, submitForm }) => {
  const [shouldSubmit, setSubmit] = React.useState(false);

  React.useEffect(() => {
    if (shouldSubmit) submitForm();
    setSubmit(false);
  }, [shouldSubmit]);

  return (
    <Form className="form-horizontal mt-4">
      <div className="col-md-6 p-0">
        <Field
          label="Status Email(s)"
          name="associated_emails"
          id="associated_emails"
          type="text"
          initValue={
            values && values.getStatusEmails
              ? values.getStatusEmails.map(e => e.email)
              : []
          }
          component={AddEmailField}
          addCallback={() => {
            setSubmit(true);
          }}
          deleteCallback={() => {
            setSubmit(true);
          }}
          disabled={values && values.getStatusEmails ? false : true}
        />
      </div>
    </Form>
  );
};

const mapPropsToValues = props => {
  const { status } = props;

  return !status
    ? { ...initialValues }
    : {
        ...status,
        associated_emails: status.getStatusEmails.map(r => r.email)
      };
};

/**
 * this component renders the webform status email associations which contains the form and the mutations.
 * @param {object} props
 */
const EmailAssociationFormIkForm = props => {
  const mutate = async (statusId, { email, mutationType }) => {
    const mutation =
      mutationType === "add" ? props.addStatusEmail : props.deleteStatusEmail;
    const prop =
      mutationType === "add" ? "addStatusEmail" : "deleteStatusEmail";

    const {
      data: {
        [prop]: { code, message }
      }
    } = await mutation({
      variables: {
        input: {
          status_id: statusId,
          email
        }
      }
    });
    return { code, message };
  };

  const getEmail = values => {
    let oldV = values.getStatusEmails.map(val => val.email);
    let newV = values.associated_emails;
    let type = oldV.length > newV.length ? "delete" : "add";

    let val =
      type === "deleted" && oldV.length === 1
        ? oldV
        : newV
            .filter(x => !oldV.includes(x))
            .concat(oldV.filter(x => !newV.includes(x)));

    return {
      email: val ? val[0] : "",
      mutationType: type
    };
  };

  const Component = withFormik({
    displayName: "EmailAssociationFormIkForm",
    enableReinitialize: true,
    mapPropsToValues,
    validationSchema: EmailAssociationSchema,
    handleSubmit: async (
      values,
      {
        props: { setNotificationProps, onSubmit, statusId },
        setSubmitting,
        resetForm
      }
    ) => {
      // delete values.hidden_associated_emails;
      const {hidden_associated_emails, ...submitValues} = values
      const { code, message } = await mutate(statusId, getEmail(submitValues));

      if (message) setNotificationProps({ variables: { open: true, message } });
      if (code === 200) {
        resetForm();
        onSubmit();
      }
      setSubmitting(false);

    }
  })(EmailAssociationsForm);

  return <Component {...props} />;
};

const Component = compose(
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" }),
  graphql(ADD_STATUS_EMAIL, { name: "addStatusEmail" }),
  graphql(DELETE_STATUS_EMAIL, { name: "deleteStatusEmail" })
)(EmailAssociationFormIkForm);

export { Component as EmailAssociationsForm };
