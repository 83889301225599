import * as Yup from "yup";
import * as errorMessages from "../../../utils/form-errors";

// import * as errorMessages from "./form-errors";

export const homeownerFilter = {
  filter_name: "",
  settled_start_date: "",
  settled_end_date: "",
  purchase_start_date: "",
  purchase_end_date: "",
  brand_ambassador: false,
  salesperson_id: "",
};

export const homeownerFilterSchema = Yup.object().shape({
  filter_name: Yup.string()
    .trim()
    .required(errorMessages.REQUIRE_FIELD)
});