import React, { useState, useEffect, useMemo } from "react";
import { compose, graphql } from "react-apollo";
import { ButtonToolbar } from "react-bootstrap";

import{ GET_CUSTOMER_SUBSCRIPTION_HISTORY, GET_REALTOR_SUBSCRIPTION_HISTORY } from '../../graphql/queries'
import TableView, {
  TextHeader,
  TextCell,
  DateTimeCell,
} from "../../components/new-table";
import CustomButton from "../../components/custom-button/index";
import Drawer from "../../hoc/Drawer";
import { formatActivityDate } from "../../utils/helpers"

/**
 * @module SubscriptionHistoryModule
 */

/**
 * this component is to render the subscription history table drawer
 * @param  {boolean} show to show hide the drawer
 * @param {string} name name of the customer
 * @param {function}
 * @param {object} getCustomerSubscriptionHistoryById ontains all the information related to the customer subscription history
 * @param {object} getCustomerSubscriptionHistoryById ontains all the information related to the realtor subscription history
 * @param {string} primaryEmail1 customer buyer 1 primary email
 * @param {string} primaryEmail2 customer buyer 2 primary email
 */
const SubscriptionHistory = ({show, name, toggleDrawer, getCustomerSubscriptionHistoryById, getRealtorSubscriptionHistoryById, primaryEmail1, primaryEmail2}, ...props) => {
  const [subscriptionHistory, setSubscriptionHistory] = useState([])
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      setLoading((getCustomerSubscriptionHistoryById && getCustomerSubscriptionHistoryById.loading) || (getRealtorSubscriptionHistoryById && getRealtorSubscriptionHistoryById.loading));
      if (getCustomerSubscriptionHistoryById) {
        setSubscriptionHistory(getCustomerSubscriptionHistoryById.getCustomerSubscriptionHistoryById? getCustomerSubscriptionHistoryById.getCustomerSubscriptionHistoryById.customer_subscription_history: []);
      }
      else if (getRealtorSubscriptionHistoryById){
        setSubscriptionHistory(getRealtorSubscriptionHistoryById.getRealtorSubscriptionHistoryById? getRealtorSubscriptionHistoryById.getRealtorSubscriptionHistoryById.realtor_subscription_history: []);
      }
  }, [getRealtorSubscriptionHistoryById,getCustomerSubscriptionHistoryById]);

  /**
   * this function is used to display the right message in the title column 
   * @function
   * @inner
   * @memberof module:SubscriptionHistoryModule
   * @param {object} field contains information of the each row
   * @param {string} accessor column accessor
   */
  const checkPrimary=(field, accessor)=>{
    let event=field["action"]+(field["action"]==="subscribed" ? " to ":field["action"]==="enabled" ?" in ":" from ")+field["title"]
    if (field[accessor]===primaryEmail1){
      return "Buyer #1 " + event
    }
    else if (field[accessor]===primaryEmail2){
      return "Buyer #2 " + event
    }
    else{
      return "Primary Email " + event
    }
  }

  const columns = useMemo(
    () => [
      {
          id: "1",
          header: "Date",
          accessor: "created_at",
          component: TextHeader,
          sortable: false,
          csvMapper: (field, accessor) => formatActivityDate(field[accessor]),
          cell: { component: DateTimeCell },
      },
      {
        id: "2",
        header: "Title",
        accessor: "email",
        component: TextHeader,
        sortable: false,
        cell: {
          component: TextCell,
          mapper:(field, accessor)=>checkPrimary(field, accessor)
        }
      },
      {
        id: "3",
        header: "Action",
        accessor: "action",
        component: TextHeader,
        sortable: false,
        cell: {
          component: TextCell
        }
      }
    ],
    []
  );

  const tableOptions = {
    title: '',
  };
  
  return (
    <Drawer show={show} toggleSideBar={toggleDrawer}>
      <div className="form_TitleContainer">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ margin: "0 1.1rem" }}
        >
          <div>
            <div className="form_Title">{name}</div>
            <div className="form_TitleBig">
              <div className="d-flex">
                <h2
                  style={{
                    position: "relative",
                    paddingRight: "0.5em",
                    marginTop: "4px"
                  }}
                >
                  Subscription History
                </h2>
              </div>
            </div>
          </div>
          <div className="d-flex pr-3">
            <ButtonToolbar>
              <CustomButton
                color="black"
                onClick={toggleDrawer}
                btnValue="Close"
              />
            </ButtonToolbar>
          </div>
        </div>
      </div>
      <div className="form_body">
        <TableView
          columns={columns}
          data={subscriptionHistory}
          tableOptions={tableOptions}
          loading={loading}
        />
      </div>
      
    </Drawer>
  )
}

export default compose(
  graphql(GET_REALTOR_SUBSCRIPTION_HISTORY, {
      name:"getRealtorSubscriptionHistoryById",
      skip:props=>!props.realtor_id,
      options: props => ({variables: {realtor_id: props.realtor_id}, fetchPolicy: 'network-only'})
  }),
  graphql(GET_CUSTOMER_SUBSCRIPTION_HISTORY, {
    name:"getCustomerSubscriptionHistoryById",
    skip:props=>!props.customer_id,
    options: props => ({variables: {customer_id: props.customer_id}, fetchPolicy: 'network-only'})
}),
)(SubscriptionHistory);

// export default SubscriptionHistory