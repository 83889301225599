import { compose, graphql } from "react-apollo";

import { GET_TABLE_PARAMS, GET_DATE_RANGE, GET_SELECTED_COMMUNITY } from "../../cacheql/queries";
import { SET_TABLE_PARAMS } from "../../cacheql/mutations";
import { getCardParams } from "../../utils/helpers";
import OscCommissionReportTable from "./osc-commission-report-table";
import { GET_OSC_COMMISSION_REPORT } from "../../graphql/queries";

export default compose(
  graphql(GET_SELECTED_COMMUNITY, { name: "selectedCommunity" }),
  graphql(SET_TABLE_PARAMS, { name: "setTableParams" }),
  graphql(GET_TABLE_PARAMS, { name: "getTableParams" }),
  graphql(GET_DATE_RANGE, { name: "selectedDateRange" }),
  graphql(GET_OSC_COMMISSION_REPORT, {
    options: ({ selectedCommunity, getTableParams: { tableParams }, ...props }) => {
      const params = getCardParams("OSC Commission Report", tableParams);

      return {
        variables: {
          filter: {
            osc_ids: props.selectedOscIds.ids ,
            purchased_start_date: props.selectedDateRange.selectedDateRange.startDate &&  new Date(props.selectedDateRange.selectedDateRange.startDate).toDateString(),
            purchased_end_date: props.selectedDateRange.selectedDateRange.endDate && new Date(props.selectedDateRange.selectedDateRange.endDate).toDateString(),
            ...(params && params.filter)
          },
          pageNum: (params && params.pageNum) || 0,
          limit: (params && params.limit) || 10,
        },
        fetchPolicy: "no-cache"
      };
    }
  })
)(OscCommissionReportTable);
