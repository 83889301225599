
import gql from 'graphql-tag';

const UPDATE_REALTOR_NOTE = gql` 
  mutation updateRealtorNote($input: RealtorNoteUpdateInput) {
    updateRealtorNote(input: $input) {
      message
      code
      data
    }
  }
`;

export default UPDATE_REALTOR_NOTE;
