import React, { useState, useEffect } from "react";
import { compose, graphql } from "react-apollo";
import { FaChevronLeft } from "react-icons/fa";
import { ButtonToolbar, Dropdown, DropdownButton } from "react-bootstrap";
import { routes } from "../../index";
import { SelectBar } from "../../../containers/";
import Dialog from "../../../components/dialog/dialog";
import Header from "../../../components/dashboard-title";
import CustomButton from "../../../components/custom-button";
import DeleteDialogBox from "../../../components/dialog/delete-dialog-box";
import DetailsDeleteButton from "../../common-lead-prospect-details/details-delete-button-container"
import { goBack } from "../../../utils/helpers";
import { PROSPECT } from "../../../utils/constants"
import ConvertRealtorView from "../../web-form/convert-realtor-form";
import {
  CONVERT_PROSPECT_TO_LEAD,
  ADD_PROSPECT_DELETE_REQUEST,
  CONVERT_PROSPECT_TO_MARKETING_ONLY,
} from "../../../graphql/mutations";
import "./prospect-details.css";
import { ProspectConvertFailedDialog } from './prospect-convert-failed-dialog'
import { MARKETING_ONLY, LEAD } from "../../../utils/constants";

/**
 * @module ProspectDetailSelectBarModule
 */
/**
 * this component is used to render the prospect details select bar which contains the name and communitites of the prospect and the convert to lead button.
 * @param {object} props component props contains all the necessary including the mutations.
 * @param {object} props.history contains information about the path, to be used in go back button
 * @param {object} props.data contains all the informations related to the prospect
 * @param {number} props.id prospect id
 */
const ProspectDetailSelectBar = ({ ...props }) => {
  const { history, data } = props;
  const [deleteProspectId, setDeleteProspectId] = useState(null);
  const [showConvertDialog, setShowConvertDialog] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null)
  const [showMultipleConvertDialog, setShowMultipleConvertDialog] = useState(false);
  const [showLeadConvertConfirmation, setShowLeadConvertConfirmation] = useState(false);
  const [showConvertRealtor, setShowConvertRealtor] = useState(false);
  const [showMarketingOnlyConvertConfirmation, setShowMarketingOnlyConvertConfirmation] = useState(false);
  const [note, setNote] = useState("");
  const [convertMultipleFailureData, setConvertMultipleFailureData] = useState([])
  const [convertMultipleSuccessData, setConvertMultipleSuccessData] = useState([])
  const [convertMultipleCommunities, setConvertMultipleCommunities] = useState([])
  const [multipleConvertType, setMultipleConvertType] = useState('')

  const [convertMessage, setConvertMessage] = useState("")
  const [
    showMultipleConvertDeleteConfirmDialog,
    setShowMultipleConvertDeleteConfirmDialog
  ] = useState(false)

  useEffect(() => {
    if (!showMultipleConvertDialog) {
      setConvertMultipleFailureData([])
      setConvertMultipleSuccessData([])
      setConvertMultipleCommunities([])
    }
  }, [showMultipleConvertDialog])

  useEffect(() => {
    if (deleteProspectId && (!showMultipleConvertDeleteConfirmDialog && !showConvertDialog && showMultipleConvertDialog))
      handleDeleteConfirmation()
  }, [deleteProspectId, convertMultipleFailureData])

  const notify = (message) => props.setNotificationProps({ variables: { open: true, message: message } });

  const resetConvertState = (show, id) => {
    setShowConvertDialog(show);
    setDeleteProspectId(id);
  };

  const onSubmit = (message, path) => {
    history.push({ pathname: path });
    notify(message);
  }
  const handleChange = (value) => {
    setNote(value)
  };

  const handleMultipleConvertDeleteConfirmation = (index, itemID) => {
    setShowMultipleConvertDeleteConfirmDialog(true)
    setDeleteIndex(index)
    setDeleteProspectId(itemID)
    setShowConvertDialog(false)
    setConvertMessage("")

  };

  const handleCancelConvert = () => {
    setShowMultipleConvertDialog(false)
    setDeleteIndex(null)
    setDeleteProspectId(null)
  };

  const handleCancelMultipleConvertDeleteConfirmation = () => {
    setShowMultipleConvertDeleteConfirmDialog(false)
    setDeleteIndex(null)
    setDeleteProspectId(null)
  };

  const handleMultipleConvertDelete = () => {
    if (convertMultipleFailureData.length > 1) {
      setShowMultipleConvertDeleteConfirmDialog(false)
      setConvertMultipleFailureData(convertMultipleFailureData.filter((_, idx) => idx !== deleteIndex))
    } else {
      setShowMultipleConvertDeleteConfirmDialog(false)
      setShowMultipleConvertDialog(false)
      setConvertMultipleFailureData(convertMultipleFailureData.filter((_, idx) => idx !== deleteIndex))
      setDeleteIndex(null)
    }
  };

  const handleConvertToProspect = () => {
    setShowLeadConvertConfirmation(false);
    props.convertProspectToLead({
      variables: { input: { prospect_ids: [props.id] } },
    }).then((res) => {
      if (res.data.convertProspectToLead.code === 200) {
        history.push({
          pathname: routes.LEAD_DASHBOARD,
        });
        notify(res.data.convertProspectToLead.message);
      } else if (res.data.convertProspectToLead.code === 207) {
        setConvertMultipleFailureData(res.data.convertProspectToLead.data.failureData)
        setConvertMultipleSuccessData(res.data.convertProspectToLead.data.successData)
        setConvertMultipleCommunities(res.data.convertProspectToLead.data.communities)
        setShowMultipleConvertDialog(true)
        setMultipleConvertType(LEAD)
      } else if (res.data.convertProspectToLead.code === 409)
        notify(res.data.convertProspectToLead.message);
    }).catch((res) => {
      res.graphQLErrors.map((error) => {
        return error;
      });
    });
  };

  const handleConvertToMarketingOnly = () => {
    setShowMarketingOnlyConvertConfirmation(false);

    props.convertProspectToMarketingOnly({
      variables: { input: { prospect_ids: [props.id] } },
    }).then((res) => {
      if (res.data.convertProspectToMarketingOnly.code === 200) {
        history.push({ pathname: routes.MARKETING_ONLY_DASHBOARD });
        notify(res.data.convertProspectToMarketingOnly.message);
      } else if (res.data.convertProspectToMarketingOnly.code === 207){
        setConvertMultipleFailureData(res.data.convertProspectToMarketingOnly.data.failureData)
        setConvertMultipleSuccessData(res.data.convertProspectToMarketingOnly.data.successData)
        setConvertMultipleCommunities(res.data.convertProspectToMarketingOnly.data.communities)
        setShowMultipleConvertDialog(true)
        setMultipleConvertType(MARKETING_ONLY)
        resetConvertState(true, [res.data.convertProspectToMarketingOnly.data.failureData[0].id])
      }
      else if (res.data.convertProspectToMarketingOnly.code === 409)
        notify(res.data.convertProspectToMarketingOnly.message);
    }).catch((res) => {
      res.graphQLErrors.map((error) => {
        return error;
      });
    });
  };

  const handleDeleteConfirmation = () => {
    props.addProspectDeleteRequests({
      variables: { input: { prospect_ids: deleteProspectId, note: note } },
    }).then((res) => {
      if (res.data.addProspectDeleteRequests.code === 200) {
        notify(res.data.addProspectDeleteRequests.message);
        setNote("");
        multipleConvertType === LEAD
        ? handleConvertToProspect()
        : handleConvertToMarketingOnly()
      }
    }).catch((res) => {
      res.graphQLErrors.map((error) => {
        return error.message;
      });
    });
  };

  return (
    <SelectBar>
      <Dialog
        show={showLeadConvertConfirmation}
        onHide={() => setShowLeadConvertConfirmation(false)}
        title="Prospect to Lead Confirmation"
        body="Are you sure you want to convert this Prospect to Lead?"
        click={handleConvertToProspect}
        clickname="YES"
        closename="NO"
      />
      <Dialog
        show={showMarketingOnlyConvertConfirmation}
        onHide={() => setShowMarketingOnlyConvertConfirmation(false)}
        title="Prospect to Marketing Only Confirmation"
        body="Are you sure you want to convert this Prospect to Marketing Only?"
        click={handleConvertToMarketingOnly}
        clickname="YES"
        closename="NO"
      />
      <Dialog
        show={showMultipleConvertDeleteConfirmDialog}
        onHide={handleCancelMultipleConvertDeleteConfirmation
        }
        title="Delete Confirmation"
        body={<DeleteDialogBox setFunc={handleChange} massDelete={true} />}
        click={handleMultipleConvertDelete}
        clickname="YES"
        closename="NO"
      />
      <ProspectConvertFailedDialog
        show={showMultipleConvertDialog}
        onHide={handleCancelConvert}
        successData={convertMultipleSuccessData}
        failureData={convertMultipleFailureData}
        communities={convertMultipleCommunities}
        currentCommunity={props?.data?.community}
        handleDelete={handleMultipleConvertDeleteConfirmation}
      />
      <div className="d-flex">
        <CustomButton
          onClick={() => goBack(history)}
          id="back-to-dashboard-btn"
          color="dark"
          btnIcon={<FaChevronLeft />}
          className="btn-back"
        />
        <Header
          title={"PROSPECT"}
          subtitle={props.name || ""}
          community={props.community || ""}
          flag={props.flag}
        />
      </div>
      <div
        className="btn-container col-padding col-12"
        id="prospect-dashboard-btn"
      >
        <ButtonToolbar>
          <DropdownButton
            alignRight
            drop="down"
            key="1"
            id="dropdown-item-button"
            title="CONVERT"
            className="past-activity-dropdown">
            <Dropdown.Item
              onSelect={() => setShowMarketingOnlyConvertConfirmation(true)}
              key={0}
            >
              Marketing Only
            </Dropdown.Item>
            <Dropdown.Item
              onSelect={() => setShowLeadConvertConfirmation(true)}
              key={1}
            >
              Lead
            </Dropdown.Item>
            <Dropdown.Item
              onSelect={() => setShowConvertRealtor(true)}
              key={2}
            >
              Realtor
            </Dropdown.Item>
          </DropdownButton>
        </ButtonToolbar>
        <DetailsDeleteButton
          type={PROSPECT}
          itemID={props.id}
          customerID={props.data && props.data.customer_id}
          history={history}
          color="dark"
        />
        <ConvertRealtorView
          showSideBar={showConvertRealtor}
          toggleShowDrawer={() => setShowConvertRealtor(false)}
          id={props.id}
          data={props.data}
          onSubmit={(message) => onSubmit(message, routes.REALTOR_DASHBOARD)}
        />
      </div>
    </SelectBar>
  );
};

export default compose(
  graphql(CONVERT_PROSPECT_TO_MARKETING_ONLY, { name: "convertProspectToMarketingOnly" }),
  graphql(CONVERT_PROSPECT_TO_LEAD, { name: "convertProspectToLead" }),
  graphql(ADD_PROSPECT_DELETE_REQUEST, { name: "addProspectDeleteRequests" })
)(ProspectDetailSelectBar);
