import gql from 'graphql-tag';

const GET_ALLRANKINGS = gql`
  {
    getAllRankings {
      id,
      name
    }
  }
`;
export default GET_ALLRANKINGS;
