
import gql from 'graphql-tag';

const CONVERT_LEAD_TO_PROSPECT = gql` 
  mutation convertLeadToProspect($input: [ConvertLeadToProspectInput]!) {
    convertLeadToProspect(input: $input) {
      message
      code
    }
  }
`;

export default CONVERT_LEAD_TO_PROSPECT;