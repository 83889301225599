import gql from "graphql-tag";

const GET_CSM = gql`
  query getAllCSMs($community_ids: [Int], $division_ids: [Int], $active_only: Boolean) {
    getAllCSMs(community_ids: $community_ids, division_ids: $division_ids, active_only: $active_only) {
      id
      first_name
      last_name
      role
      secondary_role
      roles
      email_address
      createdAt
      updatedAt
      is_active
    }
  }
`;

export default GET_CSM;