import React from "react";
import Dialog from "../../../components/dialog/dialog";
import { FaTrashAlt } from "react-icons/fa";
import CustomButton from "../../../components/custom-button";

const handleMultipleConvertFailedBody = (
  successData,
  failureData,
  communities,
  handleDelete,
  currentCommunity
) => {

  return (
    <>
      {successData.length ? (
        <div className="d-flex">
          {successData.map((item, idx) => {
            if (idx === 0)
              return `${item.first_name_1} ${item.last_name_1}`
            else
              return `, ${item.first_name_1} ${item.last_name_1}`
          }
          )}
          {` converted successfully.`}
        </div>
      ) : null}

      {failureData.map((item, index) => {
        return communities[index]?.name !== currentCommunity?.name &&
          (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "0.5rem 0",
                borderBottom: "1px solid #333",
                color: "#999"
              }}
            >
              <span style={{ color: "#80B602" }}>
                {` ${item.first_name_1} ${item.last_name_1} - ${communities.map(community => community.name)[index]}`}
              </span>
              {failureData.length > 1 && (
                <CustomButton
                  id="fa-trash"
                  color="dark"
                  className="btn-icon"
                  style={{ border: "none", padding: "none" }}
                  btnIcon={<FaTrashAlt />}
                  size="sm"
                  onClick={() => handleDelete(index, item.id)}
                />
              )}
            </div>
          );
      })}
    </>
  );
}

export const ProspectConvertFailedDialog = ({
  show,
  onHide,
  successData,
  failureData,
  communities,
  currentCommunity,
  handleDelete
}) => {

  return (
    <Dialog
      show={show}
      onHide={onHide}
      title={failureData.length > 1
        ? "Prospect(s) exist in other communities"
        : "Do you want to convert this prospect?"
      }
      body={handleMultipleConvertFailedBody(successData, failureData, communities, handleDelete, currentCommunity)}
      closename={"CANCEL"}
    />
  )
}