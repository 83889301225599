import React, { useEffect, useState, useRef } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { ButtonToolbar, Dropdown, DropdownButton } from "react-bootstrap";
import { routes } from "../../index";
import Dialog from "../../../components/dialog/dialog";
import Header from "../../../components/dashboard-title";
import CustomButton from "../../../components/custom-button";
import { SelectBar } from "../../../containers/";
import ConvertLeadView from "../convert-lead-form";
import ConvertRealtorView from "../convert-realtor-form";
import ConvertProspectView from "../convert-prospect-form";
import ConvertMarketingOnlyView from "../convert-marketing-only-form";
import { formatDate, goBack } from "../../../utils/helpers";

/**
 * @module WebformDetailSelectBarModule
 */

/**
 * this component is used to render the webdorm details select bar which contains the name and communitites of the webforms and the conver/recovert button.
 * @param {*} param0 
 * @param {object} history contains information about the path, to be used in go back button
 * @param {object} dataField contains all the informations related to the webform
 * @param {number} id id of the webform
 */
const WebformDetailSelectBar = ({ ...props }) => {
  const { history, dataField, id } = props;
  const { data, allStatuses, title, convertForm } = dataField;
  const [showMarketingOnlyRecover, setShowMarketingOnlyRecover] = useState(false)
  const [showProspectRecover, setShowProspectRecover] = useState(false);
  const [showLeadRecover, setShowLeadRecover] = useState(false);
  const [showConvertRealtor, setShowConvertRealtor] = useState(false);
  const [showConvertConfirmation, setShowConvertConfirmation] = useState(false);
  const [statusID, setStatusID] = useState();
  const selectBarComp = useRef(null);

  const focusComp = () => selectBarComp.current.scrollIntoView({ block: "end", behavior: "smooth" });
  const notify = message => props.setNotificationProps({ variables: { open: true, message: message } });

  const closeDrawers = () => {
    setShowLeadRecover(false);
    setShowProspectRecover(false);
    setShowConvertConfirmation(false)
    setShowConvertRealtor(false)
  };

  const updateStatus = () => {
    props.updateFormSubmissionStatus({
      variables: {
        formSubmission_id: id,
        status_id: statusID,
      }
    }).then((res) => {
      if (res.data.updateFormSubmissionStatus.code === 200) {
        notify(res.data.updateFormSubmissionStatus.message)
        props.onSubmit()
        closeDrawers();
      }
    });
  };

  const onSubmit = (message, path) => {
    history.push({
      pathname: path,
    });
    notify(message)
    closeDrawers();
  }

  useEffect(() => {
    focusComp();
  }, [selectBarComp]);

  return (
    <>
      <SelectBar>
        <Dialog
          show={showConvertConfirmation}
          onHide={() => setShowConvertConfirmation(false)}
          title="Webform Type Confirmaton"
          body="Are you sure you want to update this Webform type?"
          click={updateStatus}
          clickname="YES"
          closename="NO"
        />
        <div ref={selectBarComp}>
          <div className="d-flex">
            <CustomButton
              onClick={() => goBack(history)}
              id="back-to-dashboard-btn"
              color="dark"
              btnIcon={<FaChevronLeft />}
              className="btn-back"
            />
            <Header
              title={"WEBFORM"}
              community={props.community || ""}
              subtitle={props.name || ""}
              responded={data?.responded}
              converted={data?.converted}
            />
          </div>
          {data?.archived_at && (
            <div className="form_Title mt-2" style={{ color: "red" }}>
              Archived at: {formatDate(data.archived_at)}
            </div>
          )}
        </div>
        <div
          className="col-padding col-12 btn-container"
          id="lead-dashboard-btn"
        >
          <ButtonToolbar>
            <DropdownButton
              alignRight
              drop="down"
              key="1"
              id="dropdown-item-button"
              title={title}
              className="past-activity-dropdown"
            >
              <Dropdown.Item
                onSelect={() => {
                  setShowLeadRecover(true);
                }}
                key={0}
              >
                Lead
              </Dropdown.Item>
              <Dropdown.Item
                onSelect={() => {
                  setShowProspectRecover(true);
                }}
                key={1}
              >
                Prospect
              </Dropdown.Item>
              <Dropdown.Item
                onSelect={() => setShowMarketingOnlyRecover(true)}
                key={2}
              >
                Marketing Only
              </Dropdown.Item>
              <Dropdown.Item
                onSelect={() => setShowConvertRealtor(true)}
                key={3}
              >
                Realtor
              </Dropdown.Item>
              <DropdownButton
                title="Others"
                drop="left"
                id="dropdown-item-button"
                variant="secondary"
                className="past-activity-dropdown"
                style={{ border: "none" }}
              >
                {allStatuses?.map((status, index) => (
                  <Dropdown.Item
                    onSelect={() => {
                      setStatusID(status.id)
                      setShowConvertConfirmation(true)
                    }}
                    key={index + 2}
                  >
                    {status.name}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </DropdownButton>
          </ButtonToolbar>
          <ConvertLeadView
            showSideBar={showLeadRecover}
            toggleShowDrawer={() => setShowLeadRecover(false)}
            onSubmit={(message) => onSubmit(message, routes.WEB_FORM)}
            data={data}
            convertForm={convertForm}
            recoverForm={!convertForm}
            convertFormType="WEBFORM"
          />
          <ConvertProspectView
            showSideBar={showProspectRecover}
            toggleShowDrawer={() => setShowProspectRecover(false)}
            onSubmit={(message) => onSubmit(message, routes.WEB_FORM)}
            data={data}
            convertForm={convertForm}
            recoverForm={!convertForm}
            convertFormType="WEBFORM"
          />
          <ConvertMarketingOnlyView
            showSideBar={showMarketingOnlyRecover}
            toggleShowDrawer={() => setShowMarketingOnlyRecover(false)}
            onSubmit={(message) => onSubmit(message, routes.WEB_FORM)}
            data={data}
            convertForm={convertForm}
            recoverForm={!convertForm}
            convertFormType="WEBFORM"
          />
          <ConvertRealtorView
            showSideBar={showConvertRealtor}
            toggleShowDrawer={() => setShowConvertRealtor(false)}
            onSubmit={(message) => onSubmit(message, routes.WEB_FORM)}
            data={{...data, webform_id: id}}
          />
        </div>
      </SelectBar>
    </>
  );
};

export default WebformDetailSelectBar;
