import React, { useState, useEffect } from "react";
import { compose, graphql } from "react-apollo";
import * as Yup from "yup";
import { Form, Field, withFormik } from "formik";
import { GET_CUSTOMER_COMMUNITIES } from "../../graphql/queries";
import DetailsSectionTable from "../table/custom-details-table";
import TextField from "../fields/text-field";
import { PERMANENT_DELETE } from "../../graphql/mutations";

/**
 * This component is used to render body of Permanent Delete dialog box. It has an input field in which user types "DELETE" to permanently delete the customer. It also shows in which communities customer exists as Lead, Prospect, and Homeowner. This component is just the body of dialog box, which is why submit button is not inside this component, and so we take 'isSubmitted' property to perform action on submission.
 * @param {Object} props 
 * @param {Number} props.customerId id of the customer who is to be deleted
 * @param {Boolean} props.isSubmitted informs when the form is submitted
 * @param {Function} props.onSubmit it notifies that customer is deleted permanently, and closes the dialog box 
 */
const PermanentDeleteConfirmation = ({ data, isSubmitted, ...props }) => {
  const [customerCommunities, setCustomerCommunities] = useState(null);

  useEffect(() => {
    if (!data.loading && data.getCustomerCommunities)
      setCustomerCommunities(data.getCustomerCommunities);
  }, [data]);

  useEffect(() => {
    if (isSubmitted !== null) {
      props.handleSubmit()
    }
  }, [isSubmitted])

  /**
   * It creates an object of the following format:
   * {
   *  Lead: string of comma separated communities in which customer exists as Lead 
   *  Prospect: string of comma separated communities in which customer exists as Prospect 
   *  Homeowner: string of comma separated communities in which customer exists as Homeowner 
   * }
   * @param {Object} customerData 
   */
  const getData = (customerData) => {
    const data = {}

    if (customerData?.lead_communities) data.Lead = customerData.lead_communities.map((e) => e.name).join(", ") || "-"

    if (customerData?.prospect_communities) data.Prospect = customerData.prospect_communities.map((e) => e.name).join(", ") || "-"

    if (customerData?.homeowner_communities) data.Homeowner = customerData.homeowner_communities.map((e) => e.name).join(", ") || "-"

    if (customerData?.marketing_only_communities) data.Marketing_Only = customerData.marketing_only_communities.map((e) => e.name).join(", ") || "-"

    return data
  };

  return (
    <>
      <Form className="form-horizontal mt-4">
        <h3 className="ml-0">Customer Details</h3>

        <div className="sidebar-details-table" style={{ marginTop: "12px" }}>
          <div className="col-md-12 p-0">
            <DetailsSectionTable
              rowColor="#80B602"
              data={{
                "Current Status(es)": "Part of Communities",
                ...getData(customerCommunities),
              }}
            />
          </div>
        </div>

        <span style={{ fontSize: "12px" }}>
          Please note that this cannot be undone
        </span>
        <div>
          <span style={{ fontSize: "12px" }}>
            Type "DELETE" into the box below and then click the "DELETE" button.
          </span>
        </div>
        <div className="col-md-6 p-0">
          <Field
            label=""
            name="delete_input"
            id="delete_input"
            type="text"
            required={true}
            component={TextField}
          />
        </div>
      </Form>
    </>
  );
};

const PermanentDeleteConfirmationWithFormik = withFormik({
  mapPropsToValues: () => {
    return { delete_input: "" }
  },
  handleSubmit: (values, { props, setSubmitting }) => {
    props.permanentlyDeleteCustomer({
      variables: { customer_id: props.customerId }
    }).then(res => {
      setSubmitting(false);
      if (res.data.permanentlyDeleteCustomer.code === 200) {
        props.onSubmit(res.data.permanentlyDeleteCustomer.message)
      }
    })
  },
  displayName: "confirmation-form",
  enableReinitialize: true,
  validationSchema: Yup.object().shape({
    delete_input: Yup.string()
      .required('Required field')
      .trim()
      .matches(/^\b(DELETE)\b$/, `You must type "DELETE"`)
      .nullable()
  })
})(PermanentDeleteConfirmation)

export default compose(
  graphql(GET_CUSTOMER_COMMUNITIES, {
    options: (props) => ({
      variables: { customer_id: props.customerId },
      fetchPolicy: "network-only"
    }),
  }),
  graphql(PERMANENT_DELETE, { name: 'permanentlyDeleteCustomer' })
)(PermanentDeleteConfirmationWithFormik);
