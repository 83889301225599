import React, { useEffect, useMemo } from "react";
import _ from "lodash";
import TableView, {
  TextHeader,
  TextCellWithMapper,
  TextCell,
} from "../../components/new-table";
import FadeIn from "../../components/fade-in";
import {
  formatActivityDate,
  getCardParams,
  getUpdatedParams,
} from "../../utils/helpers";
import reducer, { getInitialState } from "./usage-reducer";
import CustomLoader from "../../components/custom-loader";

/** @module ActivityReportTableModule */

/**
 * This component is a table used to display all the OSC/CSM's last usage activity. This table does not contain any title or search
 * bar. It contains pagination, filtering, and export to csv options. It has no hover-actions. It shows multiple
 * columns displaying basic information related to the user's usage of the application. Table uses reducer to maintain its state. It also
 * accesses store to check if table parameters are already stored or not.
 *
 * @param {Object} props it contains all the mutations/queries in the conatiner as props
 * @param {Object} props.data it contains array of activities to be shown on table
 * @param {Object} props.getTableParams it contains table info stored inside cache
 * @returns {JSX.Element} It returns jsx containing the table
 */
const UsageTable = ({
  data,
  getTableParams: { tableParams },
  ...props
}) => {
  const cacheParams = getCardParams("Usage Report", tableParams);
  const [state, dispatch] = React.useReducer(
    reducer,
    getInitialState({ loading: data.loading, cache: cacheParams })
  );

  const { authenticationActivities, loading, render, userPageCount, pageSize } =
    state;

  const callDispatch = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  /**
   * Either set the data in state or set the loading to inform the table what to render.
   */
  useEffect(() => {
    if (!data.loading) {
      callDispatch("UPDATE", {
        render: true,
        loading: false,
        authenticationActivities:
          data &&
          data.getAuthenticationActivity &&
          data.getAuthenticationActivity.authenticationActivities,
        userPageCount: Math.ceil(
          data.getAuthenticationActivity.total_count / pageSize
        ),
    });
    }
    if (data.loading) callDispatch("UPDATE", { loading: data.loading });
  }, [data]);

  /**
   * This defines columns of the table. It has multiple columns, displaying information of the activities. None of
   * the columns are sortable.
   *
   * @constant
   * @memberof module:ActivityReportTableModule
   */
  const columns = useMemo(
    () => [
      {
        id: "0",
        header: "Action",
        accessor: "action",
        component: TextHeader,
        cell: {
          component: TextCell,
        },
      },
      {
        id: "1",
        header: "Activity Date",
        accessor: "activity_date",
        component: TextHeader,
        csvMapper: (field, accessor) => formatActivityDate(field[accessor]),
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) => formatActivityDate(field[accessor]),
        },
      },
    ],
    []
  );

  /**
   * It is a callback function which is called on any table change like: pagination, page-size, filters, search term.
   * Table passes its current state so that we can update the table parameters in store and state, which are used in
   * refetching the data with correct parameters.
   *
   * @function
   * @inner
   * @memberof module:ActivityReportTableModule
   * @see {@link module:ActivityReportTableModule~ActivityReportTable}
   */
  const fetchData = React.useCallback((tableProps) => {
    if (loading) {
      return;
    }
    //variables to be stored in cache/store
    const variables = {
      filter: {
        ...tableProps.filter,
      },
      limit: tableProps.pageSize,
      pageNum: tableProps.currentPage,
    };

    // update the state
    callDispatch("UPDATE", {
      ...variables,
      pageSize: tableProps.pageSize,
      loading: true,
    });

    const pageCount = Math.ceil(
      data.getAuthenticationActivity.total_count / tableProps.pageSize
    );


    if (tableProps.currentPage > pageCount - 1) {
      variables.pageNum = pageCount ? pageCount - 1 : 0;
    }
    // update total page count in the state
    if (pageCount !== userPageCount)
      callDispatch("UPDATE", { userPageCount: pageCount });

    dispatch({
      type: "UPDATE",
      payload: { ...variables, pageSize: tableProps.pageSize, loading: true },
    });
    // update the store
    props
      .setTableParams({
        variables: {
          cardsParams: getUpdatedParams(tableParams.cardsParams, {
            cardTitle: "Usage Report",
            params: variables,
          }),
        },
      })
      .then((res) => {
        callDispatch("UPDATE", { loading: false });
      });
  });

  const tableOptions = {
    title: ""
  };

  return (
    <div>
      <FadeIn show={render}>
        <div>
          <TableView
            columns={columns}
            removeSearchField
            data={authenticationActivities}
            tableOptions={tableOptions}
            loading={loading}
            userPageCount={userPageCount}
            userTotalCount={
              !loading ? data.getAuthenticationActivity.total_count : null
            }
            fetchData={fetchData}
            currentPage={state.pageNum}
            pageSize={state.pageSize}
            filter={state.filter}
            controlled={true}
          />
        </div>
      </FadeIn>
      {!render && (
        <CustomLoader
          style={{ marginLeft: "auto", marginRight: "auto", zIndex: 9999 }}
        />
      )}
    </div>
  );
};

export default UsageTable;
