import { graphql, compose } from "react-apollo";

import ProspectDetailsView from "./prospect-details";
import {
  GET_PROSPECT_DETAIL,
} from "../../../graphql/queries";
import { SET_NOTIFICATION_PROPS } from "../../../cacheql/mutations";

const ProspectDetailContainer = compose(
  graphql(GET_PROSPECT_DETAIL, {
    name: "getProspectDetail",
    options: props => {
      return {
        variables: {
          input: {
            id: parseInt(props.match.params.id)
          }
        },
        fetchPolicy: "no-cache"
      };
    }
  }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" })
)(ProspectDetailsView);

export default ProspectDetailContainer;
