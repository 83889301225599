import gql from "graphql-tag";

export const ADD_HOMESITE = gql`
  mutation addHomeSites($input: AddHomeSiteInput!) {
    addHomeSite(input: $input) {
      code
      success
      message
    }
  }
`;

export const UPDATE_HOMESITE_AVAILABLE = gql`
  mutation makeHomeSitesAvailable($input: MakeHomeSiteAvailableInput!) {
    makeHomeSitesAvailable(input: $input) {
      code
      success
      message
    }
  }
`;

export const UPDATE_HOMESITE_UNAVAILABLE = gql`
  mutation makeHomeSitesUnavailable($input: MakeHomeSiteAvailableInput!) {
    makeHomeSitesUnavailable(input: $input) {
      code
      success
      message
    }
  }
`;
