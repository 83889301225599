import React, { useContext } from "react";
import { compose, graphql } from "react-apollo";
import { FaHome } from "react-icons/fa";
import { Auth } from "../../../rbac/rbac";
import DashboardContextProvider from "../../../context/dashboard-context-provider";
import { AgencySalesByCommunity } from "./agency-sales-by-community";
import AgencyForm from '../forms/create-update-agency'
import AgenciesTable from './all-agencies-container'
import { GET_PREVIOUS_CARD } from "../../../cacheql/queries";
import {
  GET_AGENCY_SALES_BY_COMMUNITY_COUNT,
  GET_SALES_BY_AGENCIES_COUNT,
  GET_REGISTRATIONS_BY_AGENCIES_COUNT,
  GET_AGENCIES_COUNT
} from "../../../graphql/queries/agency/agencies";

/**
 * Array of cards that are rendered on the realtor dashboard page. Each card contains title of the card which is to 
 * be shown on the card, its mode which is to be used as a filter, uniqueTitle which is to be used to store card 
 * details in the store so it is not confused with realtor card titles, customDataQuery which is used to make a custom
 * query on each card, customPath which is used to access the count, percentPath which is used to access percentage
 * value.
 */
const activityCards = [
  {
    title: "YTD Sales",
    mode: "YTD_SALES",
    uniqueTitle: "YTD Sales - agency",
    customDataQuery: GET_SALES_BY_AGENCIES_COUNT,
    customPath: 'getSalesByAgenciesCount.count',
    percentPath: 'getSalesByAgenciesCount.percent'
  },
  {
    title: "YTD Registrations",
    mode: "YTD_REGISTRATIONS",
    uniqueTitle: "YTD Registrations - agency",
    customDataQuery: GET_REGISTRATIONS_BY_AGENCIES_COUNT,
    customPath: 'getRegistrationsByAgenciesCount',
  },
  {
    title: "Sales by Community",
    uniqueTitle: "Sales by Community - agency",
    customDataQuery: GET_AGENCY_SALES_BY_COMMUNITY_COUNT,
    customPath: 'getAgenciesSalesByCommunityCount',
    customTable: <AgencySalesByCommunity />,
  },
  {
    title: "All Agencies",
    uniqueTitle: "All Agencies - agency",
  }
];

/**
 * This component is used to render Agencies dashboard with 4 cards and a table. Each card has its own custom query,
 * and sales by community has its own custom table as well. This dashboard only contains division dropdown, that too
 * for manager role only. By default All Agencies card is preselected.
 * @param {*} props 
 */
const AgenciesDashboard = props => {

  const { is_manager, has_multiple_divisions } = useContext(Auth);

  /**
   * This function checks the store if it has a previously selected card and returns that card or returns the default
   * card
   */
  const getDefaultCard = () => {
    const cardTitle = props.getPreviousCard.previousCard.title;
    const index = activityCards.findIndex(item => item.uniqueTitle === cardTitle);
    if (index === -1) {
      return activityCards[3];
    }
    return activityCards[index];
  }

  return (
    <DashboardContextProvider
      type="Agencies"
      pageTitle={is_manager() ? null : "Dashboard"}
      text="New Agency"
      startingDefaultCard={getDefaultCard()}
      formContent={AgencyForm}
      dataQuery={GET_AGENCIES_COUNT}
      activityCards={activityCards}
      icon={FaHome}
      tableView={<AgenciesTable />}
      withCommunity={false}
      withDivision={is_manager() || has_multiple_divisions()}
      {...props}
    />
  );
};

export default compose(
  graphql(GET_PREVIOUS_CARD, { name: "getPreviousCard" })
)(AgenciesDashboard);
