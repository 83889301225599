import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { compose, graphql } from "react-apollo";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";

import {
  ADD_OTHERS_VALUES,
  UPDATE_OTHERS_AVAILABLE,
  UPDATE_OTHERS_UNAVAILABLE
} from "../../graphql/mutations";
import OtherCustomizationCard from "../../components/customziation/other-customization-card";
import CustomizationOptionBox from "../../components/customziation/customizations-option-box";
import CustomButton from "../../components/custom-button";
import { isNotNULL } from "../../utils/helpers";

/**
 * this component is used to render others section in the others customization section where u can add others and make them available/unavailable.
 * @param {*} param0 
 */
const Others = ({ ...props }) => {
  const [newOtherValue, setNewOtherValue] = useState("");
  const [selectedUnavailableOther, setSelectedUnavailableOther] = useState([]);
  const [selectedAvailableOther, setSelectedAvailableOther] = useState([]);
  const defaultField = [
    {
      mutation: "updateUnavailableOthers",
      resMutation: "makeOtherDropdownValuesUnavailable",
      selctedId: selectedAvailableOther,
      callback: setSelectedAvailableOther
    },
    {
      mutation: "updateAvailableOthers",
      resMutation: "makeOtherDropdownValuesAvailable",
      selctedId: selectedUnavailableOther,
      callback: setSelectedUnavailableOther
    }
  ];

  useEffect(() => {
    setSelectedAvailableOther([]);
    setSelectedUnavailableOther([]);
  }, [props.communityId]);

  const handleSelection = (val, doubleClick, field) => {
    field.callback(val);
    if (doubleClick) {
      handleUpdate(field);
    }
  };

  const handleChange = value => {
    setNewOtherValue(value);
  };

  const handleAddClick = () => {
    props
      .addOther({
        variables: {
          input: {
            name: newOtherValue
          }
        }
      })
      .then(res => {
        if (res.data.addOtherDropdownValue.code === 200) {
          handleChange("");
          props.update(null, res.data.addOtherDropdownValue.message);
        }
      });
  };

  const handleUpdate = field => {
    if (!isNotNULL(field.selctedId) && field.selctedId !== 0) return;
    props[field.mutation]({
      variables: {
        input: {
          community_id: props.communityId,
          other_dropdown_value_ids: field.selctedId
        }
      }
    }).then(res => {
      if (res.data[field.resMutation].code === 200) {
        field.callback([]);
        props.update(null, res.data[field.resMutation].message);
      }
    });
  };

  return (
    <OtherCustomizationCard
      cardTitle="Others"
      firstCardTitle="All Others"
      firstCardView={
        <CustomizationOptionBox
          selected={selectedUnavailableOther}
          list={props.unavailable}
          handleSelection={(val, e) => handleSelection(val, e, defaultField[1])}
        />
      }
      addNewView={{
        children: (
          <>
            <input
              type="text"
              value={newOtherValue}
              disabled={props.disabled}
              className="form-control"
              placeholder="New Others"
              onChange={e => handleChange(e.target.value)}
            />
            <CustomButton
              btnValue="Add"
              onClick={() => handleAddClick()}
              disabled={isNotNULL(newOtherValue) ? props.disabled : true}
              color="green"
            />
          </>
        )
      }}
      moveToFirstCardView={
        <CustomButton
          color="black"
          btnIcon={<FaChevronLeft />}
          className="mb-1 border-0 custom-btn-arrows arrow-up"
          onClick={() => handleUpdate(defaultField[0])}
          disabled={selectedAvailableOther.length > 0 ? false : true}
        />
      }
      moveToSecondCardView={
        <CustomButton
          color="black"
          btnIcon={<FaChevronRight />}
          className="border-0 custom-btn-arrows arrow-down"
          onClick={() => handleUpdate(defaultField[1])}
          disabled={selectedUnavailableOther.length > 0 ? false : true}
        />
      }
      secondCardTitle="Available Others"
      secondCardView={
        <CustomizationOptionBox
          leftBox
          selected={selectedAvailableOther}
          list={props.available}
          handleSelection={(val, e) => handleSelection(val, e, defaultField[0])}
        />
      }
    />
  );
};

Others.propTypes = {
  communityId: PropTypes.number,
  update: PropTypes.func,
  disbaled: PropTypes.bool,
  available: PropTypes.array,
  unavailable: PropTypes.array
};

export default compose(
  graphql(ADD_OTHERS_VALUES, { name: "addOther" }),
  graphql(UPDATE_OTHERS_AVAILABLE, { name: "updateAvailableOthers" }),
  graphql(UPDATE_OTHERS_UNAVAILABLE, { name: "updateUnavailableOthers" })
)(Others);
