import React from "react";
import { compose, graphql } from "react-apollo";
import { GET_LOGGED_IN_COMMUNITIES, GET_LOGGED_IN_DIVISIONS, GET_DIVISION_BY_ID, GET_COMMUNITY_BY_ID } from "../../graphql/queries";
import { SET_NOTIFICATION_PROPS } from "../../cacheql/mutations";
import { UPDATE_COMMUNITY_INFO, UPDATE_DIVISION_INFO } from "../../graphql/mutations";
import { UpdateInformationWithFormik as UpdateInformationForm } from "./forms/update-information-form";

/**
 * This component renders the Division/Community Descriptions tab in the customizations page. It contains 2 sections.
 * First section is used to edit descripitions for all existing divisions.
 * Second section is used to edit descriptions for all existing communities
 *
 * @param {Object} props
 * @param {Object} props.getAllDivisions object containing list of divisions
 * @param {Object} props.getAllCommunities object containing list of communities
 */
const UpdateInformation = ({
  getAllDivisions: { getLoggedInUserDivisions },
  getAllCommunities: { getLoggedInUserCommunities },
  updateDivisionInformation,
  updateCommunityInformation,
  ...props }) => {
  return (
    <>
      <UpdateInformationForm
        title="Division"
        list={getLoggedInUserDivisions}
        dataQuery={GET_DIVISION_BY_ID}
        updateMutation={updateDivisionInformation}
        {...props}
      />
      <UpdateInformationForm
        title="Community"
        list={getLoggedInUserCommunities}
        dataQuery={GET_COMMUNITY_BY_ID}
        updateMutation={updateCommunityInformation}
        {...props}
      />
    </>

  )
};

export default compose(
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" }),
  graphql(UPDATE_DIVISION_INFO, { name: "updateDivisionInformation" }),
  graphql(UPDATE_COMMUNITY_INFO, { name: "updateCommunityInformation" }),
  graphql(GET_LOGGED_IN_DIVISIONS, { name: "getAllDivisions" }),
  graphql(GET_LOGGED_IN_COMMUNITIES, { name: "getAllCommunities" })
)(UpdateInformation);
