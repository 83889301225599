import React from "react";
import { useQuery } from "react-apollo-hooks";

import ContentGrid from "../../components/grid/content-grid";
import DashboardSelectBar from "../dashboard/dashboard-select-bar";
import OscCommissionReport from "./osc-commission-report-container";
import {
  GET_SELECTED_OSC,
} from "../../cacheql/queries";

const OscCommissionReportDashboard = (props) => {
  const {
    data: { selectedOscIds },
  } = useQuery(GET_SELECTED_OSC);

  return (
    <ContentGrid
      id="osc-commission-report"
      selectbar={
        <DashboardSelectBar
          page_type="Report"
          withDate
          withOsc
          selectedOscIds
          {...props}
        />
      }
      content={{
        data: <OscCommissionReport selectedOscIds={selectedOscIds} />
      }}
    />
  );
};

export default OscCommissionReportDashboard;
