import gql from 'graphql-tag';

const ADD_HOMEOWNER_FOLLOW_UP = gql` 
  mutation addHomeownerFollowUp($input: HomeownerFollowUpInput!) {
    addHomeownerFollowUp(input: $input) {
      message
      code
    }
  }
`;

export default ADD_HOMEOWNER_FOLLOW_UP;