import gql from "graphql-tag";

const SELECT_COMMUNITY = gql`
  mutation selectCommunity($selectedCommunity: [Int]) {
    selectCommunity(
      selectedCommunity: $selectedCommunity
    ) @client {
      ids
    }
  }
`;

export default SELECT_COMMUNITY;
