import React, { useState, useEffect } from "react";
import { compose, graphql } from "react-apollo";
import { GET_WEBFORM_TYPE_AUTO_CONVERT } from "../../graphql/queries";
import { SET_NOTIFICATION_PROPS } from "../../cacheql/mutations";
import { sortArray } from "../../utils/helpers";
import AutoConvertSelector from "./auto-convert-selector";
import Select from "../../components/select";
import Head from "../../components/dashboard-title";

/**
 * This component renders the Manage Auto-Convert section in the Web From Reply Email Associations tab. There is a {@link AutoConvertSelector} which contains multiple
 * tabs: webform, marketing only, lead and prospect. The user can move the webform type in between these tabs using the buttons alongside and the webform type is updated accordingly.
 * @param {Object} props
 * @param {Object} props.getAutoConvertWebformTypes A list of all Webform types for auto convert component
 */
const ManageAutoReply = ({ getAutoConvertWebformTypes, ...props }) => {
  const [selectedOption, setSelectedOption] = useState({})
  const [typeList, setTypeList] = useState({})

  const customerTypeSelectOptions = [
    { label: "Marketing Only", value: "marketing_only" },
    { label: "Leads", value: "lead" },
    { label: "Prospects", value: "prospect" }
  ]

  /**
   * This function is used to refetch the data whenever there is an update on the {@link AutoConvertSelector} component
   * @param {String} msg The response message to show in the notification
   */
  const refetchAutoConvert = (msg) => {
    notify(msg)
    getAutoConvertWebformTypes.refetch()
  }

  /**
   * A function to show a notification window on the bottom of the screen.
   * @param {String} message The message to show
   */
  const notify = (message) => {
    if (message) {
      props.setNotificationProps({
        variables: { open: true, message: message }
      })
    }
  }

  useEffect(() => {
    if (!getAutoConvertWebformTypes.loading) {
      let webform_types = []
      let marketing_only_types = []
      let lead_types = []
      let prospect_types = []

      let all_types = sortArray(getAutoConvertWebformTypes.getWebFormTypeAutoConvert, 'name', 'asc')
      all_types.map((webform_type) => {
        switch (webform_type.with_community) {
          case null:
            webform_types.push({
              ...webform_type,
              id: webform_type.id + "_withCommunity",
              name: webform_type.name + " - with community",
            });
            break;
          case "marketing_only":
            marketing_only_types.push({
              ...webform_type,
              id: webform_type.id + "_withCommunity",
              name: webform_type.name + " - with community",
            });
            break;
          case "lead":
            lead_types.push({
              ...webform_type,
              id: webform_type.id + "_withCommunity",
              name: webform_type.name + " - with community",
            });
            break;
          case "prospect":
            prospect_types.push({
              ...webform_type,
              id: webform_type.id + "_withCommunity",
              name: webform_type.name + " - with community",
            });
          default:
            break;
        }

        switch (webform_type.without_community) {
          case null:
            webform_types.push({
              ...webform_type,
              id: webform_type.id + "_withoutCommunity",
            });
            break;
          case "marketing_only":
            marketing_only_types.push({
              ...webform_type,
              id: webform_type.id + "_withoutCommunity",
            });
            break;
          case "lead":
            lead_types.push({
              ...webform_type,
              id: webform_type.id + "_withoutCommunity",
            });
            break;
          default:
            break;
        }
      })

      setTypeList({
        webForm: webform_types,
        marketing_only: marketing_only_types,
        lead: lead_types,
        prospect: prospect_types
      })
    }
  }, [getAutoConvertWebformTypes])

  return (
    <>
      <h3 style={{ fontSize: '15px', marginLeft: '0', marginBottom: '1.5rem' }}>Auto Convert</h3>
      <span className="top-caption">
        Please note that form types are of two types, i.e with community and
        without community. Form types without community can only be moved to Marketing Only or Lead.
      </span>
      <div style={{ marginTop: '1.5rem' }}>
        <div className="col-padding mb-4 col-lg-3">
          <Head title="Convert To" />
          <Select
            placeholder="Select Customer Type"
            options={customerTypeSelectOptions}
            className="mt-2"
            onChange={(e) => setSelectedOption(e)}
          />
        </div>
        <AutoConvertSelector
          webFormList={typeList.webForm}
          secondaryTitle={selectedOption.label}
          secondaryList={typeList[selectedOption.value]}
          convertType={selectedOption.value}
          update={refetchAutoConvert}
        />
      </div>
    </>
  )
}

export default compose(
  graphql(GET_WEBFORM_TYPE_AUTO_CONVERT, { name: "getAutoConvertWebformTypes" }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" })
)(ManageAutoReply)