
import { graphql, compose } from 'react-apollo';
import MergeCustomerForm from './merge-customer-form'
import withUserContext from '../../hoc/with-user-context'
import { GET_ALL_STATES, GET_CUSTOMER_ORIGINATION_AREA, GET_DIVISIONS, GET_REALTORS } from "../../graphql/queries";
import { MERGE_CUSTOMERS } from '../../graphql/mutations';
import { SET_NOTIFICATION_PROPS } from '../../cacheql/mutations';

const MergeCustomerFormContainer = compose(
  graphql(GET_DIVISIONS, { name: "getAllDivisions" }),
  graphql(GET_ALL_STATES, { name: "getAllStates" }),
  graphql(GET_REALTORS, { name: "getAllRealtors" }),
  graphql(MERGE_CUSTOMERS, { name: "mergeCustomers" }),
  graphql(GET_CUSTOMER_ORIGINATION_AREA, { name: "getOriginationAreas" }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" }),
)(MergeCustomerForm);

export default withUserContext(MergeCustomerFormContainer);