import gql from 'graphql-tag';

export const CREATE_MARKETING_ONLY = gql`
mutation addMarketingOnly($input: AddMarketingOnlyInput!) {
  addMarketingOnly(input: $input) {
    message
    code
    data {
      leadCommunities {
        id
        name
      }
      prospectCommunities {
        id
        name
      }
      marketingOnlyCommunities {
        id
        name
      }
    }
  }
}
`;

export const CREATE_MARKETING_ONLY_DUPLICATE = gql`
mutation addMarketingOnlyDuplicate($input: AddMarketingOnlyInput, $overwrite: Boolean = false) {
  addMarketingOnlyDuplicate(input: $input, overwrite: $overwrite) {
    message
    code
  }
}
`;

export const UPDATE_MARKETING_ONLY = gql`
mutation updateMarketingOnly($input: UpdateMarketingOnlyInput!) {
  updateMarketingOnly(input: $input) {
    message
    code
    data {
      id
      name
    }
  }
}
`;

export const DELETE_MARKETING_ONLY = gql` 
mutation deleteMarketingOnly($input: DeleteMarketingOnlyInput!) {
  deleteMarketingOnly(input: $input) {
    message
    code
  }
}
`;

export const CONVERT_MARKETING_ONLY_TO_LEAD = gql` 
  mutation convertMarketingOnlyToLead($input: ConvertMarketingOnlyToLeadInput!) {
    convertMarketingOnlyToLead(input: $input) {
      message
      code
    }
  }
`;

export const CONVERT_MARKETING_ONLY_TO_PROSPECT = gql` 
  mutation convertMarketingOnlyToProspect($input: ConvertMarketingOnlyToProspectInput!) {
    convertMarketingOnlyToProspect(input: $input) {
      message
      code
    }
  }
`;

export const CREATE_MARKETING_ONLY_FILTER = gql`
mutation addMarketingOnlyFilter($input: AddMarketingOnlyFilterInput!){
    addMarketingOnlyFilter(input: $input) {
      message
      code
    }
  }
`;