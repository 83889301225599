import React, { useContext, useState, useCallback, useEffect } from "react";
import { Auth } from "../../rbac/rbac";
import Drawer from "../../hoc/Drawer";
import CreateProspectForm from "../prospect/forms/create-update-prospect-container";
import { phoneDisplay, removeDuplicates } from "../../utils/helpers";

// this is called for recover and convert to prospect from webform drawer!

/**
 *@module  ConvertProspectViewModule
 */
/**
 * this component is used while form conversion/recover to a prospect, to render the edit prospect drawer. It takes the customer information and puts it in prospect format and pass it to the drawer
 * @param {*} props
 * @param {object} props.data contains information of the customer
 * @param {function} props.onSubmit
 * @param {function} props.toggleShowDrawer closes the drawer
 * @param {boolean} props.showSideBar boolean that defines wether to show the drawer or not
 * @param {boolean} props.convertForm boolean that defines if its conversion
 * @param {boolean} props.recoverForm boolean that defines if its recover
 * @param {string} props.convertFormType type of the page thats beeing called from
 * @param {object} registration contains information related to the realtor registration
 */
const ConvertProspectView = (props) => {
  const {
    data,
    onSubmit,
    toggleShowDrawer,
    showSideBar,
    convertForm,
    recoverForm,
    convertFormType,
    registration,
  } = props;
  const [inActiveCommunities, setInactiveCommunities] = useState([]);
  const [communities, setCommunities] = useState([]);

  const { is_csm } = useContext(Auth);

  /**
 * this function takes the communities from the data and checks if they are active or inactive and sets them accordingly
 * @param {object} data contains information of the communities
 * @function
 * @inner
 * @memberof module:ConvertProspectViewModule
 */
  const getCommunities = useCallback(
    (data) => {
      let communities = [];
      let inActive = [];

      if (convertFormType === "WEBFORM") {
        const detailsCommunities = data.community && [data.community]
        communities = communities = removeDuplicates(detailsCommunities, "id");
      } else if (convertFormType === "REALTOR REGISTRATION") {
        communities = data.communities;
      }

      communities.map(
        (community) => !community.is_active && inActive.push(community)
      );
      setInactiveCommunities(inActive);
      setCommunities(communities);
    },
    [convertFormType, is_csm]
  );

  useEffect(() => {
    if (data) getCommunities(data);
  }, [data, getCommunities]);

  /**
   * this function prepared the data to be passed to the prospect form, it takes the customer information and puts it in prospect format.
   * @param {object} prospctData contains information of the customer
   * @function
   * @inner
   * @memberof module:ConvertProspectViewModule
   * @returns {object}
   */
  const formatData = (prospctData) => {
    let dataToReturn = (({
      firstname,
      lastname,
      email,
      phone,
      firstname2,
      lastname2,
      email2,
      phone2,
      realtor,
      state,
      division,
      osc_notes
    }) => {
      return {
        first_name_1: firstname || prospctData.first_name_1,
        last_name_1: lastname || prospctData.last_name_1,
        primary_email_1: email || prospctData.primary_email_1,
        cell_phone_1: phone || prospctData.cell_phone_1,
        formatted_cell_phone_1:
          (phone && phoneDisplay(phone)) ||
          (prospctData.cell_phone_1 && phoneDisplay(prospctData.cell_phone_1)),
        secondary_emails_1: prospctData.secondary_emails_1 || [],
        // hidden_secondary_emails_1: "" || prospctData.secondary_emails_1,
        first_name_2: firstname2 || prospctData.first_name_2,
        last_name_2: lastname2 || prospctData.last_name_2,
        primary_email_2: email2 || prospctData.primary_email_2,
        cell_phone_2: phone2 || prospctData.cell_phone_2,
        formatted_cell_phone_2:
          (phone2 && phoneDisplay(phone2)) ||
          (prospctData.cell_phone_2 && phoneDisplay(prospctData.cell_phone_2)),
        secondary_emails_2: prospctData.secondary_emails_2 || [],
        city: "" || prospctData.city,
        state_id: state && state.id ? state.id : undefined,
        realtor_id: (realtor && realtor.id) || undefined,
        zip: "" || prospctData.zip,
        street_address: "" || prospctData.street_address,
        customer_origination_area_id:
          prospctData.customer_origination_area &&
          prospctData.customer_origination_area.id,
        division_id: division && division.id,
        osc_notes
      };
    })(prospctData);

    dataToReturn.Prospects = communities.length
      ? communities.map((community) => {
        return {
          community_id: community.is_active ? `${community.id}__${community.division_id}` : "",
          rank_id: convertForm || convertFormType === "WEBFORM" ? 5 : "",
          csm_id: "",
          floor_plans: null,
          home_sites: null,
          other_dropdown_values: null,
          add_appointment: false,
          Prospect_Appointments: {
            send_email: true,
            title: "",
            details: "",
            appointment_type_id: null,
            appointment_datetime: new Date(),
            start_datetime: new Date(),
            end_datetime: new Date(),
            csm_id: null,
          },
          Prospect_Follow_Ups: {
            title: "",
            details: "",
            follow_up_datetime: new Date(),
            csm_id: undefined
          }
        };
      })
      : [
        {
          community_id: "",
          rank_id: convertForm || convertFormType === "WEBFORM" ? 5 : "",
          csm_id: "",
          floor_plans: null,
          home_sites: null,
          other_dropdown_values: null,
          add_appointment: false,
          Prospect_Appointments: {
            send_email: true,
            title: "",
            details: "",
            appointment_type_id: null,
            appointment_datetime: new Date(),
            start_datetime: new Date(),
            end_datetime: new Date(),
            csm_id: null,
          },
          Prospect_Follow_Ups: {
            title: "",
            details: "",
            follow_up_datetime: new Date(),
            csm_id: undefined
          }
        },
      ];

    dataToReturn.others = {
      next_step: "",
      other_notes: "",
      future_promise: "",
      motivation_what_changed: "",
      motivation_uncovered: false,
      cost_feat_objections: "",
      current_dissatisfaction: "",
      reservation: false,
      video_text: false,
    };
    return dataToReturn;
  };

  return (
    <div>
      <Drawer show={showSideBar} toggleSideBar={toggleShowDrawer}>
        <CreateProspectForm
          close={toggleShowDrawer}
          data={data && formatData(data)}
          registration_id={props.registration_id}
          onSubmit={onSubmit}
          convertForm={convertForm}
          recoverForm={recoverForm}
          convertFormType={convertFormType}
          inActiveCommunities={inActiveCommunities}
          registration={registration}
        />
      </Drawer>
    </div>
  );
};

export default ConvertProspectView;
