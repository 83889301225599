import gql from "graphql-tag";

const GET_DASHBOARD_WEBFORMS = gql`
  query getFormSubmission($input: FormSubmissionFilter){
    getWebForms:getFormSubmission(filter:$input){
      WebForms:formSubmission{
        firstname
        lastname
        email
        phone
      }
      unrespondedCount
    }
  }
`;


export default GET_DASHBOARD_WEBFORMS;
