import React, { useState, useEffect } from "react";

import DashboardSelectBar from "../views/dashboard/dashboard-select-bar";
import ContentGrid from "../components/grid/content-grid";
import { DashboardContext } from ".";
import ActivityCardsView from "../containers/cards/activity-card-container";

/**
 * This component is used by all the pages which require cards and table below them. It maintains the current card 
 * state adds it to the context and wraps the whole page inside context provider so that card and function used to 
 * update it can be available to all the child components down the tree. It uses {@link ContentGrid} to place all the
 * contents in the right place
 * @param {Object} props 
 * @param {JSX.Element} props.tableView table to be rendered
 * @param {String} props.type type of page
 * @param {Array} props.activityCards array of objects containing card
 * @param {Boolean} props.withCommunity if page contains community dropdown
 * @param {Boolean} props.withDivision if page contains division dropdown
 * @param {Object} props.startingDefaultCard default card selected on page load
 */
const DashboardContextProvider = props => {
  const {
    tableView,
    type,
    activityCards,
    withCommunity = true,
    withDivision = false,
    location: { hash },
    startingDefaultCard,
  } = props;

  const defaultCard = props.startingDefaultCard
    ? props.startingDefaultCard
    : {
      title: startingDefaultCard,
      filter: {},
      isDetail: true
    };

  const [currentCard, setCurrentCard] = useState(defaultCard);
  const [updated, setUpdated] = useState(null);

  useEffect(() => {
    const card = activityCards && activityCards.find(c => c.hash === hash);
    card && setCurrentCard(card);
  }, [hash]);

  const updateFetch = () => {
    setUpdated(updated => !updated);
  };

  const updateCurrentCard = card => {
    setCurrentCard(card || defaultCard);
  };

  //to pass additional props to a component
  const table = React.cloneElement(
    currentCard.customTable || tableView,
    type === "Web Forms"
      ? { currentCard }
      : {
        update: updateFetch,
        currentCard,
        updated
      }
  );

  //information to be added to context
  const value = {
    type,
    currentCard,
    updateCurrentCard,
    activityCards,
    withCommunity,
    withDivision,
    updated,
    ...props
  };

  return (
    <DashboardContext.Provider value={value}>
      <ContentGrid
        id="content-grid"
        selectbar={
          <DashboardSelectBar
            page_type={type}
            {...props}
            update={updateFetch}
            barButton={
              props.formContent
                ? {
                  value: props.text,
                  icon: props.icon,
                  formContent: props.formContent
                }
                : null
            }
          />
        }
        content={{
          cards: activityCards ? <ActivityCardsView /> : null,
          data: table
        }}
      />
    </DashboardContext.Provider>
  );
};

export default DashboardContextProvider;
