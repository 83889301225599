import { compose, graphql } from "react-apollo";
import { GET_FORM_SUBMISSIONS } from "../../graphql/queries";
import GET_SELECTED_COMMUNITY from "../../cacheql/queries/community";
import {
  SET_NOTIFICATION_PROPS,
  SET_TABLE_PARAMS
} from "../../cacheql/mutations";
import WebFormOthersTable from "./webform-others-table";
import { OTHER_TYPE_ID } from "./customer-types";
import { GET_TABLE_PARAMS, GET_SELECTED_DIVISION } from "../../cacheql/queries";
import { getCardParams, getCommunityIds } from "../../utils/helpers";

export default compose(
  graphql(GET_SELECTED_COMMUNITY, { name: "selectedCommunity" }),
  graphql(GET_SELECTED_DIVISION, { name: "selectedDivision" }),
  graphql(SET_TABLE_PARAMS, { name: "setTableParams" }),
  graphql(GET_TABLE_PARAMS, { name: "getTableParams" }),
  graphql(GET_FORM_SUBMISSIONS, {
    options: ({getTableParams: { tableParams },selectedDivision,selectedCommunity, ...props}) => {
      const params = getCardParams("Web Form Others", tableParams);

      return {
        variables: {
          input: {
            customer_type_id: OTHER_TYPE_ID,
            division_id: selectedDivision.selectedDivision.id,
            community_ids: getCommunityIds(selectedCommunity),
          },
          pageNum: (params && params.pageNum) || 0,
          limit: (params && params.limit) || 10,
          sort:
            params && params.order && params.order.id !== -1
              ? [params.order.name, params.order.sort]
              : null,
          search: (params && params.search) || ""
        },
        fetchPolicy: "network-only"
      }
    }
  }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" })
)(WebFormOthersTable);
