import React, { useState, useEffect } from "react";
import { FaSearch, FaTimes } from "react-icons/fa";
import DebounceInput from "../debounce-input";
import "./table-search.css";

/**
 * it is used for controlled tables, after a user inputs a search term,
 * its setting the search term value and sending it to backend
 * @param {string} searchTerm 
 * @param {function} setSearchTerm
 */
export const TableSearch = ({ setSearchTerm, searchTerm = "" }) => {
  const [value, setValue] = useState(searchTerm);

  return (
    <div className="form-group has-search search-bar">
      <FaSearch className="icon-search" />
      <input
        type="text"
        className="form-control"
        placeholder="Search"
        value={value}
        onChange={e => {
          setValue(e.target.value);
          setSearchTerm(e.target.value);
        }}
      />
      <div className="input-group-append">
        <button
          className="btn btn-secondary"
          type="button"
          onClick={e => {
            setValue("");
            setSearchTerm("");
          }}
        >
          x
        </button>
      </div>
    </div>
  );
};

/**
 * it is used for non controlled tables.
 * @param {string} searchTerm 
 * @param {function} setSearchTerm
 */
export const TableDebounceSearch = ({ id, setSearchTerm, searchTerm = "", isGlobalSearch, callback }) => {
  const [value, setValue] = React.useState("");
  useEffect(() => {
    setValue(searchTerm);
  }, [searchTerm]);
  return (
    <>
      <DebounceInput
        minLength={0}
        element={props => (
          <div className={isGlobalSearch ? "d-flex justify-content-between align-items-center w-100" : "form-group has-search search-bar"}>
            <FaSearch className="icon-search" />
            <input
              type="text"
              id={id || "search-input"}
              className="form-control"
              placeholder="Search"
              autoFocus={
                id && isGlobalSearch ? document.activeElement.id === "global-search-bar" || document.activeElement.id === id : document.activeElement.id === "search-input"
              }
              {...props}
            />
            <div className="input-group-append">
              <button
                className="btn btn-secondary"
                type="button"
                onClick={e => {
                  setValue("");
                  setSearchTerm("");
                  isGlobalSearch && callback();
                }}
              >
                <FaTimes />
              </button>
            </div>
          </div>
        )}
        value={value}
        onChange={event => {
          setValue(event.target.value);
          setSearchTerm(event.target.value);
        }}
        debounceTimeout={1000}
      />
    </>
  );
};
