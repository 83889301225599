import React, { useEffect, useMemo, useContext } from "react";
import reducer, { getInitialState } from "./realtor-sales-by-community-reducer";
import { ADD_REALTOR } from "../../graphql/mutations";
import { DashboardContext } from "../../context";
import { compose, graphql } from "react-apollo";
import { GET_SALES_BY_COMMUNITY } from "../../graphql/queries/realtors/list-realtors";
import { getUpdatedParams, getCardParams } from "../../utils/helpers";
import { SET_NOTIFICATION_PROPS, SET_TABLE_PARAMS } from "../../cacheql/mutations";
import { GET_TABLE_PARAMS, GET_SELECTED_DIVISION } from "../../cacheql/queries";
import RegistrationSalesDetail from "./drawer/registration-sales-detail";
import Drawer from "../../hoc/Drawer";
import TableView, {
  stringComparator,
  TextHeader,
  TextCellWithLinkAndMapper,
  TextCellWithMapper
} from "../../components/new-table";
import "./style.css";

/** @module RealtorSalesTableModule */

/**
 * This component is a table used to render sales by community card table. It contains pagination and export to csv options.
 * It shows multiple columns displaying basic information related to sale. 
 * Table uses reducer to maintain its state. It also accesses store to check if table parameters are already stored or not.
 *
 * @param {Object} props it contains all the queries/mutations in the conatiner as props
 * @param {Object} props.data it contains array of sale to be shown on table
 * @param {Object} props.getTableParams it contains cards info stored inside cache
 * @returns {JSX.Element} It returns jsx containing the table
 */
const RealtorSalesTable = ({ data, addRealtor, getTableParams: { tableParams }, ...props }) => {
  const { currentCard } = useContext(DashboardContext);
  const cacheParams = getCardParams(currentCard.title, tableParams);
  const [state, dispatch] = React.useReducer(
    reducer,
    getInitialState({ cache: cacheParams })
  );

  const {
    showRealtorBar,
    loading,
    realtorSales,
    drawerItem
  } = state
  /**
   * Either set the data in state or set the loading to inform the table what to render.
   */
  useEffect(() => {
    if (!data.loading && data.getSalesByCommunities && data.getSalesByCommunities.sales) {
      dispatch({
        type: "UPDATE",
        payload: {
          loading: false,
          realtorSales: data.getSalesByCommunities.sales
        }
      });
    }
    if (data.loading) {
      dispatch({ type: "UPDATE", payload: { loading: data.loading } });
    }
  }, [data]);

  const drawerHandler = (record) => {
    dispatch({
      type: "UPDATE",
      payload: {
        showRealtorBar: true,
        drawerItem: (<RegistrationSalesDetail
          saleDetail={record}
          close={() => setShowRealtorBar(false)}
        />)
      }
    });
  };

  const setShowRealtorBar = (showRealtorBar) => dispatch({ type: "UPDATE", payload: { showRealtorBar: showRealtorBar } });

  /**
 * This defines columns of the table. It has multiple columns, 
 * displaying information of the sales.
 * @constant
 * @memberof module:RealtorSalesTableModule
 */
  const columns = useMemo(
    () => [
      {
        id: "1",
        header: "Communities",
        accessor: "community",
        component: TextHeader,
        comparator: stringComparator,
        csvMapper: (field, accessor) =>
          field[accessor] ? field[accessor].name : "",
        cell: {
          component: TextCellWithLinkAndMapper,
          mapper: (field, accessor) => field[accessor] && field[accessor].name,
          action: drawerHandler
        }
      },
      {
        id: "2",
        header: "Divisions",
        accessor: "division",
        component: TextHeader,
        comparator: stringComparator,
        csvMapper: (field, accessor) =>
          field[accessor] ? field[accessor].name : "",
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) => field[accessor] && field[accessor].name
        }
      },
      {
        id: "3",
        header: "No. of Sale",
        accessor: "realtor_sales_count",
        component: TextHeader,
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) => field[accessor]
        }
      }
    ],
    []
  );

  /**
   * It is a callback function which is called on any table change like: pagination, page-size/
   * Table passes its current state so that we can update the table parameters in store and state, which are used in
   * refetching the data with correct parameters.
   *
   * @function
   * @inner
   * @memberof module:RealtorSalesTableModule
   * @see {@link module:RealtorSalesTableModule~RealtorSalesTable}
   */
  const fetchData = React.useCallback(tableProps => {
    if (loading) {
      return;
    }

    const variables = {
      pageSize: tableProps.pageSize,
      pageNum: tableProps.currentPage,
      searchTerm: tableProps.searchTerm
    };

    if (tableProps.searchTerm !== state.searchTerm) {
      variables.pageNum = 0;
    }

    dispatch({ type: "UPDATE", payload: { ...variables } });
    props
      .setTableParams({
        variables: {
          cardsParams: getUpdatedParams(tableParams.cardsParams, {
            cardTitle: currentCard.title,
            params: variables
          })
        }
      })
  });

  /**
   * This is an object that defines table title.
   *
   * @constant
   * @memberof module:AllRegistrationsTableModule
   */
  const tableOptions = {
    title: currentCard.title
  };
  return (
    <>
      <div className="realtor-community-sales">
        <TableView
          removeSearchField
          columns={columns}
          data={realtorSales}
          tableOptions={tableOptions}
          loading={loading}
          fetchData={fetchData}
          currentPage={state.pageNum}
          pageSize={state.pageSize}
          searchTerm={state.searchTerm}
        />
      </div>

      <Drawer show={showRealtorBar} toggleSideBar={() => setShowRealtorBar(false)}>
        {drawerItem}
      </Drawer>
    </>
  );
};

export const RealtorSalesByCommunity = compose(
  graphql(GET_SELECTED_DIVISION, { name: "selectedDivision" }),
  graphql(SET_TABLE_PARAMS, { name: "setTableParams" }),
  graphql(GET_TABLE_PARAMS, { name: "getTableParams" }),
  graphql(GET_SALES_BY_COMMUNITY, {
    options: ({
      selectedDivision
    }) => {
      return {
        variables: {
          filter: {
            division_id: selectedDivision.selectedDivision.id,
          }
        },
        fetchPolicy: "cache-and-network"
      }
    }
  }),
  graphql(ADD_REALTOR, { name: "addRealtor" }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" })
)(RealtorSalesTable);
