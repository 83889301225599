import React from "react";
import { SelectBar } from "../../containers";

/**
 * This is a layout component which defines a page structure. It renders a selectbar (if any) at the top, then cards and finally the table.
 * @param {Object} props 
 * @param {JSX.Element} props.selectbar top bar which contains selection dropdowns, or header
 * @param {{cards: JSX.Element, data: JSX.Element}} props.content it contains cards view, and table which are to be rendererd below selectbar
 */
const ContentGrid = ({ ...props }) => {

  const table = props.content.data

  return (
    <>
      <div
        className={"col-md-12 col-12 col-padding " + props.classes}
        id={props.id + "-dashboard-cont"}
      >
        {props.selectbar ? (
          <div className="row root-row">
            <SelectBar id={props.id + "-selectbar-cont"}>
              {props.selectbar}
            </SelectBar>
          </div>
        ) : null}
        <div className="m-3" id={props.id + "-dashboard-activity-cont"}>
          {props.content.cards}
          <div className="col-md-12 col-12 p-0" id="data-table">
            {table}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentGrid;
