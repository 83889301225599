import React, { useEffect, useState, useRef, useContext } from "react";
import { useQuery } from "react-apollo-hooks";
import { Auth } from "../../rbac/rbac";
import CustomButton from "../../components/custom-button";
import Header from "../../components/dashboard-title";
import SelectionListPopup from "../../containers/list-popup/cached-selection-list-container";
import DateRangeContainer from "../../containers/date-range/date-range-container";
import Drawer from "../../hoc/Drawer";
import {
  GET_SELECTED_COMMUNITY,
  GET_SELECTED_DIVISION,
  GET_SELECTED_CSM,
  GET_SELECTED_USER,
  GET_SELECTED_SINGLE_COMMUNITY,
  GET_SELECTED_OSC,
  GET_SELECTED_SINGLE_USER
} from "../../cacheql/queries";
import { GET_COMMUNITIES, GET_LOGGED_IN_COMMUNITIES, GET_LOGGED_IN_DIVISIONS, GET_CSM, GET_OSC } from "../../graphql/queries";
import { SELECT_COMMUNITY, SELECT_DIVISION, SELECT_OSC, SELECT_CSM, SELECT_USER, SELECT_SINGLE_COMMUNITY, SELECT_SINGLE_USER } from "../../cacheql/mutations";
import "./dashboard.css";

/**
 * This component forms the top bar of the page. It has a dropdown/header on the left side, and a button on the 
 * extreme right corner. This button is used to open a custom form in the side-drawer. Left part of the side bar can 
 * have one or multiple dropdowns. Dropdowns can be single-select or multi-select. It accepts as props that which 
 * dropdown should be visible. 
 * @param {Object} props 
 * @param {Boolean} props.withCommunity to show/hide community dropdown 
 * @param {Boolean} props.withDivision to show/hide division dropdown
 * @param {Boolean} props.withCsm to show/hide csm dropdown
 * @param {Boolean} props.withOsc to show/hide osc dropdown
 * @param {Boolean} props.withUser to show/hide user dropdown
 * @param {Boolean} props.withSingleCommunity to show hide single-select community
 * @param {Boolean} props.withDate to show/hide date-range component
 * @param {Object} props.barButton object containing from, button name and icon
 * @param {String} props.pageTitle to show/hide header component
 */
const DashboardSelectBar = ({
  getAllCommunities,
  getAllDivisions,
  withCommunity,
  withDivision,
  withCsm,
  withUser,
  withSingleUser,
  withSingleCommunity,
  withDate,
  withOsc,
  defaultItem,
  barButton,
  type,
  pageTitle,
  ...props
}) => {
  const {
    is_csm,
    is_osc,
    is_divmgr,
    is_manager,
    user
  } = useContext(Auth);
  const { update } = props;
  const [showSideBar, setShowSideBar] = useState(false);
  const selectBarComp = useRef(null);

  const focusComp = () => selectBarComp.current.scrollIntoView({ block: "end", behavior: "smooth" })

  const { data: { selectedDivision } } = useQuery(GET_SELECTED_DIVISION);
  const { data: { selectedCommunityIds } } = useQuery(GET_SELECTED_COMMUNITY);

  const headCommunityComponent = pageTitle ?
    <Header title={type} subtitle={pageTitle} />
    : withCommunity && (
      <div className={withDivision ? "mx-2 mx-md-4" : ""}>
        <SelectionListPopup
          title="Communities"
          firstItem="All Communities"
          dataQuery={
            is_csm() && (props.page_type === "Homeowners") ?
              GET_COMMUNITIES : GET_LOGGED_IN_COMMUNITIES
          }
          dataQueryTitle={
            is_csm() && (props.page_type === "Homeowners") ?
              "getAllCommunities" : "getLoggedInUserCommunities"
          }
          setSelectedQuery={SELECT_COMMUNITY}
          setSelectedVariables={"selectedCommunity"}
          getSelectedQuery={GET_SELECTED_COMMUNITY}
          selectedQueryTitle="selectedCommunityIds"
          showList={{
            isLeadView: props.page_type === "Leads",
            isProspectView: props.page_type === "Prospects",
          }}
          withDivision={{
            ids: is_csm() && (props.page_type === "Homeowners") ?
              user.Divisions?.map(div => div.id) : selectedDivision.id && [selectedDivision.id]
          }}
          selectMultiple={true}
        />
      </div>
    )

  const headDivisionComponent = (
    <div style={{ marginBottom: !headCommunityComponent && "11px" }}>
      <SelectionListPopup
        title="Division"
        firstItem="All Divisions"
        dataQuery={GET_LOGGED_IN_DIVISIONS}
        dataQueryTitle="getLoggedInUserDivisions"
        setSelectedQuery={SELECT_DIVISION}
        getSelectedQuery={GET_SELECTED_DIVISION}
        selectedQueryTitle="selectedDivision"
      />
    </div>
  )

  const headCsmOscComponent = (
    <SelectionListPopup
      preSelectedValue={withOsc && is_osc() ? [user.id] : []}
      title={withCsm ? "CSM" : "OSC"}
      firstItem={withCsm ? "All CSMs" : !is_divmgr() && !is_manager() ? `${user.first_name} ${user.last_name}` : "All OSCs"}
      dataQuery={withCsm ? GET_CSM : GET_OSC}
      dataQueryTitle={withCsm ? "getAllCSMs" : "getAllOSCs"}
      setSelectedQuery={withCsm ? SELECT_CSM : SELECT_OSC}
      setSelectedVariables={withCsm ? "selectedCsm" : "selectedOsc"}
      getSelectedQuery={withCsm ? GET_SELECTED_CSM : GET_SELECTED_OSC}
      selectedQueryTitle={withCsm ? "selectedCsmIds" : "selectedOscIds"}
      selectMultiple={true}
      community_ids={withCsm ? selectedCommunityIds.ids : null}
      withDivision={withCsm || is_divmgr() ?
        {
          ids: withOsc && is_divmgr() ?
            user.Divisions?.map(div => div.id) :
            selectedDivision.id && [selectedDivision.id]
        }
        : null
      }
      showList={withCsm ? null : { roleOSC: !is_divmgr() && !is_manager() }}
    />
  )

  const headUserComponent = (
    <SelectionListPopup
      preSelectedValue={!is_divmgr() && !is_manager() ? [user.id] : []}
      title={"User"}
      firstItem={defaultItem ? defaultItem : is_osc() ? `${user.first_name} ${user.last_name}` : "All Users"}
      dataQuery={GET_CSM}
      dataQueryTitle="getAllCSMs"
      dataQuery2={GET_OSC}
      dataQueryTitle2="getAllOSCs"
      setSelectedQuery={withSingleUser ? SELECT_SINGLE_USER : SELECT_USER}
      setSelectedVariables={withSingleUser ? "selectSingleUser" : "selectedUsers"}
      getSelectedQuery={withSingleUser ? GET_SELECTED_SINGLE_USER : GET_SELECTED_USER}
      selectedQueryTitle={withSingleUser ? "selectedSingleUserId" : "selectedUserIds"}
      selectMultiple={withSingleUser ? false : true}
      showList={{
        isReportView: true,
        roleCSM: is_csm(),
        roleOSC: is_osc(),
      }}
      dataQuery2WithDivision={{ ids: is_divmgr() ? user.Divisions.map(div => div.id) : null }}
    />
  )

  const headSingleSelectCommunityComponent = (
    <div className={"mr-4 "}>
      <SelectionListPopup
        title={"Community"}
        firstItem={"All Communities"}
        dataQuery={GET_COMMUNITIES}
        dataQueryTitle="getAllCommunities"
        setSelectedQuery={SELECT_SINGLE_COMMUNITY}
        getSelectedQuery={GET_SELECTED_SINGLE_COMMUNITY}
        selectedQueryTitle={"selectedSingleCommunityId"}
        selectMultiple={false}
      />
    </div>
  )

  const toggleShowDrawer = () => setShowSideBar(show => !show);

  const onSubmit = () => {
    setShowSideBar(false);
    update && update();
  };

  useEffect(() => {
    focusComp();
  }, [selectBarComp]);

  return (
    <>
      <div
        ref={selectBarComp}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <div
          id="dashboard-header-container"
          className="d-flex dashboard-header-wrapper z4"
          style={{ position: 'relative', top: '0.4rem' }}
        >
          {withDivision && !is_csm() ? headDivisionComponent : null}
          {headCommunityComponent}
          {withCsm || withOsc ? headCsmOscComponent : null}
          {withSingleCommunity ? headSingleSelectCommunityComponent : null}
          {withUser || withSingleUser ? headUserComponent : null}
          {withDate &&
            <div className="ml-md-3">
              <div className="caption" style={{ display: 'block', marginBottom: '0.4rem' }}>
                Date Range
              </div>
              <DateRangeContainer />
            </div>
          }
        </div>
      </div>
      <>
        {barButton && (
          <CustomButton
            size="sm"
            onClick={toggleShowDrawer}
            btnValue={barButton.value}
            btnIcon={<barButton.icon />}
          />
        )}
        {barButton && (
          <Drawer show={showSideBar} toggleSideBar={toggleShowDrawer}>
            <barButton.formContent
              close={toggleShowDrawer}
              onSubmit={onSubmit}
              editForm={false}
              dashboardForm={barButton.dashboardForm}
            />
          </Drawer>
        )}
      </>
    </>
  );
};

export default DashboardSelectBar;