import gql from "graphql-tag";

const GET_ALL_WEBFORM_TYPES = gql`
  query getAllWebFormTypes {
    getAllWebFormTypes {
      id
      name
      description
    }
  }
`;

export default GET_ALL_WEBFORM_TYPES