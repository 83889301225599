import gql from 'graphql-tag';

const GET_USER = gql`
  query {
    user @client{
      role
      name
    }
  }
`;


export default GET_USER;