import React, { useState, useContext } from "react";
import DashboardSelectBar from "./dashboard-select-bar";
import UpcomingTaskView from "./upcoming-tasks-container";
import DashboardActivityView from "./dashboard-activity-container";
import ContentGrid from "../../components/grid/content-grid";
import { FaStar } from "react-icons/fa";
import CreateProspectForm from "../prospect/forms/create-update-prospect-container";
import "./dashboard.css";
import { Auth } from "../../rbac/rbac";

/**
 * This component uses {@link ContentGrid} to align the content. This is used to render dashboard page with monthly
 * activity cards along with upcoming appointments and followups table. This page has a division dropdown at the page
 * but only for the role manager. It uses auth context to access is_manager function.
 * @param {Object} props 
 */
const Dashboard = ({ data, ...props }) => {
  const { is_manager, has_multiple_divisions } = useContext(Auth);
  const [prospectDataUpdated, setProspectDataUpdated] = useState(false);

  const update = () => {
    setProspectDataUpdated(value => !value);
  };

  return (
    <>
      <ContentGrid
        id="main"
        selectbar={
          <DashboardSelectBar
            {...props}
            withCommunity
            withCsm
            withDivision={is_manager() || has_multiple_divisions()}
            update={update}
            barButton={{
              value: "Schedule Appointment",
              icon: FaStar,
              formContent: CreateProspectForm,
              dashboardForm: true,
            }}
          />
        }
        content={{
          cards: (
            <DashboardActivityView prospectDataUpdated={prospectDataUpdated} />
          ),
          data: <UpcomingTaskView />
        }}
      />
    </>
  );
};

export default Dashboard;
