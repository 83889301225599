import gql from "graphql-tag";

const CREATE_LEAD_DUPLICATE = gql`
  mutation addLeadDuplicate(
    $input: LeadInput
    $overwrite: Boolean = false
    $registration_id: Int
  ) {
    addLeadDuplicate(
      input: $input
      overwrite: $overwrite
      registration_id: $registration_id
    ) {
      message
      code
    }
  }
`;

export default CREATE_LEAD_DUPLICATE;
