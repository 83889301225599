import { graphql, compose } from "react-apollo";
import {
  CreateProspectPhoneCall,
  UpdateProspectPhoneCall,
  CreateLeadPhoneCall,
  UpdateLeadPhoneCall,
  CreateHomeownerPhoneCall,
  UpdateHomeownerPhoneCall
} from "./add-edit-call";
import {
  ADD_PROSPECT_PHONE_CALL,
  UPDATE_PROSPECT_PHONE_CALL,
  ADD_LEAD_PHONE_CALL,
  UPDATE_LEAD_PHONE_CALL,
  ADD_HOMEOWNER_PHONE_CALL,
  UPDATE_HOMEOWNER_PHONE_CALL
} from "../../graphql/mutations";
import { GET_PHONE_CALL_TYPES } from '../../graphql/queries'

export const AddProspectPhoneCallContainer = compose(
  graphql(ADD_PROSPECT_PHONE_CALL, { name: "addProspectPhoneCall" }),
  graphql(GET_PHONE_CALL_TYPES, {name:"getAllCallTypes"})
)(CreateProspectPhoneCall);

export const UpdateProspectPhoneCallContainer = compose(
  graphql(UPDATE_PROSPECT_PHONE_CALL, { name: "updateProspectPhoneCall" }),
  graphql(GET_PHONE_CALL_TYPES, {name:"getAllCallTypes"})
)(UpdateProspectPhoneCall);

export const AddLeadPhoneCallContainer = compose(
  graphql(ADD_LEAD_PHONE_CALL, { name: "addLeadPhoneCall" }),
  graphql(GET_PHONE_CALL_TYPES, {name:"getAllCallTypes"})
)(CreateLeadPhoneCall);

export const UpdateLeadPhoneCallContainer = compose(
  graphql(UPDATE_LEAD_PHONE_CALL, { name: "updateLeadPhoneCall" }),
  graphql(GET_PHONE_CALL_TYPES, {name:"getAllCallTypes"})
)(UpdateLeadPhoneCall);

export const AddHomeownerPhoneCallContainer = compose(
  graphql(ADD_HOMEOWNER_PHONE_CALL, { name: "addHomeownerPhoneCall" }),
  graphql(GET_PHONE_CALL_TYPES, {name:"getAllCallTypes"})
)(CreateHomeownerPhoneCall);

export const UpdateHomeownerPhoneCallContainer = compose(
  graphql(UPDATE_HOMEOWNER_PHONE_CALL, { name: "updateHomeownerPhoneCall" }),
  graphql(GET_PHONE_CALL_TYPES, {name:"getAllCallTypes"})
)(UpdateHomeownerPhoneCall);
