import React from "react";
import { Table } from "react-bootstrap";
import "./custom-details-table.css";

/**
 * This component is used in details card to display information.
 */
class DetailsSectionTable extends React.Component {
  /**
     * @param {string} tableHeader Header of the table
     * @param {object} data Data of the table
     * @param {string} rowColor Color of the cell of the table
     */
  render() {
    return (
      <Table borderless className="details-table small" style={{ fontSize: '86%' }}>
        {this.props.tableHeader &&
          <thead>
            <tr>
              <th className="p-2 pl-1" colSpan="2">
                {this.props.tableHeader}
              </th>
            </tr>
          </thead>}
        <tbody>
          {Object.keys(this.props.data).map((key, index) => {
            return (
              <tr key={index}>
                <td className="details-table-row p-1 pl-2" style={{ color: this.props.rowColor && key !== "Current Status(es)" ? this.props.rowColor : "rgba(255, 255, 255, .5)", fontSize: '12px', width: '35%' }}>{key}</td>
                {key.includes("Cell") || key.includes("CELL") || key.includes("Phone") ? this.props.data[key] || "-" :
                  <td className="details-table-row notes p-1" >{this.props.data[key] || "-"}</td>}
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  }
}
export default DetailsSectionTable;
