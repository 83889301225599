import React from "react";
import { compose, graphql } from "react-apollo";

import { Form, Field, withFormik } from "formik";
import { ButtonToolbar } from "react-bootstrap";
import TextField from "../../components/fields/text-field";
import CustomButton from "../../components/custom-button/index";
import ErrorFocus from "../../components/error-focus/error-focus";
import { webformSchema } from "./webform-schema";
import { phoneDisplay } from "../../utils/helpers";
import {
  UPDATE_FORM_SUBMISSION,
  MERGE_FORM_SUBMISSION,
} from "../../graphql/mutations";
import Dialog from "../../components/dialog/dialog";

/**
 * This form is used to update webform information. Its a pretty basic form containing multiple text fields. It has only
 * 3 required fields which, without filling them form cannot be submitted. 
 * 
 * @param {Object} props 
 * @param {Object} props.values object containing agency info to be edited
 * @param {object} status contains information regarding to sho updated success and error messages.
 */
const WebFormEditForm = (props) => {
  const { values, status } = props;

  const handleCancelMerge = () => {
    props.setStatus({
      showUpdateSubmissionError: false,
      showUpdateSubmissionMessage: null,
    });
  };

  const handleMergeBody = () => {
    return (
      <div>
        {status.showUpdateSubmissionMessage}. Do you want to merge?
      </div>
    );
  };

  const handleMerge = () => {
    const { formatted_phone, ...input } = values;

    props.mergeFormSubmission({
      variables: { input: input },
    }).then((res)=>{
      if (res.data.mergeFormSubmission.code === 200) props.onSubmit()
      props.setNotificationProps({
        variables: {
          open: true,
          message: res.data.mergeFormSubmission.message
        }
      });
      handleCancelMerge();
    })
  }

  return (
    <Form className={"form-horizontal"}>
      <Dialog
        show={status ? status.showUpdateSubmissionError : false}
        title="Update Issue"
        onHide={handleCancelMerge}
        body={status ? handleMergeBody() : null}
        click={handleMerge}
        clickname="YES"
        closename="NO"
      />
      <div className="form_TitleContainer">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ margin: "0 1.1rem" }}
        >
          <div>
            <div className="form_Title">Web Form</div>
            <div className="form_TitleBig mt-1">
              <h2>{values.firstname + " " + values.lastname}</h2>
            </div>
          </div>
          <div className="d-flex pr-3">
            <ButtonToolbar>
              <CustomButton
                color="black"
                className="form_CancelButton"
                onClick={props.close}
                btnValue="CANCEL"
              />
              <CustomButton
                className="form_SubmitButton"
                type="submit"
                disabled={props.isSubmitting}
                btnValue={"UPDATE"}
              />
            </ButtonToolbar>
          </div>
        </div>
      </div>
      <div className="form_body">
        <div className="form-row">
          <div className="col-md-6">
            <Field
              label="First Name"
              name="firstname"
              id="firstname"
              type="text"
              required={true}
              component={TextField}
            />
          </div>
          <div className="col-md-6">
            <Field
              label="Last Name"
              name="lastname"
              id="lastname"
              type="text"
              required={true}
              component={TextField}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <Field
              label="Email"
              name="email"
              id="email"
              type="text"
              required={!values.formatted_phone || false}
              component={TextField}
            />
          </div>
          <div className="col-md-6">
            <Field
              label="Cell #"
              name="formatted_phone"
              id="formatted_phone"
              isCellPhone={true}
              type="text"
              required={!values.email || false}
              component={TextField}
            />
          </div>
        </div>
        <ErrorFocus />
      </div>
    </Form>
  );
};

const WebformWithFormik = withFormik({
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    const { phone } = props.data;
    const formatted_phone = phoneDisplay(phone);
    return { formatted_phone, ...props.data };
  },
  handleSubmit: (values, { props, setSubmitting, setStatus }) => {
    const { formatted_phone, ...input } = values;
    props
      .updateFormSubmission({
        variables: { input: input },
      })
      .then((res) => {
        setSubmitting(false);
        if (res.data.updateFormSubmission.code === 200) {
          props.onSubmit();
          props.setNotificationProps({
            variables: {
              open: true,
              message: res.data.updateFormSubmission.message,
            },
          });
        } else if (res.data.updateFormSubmission.code === 409) {
          setStatus({
            showUpdateSubmissionError: true,
            showUpdateSubmissionMessage: res.data.updateFormSubmission.message,
          });
        }
      });
  },
  validationSchema: webformSchema,
  displayName: "edit-web-form",
})(WebFormEditForm);

export default compose(
  graphql(UPDATE_FORM_SUBMISSION, { name: "updateFormSubmission" }),
  graphql(MERGE_FORM_SUBMISSION, { name: "mergeFormSubmission" })
)(WebformWithFormik);
