import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { getIn } from "formik";
import PropTypes from "prop-types";
import { formatAMPM, defaultTimeList } from "../../utils/helpers";

import "./fields.css";

/**
 * It is a dropdown containing list of times. If custom list is provided then those times are shown otherwise default times ranging from 00:00 to 23:30 are shown.
 * @param {Object} props 
 * @param {String} props.time value selected from the dropdown
 * @param {Array} props.customList array of times to show (optional)
 * @returns {JSX.Element} 
 */
const TimeAreaField = props => {
  const [show, setShow] = useState(false);

  const {
    id,
    label,
    field,
    customList,
    value,
    form: { errors, setFieldValue, values },
    checkFieldName,
    setFieldToValue,
    setFieldToName,
    disabled
  } = props;

  const error = checkFieldName
    ? getIn(errors, checkFieldName)
    : getIn(errors, field.name);

  /* this function checks if its end time field, then removes the unnecessary options from the list, and returns the entire list for start time.*/
  const updateEndTimeList = () => {
    if (id === "time2") {
      return defaultTimeList.filter(item => item > setFieldToValue)
    } else {
      return defaultTimeList
    }
  }

  /* Either display list that has been provided through props or default list containing time from 00:00 to 23:30 */
  const timeList = customList || updateEndTimeList()

  const CustomToggle = React.forwardRef((props, ref) => {
    return (
      <span name={field.name} ref={ref} onMouseDown={() => !disabled && setShow(!show)} className="time-toggle">
        {formatAMPM(value)}
      </span>
    );
  });

  const onSelect = (hour, index) => {
    setFieldValue(field.name, hour)
    setFieldToName && setFieldValue(setFieldToName, timeList[index + 1] || hour)
  }

  return (
    <div tabIndex="0" name={field.name} id={id + "-form-group"} className="form-group div-error-focus">

      <label htmlFor={id + "-input"} className="control-label">
        {label}
      </label>

      <div className={`time position-fix ${error && "is-invalid"}`}>
        <Dropdown
          key="down"
          id="placeholder-btn-drop-down"
          className="form-group"
          show={show}
          onToggle={() => setShow(!show)}
          style={{ height: "100%", marginBottom: "0" }}
        >
          <Dropdown.Toggle as={CustomToggle} />
          <Dropdown.Menu className="time-dropdown-menu">
            {timeList.map((hour, index) => (
              <Dropdown.Item
                key={index}
                onSelect={() => onSelect(hour, index)}
                className="time-dropdown-item"
                focus="true"
              >
                {formatAMPM(hour)}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        {error && <div className="invalid-time-feedback">{error}</div>}
      </div>

    </div>
  );
};

TimeAreaField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

export default TimeAreaField;