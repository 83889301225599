
import gql from 'graphql-tag';

const UPDATE_PROSPECT_NOTE = gql` 
  mutation updateProspectNote($input: ProspectNoteUpdateInput!) {
    updateProspectNote(input: $input) {
      message
      code
      data
    }
  }
`;

export default UPDATE_PROSPECT_NOTE;
