export const getInitialState = ({ loading, cache }) => {
  return {
    filter: {
      ...(cache && cache.filter)
    },
    search: (cache && cache.search) || "",
    limit: (cache && cache.limit) || 10,
    pageNum: (cache && cache.pageNum) || 0,
    pageSize: (cache && cache.limit) || 10,
    order: (cache && cache.order) || {
      id: -1,
      name: "",
      comparator: "",
      sort: ""
    },
    userPageCount: 0,
    Marketing_Only: [],
    loading: loading,
    render: !loading,
    isSubscription: false,
    showDeleteConfirmation: false,
    isMassDelete: false,
    showConvertConfirmation: false,
    showMarketingOnlyConvertDrawer: { show: false, type: "" },
    showPermanentDeleteConfirmation: false,
    isPermDeleteSubmitted: null,
    showEditBar: false,
    itemId: undefined,
    deleteMarketingOnlyID: undefined,
    convertMarketingOnlyID: undefined,
    deleteCustomerId: undefined,
    editItem: undefined,
    showMassEmailModal: false,
    marketingOnlyEmails: undefined,
    isMassEmailSubmitted: false,
  };
};

export default function reducer(state, action) {
  switch (action.type) {
    case "UPDATE":
      return Object.assign({}, state, { ...action.payload });

    case "RESET":
      return Object.assign({}, state, {
        ...action.payload,
        filter: {},
        search: "",
        searchTerm: "",
        limit: 10,
        pageSize: 10,
        pageNum: 0,
        order: {
          id: -1,
          name: "",
          comparator: "",
          sort: ""
        },
        userPageCount: 0
      });

    default:
      return state;
  }
}
