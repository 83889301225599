import React from "react";
import { Auth } from "../../rbac/rbac";

/**
 * This component is used to render body of delete dialog box. It shows a confirmation message along with an input to leave reason to delete. But input is not shown for role "manager" and "division-manager".
 * @param {Object} props 
 * @param {Function} setFunc it is used to set note value
 * @param {Boolean} massDelete if it is massDelete or single delete
 * @param {Boolean} isConvertToLead if it is a lead conversion scenario or not
 * @param {Boolean} customer if delete dialog box is deleting a customer or something else
 */
const DeleteDialogBox = ({ setFunc, massDelete = false, isConvertToLead, customer }) => {
  const { is_manager, is_divmgr } = React.useContext(Auth);

  const setInputValues = event => {
    massDelete
      ? setFunc(event.currentTarget.name, event.currentTarget.value)
      : setFunc(event.currentTarget.value);
  };

  return (
    <div>
      {!isConvertToLead && <p className="mb-0">Are you sure you want to delete the record{customer && " and move to archive"}?</p>}

      {!is_manager() && !is_divmgr() ? (
        <div id="delete-form-group" className="form-group">
          <hr className="light" />
          <label htmlFor="delete-input" className="control-label">
            Leave a note
          </label>
          <input
            id="delete-input"
            type="text"
            name="note"
            className="form-control"
            onChange={event => setInputValues(event)}
          />
        </div>
      ) : null}
    </div>
  );
};

export default DeleteDialogBox;
