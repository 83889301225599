export const getInitialState = ({ loading, cache }) => {
  return {
    deleteRequests: [],
    search: (cache && cache.search) || "",
    limit: (cache && cache.limit) || 10,
    pageNum: (cache && cache.pageNum) || 0,
    pageSize: (cache && cache.limit) || 10,
    order: (cache && cache.order) || {
      id: -1,
      name: "",
      comparator: "",
      sort: ""
    },
    userPageCount: 0,
    loading: loading,
    render: !loading
  };
};

export default function reducer(state, action) {
  switch (action.type) {
    case "UPDATE":
      return Object.assign({}, state, { ...action.payload });

    case "RESET":
      return Object.assign({}, state, {
        ...action.payload,
        search: "",
        limit: 10,
        pageNum: 0,
        sort: null,
        userPageCount: 0
      });

    default:
      return state;
  }
}
