import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { makeStyles, withStyles } from "@material-ui/styles";
import { FaInfoCircle } from "react-icons/fa";
import CustomButton from "../custom-button";

import './multi-select.css'

/**
 * This component is being used in to show selected items, it uses react select to show to the data without creating
 * a dropdown.  It accepts a list of objects containing id and name property to display list in the dropdown.
 * User can show multiple selected values and remove them individually or all at once by clicking on the cross icon.
 * @param {Object} props
 * @param {Array} props.selectedValues array of objects containing list of items
 * @param {Function} props.onChange callback function to perform action when a selection is removed from the list
 * @param {Function} props.labelMapper callback function to obtain label from a single selection
 * @param {Boolean} props.warningCondition condition on which to show the warning message
 * @param {String} props.warningMessage message to show on if warningCondition is true
 * @param {Function} props.onButtonClick function to run when the action button is clicked
 */
const MultiSelect = ({
  selectedValues,
  onChange,
  labelMapper,
  warningCondition,
  warningMessage,
  onButtonClick,
  buttonValue,
  ...props
}) => {
  const [values, setValues] = useState([]);

  useEffect(() => {
    setValues(getDefaults(selectedValues));
  }, [selectedValues]);

  /*It accepts an array of strings of the items that are selected, finds them in the actual list of objects and 
  creates an acceptable structure which is {value: ,label: , color: }*/
  const getDefaults = (data) => {
    return data.map((item) => {
      return { value: item.id, label: labelMapper(item), color: "grey" };
    });
  };

  //custom styles of multi select
  const customStyles = {
    control: () => ({
      display: "flex",
      alignItems: "center",
      " > div": {
        padding: "0",
        color: "transparent",
      },
      "&:hover": {
        cursor: "pointer",
      },
    }),
    indicatorsContainer: () => ({
      alignItems: "end",
      display: "flex",
    }),
    multiValue: () => ({
      backgroundColor: "#191b1d",
      color: "#666",
      display: "flex",
      borderRadius: "50px",
      marginRight: "4px",
      fontWeight: "normal",
      marginBottom: "1px",
      fontSize: '14px',
      padding: '0.5rem'
    }),
    multiValueLabel: base => ({
      ...base,
      color: '#f9f9f9',
    }),
    multiValueRemove: () => ({
      "&:hover": {
        background: "rgba(0,0,0,.1)",
      },
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    placeholder: () => ({
      fontSize: "16px",
      fontWeight: "400",
      opacity: "0.8",
      padding: "0",
      color: "white",
      marginTop: "0.4rem",
    }),
    dropdownIndicator: () => ({
      color: "white",
      padding: "8px",
      " > svg": {
        width: "19px",
      },
    }),
    option: () => ({
      backgroundColor: "black",
      border: "none",
      padding: "0.5rem 0.8rem",
      color: "#d8d8d8",
      fontSize: "0.9em",
      "&:hover": {
        backgroundColor: "#333",
        color: "#80b602",
        cursor: "pointer",
      },
    }),
  };

  return (
    <div>
      <div className="toolbarSelect">
        <div className="d-flex align-items-baseline">
          <Select
            onChange={onChange}
            styles={customStyles}
            isMulti
            isClearable={false}
            isSearchable={false}
            value={values}
            placeholder={
              <>
                <FaInfoCircle className="warning-icon" />{" "}
                <p className="mb-0 small">{warningMessage}</p>
              </>
            }
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
              Menu: () => null,
            }}
          />
          {warningCondition ? (
            <div className="warning-container mt-1">
              <FaInfoCircle className="warning-icon" />{" "}
              <p className="mb-0 small">{warningMessage}</p>
            </div>
          ) : (
            <CustomButton
              size="lg"
              color="green"
              btnValue={buttonValue}
              onClick={onButtonClick}
            />
          )}
        </div>
      </div>
    </div>
  );
};

MultiSelect.propTypes = {
  selectedValues: PropTypes.node,
  buttonValue: PropTypes.string,
};

export default withStyles(makeStyles)(MultiSelect);
