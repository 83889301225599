import gql from "graphql-tag";

const GET_DASHBOARD_REALTORS = gql`
  query getDashboardRealtors($input: RealtorFilter, $mode:RealtorsMode, $id:Int) {
    getRealtors(filter: $input, limit:1, mode:$mode ,id:$id) {
      Realtors: realtors {
        id
        first_name
        last_name
      }
      total_count
    }
  }
`;

export default GET_DASHBOARD_REALTORS;
