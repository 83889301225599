
import gql from 'graphql-tag';

const UPDATE_AGENCY = gql` 
  mutation updateAgency($id: ID!, $input: AgencyInput!) {
    updateAgency(id: $id, input: $input) {
      message
      code
    }
  }
`;

export default UPDATE_AGENCY;