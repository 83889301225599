import * as Yup from "yup";
import * as errorMessages from "../../../utils/form-errors";

export const addCommunityForm = {
  customer_id: null,
  Prospects: [
    {
      community_id: null,
      rank_id: null,
      csm_id: null,
      floor_plans: [],
      home_sites: [],
      other_dropdown_values: []
    },
  ],
};


export const addCommunitySchema = Yup.object().shape({
  Prospects: Yup.array().of(
    Yup.object().shape({
      community_id: Yup.string()
        .default("Select...")
        .required(errorMessages.REQUIRE_FIELD)
        .nullable(),
      csm_id: Yup.string().default("Select...").nullable(),
      floor_plans: Yup.array().nullable(),
      rank_id: Yup.string().default("Select...").nullable(),
      home_sites: Yup.array().nullable(),
      other_dropdown_values: Yup.array().nullable(),
    })
  ),
});
