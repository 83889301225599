import * as Yup from "yup";

export const webformFilterForm = {
    type: "",
    unresponded: false,
    unconverted: false
};

export const webformFilterSchema = Yup.object().shape({
    type: Yup.string()
        .default("All Types")
        .nullable(),
    unresponded: Yup.bool().default(false),
    unconverted: Yup.bool().default(false),
});
