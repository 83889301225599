import React, { useCallback, useState, useEffect } from "react";
import { Form, Field, withFormik } from "formik";
import { compose, graphql } from "react-apollo";
import { useDropzone } from "react-dropzone";
import { MdClose } from "react-icons/md";
import * as Yup from "yup";
import Accepted from "../../images/accepted-icon.svg";
import UploadFile from "../../images/upload-file.svg";
import ExportExcel from "../../images/export-excel.svg";
import NotAccepted from "../../images/not-accepted-icon.svg";
import SelectField from "../../components/fields/select-field";
import CustomButton from "../../components/custom-button/index";
import { SET_NOTIFICATION_PROPS } from "../../cacheql/mutations";
import { UPLOAD_WEBFORM } from "../../graphql/mutations";
import { GET_ALL_WEBFORM_TYPES, GET_COMMUNITIES, GET_DIVISIONS } from "../../graphql/queries";
import { isNotNULL } from "../../utils/helpers";
import "./admin-styles.css";

const uploadFormDefValues = {
  division_id: null,
  community_id: null,
  webform_type: null,
  file: null,
};

const submissionUploadSchema = Yup.object().shape({
  community_id: Yup.string().default("Select...").nullable(),
  division_id: Yup.string().default("Select...").nullable(),
  webform_type: Yup.string().required("Required field")
    .default("Select...")
    .nullable(),
});

/**
 * This form is used to upload an excel file containing web form data. Its a pretty basic form containing two select fields for webform type and community selection as
 * well as a dropzone where a user can either drag and drop a file or click the add file button to open a window's explorer and choose a file from there.
 * All fields are required, without filling them form cannot be submitted. The upload button will only become clickable when the user has uploaded a valid excel file.
 * On submission the form is checked and appropriate mutation is called to upload the file to the database.
 * @param {Object} props
 * @param {Object} props.values object containing activities info to be edited
 */
const SubmissionUploadForm = (props) => {
  const [fileToUpload, setFileToUpload] = useState({
    file: null,
    errors: null,
  });

  useEffect(() => {
    const { values } = props;
    if (!values.file) {
      setFileToUpload({ file: null, errors: null });
    }
  }, [props.values]);

  /**
   * Callback function called whenever a file is added to the drop zone component.
   * In case the file is accepted, the file is added to the form and upload button becomes clickable.
   * If the file is rejected the error message is shown and the user will have to re-upload the file.
   * @param {Array} acceptedFiles An array of accepted files by dropzone component.
   * @param {Array} fileRejections An array of rejected files that do not meet the requirements set in dropzone.
   */
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (acceptedFiles.length) {
      const acceptedFile = acceptedFiles[0];
      setFileToUpload({ file: acceptedFile, errors: null });
      props.setFieldValue("file", acceptedFile);
    }

    if (fileRejections.length) {
      setFileToUpload({ file: null, errors: fileRejections[0].errors });
      props.setFieldValue("file", null);
    }
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
    maxSize: 10000000,
    accept: ".csv, text/csv, .xlsx",
  });

  /**
   * A function that returns custom error message based on the error code received
   * @param {String} code The error code
   * @param {String} message Default message received by the dropzone component
   * @returns
   */
  const getErrorText = ({ code, message }) => {
    if (code === "text-to-large") {
      return "Exceeded size limit. Files under 10 MB can be uploaded.";
    }
    if (code === "file-invalid-type") {
      return "Wrong file added. Only Excel files are allowed.";
    }
    return message;
  };

  return (
    <Form className={"form-horizontal"} noValidate>
      <div className="form-row">
        <div className="col-md-4">
          <Field
            label="Division"
            name="division_id"
            id="division_id"
            component={SelectField}
          >
            <option value="">Select...</option>
            {isNotNULL(props.allDivisions?.getAllDivisions)
              ? props.allDivisions.getAllDivisions.map(
                (division, index) => (
                  <option key={index} value={division.id}>
                    {division.name}
                  </option>
                )
              )
              : null
            }
          </Field>
        </div>
        <div className="col-md-4">
          <Field
            label="Community"
            name="community_id"
            id="community_id"
            component={SelectField}
          >
            <option value="">Select...</option>
            {isNotNULL(props.allCommunities?.getAllCommunities)
              ? props.allCommunities.getAllCommunities.map(
                (community, index) => (
                  <option key={index} value={community.id}>
                    {community.name}
                  </option>
                )
              )
              : null
            }
          </Field>
        </div>
      </div>
      <div className="form-row align-items-end">
        <div className="col-md-4">
          <Field
            label="Webform Types"
            name="webform_type"
            id="webform_type"
            component={SelectField}
            required
          >
            <option value="">Select...</option>
            {isNotNULL(props.allWebformTypes?.getAllWebFormTypes)
              ? props.allWebformTypes.getAllWebFormTypes.map(
                (webform, index) => (
                  <option key={index} value={webform.name}>
                    {webform.name}
                  </option>
                )
              )
              : null
            }
          </Field>
        </div>
        <div className="col-md-4 flex-end">
          <CustomButton
            className="sample-btn"
            btnValue="SAMPLE FILE"
            onClick={() => window.open('https://sb-pulse.s3.amazonaws.com/Submissions+Sample.xlsx')}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-8">
          <div className="dropzone-container">
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <div className="content">
                <img
                  src={
                    fileToUpload.file
                      ? Accepted
                      : fileToUpload.errors
                        ? NotAccepted
                        : UploadFile
                  }
                  alt=""
                  className="icon"
                />
                {fileToUpload.file ? null : (
                  <p className="title">
                    {fileToUpload.errors
                      ? `${getErrorText(fileToUpload.errors[0])}`
                      : "Drag and drop the file here OR click add file"}
                  </p>
                )}
                {fileToUpload.file ? (
                  <div className="d-flex selected-content">
                    <div className="d-flex selected-title">
                      <img src={ExportExcel} alt="" />
                      <div className="file-name">{fileToUpload.file.path}</div>
                    </div>
                    <MdClose
                      onClick={() => setFileToUpload({ file: null, errors: null })}
                    />
                  </div>
                ) : (
                  <CustomButton
                    color="black"
                    onClick={open}
                    btnValue="ADD FILE"
                    className="add-btn"
                  />
                )}
                {fileToUpload.errors || !fileToUpload.file ? (
                  <p className="sub"> (up to 10MB) </p>
                ) : (
                  <p className="title" style={{ marginTop: "10px" }}>
                    File has been added and ready for upload.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="action-container">
        <CustomButton
          className="add-btn"
          disabled={!fileToUpload.file}
          btnValue="UPLOAD FILE"
          type="submit"
        />
      </div>
    </Form>
  );
};

const SubmissionUploadView = withFormik({
  mapPropsToValues: () => ({ ...uploadFormDefValues }),
  handleSubmit: (values, { props, setSubmitting, resetForm }) => {
    const { file, division_id, community_id, webform_type } = values;

    props.uploadWebForms({
      variables: {
        file,
        division_id,
        community_id,
        web_form_type: webform_type
      }
    }).then((res) => {
      setSubmitting(false);
      resetForm();
      if (res.data.uploadWebForms.code === 200) {
        props.setNotificationProps({
          variables: { open: true, message: res.data.uploadWebForms.message },
        });
      }
    });
  },
  validationSchema: submissionUploadSchema,
  displayName: "new-activity-form",
})(SubmissionUploadForm);

export default compose(
  graphql(GET_COMMUNITIES, { name: "allCommunities" }),
  graphql(GET_DIVISIONS, { name: "allDivisions" }),
  graphql(GET_ALL_WEBFORM_TYPES, { name: "allWebformTypes" }),
  graphql(UPLOAD_WEBFORM, { name: "uploadWebForms" }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" })
)(SubmissionUploadView);
