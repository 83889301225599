import * as Yup from "yup";

export const agencyFilterForm = {
    state_id: null
};

export const agencyFilterSchema = Yup.object().shape({
  state_id: Yup.string()
    .default("Select...")
    .nullable()
});
