import gql from 'graphql-tag';

const SET_AUTH_STATUS = gql`
  mutation setAuthStatus($status: String!){
    setAuthStatus(status: $status) @client{
      status
    }
  }
`;


export default SET_AUTH_STATUS;