import React, { useState, useEffect } from "react";
import { Card, Col } from "react-bootstrap";

import HistoryButton from "../../common-lead-prospect-details/history-button";
import SubscriptionHistoryContainer from "../../common-lead-prospect-details/subscription-history-button"
import ListPopup from "../../../components/list-popup/list-popup";
import DetailsSectionTable from "../../../components/table/custom-details-table";
import EditMarketingOnlyView from "../dashboard/edit-marketing-only-view";
import { checkDisabledEmailsLink, phoneLink, inActiveMapper, handleSecondaryEmails, getAllEmails } from "../../../utils/helpers";

import "./marketing-only-details.css";

/**
 * This component is used to render card details section on he right side of the page. It divides the details card into
 * four sections. Buyer 1, buyer 2, general, and other optional fields. It also contains 3 buttons to view history
 * and subscription history of the prospect and also the edit button. 
 * @param {Object} props 
 * @param {Object} props.data marketing only customer info 
 */
const MarketingOnlyDetail = ({ ...props }) => {
  const [showBuyer2, setShowBuyer2] = useState(true);
  const [buyer1, setBuyer1] = useState([]);
  const [buyer2, setBuyer2] = useState([]);
  const [optional, setOptional] = useState([]);

  const handleLists = (data, type) => {
    return (
      <ListPopup
        data={data[type]}
        mapper={inActiveMapper}
      />
    );
  };

  useEffect(() => {
    if (props.data) {
      setBuyer1({
        "First Name": props.data.first_name_1,
        "Last Name": props.data.last_name_1,
        "Primary Email": checkDisabledEmailsLink(props.data, "primary_email_1", getAllEmails, "primary_email_1_status"),
        "Secondary Email(s)": props.data.secondary_emails_1.length > 0 ? handleSecondaryEmails(
          props.data,
          "secondary_emails_1",
          getAllEmails
        ) : null,
        "Cell#": phoneLink(props.data, "cell_phone_1")
      });
      setBuyer2({
        "First Name": props.data.first_name_2,
        "Last Name": props.data.last_name_2,
        "Primary Email": checkDisabledEmailsLink(props.data, "primary_email_2", getAllEmails, "primary_email_2_status"),
        "Secondary Email(s)": props.data.secondary_emails_2.length > 0 ? handleSecondaryEmails(
          props.data,
          "secondary_emails_2",
          getAllEmails
        ) : null,
        "Cell#": phoneLink(props.data, "cell_phone_2")
      });
      setOptional({
        "Marketing Only Notes": props.data.other_notes,
        "Address": props.data.street_address,
        "City": props.data.city,
        "State": props.data.state ? props.data.state.name : null,
        "Zip": props.data.zip,
        "Community(ies)": props.data.communities.length > 0 ? handleLists(props.data, 'communities') : null,
        "Division": props.data.division && props.data.division.name,
        "OSC Notes": props.data.osc_notes,
        "Marketing Only Source": props.data.customer_origination_area
          ? props.data.customer_origination_area.name
          : null
      });
    }
    if (
      props.data &&
      (props.data.first_name_2 ||
        props.data.last_name_2 ||
        props.data.primary_email_2 ||
        props.data.secondary_emails_2.length > 0 ||
        props.data.cell_phone_2)
    ) {
      setShowBuyer2(true);
    } else {
      setShowBuyer2(false);
    }
  }, [props.data]);

  return (
    <Card className="details-card pt-2" as={Col}>
      <Card.Body style={{ padding: "0.5rem", overflow: "hidden" }}>
        <div className="d-flex justify-content-between">
          <h3>Marketing Only Details</h3>
          <div className="d-flex justify-content-end">
            <SubscriptionHistoryContainer
              name={props.data ? `${props.data.first_name_1} ${props.data.last_name_1}` : ""}
              customer_id={props.data ? props.data.customer_id : null}
            />
            <HistoryButton
              name={props.data ? `${props.data.first_name_1} ${props.data.last_name_1}` : ""}
              id={props.data ? props.data.customer_id : null}
              primaryEmail1={props.data ? props.data.primary_email_1 : null}
              primaryEmail2={props.data ? props.data.primary_email_2 : null}
            />
            {props.data && (
              <EditMarketingOnlyView
                marketingOnlyData={props.data}
                textButton
                btnValue="Edit"
                update={message => props.onSubmit(message)}
                history={props.history}
                isDetails
              />
            )}
          </div>
        </div>
        <div className="details-side-responsive">
          <div className="details-side-section1">
            <DetailsSectionTable data={buyer1} tableHeader="Buyer #1" />
          </div>
          {showBuyer2 && <div className="details-side-section2">
            <DetailsSectionTable data={buyer2} tableHeader="Buyer #2" />
          </div>}
          <div className="details-side-section3">
            <DetailsSectionTable
              data={optional}
              tableHeader="Optional Fields"
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default MarketingOnlyDetail