import React from "react";
import { FaHome } from "react-icons/fa";
import CustomButton from "../../components/custom-button";
import "./not-found.css";

/**
 * This component renders 404 page. It has a button to go abck to the home page.
 * @param {Object} props 
 */
const NotFound = props => {
  const handBackToDashboard = () => props.history.push("/");

  const backToDashboardButton = (
    <CustomButton
      onClick={handBackToDashboard}
      size="sm"
      btnValue="BACK TO DASHBOARD"
      btnIcon={<FaHome />}
    />
  );

  return (
    <div className="not_found">
      <h1 className="code_status">404</h1>
      <h5 className="page">Page not Found</h5>
      <p className="msg">
        The page you are looking for has been removed or relocated
      </p>
      <div className="dash_btn">{backToDashboardButton}</div>
    </div>
  );
};
export default NotFound;
