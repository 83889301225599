import { graphql, compose } from "react-apollo";
import {
  GET_CUSTOMER_LOG,
  GET_ARCHIVED_USERS_BYID
} from "../../graphql/queries";
import ArchivedDetails from "./archive-details";

const ArchivedDetailsView = compose(
  graphql(GET_CUSTOMER_LOG, {
    options: props => ({
      variables: { customer_id: parseInt(props.match.params.id) }
    })
  }),
  graphql(GET_ARCHIVED_USERS_BYID, {name :"ArchivedUser",
  options: props => ({
    variables: { input:{customer_id: parseInt(props.match.params.id)} }
  })})
)(ArchivedDetails);

export default ArchivedDetailsView;
