import React, { useEffect, useState } from "react";
import { Card, Col } from "react-bootstrap";
import CustomButton from "../../../components/custom-button";
import DetailsSectionTable from "../../../components/table/custom-details-table";
import ListPopup from "../../../components/list-popup/list-popup";
import Drawer from "../../../hoc/Drawer";
import { CreateEditRealtor } from "../realtor-form-container";
import { isNotNULL, checkDisabledEmailsLink, phoneLink, inActiveMapper, handleSecondaryEmails } from "../../../utils/helpers";
import { getEmails } from "../realtors-table";
import SubscriptionHistoryContainer from "../../common-lead-prospect-details/subscription-history-button"


/**
 * This component is used to render all realtors, YOY sales and YOY registration cards details section on the right side of the page. It divides the details card into
 * 2 sections. Basic information field and list agent field. It also contains 2 buttons to view subscription history of the realtor and also the edit button. 
 * @param {Object} props 
 * @param {Object} props.data realtor info 
 */
const RealtorDetail = props => {
  const [details, setDetails] = useState({});
  const [showSideBar, setShowSideBar] = useState(false);

  const handleCommunities = data => {
    return (
      <ListPopup
        data={data}
        mapper={inActiveMapper}
      />
    );
  };

  useEffect(() => {
    if (props.data) {
      setDetails({
        basic: {
          "First Name": props.data.first_name,
          "Last Name": props.data.last_name,
          "Primary Email": checkDisabledEmailsLink(props.data, "primary_email", getEmails, "primary_email_status"),
          "Secondary Email(s)": isNotNULL(props.data.Realtor_Other_Emails)
            ? handleSecondaryEmails(props.data, 'Realtor_Other_Emails', getEmails, 'email_address')
            : null,
          "Cell Number": phoneLink(props.data, "cell_phone"),
          "Division": props.data.division && props.data.division.name,
          "Agency Name": props.data.Agency?.name,
          "Agency Address": props.data.Agency?.address,
          "Is Listing Agent?": props.data.is_listing_agent_of_sb ? "Yes" : "No"
        },
        listingAgent: {
          Communities: props.data.Communities && props.data.Communities.length
            ? handleCommunities(props.data.Communities)
            : '-'
        }
      });
    }
  }, [props.data]);

  return (
    <Card className="details-card pt-2" as={Col}>
      <Card.Body style={{ padding: "0.5rem", overflow: "hidden" }}>
        <div className="d-flex justify-content-between">
          <h3>Realtor Details</h3>
          <div className="edit-button">
            <SubscriptionHistoryContainer
              name={
                props.data
                  ? props.data.first_name + " " + props.data.last_name
                  : ""
              }
              realtor_id={props.data ? props.data.id : null}
            />
            <CustomButton
              size="sm"
              color="green"
              btnValue="Edit"
              onClick={() => setShowSideBar(true)}
            />
          </div>
          <Drawer
            show={showSideBar}
            toggleSideBar={() => setShowSideBar(false)}
          >
            <CreateEditRealtor
              realtor={props.data}
              onSubmit={() => {
                setShowSideBar(false);
                props.refetch();
              }}
              close={() => setShowSideBar(false)}
            />
          </Drawer>
        </div>

        <div className="details-side-responsive">
          <div className="details-side-section1">
            <DetailsSectionTable data={details.basic ? details.basic : {}} />
          </div>
          <div className="details-side-section2">
            <DetailsSectionTable
              data={details.listingAgent ? details.listingAgent : {}}
              tableHeader="Listing Agent"
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default RealtorDetail;
