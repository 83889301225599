import gql from "graphql-tag";

const GET_PREVIOUS_CARD = gql`
  query {
    previousCard @client {
      title
    }
  }
`;

export default GET_PREVIOUS_CARD;
