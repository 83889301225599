import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import GlobalSearchDetails from "./global-search-detail";

/**
 * This component renders components of the global search bar on the top navigation. The input is clickable, which on click will open the global search details
 * @returns {JSX.Element} It returns jsx containing the global search bar
 */
const GlobalSearchBar = () => {
  const [showSideBar, setShowSideBar] = useState(false);

  return (
    <li className="form-group has-search search-bar search-global">
      <FaSearch className="icon-search" />
      <input
        type="text"
        className="form-control"
        id="global-search-bar"
        placeholder="Search"
        onClick={() => {
          setShowSideBar(true);
        }}
      />
      {showSideBar && (
        <GlobalSearchDetails
          showSideBar={showSideBar}
          toggleShowDrawer={() => setShowSideBar(false)}
        />
      )}
    </li>
  );
};

export default GlobalSearchBar;
