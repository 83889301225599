import * as Yup from "yup";
import * as errorMessages from "../../../utils/form-errors";

export const leadToMarketingOnlyForm = {
  division_id: undefined,
  communities: []
};

export const leadToMarketingOnlySchema = Yup.object().shape({
  division_id: Yup.string()
    .required(errorMessages.REQUIRE_FIELD)
    .nullable(),
  communities: Yup.array()
    .default("Select...")
    .nullable()
});

export const leadToProspectForm = {
  input: [
    {
      community_id: undefined,
      csm_id: undefined
    }
  ],
};

export const leadToProspectSchema = Yup.object().shape({
  input: Yup.array().of(
    Yup.object().shape({
      community_id: Yup.string()
        .required(errorMessages.REQUIRE_FIELD)
        .nullable(),
      csm_id: Yup.string()
        .default("Select...")
        .nullable()
    })
  ),
});
