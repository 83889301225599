import React, { useState, useEffect } from "react";
import { useQuery } from "react-apollo-hooks";
import { graphql } from "react-apollo";
import { ButtonToolbar } from "react-bootstrap";
import { FaPen, FaTrashAlt } from "react-icons/fa";
import { compose } from "recompose";

import Dialog from "../../components/dialog/dialog";
import CustomButton from "../../components/custom-button";
import LeadCallButton from "../lead/details/lead-call-button";
import ProspectCallButton from "../prospect/details/prospect-call-button";
import HomeownerCallButton from "../homeowner/details/homeowner-call-button";

import {
  get5WordsForTitle,
  formatActivityDate,
  getIconByEvent,
  formatCallDurationsWithSeconds
} from "../../utils/helpers";
import { LEAD, PROSPECT } from "../../utils/constants"

import { FETCH_CALL_RECORDING } from "../../graphql/queries";
import {
  DELETE_PROSPECT_PHONE_CALL,
  DELETE_LEAD_PHONE_CALL,
  DELETE_HOMEOWNER_PHONE_CALL
} from "../../graphql/mutations";

/**
 * @module PastActivityPhoneCallsListViewModule
 */
/**
 * this component is used to display the phone calls in the past activities
 * @param {object} pastActivitiesData contains information related to the phone call
 * @param {string} type to check the type of the customer if its lead or prospect or homeowner
 * @param {function} onSubmit
 * @param {function} deleteProspectPhoneCall to delete prospect type customer phone calls
 * @param {function} deleteLeadPhoneCall to delete lead type customer phone calls
 * @param {function} deleteHomeownerPhoneCall to delete homeowner type customer phone calls
 * @param {mut}
 *
 */
const PastActivityPhoneCallsListView = (props) => {
  const {
    pastActivitiesData,
    deleteProspectPhoneCall,
    deleteLeadPhoneCall,
    deleteHomeownerPhoneCall,
    onSubmit,
    type,
  } = props;

  const [showConfirmationDialog, setshowConfirmationDialog] = useState(false);
  const [deleteId, setdeleteId] = useState();
  const [recordingUrl, setRecordingUrl] = useState("");

  const { refetch: getPhoneCallRecording } = useQuery(FETCH_CALL_RECORDING, { skip: true });

  /**
   * this function displays the delete confirmation dialog
   * @function
   * @inner
   * @memberof module:PastActivityPhoneCallsListViewModule
   * @param {number} phone_call_id phone call id
   */
  const deleteHandler = (item) => {
    setshowConfirmationDialog(true);
    setdeleteId(item.phone_call_id);
  };

  /**
   * this function hides the delete confirmation dialog
   * @function
   * @inner
   * @memberof module:PastActivityPhoneCallsListViewModule
   */

  const cancelDelete = () => {
    setshowConfirmationDialog(false);
    setdeleteId();
  };

  /**
   * this function is used to fetch phone recording from backend and open it in media player
   * @method
   */
  const handleFetchRecording = () => {
    getPhoneCallRecording({
      phone_call_id: pastActivitiesData.phone_call_id,
    }).then((res) => {
      if (res.data.getPhoneCallRecording.code === 200) {
        setRecordingUrl(res.data.getPhoneCallRecording.message);
      }
    });
  };

  /**
   * this function handle the delete confirmation and delete the phone call
   * @function
   * @inner
   * @memberof module:PastActivityPhoneCallsListViewModule
   */
  const handleDelete = (item) => {
    if (type === PROSPECT) {
      deleteProspectPhoneCall({
        variables: {
          input: /* { prospect_id: pastActivitiesData.id, phone_call_id:*/ deleteId /* } */,
        },
      }).then((res) => {
        if (res.data.deleteProspectPhoneCall.code === 200) {
          onSubmit(res.data.deleteProspectPhoneCall.message);
          cancelDelete();
        }
      });
    } else if (type === LEAD) {
      deleteLeadPhoneCall({
        variables: {
          input: deleteId /* { lead_id: pastActivitiesData.id, phone_call_id: deleteId } */,
        },
      }).then((res) => {
        if (res.data.deleteLeadPhoneCall.code === 200) {
          onSubmit(res.data.deleteLeadPhoneCall.message);
          cancelDelete();
        }
      });
    } else {
      deleteHomeownerPhoneCall({
        variables: {
          input: deleteId /* { lead_id: pastActivitiesData.id, phone_call_id: deleteId } */,
        },
      }).then((res) => {
        if (res.data.deleteHomeownerPhoneCall.code === 200) {
          onSubmit(res.data.deleteHomeownerPhoneCall.message);
          cancelDelete();
        }
      });
    }
  };

  /**
   * this function renders the toggle part of the phone call in past activities
   * @function
   * @inner
   * @memberof module:PastActivityPhoneCallsListViewModule
   * @param {object} item contains information related to the phone call
   * @returns {JSX.Element}
   */
  const toggle = (item) => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <div
            className="d-flex align-items-center"
            style={{ marginRight: "2px" }}
          >
            {getIconByEvent(item.typeTitle)}
            <span style={{ fontSize: "12px", display: "d-flex" }}>
              {item.typeTitle}:
            </span>
          </div>
          {get5WordsForTitle(item.call_details)}
        </div>
        <div className="d-flex align-items-center">
          <div className="toggle-date-time">
            {formatActivityDate(item.call_datetime)}
          </div>
          <ButtonToolbar>
            {type === PROSPECT ? (
              <ProspectCallButton
                size="sm"
                color="dark"
                showButton
                btnIcon={<FaPen size={14} />}
                id={pastActivitiesData.id}
                item={item}
                function={"UPDATE"}
                onSubmit={onSubmit}
                className="icon-btn btn-icon"
              />
            ) : type === LEAD ? (
              <LeadCallButton
                size="sm"
                color="dark"
                showButton
                btnIcon={<FaPen size={14} />}
                id={pastActivitiesData.id}
                item={item}
                function={"UPDATE"}
                onSubmit={onSubmit}
                className="icon-btn btn-icon"
              />
            ) : (
              <HomeownerCallButton
                size="sm"
                color="dark"
                showButton
                btnIcon={<FaPen size={14} />}
                id={pastActivitiesData.id}
                item={item}
                function={"UPDATE"}
                onSubmit={onSubmit}
                className="icon-btn btn-icon"
              />
            )}
            <CustomButton
              size="sm"
              color="dark"
              btnIcon={<FaTrashAlt size={14} />}
              onClick={() => deleteHandler(item)}
              className="icon-btn btn-icon"
            />
          </ButtonToolbar>
        </div>
      </div>
    );
  };

  /**
   * this function renders the collapse part of the phone call in past activities
   * @function
   * @inner
   * @memberof module:PastActivityWebformListViewModule
   * @param {object} item contains information related to the phone call
   * @returns {JSX.Element}
   */
  const collapse = (item) => {
    return (
      <div className="accordion-body-inner">
        <label>SENT BY:</label>{" "}
        <span>
          {item.creator &&
            item.creator.first_name + " " + item.creator.last_name}
        </span>
        <hr className="light mt-0" />
        <p className="m-0">{item.call_details}</p>
        <span className="caption">
          {item.call_duration > 0 ?
            formatCallDurationsWithSeconds(item.call_duration)
            : "No Answer"}
        </span>
        {item.recording && (
          <p className="mt-2">
            <audio controls src={recordingUrl}>
              Your browser does not support the
              <code>audio</code> element.
            </audio>
          </p>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (pastActivitiesData?.recording && props.isExpanded) {
      handleFetchRecording()
    }
  }, [props.isExpanded])

  return (
    <div className="notes-card">
      <Dialog
        show={showConfirmationDialog}
        onHide={cancelDelete}
        title="Delete Confirmation"
        body="Are you sure you want to delete?"
        click={handleDelete}
        clickname="YES"
        closename="NO"
      />
      {props.render(toggle(pastActivitiesData), collapse(pastActivitiesData))}
    </div>
  );
};
export default compose(
  graphql(DELETE_PROSPECT_PHONE_CALL, { name: "deleteProspectPhoneCall" }),
  graphql(DELETE_LEAD_PHONE_CALL, { name: "deleteLeadPhoneCall" }),
  graphql(DELETE_HOMEOWNER_PHONE_CALL, { name: "deleteHomeownerPhoneCall" })
)(PastActivityPhoneCallsListView);
