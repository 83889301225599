import * as Yup from "yup";
import * as errorMessages from "./form-errors";
import { REALTOR } from "../utils/constants"

export const prospectListFilter = {
  rank: { id: "" },
  floor_plan: { id: "" },
  home_site: { id: "" },
  others: { id: "" },
  state: { id: "" },
  reservation: false,
  video_text: false,
  red_flag: false,
  realtor_id: "",
  advanceFilters: false,
  filter_name: "",
  created_at: [null, null],
  updated_at: [null, null],
};

export const prospectListFilterSchema = Yup.object().shape({
  rank: Yup.object().shape({
    id: Yup.array()
  }),
  floor_plan: Yup.object().shape({
    id: Yup.string()
      .default("Floorplan")
      .nullable()
  }),
  home_site: Yup.object().shape({
    id: Yup.string()
      .default("Homesite")
      .nullable()
  }),
  others: Yup.object().shape({
    id: Yup.string()
      .default("Custom Filter")
      .nullable()
  }),
  state: Yup.object().shape({
    id: Yup.string()
      .default("State")
      .nullable()
  }),
  realtor_id: Yup.string()
    .default(REALTOR)
    .nullable(),
  filter_name: Yup.string()
    .trim()
    .required(errorMessages.REQUIRE_FIELD)
});
