import gql from 'graphql-tag';

const UPDATE_REALTOR = gql`
  mutation updateRealtor($id: ID!, $input: RealtorInput!) {
    updateRealtor(id: $id, input: $input) {
      message
      code
    }
  }
`;

export default UPDATE_REALTOR;