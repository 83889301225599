import React from "react";
import * as actionTypes from "./action-types";

/**
 * this custom hook that handles asc desc sorter state on table columns.
 * @param {object} state 
 * @param {function} dispatch 
 * @returns {object}
 */
export default function useSorter(state, dispatch) {
  const { rows, sortColumn, rowsLength, manualSorting } = state;
 
  const handleSort = (sort, id, name, comparator) => {
    dispatch({
      type: actionTypes.SET_SORT_COL,
      payload: { sortColumn:{id, name, comparator, sort }}
    });
  };

  const sortRows = (sortColumn, rows) => {
    let sortedRows = rows;
    const id = sortColumn.id;

    if (id === -1 || manualSorting) {
      return;
    }

    sortedRows = [...sortedRows.sort(sortColumn.comparator(sortColumn.name))];
    if (sortColumn.sort === "desc") {
      sortedRows = sortedRows.reverse();
    }
    dispatch({ type: actionTypes.SORT_DATA, payload: { rows: sortedRows } });
    
  };


  React.useMemo(() => sortRows(sortColumn, rows), [
    sortColumn.id, sortColumn.sort,
    rowsLength
  ]);

  const getColumnProperties = id => {
    return id === sortColumn.id ? sortColumn.sort : "";
  };
  return {
    sortColumn,
    handleSort,
    getColumnProperties
  };
}
