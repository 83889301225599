import React, { useEffect, useState } from "react";
import { compose, graphql } from "react-apollo";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaBan } from "react-icons/fa";

import { isNotNULL } from "../../../utils/helpers";
import { GET_AGENCY_SALES_DETAILS } from "../../../graphql/queries/agency/agency-sales-by-community";
import CustomButton from "../../../components/custom-button/index";
import CustomTable from "../../../components/table/custom-table";

/**
 * This component is used to render content inside the side drawer of {@link module:AgencySalesTableModule~AgencySalesTable}
 * It renders a table showing individual agency sales of community passed in the props. One column shows agency names
 * and one column shows number of sales. Title of drawer shows community name followed by total number of sales in 
 * that community. 
 * 
 * @param {Object} props 
 * @param {Object} props.community community for which details are to be shown
 * @param {Number} props.agency_sales_count total sales count of this community
 * @param {Function} props.close function to close the drawer
 */
const AgencySalesDetail = ({ data, close, ...props }) => {
  const [saleDetails, setSaleDetails] = useState(null);

  useEffect(() => {
    if (data.getAgencies && data.getAgencies.agencies) {
      setSaleDetails(data.getAgencies.agencies);
    }
  }, [data]);

  const communityNameResolver = (community) => `${community.name}`;
  const titleResolver = (agency) => `${agency.name}`;
  const saleResolver = (agency) => `${agency.sales_count}`;

  return (
    <div className="form-horizontal">
      <div className="form_TitleContainer">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ margin: "0 1.1rem" }}
        >
          <div>
            <div className="form_Title">Community</div>
            <div className="form_TitleBig">
              <div className="d-flex align-items-center">
                {props.community.is_active === false ? (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip style={{ zIndex: 2022 }}>
                        {"Inactive Community"}
                      </Tooltip>
                    }
                  >
                    <FaBan size={18} className="td-warning" />
                  </OverlayTrigger>
                ) : null}

                <h5
                  style={{
                    position: "relative",
                    paddingRight: "0.5em",
                    marginTop: "9px",
                  }}
                >
                  {`${communityNameResolver(props.community)} (${props.agency_sales_count
                    })`}
                </h5>
              </div>
            </div>
          </div>
          <CustomButton
            color="black"
            onClick={() => close()}
            btnValue="CLOSE"
          />
        </div>
      </div>
      <div className="form_body">
        <h2>Individual Agency Sales</h2>
        <CustomTable
          tableData={getData(saleDetails, titleResolver, saleResolver)}
          tableHead={["Agency Name", "No. of Sales", ""]}
        />
      </div>
    </div>
  );
};

/**
 * This function is used inside {@link AgencySalesDetail} component to create table content
 * @param {Array} value containing sale details  
 * @param {Function} titleResolver string resolver for agency name column 
 * @param {Function} saleResolver string resolver for sale count column 
 */
function getData(value, titleResolver, saleResolver) {
  let data = [];

  let items = value;
  if (isNotNULL(items)) {
    items.map((item, key) => {
      return data.push([
        <div
          style={{
            maxWidth: "180px",
            display: "block",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span>{titleResolver(item)}</span>
        </div>,
        <span>{saleResolver(item)}</span>,
        <span></span>,
      ]);
    });
  }

  return data;
}

export default compose(
  graphql(GET_AGENCY_SALES_DETAILS, {
    options: (props) => {
      return {
        variables: {
          filter: {
            community_id: props.community.id,
          },
          mode: "YOY_SALES"
        },
        fetchPolicy: "cache-and-network",
      };
    },
  })
)(AgencySalesDetail);
