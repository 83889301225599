import React from "react";
import { graphql, compose } from "react-apollo";
import { Form, Field, withFormik } from "formik";

import { GET_ALL_STATES } from "../../../graphql/queries";
import {
  isNotNULL,
  sanitizeFilterObject,
  isEmpty,
} from "../../../utils/helpers";
import { agencyFilterSchema, agencyFilterForm } from "./agencies-filter-schema";
import SelectField from "../../../components/fields/select-field";
import CustomButton from "../../../components/custom-button";

/**
 * This component is used to render agencies filter form, which has only one field. It contains a select field to 
 * filter on the basis of status. Apply filter button remains disabled unless an item is selected inside the form. 
 * On submit setFilter from the table is called to set the filter inside table state, which is turn triggers the table
 * data refetch.  
 * 
 * @param {Object} props contains formikbag, and container queries
 */
const AgencyFilterForm = (props) => {
  const { values } = props;
  const enableFormSubmit = ({ filter_name: _, ...valueCompareFrom }) => {
    const filters = sanitizeFilterObject(Object.assign({}, valueCompareFrom));
    return isEmpty(filters);
  };

  return (
    <Form className="filter-menu">
      <div className="form-row">
        <div className="col-md-12">
          <Field
            label="State"
            name="state_id"
            id="state_id"
            propsStyle={{ width: "200px" }}
            classStyle="filter-select-field"
            component={SelectField}
          >
            <option value="">Select...</option>
            {props.getAllStates.getAllStates &&
              props.getAllStates.getAllStates.map((state, index) => (
                <option key={index} value={state.id}>
                  {state.name}
                </option>
              ))}
          </Field>
        </div>
      </div>
      <CustomButton
        type="submit"
        btnValue="APPLY FILTERS"
        className="filter-apply-btn"
        disabled={enableFormSubmit(values)}
      />
    </Form>
  );
};

const AgencyFilterFormWithFormik = withFormik({
  mapPropsToValues: (props) => {
    const { filter } = props;
    return isNotNULL(filter) ? filter : agencyFilterForm;
  },
  handleSubmit: (values, { props, setSubmitting }) => {
    const { setFilter, setShow } = props;
    setFilter({ ...values });
    setShow(false);
    setSubmitting(false);
  },
  displayName: "agency-filter-form",
  enableReinitialize: true,
  validationSchema: agencyFilterSchema,
})(AgencyFilterForm);

export default compose(
  graphql(GET_ALL_STATES, { name: "getAllStates" })
)(AgencyFilterFormWithFormik);
