import React from "react";
import { Form, Field, withFormik } from "formik";
import { ButtonToolbar } from "react-bootstrap";

import SelectField from "../../../components/fields/select-field";
import CustomButton from "../../../components/custom-button/index";
import ErrorFocus from "../../../components/error-focus/error-focus";
import MultiSelectField from "../../../components/fields/multi-select-field";
import { isNotNULL } from "../../../utils/helpers";
import { marketingOnlyToLeadForm, marketingOnlyToLeadSchema } from "./marketing-only-convert-schema";


/**
 * This component is used to render marketing only to lead form. It contains couple of select fields.
 * @param {Object} props 
 */
const MarketingOnlyToLeadConvertForm = ({ values, ...props }) => {
  return (
    <Form className={"form-horizontal"}>
      <div className="form_TitleContainer">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ margin: "0 1.1rem" }}
        >
          <div className="pl-1">
            <div className="form_Title">Marketing Only Conversion</div>
            <div className="form_TitleBig">
              <div className="d-flex">
                <h2
                  style={{
                    position: "relative",
                    paddingRight: "0.5em",
                    marginTop: "4px"
                  }}
                >
                  {`${props.marketingOnlyData.first_name_1} ${props.marketingOnlyData.last_name_1}`}
                </h2>
              </div>
            </div>
          </div>

          <div className="d-flex">
            <ButtonToolbar>
              <CustomButton
                color="black"
                className={`form_CancelButton ml-0`}
                onClick={props.close}
                btnValue="CANCEL"
              />
              <CustomButton
                className={`form_SubmitButton`}
                type="submit"
                disabled={props.isSubmitting}
                btnValue={"CONVERT"}
              />
            </ButtonToolbar>
          </div>
        </div>
      </div>

      <div className="form_body">
        <div className="form-row">
          <div className="col-md-6">
            <Field
              label="Division"
              name="division_id"
              id="division_id"
              required={true}
              component={SelectField}
              fieldsToClear={[{ fieldName: 'communities', value: [] }]}
            >
              <option value="">Select...</option>
              {isNotNULL(props.getDivisions?.getAllDivisions)
                ? props.getDivisions?.getAllDivisions.map((div, index) => (
                  <option key={index} value={div.id}>
                    {div.name}
                  </option>
                ))
                : null
              }
            </Field>
          </div>
          <div className="col-md-6">
            <Field
              label="Communities"
              name="communities"
              id="communities"
              disabled={!values.division_id && !values.communities?.length}
              component={MultiSelectField}
              children={props.getCommunities?.getAllCommunities?.filter(comm =>
                comm.is_active && comm.division_id === values.division_id
              )}
              defaultValue={values.communities || null}
            />
          </div>
        </div>
      </div>
      <ErrorFocus />
    </Form>
  )
}

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: () => { return { ...marketingOnlyToLeadForm } },
  handleSubmit: (values, { props, setSubmitting }) => {
    props.convertMarketingOnlyToLead({
      variables: {
        input: {
          marketing_only_id: props.marketingOnlyId,
          division_id: values.division_id,
          communities: values.communities
        }
      }
    }).then(res => {
      setSubmitting(false);
      if (res.data.convertMarketingOnlyToLead.code === 200) {
        props.onSubmit(res.data.convertMarketingOnlyToLead.message);
      } else if (res.data.convertMarketingOnlyToLead.code === 449) {
        props.setNotificationProps({
          variables: {
            open: true,
            message: res.data.convertMarketingOnlyToLead.message
          }
        })
      }
    });
  },
  validationSchema: marketingOnlyToLeadSchema,
  displayName: "marketing-only-to-lead-form"
})(MarketingOnlyToLeadConvertForm)