import gql from "graphql-tag";

const GET_FORM_SUBMISSIONS = gql`
  query getFormSubmission(
    $input: FormSubmissionFilter
    $limit: Int
    $pageNum: Int
    $sort: [String]
    $search: String
  ) {
    getFormSubmission(
      filter: $input
      limit: $limit
      pageNum: $pageNum
      sort: $sort
      search: $search
    ) {
      total_count
      formSubmission {
        id
        form_submission_id
        firstname
        lastname
        email
        phone
        responded
        created_at
        status {
          id
          name
        }
        customer_communities {
          id
          name
          is_active
        }
        form_type
        community {
          id
          name
          is_active
        }
        division {
          id
          name
        }
        responded_at
        interested_in
        other_data
        responder{
          first_name
          last_name
        }
        converted
      }
    }
  }
`;

export default GET_FORM_SUBMISSIONS;
