import { compose, graphql } from "react-apollo";
import { GET_ALL_STATUSES, GET_FORM_SUBMISSIONS } from "../../graphql/queries";
import GET_SELECTED_COMMUNITY from "../../cacheql/queries/community";
import {
  SET_NOTIFICATION_PROPS,
  SET_TABLE_PARAMS,
  SET_PREVIOUS_CARD,
} from "../../cacheql/mutations";
import { GET_TABLE_PARAMS, GET_SELECTED_DIVISION } from "../../cacheql/queries";
import { getCardParams, getCommunityIds } from "../../utils/helpers";
import WebFormTable from "./webform-table";
import { UPDATE_FORM_SUBMISSION_STATUS } from "../../graphql/mutations";

export default compose(
  graphql(SET_TABLE_PARAMS, { name: "setTableParams" }),
  graphql(GET_TABLE_PARAMS, { name: "getTableParams" }),
  graphql(GET_SELECTED_COMMUNITY, { name: "selectedCommunity" }),
  graphql(GET_SELECTED_DIVISION, { name: "selectedDivision" }),
  graphql(GET_ALL_STATUSES, { name: "getAllStatuses" }),
  graphql(GET_FORM_SUBMISSIONS, {
    options: ({
      selectedDivision,
      selectedCommunity,
      currentCard,
      getTableParams: { tableParams },
      ...props
    }) => {
      let params = getCardParams(currentCard.title, tableParams);
      return {
        variables: {
          input: {
            ...(params && params.filter),
            customer_type_id: currentCard.filter.customer_type_id,
            division_id: selectedDivision.selectedDivision.id,
            community_ids: getCommunityIds(selectedCommunity),
          },
          pageNum: (params && params.pageNum) || 0,
          limit: (params && params.limit) || 10,
          sort:
            params && params.sort && params.sort.id !== -1
              ? [params.sort.name, params.sort.sort]
              : null,
          search: (params && params.search) || "",
        },
        fetchPolicy: "cache-and-network",
      };
    },
  }),
  graphql(UPDATE_FORM_SUBMISSION_STATUS, {
    name: "updateFormSubmissionStatus",
  }),
  graphql(SET_PREVIOUS_CARD, { name: "setPreviousCard" }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" })
)(WebFormTable);
