import React from "react";
import {
  EditorState,
  ContentState,
  CompositeDecorator,
  RichUtils,
  Modifier,
  convertFromHTML as convertFromHTMLClassic
} from "draft-js";
import ReactDOMServer from "react-dom/server";
import { defaults } from "./editor-constants";
import { convertToHTML } from "draft-convert";
import { Media, Link, Image } from "./editor-blocks";
export const RichTextUtils = RichUtils;
export const ModifierTextUtils = Modifier;
export const fromHTML = convertFromHTMLClassic;


/**
 * this function is used to to decorate a editor state
 */
export const decorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link
  },
  {
    strategy: findImageEntities,
    component: Image
  }
]);

/**
 * this function is used in decoration for link blocks type
 * @param {object} contentBlock contains all the information related to the blocks
 * @param {function} callback 
 * @param {object} contentState contains all the information related to the state and the blocks
 */
function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
}

/**
 * this function is used in decoration for image blocks type
 * @param {object} contentBlock contains all the information related to the blocks
 * @param {function} callback 
 * @param {object} contentState contains all the information related to the state and the blocks
 */
function findImageEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === ("IMAGE" || "image")
    );
  }, callback);
}

/**
 * this function is used to specify the component of atomic type blocks
 * @param {object} block contains information of block type 
 * @return {object}
 */
export function getBlockRenderer(block) {
  switch (block.getType()) {
    case "atomic":
      return {
        component: Media,
        editable: true
      };
    default:
      return null;
  }
}

/**
 * this function is used to specify the style of blockquote type blocks
 * @param {object} block contains information of block type 
 * @returns {object}
 */
export function getBlockStyle(block) {
  switch (block.getType()) {
    case "blockquote":
      return "RichEditor-blockquote";
    default:
      return null;
  }
}

/**
 * this function is used to change block type of images to 'atomic'
 * @param {object} contentState contains all the information related to the state and the blocks
 * @returns {object}
 */
export const customContentStateConverterToBlocks = contentState => {
  // changes block type of images to 'atomic'
  const newBlockMap = contentState.getBlockMap().map(block => {
    const entityKey = block.getEntityAt(0);
    if (entityKey !== null) {
      const entityBlock = contentState.getEntity(entityKey);
      const entityType = entityBlock.getType();
      switch (entityType) {
        case "IMAGE": {
          const newBlock = block.merge({
            type: "atomic"
            /* text: "img" */
          });
          return newBlock;
        }
        default:
          return block;
      }
    }
    return block;
  });
  const newContentState = contentState.set("blockMap", newBlockMap);
  return newContentState;
};

/**
 * this function converts blocks to html
 */
export const customHtmlConvertorfromBlocks = () => {
  return {
    blockToHTML: b => {
      if (b.type === "atomic") {
        return {
          start: "<figuretag>",
          end: "</figuretag>",
          empty: " "
        };
      }

      return;
    },
    entityToHTML: (entity, originalText) => {
      if (entity.type === "LINK") {
        return <a href={entity.data["url"]}>{originalText}</a>;
      }

      if (entity.type === "image" || entity.type === "IMAGE") {
        return `<img src="${entity.data.src}" height="${entity.data.height}" width="${entity.data.width}" />`;
      }
      return originalText;
    }
  };
};

/**
 * this function is used to load the editor from html body
 * @param {string} html Html body of the selected section
 * @param {function} callback 
 * @param {object} type type of the selected section
 */
export const loadEditorFromHTML = (html, callback, type) => {
  const parsedHtml = fromHTML(html);
  if (!parsedHtml) {
    return;
  }
  const { contentBlocks, entityMap } = parsedHtml;
  if (!contentBlocks) {
    return;
  }

  if (contentBlocks.length !== 0) {
    const content = customContentStateConverterToBlocks(
      ContentState.createFromBlockArray(contentBlocks, entityMap)
    );
    callback(EditorState.createWithContent(content), type);
  } else callback(EditorState.createEmpty(), type);
};

/**
 * this function is to convert editor to html
 * @param {object} editorState contatins all the information related to the state
 */
export const convertEditorToHTML = editorState => {
  return convertToHTML(customHtmlConvertorfromBlocks())(
    editorState.getCurrentContent()
  );
};

/**
 * this function is used to get the inisital tags of a template
 * @param {array} defaults conatins all initial html elements of a template 
 */
export const getInitialTags = () => {
  return `<td class="section" id="logo-image">
        ${defaults[0]}
      </td>
      <td class="section" id="body-header" cssStyle={{marginLeft:'10px'}}>
        ${defaults[1]}
      </td>
      <td class="section" id="body-image">
        ${defaults[0]}
      </td>
      <td class="section" id="body-text">
      ${defaults[2]}
      </td>
      <td class="section" id="body-link">
      ${defaults[3]}
      </td>`;
};

/**
 * this function is used to replace the div tags with nothing
 * @param {string} content contains the html body of each section
 */
export const replace = content => {
  content = content.replace(/<\/div>/gm, "");
  content = content.replace(/<div>/gm, "");
  return content;
};

/**
 * this function is used the fet the inner html of the content
 * @param {string} content contains the html body of each section
 */
export const getInnerHTML = content => {
  return (
    content && (
      <div
        dangerouslySetInnerHTML={{
          __html: content.replace(/\n/g, "")
        }}
      />
    )
  );
};

/**
 * this function is used to render markup code from string
 * @param {string} content contains the html body of each section
 */
export const renderToStaticMarkup = content => {
  return ReactDOMServer.renderToStaticMarkup(content);
};
