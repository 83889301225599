import gql from 'graphql-tag';

const CONVERT_LEAD_TO_MARKETING_ONLY = gql` 
  mutation convertLeadToMarketingOnly($input: ConvertLeadToMarketingOnlyInput) {
    convertLeadToMarketingOnly(input: $input) {
      message
      code
    }
  }
`;

export default CONVERT_LEAD_TO_MARKETING_ONLY