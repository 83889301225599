import gql from 'graphql-tag';

const SET_USER = gql`
  mutation setUser($role: String!, $name:String!){
    setUser(role: $role, name:$name) @client{
      role
    }
  }
`;


export default SET_USER;