import { graphql, compose } from "react-apollo";
import marketingOnlyToProspectForm from "./marketing-only-to-prospect-form";
import { CONVERT_MARKETING_ONLY_TO_PROSPECT } from "../../../graphql/mutations";
import { GET_COMMUNITIES } from '../../../graphql/queries'
import { SET_NOTIFICATION_PROPS } from "../../../cacheql/mutations";

const MarketingOnlyToProspectMutation = compose(
  graphql(CONVERT_MARKETING_ONLY_TO_PROSPECT, { name: "convertMarketingOnlyToProspect" }),
  graphql(GET_COMMUNITIES, { name: "getCommunities" }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" })
)(marketingOnlyToProspectForm);

export default MarketingOnlyToProspectMutation;