
import gql from 'graphql-tag';

const GET_LEAD_DIVISIONS = gql`
   query{
    getAllDivisions{
        id
        name
    }
   }
   `;
export default GET_LEAD_DIVISIONS;