import gql from 'graphql-tag';

const UPDATE_PROSPECT_RANKING = gql` 
  mutation updateProspectRankings($input: UpdateProspectRankingInput!) {
    updateProspectRankings(input: $input) {
      message
      code
    }
  }
`;

export default UPDATE_PROSPECT_RANKING;