import React from "react";
import PropTypes from "prop-types";
import { ButtonToolbar, OverlayTrigger, Popover } from "react-bootstrap";
import { FaEllipsisH, FaBan } from "react-icons/fa";
import { isNotNULL } from "../../utils/helpers";

import "./list-popup.css";

/**
 * This component is used to show a three dots icon, which opens on click and shows a list of items e.g: communities etc.
 * @param {Object} props 
 * @param {Array} props.data array containing list items
 * @param {Function} props.mapper callback function to render list items 
 * @param {Object} props.community current community, which should be at the top of the list and has "CURRENT" written infront of it
 */
const ListPopup = ({ data, mapper, community }) => {
  let list, listPopover;
  // if community is present, filter it out from the data list and render rest of the items 
  if (community) {
    list = data.filter(item => item.name !== community.name);
    listPopover = mapper(list);
  } else {
  // if community is not passed just remove the first item from the list and render the rest
    list = mapper(data);
    listPopover = list.slice(1, list.length);
  }

  return (
    <div className="list-card">
      {community ? <div className='d-flex'>
          {!community.is_active && <FaBan className='td-warning'/>}
          {community.name}
        </div> : isNotNULL(list[0]) ? list[0] : " "}
      {listPopover.length > 0 ? (
        <ButtonToolbar
          onClick={event => {
            event.stopPropagation();
          }}
        >
          <OverlayTrigger
            trigger="click"
            key="down"
            placement={"bottom"}
            rootClose={true}
            overlay={
              <Popover style={{zIndex: 2022}}id="popover-positioned-down" className="details-list">
                  {/*either show first element from the list or community (if present) and then the rest of the list*/}
                  {(community && 
                    <div className='d-flex align-items-center'>
                      {!community.is_active && <FaBan className='td-warning'/>}
                      {community.name}
                      <span className="caption">(current)</span>
                    </div>) || list[0]
                  }
                {listPopover}
              </Popover>
            }
          >
            <div className="icon-btn btn-icon">
              <FaEllipsisH size={14} style={{ color: "#80B602" }} />
            </div>
          </OverlayTrigger>
        </ButtonToolbar>
      ) : null}
    </div>
  );
};

ListPopup.propTypes = {
  mapper: PropTypes.func
};

export default ListPopup;
