import React, { useEffect, useState } from "react";
import _ from "lodash";
import { MergeRealtorForm } from "../realtor/realtor-form-container"
import RealtorDetailsCard from "./realtor-details-card";
import Dialog from "../../components/dialog/dialog";

/**
 * This component renders dialog box containing cards showing data of the realtors selected to be merged and a form with pre-filled
 * fields from the first two selected realtors. User can edit the form by observing the data in the cards and either click the
 * merge button to submit the form or close the dialog box.
 * @param {Object} props
 * @param {Boolean} props.show show or hide dialog box
 * @param {Function} props.onHide callback function which is called when no is pressed
 * @param {Function} props.onSubmit a callback function that closes all dialog boxes when the form is successfully submitted
 * @param {Array} props.data data with which the form will be pre-filled
 */
const MergeRealtorDialog = ({
  show,
  onHide,
  onSubmit,
  data
}) => {
  const [realtorData, setRealtorData] = useState({})

  useEffect(() => {

    // Sets Realtor Data for the form. Selects the first Realtor as 
    // primary realtor for information 
    if (data && data.length > 1) {
      const realtor = data[0]

      setRealtorData({
        first_name: realtor.first_name,
        last_name: realtor.last_name ?? null,
        primary_email: realtor.primary_email,
        cell_phone: realtor.cell_phone ?? null,
        division: realtor.division,
        is_listing_agent_of_sb: realtor.is_listing_agent_of_sb,
        Communities: realtor.Communities ?? [],
        Agency: realtor.Agency,
        Realtor_Other_Emails: realtor.Realtor_Other_Emails
      })
    }
  }, [data])

  /**
  * A function to obtain the customers ids from customers selected
  * @param {Array} data
  */
  const getRealtorIds = (data) => {
    return data.map((realtor) => {
      return realtor.id;
    });
  };


  return (
    <Dialog
      show={show}
      onHide={onHide}
      title="Merge Realtors"
      dialogClassName="modal-98w"
      body={
        <div style={{ overflow: 'hidden', overflowX: 'auto' }}>
          <div className="dialog-merge" style={{ width: `${(350 * data.length) + 800}px` }}> {/*  increment width proerpty here by adding 350px everytime a new card added. */}
            {data.map((realtor) => {
              return (
                <RealtorDetailsCard
                  realtorData={realtor}
                />
              )
            })}

            <div className="merge-form" style={{ margin: '0 10px' }}>
              <MergeRealtorForm
                realtor={realtorData}
                onMerge
                closeDialog={onHide}
                realtors_ids={getRealtorIds(data)}
                onSubmit={onSubmit}
              />
            </div>
          </div>
        </div>
      }
    />
  )
}

export default MergeRealtorDialog;