import gql from "graphql-tag";

const SELECT_SINGLE_COMMUNTIY = gql`
  mutation selectCommunityId($id: Int) {
    selectCommunityId(id: $id) @client {
      id
    }
  }
`;

export default SELECT_SINGLE_COMMUNTIY;
