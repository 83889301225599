import gql from "graphql-tag";

const GET_EMAIL_TEMPLATE = gql`
  query getTemplate($id: ID!) {
    getEmailTemplate(id: $id) {
      id
      name
      subject
      body
      description
      placeholders{
        name
        description
      }
    }
  }
`;
export default GET_EMAIL_TEMPLATE;
