import * as Yup from "yup";
import * as errorMessages from "../../utils/form-errors";
import * as validations from "../../utils/common-validations";

export const initialValues = {
  id: null,
  first_name: "",
  last_name: "",
  primary_email: "",
  agency_name: "",
  cell_phone: "",
  formatted_cell_phone: "",
  is_listing_agent_of_sb: false,
  Realtor_Other_Emails: [],
  Communities: [],
  division_id: null,
  hidden_Realtor_Other_Emails: ""
};

Yup.addMethod(Yup.mixed, "validateCell", function(ref, message) {
  const msg = message;
  return this.test("equalTo", msg, function(value) {
    let refValue = this.resolve(ref);
    return (
      (refValue && /^[0-9]{10}$/.test(refValue)) ||
      refValue === null ||
      refValue === undefined ||
      refValue === ""
    );
  });
});

Yup.addMethod(Yup.mixed, "stringInArray", function(ref, message) {
  const msg = message;
  return this.test("equalTo", msg, function(value) {
    let refValue = this.resolve(ref);
    return !refValue || !value || refValue.indexOf(value) === -1;
  });
});

export const realtorSchema = Yup.object().shape({
  first_name: validations.name.required(errorMessages.REQUIRE_FIELD),
  last_name: validations.name,
  primary_email: validations.email
    .required(errorMessages.REQUIRE_FIELD)
    .stringInArray(Yup.ref("Realtor_Other_Emails"), errorMessages.EMAIL_MATCHED)
    .compareStrings(
      Yup.ref("hidden_Realtor_Other_Emails"),
      errorMessages.EMAIL_MATCHED
    ),
  Realtor_Other_Emails: Yup.array(),
  hidden_Realtor_Other_Emails: validations.email
  .compareStrings(Yup.ref("primary_email"), errorMessages.EMAIL_MATCHED)
  .stringInArray(Yup.ref("Realtor_Other_Emails"), errorMessages.EMAIL_EXISTS),
  division_id: Yup.string().required(errorMessages.REQUIRE_FIELD)
              .default("Select...")
              .nullable(),
  agency_name: Yup.string()
    .nullable()
    .max(128, errorMessages.AGENCY_NAME_LONG),
  formatted_cell_phone: Yup.string()
    .nullable()
    .validateCell(Yup.ref("cell_phone"), errorMessages.CELL_LENGTH),
  Communities: Yup.array().when("is_listing_agent_of_sb", {
    is: true,
    then: Yup.array().required(errorMessages.REQUIRE_FIELD),
    otherwise: Yup.array().nullable()
  })
});
