import {
  FaListUl,
  FaListOl,
  FaBold,
  FaItalic,
  FaUnderline,
  FaAlignLeft,
  FaAlignCenter,
  FaAlignJustify,
  FaStrikethrough,
  FaImage,
  FaLink,
  FaUnlink
} from "react-icons/fa";
import { MdSpaceBar } from "react-icons/md";

export const styleMap = {
  CODE: {
    backgroundColor: "rgba(255, 165, 0, 0.21)",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    padding: 2
  }
};

export const AlIGNMENT_STYLES = [
  { tooltip: "Left", icon: FaAlignLeft, style: "left" },
  { tooltip: "Center", icon: FaAlignCenter, style: "center" },
  { tooltip: "Right", icon: FaAlignJustify, style: "right" }
];

export const INLINE_STYLES = [
  {
    tooltip: "Bold",
    icon: FaBold,
    style: "BOLD",
    show: ["text", "header"]
  },
  {
    tooltip: "Italic",
    icon: FaItalic,
    style: "ITALIC",
    show: ["text", "header"]
  },
  {
    tooltip: "Underline",
    icon: FaUnderline,
    style: "UNDERLINE",
    show: ["text", "header"]
  },
  {
    tooltip: "Monospace",
    icon: MdSpaceBar,
    style: "CODE",
    show: ["text"]
  },
  {
    tooltip: "Strikethrough",
    icon: FaStrikethrough,
    style: "STRIKETHROUGH",
    show: ["text"]
  }
];

export const BLOCK_TYPES = [
  { label: "Paragraph", style: "paragraph", tag: "p" },
  { label: "Heading 6", style: "header-six", tag: "h6" },
  { label: "Heading 5", style: "header-five", tag: "h5" },
  { label: "Heading 4", style: "header-four", tag: "h4" },
  { label: "Heading 3", style: "header-three", tag: "h3" },
  { label: "Heading 2", style: "header-two", tag: "h2" },
  { label: "Heading 1", style: "header-one", tag: "h1" },
  { label: "Blockquote", style: "blockquote" }
];

export const LIST_TYPES = [
  {
    tooltip: "Bulleted List",
    icon: FaListUl,
    style: "bulleted-list-item",
    show: ["text"]
  },
  {
    tooltip: "Numbered List",
    icon: FaListOl,
    style: "numbered-list-item",
    show: ["text"]
  }
];

export const CUSTOM_TYPES = [
  {
    tooltip: "Image",
    icon: FaImage,
    customType: "image",
    overlay: true,
    show: ["image"]
  },
  {
    tooltip: "Link",
    icon: FaLink,
    customType: "LINK",
    overlay: true,
    show: ["text", "link"]
  },
  {
    tooltip: "Unlink",
    icon: FaUnlink,
    customType: "unlink",
    overlay: false,
    show: ["text", "link"]
  }
];

export const defaults = [
  `<figuretag><img src="${require("../../images/img_upload.png")}" height="undefined" width="undefined"></figuretag>`,
  `<h1>Heading</h1>`,
  `<p>Body is Empty</p>`,
  `<a src="#">CTA</a>`
];

export const editorClassName = "RichEditor-editor"