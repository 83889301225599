import React from "react";
import { graphql, compose } from "react-apollo";
import { Form, Field, withFormik } from "formik";
import { ButtonToolbar } from "react-bootstrap";

import { GET_ALL_STATES } from "../../../graphql/queries";
import { agencyForm, agencySchema } from "./agency-form-schema";
import CustomButton from "../../../components/custom-button/index";
import ErrorFocus from "../../../components/error-focus/error-focus";
import TextField from "../../../components/fields/text-field";
import SelectField from "../../../components/fields/select-field";
import { ADD_AGENCY, UPDATE_AGENCY } from "../../../graphql/mutations";
import { SET_NOTIFICATION_PROPS } from "../../../cacheql/mutations";

/**
 * This form is used to create and update agency information. It shows either "Create New" or agency name at the top
 * depending on its type. Its a pretty basic form containing multiple text fields and a select field. It has only
 * one required field which is agency name, without filling it form cannot be submitted. On submission type of form 
 * is checked and appropriate mutation is called to either create or edit agency. 
 * 
 * @param {Object} props 
 * @param {Object} props.agency object containing agency info to be edited
 */
const AgencyForm = ({agency, ...props}) => {
  return (
    <Form className={"form-horizontal"} noValidate>
      <div className="form_TitleContainer">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ margin: "0 1.1rem" }}
        >
          <div>
            <div className="form_Title">Agency</div>
            <div className="form_TitleBig mt-1">
              <h2>{agency? agency.name:"Create New"}</h2>
            </div>
          </div>
          <div className="d-flex pr-3">
            <ButtonToolbar>
              <CustomButton
                color="black"
                className="form_CancelButton"
                onClick={props.close}
                btnValue="CANCEL"
              />
              <CustomButton
                className="form_SubmitButton"
                type="submit"
                disabled={props.isSubmitting}
                btnValue={agency? "UPDATE": "CREATE"}
              />
            </ButtonToolbar>
          </div>
        </div>
      </div>
      <div className="form_body">
        <div className="form-row">
          <div className="col-md-6">
            <Field
              label="Agency Name"
              name="name"
              id="name"
              type="text"
              required
              component={TextField}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-12">
            <Field
              label="Street Address"
              name="address"
              id="address"
              type="text"
              component={TextField}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <Field
              label="City"
              name="city"
              id="city"
              type="text"
              component={TextField}
            />
          </div>
          <div className="col-md-6">
            <Field
              label="State"
              name="state_id"
              id="state_id"
              type="text"
              component={SelectField}
            >
              <option value="">Select...</option>
              {props.getAllStates.getAllStates &&
                props.getAllStates.getAllStates.map((state, index) => (
                  <option key={index} value={state.id}>
                    {state.name}
                  </option>
                ))}
            </Field>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-4">
            <Field
              label="Zip"
              name="zip"
              id="zip"
              type="text"
              component={TextField}
            />
          </div>
        </div>
        <ErrorFocus />
      </div>
    </Form>
  );
};

const AgencyFormWithFormik = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({agency}) => {
    if (!agency) return { ...agencyForm }

    const {state, ...rest} = agency
    return {
      state_id: state?.id,
      ...rest
    }
  },
  handleSubmit: (values, { props, setSubmitting }) => {
    if(props.agency){
      props.updateAgency({
        variables: { id: props.id, input: values },
      }).then(res=>{
        setSubmitting(false);
        if (res.data.updateAgency.code === 200) {
          props.onSubmit();
        }
        props.setNotificationProps({
          variables: { open: true, message: res.data.updateAgency.message },
        });
      })
    }else{
      props.addAgency({
        variables: { input: values },
      }).then(res=>{
        setSubmitting(false);
        if (res.data.addAgency.code === 200) {
          props.onSubmit();
        }
        props.setNotificationProps({
          variables: { open: true, message: res.data.addAgency.message },
        });
      })
    }
  },
  validationSchema: agencySchema,
  displayName: "create-edit-agency-form",
})(AgencyForm);

export default compose(
  graphql(GET_ALL_STATES, { name: "getAllStates" }),
  graphql(ADD_AGENCY, { name: "addAgency" }),
  graphql(UPDATE_AGENCY, { name: "updateAgency" }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" })
  )(AgencyFormWithFormik);
