import React from "react";
import { TableSearch, TableDebounceSearch } from "./table-search-input";
import {
  Button,
  DropdownButton,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { downloadCSV } from "./export-csv";
import { FaFileExcel } from "react-icons/fa";

import FilterButton from "./filter-button";
import CustomButton from "../../components/custom-button";
import { isEmpty } from "../../utils/helpers";
import "./table-extras.css";

/**
 * this component is to render the table options at the top, such as the table title, search bar, mass update button, as well as export filter buttons.
 * @param {object} tableOptions contains all the information related to the table options.
 * @param {function} getSelectedRows
 * @param {boolean} canAction used to disable/enable mass update button
 * @param {string} searchTerm the input the string to search
 * @param {function} setSearchTerm
 * @param {array} columns
 * @param {object} filter
 * @param {function} setFilter
 * @param {boolean} controlled specifies wether the search should go done in backend 
 * @param {boolean} removeSearchField to hide or show the search field
 */
export default function TableExtras(props) {
  const {
    tableOptions,
    getSelectedRows,
    canAction,
    searchTerm,
    setSearchTerm,
    columns,
    filter,
    setFilter,
    controlled,
    removeSearchField
  } = props;
  return (
    <div>
      <div
        className="row-direction table-extras p-2 pt-3"
        id="custom-table-row"
      >
        <div className="d-flex table-title-n-search align-items-center">
          {tableOptions.title ? (
            <div className="custom-table-title" id="custom-table-title">
              <h3>
                {/* <a name="tableHeading"></a> */}
                {tableOptions.title}
              </h3>
            </div>
          ) : null}
          {!removeSearchField ? !controlled ? (
            <TableSearch searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
          ) : (
            <TableDebounceSearch
              setSearchTerm={setSearchTerm}
              searchTerm={searchTerm}
            />
          ):null}
        </div>
        <div className="table-controls">
          {!isEmpty(filter) && (
            <CustomButton
              btnValue="Clear Filters"
              size="sm"
              color="dark"
              onClick={() => {
                setFilter({});
              }}
              className="p-2"
              style={{ height: "32px", textTransform: "capitalize" }}
            />
          )}
          {tableOptions.massActions && (
            <div className="drop-down ml-1" id="custom-drop-down">
              <DropdownButton
                alignRight
                drop="down"
                variant="secondary"
                title="Mass Update"
                id={`dropdown-button-drop-down`}
                key="down"
                disabled={canAction}
              >
                {tableOptions.massActions.render(getSelectedRows)}
              </DropdownButton>
            </div>
          )}

          <div className="table-top-actions" id="custom-table-top-actn">
            <OverlayTrigger overlay={<Tooltip>Export CSV</Tooltip>}>
              <Button
                variant="secondary"
                onClick={() => {
                  downloadCSV(columns, getSelectedRows());
                }}
                className="btn-icon"
                disabled={canAction}
              >
                <FaFileExcel id="custom-excel-file-icn" size={15} />
              </Button>
            </OverlayTrigger>

            {tableOptions.FilterItems && (
              <FilterButton
                FilterItems={tableOptions.FilterItems}
                filter={filter}
                setFilter={setFilter}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
