import React from "react";
import logo from "../../../images/graphic-no-lead.png";
import Calendar from "../../../components/calendar/calendar";
import ListPopup from "../../../components/list-popup/list-popup";
import NoDataPage from "../../no-data-page/no-data-page";
import LoadingWrapper from "../../../components/loading-wrapper";
import CalendarPopover from "../../common-lead-prospect-details/calendar-popover";
import NoteReminderView from "../../common-lead-prospect-details/notes-card";
import PastActivitiesView from "../../common-lead-prospect-details/past-activities";
import MarketingOnlyDetailView from "./marketing-only-detail-card";
import MarketingOnlyDetailSelectBar from "./marketing-only-detail-select-bar";
import { getIssueEmail, inActiveMapper, formatDataForCalendar } from "../../../utils/helpers";
import { MARKETING_ONLY } from "../../../utils/constants";
import "./marketing-only-details.css";


/**
 * This component is used to render details page of the marketing only. It is divided into four main parts. On the top
 * it renders {@link MarketingOnlyDetailSelectBar} component to display marketing only's community and his name and convert to lead/prospect buttons.
 * Right below it, {@link BigCalendar} is displayed and next to it {@link MarketingOnlyDetailView} card is rendered to show basic 
 * information of the marketing only and to edit it as well. Right below Big calendar we have past-activities view and notes list view using
 * {@link NoteReminderView}. If the marketing only is not available, no data page is displayed instead of details page. 
 * while the data is loading, a full page loader is rendered. 
 * @param {Object} props queries from the container are received as props 
 */
const MarketingOnlyDetailsView = ({
  getMarketingOnlyDetail: data,
  ...props
}) => {
  const { loading, refetch } = data;
  const id = parseInt(props.match.params.id);

  const submitHandler = (refetch, message) => {
    message && props.setNotificationProps({ variables: { open: true, message: message } });
    refetch();
  }

  const handleCommunities = data => {
    return (
      <div className='communities-collection'>
        <span>:&nbsp;</span>
        <ListPopup
          data={data.communities}
          mapper={inActiveMapper}
        />
      </div>
    );
  };

  let name = !loading ? data.getMarketingOnly.Marketing_Only
    && `${data.getMarketingOnly.Marketing_Only[0].first_name_1} ${data.getMarketingOnly.Marketing_Only[0].last_name_1}`
    : ''

  name += !loading && data.getMarketingOnly.Marketing_Only[0].first_name_2
    ? ` and ${data.getMarketingOnly.Marketing_Only[0].first_name_2} ${data.getMarketingOnly.Marketing_Only[0].last_name_2}`
    : "";

  let community = !loading
    ? data.getMarketingOnly.Marketing_Only[0].communities.length > 0
      ? handleCommunities(data.getMarketingOnly.Marketing_Only[0])
      : ""
    : "";

  return !loading && data.getMarketingOnly.Marketing_Only.length === 0 ? (
    <NoDataPage
      backgroundImage={logo}
      heading={"Marketing Only customer not found!"}
      content={
        <div>
          <p className="message-reportBug">
            The resource for this Marketing Only customer was not found in our records.
            <br />
            If it's a bug, please report.
            <br />
            <a href={getIssueEmail()} target="_blank" rel="noopener noreferrer" className="btn btn-primary">Report</a>
          </p>
        </div>
      }
    />
  ) : (
    <LoadingWrapper
      loading={loading}
      component={
        <div className="col-padding col-md-12 col-12">
          <div
            className="root-row row"
            id="marketing-only-detail-header"
          >
            <MarketingOnlyDetailSelectBar
              name={name}
              community={community}
              id={id}
              data={!loading ? data.getMarketingOnly.Marketing_Only[0] : null}
              setNotificationProps={props.setNotificationProps}
              {...props}
            />
          </div>
          <div className="row-container main-responsive col-xl-12 p-2 px-3 row">
            <div className="col-padding col-12 col-md-8 detail-view-main">
              <div
                style={{
                  padding: "1rem",
                  marginTop: "0.5rem",
                  border: "1px solid #191b1d",
                  borderRadius: "0.25rem"
                }}
              >
                <Calendar
                  id={id}
                  data={Object.assign(
                    {},
                    !loading ? data.getMarketingOnly.Marketing_Only[0] : {},
                  )}
                  onSubmit={submitHandler.bind(this, refetch)}
                  {...props}
                  popover={CalendarPopover}
                  type={MARKETING_ONLY}
                />

                <PastActivitiesView
                  type={MARKETING_ONLY}
                  pastActivitiesData={
                    !loading ? formatDataForCalendar(data.getMarketingOnly.Marketing_Only[0]) : null
                  }
                  id={id}
                  onSubmit={submitHandler.bind(this, refetch)}
                />
              </div>
            </div>

            <div className="col-padding col-md-4 col-12 pb-2 pt-2 pr-0 pl-3 detail-view-side">
              <MarketingOnlyDetailView
                data={!loading ? data.getMarketingOnly.Marketing_Only[0] : null}
                id={id}
                {...props}
                onSubmit={submitHandler.bind(this, refetch)}
              />
            </div>
          </div>
        </div>
      }
    />
  );
};

export default MarketingOnlyDetailsView;