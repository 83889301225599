import React from "react";
import { Dropdown } from "react-bootstrap";

/**
 * this component render the delete request table mass update actions, and contains 2 actions, approve and delete.
 * @param {} param0 
 */
const DeleteRequestsMassUpdateItems = ({ handleApprove, selectedDeleteRequests }) => {
  return (
    <div>
      <Dropdown.Item
        eventKey="1"
        id="dropdown-1"
        onClick={() => handleApprove(selectedDeleteRequests, true)}
      >
        APPROVE
      </Dropdown.Item>
      <Dropdown.Item
        eventKey="2"
        id="dropdown-2"
        onClick={() => handleApprove(selectedDeleteRequests, false)}
      >
        REJECT
      </Dropdown.Item>
    </div>
  );
};

export default DeleteRequestsMassUpdateItems;
