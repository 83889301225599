import gql from 'graphql-tag';

const SET_NOTIFICATION_PROPS = gql`
  mutation setNotificationProps($open: Boolean, $message: String){
    setNotificationProps(open: $open, message: $message) @client{
      open
      message
    }
  }
`;


export default SET_NOTIFICATION_PROPS;