import React, { useEffect, useState, useMemo, useContext, useReducer } from "react";
import reducer, { getInitialState } from "./marketing-only-reducer";
import _ from "lodash";
import FadeIn from "../../../components/fade-in";
import Dialog from "./../../../components/dialog/dialog";
import CustomLoader from "../../../components/custom-loader";
import SendMassEmail from "../../../components/dialog/send-email"
import routeConstants from "../../layout/routes";
import DeleteDialogBox from "../../../components/dialog/delete-dialog-box";
import MarketingOnlyFilters from "../forms/marketing-only-filter-container";
import MarketingOnlyConvertView from "./marketing-only-convert-view";
import PermanentDeleteConfirmation from "../../../components/dialog/permanent-delete";
import { Auth } from "../../../rbac/rbac";
import { Dropdown } from "react-bootstrap";
import { LEAD, PROSPECT } from "../../../utils/constants"
import { DashboardContext } from "../../../context";
import { EditMarketingOnlyViewWithData } from "./edit-marketing-only-view";
import { FaEnvelope, FaPen, FaTrashAlt, FaStar, FaFilter, FaMailBulk } from "react-icons/fa";
import TableView, {
  SortableHeader,
  stringComparator,
  TextCell,
  ListCell,
  TextHeader,
  ActionCell,
  TextCellWithMapper,
  PhoneCell,
  DetailLinkCell,
  TooltipCell,
  includes,
  betweenDates,
  filterForList,
  EmailCell,
  buyerTwoIconCellObject,
} from "../../../components/new-table";
import {
  getUpdatedParams,
  getCardParams,
  formatActivityDate,
  phoneDisplay,
  isBuyerTwo,
  getSubscribedEmails,
  getAllEmails
} from "../../../utils/helpers";


/**
 * @module MarketingOnlyTableModule
 * 
 * This component is a table used to display all the marketing only customers. It contains pagination, searchbar, filtering,
 * mass-update and export to csv options. It has multiple hover-actions. It shows multiple columns displaying basic
 * information related to marketing only. Table uses reducer to maintain its state.
 * It also accesses store to check if table parameters are already stored or not.
 *
 * @param {Object} props it contains all the mutations/queries in the conatiner as props
 * @param {Object} props.data it contains array of marketing only customers to be shown on table
 * @param {Object} props.getTableParams it contains cards info stored inside cache
 * @returns {JSX.Element} It returns jsx containing the table
 */

const MarketingOnlyTable = ({
  data,
  addMarketingOnlyDeleteRequests,
  convertLeadToProspect,
  marketingOnlyDataUpdated,
  selectedDivision,
  selectedCommunity,
  getTableParams: { tableParams },
  ...props
}) => {
  const { is_manager, is_divmgr } = useContext(Auth);
  const { currentCard } = useContext(DashboardContext);
  const cacheParams = getCardParams(currentCard.tableTitle, tableParams);
  const [massEmailSubmission, setMassEmailSubmission] = useState(false)
  const [state, dispatch] = useReducer(reducer, getInitialState({ loading: data.loading, cache: cacheParams }));

  const {
    render,
    userPageCount,
    pageSize,
    Marketing_Only,
    loading,
    showDeleteConfirmation,
    isMassDelete,
    showEditBar,
    itemId,
    deleteMarketingOnlyID,
    deleteCustomerID,
    showMarketingOnlyConvertDrawer,
    showPermanentDeleteConfirmation,
    isPermDeleteSubmitted,
    editItem,
    showMassEmailModal,
    marketingOnlyEmails,
    isMassEmailSubmitted,
  } = state;

  const [note, setNote] = useState("");

  const onSaveFilter = (message) => {
    props.setNotificationProps({ variables: { open: true, message: message } });
  }

  const callDispatch = (type, payload) => {
    dispatch({ type, payload });
  };


  /**
    * This function hanldes the dialog for sending mass emails
    * 
    * @param {Array} marketingOnlyEmails Marketing Only IDs
    */
  const handleMassEmail = (marketingOnlyEmails) => {
    dispatch({
      type: "UPDATE",
      payload: {
        showMassEmailModal: !showMassEmailModal,
        marketingOnlyEmails
      },
    });
  }

  /**
   * This function handles the submitted state for when mass
   * emails are sent
   */
  const handleMassEmailSubmitted = () => {
    dispatch({
      type: "UPDATE",
      payload: {
        isMassEmailSubmitted: !isMassEmailSubmitted,
        showMassEmailModal: !showMassEmailModal
      },
    });
  };

  const onMassEmailSuccess = (message) => {
    props.setNotificationProps({
      variables: {
        open: true,
        message: message,
      },
    });
  }

  /**
   * This function is used by the Dialog as well as the send-email component.
   * It sets the value true when the Dialog is submitted and is reset to
   * false in the send-email component. 
   * 
   * @param {Boolean} state Changes the state of massEmailSubmission 
   */
  const checkMassEmailSubmission = (state) => {
    setMassEmailSubmission(state)
  }

  /**
   * This function is responsible to generate the entire content
   * of the Dialog. It renders the 'SendMassEmail' component
   * with an array of emails of selected Marketing only individuals and a 
   * Boolean variable to check the form submission state.
   * 
   * @param {Array} emails Marketing Only Emails
   * @param {Boolean} checkSubmission Is form submitted
   * @param {Function} onFormSubmit Updated the states when form submitted successfully
   * @param {Function} onSubmitNotification Notifies user of the email submission
   * @param {Funciton} handleCheckSubmission Updates the status of checkSubmission  
   * @returns 
   */
  const sendMassEmailBody = (
    marketingOnlyEmails
  ) => {
    return (
      marketingOnlyEmails && (
        <SendMassEmail
          emails={marketingOnlyEmails.split("%3B%20")}
          checkSubmission={massEmailSubmission}
          onFormSubmit={handleMassEmailSubmitted}
          onSubmitNotification={onMassEmailSuccess}
          handleCheckSubmission={checkMassEmailSubmission}
        />
      )
    )
  }

  /**
   * It is used to show delete dialog and set the delete and customer id variables.
   * @param {number} itemId marketing only id
   * @param {number} customer_id customer id
   * @function
   * @inner
   * @memberof module:MarketingOnlyTableModule
   * @see {@link module:MarketingOnlyTableModule~MarketingOnlyTable}
   */
  const handleDelete = (itemId, customer_id) => {
    dispatch({
      type: "UPDATE",
      payload: {
        showDeleteConfirmation: !showDeleteConfirmation,
        deleteMarketingOnlyID: itemId,
        deleteCustomerID: customer_id,
      },
    });
  };

  /**
   * It is used to show delete dialog and set the delete id variable.
   * @param {array} id selected marketing only ids
   * @function
   * @inner
   * @memberof module:MarketingOnlyTableModule
   * @see {@link module:MarketingOnlyTableModule~MarketingOnlyTable}
   */
  const handleMassDelete = (id) => {
    dispatch({
      type: "UPDATE",
      payload: {
        showDeleteConfirmation: !showDeleteConfirmation,
        isMassDelete: true,
        deleteMarketingOnlyID: id,
      },
    });
  };

  /**
   * It is used to hide delete dialog.
   * @function
   * @inner
   * @memberof module:MarketingOnlyTableModule
   * @see {@link module:MarketingOnlyTableModule~MarketingOnlyTable}
   */
  const hideDeleteDialog = () => {
    dispatch({
      type: "UPDATE",
      payload: { showDeleteConfirmation: !showDeleteConfirmation, isMassDelete: false },
    })
  }

  /**
   * It is used to call the mutation to delete a Marketing Only customer(s)
   * @function
   * @inner
   * @memberof module:MarketingOnlyTableModule
   * @see {@link module:MarketingOnlyTableModule~MarketingOnlyTable}
   */
  const handleDeleteConfirmation = () => {
    dispatch({
      type: "UPDATE",
      payload: { showDeleteConfirmation: !showDeleteConfirmation, isMassDelete: false },
    })

    addMarketingOnlyDeleteRequests({
      variables: { input: { marketing_only_ids: deleteMarketingOnlyID, note: note } },
    }).then((res) => {
      if (res.data.addMarketingOnlyDeleteRequests.code === 200) {
        setNote("")
        props.update();

        props.setNotificationProps({
          variables: {
            open: true,
            message: res.data.addMarketingOnlyDeleteRequests.message,
          }
        })
      }
    }).catch((res) => {
      res.graphQLErrors.map((error) => {
        return error.message;
      })
    })
  }

  /**
   * this function is used to change the ispermDeleteSubmitted.
   */
  const handlePermanentDeleteConfirmation = () => {
    dispatch({
      type: "UPDATE",
      payload: { isPermDeleteSubmitted: !isPermDeleteSubmitted }
    })
  }

  /**
   * It is used to when permanent delete has successfully done and it is used to show the success notification and refetch data and hides the permanent delete confirmation dialog.
   * @param {string} message message to be shown in notification bar
   * @function
   * @inner
   * @memberof module:MarketingOnlyTableModule
   * @see {@link module:MarketingOnlyTableModule~MarketingOnlyTable}
   */
  const onPermanentDeletionSuccess = (message) => {
    dispatch({
      type: "UPDATE",
      payload: { loading: true, showPermanentDeleteConfirmation: false },
    })

    props.update();
    props.setNotificationProps({
      variables: {
        open: true,
        message: message
      }
    })
  }

  /**
   * It is used to render the permanent delete confirmation dialog.
   * @param {number} deletCustomerID customer id
   * @param {boolean} isPermDeleteSubmitted boolean that shows if permanent delete is submitted
   * @param {function} onPermanentDeletionSuccess its a function called and permanent delete is successfully performed
   * @function
   * @inner
   * @memberof module:MarketingOnlyTableModule
   * @see {@link module:MarketingOnlyTableModule~MarketingOnlyTable}
   */
  const reConfirmationBody = (
    deleteCustomerID,
    isPermDeleteSubmitted,
    onPermanentDeletionSuccess
  ) => {
    return (
      deleteCustomerID && (
        <PermanentDeleteConfirmation
          customerId={deleteCustomerID}
          isSubmitted={isPermDeleteSubmitted}
          onSubmit={onPermanentDeletionSuccess}
        />
      )
    );
  };

  /**
   * It is used to show the edit marketing only drawer and set the item id to the marketing only id and the edit item to the marketing only.
   * @param {object} data contains information realted to the marketing only
   * @param {number} itemId marketing only id
   * @function
   * @inner
   * @memberof module:MarketingOnlyTableModule
   * @see {@link module:MarketingOnlyTableModule~MarketingOnlyTable}
   */
  const handleEditOpen = (itemId, data) => {
    dispatch({
      type: "UPDATE",
      payload: {
        showEditBar: true,
        itemId: itemId,
        editItem: data,
      },
    });
  };

  /**
   * It is used to close the edit marketing only drawer and reset the item id and edit item.
   * @param {object} data contains information realted to the marketing only
   * @param {number} itemId marketing only id
   * @function
   * @inner
   * @memberof module:MarketingOnlyTableModule
   * @see {@link module:MarketingOnlyTableModule~MarketingOnlyTable}
   */
  const handleEditClose = () => {
    dispatch({
      type: "UPDATE",
      payload: {
        showEditBar: false,
        itemId: null,
        editItem: null,
      },
    });
  };

  /**
   * It is used to show the marketing only to lead/prospect drawer and set the item id to the marketing only id and the edit item to the marketing only.
   * @param {object} data contains information related to the marketing only
   * @param {number} itemId marketing only id
   * @function
   * @inner
   * @memberof module:MarketingOnlyTableModule
   * @see {@link module:MarketingOnlyTableModule~MarketingOnlyTable}
   */
  const handleMarketingOnlyConvertDrawer = (itemId, data, type) => {
    dispatch({
      type: "UPDATE",
      payload: {
        showMarketingOnlyConvertDrawer: { show: true, type },
        itemId,
        editItem: data,
      },
    });
  };

  /**
   * It is used to close the marketing only to lead drawer and reset the item id and edit item.
   * @param {object} data contains information realted to the marketing only
   * @param {number} itemId marketing only id
   * @function
   * @inner
   * @memberof module:MarketingOnlyTableModule
   * @see {@link module:MarketingOnlyTableModule~MarketingOnlyTable}
   */
  const toggleSideBar = () => {
    dispatch({
      type: "UPDATE",
      payload: {
        showMarketingOnlyConvertDrawer: {
          show: !showMarketingOnlyConvertDrawer.show,
          type: ""
        },
        itemId: null,
        editItem: null,
      },
    });
  };

  const marketingOnlyUpdate = (message, isMarketingOnlyConvert) => {
    if (isMarketingOnlyConvert) toggleSideBar()
    props.update()
    message && props.setNotificationProps({
      variables: { open: true, message: message }
    })
  }

  /**
   * It is used to render the mass dropdown actions which contains 4 actions.
   * @param {array} emails selected MarketingOnly emails
   * @param {array} ids selected MarketingOnly ids
   * @function
   * @inner
   * @memberof module:MarketingOnlyTableModule
   * @see {@link module:MarketingOnlyTableModule~MarketingOnlyTable}
   * @returns {JSX.Element}
   */
  const massDropDownActions = (emails, ids) => {
    let mailTo = "mailto:" + emails;
    let massMail = "mailto:?bcc=" + emails;
    return (
      <div>
        <div style={{ display: "none" }}>
          <a href={mailTo} id="send-marketing-only-email">
            {""}
          </a>
        </div>
        <div style={{ display: "none" }}>
          <a href={massMail} id="send-mass-marketing-only-email">
            {""}
          </a>
        </div>
        <Dropdown.Item
          eventKey="1"
          id="dropdown-1"
          onClick={() => document.getElementById("send-marketing-only-email").click()}
        >
          <FaEnvelope id={"dropdown-icn-1"} /> Send Email TO
        </Dropdown.Item>

        <Dropdown.Item
          eventKey="2"
          id="dropdown-2"
          // onClick={() => handleMassEmail(emails)} // mass email using SendGrid (currently disabled)
          onClick={() => document.getElementById("send-mass-marketing-only-email").click()} // mass email using Outlook
        >
          <FaMailBulk id={"dropdown-icn-3"} /> Send Email BCC
        </Dropdown.Item>

        <Dropdown.Item
          eventKey="3"
          id="dropdown-3"
          onClick={() => handleMassDelete(ids)}
        >
          {" "}
          <FaTrashAlt id="drop-down-icn-4" /> Delete
        </Dropdown.Item>
      </div>
    );
  };

  const ehancedFilter = (onSaveFilter, currentCard, ...rest) => (Component) => {
    const WrappedComponent = (props) => {
      return (
        <Component
          {...props}
          onSaveFilter={onSaveFilter}
          currentCard={currentCard}
          {...rest}
        />
      );
    };
    return WrappedComponent;
  };

  /**
   * It is a callback function which is called on any table change like: pagination, page-size, filters, search term.
   * Table passes its current state so that we can update the table parameters in store and state, which are used in
   * refetching the data with correct parameters.
   * @function
   * @inner
   * @memberof module:MarketingOnlyTableModule
   * @see {@link module:MarketingOnlyTableModule~MarketingOnlyTable}
   */
  const fetchData = React.useCallback((tableProps) => {
    if (loading) return;

    const columnItem = columns.find(
      (item) => item.accessor === tableProps.sortColumn.name
    );

    const pageCount = Math.ceil(
      data.getMarketingOnly.total_count / tableProps.pageSize
    );

    const variables = {
      filter: {
        ...tableProps.filter,
      },
      limit: tableProps.pageSize,
      pageNum: tableProps.currentPage,
      search: tableProps.searchTerm,
      order: {
        id: tableProps.sortColumn.id,
        name: (columnItem && columnItem.sortName) || tableProps.sortColumn.name,
        sort: tableProps.sortColumn.sort,
      },
    };

    if (
      tableProps.searchTerm !== state.search ||
      !_.isEqual(variables.filter, state.filter)
    ) {
      variables.pageNum = 0;
    }
    if (tableProps.currentPage > pageCount - 1) {
      variables.pageNum = pageCount ? pageCount - 1 : 0;
    }

    callDispatch("UPDATE", {
      ...variables,
      pageSize: tableProps.pageSize,
      loading: true
    });

    if (pageCount !== userPageCount)
      callDispatch("UPDATE", { userPageCount: pageCount });

    props.setTableParams({
      variables: {
        cardsParams: getUpdatedParams(tableParams.cardsParams, {
          cardTitle: currentCard.tableTitle,
          params: variables
        })
      }
    }).then((res) => {
      dispatch({ type: "UPDATE", payload: { loading: false } });
    });
  });

  /**
   * This defines columns of the table. It has multiple columns, displaying information of the marketing only.
   * Most of the columns are sortable. Clicking the first name column opens the details page of marketing only.
   * Buyer two icon shows up as the first column if the marketing only has buyer 2 data as well.
   * @constant
   * @memberof module:MarketingOnlyTableModule
   */
  const columns = useMemo(
    () => [
      {
        id: "0",
        header: "",
        accessor: "",
        component: TextHeader,
        cell: buyerTwoIconCellObject,
      },
      {
        id: "1",
        header: "First Name(s)",
        accessor: "first_name_1",
        component: SortableHeader,
        comparator: stringComparator,
        cell: {
          component: DetailLinkCell,
          path: (field, accessor) => routeConstants.MARKETING_ONLY_DETAIL,
        },
      },
      {
        id: "2",
        header: "Last Name",
        accessor: "last_name_1",
        component: SortableHeader,
        comparator: stringComparator,
        cell: { component: TextCell },
      },
      {
        id: "3",
        header: "Notes",
        accessor: "other_notes",
        component: TextHeader,
        sortable: false,
        filter: includes,
        cell: { component: TooltipCell },
      },
      {
        id: "4",
        header: "Email(s)",
        accessor: "primary_email_1",
        component: SortableHeader,
        comparator: stringComparator,
        cell: {
          component: EmailCell,
          check: "primary_email_1_status",
          callback: getAllEmails
        },
      },
      {
        id: "5",
        header: "Mailchimp Subscription",
        accessor: "primary_email_1_status",
        hidden: true,
        csvMapper: (field, accessor) => field[accessor] ? "Subscribed" : "Unsubscribed",
        cell: { component: null },
      },
      {
        id: "6",
        header: "Phone",
        accessor: "cell_phone_1",
        component: TextHeader,
        sortable: false,
        filter: includes,
        csvMapper: (field, accessor) => phoneDisplay(field[accessor]) || "",
        cell: { component: PhoneCell },
      },
      {
        id: "7",
        header: "First Name 2",
        accessor: "first_name_2",
        hidden: true,
        cell: { component: null },
      },
      {
        id: "8",
        header: "Last Name 2",
        accessor: "last_name_2",
        hidden: true,
        cell: { component: null },
      },
      {
        id: "9",
        header: "Primary Email 2",
        accessor: "primary_email_2",
        hidden: true,
        cell: { component: null },
      },
      {
        id: "10",
        header: "Mailchimp 2 Subscription",
        accessor: "primary_email_2_status",
        hidden: true,
        csvMapper: (field, accessor) => isBuyerTwo(field) ? field[accessor] ? "Subscribed" : "Unsubscribed" : "",
        cell: { component: null },
      },
      {
        id: "11",
        header: "Phone 2",
        accessor: "cell_phone_2",
        hidden: true,
        csvMapper: (field, accessor) => phoneDisplay(field[accessor]) || "",
        cell: { component: null },
      },
      {
        id: "12",
        header: "Community(ies)",
        accessor: "communities",
        component: TextHeader,
        sortable: false,
        csvMapper: (field, accessor) => field[accessor].map(({ name }) => name),
        filter: filterForList,
        cell: {
          component: ListCell,
          mapper: (field) =>
            field.map((item) => <div key={item.id}>{item.name}</div>),
        },
      },
      {
        id: "13",
        header: "Division",
        accessor: "division",
        component: TextHeader,
        sortable: false,
        csvMapper: (field, accessor) => field[accessor]?.name,
        filter: filterForList,
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) => field[accessor]?.name,
        },
      },
      {
        id: "14",
        header: "Date Created",
        accessor: "created_at",
        component: SortableHeader,
        filter: betweenDates,
        csvMapper: (field, accessor) => formatActivityDate(field[accessor]),
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) => formatActivityDate(field[accessor]),
        },
      },
    ],
    [currentCard.filter]
  );

  /**
   * Array of objects containing different actions which can be performed on row hover.
   * @constant
   * @memberof module:MarketingOnlyTableModule
   */
  const hoverActions = useMemo(
    () => [
      {
        tooltip: "Convert To Lead",
        icon: FaFilter,
        component: ActionCell,
        action: ({ isSelected, uuid, ...row }) => {
          handleMarketingOnlyConvertDrawer(row.id, row, LEAD);
        }
      },
      {
        tooltip: "Convert To Prospect",
        icon: FaStar,
        component: ActionCell,
        action: ({ isSelected, uuid, ...row }) => {
          handleMarketingOnlyConvertDrawer(row.id, row, PROSPECT);
        }
      },
      {
        tooltip: "Edit",
        icon: FaPen,
        component: ActionCell,
        action: ({ isSelected, uuid, ...row }) => {
          handleEditOpen(row.id, row);
        }
      },
      {
        tooltip: "Delete",
        icon: FaTrashAlt,
        component: ActionCell,
        action: ({ id, customer_id }) => {
          handleDelete(id, customer_id);
        }
      }
    ],
    []
  );

  /**
   * This is an object that defines table title, and massActions and filters for the table.
   *
   * @constant
   * @memberof module:MarketingOnlyTableModule
   */
  const tableOptions = {
    title: currentCard.tableTitle,
    massActions: {
      render: (getSelectedRows) => {
        let emails = getSubscribedEmails(getSelectedRows());
        let ids = getSelectedRows().map((x) => x.id);
        return massDropDownActions(emails, ids);
      },
    },
    FilterItems: ehancedFilter(onSaveFilter, currentCard)(MarketingOnlyFilters)
  };

  /** Refetch table data on any update like create, edit, delete */
  useEffect(() => {
    if (props.updated !== null) {
      data.refetch();
    }
  }, [props.updated]);

  useEffect(() => {
    if (!cacheParams) {
      dispatch({ type: "RESET", payload: { render: false } });
    } else {
      dispatch({
        type: "UPDATE",
        payload: { ...cacheParams, render: false, pageSize: cacheParams.limit },
      });
    }
    props.setPreviousCard({ variables: { title: currentCard.title } });
  }, [props.currentCard.title]);

  /**
   * Either set the data in state or set the loading to inform the table what to render.
   */
  useEffect(() => {
    if (!data.loading && data.getMarketingOnly && data.getMarketingOnly.Marketing_Only) {
      dispatch({
        type: "UPDATE",
        payload: {
          render: true,
          Marketing_Only: data.getMarketingOnly.Marketing_Only,
          loading: false,
          userPageCount: Math.ceil(data.getMarketingOnly.total_count / pageSize),
        },
      });
    }
    if (data.loading) {
      dispatch({ type: "UPDATE", payload: { loading: data.loading } });
    }
  }, [data]);

  /** Refetch table data on any update like create, edit, delete */
  useEffect(() => {
    if (marketingOnlyDataUpdated) {
      dispatch({ type: "UPDATE", payload: { loading: true } });
      data.refetch();
    }
  }, [marketingOnlyDataUpdated]);

  return (
    <div className="col-md-12 col-12 p-0">
      <Dialog
        show={showDeleteConfirmation}
        onHide={hideDeleteDialog}
        title="Delete Confirmation"
        body={<DeleteDialogBox setFunc={setNote} customer />}
        click={handleDeleteConfirmation}
        isPermanentDelete={!isMassDelete && is_manager() || is_divmgr()}
        permanentDeleteClick={() =>
          dispatch({
            type: "UPDATE",
            payload: {
              showDeleteConfirmation: false,
              showPermanentDeleteConfirmation: true,
            },
          })
        }
        clickname="YES"
        closename="NO"
      />

      <Dialog
        show={showPermanentDeleteConfirmation}
        onHide={() =>
          dispatch({
            type: "UPDATE",
            payload: { showPermanentDeleteConfirmation: false },
          })
        }
        title="Re-Confirmation"
        body={reConfirmationBody(
          deleteCustomerID,
          isPermDeleteSubmitted,
          onPermanentDeletionSuccess
        )}
        click={handlePermanentDeleteConfirmation}
        clickname="Permanently Delete"
        closename="NO"
      />

      <Dialog
        show={showMassEmailModal}
        onHide={() =>
          dispatch({
            type: "UPDATE",
            payload: { showMassEmailModal: !showMassEmailModal },
          })
        }
        title="Send Email To"
        body={sendMassEmailBody(
          marketingOnlyEmails
        )}
        click={() => checkMassEmailSubmission(true)}
        clickname="Send Email"
        closename="Cancel"
        size="lg"
      />

      <FadeIn show={render}>
        <div className="table-leads">
          <TableView
            columns={columns}
            data={Marketing_Only}
            tableOptions={tableOptions}
            loading={loading}
            hoverActions={hoverActions}
            userPageCount={userPageCount}
            userTotalCount={
              !loading && data.getMarketingOnly ? data.getMarketingOnly.total_count : null
            }
            fetchData={fetchData}
            currentPage={state.pageNum}
            pageSize={state.pageSize}
            searchTerm={state.search}
            sortColumn={state.order}
            filter={state.filter}
            controlled={true}
          />
        </div>
      </FadeIn>

      {!render && <CustomLoader />}

      {showEditBar &&
        <EditMarketingOnlyViewWithData
          show={showEditBar}
          id={itemId}
          update={marketingOnlyUpdate}
          close={handleEditClose}
        />
      }

      {showMarketingOnlyConvertDrawer.show &&
        <MarketingOnlyConvertView
          show={showMarketingOnlyConvertDrawer.show}
          id={itemId}
          onSubmit={(msg) => marketingOnlyUpdate(msg, true)}
          marketingOnlyData={editItem}
          close={toggleSideBar}
          type={showMarketingOnlyConvertDrawer.type}
        />
      }
    </div>
  );
};

export default MarketingOnlyTable;