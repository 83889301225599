import React, { useContext } from "react";
import { Auth } from "../../../rbac/rbac";
import { compose, graphql } from "react-apollo";
import { GET_PREVIOUS_CARD } from "../../../cacheql/queries";
import { GET_DASHBOARD_REALTORS } from "../../../graphql/queries";
import { RealtorSalesByCommunity } from "../realtor-salesbycommunity-table";
import RealtorsTable from "./realtors-container";
import DashboardContextProvider from "../../../context/dashboard-context-provider";
import {
  GET_SALES_TO_REALTOR_COUNT,
  GET_SALES_BY_COMMUNITY_COUNT,
  GET_YOY_REGISTRATIONS_COUNT
} from "../../../graphql/queries/realtors/list-realtors";

const activityCards = [
  {
    title: "All Realtors",
    isDetail: true
  },
  {
    title: "Sales by Community",
    customDataQuery: GET_SALES_BY_COMMUNITY_COUNT,
    customPath: 'getSalesByCommunityCount',
    customTable: <RealtorSalesByCommunity />,
  },
  {
    title: "YOY Registrations",
    mode: "YOY_REGISTRATIONS",
    customDataQuery: GET_YOY_REGISTRATIONS_COUNT,
    customPath: 'getRegistrationsByRealtorsCount',
    isDetail: true
  },
  {
    title: "YOY Sales",
    mode: "YOY_SALES",
    customDataQuery: GET_SALES_TO_REALTOR_COUNT,
    customPath: 'getSalesByRealtorsCount.count',
    percentPath: 'getSalesByRealtorsCount.percent',
    isDetail: true
  },

];

/**
 * This component is used to render realtor dashboard with 4 cards and a table. Not all the tables render the same table, 
 * we have 3 cards that render the same tables and 1 card that redners the different table. 
 * This dahsboard contains community dropdown and division dropdown available for manager role only. 
 * By default All Realtors card is preselected.
 * 
 * @param {*} props 
 */
const RealtorsDashboardView = props => {

  const { is_manager, has_multiple_divisions } = useContext(Auth);

  const getDefaultCard = () => {
    const cardTitle = props.getPreviousCard.previousCard.title;
    const index = activityCards.findIndex(item => item.title === cardTitle);
    if (index === -1) {
      return activityCards[0];
    }
    return activityCards[index];
  }

  return (
    <DashboardContextProvider
      type="Realtors"
      pageTitle={is_manager() ? null : "Dashboard"}
      startingDefaultCard={getDefaultCard()}
      dataQuery={GET_DASHBOARD_REALTORS}
      activityCards={activityCards}
      tableView={<RealtorsTable />}
      withCommunity={false}
      withDivision={is_manager() || has_multiple_divisions()}
      {...props}
    />
  );
};

export default compose(
  graphql(GET_PREVIOUS_CARD, { name: "getPreviousCard" })
)(RealtorsDashboardView);
