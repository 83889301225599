
import gql from 'graphql-tag';

const MARK_LEAD_APPOINTMENT_DONE = gql` 
mutation markDoneLeadAppointment($input: LeadAppointmentMarkDoneInput!) {
    markDoneLeadAppointment(input: $input){
    message
    code
    }
}
`;

export default MARK_LEAD_APPOINTMENT_DONE;
