import React, { useContext } from "react";
import { Form, Field, withFormik } from "formik";
import { sanitizeFilterObject, isEmpty } from "../../utils/helpers";
import TextField from "../../components/fields/text-field";
import CustomButton from "../../components/custom-button";
import CheckboxField from "../../components/fields/checkbox-field";
import { allRegistrationFilterSchema, allRegistrationFilterForm } from "./all-registration-filter-schema";
import { DashboardContext } from "../../context";

/**
 * This component is used to render all registrations card table filter form. It contains two text fields and 1 checkbox field.
 * In case of YTD/All Realtor Registrations, only the 'Agency' filter is displayed. 
 * At the bottom it contains an input field to name the filter and save it.
 * Apply filter button remains disabled unless some value on filter fields are input.
 * On submit setFilter is called, which triggers table refetch. 
 * @param {Object} props 
 */
const AllRegistrationFilterForm = (props) => {
  const { currentCard } = useContext(DashboardContext)
  const { values } = props;

  const enableFormSubmit = ({ filter_name: _, ...valueCompareFrom }) => {
    const filters = sanitizeFilterObject(Object.assign({}, valueCompareFrom));
    return isEmpty(filters);
  };

  return (
    <Form className="filter-menu">
      {!currentCard.uniqueTitle?.includes("Realtor") &&
        <div className="row">
          <div className="col-md-12">
            <Field
              id="realtor"
              label="Realtor Name"
              name="realtor"
              component={TextField}
            />
          </div>
        </div>
      }
      <div className="row">
        <div className="col-md-12">
          <Field
            id="agency"
            label="Agency Name"
            name="agency"
            component={TextField}
          />
        </div>
      </div>
      {!currentCard.uniqueTitle?.includes("Realtor") &&
        <div className="row">
          <div className="col-md-12">
            <Field
              id="expired"
              label="Show Inactive"
              name="expired"
              component={CheckboxField}
            />
          </div>
        </div>
      }
      <CustomButton
        type="submit"
        btnValue="APPLY FILTERS"
        className="filter-apply-btn"
        disabled={enableFormSubmit(values)}
      />
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (props) => {
    const { filter } = props;
    if (!isEmpty(filter)) return filter;
    return allRegistrationFilterForm
  },
  handleSubmit: (values, { props, setSubmitting }) => {
    const { setFilter, setShow } = props;
    setFilter(values);
    setShow(false);
    setSubmitting(false);
  },
  validationSchema: allRegistrationFilterSchema,
  displayName: "all-registration-filter-form",
  enableReinitialize: true,
})(AllRegistrationFilterForm);
