export const getInitialState = ({ loading, cache }) => {
  return {
    input: {
      customer_id: null
    },
    pageSize: (cache && cache.limit) || 10,
    search: (cache && cache.search) || "",
    limit: (cache && cache.limit) || 10,
    pageNum: (cache && cache.pageNum) || 0,
    order: (cache && cache.order) || {
      id: -1,
      name: "",
      comparator: "",
      sort: ""
    },
    userPageCount: 0,
    archivedUsers: [],
    loading: loading,
    render: !loading,
    showPermanentDeletConfirmation: false,
    deleteCustomerId: null,
    isPermDeleteSubmitted: null
  };
};

export default function reducer(state, action) {
  switch (action.type) {
    case "UPDATE":
      return Object.assign({}, state, { ...action.payload });

    default:
      return state;
  }
}
