import React from "react";
import { FaTrash, FaWrench, FaEnvelope, FaEdit } from "react-icons/fa";
import { Tabs, Tab } from "react-bootstrap";

import DeleteRequestTable from "./delete-request-table";
import OtherCustomizations from "./other-customizations";
import EmailAssociations from "./email-associations";
import EmailReplyAssociations from './email-reply-associations'
import UpdateInformation from "./update-information";

import DashboardSelectBar from "../dashboard/dashboard-select-bar";
import ContentGrid from "../../components/grid/content-grid";
import { Auth } from "../../rbac/rbac";
import { ROLE_OSC } from "../../utils/constants";
import "./admin-styles.css";

/**
 * this component renders the cusomizations page which contains a select bar and 3 tabs that are delete requests, other customizations and webform status emails associations.
 * @param {*} param0 
 */
const AdminCustomization = ({ editorState, ...props }) => {
  const { is_manager, is_divmgr, user } = React.useContext(Auth);
  const isAdmin = is_manager() || is_divmgr()
  const isOsc = user.role === ROLE_OSC || user.secondary_role === ROLE_OSC
  const defaultActiveTab = isAdmin || isOsc ? "deleteRequests" : "customization";

  return (
    <ContentGrid
      id="admin-customizations"
      selectbar={
        <DashboardSelectBar
          type="Admin"
          pageTitle="Customizations"
          {...props}
        />
      }
      content={{
        data: (
          <div className="delete-request-root ">
            <Tabs
              defaultActiveKey={defaultActiveTab}
              id="uncontrolled-tab-example"
              className="tabs"
            >
              {(isAdmin || isOsc) && (
                <Tab
                  eventKey="deleteRequests"
                  title={
                    <div className="d-flex">
                      <FaTrash className="mr-2" />
                      Delete Requests
                    </div>
                  }
                  className="tabs nav nav-tabs"
                >
                  <DeleteRequestTable />
                </Tab>
              )}
              <Tab
                eventKey="customization"
                title={
                  <div className="d-flex">
                    <FaWrench className="mr-2" />
                    Other Customizations
                  </div>
                }
                className="tabs nav nav-tabs"
              >
                <OtherCustomizations />
              </Tab>
              {(isAdmin) && (
                <Tab
                  eventKey="emailAssociations"
                  title={
                    <div className="d-flex">
                      <FaEnvelope className="mr-2" />
                      Web Form Status Email Associations
                    </div>
                  }
                  className="tabs nav nav-tabs"
                >
                  <EmailAssociations />
                </Tab>
              )}
              {(isAdmin) && (
                <Tab
                  eventKey="replyEmailAssociations"
                  title={
                    <div className="d-flex">
                      <FaEnvelope className="mr-2" />
                      Web Form Auto-Reply/Convert
                    </div>
                  }
                  className="tabs nav nav-tabs"
                >
                  <EmailReplyAssociations />
                </Tab>
              )}
              {(isAdmin || isOsc) && (
                <Tab
                  eventKey="descriptions"
                  title={
                    <div className="d-flex">
                      <FaEdit className="mr-2" />
                      Division/Community Information
                    </div>
                  }
                  className="tabs nav nav-tabs"
                >
                  <UpdateInformation />
                </Tab>
              )}
            </Tabs>
          </div>
        )
      }}
    />
  );
};

export default AdminCustomization;
