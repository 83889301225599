import React from "react";

import TableView, {
  SortableHeader,
  stringComparator,
  TextHeader,
  TextCell,
  PhoneCell,
  DetailLinkCell,
  EmailCell
} from "../../../components/new-table";
import routeConstants from "../../layout/routes";

/**
 * This component renders an uncontrolled table, whose pagination etc is handled on the front end side. This table 
 * has search, pagination and export to csv options. It has four columns rendering realtors information.
 * @param {Object} props 
 * @param {Object} props.data data containing associated realtors
 * @param {Boolean} props.loading if data is loaded or not
 */
const AssociatedRealtorsTable = (props) => {
  const { data, loading } = props;

  const columns = React.useMemo(() => [
    {
      id: "1",
      header: "First Name",
      accessor: "first_name",
      component: SortableHeader,
      comparator: stringComparator,
      cell: {
        component: DetailLinkCell,
        path: (field,accessor)=>routeConstants.REALTOR_DETAIL
      },
    },
    {
      id: "2",
      header: "Last Name",
      accessor: "last_name",
      component: SortableHeader,
      comparator: stringComparator,
      cell: {
        component: TextCell,
      },
    },
    {
      id: "3",
      header: "Email",
      accessor: "primary_email",
      component: TextHeader,
      cell: {
        component: EmailCell,
        check: 'primary_email_status'
      },
    },
    {
      id: "4",
      header: "Phone",
      accessor: "cell_phone",
      component: SortableHeader,
      comparator: stringComparator,
      cell: {
        component: PhoneCell,
      },
    },
  ]);

  const tableOptions = {
    title: "Associated Realtors",
  };

  return (
    <div>
      <TableView
        columns={columns}
        data={data ? data : []}
        tableOptions={tableOptions}
        loading={loading}
      />
    </div>
  );
};

export default AssociatedRealtorsTable;
