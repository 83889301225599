import gql from 'graphql-tag';

/**
 * GET_REALTOR_REGISTRATION fetches all the new realtor registrations
 * GET_ALL_REALTOR_REGISTRATIONS fetches all registrations which have been
 * converted to a Realtor. 
 */
export const GET_REALTOR_REGISTRATION = gql`
  query getRealtorRegistrations($filter: RealtorRegistrationFilter, $sort: [String], $search: String, $limit: Int, $pageNum: Int) {
    getRealtorRegistrations(filter: $filter, sort: $sort, search: $search, limit: $limit, pageNum: $pageNum) {
      realtor_registration {
        id
        name
        email
        phone
        brokerage_name
        note
        realtor_id
        createdAt
        updatedAt
        division{
          id
        }
      }
      total_count
    }
  }
`

export const GET_REALTOR_REGISTRATION_COUNT = gql`
query getRealtorRegistrationCount($input: RegistrationCountFilter) {
  getRealtorRegistrationCount(filter: $input) 
}
`

export const GET_ALL_REALTOR_REGISTRATIONS_COUNT = gql`
query getAllRealtorRegistrations($input: RegistrationsFilter) {
  getAllRealtorRegistrations(filter: $input) {
    total_count
  }
}
`

export const GET_ALL_REALTOR_REGISTRATIONS = gql`
query getAllRealtorRegistrations($filter: RegistrationsFilter, $search: String, $limit: Int, $pageNum: Int, $sort: [String]) {
  getAllRealtorRegistrations(filter: $filter, search: $search, limit: $limit, pageNum: $pageNum, sort: $sort) {
    realtors {
      id
      first_name
      last_name
      primary_email
      primary_email_status
      cell_phone
      is_listing_agent_of_sb
      Agency {
        name
      }
      Communities {
        id
        name
      }
      division {
        id
        name
      }
    }
    total_count
  }
}
`