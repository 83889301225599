import React, { useEffect, useState } from "react";
import { useQuery } from "react-apollo-hooks";
import { graphql, compose } from "react-apollo";
import { ButtonToolbar } from "react-bootstrap";
import { FaTrashAlt, FaPen, FaPaperclip } from "react-icons/fa";

import Dialog from "../../components/dialog/dialog";
import CustomButton from "../../components/custom-button";
import LeadCallButton from "../lead/details/lead-call-button";
import ProspectCallButton from "../prospect/details/prospect-call-button";
import HomeownerCallButton from "../homeowner/details/homeowner-call-button";

import {
  get5WordsForTitle,
  formatActivityDate,
  getIconByEvent,
} from "../../utils/helpers";
import { LEAD, PROSPECT, TEXT_OUT_ACTIVITY } from "../../utils/constants"

import { FETCH_SMS_ATTACHMENT } from "../../graphql/queries";
import {
  DELETE_PROSPECT_PHONE_CALL,
  DELETE_LEAD_PHONE_CALL,
  DELETE_HOMEOWNER_PHONE_CALL
} from "../../graphql/mutations";

import "./style.css";

/**
 *  @module PastActivityPhoneSmsListViewModule
 * 
 * This component is used to display the sms in the past activities
 * @param {object} pastActivitiesData contains information related to the sms
 * @param {string} type to check the type of the customer if its lead or prospect or homeowner
 * @param {function} onSubmit
 * @param {function} deleteProspectPhoneCall to delete prospect type customer sms
 * @param {function} deleteLeadPhoneCall to delete lead type customer sms
 * @param {function} deleteHomeownerPhoneCall to delete homeowner type customer sms
 * @param {mut}
 *
 */
const PastActivityPhoneSmsListView = (props) => {
  const {
    pastActivitiesData,
    deleteProspectPhoneCall,
    deleteLeadPhoneCall,
    deleteHomeownerPhoneCall,
    onSubmit,
    type,
  } = props;

  const [showConfirmationDialog, setshowConfirmationDialog] = useState(false);
  const [deleteId, setdeleteId] = useState();
  const [downloadUrl, setDownloadUrl] = useState(null);

  const { refetch: getSmsAttachment } = useQuery(FETCH_SMS_ATTACHMENT, { skip: true });

  /**
   * this function displays the delete confirmation dialog
   * @function
   * @inner
   * @memberof module:PastActivityPhoneSmsListViewModule
   * @param {number} phone_call_id sms id
   */
  const deleteHandler = (item) => {
    setshowConfirmationDialog(true);
    setdeleteId(item.phone_call_id);
  };

  /**
   * this function hides the delete confirmation dialog
   * @function
   * @inner
   * @memberof module:PastActivityPhoneSmsListViewModule
   */
  const cancelDelete = () => {
    setshowConfirmationDialog(false);
    setdeleteId();
  };

  /**
   * this function handle the delete confirmation and delete the sms
   * @function
   * @inner
   * @memberof module:PastActivityPhoneSmsListViewModule
   */
  const handleDelete = (item) => {
    if (type === PROSPECT) {
      deleteProspectPhoneCall({
        variables: {
          input: /* { prospect_id: pastActivitiesData.id, phone_call_id:*/ deleteId /* } */,
        },
      }).then((res) => {
        if (res.data.deleteProspectPhoneCall.code === 200) {
          onSubmit(res.data.deleteProspectPhoneCall.message);
          cancelDelete();
        }
      });
    } else if (type === LEAD) {
      deleteLeadPhoneCall({
        variables: {
          input: deleteId /* { lead_id: pastActivitiesData.id, phone_call_id: deleteId } */,
        },
      }).then((res) => {
        if (res.data.deleteLeadPhoneCall.code === 200) {
          onSubmit(res.data.deleteLeadPhoneCall.message);
          cancelDelete();
        }
      });
    } else {
      deleteHomeownerPhoneCall({
        variables: {
          input: deleteId /* { lead_id: pastActivitiesData.id, phone_call_id: deleteId } */,
        },
      }).then((res) => {
        if (res.data.deleteHomeownerPhoneCall.code === 200) {
          onSubmit(res.data.deleteHomeownerPhoneCall.message);
          cancelDelete();
        }
      });
    }
  };

  // This function is used to fetch the sms attachment from backend
  const handleFetchAttachment = () => {
    getSmsAttachment({
      session_id: pastActivitiesData.session_id,
      message_id: pastActivitiesData.phone_call_id
    }).then((res) => {
      if (res.data.getSmsAttachment.code === 200) {
        setDownloadUrl(res.data.getSmsAttachment.data[0]);
      }
    });
  };

  /**
   * this function renders the toggle part of the sms in past activities
   * @function
   * @inner
   * @memberof module:PastActivityWebformListViewModule
   * @param {object} item contains information related to the sms
   * @returns {JSX.Element}
   */
  const toggle = (item) => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <div
            className="d-flex align-items-center"
            style={{ marginRight: "2px" }}
          >
            {getIconByEvent(item.typeTitle)}
            <span style={{ fontSize: "12px", display: "d-flex" }}>
              {item.typeTitle}:
            </span>
          </div>
          {get5WordsForTitle(item.call_details)}
        </div>
        <div>
          <div className="d-flex align-items-center">
            {item.attachments?.length ?
              <FaPaperclip style={{ opacity: "0.4", marginRight: "0.5rem", marginTop: "-2px" }} />
              : null}
            <div className="toggle-date-time">
              {formatActivityDate(item.call_datetime)}
            </div>
            <ButtonToolbar>
              {type === PROSPECT ? (
                <ProspectCallButton
                  size="sm"
                  color="dark"
                  showButton
                  btnIcon={<FaPen size={14} />}
                  id={pastActivitiesData.id}
                  item={item}
                  function={"UPDATE"}
                  onSubmit={onSubmit}
                  className="icon-btn btn-icon"
                />
              ) : type === LEAD ? (
                <LeadCallButton
                  size="sm"
                  color="dark"
                  showButton
                  btnIcon={<FaPen size={14} />}
                  id={pastActivitiesData.id}
                  item={item}
                  function={"UPDATE"}
                  onSubmit={onSubmit}
                  className="icon-btn btn-icon"
                />
              ) : (
                <HomeownerCallButton
                  size="sm"
                  color="dark"
                  showButton
                  btnIcon={<FaPen size={14} />}
                  id={pastActivitiesData.id}
                  item={item}
                  function={"UPDATE"}
                  onSubmit={onSubmit}
                  className="icon-btn btn-icon"
                />
              )}
              <CustomButton
                size="sm"
                color="dark"
                btnIcon={<FaTrashAlt size={14} />}
                onClick={() => deleteHandler(item)}
                className="icon-btn btn-icon"
              />
            </ButtonToolbar>
          </div>
          {item.typeTitle === TEXT_OUT_ACTIVITY ? <div className="creator-name">
            Sent by:
            {item.creator ? ` ${item.creator.first_name + " " + item.creator.last_name}` : " -"}
          </div> : null}
        </div>
      </div>
    );
  };

  /**
   * this function renders the collapse part of the sms in past activities
   * @function
   * @inner
   * @memberof module:PastActivityWebformListViewModule
   * @param {object} item contains information related to the sms
   * @returns {JSX.Element}
   */
  const collapse = (item) => {
    return (
      <div className="accordion-body-inner">
        <p className="m-0 mb-1">{item.call_details}</p>
        <div className="d-flex attachment">
          {item.attachments?.length ? item.attachments.map((attachment, index) => (
            <div
              className="attachment-name"
              style={{ fontSize: '12px' }}
              key={index}
              onClick={() => {
                downloadUrl && window.open(downloadUrl)
              }}
            >
              {attachment.name}
            </div>
          )) : null}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (pastActivitiesData?.attachments?.length && props.isExpanded) {
      handleFetchAttachment()
    }
  }, [props.isExpanded])

  return (
    <div className="notes-card">
      <Dialog
        show={showConfirmationDialog}
        onHide={cancelDelete}
        title="Delete Confirmation"
        body="Are you sure you want to delete?"
        click={handleDelete}
        clickname="YES"
        closename="NO"
      />
      {props.render(toggle(pastActivitiesData), collapse(pastActivitiesData))}
    </div>
  );
};
export default compose(
  graphql(DELETE_PROSPECT_PHONE_CALL, {
    name: "deleteProspectPhoneCall",
  }),
  graphql(DELETE_LEAD_PHONE_CALL, {
    name: "deleteLeadPhoneCall",
  }),
  graphql(DELETE_HOMEOWNER_PHONE_CALL, {
    name: "deleteHomeownerPhoneCall",
  })
)(PastActivityPhoneSmsListView);
