import gql from "graphql-tag";

export default gql`
  query getAgenciesById($filter: agencyFilter) {
    getAgencies(filter: $filter) {
      agencies {
        name
        address
        city
        state {
          id
          name
        }
        zip
        Realtors{
            id
            first_name
            last_name
            primary_email
            primary_email_status
            cell_phone
        }
      }
    }
  }
`;
