import gql from "graphql-tag";

const ADD_PROSPECT_TO_EXISTING_CUSTOMER = gql`
  mutation addProspectsToExistingCustomer(
    $input: AddProspectsToExistingCustomerInput!
  ) {
    addProspectsToExistingCustomer(input: $input) {
      message
      code
      success
    }
  }
`;

export default ADD_PROSPECT_TO_EXISTING_CUSTOMER;
