import { graphql, compose } from "react-apollo";
import leadToMarketingOnlyForm from "./lead-to-marketing-only-form";
import { CONVERT_LEAD_TO_MARKETING_ONLY } from "../../../graphql/mutations";
import { GET_COMMUNITIES, GET_DIVISIONS } from '../../../graphql/queries'

const LeadToMarketingOnlyMutation = compose(
  graphql(CONVERT_LEAD_TO_MARKETING_ONLY, { name: "convertLeadToMarketingOnly" }),
  graphql(GET_COMMUNITIES, { name: "getCommunities" }),
  graphql(GET_DIVISIONS, { name: "getDivisions" }),
)(leadToMarketingOnlyForm);

export default LeadToMarketingOnlyMutation;