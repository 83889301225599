import gql from "graphql-tag";

const GET_HOMEOWNER_COUNT = gql`
    query getAllHomeOwners($input: HomeOwnersFilter){
        getAllHomeOwners(limit: 1, filter: $input){
            total_count
        }
    }
`;

export default GET_HOMEOWNER_COUNT;
