
import gql from 'graphql-tag'

const MAKE_PHONE_CALL = gql`
  mutation makePhoneCall($phoneNumber: String!, $device_id: ID) {
    makePhoneCall(phoneNumber: $phoneNumber, device_id: $device_id) {
      message
      code
    }
  }
`;

export default MAKE_PHONE_CALL