import React from "react";
import CustomButton from "../../../components/custom-button";
import Drawer from "../../../hoc/Drawer";
import { PROSPECT } from "../../../utils/constants"
import {
  AddProspectPhoneCallContainer,
  UpdateProspectPhoneCallContainer
} from "../../common-lead-prospect-forms/call-container";
import "./prospect-details.css";


/**
 * this component used to render the call button and the call drawer.
 * the create edit call drawer will open once the button is clicked or when its not showing the button with specified variable, 
 * then the drawer direcly opens once the component is called.
 */
class ProspectCallButton extends React.Component {
  /**
 * 
 * @param {*} props
 * @param {object} props.data provides all information related to the calls
 * @param {number} props.id specifies prospect id
 * @param {number} props.size specifies size of the button
 * @param {string} props.color specifies color of the button
 * @param {boolean} props.showButton specifies wether the button is shown of the drawer is called directly when this component is called.
 * @param {string} props.type specifies type of customer
 * @param {string} props.function specifies type of function if its create or edit
 * @param {string} props.btnValue specifies value of the button
 * @param {object} props.btnIcon specifies icon of the button
 */
  constructor(props) {
    super(props);
    this.state = {
      showSideBar: false
    };
    this.toggleShowDrawer = this.toggleShowDrawer.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  toggleShowDrawer() {
    if (!this.props.showButton) {
      this.props.close();
    } else {
      this.setState({ showSideBar: !this.state.showSideBar });
    }
  }

  onSubmit = message => {
    this.setState({
      showSideBar: false
    });
    if (!this.props.showButton) {
      this.props.close();
    }
    this.props.onSubmit(message);
  };
  render() {
    const className = this.props.className;
    return (
      <div>
        {this.props.showButton && (
          <CustomButton
            size={this.props.size}
            color={this.props.color}
            btnIcon={this.props.btnIcon}
            className={className}
            btnValue={this.props.btnValue}
            onClick={this.toggleShowDrawer}
          />
        )}
        <Drawer
          show={this.state.showSideBar || !this.props.showButton}
          toggleSideBar={this.toggleShowDrawer}
        >
          {this.props.function === "ADD" ? (
            <AddProspectPhoneCallContainer
              function={this.props.function}
              close={this.toggleShowDrawer}
              id={this.props.id}
              {...this.props}
              onSubmit={this.onSubmit}
            />
          ) : (
            <UpdateProspectPhoneCallContainer
              function={this.props.function}
              close={this.toggleShowDrawer}
              id={this.props.id}
              {...this.props}
              onSubmit={this.onSubmit}
            />
          )}
        </Drawer>
      </div>
    );
  }
}

export default ProspectCallButton;
