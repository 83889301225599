import React from "react";
import ContentGrid from "../../components/grid/content-grid";
import DashboardSelectBar from "../dashboard/dashboard-select-bar";
import ArchiveUsersTableView from './archive-users-table-container'

const ArchiveUsers = props => {
  return (
    <ContentGrid
      id="schedule-appointments-osc-report"
      selectbar={
        <DashboardSelectBar
          type="Admin"
          pageTitle="Archive Users"
          {...props}
        />
      }
      content={{
        data: <ArchiveUsersTableView/>
      }}
    />
  );
};
export default ArchiveUsers;
