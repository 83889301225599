import gql from 'graphql-tag';

const GET_AUTH_STATUS = gql`
  query {
    authStatus @client{
      status
    }
  }
`;


export default GET_AUTH_STATUS;