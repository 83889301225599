import React from "react";
import OverlayBackdrop from "./overlay-backdrop"

/**
 * It uses {@link OverlayBackdrop} to show hide backdrop and component
 * @param {Object} props 
 * @param {Boolean} props.show show/hide backdrop and component
 */
const OverlayWrapper = props => {
  return (
    <>
      {!props.show && <OverlayBackdrop {...props}/>}
      {props.component}
    </>
  );
};
export default OverlayWrapper;
