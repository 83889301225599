import gql from "graphql-tag";

const SET_TABLE_PARAMS = gql`
  mutation setTableParams($cardsParams: [CardState]) {
    setTableParams(cardsParams: $cardsParams) @client {
      cardsParams
    }
  }
`;

export default SET_TABLE_PARAMS;
