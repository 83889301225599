import gql from "graphql-tag";

export const SUB_NEW_REALTOR_REGISTRATION_COUNT = gql`
  subscription realtorRegistrationCount($division_id: Int) {
    realtorRegistrationCount(division_id: $division_id)
  }
`;

export const SUB_NEW_REALTOR_REGISTRATION_CHANGE = gql`
  subscription realtorRegistrationChange($registration_id: Int) {
    realtorRegistrationChange(registration_id: $registration_id)
  }
`;