import { graphql, compose } from "react-apollo";
import marketingOnlyToLeadForm from "./marketing-only-to-lead-form";
import { CONVERT_MARKETING_ONLY_TO_LEAD } from "../../../graphql/mutations";
import { GET_COMMUNITIES, GET_DIVISIONS } from '../../../graphql/queries'
import { SET_NOTIFICATION_PROPS } from "../../../cacheql/mutations";

const MarketingOnlyToLeadMutation = compose(
  graphql(CONVERT_MARKETING_ONLY_TO_LEAD, { name: "convertMarketingOnlyToLead" }),
  graphql(GET_COMMUNITIES, { name: "getCommunities" }),
  graphql(GET_DIVISIONS, { name: "getDivisions" }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" })
)(marketingOnlyToLeadForm);

export default MarketingOnlyToLeadMutation;