import React, { useEffect, useMemo, useContext, useRef } from "react";
import reducer, { getInitialState } from "../realtor-registrations-reducer";
import { DashboardContext } from "../../../context";
import { compose, graphql } from "react-apollo";
import { CreateEditRealtor } from "../realtor-form-container";
import { GET_REALTOR_REGISTRATION } from "../../../graphql/queries"
import { DELETE_REALTOR_REGISTRATION } from "../../../graphql/mutations";
import { FaUserPlus, FaTrashAlt, FaPlus } from "react-icons/fa";
import { SUB_NEW_REALTOR_REGISTRATION_CHANGE } from "../../../graphql/subscriptions";
import { GET_TABLE_PARAMS, GET_SELECTED_DIVISION } from "../../../cacheql/queries";
import { SET_NOTIFICATION_PROPS, SET_TABLE_PARAMS } from "../../../cacheql/mutations";
import CustomLoader from "../../../components/custom-loader";
import Dialog from "../../../components/dialog/dialog";
import Drawer from "../../../hoc/Drawer";
import FadeIn from "../../../components/fade-in";
import TableView, {
  SortableHeader,
  stringComparator,
  EmailCell,
  ActionCell,
  TextHeader,
  PhoneCell,
  iconTextCell,
  TextCellWithMapper,
  dateTimeComparator,
  TextCell
} from "../../../components/new-table";
import {
  formatActivityDate,
  getCardParams,
  getUpdatedParams,
  phoneDisplay,
} from "../../../utils/helpers";

/** @module RealtorTableModule */

/**
 * This component is a table used to render new realtor registrations table. It contains pagination, search bar and export to csv options.
 * It shows multiple columns displaying basic information related to new registrations. Table uses reducer to maintain its state. 
 * It also accesses store to check if table parameters are already stored or not.
 *
 * @param {Object} props it contains all the queries/mutations in the conatiner as props
 * @param {Object} props.data it contains array of new registrations to be shown on table
 * @param {Object} props.getTableParams it contains cards info stored inside cache
 * @returns {JSX.Element} It returns jsx containing the table
 */
const RealtorRegistrationsTable = ({
  data,
  markRead,
  deleteRealtorRegistration,
  getTableParams: { tableParams },
  ...props
}) => {

  const handler = useRef(false);
  const { currentCard } = useContext(DashboardContext);
  const cacheParams = getCardParams(currentCard.uniqueTitle, tableParams);
  const [state, dispatch] = React.useReducer(
    reducer,
    getInitialState({ loading: data.loading, cache: cacheParams })
  );
  const {
    showRealtorBar,
    realtors,
    registrationDetail,
    loading,
    render,
    userPageCount,
    pageSize,
    isSubscription,
    showDeleteConfirmation,
    delete_reg_id,
  } = state;


  const callDispatch = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };


  /**
   * updates the data in state based on subscribtion
   */
  useEffect(() => {
    handler.current = data.subscribeToMore({
      document: SUB_NEW_REALTOR_REGISTRATION_CHANGE,
      variables: { registration_id: null },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) return prev;
        callDispatch("UPDATE", { isSubscription: true, loading: true });
        return prev;
      },
    });
    return () => {
      handler.current && handler.current();
      handler.current = false;
    };
  }, [props.currentCard.uniqueTitle]);

  /** Refetch table data on any update */
  useEffect(() => {
    if (isSubscription || props.updated !== null) {
      callDispatch("UPDATE", { isSubscription: false });
      data.refetch();
    }
  }, [isSubscription, props.updated]);

  /**
   * Either set the data in state or set the loading to inform the table what to render.
   */
  useEffect(() => {
    if (
      !data.loading &&
      data.getRealtorRegistrations &&
      data.getRealtorRegistrations.realtor_registration
    )
      callDispatch("UPDATE", {
        render: true,
        loading: false,
        realtors: data.getRealtorRegistrations.realtor_registration,
        userPageCount: Math.ceil(
          data.getRealtorRegistrations.total_count / pageSize
        ),
      });
    if (data.loading) callDispatch("UPDATE", { loading: data.loading });
  }, [data]);

  const setShowRealtorBar = (toSetValue) =>
    callDispatch("UPDATE", { showRealtorBar: toSetValue });

  const detailsHandler = (data) => {
    const name = data.name ? data.name.split(" ") : "";
    let realtor = {
      first_name: name ? name[0] : "",
      last_name: name ? name[1] : "",
      primary_email: data.email ?? null,
      cell_phone: data.phone ?? null,
      division: data.division ?? null,
      note: data.note ?? null,
      id: data.realtor_id,
      realtor_registration_id: data.id
    };
    callDispatch("UPDATE", {
      showDetailsBar: true,
      registrationDetail: realtor,
      showRealtorBar: true
    });
  };

  const deleteConfirmationBody = () => {
    return (
      <div>
        <p className="mb-0">Are you sure you want to delete this record?</p>
      </div>
    );
  };

  const handleDelete = (itemId) => {
    dispatch({
      type: "UPDATE",
      payload: {
        showDeleteConfirmation: !showDeleteConfirmation,
        delete_reg_id: itemId,
      },
    });
  };

  const hideDeleteDialog = () => {
    dispatch({
      type: "UPDATE",
      payload: { showDeleteConfirmation: !showDeleteConfirmation },
    });
  };

  const handleDeleteConfirmation = () => {
    dispatch({
      type: "UPDATE",
      payload: { showDeleteConfirmation: !showDeleteConfirmation },
    });
    deleteRealtorRegistration({
      variables: { realtor_registration_id: delete_reg_id },
    }).then((res) => {
      if (res.data.deleteRealtorRegistration.code === 200) {
        props.setNotificationProps({
          variables: {
            open: true,
            message: res.data.deleteRealtorRegistration.message,
          },
        });
      }
    });
  };

  /**
   * This defines columns of the table. It has multiple columns, displaying information of the new registrations. 
   * @constant
   * @memberof module:RealtorTableModule
   */
  const columns = useMemo(
    () => [
      {
        id: "0",
        header: "",
        accessor: "",
        component: TextHeader,
        cell: {
          component: iconTextCell,
          action: detailsHandler,
          Icon: FaUserPlus,
          check: (field) => !field["realtor_id"],
          displayName: () => { }
        },
      },
      {
        id: "1",
        header: "Realtor Name",
        accessor: "name",
        sortName: "name",
        component: SortableHeader,
        comparator: stringComparator,
        cell: {
          component: TextCell,
          displayName: (field, accessor) => field[accessor]
        },
      },
      {
        id: "2",
        header: "Realtor Email",
        accessor: "email",
        sortName: "email",
        component: SortableHeader,
        comparator: stringComparator,
        cell: { component: EmailCell },
      },
      {
        id: "3",
        header: "Realtor Phone",
        accessor: "phone",
        component: TextHeader,
        sortable: false,
        csvMapper: (field, accessor) => phoneDisplay(field[accessor]) || "",
        cell: { component: PhoneCell },
      },
      {
        id: "4",
        header: "Realtor Note",
        accessor: "note",
        component: TextHeader,
        csvMapper: (field, accessor) =>
          field[accessor][field[accessor].length - 1]
            ? field[accessor][field[accessor].length - 1].replace(
              /<br>/gm,
              ""
            )
            : "",
        cell: {
          component: TextCell,
          mapper: (field, accessor) =>
            field[accessor][field[accessor].length - 1] &&
            field[accessor][field[accessor].length - 1].replace(
              /<br>/gm,
              ""
            ),
          check: (field, accessor) => field[accessor].length > 1,
        },
      },
      {
        id: "5",
        header: "Brokerage Name",
        component: TextHeader,
        accessor: "brokerage_name",
        cell: { component: TextCell },
      },
      {
        id: "6",
        header: "Registration Date",
        accessor: "createdAt",
        component: SortableHeader,
        comparator: dateTimeComparator,
        csvMapper: (field, accessor) => formatActivityDate(field[accessor]),
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) => formatActivityDate(field[accessor]),
        },
      },
    ]
  );

  /**
   * Array of objects containing different actions which can be performed on row hover.
   * @constant
   * @memberof module:RealtorTableModule
   */
  const hoverActions = useMemo(
    () => [
      {
        tooltip: "Add/Update Realtor",
        icon: FaPlus,
        component: ActionCell,
        action: detailsHandler,
      },
      {
        tooltip: "Delete",
        icon: FaTrashAlt,
        component: ActionCell,
        action: ({ id }) => {
          handleDelete(id);
        },
      }
    ],
    []
  );

  /**
   * This is an object that defines table title.
   *
   * @constant
   * @memberof module:RealtorTableModule
   */
  const tableOptions = {
    title: currentCard.uniqueTitle,
  };

  /**
   * It is a callback function which is called on any table change like: pagination, page-size,search term.
   * Table passes its current state so that we can update the table parameters in store and state, which are used in
   * refetching the data with correct parameters.
   *
   * @function
   * @inner
   * @memberof module:RealtorTableModule
   * @see {@link module:RealtorTableModule~RealtorTable}
   */
  const fetchData = React.useCallback((tableProps) => {
    if (loading) {
      return;
    }

    const columnItem = columns.find(
      (item) => item.accessor === tableProps.sortColumn.name
    );

    const variables = {
      search: tableProps.searchTerm,
      limit: tableProps.pageSize,
      pageNum: tableProps.currentPage,

      order: {
        id: tableProps.sortColumn.id,
        name: (columnItem && columnItem.sortName) || tableProps.sortColumn.name,
        sort: tableProps.sortColumn.sort,
      },
    };

    if (tableProps.searchTerm !== state.search) {
      variables.pageNum = 0;
    }

    callDispatch("UPDATE", {
      ...variables,
      pageSize: tableProps.pageSize,
      loading: true,
    });

    const pageCount = Math.ceil(
      data.getRealtorRegistrations.total_count / tableProps.pageSize
    );
    if (pageCount !== userPageCount)
      callDispatch("UPDATE", {
        userPageCount: pageCount,
      });

    props
      .setTableParams({
        variables: {
          cardsParams: getUpdatedParams(tableParams.cardsParams, {
            cardTitle: currentCard.uniqueTitle,
            params: variables,
          }),
        },
      })
      .then(() => {
        dispatch({ type: "UPDATE", payload: { loading: false } });
      });
  });

  return (
    <>
      <Dialog
        show={showDeleteConfirmation}
        onHide={hideDeleteDialog}
        title="Delete Confirmation"
        body={deleteConfirmationBody()}
        click={handleDeleteConfirmation}
        clickname="YES"
        closename="NO"
      />
      <FadeIn show={render}>
        <TableView
          columns={columns}
          data={realtors}
          tableOptions={tableOptions}
          loading={loading}
          hoverActions={hoverActions}
          userPageCount={userPageCount}
          userTotalCount={
            !loading && data.getRealtorRegistrations
              ? data.getRealtorRegistrations.total_count
              : null
          }
          fetchData={fetchData}
          currentPage={state.pageNum}
          pageSize={state.pageSize}
          searchTerm={state.search}
          sortColumn={state.order}
          controlled={true}
        />
      </FadeIn>
      {!render && <CustomLoader />}

      <Drawer
        show={showRealtorBar}
        toggleSideBar={() => setShowRealtorBar(false)}
      >
        <CreateEditRealtor
          realtor={registrationDetail}
          onSubmit={() => {
            setShowRealtorBar(false);
            props.update();
          }}
          close={() => setShowRealtorBar(false)}
        />
      </Drawer>
    </>
  );
};

export const NewRealtorRegistrationsTable = compose(
  graphql(GET_SELECTED_DIVISION, { name: "selectedDivision" }),
  graphql(SET_TABLE_PARAMS, { name: "setTableParams" }),
  graphql(GET_TABLE_PARAMS, { name: "getTableParams" }),
  graphql(GET_REALTOR_REGISTRATION, {
    options: ({
      selectedDivision,
      currentCard: { uniqueTitle },
      getTableParams: { tableParams }
    }) => {
      let params = getCardParams(uniqueTitle, tableParams);
      return {
        variables: {
          pageNum: (params && params.pageNum) || 0,
          limit: (params && params.limit) || 10,
          sort:
            params && params.order && params.order.id !== -1
              ? [params.order.name, params.order.sort]
              : null,
          search: (params && params.search) || "",
          filter: {
            division_id: selectedDivision.selectedDivision.id,
          },
        },
        fetchPolicy: "cache-and-network",
      };
    },
  }),
  graphql(DELETE_REALTOR_REGISTRATION, { name: "deleteRealtorRegistration" }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" })
)(RealtorRegistrationsTable);
