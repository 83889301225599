import * as Yup from "yup";
import * as errorMessages from "./form-errors";

export const leadFilterForm = {
  customer_origination_area: { id: "" },
  state: { id: "" },
  cell_phone_1: "",
  other_notes: "",
  created_at: [null, null],
  updated_at: [null, null],
  filter_name: "",
  advanceFilters: false
};

export const leadFilterSchema = Yup.object().shape({
  customer_origination_area: Yup.object().shape({
    id: Yup.string()
      .default("Select...")
      .nullable()
  }),
  state: Yup.object().shape({
    id: Yup.string()
      .default("Select...")
      .nullable()
  }),
  id: Yup.string()
    .default("Select...")
    .nullable(),
  cell_phone_1: Yup.string().nullable(),
  other_notes: Yup.string().nullable(),
  filter_name: Yup.string()
    .trim()
    .required(errorMessages.REQUIRE_FIELD)
});
