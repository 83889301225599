
import gql from 'graphql-tag';

const DELETE_PROSPECT = gql` 
  mutation deleteProspect($input: deleteProspectInput!) {
    deleteProspect(input: $input) {
      message
      code
    }
  }
`;

export default DELETE_PROSPECT;