import { compose, graphql } from "react-apollo";
import { GET_ALLRANKINGS } from "../../../graphql/queries";
import { UpcomingFilterFormIkForm } from "./upcoming-filters";
import withUserContext from "../../../hoc/with-user-context";

const UpcomingFilter = compose(
  graphql(GET_ALLRANKINGS, { name: "getAllRankings" }),
)(UpcomingFilterFormIkForm);

export default withUserContext(UpcomingFilter);
