import React, { useState, useEffect } from "react";
import CreateProspectForm from "../forms/create-update-prospect-container";
import Drawer from "../../../hoc/Drawer";
import { compose, graphql } from "react-apollo";
import { GET_PROSPECT_ON_EDIT } from "../../../graphql/queries";
import "./prospect-dashboard.css";

// this is called for recover and convert to lead from webform drawer!

/**
 *@module  EditProspectModule
 */
/**
 * this component is used to render the edit prospect drawer which contains the create edit prospect form and it takes the data and prepares the appropriate format to be passed to the create edit form.
 * @param {*} props
 * @param {object} props.prospectsData contains information of the prospect
 * @param {function} props.close closes the drawer
 * @param {boolean} props.setShowEdit boolean that defines wether to show the drawer or not
 */
const EditProspect = ({ prospectsData, setShowEdit, ...props }) => {
  const [showSideBar, setShowSideBar] = useState(false);
  const [redFlagUpdated, setRedFlagUpdated] = useState(false);
  const [data, setData] = useState([]);
  const [inActiveCsm, setInActiveCsm] = useState(null);
  const [inActiveCommunities, setInactiveCommunities] = useState([]);

  const toggleShowDrawer = () => {
    props.close && props.close();
    setShowEdit && setShowEdit(false);
    setShowSideBar(false);
    if (redFlagUpdated) onSubmit();
  };

  const onSubmit = () => {
    setShowSideBar(false);
    props.submitHandler();
  };

  /**
* this function prepares the data to be passed to the prospect form, it takes the prospect information and puts it in an appropriate prospect format.
* @param {object} data contains information of the prospect
* @function
* @inner
* @memberof module:EditProspectModule
* @returns {object}
*/
  const formatData = () => {
    let dataToReturn = (({
      first_name_1,
      last_name_1,
      primary_email_1,
      cell_phone_1,
      secondary_emails_1,
      first_name_2,
      last_name_2,
      primary_email_2,
      cell_phone_2,
      secondary_emails_2,
      city,
      state,
      customer_origination_area,
      zip,
      street_address,
      id,
      realtor,
      red_flag,
      division,
      osc_notes,
      primary_email_1_status,
      primary_email_2_status
    }) => {
      return {
        first_name_1,
        last_name_1,
        primary_email_1: primary_email_1,
        disable_primary_email_1: !primary_email_1_status,
        cell_phone_1,
        secondary_emails_1,
        first_name_2,
        last_name_2,
        primary_email_2: primary_email_2,
        disable_primary_email_2: !primary_email_2_status,
        cell_phone_2,
        secondary_emails_2,
        city,
        zip,
        street_address,
        id,
        realtor_id: realtor ? realtor.id : undefined,
        customer_origination_area_id:
          (customer_origination_area && customer_origination_area.id) ||
          undefined,
        state_id: state && state.id ? state.id : undefined,
        red_flag: red_flag && red_flag.description,
        division_id: division && division.id,
        osc_notes
      };
    })(data);

    dataToReturn.Prospects = (({
      community,
      floor_plans,
      others,
      home_sites,
      rank,
      csm,
      id
    }) => {
      return [
        {
          community_id: community && community.id && community.is_active ? `${community.id}__${community.division_id}` : "",
          floor_plans: floor_plans && floor_plans.map(item => item.id),
          other_dropdown_values: others && others.map(item => item.id),
          home_sites: home_sites && home_sites.map(item => item.id),
          rank_id: rank && rank.id ? rank.id : undefined,
          csm_id: csm && csm.id && csm.is_active ? csm.id : "",
          id,
          add_appointment: false,
          Prospect_Appointments: {
            title: "",
            details: "",
            appointment_datetime: new Date(),
            start_datetime: new Date(),
            end_datetime: new Date(),
            csm_id: null
          }
        }
      ];
    })(data);
    dataToReturn.floor_plans =
      data.floor_plans && data.floor_plans.length > 0 ? data.floor_plans : null;
    dataToReturn.other_dropdown_values =
      data.others && data.others.length > 0 ? data.others : null;
    dataToReturn.home_sites =
      data.home_sites && data.home_sites.length > 0 ? data.home_sites : null;
    dataToReturn.others = (({
      next_step,
      next_step_updated_at,
      other_notes,
      other_notes_updated_at,
      future_promise,
      motivation_what_changed,
      motivation_uncovered,
      cost_feat_objections,
      current_dissatisfaction,
      reservation,
      video_text
    }) => {
      return {
        next_step,
        next_step_updated_at,
        other_notes,
        other_notes_updated_at,
        future_promise,
        motivation_what_changed,
        motivation_uncovered,
        cost_feat_objections,
        current_dissatisfaction,
        reservation,
        video_text
      };
    })(data);

    return dataToReturn;
  };

  useEffect(() => {
    if (
      !prospectsData.loading &&
      prospectsData.getProspects &&
      prospectsData.getProspects.Prospects[0]
    ) {
      const { community, csm } = prospectsData.getProspects.Prospects[0]
      !community.is_active && setInactiveCommunities([community])
      csm && !csm.is_active && setInActiveCsm(csm)
      setData(prospectsData.getProspects.Prospects[0]);
      setShowSideBar(true);
    }
  }, [prospectsData]);

  return (
    <Drawer show={showSideBar} toggleSideBar={toggleShowDrawer}>
      <CreateProspectForm
        close={toggleShowDrawer}
        data={formatData()}
        id={props.id}
        onSubmit={onSubmit}
        refetchEditData={() => setRedFlagUpdated(true)}
        editForm={true}
        inActiveCommunities={inActiveCommunities}
        inActiveCsm={inActiveCsm}
        isDetails={props.isDetails}
        history={props.history}
      />
    </Drawer>
  );
};

const EditProspectView = compose(
  graphql(GET_PROSPECT_ON_EDIT,
    {
      name: "prospectsData",
      options: props => {
        return {
          variables: { filter: { id: props.id } },
          fetchPolicy: "network-only"
        };
      }
    })
)(EditProspect);

export default EditProspectView;
