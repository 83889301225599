import gql from "graphql-tag";

const GET_COMMUNITY_BY_ID = gql`
    query getCommunityById($id: Int!) {
        getCommunityById(id: $id) {
            id
            name
            description
        }
    }
`;

export default GET_COMMUNITY_BY_ID;
