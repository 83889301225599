import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";
import "whatwg-fetch"; //importing fetch polyfill

import "bootstrap/dist/css/bootstrap.css";
import * as serviceWorker from "./app/serviceWorker";
import App from "./app/App";
import client from "./app/apollo-client";

import { CookiesProvider } from 'react-cookie';

ReactDOM.render(
  <ApolloProvider client={client}>
    <ApolloHooksProvider client={client}>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </ApolloHooksProvider>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
