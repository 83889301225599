import React, { useEffect, useMemo, useState } from "react";
import { compose, graphql } from "react-apollo";

import TableView, {
  buyerTwoIconCellObject,
  DetailLinkCell,
  EmailCell,
  includes,
  PhoneCell,
  stringComparator,
  TextCellWithMapper,
  TextHeader,
} from "../../components/new-table";
import FadeIn from "../../components/fade-in";
import { phoneDisplay } from "../../utils/helpers";
import routes_constants from "../layout/routes";
import { GET_HOME_OWNERS } from "../../graphql/queries";
import { FaWarehouse } from "react-icons/fa";

/** @module GlobalHomeownersTableModule */
/**
 * This component is a table used to display homeowners data based on the global search query. It contains only the top 5 results and further specific query needs to be made
 * to get the appropriate data. It also has a basic hover action on Name column which redirect to the homeowner's details.
 *
 * @param {Object} props this contains the data from the graphql query and the function to close the drawer
 * @param {String} props.searchTerm it contains the search query from the GlobalSearchDetails component
 * @param {Function} props.handleDrawerClose function to close the drawer
 * @param {Object} props.data it contains array of prospects to be shown on table
 * @returns {JSX.Element} It returns jsx containing the table title and the table itself
 */
const GlobalHomeownersTable = ({ searchTerm, handleDrawerClose, data }) => {
  const [loading, setLoading] = useState(true);
  const [render, setRender] = useState(false);
  const [Homeowners, setHomeowners] = useState({});

  /**
   * Either set the data in state or set the loading to inform the table what to render.
   */
  useEffect(() => {
    if (
      !data.loading &&
      data.getAllHomeOwners &&
      data.getAllHomeOwners.Home_Owner
    ) {
      setLoading(false);
      setRender(true);
      setHomeowners(data.getAllHomeOwners.Home_Owner);
    }
    if (data.loading) {
      setLoading(data.loading);
    }
  }, [data]);

  /**
   * Columns of the table containing a column to show an icon in case there is a second buyer, name, community, phone and email.
   * @constant
   * @memberof module:GlobalHomeownersTableModule
   */
  const columns = useMemo(() => [
    {
      id: "0",
      header: "",
      accessor: "",
      component: TextHeader,
      cell: buyerTwoIconCellObject,
    },
    {
      id: "1",
      header: "Name",
      accessor: "first_name_1",
      component: TextHeader,
      sortable: false,
      cell: {
        component: DetailLinkCell,
        path: (field, accessor) => routes_constants.HOME_OWNER_DETAIL,
        mapper: (field, accessor) =>
          field[accessor] + " " + field["last_name_1"],
        action: handleDrawerClose
      },
    },
    {
      id: "2",
      header: "Community",
      accessor: "community",
      component: TextHeader,
      sortable: false,
      csvMapper: (field, accessor) => field[accessor].name,
      cell: {
        component: TextCellWithMapper,
        mapper: (field, accessor) => field[accessor].name,
        check: true,
      },
    },
    {
      id: "3",
      header: "Phone",
      accessor: "cell_phone_1",
      component: TextHeader,
      sortable: false,
      filter: includes,
      csvMapper: (field, accessor) => phoneDisplay(field[accessor]) || "",
      cell: { component: PhoneCell },
    },
    {
      id: "4",
      header: "Email",
      accessor: "primary_email_1",
      component: TextHeader,
      sortable: false,
      comparator: stringComparator,
      cell: {
        component: EmailCell,
        check: "primary_email_1_status",
      },
    },
  ]);

  return (
    <div>
      <div className="d-flex header-table">
        <FaWarehouse className="header-icon" />
        <div className="d-flex align-self-center header-text">
          Homeowners {!Homeowners.length && "(No records Found)"}
        </div>
      </div>
      {Homeowners.length ? (
        <div className="results-table-search">
          <FadeIn show={render}>
            <TableView
              columns={columns}
              data={Homeowners}
              loading={loading}
              currentPage={0}
              isGlobalSearch={true}
              controlled={true}
            />
          </FadeIn>
        </div>
      ) : null}
    </div>
  );
};

export default compose(
  graphql(GET_HOME_OWNERS, {
    options: ({searchTerm}) => {
      return {
        variables: {
          filter: {
            community_ids: [],
            division_id: null,
          },
          pageNum: 0,
          limit: 5,
          sort: null,
          search: searchTerm || "",
        },
        fetchPolicy: "cache-and-network",
      };
    },
  })
)(GlobalHomeownersTable);
