import React, { useState, useRef, useEffect } from "react";
import { Button, OverlayTrigger, Overlay, Tooltip } from "react-bootstrap";

import { FaFlag } from "react-icons/fa";
import { isNotNULL } from "../../utils/helpers";
import "./flag.css";

/**
 * This is a button with flag icon. On clicking the button a form opens up in a popover in which you can add description and submit the form. After submitting, button is colored red and description starts to show up in the tooltip.
 * @param {Object} props 
 * @param {JSX.Element} props.FlagForm form to show inside flag popover'
 * @param {Number} props.id id of the user who is being flagged
 * @param {String} props.red_flag description of flag 
 * @param {Function} props.refetchEditData callback function called when form is submitted
 */
export default function TableExtras(props) {
  const { FlagForm, id, red_flag, refetchEditData } = props;

  const [description, setDescription] = useState(null);
  const [show, setShow] = useState(false);

  // if flag is marked red, then show the button as red colored with a tooltip containing description
  const [isRedFlag, setIsRedFlagIcon] = useState(false);
  const target = useRef(null);

  const refetchData = desc => {
    setDescription(desc);
    refetchEditData();
  };
  const flagButton = () => {
    return (
      <Button
        ref={target}
        variant="secondary"
        onClick={() => {
          setShow(true);
        }}
        className="flag-btn"
      >
        <FaFlag
          className={isRedFlag ? "red-flag-icon" : "flag-icon"}
        />
      </Button>
    );
  };
  useEffect(() => {
    if (description === null && isNotNULL(red_flag)) {
      setIsRedFlagIcon(true);
    }
    if (description !== null) {
      setIsRedFlagIcon(true);
    }
    if (description === "") {
      setIsRedFlagIcon(false);
    }
  }, [description]);
  return (
    <>
      {isRedFlag ? (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip style={{ zIndex: 2022 }}>
              {description !== null ? description : red_flag}
            </Tooltip>
          }
        >
          {flagButton()}
        </OverlayTrigger>
      ) : (
          flagButton()
        )}

      <Overlay
        target={target.current}
        show={show}
        rootClose={false}
        onHide={() => {
          setShow(false);
        }}
        placement="bottom-end"
      >
        {({ arrowProps, outOfBoundaries, ...props }) => (
          <div
            className="p-3"
            {...props}
            style={{
              backgroundColor: "#191b1d",
              border: "1px solid black",
              borderRadius: 3,
              boxShadow: "0 0 20px black",
              zIndex: 2022,
              ...props.style
            }}
          >
            <FlagForm
              setShow={setShow}
              id={id}
              red_flag={description !== null ? description : red_flag}
              refetchEditData={refetchData}
            />
          </div>
        )}
      </Overlay>
    </>
  );
}
