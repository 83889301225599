import React, { useContext } from "react";

import HomeownersTable from "./homeowners-container";
import DashboardContextProvider from "../../../context/dashboard-context-provider";
import {
  GET_DASHBOARD_HOMEOWNERS
} from "../../../graphql/queries";
import { Auth } from "../../../rbac/rbac";

const activityCards = [
  {
    title: "Sold Not Started",
    filter: { stage_id: 1 }
  },
  {
    title: "Under Construction",
    filter: { stage_id: 2 }
  },
  {
    title: "Settled",
    filter: { stage_id: 3 }
  },
  { title: "All Homeowners", filter: {}, isDetail: true }
];


/**
 * This component is used to render Homeowner dashboard with 4 cards and a table. Each card has same query and its 
 * own filter. All the tables render the same table with data change only. This dahsboard contains community 
 * dropdown and division dropdown is there for manager role only. By default All Homeowners card is preselected.
 * @param {*} props 
 */
const HomeOwnerDashboard = props => {
  const { is_manager, has_multiple_divisions } = useContext(Auth);

  return (
    <DashboardContextProvider
      type="Homeowners"
      text="New Homeowner"
      startingDefaultCard={activityCards[3]}
      dataQuery={GET_DASHBOARD_HOMEOWNERS}
      activityCards={activityCards}
      tableView={<HomeownersTable />}
      withCommunity={true}
      withDivision={is_manager() || has_multiple_divisions()}
      {...props}
    />
  );
};

export default HomeOwnerDashboard;
