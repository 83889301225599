import { defaults } from "codemirror";
import gql from "graphql-tag";

const GET_ALL_CUSTOMER_ORIGINATION_AREAS = gql`
  {
    getAllCustomer_Origination_Areas {
      id
      name
    }
  }
`;

export default GET_ALL_CUSTOMER_ORIGINATION_AREAS