import React from "react";
import { Query } from "react-apollo";

export const DefaultQuery = ({
  query,
  variables = {},
  children,
  fetchPolicy,
  skip
}) => (
  <Query
    query={query}
    variables={variables}
    fetchPolicy={fetchPolicy}
    skip={skip}
  >
    {({ loading, error, data, fetchMore, refetch, subscribeToMore }) => {
      return children({
        data,
        loading,
        error,
        fetchMore,
        refetch,
        subscribeToMore
      });
    }}
  </Query>
);
