
import { graphql, compose } from 'react-apollo';
import ProspectForm from './create-update-prospect'
import withUserContext from '../../../hoc/with-user-context'
import { CREATE_PROSPECT, CREATE_PROSPECT_DUPLICATE, UPDATE_PROSPECT } from '../../../graphql/mutations'
import { GET_CUSTOMER_ORIGINATION_AREA, GET_ALLRANKINGS, GET_ALL_STATES, GET_DIVISIONS, GET_LOGGED_IN_COMMUNITIES, GET_APPOINTMENT_TYPES } from "../../../graphql/queries";
import { GET_TITLE_CUSTOMIZATIONS } from "../../../cacheql/queries";
import { SET_NOTIFICATION_PROPS } from '../../../cacheql/mutations'


const CreateProspectWithMutation = compose(
  graphql(CREATE_PROSPECT, { name: "addProspect" }),
  graphql(CREATE_PROSPECT_DUPLICATE, { name: "addProspectDuplicate" }),
  graphql(GET_LOGGED_IN_COMMUNITIES, { name: "getLoggedInCommunities" }),
  graphql(GET_DIVISIONS, { name: "getAllDivisions" }),
  graphql(GET_ALL_STATES, { name: "getAllStates" }),
  graphql(GET_ALLRANKINGS, { name: "getAllRankings" }),
  graphql(UPDATE_PROSPECT, { name: "updateProspect" }),
  graphql(GET_CUSTOMER_ORIGINATION_AREA, { name: "getOriginationAreas" }),
  graphql(GET_TITLE_CUSTOMIZATIONS, { name: "getTitleCustomizations" }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" }),
  graphql(GET_APPOINTMENT_TYPES, { name: "getAllProspectAppointmentTypes" }),
)(ProspectForm);

export default withUserContext(CreateProspectWithMutation);