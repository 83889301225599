import React from "react";

import * as actionTypes from "./action-types";

/**
 * this is a custom hook that handles updates tables states with every action trigger for pagination,
 * sorting, searching, applying filters and row selections.
 * @param {*} props 
 * @param {object} state 
 * @param {function} dispatch 
 */
export default function useTableUpdate(props, state, dispatch) {
  const { searchTerm, pageSize, currentPage, filter, sortColumn } = state;

  //it is used to avoid useEffect function call on first load
  const firstLoad = React.useRef(true);

  //This hook is for getting data from back-end
  //it gets called on page table actions
  React.useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      return;
    } 

    state.fetchData && state.fetchData(state);

  }, [pageSize, currentPage, JSON.stringify(sortColumn), searchTerm, JSON.stringify(filter)]);

  //This for updating table when we get data from back-end
  React.useEffect(() => {
    dispatch({ type: actionTypes.UPDATE, payload: { state: props } });
  }, [props.data]);
}
