import gql from 'graphql-tag';

const GET_AVAILABLE_OTHERS = gql`
query  getAvailableOthersById($community_ids: [Int!]){
  getAvailableOther_Dropdown_Values(community_ids: $community_ids) {
  	id
    name
  }
 }
`;
export default GET_AVAILABLE_OTHERS;
