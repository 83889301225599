import { compose, graphql } from "react-apollo";

import { GET_CONVERSION_REPORT } from "../../graphql/queries";
import { GET_TABLE_PARAMS, GET_DATE_RANGE } from "../../cacheql/queries";
import { SET_TABLE_PARAMS } from "../../cacheql/mutations";
import { getCardParams } from "../../utils/helpers";
import ConversionReportTable from "./conversion-report-table";

export default compose(
  graphql(SET_TABLE_PARAMS, { name: "setTableParams" }),
  graphql(GET_TABLE_PARAMS, { name: "getTableParams" }),
  graphql(GET_DATE_RANGE, { name: "selectedDateRange" }),
  graphql(GET_CONVERSION_REPORT, {
    options: ({ getTableParams: { tableParams }, ...props }) => {
      const params = getCardParams("Conversion Report", tableParams);
      return {
        variables: {
          filter: {
            creator_ids: props.selectedUserIds.ids,
            conversion_start_datetime: props.selectedDateRange.selectedDateRange.startDate,
            conversion_end_datetime: props.selectedDateRange.selectedDateRange.endDate,
            ...(params && params.filter)
          },
          pageNum: (params && params.pageNum) || 0,
          limit: (params && params.limit) || 10,
        },
        fetchPolicy: "cache-and-network"
      };
    }
  })
)(ConversionReportTable);
