import React from "react";
import { default as ReactSelect } from "react-select";

/**
 * This component is being used in to show selected items, it uses react select to show to the data without creating
 * a dropdown.
 * 
 * It accepts a list of objects containing id and name property to display list in the dropdown.
 * @param {Object} props
 * @param {Array} props.options list of objects to display in dropdown
 * @param {Object} props.defaultValue value to be displayed by default
 * @param {Object} props.placeholder default text to be displayed in case there is no
 * @param {Boolean} props.isSearchable enables select field to be searchable
 * @param {Function} props.onChange callback function to perform action when a selection is removed from the list
 * @param {Function} props.onClick function to run when the action button is clicked
 */
const Select = ({
  options,
  defaultValue,
  placeholder,
  styles,
  isSearchable,
  onChange,
  onClick,
  ...props
}) => {

  const style = {
    border: "1px solid #242527",
    backgroundColor: "#121415",
    outline: "none",
  };

  //custom styles of single select
  const customStyles = {
    control: provided => ({
      ...provided,
      minHeight: '35px',
      ...style,
    }),
    menuList: provided => ({
      ...provided,
      backgroundColor: "black",
      border: "1px solid #292929",
      borderRadius: 4,
    }),
    singleValue: provided => ({
      ...provided,
      color: "white",
      padding: "0 0 2px 0"
    }),
    option: (provided) => ({
      ...provided,
      color: 'inherit',
      backgroundColor: "black !important",
      "&:hover": {
        backgroundColor: "#333",
        color: "#80b602",
        cursor: "pointer",
      }
    }),
    input: provided => ({ ...provided, color: "white" }),
    indicatorContainer: provided => ({ ...provided, padding: "5px" }),
    indicatorSeparator: () => ({ display: "none" }),
  };

  return (
    <ReactSelect
      options={options}
      onChange={onChange}
      onClick={onClick}
      defaultValue={defaultValue}
      isSearchable={isSearchable}
      placeholder={placeholder}
      styles={customStyles}
      {...props}
    />
  )
};

export default Select;