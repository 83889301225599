import React, { useState, useEffect } from 'react';
import { DateRangePicker } from "react-dates";
import { FaTimes } from 'react-icons/fa';
import { cloneDeep } from 'lodash';
import moment from "moment";
import { getCreateDateFilters } from "../../utils/helpers";

import './style.css';
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

/**
 * It uses DateRangePicker to display a popup with multiple months that enables the user to 
 * select a range of dates.
 * Shortcuts are also added for quick selections
 * @param {Object} props 
 * @param {Date} props.startDate initial start date
 * @param {Date} props.endDate initial end date
 * @param {Function} props.onChange on change handler to set new selected date
 */


const DateRange = (props) => {
  // sets # of months to display in range picker according to screen size
  const months = window.matchMedia('(max-width: 1024px)').matches ? 1 : 2

  const [date, setDate] = useState({
    startDate: props.startDate ? moment(props.startDate) : null,
    endDate: props.endDate ? moment(props.endDate) : null
  })
  const [focusedInput, setFocusedInput] = useState(null)

  const onChange = ({ startDate, endDate }) => {
    // IMPORTANT: cloning is necessary in case of manually selecting same start and end dates
    const sDate = cloneDeep(startDate)
    const eDate = cloneDeep(endDate)

    sDate && sDate.set('hour', 0).set('minute', 0).set('second', 0)
    eDate && eDate.set('hour', 23).set('minute', 59).set('second', 59)

    setDate({
      startDate: sDate,
      endDate: eDate
    })
  }

  const filterDate = (filterType) => {
    const date = getCreateDateFilters(filterType);
    setFocusedInput(null)

    onChange({
      startDate: moment(date.create_start_date, 'YYYY/MM/DD'),
      endDate: moment(date.create_end_date, 'YYYY/MM/DD')
    })
  }

  useEffect(() => {
    props.onChange(date.startDate, date.endDate)
  }, [date])

  return (

    <DateRangePicker
      startDate={date.startDate}
      startDateId="startDate"
      startDatePlaceholderText="MM/DD/YYYY"
      endDate={date.endDate}
      endDateId="endDate"
      endDatePlaceholderText="MM/DD/YYYY"
      onDatesChange={onChange}
      focusedInput={focusedInput}
      onFocusChange={setFocusedInput}
      isOutsideRange={() => { }}
      minimumNights={0}
      numberOfMonths={months}
      orientation={"horizontal"}
      hideKeyboardShortcutsPanel={true}
      showClearDates={true}
      keepOpenOnDateSelect={false}
      customArrowIcon={"_"}
      customCloseIcon={<FaTimes />}
      verticalSpacing={0}
      renderCalendarInfo={() =>
        <div className="date-range-select-options">
          <div>
            <p>Current: </p>
            <a onClick={() => filterDate("today")}>Day</a>
            <a onClick={() => filterDate("week")}>Week-to-Date</a>
            <a onClick={() => filterDate("month")}>Month-to-Date</a>
            <a onClick={() => filterDate("year")}>Year-to-Date</a>
          </div>
          <div>
            <p>Previous: </p>
            <a onClick={() => filterDate("prev_week")}>Week</a>
            <a onClick={() => filterDate("prev_month")}>Month</a>
            <a onClick={() => filterDate("prev_year_complete")}>Year</a>
            <a onClick={() => filterDate("trailing_year")}>Trailing Year</a>
          </div>
        </div>
      }
    />
  )
}

export default DateRange