import gql from 'graphql-tag';

const GET_PHONE_CALL_TYPES = gql`
{
    getAllCallTypes{
      id
      name
    }
  }
`;
export default GET_PHONE_CALL_TYPES;
