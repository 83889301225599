import gql from "graphql-tag";

const GET_SELECTED_USER = gql`
  query {
    selectedUserIds @client {
      ids
    }
  }
`;

export default GET_SELECTED_USER;
