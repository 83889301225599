import gql from 'graphql-tag';

const UPDATE_TITLE_CUSTOMIZATION = gql`
mutation updateTitle($name: String!, $value: String!){
  updateTitleCustomization(name:$name,value: $value){
   code
    success
    message
  }
}
`;
export default UPDATE_TITLE_CUSTOMIZATION;

