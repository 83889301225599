export const getInitialState = ({ filter, loading, cache }) => {

  return {
    filter: {
      // ...filter,
      ...(cache && cache.filter)
    },
    search: (cache && cache.search) || "",
    limit: (cache && cache.limit) || 10,
    pageNum: (cache && cache.pageNum) || 0,
    pageSize: (cache && cache.limit) || 10,
    order: (cache && cache.order) || {
      id: -1,
      name: "",
      comparator: "",
      sort: ""
    },
    userPageCount: 0,
    editItemId: null,
    homeOwners: [],
    loading: loading,
    render: !loading,
    showMassEmailModal: false,
    homeownerEmails: undefined,
    isMassEmailSubmitted: false
  }
}

export default function reducer(state, action) {
  switch (action.type) {
    case "UPDATE":
      return Object.assign({}, state, { ...action.payload });

    case "RESET":
      return Object.assign({}, state, {
        ...action.payload,
        filter: {},
        search: "",
        pageSize: 10,
        limit: 10,
        pageNum: 0,
        order: {
          id: -1,
          name: "",
          comparator: "",
          sort: ""
        },
        userPageCount: 0
      });
    default:
      return state;
  }
}