//this is used to store the access token in memory only
let accessToken = "";

//setter
export const setAccessToken = (s) => {
  accessToken = s;
};

//getter
export const getAccessToken = () => {
  return accessToken;
};
