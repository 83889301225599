import React from 'react';
import './side-drawer.css';

/**
 * this component is used to display a drawer on the entire application.
 * @param {object} props component props
 * @param {boolean} show Show/hide to drawer
 * @param {HTMLBodyElement} component content of the drawer
 */
const sideDrawer = props => {
  let drawerClasses = `side-drawer side-dialog ${props.className}`
  if (props.show) {
    drawerClasses += ' open'
  }
  let component = props.component
  return (
    <nav className={drawerClasses}>
      {component}
    </nav>
  )
}
export default sideDrawer