import { thisMondayDate, thisSundayDate, getMonthStartDate, getMonthEndDate } from "../utils/helpers";

const defaults = {
  selectedCommunityIds: {
    __typename: "Integer",
    ids: []
  },

  defaultView: {
    __typename: "DefaultView",
    tableView: false
  },

  selectedCsmIds: {
    __typename: "Integer",
    ids: null
  },

  selectedUserIds: {
    __typename: "Csm",
    ids: null
  },
  selectedSingleUserId: {
    __typename: "Csm",
    id: null
  },
  selectedOscIds: {
    __typename: "Csm",
    ids: null
  },
  selectedSingleCommunityId: {
    __typename: "Integer",
    id: null
  },

  selectedDateRange: {
    __typename: "DateRange",
    startDate: thisMondayDate(),
    endDate: thisSundayDate()
  },

  selectedCalendarDateRange: {
    __typename: "CalendarDateRange",
    start: getMonthStartDate(),
    end: getMonthEndDate()
  },

  selectedDivision: {
    __typename: "Division",
    id: null,
    name: "All Divisions"
  },

  previousCard: {
    __typename: "Card",
    title: null
  },

  titleCustomizations: [
    {
      __typename: "TitleCustomization",
      name: "reservation",
      value: "Reservation"
    },
    {
      __typename: "TitleCustomization",
      name: "video_text",
      value: "Video Text"
    }
  ],

  notificationProps: {
    __typename: "",
    open: false,
    message: ""
  },

  user: {
    __typename: "User",
    role: "Manager",
    name: "Manager"
  },

  authStatus: {
    __typename: "AuthStatus",
    status: ""
  },

  tableParams: {
    __typename: "CardState",
    cardsParams: []
  },

  showMyAppointmentsOnly: {
    __typename: "",
    show: false
  }
};

export default defaults;
