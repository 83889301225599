import gql from "graphql-tag";

export const ADD_STATUS_EMAIL = gql`
  mutation addStatusEmail($input: StatusInput!) {
    addStatusEmail(input: $input) {
      code
      success
      message
    }
  }
`;

export const DELETE_STATUS_EMAIL = gql`
  mutation deleteStatusEmail($input: StatusInput!) {
    deleteStatusEmail(input: $input) {
      code
      success
      message
    }
  }
`;
