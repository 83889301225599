import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { FaUser } from "react-icons/fa";
import { phoneLink } from "../../utils/helpers";
import DetailsSectionTable from "../../components/table/custom-details-table";
import "./merge-realtor.css"

/** @module RealtorDetailsCard */
/**
 * This component is used to render a card details section. It divides the details card into
 * two sections. Realtor Information and Agency. 
 * 
 * @param {Object} props this contains the data from the graphql query and the function to close the drawer
 * @param {Object} props.realtorData it contains data to be shown on card
 * @returns {JSX.Element} It returns jsx containing card title and card body
 */

const RealtorDetailsCard = realtorData => {
  const [realtorInformation, setRealtorInformation] = useState({})
  const [agency, setAgency] = useState({})

  // Sets the information to display in a card
  useEffect(() => {

    setRealtorInformation({
      "First Name": realtorData.realtorData.first_name,
      "Last Name": realtorData.realtorData.last_name,
      "Primary Email": realtorData.realtorData.primary_email,
      "Cell Phone": phoneLink(realtorData.realtorData, "cell_phone", false) || null,
      "Other Emails": realtorData.realtorData.Realtor_Other_Emails.map(email => email.email_address).join(", "),
      "Division": realtorData.realtorData.division.name,
      "Communities": realtorData.realtorData.Communities.map(communities => communities.name).join(", ")
    })

    setAgency({
      "Agency Name": realtorData.realtorData.Agency?.name ?? null,
      "Agency City": realtorData.realtorData.Agency?.city ?? null,
      "Agency Address": realtorData.realtorData.Agency?.address ?? null,
      "Agency State": realtorData.realtorData.Agency?.state?.name ?? null,
      "Zip Code": realtorData.realtorData.Agency?.zip ?? null
    })

  }, [realtorData])

  return (
    <Card className="details-card">
      <Card.Header>
        <FaUser /> {`${realtorData.realtorData.first_name} ${realtorData.realtorData.last_name}`}
      </Card.Header>
      <Card.Body>
        <div>
          <DetailsSectionTable data={realtorInformation} tableHeader="Realtor Information" />
        </div>
        <div>
          <DetailsSectionTable data={agency} tableHeader="Agency Information" />
        </div>
      </Card.Body>
    </Card>
  )
}

export default RealtorDetailsCard