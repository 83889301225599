import gql from "graphql-tag";

const GET_SELECTED_CSM = gql`
  query {
    selectedCsmIds @client {
      ids
    }
  }
`;

export default GET_SELECTED_CSM;
