import gql from 'graphql-tag';

const GET_LEAD_ON_EDIT = gql`
query getLeadsByFilter($filter: LeadFilter){
    getLeads(filter:$filter) {
    Leads{
      id
      first_name_1
      last_name_1
      primary_email_1
      secondary_emails_1
      cell_phone_1
      first_name_2
      last_name_2
      primary_email_2
      secondary_emails_2
      primary_email_1_status
      primary_email_2_status
      cell_phone_2
      street_address
      city
      osc_notes
      realtor{
        id
        first_name
        last_name
        primary_email
        cell_phone
      }
      customer_origination_area{
        id
        name
      }
      state{
        id
        name
      }
      lead_classification_filters {
        id
        name
      }
      zip
      other_notes
      quality_score
      engagement_score
      communities {
        id
        name
        is_active
        division_id
      }
      divisions {
        id
      }
    }
    total_count
  }
 }
 `;

export default GET_LEAD_ON_EDIT;