import gql from "graphql-tag";

const GET_EMAIL_TEMPLATES_TYPES = gql`
  {
    getEmailTemplateTypes {
      id
      name
      description
      is_web_form_template
    }
  }
`;
export default GET_EMAIL_TEMPLATES_TYPES;
