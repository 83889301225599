import gql from 'graphql-tag';

const ADD_HOMEOWNER_APPOINTMENT = gql` 
  mutation addHomeownerAppointment($input: HomeownerAppointmentInput!) {
    addHomeownerAppointment(input: $input) {
      message
      code
    }
  }
`;

export default ADD_HOMEOWNER_APPOINTMENT;