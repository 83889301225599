import React, { useState } from "react";
import CustomButton from "../../../components/custom-button";
import Drawer from "../../../hoc/Drawer";
import {
  AddHomeownerPhoneCallContainer,
  UpdateHomeownerPhoneCallContainer,
} from "../../common-lead-prospect-forms/call-container";

/**
 * This component is used to render button along with the drawer to open/close homeowner call button. On the basis
 * of function it decides whether to render add or edit container. On submit it calls the onSubmit callback and 
 * closes the drawer.
 * 
 * @param {Object} props 
 * @param {Boolean} props.showButton to show/hide button
 * @param {JSX.Element} props.btnIcon icon to show
 * @param {Number} props.id id to edit 
 * @param {String} props.function whether add or update
 * @param {Function} props.onSubmit callback function
 */
const HomeownerCallButton = (props) => {
  const [showSideBar, setShowSideBar] = useState(false);

  const toggleShowDrawer = () => {
    if (props.showButton) {
      setShowSideBar(!showSideBar)
    } else {
      props.close();
    }
  }

  const onSubmit = (message) => {
    setShowSideBar(false)
    if (!props.showButton) {
      props.close();
    }
    props.onSubmit(message);
  };

  return (
    <div>
      {props.showButton && (
        <CustomButton
          size={props.size}
          color={props.color}
          btnIcon={props.btnIcon}
          className={props.className}
          btnValue={props.btnValue}
          onClick={toggleShowDrawer}
        />
      )}
      <Drawer
        show={showSideBar || !props.showButton}
        toggleSideBar={toggleShowDrawer}
      >
        {props.function === "ADD" ? (
          <AddHomeownerPhoneCallContainer
            type={props.type}
            function={props.function}
            close={toggleShowDrawer}
            id={props.id}
            {...props}
            onSubmit={onSubmit}
          />
        ) : (
          <UpdateHomeownerPhoneCallContainer
            type={props.type}
            function={props.function}
            close={toggleShowDrawer}
            id={props.id}
            {...props}
            onSubmit={onSubmit}
          />
        )}
      </Drawer>
    </div>
  );
}

export default HomeownerCallButton;