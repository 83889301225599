import gql from "graphql-tag";

const SET_CALENDAR_DATE_RANGE = gql`
  mutation selectCalendarDateRange($start: String, $end: String) {
    selectCalendarDateRange(start: $start, end: $end) @client {
      start
      end
    }
  }
`;

export default SET_CALENDAR_DATE_RANGE;
