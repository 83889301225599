import { compose, graphql } from "react-apollo";
import { GET_ARCHIVED_USERS_BYID } from "../../graphql/queries";
import {
  SET_NOTIFICATION_PROPS,
  SET_TABLE_PARAMS
} from "../../cacheql/mutations";
import ArchiveUsersTable from "./archive-users-table";
import { GET_TABLE_PARAMS } from "../../cacheql/queries";
import { getCardParams } from "../../utils/helpers";

const ArchivedDetailsView = compose(
  graphql(SET_TABLE_PARAMS, { name: "setTableParams" }),
  graphql(GET_TABLE_PARAMS, { name: "getTableParams" }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" }),
  graphql(GET_ARCHIVED_USERS_BYID, {
    options: ({
      getTableParams: { tableParams },
      ...props
    }) => {
      let params = getCardParams('Archived Users', tableParams);

      return {
        variables: {
          pageNum: (params && params.pageNum) || 0,
          limit: (params && params.limit) || 10,
          sort:
            params && params.order && params.order.id !== -1
              ? [params.order.name, params.order.sort]
              : null,
          search: (params && params.search) || ""
        },
        fetchPolicy: "cache-and-network"
      };
    }
  }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" })
)(ArchiveUsersTable);

export default ArchivedDetailsView;
