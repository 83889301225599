import * as Yup from "yup";
import * as errorMessages from "../../../utils/form-errors";
import * as validations from "../../../utils/common-validations";
import {
  checkIsBeforeTime,
  checkIsBeforeCurrentTime,
} from "../../../utils/helpers";

export const scheduleAppointmentForm = {
  input: [
    {
      send_email: true,
      title: "",
      details: "",
      appointment_datetime: new Date(),
      start_datetime: new Date(),
      end_datetime: new Date(),
      csm_id: "",
      appointment_type_id: "",
      community_id: "",
    },
  ],
};

export const scheduleAppointmentSchema = Yup.object().shape({
  input: Yup.array().of(
    Yup.object().shape({
      title: validations.isSpace
        .max(64, errorMessages.TITLE_LONG)
        .required(errorMessages.REQUIRE_FIELD)
        .default("")
        .nullable(),
      details: validations.isSpace
        .max(256, "Details must not be more than 256 characters")
        .default("")
        .nullable(),
      community_id: Yup.string()
        .required(errorMessages.REQUIRE_FIELD)
        .default("Select...")
        .nullable(),
      csm_id: Yup.string()
        .default("Select...")
        .required(errorMessages.REQUIRE_FIELD)
        .nullable(),
      appointment_type_id: Yup.string()
        .default("Select...")
        .required(errorMessages.REQUIRE_FIELD)
        .nullable(),
      appointment_datetime: Yup.date().required().default(new Date()),
      timecheck: Yup.string().when(
        ["start_datetime", "end_datetime"],
        {
          is: (start_datetime, end_datetime) => !checkIsBeforeTime(start_datetime, end_datetime),
          then: Yup.string().required(errorMessages.END_TIME),
          otherwise: Yup.string(),
        }),
      startTimeCheck: Yup.string().when(
        ["start_datetime", "appointment_datetime"],
        {
          //start_datetime is selected appointment slot start time, appointment_datetime is selected appointment date
          is: (start_datetime, appointment_datetime) =>
            checkIsBeforeCurrentTime(start_datetime, appointment_datetime),
          then: Yup.string().required(errorMessages.START_TIME),
          otherwise: Yup.string(),
        }),
    })
  ),
});
