import gql from "graphql-tag";

const GET_AUTHENTICATION_ACTIVITY = gql`
  query getAuthenticationActivity(
    $limit: Int
    $pageNum: Int
    $filter: authenticationActivityFilter
  ) {
    getAuthenticationActivity(
      limit: $limit
      pageNum: $pageNum
      filter: $filter
    ) {
      authenticationActivities {
        id
        action
        activity_date
      }
      total_count
    }
  }
`;
export default GET_AUTHENTICATION_ACTIVITY;
