import React, { useState, useEffect } from 'react';
import { LEAD } from "../../../utils/constants"
import Drawer from "../../../hoc/Drawer";
import MarketingOnlyToLeadForm from '../forms/marketing-only-to-lead-container';
import MarketingOnlyToProspectForm from '../forms/marketing-only-to-prospect-container';

/**
 * this component is used to render the marketing only to lead/prospect form drawer 
 * @param {*} param0 
 */
const MarketingOnlyConvertView = (props) => {
  const { show } = props
  const [showSidebar, setShowSidebar] = useState(false)

  const toggleSidebar = () => {
    setShowSidebar(false)
    props.close()
  }

  const onSubmit = (message) => {
    setShowSidebar(false)
    props.onSubmit(message)
  }

  useEffect(() => {
    show && setShowSidebar(true)
  }, [show])

  return (
    <Drawer
      show={showSidebar}
      toggleSideBar={toggleSidebar}
    >
      {props.type === LEAD ?
        <MarketingOnlyToLeadForm
          close={toggleSidebar}
          marketingOnlyData={props.marketingOnlyData}
          marketingOnlyId={props.id}
          onSubmit={onSubmit}
        /> :
        <MarketingOnlyToProspectForm
          close={toggleSidebar}
          marketingOnlyData={props.marketingOnlyData}
          marketingOnlyId={props.id}
          onSubmit={onSubmit}
        />
      }
    </Drawer>
  )
}

export default MarketingOnlyConvertView;