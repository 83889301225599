import gql from "graphql-tag";

const GET_CUSTOMER_COMMUNITIES = gql`
  query getCustomerCommunities($customer_id: Int!) {
    getCustomerCommunities(customer_id: $customer_id) {
        lead_communities{
            id
            name
        }
        prospect_communities{
            id
            name
        }
        homeowner_communities{
            id
            name
        }
        marketing_only_communities{
          id
          name
      }
    }
  }
`;
export default GET_CUSTOMER_COMMUNITIES;
