import gql from 'graphql-tag';

const GET_OTHERVALUES = gql`
  {
    getAllOther_Dropdown_Values {
      id,
      name
    }
  }
`;
export default GET_OTHERVALUES;
