import React, { useState } from "react";
import { getIssueEmail, InactivityIcon, formatDataForCalendar } from "../../../utils/helpers";
import { UpdateActivityContainer } from "../../common-lead-prospect-forms/activity-container";
import { PROSPECT } from "../../../utils/constants"
import Calendar from "../../../components/calendar/calendar";
import LoadingWrapper from "../../../components/loading-wrapper";
import ProspectDetailSelectBarView from "./prospect-detail-select-bar";
import CalendarPopover from '../../common-lead-prospect-details/calendar-popover'
import ProspectDetailView from "./prospect-detail-card";
import NoteReminderView from "../../common-lead-prospect-details/notes-card";
import ProspectCallButton from "./prospect-call-button";
import PastActivitiesView from "../../common-lead-prospect-details/past-activities";
import NoDataPage from "../../no-data-page/no-data-page";
import logo from "../../../images/graphic-no-prospect.png";
import ActivityButton from "../../../components/activity-button";
import "./prospect-details.css";

/**
 * This component is used to render details page of the prospect. It is divided into four main parts. On the top
 * it renders {@link ProspectDetailSelectBarView} component to display prospect's community and his name and convert to lead button. Right
 * below it, {@link BigCalendar} is displayed and next to it {@link ProspectDetailView} card is rendered ti show basic 
 * information of the prospect and to edit it as well. Right below Big calendar we have past-activities view and notes list view using
 * {@link NoteReminderView}. If the prospect is not available, no data page is displayed instead of details page. 
 * while the data is loading, a full page loader is rendered. 
 * @param {Object} props queries from the container are received as props 
 */
const ProspectDetailsView = ({
  getProspectDetail,
  ...props
}) => {
  const [activity, setActivity] = useState(null);
  const [showEditActivity, setShowEditActivity] = useState(false);
  const [showEditPhoneCall, setShowEditPhoneCall] = useState(false);

  const submitHandler = (refetch, message) => {
    message &&
      props.setNotificationProps({
        variables: { open: true, message: message }
      });
    refetch();
  };
  const toggleShowActivityDrawer = activity => {
    if (activity.__typename === "PhoneCall" || activity.__typename === "Text") {
      setShowEditPhoneCall(!showEditPhoneCall);
      setActivity(activity);
    } else {
      setShowEditActivity(!showEditActivity);
      setActivity(activity);
    }
  };
  const hideEditDrawer = () => {
    setShowEditPhoneCall(false);
    setShowEditActivity(false);
    setActivity(null);
  };

  const prospectDetailsView = (id, data, loading, refetch) => {
    let name =
      !loading ?
        data.getProspects.Prospects &&
        ` ${data.getProspects.Prospects[0].first_name_1} ${data.getProspects.Prospects[0].last_name_1}` : ''
    name +=
      !loading && data.getProspects.Prospects[0].first_name_2
        ? ` and ${data.getProspects.Prospects[0].first_name_2} ${data.getProspects.Prospects[0].last_name_2 || ""}`
        : "";

    return (
      <>
        <LoadingWrapper
          loading={loading}
          component={
            <div className="col-padding col-md-12 col-12">
              <div className="root-row" id="prospect-detail-header">
                <ProspectDetailSelectBarView
                  name={name}
                  community={
                    !loading ? (
                      <div className="d-flex mr-2">
                        <span className="text-muted">:&nbsp;</span>{" "}
                        {InactivityIcon(
                          data.getProspects.Prospects[0].community,
                          data.getProspects.Prospects[0].community.name
                        )}
                      </div>
                    ) : null
                  }
                  flag={
                    !loading ? data.getProspects.Prospects[0].red_flag : null
                  }
                  id={id}
                  data={!loading ? data.getProspects.Prospects[0] : null}
                  setNotificationProps={props.setNotificationProps}
                  {...props}
                />
              </div>

              <div className="row-container main-responsive col-xl-12 row p-2 px-3">
                <div className="detail-view-main col-padding col-md-8 col-8">
                  <div
                    style={{
                      padding: "1rem",
                      marginTop: "0.5rem",
                      border: "1px solid #191b1d",
                      borderRadius: "0.25rem"
                    }}
                  >
                    <Calendar
                      id={id}
                      data={Object.assign(
                        {},
                        !loading ? data.getProspects.Prospects[0] : {},
                      )}
                      onSubmit={submitHandler.bind(this, refetch)}
                      {...props}
                      popover={CalendarPopover}
                      editComp={toggleShowActivityDrawer}
                      type={PROSPECT}
                    />
                    <PastActivitiesView
                      type={PROSPECT}
                      pastActivitiesData={
                        !loading ? formatDataForCalendar(data.getProspects.Prospects[0]) : null
                      }
                      id={id}
                      onSubmit={submitHandler.bind(this, refetch)}
                    />
                  </div>
                  <NoteReminderView
                    {...props}
                    id={id}
                    type={PROSPECT}
                    data={!loading ? data.getProspects.Prospects[0] : null}
                    loading={loading}
                    onSubmit={submitHandler.bind(this, refetch)}
                  />
                </div>

                <div className="col-padding col-md-4 col-12 pb-2 pt-2 pr-0 pl-3 detail-view-side">
                  {showEditActivity && (
                    <ActivityButton
                      type={PROSPECT}
                      function="UPDATE"
                      item={activity}
                      community_id={
                        !loading
                          ? data.getProspects.Prospects[0].community.id
                          : null
                      }
                      close={hideEditDrawer}
                      {...props}
                      component={UpdateActivityContainer}
                      onSubmit={submitHandler.bind(this, refetch)}
                    />
                  )}
                  {showEditPhoneCall && (
                    <ProspectCallButton
                      close={hideEditDrawer}
                      id={id}
                      item={activity}
                      showButton={false}
                      function={"UPDATE"}
                      onSubmit={submitHandler.bind(this, refetch)}
                      {...props}
                    />
                  )}

                  <ProspectDetailView
                    data={!loading ? data.getProspects.Prospects[0] : null}
                    id={id}
                    {...props}
                    onSubmit={submitHandler.bind(this, refetch)}
                  />
                </div>
              </div>
            </div>
          }
        />
      </>
    );
  };

  const { refetch, loading } = getProspectDetail;

  return !loading &&
    (!getProspectDetail.getProspects ||
      getProspectDetail.getProspects.Prospects.length === 0) ? (
    <NoDataPage
      {...props}
      backgroundImage={logo}
      heading={"Prospect not found!"}
      content={
        <div>
          <p className="message-reportBug">The resource for this prospect was not found in our records.<br />If it's a bug, please report.<br /><a href={getIssueEmail()} target="_blank" rel="noopener noreferrer" className="btn btn-primary">Report</a></p>
        </div>
      }
    />
  ) : (
    prospectDetailsView(
      parseInt(props.match.params.id),
      getProspectDetail,
      loading,
      refetch
    )
  );
};
export default ProspectDetailsView;
