import gql from "graphql-tag";

const SELECT_USER = gql`
  mutation selectUser($selectedUsers: [Int]) {
    selectUser(selectedUsers: $selectedUsers) @client {
      ids
    }
  }
`;

export default SELECT_USER;
