import * as React from "react";
import { compose, graphql } from "react-apollo";
import DashboardActivity from "./dashboard-activity-items";
import {
  GET_DASHBOARD_LEADS,
  GET_UNIQUE_PROSPECTS_COUNT, GET_PROSPECTS_COUNT
} from "../../graphql/queries";
import {
  GET_SELECTED_COMMUNITY,
  GET_SELECTED_DIVISION,
  GET_SELECTED_CSM
} from "../../cacheql/queries";
import {
  isNotNULL,
  getMonthlyActivityDate,
  getCommunityIds,
  getCreateDateFilters
} from "../../utils/helpers";
import "./dashboard.css";

const quer_params = GET_UNIQUE_PROSPECTS_COUNT;
const quer_lead_params = GET_DASHBOARD_LEADS;

const inputNewVariables = function (props, type, timePeriod) {
  const filters = timePeriod === "currYear" ? { ...props.filters.currentYear } : { ...props.filters.prevYear }
  return {
    input: {
      ...filters,
      ...(isNotNULL(props.selectedCommunity)
        ? {
          community_ids: getCommunityIds(props.selectedCommunity),
        }
        : {}),
      ...(isNotNULL(props.selectedDivision.selectedDivision)
        ? { division_id: props.selectedDivision.selectedDivision.id }
        : {}),
      ...(isNotNULL(props.selectedCsm) && type === "prospect"
        ? {
          csm_id: props.selectedCsm.selectedCsmIds.ids,
        }
        : {}),
    },
  };
};

const ActivityList = compose(
  graphql(GET_SELECTED_COMMUNITY, { name: "selectedCommunity" }),
  graphql(GET_SELECTED_CSM, { name: "selectedCsm" }),
  graphql(GET_SELECTED_DIVISION, { name: "selectedDivision" }),
  graphql(quer_lead_params, {
    name: "leadMonthly",
    options: (props) => ({ variables: inputNewVariables(props, "lead", "currYear") }),
  }),
  graphql(quer_params, {
    name: "monthly",
    options: (props) => ({ variables: inputNewVariables(props, "prospect", "currYear") }),
  }),
  graphql(quer_lead_params, {
    name: "leadPrevYearMonthly",
    options: (props) => ({ variables: inputNewVariables(props, "lead", "prevYear") }),
  }),
  graphql(quer_params, {
    name: "prevYearMonthly",
    options: (props) => ({ variables: inputNewVariables(props, "prospect", "prevYear") }),
  }),
  graphql(GET_PROSPECTS_COUNT, {
    name: "ranked",
    options: (props) => ({
      variables: {
        input: {
          community_ids: getCommunityIds(props.selectedCommunity),
          division_id: props.selectedDivision.selectedDivision.id,
          csm_id: props.selectedCsm.selectedCsmIds.ids,
          rank_id: 1,
        },
      },
    }),
  })
)(DashboardActivity);

class ActivityContainer extends React.Component {
  render() {
    const filters = {
      currentYear: getCreateDateFilters('month'),
      prevYear: getCreateDateFilters('prev_year_month')
    }
    return (
      <div className="activity-root col-padding col" id="activity-root">
        <ActivityList
          isCommunityUpdated={this.props.isCommunityUpdated}
          prospectDataUpdated={this.props.prospectDataUpdated}
          date={getMonthlyActivityDate()}
          filters={filters}
        />
      </div>
    );
  }
}

export default ActivityContainer;
