import React, { useState, useEffect, useContext } from "react";
import CustomButton from "../custom-button";
import ActivityButton from "../activity-button";
import LeadCallButton from "../../views/lead/details/lead-call-button";
import ProspectCallButton from "../../views/prospect/details/prospect-call-button";
import HomeownerCallButton from "../../views/homeowner/details/homeowner-call-button";
import { Auth } from "../../rbac/rbac";
import { CalenderContext } from "./context";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { LEAD, PROSPECT, HOMEOWNER, MARKETING_ONLY } from "../../utils/constants"
import { upperCaseFirstLetter, MonthYearComparison, getMonth, formatDate } from "../../utils/helpers";
import { AddHomeownerActivityContainer, AddLeadActivityContainer, AddActivityContainer } from "../../views/common-lead-prospect-forms/activity-container";
import {
  FaChevronLeft,
  FaPlus,
  FaChevronRight,
  FaListAlt,
} from "react-icons/fa";
import "./calendar.css";

/** 
 * @module CustomToolbarModule */

/**
 * This component is used in {@link BigCalendar} to render a custom toolbar. It contains legend and all the extra controls like changing the calendar view from monthly to weekly, changing the the day/month, or buttons for adding call/notes etc.
 * @param {Object} props 
 * @returns {JSX.Element}
 */
const CustomToolbar = ({ onNavigate, onView, view, label, ...props }) => {
  const [dropDownTitle, setDropDownTitle] = useState(
    upperCaseFirstLetter(view)
  );

  useEffect(() => {
    if (view !== dropDownTitle) {
      setDropDownTitle(upperCaseFirstLetter(view));
    }
  }, [view]);

  const { is_osc } = useContext(Auth);
  const { type, id, submitHandler, data } = useContext(CalenderContext);
  const container = type === LEAD ? AddLeadActivityContainer
    : type === PROSPECT ? AddActivityContainer
      : type === HOMEOWNER ? AddHomeownerActivityContainer
        : null

  const navigate = (action) => {
    onNavigate(action);
  };

  /**
   * This function checks where exactly unresponded webforms exist in previous or next month
   * @function
   * @inner
   * @memberof module:CustomToolbarModule
   * @returns {Array} show contains "previous" or "next" which informs about existence of unresponded webform
   * @see {@link module:CustomToolbarModule~CustomToolbar}
   */
  const checkVisibilty = () => {
    let show = [];
    let labelDate = new Date(label)
    data &&
      data.Form_Submission_Details &&
      data.Form_Submission_Details.forEach((item) => {
        let date = new Date(item.created_at)
        let monthYearComparisonResult = MonthYearComparison(date, labelDate)
        if (
          monthYearComparisonResult.isBefore &&
          !item.responded_at
        ) {
          show.push("previous");
        } else if (
          monthYearComparisonResult.isAfter &&
          !item.responded_at
        ) {
          show.push("next");
        }
      });
    return show;
  };

  /**
   * This function renders icon with a tooltip whether unresponded is in next or previuos months
   * @function
   * @inner
   * @memberof module:CustomToolbarModule
   * @param {String} comparator conatins "next" or "previous"
   * @returns {JSX.Element}
   * @see {@link module:CustomToolbarModule~CustomToolbar}
   */
  const unrespondedWebformsIcon = (comparator) => {
    return type === "webform" ? (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip style={{ zIndex: 10000 }}>
            unresponded formsubmission(s) in {comparator} months
          </Tooltip>
        }
      >
        <FaListAlt
          size={14}
          color="red"
        />
      </OverlayTrigger>
    ) : null;
  };

  /**
   * This function renders navigation buttons, to go to previous or next date
   * @function
   * @inner
   * @memberof module:CustomToolbarModule
   * @returns {JSX.Element}
   * @see {@link module:CustomToolbarModule~CustomToolbar}
   */
  const monthlyNavigationHeader = () => {
    let visibie = checkVisibilty();
    return <div className="section-today">
      {visibie.includes("previous") && unrespondedWebformsIcon("previous")}
      <CustomButton
        onClick={() => navigate("PREV")}
        id="fa-ChevronLeft"
        color="dark"
        btnIcon={<FaChevronLeft />}
        className={"arrowLeft"}
      />
      <button
        className="nav-btn"
        style={{ cursor: "default" }}
        type="button"
      >
        {getMonth(props.date)}
      </button>
      <CustomButton
        onClick={() => navigate("NEXT")}
        id="fa-ChevronRight"
        color="dark"
        btnIcon={<FaChevronRight />}
        className={"arrowRight"}
      />
      {visibie.includes("next") && unrespondedWebformsIcon("next")}
    </div>
  };

  return (
    <div className="rbc-toolbar-custom">
      <div className="d-flex flex-column mb-2">
        <h3 className="calendar-title m-0 mb-1">
          {
            id === 'dashboard-calendar'
              ? (is_osc() ? "Appointments & Follow-ups" : "Upcoming Appointments & Follow-ups")
              : "Activity Calendar"
          }
        </h3>
        <div className="calendar-legend">
          {(type === PROSPECT || type === HOMEOWNER || id === 'dashboard-calendar') && (
            <span id="legend-appointments">
              <i /> Appointments
            </span>
          )}
          {(type === LEAD || type === PROSPECT || type === HOMEOWNER || id === 'dashboard-calendar') && (
            <span id="legend-followups">
              <i /> Follow-Ups
            </span>
          )}
          {(id !== 'dashboard-calendar') && (
            <>
              <span
                id="legend-ebomb"
                style={{ margin: "0.2rem 1rem 0 0", display: "inline-block" }}
              >
                <i /> EBomb
              </span>
              <span
                id="legend-webform"
                style={{ margin: "0.2rem 1rem 0 0", display: "inline-block" }}
              >
                <i /> Web Forms
              </span>
            </>
          )}
          {(id !== 'dashboard-calendar' && type !== MARKETING_ONLY) && (
            <>
              <span
                id="legend-email"
                style={{ margin: "0.2rem 1rem 0 0", display: "inline-block" }}
              >
                <i /> Email
              </span>
              <span
                id="legend-massemail"
                style={{ margin: "0.2rem 1rem 0 0", display: "inline-block" }}
              >
                <i /> Mass Email
              </span>
              <span
                id="legend-phonecall"
                style={{ margin: "0.2rem 1rem 0 0", display: "inline-block" }}
              >
                <i /> Phone Calls
              </span>
              <span
                id="legend-text"
                style={{ margin: "0.2rem 1rem 0 0", display: "inline-block" }}
              >
                <i /> Text
              </span>
            </>
          )
          }
          {(type === PROSPECT) && (
            <span id="legend-walkins">
              <i /> Walk-Ins
            </span>
          )}
        </div>
      </div>
      <div className="calendar-header-controls">
        <div className="calendar-header-filters d-flex align-items-center">
          <p
            onClick={() => navigate("TODAY")}
            style={{ cursor: 'pointer' }}
          >
            {formatDate(new Date)}
          </p>
        </div>
         {monthlyNavigationHeader()}
        <div className="d-flex justify-content-end">
          {type === PROSPECT ||
            type === LEAD ||
            type === HOMEOWNER ? (
            <ActivityButton
              {...props}
              data={data}
              btnIcon={<FaPlus />}
              type={type}
              function="CREATE"
              component={container}
              onSubmit={(message) => submitHandler(message)}
            />
          ) : null}
          <div className="ml-2">
            {type === PROSPECT ? (
              <ProspectCallButton
                showButton
                id={id}
                onSubmit={(message) => submitHandler(message)}
                btnValue="ADD CALL"
                function={"ADD"}
              />
            ) : type === LEAD ? (
              <LeadCallButton
                showButton
                id={id}
                onSubmit={(message) => submitHandler(message)}
                btnValue="ADD CALL"
                function={"ADD"}
              />
            ) : type === HOMEOWNER ? (
              <HomeownerCallButton
                showButton
                id={id}
                onSubmit={(message) => submitHandler(message)}
                btnValue="ADD CALL"
                function={"ADD"}
              />
            ) : null}
          </div>
        </div>
      </div>
      {/* <div className="calendar-month" >
        {monthlyNavigationHeader()}
      </div> */}
    </div>
  );
};

export default CustomToolbar;
