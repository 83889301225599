import React, { useState, useEffect } from "react";
import { Form, Field, withFormik } from "formik";
import { compose, graphql } from "react-apollo";
import { EditorState } from "draft-js";
import * as Yup from "yup";
import TextField from "../fields/text-field";
import MultiSelectField from "../fields/multi-select-field";
import TextEditor from "../rich-text-editor/editor";
import { editorClassName } from "../rich-text-editor/editor-constants";
import { convertEditorToHTML, decorator } from "../rich-text-editor/editor-helpers";
import { SEND_MASS_EMAIL } from "../../graphql/mutations";
import { isEmpty } from "../../utils/helpers";
import { REQUIRE_FIELD } from "../../utils/form-errors";
import './send-email.css'

/**
 * This component is responsible to create the form of sending mass email.
 * It renders three fields: 
 * Recipients - Emails of all leads that are selected. 
 * Subject - Subject of the email
 * Body - Body of the email
 * 
 * Body of the email uses a rich text editor component to write emails with
 * multiple decorative and alignment options.
 * 
 * @param {Object} values All current set values of the form
 * @param {Boolean} checkSubmission A boolean to check if the form is submitted 
 * @param {Function} onFormSubmit A function that notifies the dialog when the form is submitted successfully
 * @param {Function} handleCheckSubmission This function controls the checkSubmission variable  
 * @returns 
 */
const SendMassEmail = ({
  values,
  checkSubmission,
  onFormSubmit,
  handleCheckSubmission,
  emails,
  ...props
}) => {
  const [emailRecipients, setEmailRecipients] = useState([])
  const [uploadErrorText, setUploadErrorText] = useState('')
  const [editorState, setEditorState] = useState(
    EditorState.createEmpty(decorator)
  );

  const onBodyTextChange = (eState) => {
    setEditorState(eState);
    props.setFieldValue('emailBody', convertEditorToHTML(eState ?? editorState))
  };

  const uploadAttachments = (event) => {
    setUploadErrorText('')
    const files = Array.from(event.target.files);

    if (files.length > 5) {
      event.target.value = ''
      setUploadErrorText("Exceeded upload limit. Only 5 files can be uploaded.")
      return;
    } else if (files.some((file) => file.size > 10000000)) {
      event.target.value = '';
      setUploadErrorText("Exceeded size limit. Only files under 10 MB can be uploaded.")
      return;
    }

    props.setFieldValue('attachments', files)
  };

  useEffect(() => {
    // filtering out duplicate emails
    const uniqueEmails = [...new Set(emails)];
    const emailObject = uniqueEmails.map((email, index) => {
      return {
        id: index,
        name: email
      }
    })
    setEmailRecipients(emailObject)
  }, [])

  useEffect(() => {
    if (checkSubmission) {
      values.recipient = emails.filter((_, index) => {
        return values.recipient?.includes(index)
      })

      props.handleSubmit()
      isEmpty(props.errors) && onFormSubmit()
      handleCheckSubmission(false)
    }
  }, [checkSubmission])

  return (
    <>
      <Form>
        <div className="form-row">
          <div className="col-md-12">
            <Field
              label="Recipients"
              name="recipient"
              id="recipient"
              menuPosition="bottom"
              required={true}
              component={MultiSelectField}
              children={emailRecipients}
              defaultValue={values.recipient}
            />
          </div>
          <div className="col-md-12">
            <Field
              label="Subject"
              name="emailSubject"
              id="emailSubject"
              type="text"
              required={true}
              component={TextField}
            />
          </div>
          <div className="col-md-12">
            <Field
              name="emailBody"
              id="emailBody"
              required={true}
              type={["body", "text"]}
              editorState={editorState}
              _onChange={onBodyTextChange}
              editorClassName={editorClassName}
              valueSet={null}
              category={"mass-email"}
              component={TextEditor}
            />
          </div>
        </div>
        <div className="col-md-12">
          <Field
            name="attachments"
            id="attachments"
            hidden={true}
          />
        </div>
      </Form>
      <div className="d-flex align-items-center">
        <input type="file" className="attachment" multiple onChange={uploadAttachments} />
        <div className="error-file-name">{uploadErrorText}</div>
      </div>
      <p>5 files maximum (each up to 10MB)</p>
    </>
  )
}

const SendMassEmailWithFormik = withFormik({
  mapPropsToValues: (props) => {
    return {
      emailSubject: '',
      emailBody: '',
      recipient: props.emails.map((_, index) => index)
    }
  },
  displayName: "send-mass-email",
  enableReinitialize: true,
  handleSubmit: (values, { props, setSubmitting }) => {
    props.sendMassEmail({
      variables: {
        input: {
          subject: values.emailSubject,
          body: values.emailBody ?? '',
          to: values.recipient,
          attachments: values.attachments
        }
      }
    }).then(res => {
      setSubmitting(false);
      if (res.data.sendMassEmail.code === 200) {
        props.onSubmitNotification(res.data.sendMassEmail.message)
      } else {
        props.onSubmitNotification("Error sending the email. Please try again.")
      }
    })
  },
  validationSchema: Yup.object().shape({
    emailSubject: Yup.string().required(REQUIRE_FIELD),
    emailBody: Yup.string().required(REQUIRE_FIELD),
    recipient: Yup.array()
      .min(1, "Recipients cannot be empty")
      .required("Recipients cannot be empty"),
  })
})(SendMassEmail)

export default compose(
  graphql(SEND_MASS_EMAIL, { name: 'sendMassEmail' })
)(SendMassEmailWithFormik)