const typeDefs = `
  type Community {
    id: Int!
  }

  type Csm {
    id: Int!
  }

  type Division {
    id: Int!
    name: String!
  }

  type DateRange {
    startDate: String
    endDate: String
  }

  type TitleCustomization {
    name: String!
    value String!
  }

  type NotificationProps {
    open: Boolean
    message: String
  }

  type User {
    role: String!
    name: String!
  }

  type AuthStatus {
    status: String
  }

  type CardState {
    cardTitle: String
  }

  type Query {
    selectedCommunity: [Community]
    selectedDivision: [Division]
    selectCsm:[Csm]
    selectOsc:[Csm]
    selectUser: [Csm]
    selectSingleUser: Csm
    selectCommunityId: Community
    getTitleCustomizations: [TitleCustomization]
    getNotificationProps: [NotificationProps]
    getAuthStatus: AuthStatus    
    tableParams: [CardState]
  }
`;

export default typeDefs;
