import React from "react";

import { formatDate, expiryFormatDate, phoneDisplay } from "../../../utils/helpers";
import TableView, {
  SortableHeader,
  stringComparator,
  TextCellWithMapper,
  TextHeader,
  dateComparator,
  TextCell,
  PhoneCell,
  EmailCell
} from "../../../components/new-table";

/** @module RealtorDetailsTableModule */

/**
 * This component is a table used in all realtors, YOY sales and YOY registration cards details page to display YOY sales or YOY registrations details. It contains pagination, searchbar and export to csv options.
 * It shows multiple columns displaying basic information related to YOY sales or YOY registration, some columns are shown for both cases and some are unique for YOY sale or registrations.
 *
 * @param {Object} props it contains all the mutations/queries in the conatiner as props
 * @param {Object} props.data it contains array of  YOY sales or YOY registration to be shown on table
 * @param {string} props.type it specifies wether its YOY sales or registrations.
 * @returns {JSX.Element} It returns jsx containing the table
 */
const RealtorDetailsTable = props => {
  const { data, type, loading } = props;

  /**
 *  This defines columns of the table. It has multiple columns, displaying information of the YOY sales or YOY registratio. Most of
 * the columns are common for both cases and some are unique for YOY sale or registrations.

 * @constant
 * @memberof module:RealtorDetailsTableModule
 */
  const columns = React.useMemo(() => [
    {
      id: "client_name",
      header: "Name",
      accessor: "client_name",
      component: SortableHeader,
      comparator: stringComparator,
      cell: {
        component: TextCell
      }
    },
    ...(type === "Sales"
      ? [
        {
          id: "2",
          header: "Community",
          accessor: "community",
          component: TextHeader,
          csvMapper: (field, accessor) =>
            field[accessor] ? field[accessor].name : "",
          cell: {
            component: TextCellWithMapper,
            mapper: (field, accessor) =>
              field[accessor] ? field[accessor].name : "",
            check: true
          }
        },
        {
          id: "lot",
          header: "Lot",
          accessor: "lot",
          component: SortableHeader,
          comparator: stringComparator,
          cell: {
            component: TextCell
          }
        },
        {
          id: "sale_date",
          header: "Date of Sale",
          accessor: "sale_date",
          component: SortableHeader,
          comparator: dateComparator,
          csvMapper: (field, accessor) =>
            field[accessor] ? formatDate(field[accessor]) : "",
          cell: {
            component: TextCellWithMapper,
            mapper: (field, accessor) =>
              field[accessor] ? formatDate(field[accessor]) : ""
          }
        },
        {
          id: "registration_date",
          header: "Date of Registration",
          accessor: "registration_date",
          component: SortableHeader,
          comparator: dateComparator,
          csvMapper: (field, accessor) =>
            field[accessor] ? formatDate(field[accessor]) : "",
          cell: {
            component: TextCellWithMapper,
            mapper: (field, accessor) =>
              field[accessor] ? formatDate(field[accessor]) : ""
          }
        }
      ]
      : [
        {
          id: "client_email",
          header: "Email",
          accessor: "client_email",
          component: SortableHeader,
          comparator: stringComparator,
          cell: {
            component: EmailCell,
            check: 'client_email_status'
          }
        },
        {
          id: "client_email_status",
          header: "Mailchimp Subscription",
          accessor: "client_email_status",
          hidden: true,
          csvMapper: (field, accessor) => field[accessor] ? "Subscribed" : "Unsubscribed",
          cell: { component: null },
        },
        {
          id: "client_phone",
          header: "Phone",
          accessor: "client_phone",
          component: SortableHeader,
          comparator: stringComparator,
          csvMapper: (field, accessor) => phoneDisplay(field[accessor]) || "",
          cell: {
            component: PhoneCell
          }
        },
        {
          id: "date",
          header: "Date of Registration",
          accessor: "date",
          component: SortableHeader,
          comparator: dateComparator,
          csvMapper: (field, accessor) =>
            field[accessor] ? formatDate(field[accessor]) : "",
          cell: {
            component: TextCellWithMapper,
            mapper: (field, accessor) =>
              field[accessor] ? formatDate(field[accessor]) : ""
          }
        },
        {
          id: "expiry",
          header: "Expiration Date",
          accessor: "date",
          component: SortableHeader,
          comparator: dateComparator,
          csvMapper: (field, accessor) =>
            field[accessor] ? expiryFormatDate(field[accessor]) : "",
          cell: {
            component: TextCellWithMapper,
            mapper: (field, accessor) =>
              field[accessor] ? expiryFormatDate(field[accessor]) : ""
          }
        }
      ]),
  ]);
  /**
   * 
   * This is an object that defines table title.
   *
   * @constant
   * @memberof module:RealtorDetailsTableModule
   */
  const tableOptions = {
    title: type === "Sales" ? "YOY Sales" : "YOY Registration"
  };

  return (
    <div>
      <TableView
        columns={columns}
        data={data ? data : []}
        tableOptions={tableOptions}
        loading={loading}
      />
    </div>
  );
};

export default RealtorDetailsTable;
