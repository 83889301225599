import { graphql, compose } from "react-apollo";

import LeadDetailsView from "./lead-details";
import {
  GET_LEADS_BY_FILTER,
} from "../../../graphql/queries";
import { SET_NOTIFICATION_PROPS } from "../../../cacheql/mutations";

const LeadDetailContainer = compose(
  graphql(GET_LEADS_BY_FILTER, {
    name: "getLeadDetail",
    options: props => {
      return {
        variables: {
          filter: {
            id: parseInt(props.match.params.id)
          }
        },
        fetchPolicy: "no-cache"
      };
    }
  }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" })
)(LeadDetailsView);

export default LeadDetailContainer;
