import React from 'react';
import { Button } from 'react-bootstrap';

/**
 * This component renders button from react bootstrap. It can be passed multiple properties to have different functionalities.
 * @param {Object} props
 * @param {JSX.Element} children custom element to render anything you want inside button
 * @param {String} className className assigned to button to have custom styles
 * @param {Function} onClick click handler, what should happen on clicking the button
 * @param {String} color default is green, but can be assigned any color
 * @param {Sring} btnValue name/title of the button
 * @param {Boolean} disabled to make button non-clickable
 * @param {JSX.Element} btnIcon icon to the left of title
 */
export default function CustomButton({
  children,
  className,
  onClick,
  color = 'green',
  btnValue,
  disabled,
  btnIcon,
  ...rest
}) {

  const btnClasses = className !== undefined
    ? `${className} custom-btn custom-btn-${color}`
    : `custom-btn custom-btn-${color}`;

  return (
    <Button
      id={`custom-btn-${btnValue}`}
      variant="secondary"
      className={btnClasses}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      {btnIcon}
      {btnValue}
      {children}
    </Button>
  );
}