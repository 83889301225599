import gql from "graphql-tag";

const GET_SELECTED_OSC = gql`
  query {
    selectedOscIds @client {
      ids
    }
  }
`;

export default GET_SELECTED_OSC;
