
import gql from 'graphql-tag';

const ADD_LEAD_DELETE_REQUEST = gql` 
  mutation addLeadDeleteRequests($input: AddLeadDeleteRequestInput!) {
    addLeadDeleteRequests(input: $input) {
      message
      code
    }
  }
`;

export default ADD_LEAD_DELETE_REQUEST;