import React, { useEffect, useContext } from "react";

import ActivityCardGenerator from "../../components/activity-card/activity-generator";
import { SUB_COUNT_BY_TYPE, SUB_NEW_CLIENT_REGISTRATION_COUNT, SUB_NEW_REALTOR_REGISTRATION_COUNT } from '../../graphql/subscriptions'
import { DashboardContext } from "../../context";
import "./activity-card.css";

/**
 * This component extracts some information from the context and passes it down to the {@link ActivityCardGenerator}.
 * It is also responsible for starting subscription on indivisual cards' count. Subscription is closed and restarted
 * on change of division selection. Card queries are also refetched here whenever a new update is made like any CRUD
 * operation on the page.
 * @param {Object} props 
 */
const ActivityCardContainer = ({ total, selectedDivision, ...props }) => {
  const { activityCards, updated, type } = useContext(DashboardContext);

  function RefetchCards() {
    return activityCards.forEach(card => props[card.title].refetch());
  }

  /**
   * This useEffect is resonsible for starting and ending subscriptions on division selection. Only webforms and
   * realtors page have subscription on cards count
   */
  useEffect(() => {
    let handlers = []
    if (type === "Web Forms") {
      handlers.push(...activityCards.map(card => props[card.title].subscribeToMore({
        document: SUB_COUNT_BY_TYPE,
        variables: {
          ...card.filter,
          division_id: selectedDivision.selectedDivision.id
        },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData) return prev;
          props[card.title].refetch();
          return prev;
        }
      })))

      handlers.push(...activityCards.map(card => props[card.secondaryDataQuery.name].subscribeToMore({
        document: SUB_COUNT_BY_TYPE,
        variables: {
          ...card.secondaryDataQuery.filter,
          division_id: selectedDivision.selectedDivision.id
        },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData) return prev;
          props[card.secondaryDataQuery.name].refetch();
          return prev;
        }
      })))
    }
    else if (type === "Client Registrations") {
      handlers.push(...activityCards.map(card => {
        if (card.isSubscriptionTab) {
          return props[card.title].subscribeToMore({
            variables: { division_id: selectedDivision.selectedDivision.id },
            document: SUB_NEW_CLIENT_REGISTRATION_COUNT,
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData) return prev;
              return { getRealtorClientRegistrationCount: subscriptionData.data.realtorClientRegistrationCount }
            }
          })
        }
        return null;
      }))
    }
    else if (type === "Realtor Registrations") {
      handlers.push(...activityCards.map(card => {
        if (card.isSubscriptionTab) {
          return props[card.title].subscribeToMore({
            variables: { division_id: selectedDivision.selectedDivision.id },
            document: SUB_NEW_REALTOR_REGISTRATION_COUNT,
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData) return prev;
              return { getRealtorRegistrationCount: subscriptionData.data.realtorRegistrationCount }
            }
          })
        }
        return null;
      }))
    }
    return () => {
      handlers.map(handler => handler && handler());
    }

  }, [selectedDivision.selectedDivision.id])

  /**
   * This useEffect is responsible for refetching cards data on any update 
   */
  useEffect(() => {
    if (type !== "Web Forms") {
      RefetchCards();
    }
  }, [updated]);


  return (
    <DashboardContext.Consumer>
      {({ type, updateCurrentCard, activityCards, currentCard }) => {

        return (
          <div className="d-flex mb-2 activity-cards-wrapper">
            <ActivityCardGenerator
              updateCurrentCard={updateCurrentCard}
              currentCard={currentCard}
              activityCards={activityCards}
              type={type}
              {...props}
            />
          </div>
        )
      }
      }
    </DashboardContext.Consumer>
  );
}


export default ActivityCardContainer;


