
import gql from 'graphql-tag';

const CREATE_PROSPECT = gql` 
  mutation addProspect($input: ProspectInput!, $registration_id: Int) {
    addProspect(input: $input, registration_id: $registration_id) {
      message
      code
      data{
        leadCommunities{
          id
          name
        }
        prospectCommunities{
          id
          name
        }
      }
    }
  }
`;

export default CREATE_PROSPECT;