import gql from "graphql-tag";

const GET_SHOW_MY_APPOINTMENTS_ONLY = gql`
  query {
    showMyAppointmentsOnly @client {
      show
    }
  }
`;

export default GET_SHOW_MY_APPOINTMENTS_ONLY;
