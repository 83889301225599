import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import { getCommunityIds } from "../../utils/helpers";
import { compose, graphql } from "react-apollo";
import { default as SidePanel } from "./side-navigation";
import { default as HomeNavigation } from "./home-navigation";
import { GET_UNREAD_COUNT, GET_FORM_SUBMISSION_COUNT, GET_REALTOR_REGISTRATION_COUNT } from "../../graphql/queries";
import { GET_SELECTED_COMMUNITY, GET_SELECTED_DIVISION } from "../../cacheql/queries";
import withUserContext from "../../hoc/with-user-context";
import {
  SUB_UNRESPONDED_COUNT,
  SUB_NEW_CLIENT_REGISTRATION_COUNT,
  SUB_NEW_REALTOR_REGISTRATION_COUNT
} from "../../graphql/subscriptions";
import "./layout.css";

/**
 * This component is a container for side-navigation. It contains all the side logic required on side navigation. Here
 * the subscriptions start on the webform and realtor nav-counts. In mobile view side nav is made to collapse forcefully
 * on clicking of sidenav item. 
 * Number of unregistered and unconverted webforms are updated on filtering divison or community from webform
 * dashboard. 
 * @param {Object} props 
 */
const SidePanelContainer = props => {
  const [unrespondedCount, setUnrespondedCount] = useState(0);
  const [unconvertedCount, setUnconvertedCount] = useState(0);
  const [realtorClientRegistrationCount, setRealtorClientRegistrationCount] = useState(0);
  const [realtorRegistrationCount, setRealtorRegistrationCount] = useState(0)
  const [isWebformCountFirstLoad, setIsWebformCountFirstLoad] = useState(true);
  const [isRealtorClientRegistrationCountFirstLoad, setIsRealtorClientRegistrationCountFirstLoad] = useState(true);
  const [isRealtorRegistrationFirstLoad, setIsRealtorRegistrationFirstLoad] = useState(true)

  useEffect(() => {
    // Bind the event listener
    const element = document.getElementsByClassName("main-container")[0]
    if (toggleClass && toggleClass !== "")
      element.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      element.removeEventListener("mousedown", handleClickOutside);
    };
  });

  useEffect(() => {
    if (
      props.getUnrespondedCount?.getFormSubmissionCount >= 0 ||
      props.getUnconvertedCount?.getFormSubmissionCount >= 0
    ) {
      if (isWebformCountFirstLoad) {
        props.getUnrespondedCount.subscribeToMore({
          document: SUB_UNRESPONDED_COUNT,
          fetchPolicy: "cache-and-network",
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData) return prev;
            props.getUnrespondedCount.refetch()
            return prev
          }
        });

        props.getUnconvertedCount.subscribeToMore({
          document: SUB_UNRESPONDED_COUNT,
          fetchPolicy: "cache-and-network",
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData) return prev;
            props.getUnconvertedCount.refetch()
            return prev
          }
        });
        setIsWebformCountFirstLoad(false)
      }
      setUnrespondedCount(props.getUnrespondedCount.getFormSubmissionCount);
      setUnconvertedCount(props.getUnconvertedCount.getFormSubmissionCount);
    }
  }, [props.getUnrespondedCount.loading, props.getUnconvertedCount.loading]);

  useEffect(() => {
    if (
      props.getRealtorClientRegistrationCount?.getRealtorClientRegistrationCount >= 0
    ) {
      if (isRealtorClientRegistrationCountFirstLoad) {
        props.getRealtorClientRegistrationCount.subscribeToMore({
          document: SUB_NEW_CLIENT_REGISTRATION_COUNT,
          fetchPolicy: "cache-and-network",
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData) return prev;
            setRealtorClientRegistrationCount(subscriptionData.data.realtorClientRegistrationCount);
            return { getRealtorClientRegistrationCount: subscriptionData.data.realtorClientRegistrationCount }
          }
        });
        setIsRealtorClientRegistrationCountFirstLoad(false)
      }
      setRealtorClientRegistrationCount(props.getRealtorClientRegistrationCount.getRealtorClientRegistrationCount);
    }
  }, [props.getRealtorClientRegistrationCount?.loading]);

  useEffect(() => {
    if (props.getRealtorRegistrationCount?.getRealtorRegistrationCount >= 0) {
      if (isRealtorRegistrationFirstLoad) {
        props.getRealtorRegistrationCount.subscribeToMore({
          document: SUB_NEW_REALTOR_REGISTRATION_COUNT,
          fetchPolicy: "cache-and-network",
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData) return prev;
            setRealtorRegistrationCount(subscriptionData.data.realtorRegistrationCount);
            return { getRealtorRegistrationCount: subscriptionData.data.realtorRegistrationCount }
          }
        });
        setIsRealtorRegistrationFirstLoad(false)
      }
      setRealtorRegistrationCount(props.getRealtorRegistrationCount.getRealtorRegistrationCount);
    }
  }, [props.getRealtorRegistrationCount?.loading])

  useEffect(() => {
    props.getRealtorRegistrationCount.refetch()
    props.getRealtorClientRegistrationCount.refetch()
  }, [realtorRegistrationCount, realtorClientRegistrationCount])

  const [toggleClass, setToggle] = React.useState("");

  const setToggleCallback = React.useCallback(() => {
    setToggle(toggleClass === "open" && "");
  });

  function handleClickOutside(event) {
    if (toggleClass !== "") {
      setToggle("")
    }
  }

  return (
    <nav
      id="side-container"
      className={"col-md-2 bg-dark sidebar pb-0"}
    >
      <div>
        <div
          className="btn custom-btn w-100 nav-toggle pb-2"
          onClick={() => setToggle(toggleClass.length > 0 ? "" : "open")}
        ><FaBars /> Menu </div>
        <div className="d-md-hidden">
          <HomeNavigation />
        </div>
      </div>
      <div
        className={"sidebar-sticky " + toggleClass}
      >
        <SidePanel
          unrespondedCount={unrespondedCount}
          unconvertedCount={unconvertedCount}
          realtorClientRegistrationCount={realtorClientRegistrationCount}
          realtorRegistrationCount={realtorRegistrationCount}
          callback={setToggleCallback}
        />
      </div>
    </nav>
  );
};

export default withUserContext(
  compose(
    graphql(GET_SELECTED_COMMUNITY, { name: "selectedCommunity" }),
    graphql(GET_SELECTED_DIVISION, { name: "selectedDivision" }),
    graphql(GET_FORM_SUBMISSION_COUNT, {
      name: "getUnrespondedCount",
      options: (
        { selectedDivision,
          selectedCommunity }) => {
        return {
          variables: {
            input: {
              division_id: selectedDivision.selectedDivision.id,
              community_ids: getCommunityIds(selectedCommunity),
              unresponded: true
            }
          },
          fetchPolicy: "cache-and-network",
          pollInterval: 60000
        }
      }
    }),
    graphql(GET_FORM_SUBMISSION_COUNT, {
      name: "getUnconvertedCount",
      options: (
        { selectedDivision,
          selectedCommunity }) => {
        return {
          variables: {
            input: {
              division_id: selectedDivision.selectedDivision.id,
              community_ids: getCommunityIds(selectedCommunity),
              unconverted: true
            }
          },
          fetchPolicy: "cache-and-network",
          pollInterval: 60000
        }
      }
    }),
    graphql(GET_UNREAD_COUNT, {
      name: "getRealtorClientRegistrationCount",
      options: ({ selectedDivision }) => {
        return {
          variables: {
            input: {
              division_id: selectedDivision.selectedDivision.id
            }
          },
          fetchPolicy: "cache-and-network",
          pollInterval: 60000
        }
      }
    }),
    graphql(GET_REALTOR_REGISTRATION_COUNT, {
      name: "getRealtorRegistrationCount",
      options: ({ selectedDivision }) => {
        return {
          variables: {
            input: {
              division_id: selectedDivision.selectedDivision.id
            }
          },
          fetchPolicy: "cache-and-network",
          pollInterval: 60000
        }
      }
    })
  )(SidePanelContainer)
);
