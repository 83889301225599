import gql from "graphql-tag";

const GET_CONVERSION_REPORT = gql`
  query getCustomerConversionReport(
    $limit: Int
    $pageNum: Int
    $filter: customerConversionReportFilter
  ) {
    getCustomerConversionReport(
      limit: $limit
      pageNum: $pageNum
      filter: $filter
    ) {
        customerConversionReports {
            id
            conversion_type
            conversion_datetime
            creator{
                id
                first_name
                last_name
                is_active
            }
            customer_name
            communities{
                id
                name
                is_active
            }
        }
        total_count
    }
  }
`;
export default GET_CONVERSION_REPORT;
