import { compose, graphql } from "react-apollo";
import { GET_REALTORS } from "../../../graphql/queries";
import {
  ADD_REALTOR,
  UPDATE_REALTOR,
  ADD_REALTOR_DELETE_REQUEST
} from "../../../graphql/mutations";
import {
  SET_NOTIFICATION_PROPS,
  SET_TABLE_PARAMS,
  SET_PREVIOUS_CARD
} from "../../../cacheql/mutations";
import RealtorsTable from "../realtors-table";
import { GET_TABLE_PARAMS, GET_SELECTED_DIVISION } from "../../../cacheql/queries";
import { getCardParams } from "../../../utils/helpers";

export default compose(
  graphql(SET_TABLE_PARAMS, { name: "setTableParams" }),
  graphql(SET_PREVIOUS_CARD, { name: "setPreviousCard" }),
  graphql(GET_TABLE_PARAMS, { name: "getTableParams" }),
  graphql(GET_SELECTED_DIVISION, { name: "selectedDivision" }),
  graphql(GET_REALTORS, {
    options: ({
      currentCard: { filter, mode, title },
      getTableParams: { tableParams },
      selectedDivision,
      ...props
    }) => {
      let params = getCardParams(title, tableParams);

      return {
        variables: {
          filter: {
            text: "",
            ...filter,
            ...(params && params.filter),
            division_id: selectedDivision.selectedDivision.id,
          },
          pageNum: (params && params.pageNum) || 0,
          limit: (params && params.limit) || 10,
          order:
            params && params.order && params.order.id !== -1
              ? [params.order.name, params.order.sort]
              : null,
          mode: mode
        },
        fetchPolicy: "cache-and-network"
      };
    }
  }),
  graphql(ADD_REALTOR, { name: "addRealtor" }),
  graphql(UPDATE_REALTOR, { name: "updateRealtor" }),
  graphql(ADD_REALTOR_DELETE_REQUEST, { name: "addRealtorDeleteRequests" }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" })
)(RealtorsTable);
