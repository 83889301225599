
import gql from 'graphql-tag';

const DELETE_RED_FLAG = gql` 
  mutation deleteRedFlag($input: RedFlagDeleteInput) {
    deleteRedFlag(input: $input) {
      message
      code
    }
  }
`;

export default DELETE_RED_FLAG;