import React, { useState, useContext } from "react";
import CustomButton from "../../components/custom-button";
import Dialog from "../../components/dialog/dialog";
import DeleteDialogBox from "../../components/dialog/delete-dialog-box";
import PermanentDeleteConfirmation from "../../components/dialog/permanent-delete";
import { goBack } from "../../utils/helpers";
import { Auth } from "../../rbac/rbac";
import { LEAD, PROSPECT, MARKETING_ONLY } from "../../utils/constants"

/**
 * @module DetailsDeleteButtonModule
 * 
 * this component is used to render the delete button in lead and prospect table, to send a delete request or permanent delete the lead or prospect.
 * @param {number} itemID id of the prospect/lead
 * @param {string} color specifies the color of the button
 * @param {string} tyoe specifies type of the customer
 * @param {object} history contains the path to go back  
 */
const DetailsDeleteButton = ({
  itemID,
  color,
  type,
  customerID,
  history,
  ...props
}) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [
    showPermanentDeletConfirmation,
    setShowPermanentDeletConfirmation,
  ] = useState(false);
  const [isPermDeleteSubmitted, setIsPermDeleteSubmitted] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [note, setNote] = useState("");
  const { is_manager, is_divmgr } = useContext(Auth);

  /**
   * It is used to render the delete confirmation dialog
   * @function
   * @inner
   * @memberof module:DetailsDeleteButtonModule
   * @see {@link module:DetailsDeleteButtonModule~DetailsDeleteButton}
   */
  const deleteConfirmationBody = () => {
    return <DeleteDialogBox setFunc={setNote} customer />;
  };

  /**
   * this function is used to handle the delete mutation
   * @function
   * @inner
   * @memberof module:DetailsDeleteButtonModule
   */
  const handleDeleteConfirmation = () => {
    setDisabled(true);
    if (type === MARKETING_ONLY) {
      props
        .addMarketingOnlyDeleteRequests({
          variables: { input: { marketing_only_ids: itemID, note } },
        })
        .then((res) => {
          if (res.data.addMarketingOnlyDeleteRequests.code === 200) {
            props.setNotificationProps({
              variables: {
                open: true,
                message: res.data.addMarketingOnlyDeleteRequests.message,
              },
            });
            goBack(history);
            setShowDeleteConfirmation(!showDeleteConfirmation);
            setDisabled(false);
          }
        })
        .catch((res) => {
          res.graphQLErrors.map((error) => {
            return error.message;
          });
        });
    } else if (type === LEAD) {
      props
        .addLeadDeleteRequests({
          variables: { input: { lead_ids: itemID, note } },
        })
        .then((res) => {
          if (res.data.addLeadDeleteRequests.code === 200) {
            props.setNotificationProps({
              variables: {
                open: true,
                message: res.data.addLeadDeleteRequests.message,
              },
            });
            goBack(history);
            setShowDeleteConfirmation(!showDeleteConfirmation);
            setDisabled(false);
          }
        })
        .catch((res) => {
          res.graphQLErrors.map((error) => {
            return error.message;
          });
        });
    } else if (type === PROSPECT) {
      props
        .addProspectDeleteRequests({
          variables: { input: { prospect_ids: itemID, note } },
        })
        .then((res) => {
          if (res.data.addProspectDeleteRequests.code === 200) {
            props.setNotificationProps({
              variables: {
                open: true,
                message: res.data.addProspectDeleteRequests.message,
              },
            });
            goBack(history);
            setShowDeleteConfirmation(!showDeleteConfirmation);
            setDisabled(false);
          }
        })
        .catch((res) => {
          res.graphQLErrors.map((error) => {
            return error.message;
          });
        });
    }
    setNote("");
  };

  /**
   * It is used to when permanent delete has successfully done and it is used to show the success notification and go back to the previous page.
   * @param {string} message message to be shown in notification bar
   * @function
   * @inner
   * @memberof module:DetailsDeleteButtonModule
   * @see {@link module:DetailsDeleteButtonModule~DetailsDeleteButton}
   */
  const onPermanentDeletionSuccess = (message) => {
    setDisabled(false)
    props.setNotificationProps({
      variables: {
        open: true,
        message: message,
      },
    });
    goBack(history);
  };

  /**
   * It is used to render the permanent delete confirmation dialog.
   * @param {number} deletCustomerID customer id
   * @param {boolean} isPermDeleteSubmitted boolean that shows if permanent delete is submitted
   * @param {funciton} onPermanentDeletionSuccess its a function called and permanent delete is successfully performed
   * @function
   * @inner
   * @memberof module:DetailsDeleteButtonModule
   * @see {@link module:DetailsDeleteButtonModule~DetailsDeleteButton}
   */
  const reConfirmationBody = (
    deleteCustomerId,
    isPermDeleteSubmitted,
    onPermanentDeletionSuccess
  ) => {
    return (
      deleteCustomerId && (
        <PermanentDeleteConfirmation
          customerId={deleteCustomerId}
          isSubmitted={isPermDeleteSubmitted}
          onSubmit={onPermanentDeletionSuccess}
        />
      )
    );
  };

  return (
    <>
      <Dialog
        show={showDeleteConfirmation}
        onHide={() => setShowDeleteConfirmation(!showDeleteConfirmation)}
        title="Delete Confirmation"
        body={deleteConfirmationBody()}
        click={handleDeleteConfirmation}
        isPermanentDelete={is_manager() || is_divmgr()}
        permanentDeleteClick={() => {
          setShowDeleteConfirmation(!showDeleteConfirmation);
          setShowPermanentDeletConfirmation(!showPermanentDeletConfirmation);
        }}
        clickname="YES"
        closename="NO"
        disabled={disabled}
      />
      <Dialog
        show={showPermanentDeletConfirmation}
        onHide={() =>
          setShowPermanentDeletConfirmation(!showPermanentDeletConfirmation)
        }
        title="Re-Confirmation"
        body={reConfirmationBody(
          customerID,
          isPermDeleteSubmitted,
          onPermanentDeletionSuccess
        )}
        click={() => {
          setIsPermDeleteSubmitted(!isPermDeleteSubmitted);
          setDisabled(true);
        }}
        clickname="Permanently Delete"
        closename="NO"
        disabled={disabled}
      />
      <CustomButton
        size="sm"
        btnValue="DELETE"
        color={color}
        onClick={() => setShowDeleteConfirmation(!showDeleteConfirmation)}
      />
    </>
  );
};

export default DetailsDeleteButton;