
import gql from 'graphql-tag';

const PERMANENT_DELETE = gql` 
  mutation permanentlyDeleteCustomer($customer_id: Int!) {
    permanentlyDeleteCustomer(customer_id: $customer_id) {
      message
      code
    }
  }
`;

export default PERMANENT_DELETE;