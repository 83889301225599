import gql from "graphql-tag";

const SELECT_OSC = gql`
  mutation selectOsc($selectedOsc: [Int]) {
    selectOsc(selectedOsc: $selectedOsc) @client {
      ids
    }
  }
`;

export default SELECT_OSC;
