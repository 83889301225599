import gql from 'graphql-tag';

const DELETE_HOMEOWNER_APPOINTMENT = gql` 
  mutation deleteHomeownerAppointment($input: HomeownerAppointmentDeleteInput!) {
    deleteHomeownerAppointment(input: $input) {
      message
      code
    }
  }
`;

export default DELETE_HOMEOWNER_APPOINTMENT;