import gql from 'graphql-tag';

const GET_ALL_TITLE_CUSTOMIZATION = gql`
{
  getAllTitleCustomizations{
    name
    value
  }
}
`;
export default GET_ALL_TITLE_CUSTOMIZATION;

