import { graphql, compose } from "react-apollo";
import WebformDetailsView from "./webform-details";
import {
  GET_ALL_STATUSES,
  GET_ARCHIVED_USERS_BYID,
  GET_FORM_SUBMISSION_BY_ID,
} from "../../../graphql/queries";
import { UPDATE_FORM_SUBMISSION_STATUS } from "../../../graphql/mutations";
import { SET_NOTIFICATION_PROPS } from "../../../cacheql/mutations";
import { DELETED_TYPE_ID } from "../customer-types";

const WebformDetailContainer = compose(
  graphql(GET_ALL_STATUSES, { name: "getAllStatuses" }),
  graphql(UPDATE_FORM_SUBMISSION_STATUS, {
    name: "updateFormSubmissionStatus",
  }),
  graphql(GET_FORM_SUBMISSION_BY_ID, {
    name: "getFormSubmissionById",
    options: (props) => {
      return {
        variables: {
          input: {
            id: parseInt(props.match.params.id),
          },
        },
        fetchPolicy: "no-cache",
      };
    },
  }),
  graphql(GET_ARCHIVED_USERS_BYID, {
    name: "ArchivedUser",
    skip: props =>
      !(props.getFormSubmissionById.getFormSubmission &&
      props.getFormSubmissionById.getFormSubmission.formSubmission.length &&
      props.getFormSubmissionById.getFormSubmission.formSubmission[0].customer_id &&
      props.getFormSubmissionById.getFormSubmission.formSubmission[0].type.id === DELETED_TYPE_ID),
    options: (props) => ({
      variables: {
        input: {
          customer_id: props.getFormSubmissionById.getFormSubmission
            ? props.getFormSubmissionById.getFormSubmission.formSubmission[0]?.customer_id
            : null,
        },
      },
    }),
  }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" })
)(WebformDetailsView);

export default WebformDetailContainer;
