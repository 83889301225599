import React, { useState } from "react";
import { compose, graphql } from "react-apollo";
import { default as SelectionListPopup } from "../../components/list-popup/selection-list-popup";
import {
  GET_AVAILABLE_HOMESITES,
  GET_UNAVAILABLE_HOMESITES,
  GET_AVAILABLE_OTHERS,
  GET_UNAVAILABLE_OTHERS,
  GET_ALL_TITLE_CUSTOMIZATION,
  GET_LOGGED_IN_COMMUNITIES
} from "../../graphql/queries";
import {
  UPDATE_TITLE_CUSTOMIZATIONS,
  SET_NOTIFICATION_PROPS
} from "../../cacheql/mutations";

import Others from "./others";
import Homesites from "./homesites";
import { isNotNULL } from "../../utils/helpers";
import MoreCustomizations from "./more-customizations";
import { ListGroup } from "react-bootstrap";
import LoadingWrapper from "../../components/loading-wrapper";
import { Auth } from "../../rbac/rbac";
import SourceTagCustomizations from "./source-tag-customization";
import LeadFilterCustomization from './lead-filter-customization'

/**
 * this component renders the other customizations tab in the customizations page. it contains 4 section, first we have communities dropdow, then we have homesites and others sections, and last we have more customizations section.
 * @param {*} props 
 */
const OtherCustomizations = ({
  getLoggedInUserCommunity: { getLoggedInUserCommunities },
  titleCustomization,
  ...props
}) => {
  const [communityId, setCommunityId] = useState(null);
  const [unavailableHomesites, setUnAvailableHomesites] = useState([]);
  const [availableHomesites, setAvailableHomesites] = useState([]);
  const [unavailableOthers, setUnAvailableOthers] = useState([]);
  const [availableOthers, setAvailableOthers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { is_manager, is_divmgr } = React.useContext(Auth);
  const isAdmin = is_manager() || is_divmgr()

  const handleCommunityItemSelection = item => {
    const id = item.id;
    setCommunityId(id);
    refetchHomeSites(id);
    refetchOthers(id);
  };

  const refetchHomeSites = (id, msg) => {
    notify(msg);
    refetchData("unavailableHomesites", setUnAvailableHomesites, id);
    refetchData("availableHomesites", setAvailableHomesites, id);
  };

  const refetchOthers = (id, msg) => {
    notify(msg);
    refetchData("unavailableOthers", setUnAvailableOthers, id);
    refetchData("availableOthers", setAvailableOthers, id);
  };

  const refetchData = (data, callback, id) => {
    id = id ? id : communityId;
    props[data].fetchMore({
      variables: {
        community_ids: id
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        callback({ ...fetchMoreResult });
      }
    });
  };

  const fetchCustomizationTexts = msg => {
    notify(msg);
    titleCustomization.refetch().then(res => {
      if (res.data) {
        props.updateTitleCustomizations({
          variables: { titleCustomizations: res.data.getAllTitleCustomizations }
        });
      }
    });
  };

  const notify = message => {
    if (message) {
      props.setNotificationProps({
        variables: { open: true, message: message }
      });
    }
  };

  return (
    <div className="p-3 pr-4">
      {isAdmin && (
        <>
          <SourceTagCustomizations />
          <LeadFilterCustomization />
        </>
      )}
      <LoadingWrapper
        component={
          <div id="admin-other-customizations-root">
            <h3 className='category-header'>Prospects</h3>
            <SelectionListPopup
              title="Communities"
              label="Select a Community"
              list={isNotNULL(getLoggedInUserCommunities) ? getLoggedInUserCommunities : null}
              mapper={field => {
                return (
                  isNotNULL(field) &&
                  field.map((item, key) => (
                    <ListGroup.Item
                      className="list-item"
                      onClick={() => handleCommunityItemSelection(item)}
                      key={key}
                    >
                      {item.name}
                    </ListGroup.Item>
                  ))
                );
              }}
              loading={state => setLoading(state)}
            />
            <div className="col-md-12 row row-sm-padding mt-2">
              <Homesites
                communityId={communityId}
                disabled={communityId ? false : true}
                unavailable={
                  isNotNULL(unavailableHomesites)
                    ? unavailableHomesites.getUnavailableHome_Sites
                    : null
                }
                available={
                  isNotNULL(availableHomesites)
                    ? availableHomesites.getAvailableHome_Sites
                    : null
                }
                update={refetchHomeSites}
              />
              <Others
                communityId={communityId}
                disabled={communityId ? false : true}
                unavailable={
                  isNotNULL(unavailableOthers)
                    ? unavailableOthers.getUnavailableOther_Dropdown_Values
                    : null
                }
                available={
                  isNotNULL(availableOthers)
                    ? availableOthers.getAvailableOther_Dropdown_Values
                    : null
                }
                update={refetchOthers}
              />
            </div>
            {(isAdmin) && (
              <MoreCustomizations
                texts={titleCustomization.getAllTitleCustomizations}
                update={fetchCustomizationTexts}
              />
            )}
          </div>
        }
        loading={loading}
      />
    </div>
  );
};

export default compose(
  graphql(UPDATE_TITLE_CUSTOMIZATIONS, { name: "updateTitleCustomizations" }),
  graphql(GET_LOGGED_IN_COMMUNITIES, { name: "getLoggedInUserCommunity" }),
  graphql(GET_UNAVAILABLE_HOMESITES, {
    name: "unavailableHomesites",
    options: () => ({ variables: { community_ids: 0 } })
  }),
  graphql(GET_AVAILABLE_HOMESITES, {
    name: "availableHomesites",
    options: () => ({ variables: { community_ids: 0 } })
  }),
  graphql(GET_UNAVAILABLE_OTHERS, {
    name: "unavailableOthers",
    options: () => ({ variables: { community_ids: 0 } })
  }),
  graphql(GET_AVAILABLE_OTHERS, {
    name: "availableOthers",
    options: () => ({ variables: { community_ids: 0 } })
  }),
  graphql(GET_ALL_TITLE_CUSTOMIZATION, { name: "titleCustomization" }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" })
)(OtherCustomizations);
