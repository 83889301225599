import React, { useEffect, useState, useRef } from "react";
import { compose, graphql } from "react-apollo";
import "./version-history.css";
import GroupIcon from "../../images/group.svg";
import FeatureIcon from "../../images/check-feature.svg";
import NoDataIcon from "../../images/graphic-no-prospect.png";
import FixIcon from "../../images/bug-fixes.svg";
import BulletedList from "../../components/lists/bulleted-list";
import { GET_VERSION_HISTORY } from "../../graphql/queries";
import LoadingWrapper from "../../components/loading-wrapper";
import NoDataPage from "../no-data-page/no-data-page";
import { getDate_ddmmmmyyyy_format } from "../../utils/helpers";

/**@module VersionHistoryView */

const InnerHTML = (props) => {
  const { html } = props;
  const divRef = useRef(null);

  useEffect(() => {
    const parsedHTML = document.createRange().createContextualFragment(html);
    divRef.current.appendChild(parsedHTML);
  }, []);

  return <div ref={divRef}></div>;
}

/**
 * This component is used to display the version history of the application. The list contains version number, date of publishing and a bulleted list of 
 * features and fixes that were part of that specific version release. 
 * @param {Object} props
 * @param {Object} props.data it contains an array of version histories to display on the page
 * @returns {JSX.Element} it returns a JSX Element containing a list of versions
 */
const VersionHistoryView = ({ data }) => {
  const [versionHistory, setVersionHistory] = useState([]);

   /**
   * Set the data in the state only when loading has been completed 
   */
  useEffect(() => {
    if (
      !data.loading &&
      data.getVersionHistory &&
      data.getVersionHistory.version_history
    ) {
      setVersionHistory(data.getVersionHistory.version_history);
    }
  }, [data]);

  return (
    <LoadingWrapper
      loading={data.loading}
      component={
        versionHistory ? (
          versionHistory.map((version, index) => {
            return (
              <div
                className="d-flex flex-column flex-md-row align-items-start versions-wrapper"
                key={index}
              >
                <div className="child version-number">
                  <div className="flex-parent">
                    <img alt="" src={GroupIcon} className="group-icon" />
                    <div className="version-title">
                      <h4 style={{fontWeight: '400', fontSize: '22px'}}>{`Version ${version.version}`}</h4>
                      <caption className="caption pt-0 pb-0">{`Published on ${version.release_date && getDate_ddmmmmyyyy_format(version.release_date)}`}</caption>
                    </div>
                  </div>
                  {version.recording_embed_code && (
                    <div className='recoding-view'>
                      <InnerHTML html={version.recording_embed_code} />
                    </div>
                  )}
                </div>
                <div className="child version-details">
                  <BulletedList
                    icon={FeatureIcon}
                    header="Features"
                    type="features"
                    list={version.features}
                    title_accessor="feature_id"
                    text_accessor="description"
                    style={{borderBottom: '1px solid #222'}}
                  />
                  <BulletedList
                    icon={FixIcon}
                    header="Fixes"
                    type="fixes"
                    list={version.fixes}
                    title_accessor="fix_id"
                    text_accessor="description"
                  />
                </div>
              </div>
            );
          })
        ) : (
          <NoDataPage
            backgroundImage={NoDataIcon}
            heading={"Version history not found"}
          />
        )
      }
    />
  );
};

export default compose(graphql(GET_VERSION_HISTORY))(VersionHistoryView);
