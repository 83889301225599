import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CustomButton from "../custom-button";

/**
 * this component is used to render hover action buttons of the tables, which contains icons and tooltips for each action.
 *
 * @param {object} icon icon of the button of the action
 * @param {string} tooltip text to shown on the icon
 * @param {string} textValue label of the button
 * @param {function} action action to be perfomed on click of the button
 * @param {object} field contains information related to the hovered row
 * @returns {JSX.Element}
 */
export const ActionCell = ({
  icon: Icon,
  tooltip,
  textValue,
  field,
  action,
  removeAction,
}) => {
  return removeAction && removeAction(field) ? null : (
    <OverlayTrigger overlay={tooltip ? <Tooltip>{tooltip}</Tooltip> : <p></p>}>
      <CustomButton
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          action(field);
        }}
        color="dark"
        size="sm"
        btnIcon={Icon ? <Icon /> : null}
        btnValue={
          textValue
        } /* TODO: add btn with only text styles to component */
      />
    </OverlayTrigger>
  );
};
