import gql from "graphql-tag";

const DELETE_CLIENT_REGISTRATION = gql`
  mutation deleteRealtorClientRegistration($client_registration_id: Int!) {
    deleteRealtorClientRegistration(client_registration_id: $client_registration_id){
        success
        message
        code
    }
  }
`;

export default DELETE_CLIENT_REGISTRATION;
