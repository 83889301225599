import { graphql, compose } from "react-apollo";

import DetailsDeleteButtonView from "./details-delete-button";
import {
  ADD_MARKETING_ONLY_DELETE_REQUEST,
  ADD_LEAD_DELETE_REQUEST,
  ADD_PROSPECT_DELETE_REQUEST,
} from "../../graphql/mutations";
import { SET_NOTIFICATION_PROPS } from "../../cacheql/mutations";

const DetailsDeleteButtonContainer = compose(
  graphql(ADD_MARKETING_ONLY_DELETE_REQUEST, { name: "addMarketingOnlyDeleteRequests" }),
  graphql(ADD_LEAD_DELETE_REQUEST, { name: "addLeadDeleteRequests" }),
  graphql(ADD_PROSPECT_DELETE_REQUEST, { name: "addProspectDeleteRequests" }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" }),
)(DetailsDeleteButtonView);

export default DetailsDeleteButtonContainer;