import React from "react";
import {
  checkDisabledEmailsLink,
  formatDate,
  phoneLink
} from "../../utils/helpers";

import "./archive-details-card.css"


/**
 * This compoent is used to show basic archived user's details. It renders two tables side by side. First table 
 * contains name and status, while the second table contains email, phone and address. Phone is clickable. 
 * 
 * @param {Object} props
 * @param {Object} props.data containing info about the user
 * @param {Object} props.archivedDetails contains archived information
 */
const ArchiveDetailsCard = props => {
  const { data, archivedDetails } = props;
  const firstPart = {
    NAME: data && data.first_name_1 + " " + data.last_name_1,
    STATUS:
      archivedDetails &&
      "Archived at " + formatDate(new Date(archivedDetails.archived_at))
  };
  const secondPart = {
    EMAIL: archivedDetails && checkDisabledEmailsLink(archivedDetails, 'primary_email_1', undefined, 'primary_email_1_status'),
    PHONE: data && data.cell_phone_1,
    ADDRESS: data && data.street_address
  };
  const handleCardData = card => {
    return Object.keys(card).map((key, index) => {
      return (
        <div key={index} className="archiveDetailsCard">
          <div className="d-flex vertical-align-middle " key={index}>
            <div
              className="pt-2 pb-2 archiveCardTitle"
              style={{
                color: "rgba(255, 255, 255, .5)",
                fontSize: "12px",
                width: "25%"
              }}
              key={index}
            >
              {key}
            </div>
              <div
                key={index + 1}
                className="pt-2 pb-2"
                style={{ color: key === "STATUS" && "red" }}
              >
                { card[key] ? key === "PHONE" ? phoneLink(card, key, false, true): card[key] : "-"}
              </div>
          </div>
          <hr
            className="p-0 m-0"
            style={{ borderColor: "#333", padding: "0" }}
          />
        </div>
      );
    });
  };
  return (
    <div className="row p-4">
      <div className="col-md-4" key="1">
        {handleCardData(firstPart)}
      </div>
      <div className="col-md-4" key="2">
        {handleCardData(secondPart)}
      </div>
    </div>
  );
};
export default ArchiveDetailsCard;
