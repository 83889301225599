
import gql from 'graphql-tag';

const ADD_PROSPECT_NOTE = gql` 
  mutation addProspectNote($input: ProspectNoteInput!) {
    addProspectNote(input: $input) {
      message
      code
      data
    }
  }
`;

export default ADD_PROSPECT_NOTE;