import gql from 'graphql-tag';

const DELETE_NOTE_ATTACHMENT = gql` 
  mutation deleteNoteAttachment($input: DeleteNoteAttachmentInput!) {
    deleteNoteAttachment(input: $input) {
      message
      code
    }
  }
`;

export default DELETE_NOTE_ATTACHMENT;