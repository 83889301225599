import gql from "graphql-tag";

export const UPDATE_WEBFORM_TYPE_AUTO_CONVERT = gql`
  mutation updateWebFormTypeAutoConvert(
    $web_form_type_id: Int!
    $community_status: Boolean!
    $customer_type: String
  ) {
    updateWebFormTypeAutoConvert(
      web_form_type_id: $web_form_type_id
      community_status: $community_status
      customer_type: $customer_type
    ) {
      code
      success
      message
    }
  }
`;
