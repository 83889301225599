import gql from 'graphql-tag';

const GET_ARCHIVED_USERS_BYID = gql`
  query getArchived_Customer($input: ArchivedCustomer_Filter, $pageNum: Int, $limit: Int, $search: String, $sort: [String]){
    getArchived_Customer(filter: $input, pageNum: $pageNum, limit: $limit, search: $search, sort: $sort){
        ArchivedCustomers {
          id
          first_name_1
          first_name_2
          last_name_1
          last_name_2
          primary_email_1
          primary_email_2
          primary_email_1_status
          primary_email_2_status
          secondary_emails_1
          secondary_emails_2
          home_phone
          cell_phone_1
          cell_phone_2
          street_address
          city
          osc_notes
          division {
            id
            name
          }
          realtor{
            id
            first_name
            last_name
            primary_email
            cell_phone
          }
          state{
            id
            name
          }
          zip
          archived_at
          customer_origination_area{
            id
            name
          }
        }
        total_count
    }
  }
`;
 export default GET_ARCHIVED_USERS_BYID;