import gql from 'graphql-tag'

const GET_ALL_LEAD_CLASSIFICATION_FILTERS = gql`
{
    getAllLeadClassificationFilters{
      id
      name
    }
  }
`

export default GET_ALL_LEAD_CLASSIFICATION_FILTERS
