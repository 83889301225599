import React, { useEffect, useMemo } from "react";
import { compose, graphql } from "react-apollo";

import { APPROVE_DELETE_REQUEST } from "../../graphql/mutations";
import { GET_DELETE_REQUESTS } from "../../graphql/queries";
import {
  SET_NOTIFICATION_PROPS,
  SET_TABLE_PARAMS,
} from "../../cacheql/mutations";
import DeleteRequestsMassUpdateItems from "./delete-request-mass-update";
import FadeIn from "../../components/fade-in";

import TableView, {
  SortableHeader,
  stringComparator,
  TextCell,
  ActionCell,
  TextHeader,
  ListCell,
  TextCellWithMapper,
} from "../../components/new-table";
import reducer, { getInitialState } from "./delete-request-reducer";
import { FaCheck, FaTimes, FaExclamationCircle } from "react-icons/fa";
import CustomLoader from "../../components/custom-loader";
import { GET_TABLE_PARAMS } from "../../cacheql/queries";
import { getCardParams, getUpdatedParams } from "../../utils/helpers";

/** @module DeleteRequestTableModule */

/**
 * This component is a table used to display all the delete request. It contains pagination,
 * mass-update and export to csv options. It has couple of hover-actions. It shows multiple columns displaying basic
 * information related to delete requests. Table uses reducer to maintain its state. It also accesses store to check if
 * table parameters are already stored or not.
 *
 * @param {Object} props it contains all the mutations/queries in the conatiner as props
 * @param {Object} props.data it contains array of delete requests to be shown on table
 * @param {Object} props.getTableParams it contains cards info stored inside cache
 * @returns {JSX.Element} It returns jsx containing the table
 */
const DeleteRequestTable = ({
  data,
  approveDeleteRequests,
  setNotificationProps,
  getTableParams: { tableParams },
  ...props
}) => {
  const cacheParams = getCardParams("Delete Requests", tableParams);

  const [state, dispatch] = React.useReducer(
    reducer,
    getInitialState({ loading: data.loading, cache: cacheParams })
  );

  const {
    deleteRequests,
    loading,
    render,
    userPageCount,
    pageSize,
    // isSubscription,
    // ...params
  } = state;

  const callDispatch = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  /**
   * Either set the data in state or set the loading to inform the table what to render.
   */
  useEffect(() => {
    if (!data.loading)
      callDispatch("UPDATE", {
        render: true,
        loading: false,
        deleteRequests: data.getDeleteRequests.delete_requests,
        userPageCount: Math.ceil(data.getDeleteRequests.total_count / pageSize),
      });
    if (data.loading) callDispatch("UPDATE", { loading: data.loading });
  }, [data]);

  /**
   * It is used to approve or reject delete requests, it contains the approve mutation.
   * @param {array} rows selected rows
   * @param {boolean} approve specifies wether to approve or reject the delete requests
   * @function
   * @inner
   * @memberof module:DeleteRequestTableModule
   * @see {@link module:DeleteRequestTableModule~DeleteRequestTable}
   */
  const handleApprove = (rows, approve) => {
    let DeleteRequestInput = [];
    rows.map((item) =>
      DeleteRequestInput.push({
        source_id: item.source_id,
        type: item.type,
      })
    );

    approveDeleteRequests({
      variables: {
        input: {
          deleteRequestTuples: DeleteRequestInput,
          approve: approve,
        },
      },
    }).then((res) => {
      if (res.data.approveDeleteRequests.code === 200) {
        setNotificationProps({
          variables: {
            open: true,
            message: res.data.approveDeleteRequests.message,
          },
        });
        data.refetch();
      }
    });
  };

  /**
   *  This defines columns of the table. It has multiple columns, displaying information of the delete requests.
   * @constant
   * @memberof module:DeleteRequestTableModule
   */
  const columns = useMemo(
    () => [
      {
        id: "1",
        header: "Type",
        accessor: "type",
        component: SortableHeader,
        comparator: stringComparator,
        cell: { component: TextCell },
      },
      {
        id: "2",
        header: "Name",
        accessor: "first_name_1",
        component: SortableHeader,
        comparator: stringComparator,
        csvMapper: (field, accessor) =>
          field[accessor] ? field[accessor] + " " + field["last_name_1"] : "",
        cell: {
          component: ({ field, accessor }) => (
            <td>{field[accessor] + " " + field["last_name_1"]}</td>
          ),
        },
      },
      {
        id: "3",
        header: "Community(ies)",
        accessor: "all_communities",
        component: TextHeader,
        sortable: false,
        csvMapper: (field, accessor) =>
          field[accessor].map((item) => item.name),
        cell: {
          component: ListCell,
          mapper: (field) =>
            field.map((item, index) => <div key={item.id}>{item.name}</div>),
        },
      },
      {
        id: "4",
        header: "Submitter",
        accessor: "submitter",
        component: TextHeader,
        sortable: false,
        csvMapper: (field, accessor) =>
          field[accessor]
            ? field[accessor].first_name + " " + field[accessor].last_name
            : "",
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) =>
            field[accessor] &&
            field[accessor].first_name + " " + field[accessor].last_name,
        },
      },
      {
        id: "5",
        header: "Note",
        accessor: "note",
        component: SortableHeader,
        comparator: stringComparator,
        cell: { component: TextCell },
      },
    ],
    []
  );

  /**
   * Array of objects containing different actions which can be performed on row hover.
   * @constant
   * @memberof module:DeleteRequestTableModule
   */
  const hoverActions = useMemo(
    () => [
      {
        icon: FaCheck, //textValue: "APPROVE",
        tooltip: "Approve",
        component: ActionCell,
        action: ({ isSelected, uuid, ...row }) => {
          handleApprove([row], true);
        },
      },
      {
        icon: FaTimes, //textValue: "REJECT",
        tooltip: "Reject",
        component: ActionCell,
        action: ({ isSelected, uuid, ...row }) => {
          handleApprove([row], false);
        },
      },
    ],
    []
  );

  /**
   *  it is used to render the table title, and to show notifications related to the number of delete requests.
   * @constant
   * @memberof module:DeleteRequestTableModule
   * @returns {JSX.Element}
   */
  const headerNotification = () => {
    return (
      <>
        <div className="d-flex align-items-start flex-md-wrap">
          <h6 className="mb-0">Deletion Requests</h6>
          <div className="d-flex text-danger ml-3" style={{ fontSize: "12px" }}>
            <FaExclamationCircle size={18} className="text-danger mr-1 ml-0" />
            <p className="text-danger mb-0">
              {data.getDeleteRequests && data.getDeleteRequests.total_count}{" "}
              Deletion Requests
            </p>
          </div>
        </div>
      </>
    );
  };

  //Fetchmore and pagination functions
  /**
   * It is a callback function which is called on any table change like: pagination, page-size.
   * Table passes its current state so that we can update the table parameters in store and state, which are used in
   * refetching the data with correct parameters.
   *
   * @function
   * @inner
   * @memberof module:DeleteRequestTableModule
   * @see {@link module:DeleteRequestTableModule~DeleteRequestTable}
   */
  const fetchData = React.useCallback((tableProps) => {
    if (loading) {
      return;
    }

    const variables = {
      search: tableProps.searchTerm,
      limit: tableProps.pageSize,
      pageNum: tableProps.currentPage,
      order: {
        id: tableProps.sortColumn.id,
        name: tableProps.sortColumn.name,
        sort: tableProps.sortColumn.sort,
      },
    };

    if (tableProps.searchTerm !== state.search) {
      variables.pageNum = 0;
    }

    callDispatch("UPDATE", {
      ...variables,
      pageSize: tableProps.pageSize,
      loading: true,
    });

    const pageCount = Math.ceil(
      data.getDeleteRequests.total_count / tableProps.pageSize
    );
    if (pageCount !== userPageCount)
      callDispatch("UPDATE", {
        userPageCount: pageCount,
      });

    props
      .setTableParams({
        variables: {
          cardsParams: getUpdatedParams(tableParams.cardsParams, {
            cardTitle: "Delete Requests",
            params: variables,
          }),
        },
      })
      .then((res) => {
        callDispatch("UPDATE", { loading: false });
      });
  });

  /**
   * This is an object that defines table title, and massActions for the table.
   *
   * @constant
   * @memberof module:DeleteRequestTableModule
   */
  const tableOptions = {
    title: headerNotification(),
    massActions: {
      render: (getSelectedRows) => {
        let rows = getSelectedRows().map((x) => x);
        return (
          <DeleteRequestsMassUpdateItems
            selectedDeleteRequests={rows}
            handleApprove={handleApprove}
          />
        );
      },
    },
  };

  return (
    <div>
      <FadeIn show={render}>
        <div className="mt-3">
          <TableView
            columns={columns}
            data={deleteRequests}
            tableOptions={tableOptions}
            loading={loading}
            hoverActions={hoverActions}
            userPageCount={userPageCount}
            userTotalCount={
              !loading ? data.getDeleteRequests.total_count : null
            }
            fetchData={fetchData}
            currentPage={state.pageNum}
            pageSize={state.pageSize}
            searchTerm={state.search}
            sortColumn={state.order}
            controlled={true}
          />
        </div>
      </FadeIn>
      {!render && (
        <CustomLoader
          style={{ marginLeft: "auto", marginRight: "auto", zIndex: 9999 }}
        />
      )}
    </div>
  );
};
export default compose(
  graphql(SET_TABLE_PARAMS, { name: "setTableParams" }),
  graphql(GET_TABLE_PARAMS, { name: "getTableParams" }),
  graphql(GET_DELETE_REQUESTS, {
    options: ({ getTableParams: { tableParams }, ...props }) => {
      const params = getCardParams("Delete Requests", tableParams);

      return {
        variables: {
          pageNum: (params && params.pageNum) || 0,
          limit: (params && params.limit) || 10,
          order:
            params && params.order && params.order.id !== -1
              ? `${params.order.name} ${params.order.sort}`
              : null,
          search: (params && params.search) || "",
        },
        fetchPolicy: "cache-and-network",
      };
    },
  }),
  graphql(APPROVE_DELETE_REQUEST, { name: "approveDeleteRequests" }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" })
)(DeleteRequestTable);
