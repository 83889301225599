import { compose, graphql } from "react-apollo"
import MAKE_PHONE_CALL from "../../graphql/mutations/csm/make-phone-call"
import CallDialog from "./call-dialog"
import { SET_NOTIFICATION_PROPS } from "../../cacheql/mutations"

export default compose(
    graphql(MAKE_PHONE_CALL, { name: "makePhoneCall" }),
    graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" }),
)(CallDialog)

