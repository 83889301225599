import React from "react";
import { graphql } from "react-apollo";
import { GET_ACTIVITY_TYPES } from "../../graphql/queries";
import { Form, Field, withFormik } from "formik";
import { ActivityReportFilterForm, ActivityReportSchema } from "./activity-report-filter-schema";
import { isNotNULL } from "../../utils/helpers";
import SelectFieldNonInt from "../../components/fields/select-field-non-int";
import CustomButton from "../../components/custom-button";
import CheckboxField from "../../components/fields/checkbox-field";

/**
 * This component is used to render filter form for activity-report table.
 * It is a very basic form containing a select field to list down all the types of activities and a checkbox to filter traffic.
 * This way user can filter out the data on table by activity type.
 * On clicking apply filter button, filters are set inside table which in turn causes table refetching the table data with new filter.
 * @param {Object} props 
 */
const ActivityReportFilter = (props) => {
  const { getAllActivityTypes } = props
  return (
    <Form className="filter-menu">
      <div className="form-row">
        <div className="col-md-12">
          <Field
            name="type"
            label="Types"
            id="type"
            classStyle="filter-select-field"
            component={SelectFieldNonInt}
          >
            <option value="">All Types</option>
            {getAllActivityTypes?.getAllActivityTypes?.map((type, index) => (
              <option key={type.name} value={type.name}>
                {type.name}
              </option>
            ))}
          </Field>
        </div>
      </div>

      <div className="form-row">
        <div className="col-md-12">
          <Field
            name="traffic"
            label="Traffic"
            id="traffic"
            classStyle="filter-select-field"
            component={CheckboxField}
          />
        </div>
      </div>

      <div className="form-row">
        <CustomButton
          type="submit"
          btnValue="APPLY FILTERS"
          className="filter-apply-btn"
        />
      </div>
    </Form>
  );
};

const ActivityReportFilterFormView = withFormik({
  mapPropsToValues: (props) => {
    const { filter } = props;
    return isNotNULL(filter) ?
      {
        type: filter.activity_type,
        traffic: filter.traffic
      }
      : ActivityReportFilterForm;
  },
  handleSubmit: (values, { props }) => {
    props.setShow(false);
    props.setFilter({
      activity_type: values.type,
      traffic: values.traffic
    });
  },
  displayName: "activity-report-filter-form",
  enableReinitialize: true,
  validationSchema: ActivityReportSchema,
})(ActivityReportFilter)

export default graphql(GET_ACTIVITY_TYPES, { name: "getAllActivityTypes" })(ActivityReportFilterFormView)