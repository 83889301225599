import gql from 'graphql-tag';

const MARK_NOTIFICATION_READ = gql` 
  mutation markNotificationsAsRead($ids: [ID!]) {
    markNotificationsAsRead(ids: $ids) {
      message
      code
    }
  }
`;

export default MARK_NOTIFICATION_READ;