import gql from "graphql-tag";

const GET_DIVISION_BY_COMMUNITY_ID = gql`
  query getDivisionByCommunityId($community_id: Int) {
    getDivisionByCommunityId(community_id: $community_id) {
        id
        name
        timezone
    }
  }
`;

export default GET_DIVISION_BY_COMMUNITY_ID;