import React from 'react';
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaRegQuestionCircle } from "react-icons/fa";

/**
 * this component is used to display a tooltip on activitycards and toolbars.
 * @param {object} props component props
 * @param {string} description Description to be used in tooltip
 * @param {string} placement Placement of the tooltip
 */
const Help = ({ description, placement }) => {
    return (
        <OverlayTrigger placement={placement} overlay={<Tooltip style={{zIndex: 10000}}>{description}</Tooltip>}>
            {<FaRegQuestionCircle />}
        </OverlayTrigger>
    );
};

Help.propTypes = {
    // description: PropTypes.string,
    placement: PropTypes.string
};

Help.defaultProps = {
    placement: 'bottom'
};

export default Help;
