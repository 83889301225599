
import gql from 'graphql-tag';

const MARK_LEAD_FOLLOW_UP_DONE = gql` 
mutation markDoneLeadFollowUp($input: LeadFollowUpMarkDoneInput!) {
  markDoneLeadFollowUp(input: $input){
      message
      code
    }
  }
`;

export default MARK_LEAD_FOLLOW_UP_DONE;