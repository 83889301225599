import gql from 'graphql-tag';

const GET_TITLE_CUSTOMIZATION = gql`
query TitleCustomizations($name: String!){
  getTitleCustomization(name:$name){
    name
    value
  }
}
`;
export default GET_TITLE_CUSTOMIZATION;

