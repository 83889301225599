import React, { useState, useEffect } from "react";
import { Card, Col } from "react-bootstrap";
import HistoryButton from "../../common-lead-prospect-details/history-button";
import ListPopup from "../../../components/list-popup/list-popup";
import DetailsSectionTable from "../../../components/table/custom-details-table";
import { checkDisabledEmailsLink, phoneLink, inActiveMapper } from "../../../utils/helpers";
import CustomButton from "../../../components/custom-button";
import WebFormEditForm from "../edit-webform";
import Drawer from "../../../hoc/Drawer";
import { getStringDescendantElements } from "../../../components/new-table/utils";

export const getEmails = (data) => {
  let emails = [];
  data.length > 0 && data.forEach((item) => emails.push(item.email));
  return emails;
};

/**
 * @module WebformDetailsCardModule
 * This component is used to show basic webform details along with edit button.
 * On clicking the button webform edit form opens up, to edit the information.
 *
 * @param {Object} props
 * @param {Object} props.data data containing details
 */
const WebformDetailsCard = (props) => {
  const { data } = props;
  const [firstPart, setFirstPart] = useState([]);
  const [secondPart, setSecondPart] = useState([]);
  const [showSideBar, setShowSideBar] = useState(false);

  const getFormData = ({ firstname, lastname, phone, email, form_submission_id }) => {
    return { firstname, lastname, phone, email, form_submission_id };
  };

  /**
   * this function is used as form type mapper.
   * @param {object} field contains information of the form type
   * @function
   * @inner
   * @memberof module:WebformDetailsCardModule
   * @returns {JSX.Element}
   */
  const typeMapper = (field) => {
    return field.length > 0
      ? field.map((item, index) => (
        <div
          key={index}
          className="d-flex align-items-center"
          style={{ color: "#888" }}
        >
          {item ? item : "-"}
        </div>
      ))
      : [
        <div className="d-flex align-items-center" style={{ color: "#888" }}>
          {"-"}
        </div>,
      ];
  };

  /**
 * this function is used as division mapper.
 * @param {object} field contains information of the division
 * @function
 * @inner
 * @memberof module:WebformDetailsCardModule
 * @returns {JSX.Element}
 */
  const divisionMapper = (field) => {
    return field.length > 0
      ? field.map((item, index) => (
        <div
          key={index}
          className="d-flex align-items-center"
          style={{ color: "#888" }}
        >
          {item ? item.name : "-"}
        </div>
      ))
      : [
        <div className="d-flex align-items-center" style={{ color: "#888" }}>
          {"-"}
        </div>,
      ];
  };

  /**
   * this function handles form type, division and community arrays. each type has a different mapper.
   * @param {object} data contains information of the communities or form types
   * @param {string} type defines if its form type or community
   * @function
   * @inner
   * @memberof module:WebformDetailsCardModule
   * @returns {JSX.Element}
   */
  const handleLists = (data, type) => {
    let mapper = type === "community" ? inActiveMapper : type === "division" ? divisionMapper : typeMapper;
    return (
      <ListPopup
        data={getStringDescendantElements(data, type)}
        mapper={mapper}
      />
    );
  };

  /**
   * updates and sets the first and second parts of the details card based on the data, and each time the data has been changed.
   */
  useEffect(() => {
    if (data) {
      let name = data.firstname ? data.firstname : "";
      name += data.lastname ? ` ${data.lastname}` : "";
      setFirstPart({
        Name: name,
        Email: checkDisabledEmailsLink(data, "email", getEmails),
        Phone: phoneLink(data, "phone"),
        Type: data.type.name,
        "Existing Communities": data.customer_communities && (
          <ListPopup data={data.customer_communities} mapper={inActiveMapper} />
        ),
      });
      setSecondPart({
        Type: data.form_type,
        Division: data.division?.name,
        Community: data.community?.name,
      });
    }
  }, [data]);

  const handleSubmit = () => {
    props.getFormSubmissionById.refetch();
    setShowSideBar(false);
  }

  return (
    <Card className="details-card pt-2" as={Col}>
      <Card.Body style={{ padding: "0.5rem", overflow: "hidden" }}>
        <div className="d-flex justify-content-between">
          <h3>Webform Details</h3>
          <div className="d-flex justify-content-end">
            {data && data.customer_id && (
              <HistoryButton
                name={data ? data.firstname + " " + data.lastname : ""}
                id={data ? data.customer_id : null}
              />
            )}
            <div className="edit-button">
              <CustomButton
                size="sm"
                color="green"
                btnValue="Edit"
                onClick={() => setShowSideBar(true)}
              />
            </div>
          </div>

          {showSideBar && (
            <Drawer
              show={showSideBar}
              toggleSideBar={() => setShowSideBar(false)}
            >
              <WebFormEditForm
                data={data && getFormData(data)}
                setNotificationProps={props.setNotificationProps}
                onSubmit={handleSubmit}
                close={() => setShowSideBar(false)}
              />
            </Drawer>
          )}
        </div>
        <div className="details-side-responsive">
          <div className="details-side-section1">
            <DetailsSectionTable
              data={firstPart}
              tableHeader="Customer Details"
            />
          </div>
          <div className="details-side-section2">
            <DetailsSectionTable
              data={secondPart}
              tableHeader="Other Details"
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default WebformDetailsCard;