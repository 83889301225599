import React from "react";
// import { useEffect, useState } from "react";
// import { compose, graphql } from "react-apollo";

// import { GET_USER } from "../../cacheql/queries";
import { NavContainer, PageContainer } from "../../containers";
import { default as HomeNavigation } from "./home-navigation";
import { default as MainContent } from "./main-content";
import { default as SidePanel } from "./sidenav-container";
import NotificationContainer from "./notification-container";
import { Auth } from "../../rbac/rbac";
import { default as AppBar } from "./top-navigation";
import "./layout.css";
import ViewAsLayover from "../../views/layout/view-as-layover"

/**
 * This component is responsible for dividing the application into top bar, sidebar and main component. It uses 
 * auth context to decide what to render when authenticated and what not.
 * @param {Object} props 
 */
const Layout = ({
  updateTitleCustomizations,
  titleCustomization,
  ...props
}) => {

  return (
    <>
      <Auth.Consumer>
        {auth =>
          auth.isAuthenticated ? (
            <NavContainer>
              <span className="d-sm-hidden col-md-2 text-center"><HomeNavigation /></span>
              {auth.is_logged_in_as() && <ViewAsLayover name={`${auth.user.first_name} ${auth.user.last_name}`} />}
              <AppBar name={auth.user.first_name} />
            </NavContainer>
          ) : (
            <div></div>
          )
        }
      </Auth.Consumer>
      <PageContainer id="page-container">
        <div id="page-cont-view" className="main row">
          <Auth.Consumer>
            {auth =>
              auth.isAuthenticated ? (
                <>
                  <SidePanel />
                  <NotificationContainer />
                </>
              ) : (
                <div></div>
              )
            }
          </Auth.Consumer>
          <MainContent {...props} />
        </div>
      </PageContainer>
    </>
  );
};

export default Layout;
