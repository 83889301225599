import React from "react";
import PropTypes from "prop-types";
import Head from "../dashboard-title";
import './customization.css';

/**
 * This is a customization card that contains two boxes rendered using {@link CustomizationOptionBox}.
 * Items can be switched from one box to an other in two ways either by double clicking an item, or by selecting items in one box and press the arrow button.
 * @param {Object} props
 * @param {String} props.cardTitle title of the card
 * @param {String} props.firstCardTitle title of the first box
 * @param {String} props.secondCardTitle title of the second box
 * @param {CustomizationOptionBox} props.firstCardView first box containing list items
 * @param {CustomizationOptionBox} props.secondCardView second box containing list items
 * @param {JSX.Element} props.moveToFirstCardView button to move items from the second card to the first one
 * @param {JSX.Element} props.moveToSecondCardView button to move items from the first card into the second one
 * @param {JSX.Element} props.addNewView input box along with a button to right to add new Items
 */
const OtherCustomizationCard = ({ ...props }) => {
  return (
    <div className='col-padding other-customization-root' id="activity-root">
      <div className="mr-md-2 mb-2 mb-md-0">
        <div sm={12} md={12} className="customization-header row d-none" id="customization-card-header">
          <div className="row title-container">
            <h3 className="header" id="header">
              {props.cardTitle}
            </h3>
          </div>
        </div>
        <div className='col-padding other-customization-card-root d-flex align-items-start justify-content-between' id="other-customization-card-root">
          <div className="col-12">
            <div className="row">
              <div className='col-padding col-lg-5' id="all-other-customization-col" >
                <Head title={props.firstCardTitle} />
                {props.firstCardView}
              </div>

              <div className="custom-btn-container left-right-arrows mx-2" >
                <div id="update-other-wrapper" className="customization-arrows d-lg-flex flex-lg-column">
                  {props.moveToSecondCardView}
                  {props.moveToFirstCardView}
                </div>
              </div>

              {props.secondCardTitle ? (
                <div className='col-padding col-lg-5' id="available-other-customization-col" >
                  <Head title={props.secondCardTitle} />
                  {props.secondCardView}
                </div>
              ) : null}

              {props.addNewView && (
                <div className="add-other-wrapper w-100 mt-2">
                  <Head title={props.addNewView.title} />
                  <div className="add-other-input-wrapper mt-1">
                    {props.addNewView.children}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

OtherCustomizationCard.propTypes = {
  cardTitle: PropTypes.string,
  firstCardTitle: PropTypes.string,
  firstCardView: PropTypes.node,
  addNewView: PropTypes.object,
  moveToFirstCardView: PropTypes.node,
  moveToSecondCardView: PropTypes.node,
  secondCardTitle: PropTypes.string,
  secondCardView: PropTypes.node,
};

export default OtherCustomizationCard