import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
// do not separate or move the following imports (trust me)
// ----------------------------------------------
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
// ----------------------------------------------
import {
  Button,
  ButtonToolbar,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { CalenderContext } from "./context";
import CustomToolbar from "./toolbar";
import {
  formatDataForDashboardCalendar,
  formatDataForCalendar,
  isEmpty,
  isNotNULL,
  getIconByEvent,
  getShortNotation
} from "../../utils/helpers";

import "./calendar.css";

/**
 * @typedef {Object} Style
 * @property {String} backgroundColor any color to be used as background color
 * @property {String} borderLeft left border's size and color
 * @property {String} opacity opacity ranging from 0-1
 */

/**
 * It uses react-big-calendar component to display a big calendar displaying all the events of the customer along with a legend and some controls.
 */
class BigCalendar extends React.Component {
  /**
   * Initializes calendar view, today's date, events to be displayed and id of the user for which calendar is being displayed.
   * @param {Object} props Component props
   * @param {Number} props.id Id of the customer
   * @param {String} props.type Type of the page on which calendar is being rendered
   * @param {Object} props.data Data to be displayed on calendar
   * @param {JSX.Element} props.popover Component to be displayed on clicking event on calendar
   */
  constructor(props) {
    super(props);
    this.state = {
      calendarView: "month",
      displayDate: new Date(),
      events: !isEmpty(this.props.data)
        ? this.props.type === "Dashboard"
          ? formatDataForDashboardCalendar(this.props.data)
          : formatDataForCalendar(this.props.data, this.props.type)
        : [],
      prospect: {
        id: this.props.id,
      },
    };
  }

  componentWillReceiveProps = (newProps) => {
    const oldProps = this.props.data;
    if (oldProps !== newProps.data) {
      this.setState({
        events:
          newProps.type === "Dashboard"
            ? formatDataForDashboardCalendar(newProps.data)
            : formatDataForCalendar(newProps.data, newProps.type),
      });
    }
  };

  handleNavigate = (date, view, action) => {
    this.setState({ displayDate: moment(date).toDate() });
  };

  /**
   * This function takes an event as input and generate style according to the type of event.
   * @method
   * @param {Object} event Contains all the information related to an event (Appointment, Email, etc)
   * @returns {Style} style style object according to the type of evet
   */
  eventStyleGetter = (event) => {
    let color = "rgba(76, 126, 249, 0.3)";
    let borderColor = "#4c7ef9";
    let opacity = "1";
    if (event.__typename === "Appointment") {
      color = "rgba(183, 214, 135, 0.3)";
      borderColor = "#92af00";
    } else if (event.__typename === "PhoneCall") {
      color = "rgba(80, 227, 197, 0.3)";
      borderColor = "#50E3C2";
    } else if (event.__typename === "Text") {
      color = "rgb(87, 71, 49,0.3)";
      borderColor = "#C48E48";
    }
    if (event.__typename === "Mass Email") {
      color = "rgba(148, 0, 255, 0.3)";
      borderColor = "#9400ff";
    } else if (event.__typename === "Email") {
      color = "rgba(255, 122, 0, 0.3)";
      borderColor = "#FF7A00";
    } else if (event.__typename === "MailchimpEmailType") {
      color = "rgba(208, 2, 27, 0.3)";
      borderColor = "#D0021B";
    } else if (event.__typename === "CustomerFormSubmission") {
      color = "rgba(255, 94, 94, 0.3)";
      borderColor = "#FF5E5E";
    } else if (event.__typename === "Prospect_Walk_In") {
      color = "rgba(168, 66, 100, 0.3)";
      borderColor = "#A84264";
    }
    if (event.done || isNotNULL(event.responder) || event.responded_at) {
      opacity = "0.5";
    }
    return {
      style: {
        backgroundColor: color,
        borderLeft: "3px solid" + borderColor,
        opacity,
      },
    };
  };

  handleDoneClick() {
    document.body.click();
  }

  addActivitySubmit = (message) => {
    this.props.onSubmit(message);
  };

  setGroupEvent = (groupEvent, item, others) => {
    const { event, ...rest } = groupEvent;
    let eventToReturn = { ...rest };
    eventToReturn.event = { ...item, ...others };
    eventToReturn.title = item.subject;
    return eventToReturn;
  };

  /**
   * This function decides how the event will look like, and how it will work. Every event is a button colored according to its type, on clicking it a popover opens up displaying required information and operations
   * @method
   * @param {Object} event Contains all the information related to an event (Appointment, Email, etc)
   */
  Event = (event) => {
    let PopoverView = this.props.popover;
    let groupEvent = Object.assign({}, event);
    const { email, __typename, ...rest } = groupEvent.event;

    return (
      <ButtonToolbar>
        <OverlayTrigger
          name={event}
          trigger="click"
          placement="bottom"
          flip={true}
          rootClose={true}
          overlay={
            <Popover
              id="popover-basic"
              className="event-details-popover dropdown"
              style={{ maxWidth: "none" }}
              onClick={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
              }}
            >
              <PopoverView
                item={
                  groupEvent.event.__typename === "Email" ||
                    groupEvent.event.__typename === "Mass Email" ||
                    groupEvent.event.__typename === "MailchimpEmailType"
                    ? this.setGroupEvent(groupEvent, email, rest)
                    : event
                }
                {...this.props}
                editComp={this.props.editComp}
                handleDoneClick={this.handleDoneClick}
              />
            </Popover>
          }
        >
          <Button
            className="calendar-popover-button text-white"
            style={{
              width: "100%",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              paddingRight: "2px",
            }}
          >
            <div className="d-flex align-items-center">
              {getIconByEvent(event.event.typeTitle)}
              {this.props.type === "Dashboard"
                ? `${event.event.last_name_1 || event.event.first_name_1} ${event.event.community ? "- " + event.event.community.name : ""}`
                : `${getShortNotation(event.event.typeTitle)} ${event.event.community ? "- " + event.event.community.name : ""}`
              }
            </div>
          </Button>
        </OverlayTrigger>
      </ButtonToolbar>
    );
  };

  render() {
    const localizer = momentLocalizer(moment);

    const calendarFormats = {
      monthHeaderFormat: "MM/DD/YYYY",
    };

    const calendarOptions = {
      popup: true,
      selectable: true,
      className: "isomorphicCalendar",
      components: {
        event: this.Event,
        toolbar: CustomToolbar,
      },
    };

    const value = {
      data: this.props.data,
      location: this.props.location,
      history: this.props.history,
      submitHandler: this.addActivitySubmit,
      calendarView: this.state.calendarView,
      leadCalendar: this.props.leadCalendar,
      type: this.props.type,
      id: this.props.id,
    };
    return (
      <CalenderContext.Provider value={value}>
        <div style={{ paddingBottom: "2rem" }}>
          <Calendar
            {...calendarOptions}
            formats={calendarFormats}
            localizer={localizer}
            events={this.state.events}
            eventPropGetter={this.eventStyleGetter}
            defaultDate={new Date()}
            date={this.state.displayDate}
            onNavigate={this.handleNavigate}
            drilldownView={null}
            step={10}
            showMultiDayTimes
            onRangeChange={(range) =>
              this.props.onRangeChange && this.props.onRangeChange(range)
            }
          />
        </div>
      </CalenderContext.Provider>
    );
  }
}

BigCalendar.propTypes = {
  data: PropTypes.object,
  leadCalendar: PropTypes.bool,
};

export default BigCalendar;
