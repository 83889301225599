import React, { useState, useEffect } from "react";
import ListPopup from "../../../components/list-popup/list-popup";
import EditLeadView from "../dashboard/edit-lead-view";
import HistoryButton from "../../common-lead-prospect-details/history-button";
import DetailsSectionTable from "../../../components/table/custom-details-table";
import SubscriptionHistoryContainer from "../../common-lead-prospect-details/subscription-history-button"
import { Card, Col } from "react-bootstrap";
import { 
  checkDisabledEmailsLink, 
  phoneLink, 
  inActiveMapper, 
  handleSecondaryEmails, 
  getAllEmails 
} from "../../../utils/helpers";
import "./lead-details.css";


/**
 * This component is used to render card details section on he right side of the page. It divides the details card into
 * four sections. Buyer 1, buyer 2, general, and other optional fields. It also contains 3 buttons to view history
 * and subscription history of the prospect and also the edit button. 
 * @param {Object} props 
 * @param {Object} lead lead info 
 */
const LeadDetail = ({data: lead, ...props }) => {
  const [showBuyer2, setShowBuyer2] = useState(true);
  const [buyer1, setBuyer1] = useState([]);
  const [buyer2, setBuyer2] = useState([]);
  const [optional, setOptional] = useState([]);
  const [realtor, setRealtor] = useState([])

  useEffect(() => {
    if (lead) {
      setBuyer1({
        "First Name": lead.first_name_1,
        "Last Name": lead.last_name_1,
        "Primary Email": checkDisabledEmailsLink(lead, "primary_email_1", getAllEmails, "primary_email_1_status"),
        "Secondary Email(s)": lead.secondary_emails_1.length ? handleSecondaryEmails(
          lead,
          "secondary_emails_1",
          getAllEmails
        ) : null,
        "Cell#": phoneLink(lead, "cell_phone_1")
      });
      setBuyer2({
        "First Name": lead.first_name_2,
        "Last Name": lead.last_name_2,
        "Primary Email": checkDisabledEmailsLink(lead, "primary_email_2", getAllEmails, "primary_email_2_status"),
        "Secondary Email(s)": lead.secondary_emails_2.length ? handleSecondaryEmails(
          lead,
          "secondary_emails_2",
          getAllEmails
        ) : null,
        "Cell#": phoneLink(lead, "cell_phone_2")
      });
      setRealtor({
        "Realtor Name": lead.realtor && `${lead.realtor.first_name} ${(lead.realtor?.last_name ?? "")}`,
        "Realtor Email": lead.realtor && checkDisabledEmailsLink(lead.realtor, "primary_email", null, "primary_email_status"),
        "Realtor Cell#": lead.realtor && phoneLink(lead.realtor, "cell_phone")
      });
      setOptional({
        "Lead Notes": lead.other_notes,
        "Address": lead.street_address,
        "City": lead.city,
        "State": lead.state?.name ?? null,
        "Zip": lead.zip,
        "Quality Score": lead.quality_score,
        "Engagement Score": lead.engagement_score,
        "Community(ies)": lead.communities.length ? handleLists(lead, 'communities') : null,
        "Division(s)": lead.divisions.length ? handleLists(lead, 'divisions') : null,
        "OSC Notes": lead.osc_notes,
        "Lead Source": lead.customer_origination_area?.name ?? null,
        "Lead Classification": lead.lead_classification_filters.length ? handleLeadClassifications(lead) : null
      });
    }
    if (
      lead &&
      (lead.first_name_2 ||
        lead.last_name_2 ||
        lead.primary_email_2 ||
        lead.secondary_emails_2.length ||
        lead.cell_phone_2)
    ) {
      setShowBuyer2(true);
    } else {
      setShowBuyer2(false);
    }
  }, [lead, props.Realtor]);

  const handleLists = (data, type) => {
    return (
      <ListPopup
        data={data[type]}
        mapper={inActiveMapper}
      />
    );
  };

  const handleLeadClassifications = data => {
    return (
      <ListPopup
        data={data.lead_classification_filters}
        mapper={(data) => {
          return data.map((item, index) => (
            <div key={index} className="d-flex align-items-center">
              {item ? item.name : "-"}
            </div>
          ));
        }}
      />
    )
  }

  return (
    <Card className="details-card pt-2" as={Col}>
      <Card.Body style={{ padding: "0.5rem", overflow: "hidden" }}>
        <div className="d-flex justify-content-between">
          <h3>Lead Details</h3>
          <div className="d-flex justify-content-end">
            <SubscriptionHistoryContainer
              name={
                lead
                  ? `${lead.first_name_1} ${lead.last_name_1}` 
                  : ""
              }
              customer_id={lead?.customer_id ?? null}
            />
            <HistoryButton
              name={
                lead
                  ? `${lead.first_name_1} ${lead.last_name_1}` 
                  : ""
              }
              id={lead?.customer_id ?? null}
              primaryEmail1={lead?.primary_email_1 ?? null}
              primaryEmail2={lead?.primary_email_2 ?? null}
            />
            {lead && (
              <EditLeadView
                leadData={lead}
                textButton
                btnValue="Edit"
                update={message => props.onSubmit(message)}
                history={props.history}
                isDetails
              />
            )}
          </div>
        </div>
        <div className="details-side-responsive">
          <div className="details-side-section1">
            <DetailsSectionTable data={buyer1} tableHeader="Buyer#1" />
          </div>
          {showBuyer2 && <div className="details-side-section2">
            <DetailsSectionTable data={buyer2} tableHeader="Buyer#2" />
          </div>}
          <div className="details-side-section3">
            <DetailsSectionTable
              data={optional}
              tableHeader="Optional Fields"
            />
          </div>
          {lead?.realtor &&
            (<div className="details-side-section3">
              <DetailsSectionTable data={realtor} tableHeader="Realtor" />
            </div>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default LeadDetail
