import PropTypes from "prop-types";
import React from "react";
import TableRow from "./table-row";


/**
 * This component is used in {@link CustomTable} to display the header of the table
 */
class TableHead extends React.Component {
  /**
   * @param {Array} tableHeaderData Header of the table
   */
  render() {
    const { tableHeaderData } = this.props;
    return (
      <thead style={{ color: "white" }}>
        {tableHeaderData[1] instanceof Array ? (
          tableHeaderData.map((prop, key) => {
            return <TableRow tableRowData={prop} key={key} />;
          })
        ) : (
          <TableRow tableRowData={tableHeaderData} key={0} />
        )}
      </thead>
    );
  }
}

TableHead.defaultProps = {
  tableHeaderColor: "gray"
};

TableHead.propTypes = {
  tableHeaderData: PropTypes.arrayOf(PropTypes.string)
};

export default TableHead;
