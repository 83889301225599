import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownButton } from "react-bootstrap";
import "./fields.css";

/**
 * This field renders a dropdown containing a list of options. It takes input the list which is to be rendered and sets field value on selection
 * @param {Object} props 
 * @param {String} props.listType type of list 
 * @param {Array} props.dropdownList list which is to be rendered as dropdown items
 * @param {Boolean} props.isFilterField if this field is rendered on normal form or filterform
 */
const ScoreSelectField = props => {
  const {
    id,
    label,
    field,
    field: { value },
    form: { setFieldValue },
    listType,
    dropdownList,
    isFilterField
  } = props;

  const [text, setText] = useState("");

  useEffect(()=>{
    setText(dropdownList.find(obj => obj.eventKey === value)?.value || (listType==="BOOL"? "Select...": "Score"))
  },[value])

  const style = listType !== "BOOL"? {
    borderLeftColor: value && value > 0 ? 
    dropdownList.find(obj => obj.eventKey === value)?.color : isFilterField ? '#3b3b3b' : "black",
    borderLeftWidth: 2,
    borderLeftStyle: "solid",
  }: {};

  const myOnChange = (eventKey, e) => {
    let valToSet = null
    if (listType === "BOOL") {
      valToSet = (eventKey? eventKey === 'true': null);
    } else {
      valToSet = isNaN(parseInt(eventKey, 10)) || eventKey === 0 ?
        null : parseInt(eventKey, 10);
    }

    setFieldValue(field.name, valToSet, true);
  };

  return (
    <div
      id={id + "-form-group"}
      className="form-group score-label d-flex justify-content-between align-items-baseline mb-0 position-fix"
    >
      <label htmlFor={id + "-input"} className="control-label">
        {label}
      </label>
      <DropdownButton
        alignRight
        drop="down"
        variant="secondary"
        title={text}
        id={isFilterField ? `filter-score-select-${label}` : `score-select-${label}`}
        key="down"
        onSelect={myOnChange}
        style={style}
        className={isFilterField ? 'filter-rating-dropdown' : "rating-dropdown"}
      >
        {
          dropdownList.map((item) => (
            <Dropdown.Item className={item.style} eventKey={item.eventKey} key={item.eventKey}>
              {item.value}
            </Dropdown.Item>
          ))
        }
      </DropdownButton>
    </div>
  );
};

ScoreSelectField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  children: PropTypes.array
};

export default ScoreSelectField;
