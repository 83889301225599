import React, { useState, useContext } from "react";
import { useQuery } from "react-apollo-hooks";
import { FaTrashAlt } from "react-icons/fa";
import { Card, ButtonToolbar } from "react-bootstrap";
import { FaFileAlt, FaPen, FaPaperclip } from "react-icons/fa";
import { formatDate, get5WordsForTitle, formatActivityDate } from "../../utils/helpers";
import { GET_NOTE_ATTACHMENT } from "../../graphql/queries";
import { Auth } from "../../rbac/rbac";
import { LEAD, PROSPECT, REALTOR } from "../../utils/constants"
import CustomButton from "../../components/custom-button";
import NoteButton from "../../components/note-button";
import Dialog from "../../components/dialog/dialog";

/**
 * @module NoteListModule
 *
 * @param {object} props component props
 * @param {object} data contains all information related to the notes
 * @param {string} type type of the customer
 * @param {function} onSubmit
 */
const NoteList = (props) => {
  const { user } = useContext(Auth);
  const { data, onSubmit, type } = props;
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState(null);

  const { refetch } = useQuery(GET_NOTE_ATTACHMENT, { skip: true })

  const getDownloadUrl = async (attachment_id, file_path) => {
    let { data } = await refetch({
      input: {
        attachment_id,
        file_path
      }
    })

    data && window.open(data.getNoteAttachment)
  }

  /**
   * this function is used to sort the notes
   * @function
   * @inner
   * @memberof module:NoteListModule
   * @param {object} a
   * @param {object} b
   */
  function handleNotesSort(a, b) {
    if (new Date(a.createdAt) > new Date(b.createdAt)) return -1;
    if (new Date(a.createdAt) < new Date(b.createdAt)) return 1;
    return 0;
  }
  let reversedNotes =
    data && data.notes.length > 0 ? data.notes.sort(handleNotesSort) : null;
  let oldDate = new Date();

  /**
   * this function is used show/hide the delete confirmation dialog
   * @function
   * @inner
   * @memberof module:NoteListModule
   * @param {number} id id of the note
   */
  const handleClick = (id) => {
    setShowDeleteConfirmation(!showDeleteConfirmation);
    setNoteToDelete({
      type_id: props.id,
      note_id: id
    });
  };

  /**
   * this function is used when confirmation has been made and to delete the note
   * @function
   * @inner
   * @memberof module:NoteListModule

   */
  const handleDelete = () => {
    if (type === PROSPECT) {
      props
        .deleteProspectNote({
          variables: {
            input: { id: noteToDelete.note_id }
          },
        })
        .then((res) => {
          if (res.data.deleteProspectNote.code === 200) {
            setShowDeleteConfirmation(!showDeleteConfirmation);
            onSubmit(res.data.deleteProspectNote.message);
          }
        });
    } else if (type === REALTOR) {
      props
        .deleteRealtorNote({
          variables: {
            input: { id: noteToDelete.note_id }
          },
        })
        .then((res) => {
          if (res.data.deleteRealtorNote.code === 200) {
            setShowDeleteConfirmation(!showDeleteConfirmation);
            onSubmit(res.data.deleteRealtorNote.message);
          }
        });
    } else if (type === LEAD) {
      props
        .deleteLeadNote({
          variables: {
            input: { id: noteToDelete.note_id }
          },
        })
        .then((res) => {
          if (res.data.deleteLeadNote.code === 200) {
            setShowDeleteConfirmation(!showDeleteConfirmation);
            onSubmit(res.data.deleteLeadNote.message);
          }
        });
    } else {
      props
        .deleteHomeownerNote({
          variables: {
            input: { id: noteToDelete.note_id }
          },
        })
        .then((res) => {
          if (res.data.deleteHomeownerNote.code === 200) {
            setShowDeleteConfirmation(!showDeleteConfirmation);
            onSubmit(res.data.deleteHomeownerNote.message);
          }
        });
    }
  };

  /**
   * this function renders the toggle part of the notes
   * @function
   * @inner
   * @memberof module:NoteListModule
   * @param {object} item contains information related to the notes
   * @returns {JSX.Element}
   */
  const toggle = (item) => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <FaFileAlt
            style={{ opacity: "0.4", marginRight: "0.5rem", marginTop: "-2px" }}
          />{" "}
          {get5WordsForTitle(item.description)}
        </div>
        <div>
          <div className="d-flex align-items-center">
            {item.note_attachment.length ?
              <FaPaperclip style={{ opacity: "0.4", marginRight: "0.5rem", marginTop: "-2px" }} />
              : null}
            {formatActivityDate(item.createdAt)}
            <ButtonToolbar>
              <NoteButton
                {...props}
                id={props.id}
                btnIcon={<FaPen />}
                type={type}
                className="btn-icon"
                function="UPDATE"
                notes={item}
                style={item.creator?.id !== user.id ? { display: 'none' } : {}}
              />
              <CustomButton
                btnIcon={<FaTrashAlt />}
                onClick={() => handleClick(item.id)}
                className="btn-icon"
                style={item.creator?.id !== user.id ? { display: 'none' } : {}}
              />
            </ButtonToolbar>
          </div>
          <div className="creator-name">
            {item.creator ? ` ${item.creator.first_name + " " + item.creator.last_name}` : " -"}
          </div>
        </div>
      </div>
    );
  };

  /**
   * this function renders the collapse part of the notes
   * @function
   * @inner
   * @memberof module:NoteListModule
   * @param {object} item contains information related to the notes
   * @returns {JSX.Element}
   */
  const collapse = (item) => {
    return (
      <Card.Body className="noted-descr-body notes">
        <div>
          <label> Description: </label>{` ${item.description}`}
        </div>
        <div className="d-flex attachment">
          {item.note_attachment.length ? item.note_attachment.map((attachment, index) => {
            return (
              <div
                className="attachment-name"
                key={index}
                onClick={() => getDownloadUrl(attachment.id, attachment.file_path)}
              >
                {attachment.file_path}
              </div>
            )
          })
            : null}
        </div>
      </Card.Body>
    );
  };
  return (
    <div className="notes-card">
      {reversedNotes &&
        reversedNotes.map((item, index) => {
          let date = new Date(item.createdAt);
          let NewDate = null;
          if (date.getDate() !== oldDate.getDate() || index === 0) {
            NewDate = formatDate(date);
            oldDate = date;
          }
          return (
            <div key={index}>
              <Dialog
                show={showDeleteConfirmation}
                onHide={handleClick}
                title="Delete Confirmation"
                body="Are you sure you want to delete?"
                click={handleDelete}
                clickname="YES"
                closename="NO"
              />
              {NewDate && (
                <div className="text-muted small mb-2 mt-2">{NewDate}</div>
              )}

              {props.render(toggle(item), collapse(item), index)}
            </div>
          );
        })}
    </div>
  );
};

export default NoteList;