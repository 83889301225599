import React, { useState, useEffect } from "react";
import { graphql, compose } from "react-apollo";
import SelectionListPopup from "../../components/list-popup/selection-list-popup";
import MultiSelectionListPopup from "../../components/list-popup/multi-selection-list";
import { ListGroup } from "react-bootstrap";
import { filterArray, isNotNULL } from "../../utils/helpers";
import { ROLE_CSM, ROLE_OSC } from "../../utils/constants";

/** @module CachedSelectionModule */

/**
 * This component is used to render a dropdown, which receives its list of options from query made to the backend
 * in its container.  It can be a multi-select or a single select dropdown used to render communities, csms, etc. Any
 * item selected from this dropdown gets stored inside store using client side mutation. All this is done dynamically
 * using props. 
 * @param {*} props 
 * @param {String} props.firstItem used as a label as first pre-selected value
 * @param {Object} props.data list of options received by container 
 * @param {Object} props.data2 for User dropdown 2 list of datas are received, this is the second one
 * @param {String} props.dataQueryTitle path to get to list of options  
 * @param {String} props.dataQueryTitle2 path to get to list of options for 2nd data array
 * @param {Function} props.setSelectedQuery setter to set selected option
 * @param {String} props.setSelectedVariables property name which has to be set while calling setSelectedQuery
 * @param {Query} props.getSelectedQuery query to get selected value from the store
 * @param {String} props.selectedQueryTitle path to get to selected data
 * @param {Array} props.preSelectedValue used to pre-select an option for multi-selection
 * @param {Object} props.showList contains multiple properties to identify to which role-type what should be visible  
 * @param {Boolean} props.selectMultiple to identify multi-select
 * @param {Object} props.withDivision containing selected division id to query accordingly
 */
const ListComp = ({
  firstItem,
  data,
  data2,
  dataQueryTitle,
  dataQueryTitle2,
  setSelectedQuery,
  setSelectedVariables,
  getSelectedQuery,
  selectedQueryTitle,
  preSelectedValue,
  showList = null,
  selectMultiple = false,
  withDivision = false,
  dataQueryWithDivision,
  ...props
}) => {
  const [listData, setListData] = useState();

  // this is used to pre-select an option in multi-select
  useEffect(() => {
    if (getSelectedQuery[selectedQueryTitle].ids === null)
      handleMutiSelect(preSelectedValue)
  }, [preSelectedValue])

  // this useEffect is responsible for setting data to list of options that are to be shown in dropdown
  useEffect(() => {
    if (data[dataQueryTitle] && showList?.isLeadView && props.title === "Communities") {
      setListData([...data[dataQueryTitle]]);
    } else if (props.title === "User") {
      const csmList = data[dataQueryTitle] ? data[dataQueryTitle].map(item => ({ ...item, name: `${item.first_name} ${item.last_name}` })) : []
      const oscList = data2 && data2[dataQueryTitle2] ? data2[dataQueryTitle2].map(item => ({ ...item, name: `${item.first_name} ${item.last_name}` })) : []
      setListData(filterArray([...csmList, ...oscList], (arrVal, othVal) => arrVal.id === othVal.id))
    } else if (props.title === "CSM" || props.title === "OSC") {
      const datalist = data[dataQueryTitle]?.map(item => ({ ...item, name: `${item.first_name} ${item.last_name}` }))
      setListData(showList?.roleOSC ? [] : datalist)
    } else {
      setListData(data[dataQueryTitle])
    }
  }, [data[dataQueryTitle], data2]);

  /**
   * This function is responsible to set newly selected data from single-select to the store
   * @inner
   * @function
   * @memberof module:CachedSelectionModule
   * @param {Object} item newly-selected item
   */
  const handleItemSelection = item => {
    setSelectedQuery({
      variables: item ? { id: item.id, ...(props.title !== "User" || props.title !== "Community") ? { name: item.name } : {} } : {}
    });
  };

  /**
   * This function is responsible to set newly selected items from multi-select to the store
   * @inner
   * @function
   * @memberof module:CachedSelectionModule
   * @param {Array} items array containing newly selected items
   */
  const handleMutiSelect = items => {
    setSelectedQuery({
      variables: items ? { [setSelectedVariables]: items } : {}
    });
  };

  /**
   * This function is responsible to retrieve multiple-selected values stored in the store. If store contains nothing
   * empty array is returned. This function is a helper function used in {@link getMultiSelections}
   * @inner
   * @function
   * @memberof module:CachedSelectionModule
   * @param {Array} items array containing newly selected items
   * @returns {Array}
   */
  const getDefaultValues = () => {
    let selectedItems = getSelectedQuery[selectedQueryTitle].ids;
    return selectedItems?.length > 0 ? selectedItems : [];
  };

  /**
   * This function is responsible to find out selected values inside the original array and then create an array of 
   * ids of the selected items. Ids are pushed to the array.
   * This function is a helper function used in {@link getMultiSelections}  
   * @inner
   * @function
   * @memberof module:CachedSelectionModule
   * @param {Array} items array containing newly selected items
   */
  const getFilteredValues = items => {
    return items.reduce((defaultVal, item) => {
      const label =
        listData &&
        listData.filter(listItem => listItem.id === item).find(item => item);

      if (label) {
        defaultVal.push(label.id);
      }
      return defaultVal;
    }, []);
  };

  /**
   * This function is responsible to get selected values from the store for multi-select, filter out the required ones, and make any
   * required changes back to the store and finally return which items were selected from the dropdown
   * @inner
   * @function
   * @memberof module:CachedSelectionModule
   * @returns {Array}
   */
  const getMultiSelections = () => {
    const defaults = getDefaultValues()
    let filteredDefaults = getFilteredValues(defaults);

    /**
     * If filtered length is not equal to defaults length this means that some of the items were removed from the initial
     * array, and that change needs to be set inside client store to stay consistent.
     */
    if (listData && filteredDefaults.length !== 0 && filteredDefaults.length !== defaults.length) {
      handleMutiSelect(filteredDefaults);
    }

    if (listData && withDivision && withDivision.id) {
      if (filteredDefaults.length > 0) handleMutiSelect();
      if (!filteredDefaults.length > 0) handleMutiSelect(filteredDefaults);
    }
    return filteredDefaults;
  };

  /**
   * This function categorizes the list received against the options OSC or CSM if the list contains role.
   * Otherwise pushes values without labels
   * @param {Array} list
   * @returns {Array}
   */
  const mapCategories = (list) => {
    const vals = []
    const csm = []
    const osc = []

    list && list.forEach((item) => {
      if (!item.roles) vals.push(item)
      else {
        if (item.roles.includes(ROLE_CSM)) csm.push(item)
        if (item.roles.includes(ROLE_OSC)) osc.push(item)
      }
    })

    return [
      ...[{ list: vals }],
      ...csm.length ? [{ label: "CSM", list: csm }] : [],
      ...osc.length ? [{ label: "OSC", list: osc }] : []
    ]
  }

  /**
   * This function is responsible to get selected values from the store for single-select, and find it in the original
   * array, if it is present then return the item else return undefined
   * @inner
   * @function
   * @memberof module:CachedSelectionModule
   * @returns {Object}
   */
  const getSelected = () => {
    return (
      getSelectedQuery[selectedQueryTitle].id &&
      listData &&
      listData.find(listItem => {
        return listItem.id === getSelectedQuery[selectedQueryTitle].id;
      })
    );
  };

  return selectMultiple ? (
    <MultiSelectionListPopup
      showInactive={true}
      isLeadView={showList?.isLeadView}
      isReportView={showList?.isReportView}
      disableDropdown={showList?.roleOSC}
      title={props.title}
      list={listData}
      label={firstItem}
      defaultVal={getMultiSelections()}
      handleMutiSelect={handleMutiSelect}
    />
  ) : (
    <SelectionListPopup
      label={getSelected() ? getSelected().name : firstItem}
      list={listData}
      defaultItem={
        <ListGroup.Item
          className="list-item"
          onClick={() => handleItemSelection()}
        >
          {firstItem}
        </ListGroup.Item>
      }
      optionsMapper={mapCategories}
      mapper={field => {
        return (
          isNotNULL(field) &&
          field.map((item, key) => (
            <ListGroup.Item
              className="list-item"
              onClick={() => handleItemSelection(item)}
              key={key}
            >
              {item.name}
            </ListGroup.Item>
          ))
        );
      }}
      {...props}
    />
  );
};

const SelectionListContainer = ({
  dataQuery,
  dataQuery2,
  setSelectedQuery,
  getSelectedQuery,
  community_ids,
  dataQuery2WithDivision,
  ...props
}) => {
  let withDivision = props.withDivision ? props.withDivision : false;

  const Component = compose(
    graphql(dataQuery, {
      options: () => ({
        variables: {
          ...(withDivision && {
            division_ids: withDivision.ids
          }),
          ...(community_ids && {
            community_ids: community_ids
          })
        }
      })
    }),
    graphql(dataQuery2 || getSelectedQuery, {
      skip: !dataQuery2 || props.showList?.roleOSC,
      name: 'data2',
      options: () => ({
        variables: {
          ...(dataQuery2WithDivision && {
            division_ids: dataQuery2WithDivision.ids
          }),
        }
      })
    }),
    graphql(setSelectedQuery, { name: "setSelectedQuery" }),
    graphql(getSelectedQuery, { name: "getSelectedQuery" })
  )(ListComp);

  return <Component {...props} />;
};

export default SelectionListContainer;