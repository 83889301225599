
import gql from 'graphql-tag';

const UPDATE_PROSPECT_APPOINTMENT = gql` 
  mutation updateProspectAppointment($input: ProspectAppointmentUpdateInput!) {
    updateProspectAppointment(input: $input) {
      message
      code
    }
  }
`;

export default UPDATE_PROSPECT_APPOINTMENT;