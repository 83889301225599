import React, { useContext, useEffect, useState } from "react";
import { compose, graphql } from "react-apollo";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { AuthButton } from "../../rbac/rbac";
import GlobalSearchBar from "../global-search/global-search-bar";
import { Auth } from "../../rbac/rbac";
import ViewAs from "./view-as";
import "./layout.css";
import { FaInbox } from "react-icons/fa";
import Drawer from "../../hoc/Drawer";
import NotificationBar from "./notification-bar"
import { DefaultQuery } from "../../graphql/default-query";
import { GET_NOTIFICATIONS, GET_NOTIFICATIONS_COUNT } from "../../graphql/queries";
import { SUB_UNREAD_COUNT } from "../../graphql/subscriptions";
import { NOTIFICATION_FETCH_LIMIT } from "../../utils/constants";


/**
 * This component renders the notification bar, global search bar input field, logged in person's name and logout button
 * @param {Object} props
 */
const HeaderBar = (props) => {
  const [isCountFirstLoad, setIsCountFirstLoad] = useState(true);
  const [showSidebar, setShowSidebar] = useState(false);
  const [showUnread, setShowUnread] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);

  const { is_logged_in_as } = useContext(Auth);
  const { getNotificationsCount } = props;

  const toggleSidebar = () => setShowSidebar(!showSidebar)

  useEffect(() => {
    if (!getNotificationsCount.loading) {
      if (isCountFirstLoad) {
        getNotificationsCount.subscribeToMore({
          document: SUB_UNREAD_COUNT,
          fetchPolicy: "cache-and-network",
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData) return prev;
            getNotificationsCount.refetch()
            return prev
          }
        });
        setIsCountFirstLoad(false)
      }

      setUnreadCount(getNotificationsCount.getNotificationsCount)
    }
  }, [getNotificationsCount])

  return (
    <ul id="user-bar-nav" className="sb-navbar-user-profile navbar-nav px-3">

      <div className="notification-icon" onClick={toggleSidebar}>
        <FaInbox />
        {unreadCount ? <span className="badge"> {unreadCount < 100 ? unreadCount : '99+'} </span> : null}
      </div>

      <Drawer
        className="notification-drawer"
        show={showSidebar}
        toggleSideBar={toggleSidebar}
      >
        <DefaultQuery
          query={GET_NOTIFICATIONS}
          variables={{
            filter: { is_unread: showUnread },
            limit: NOTIFICATION_FETCH_LIMIT,
            pageNum: 0
          }}
          fetchPolicy='cache-and-network'
        >
          {({ data, refetch, fetchMore, subscribeToMore }) =>
            data && (
              <NotificationBar
                data={data.getNotifications || []}
                refetch={refetch}
                subscribeToMore={subscribeToMore}
                onLoadMore={() =>
                  fetchMore({
                    variables: {
                      filter: { is_unread: showUnread },
                      limit: NOTIFICATION_FETCH_LIMIT,
                      pageNum: Math.floor(data.getNotifications.Notifications.length / NOTIFICATION_FETCH_LIMIT)
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult.getNotifications.Notifications.length) return prev;
                      return Object.assign({}, prev, {
                        getNotifications: {
                          total_count: fetchMoreResult.getNotifications.total_count,
                          Notifications: [...prev.getNotifications.Notifications, ...fetchMoreResult.getNotifications.Notifications],
                          __typename: "NotificationsWithCount"
                        }
                      });
                    }
                  })}
                showUnread={showUnread}
                setShowUnread={setShowUnread}
                close={toggleSidebar}
              />
            )}
        </DefaultQuery>
      </Drawer>

      <GlobalSearchBar />
      <ViewAs />
      { //hides the logout button if viewing as another user 
        !is_logged_in_as() &&
        <>
          <li className="user-text" id="user-bar-username">
            <span className="small text-muted ml-3">{" "}{"Hello, " + props.name}</span>
          </li>
          <OverlayTrigger placement="left" overlay={<Tooltip>Leave Application</Tooltip>}>
            <li style={{ marginLeft: '0', borderLeft: 'none' }}><AuthButton /></li>
          </OverlayTrigger>
        </>
      }
    </ul>
  )
};

export default compose(
  graphql(GET_NOTIFICATIONS_COUNT, {
    name: "getNotificationsCount",
    options: {
      variables: { filter: { is_unread: true } },
      fetchPolicy: 'cache-and-network'
    }
  })
)(HeaderBar);