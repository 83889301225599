import React from "react";
import { useQuery } from "react-apollo-hooks";

import ContentGrid from "../../components/grid/content-grid";
import DashboardSelectBar from "../dashboard/dashboard-select-bar";
import Usage from "./usage-container";
import { GET_SELECTED_SINGLE_USER } from "../../cacheql/queries";
import SelectUser from "../../images/select-user.svg";

const UsageDashboard = (props) => {
  const {
    data: { selectedSingleUserId },
  } = useQuery(GET_SELECTED_SINGLE_USER);

  return (
    <ContentGrid
      id="usage-report"
      selectbar={
        <DashboardSelectBar
          page_type="Report"
          withSingleUser
          withDate
          defaultItem='Select'
          {...props}
        />
      }
      content={{
        data: selectedSingleUserId.id ? (
          <Usage selectedSingleUserId={selectedSingleUserId} />
        ) : (
          <img src={SelectUser} alt="" style={{ margin: "20px 40px" }} />
        ),
      }}
    />
  );
};

export default UsageDashboard;
