import React, { useEffect, useState, useRef } from "react";
import { goBack } from "../../../utils/helpers";
import { routes } from "../../index";
import { SelectBar } from "../../../containers/";
import { ButtonToolbar, Dropdown, DropdownButton } from "react-bootstrap";
import { LEAD, PROSPECT, MARKETING_ONLY, REALTOR } from "../../../utils/constants"
import { FaChevronLeft, FaCalendarDay, FaBullhorn, FaStar } from "react-icons/fa";
import CustomButton from "../../../components/custom-button";
import Header from "../../../components/dashboard-title";
import LeadConvertView from "../dashboard/lead-convert-view";
import LeadScheduleAppointmentView from "./lead-schedule-appointment";
import DetailsDeleteButton from "../../common-lead-prospect-details/details-delete-button-container";
import "./lead-details.css";
import ConvertRealtorView from "../../web-form/convert-realtor-form";

/**
 * this component is used to render the lead details select bar which contains the name and communitites of the lead and the convert to prospect and schedule an appointment buttons.
 * @param {object} props component props contains all the necessary including the mutations.
 * @param {object} props.history contains information about the path, to be used in go back button
 * @param {object} props.data contains all the informations related to the lead
 * @param {number} props.id lead id
 */
const LeadDetailSelectBar = ({ ...props }) => {
  const { history } = props;
  const [showConvertRealtor, setShowConvertRealtor] = useState(false);
  const [
    showLeadConvertDrawer,
    setShowLeadConvertDrawer
  ] = useState({ show: false, type: "" });
  const [
    showScheduleAppointmentDrawer,
    setShowScheduleAppointmentDrawer,
  ] = useState(false);

  const selectBarComp = useRef(null);
  const focusComp = () => selectBarComp.current.scrollIntoView({ block: "end", behavior: "smooth" });
  const notify = (message) => props.setNotificationProps({ variables: { open: true, message: message } });

  const onConvertSubmit = (message) => {
    if (showLeadConvertDrawer.type === MARKETING_ONLY) {
      toggleLeadToMarketingOnlyDrawer();
      history.push({
        pathname: routes.MARKETING_ONLY_DASHBOARD,
        showLeadConvertNotification: true,
        LeadConvertMessage: "Lead(s) converted successfully!",
      });
    } else {
      toggleLeadToProspectDrawer();
      history.push({
        pathname: routes.PROSPECT_DASHBOARD,
        showLeadConvertNotification: true,
        LeadConvertMessage: "Lead(s) converted successfully!",
      });
    }

    notify(message);
  };

  const scheduleAppointmentOnSubmit = (message) => {
    toggleAppointmentSideDrawer();
    history.push({
      pathname: routes.PROSPECT_DASHBOARD,
      showLeadConvertNotification: true,
      LeadConvertMessage: "Lead(s) converted successfully!",
    });
    notify(message);
  };

  const toggleLeadToProspectDrawer = () =>
    setShowLeadConvertDrawer((prevState) => ({ show: !prevState.show, type: PROSPECT }));

  const toggleLeadToMarketingOnlyDrawer = () =>
    setShowLeadConvertDrawer((prevState) => ({ show: !prevState.show, type: MARKETING_ONLY }));

  const toggleLeadToRealtorDrawer = () => setShowConvertRealtor((prevState) => !prevState)

  const toggleAppointmentSideDrawer = () =>
    setShowScheduleAppointmentDrawer((showScheduleAppointmentDrawer) => !showScheduleAppointmentDrawer);

  const onSubmit = (message, path) => {
    history.push({ pathname: path });
    notify(message);
  }

  useEffect(() => {
    focusComp();
  }, [selectBarComp]);

  return (
    <>
      <SelectBar>
        <div
          ref={selectBarComp}
          className="d-flex justify-content-between align-items-center"
        >
          <CustomButton
            onClick={() => goBack(history)}
            id="back-to-dashboard-btn"
            color="dark"
            btnIcon={<FaChevronLeft />}
            className="btn-back"
          />
          <Header
            title={LEAD}
            community={props.community || ""}
            subtitle={props.name || ""}
          />
        </div>
        <div
          className="col-padding col-12 btn-container"
          id="lead-dashboard-btn"
        >
          <ButtonToolbar>
            <CustomButton
              size="sm"
              btnValue="SCHEDULE APPOINTMENT"
              color="green"
              btnIcon={<FaCalendarDay />}
              onClick={() => toggleAppointmentSideDrawer()}
            />
            <DropdownButton
              alignRight
              drop="down"
              key="1"
              id="dropdown-item-button"
              title="CONVERT"
              className="past-activity-dropdown"
            >
              <Dropdown.Item
                onSelect={() => toggleLeadToMarketingOnlyDrawer()}
                key={0}
              >
                Marketing Only
              </Dropdown.Item>
              <Dropdown.Item
                onSelect={() => toggleLeadToProspectDrawer()}
                key={1}
              >
                Prospect
              </Dropdown.Item>
              <Dropdown.Item
                onSelect={() => toggleLeadToRealtorDrawer()}
                key={2}
              >
                Realtor
              </Dropdown.Item>
            </DropdownButton>
            <DetailsDeleteButton
              type={LEAD}
              itemID={props.id}
              customerID={props.data && props.data.customer_id}
              color="green"
              history={history}
            />
          </ButtonToolbar>
          <LeadScheduleAppointmentView
            show={showScheduleAppointmentDrawer}
            id={props.id}
            onSubmit={scheduleAppointmentOnSubmit}
            leadData={props.data}
            close={toggleAppointmentSideDrawer}
          />
          {showLeadConvertDrawer.show &&
            <LeadConvertView
              show={showLeadConvertDrawer.show}
              id={props.id}
              onSubmit={onConvertSubmit}
              leadData={props.data}
              close={() => setShowLeadConvertDrawer({ show: false, type: "" })}
              type={showLeadConvertDrawer.type}
            />
          }
          {showConvertRealtor &&
            <ConvertRealtorView
              showSideBar={showConvertRealtor}
              toggleShowDrawer={() => setShowConvertRealtor(false)}
              id={props.id}
              data={props.data}
              onSubmit={(message) => onSubmit(message, routes.REALTOR_DASHBOARD)}
            />
          }
        </div>
      </SelectBar>
    </>
  );
};

export default LeadDetailSelectBar;
