import { compose, graphql } from "react-apollo";
import MarketingOnlyTable from "./marketing-only-table";
import { GET_MARKETING_ONLY } from "../../../graphql/queries";
import { ADD_MARKETING_ONLY_DELETE_REQUEST, CONVERT_MARKETING_ONLY_TO_LEAD } from "../../../graphql/mutations";
import { GET_SELECTED_COMMUNITY, GET_SELECTED_DIVISION, GET_TABLE_PARAMS } from "../../../cacheql/queries";
import { SET_NOTIFICATION_PROPS, SET_TABLE_PARAMS, SET_PREVIOUS_CARD } from "../../../cacheql/mutations";
import { getCommunityIds, getCardParams } from "../../../utils/helpers";

const MarketingOnlyContainer = compose(
  graphql(GET_SELECTED_COMMUNITY, { name: "selectedCommunity" }),
  graphql(GET_SELECTED_DIVISION, { name: "selectedDivision" }),
  graphql(SET_PREVIOUS_CARD, { name: "setPreviousCard" }),
  graphql(SET_TABLE_PARAMS, { name: "setTableParams" }),
  graphql(GET_TABLE_PARAMS, { name: "getTableParams" }),
  graphql(GET_MARKETING_ONLY, {
    name: "data",
    options: ({
      currentCard: { tableTitle, filter },
      selectedCommunity,
      selectedDivision,
      getTableParams: { tableParams }
    }) => {
      let params = getCardParams(tableTitle, tableParams);

      if (params)
        delete params.filter.filter_name

      return {
        variables: {
          pageNum: (params && params.pageNum) || 0,
          limit: (params && params.limit) || 10,
          sort: (params && params.order && params.order.id !== -1)
            ? [params.order.name, params.order.sort]
            : null,
          search: (params && params.search) || "",
          filter: {
            ...filter,
            community_ids: getCommunityIds(selectedCommunity),
            division_id: selectedDivision.selectedDivision.id,
            ...(params && params.filter)
          },
        },
        fetchPolicy: "cache-and-network"
      }
    }
  }),
  graphql(ADD_MARKETING_ONLY_DELETE_REQUEST, { name: "addMarketingOnlyDeleteRequests" }),
  graphql(CONVERT_MARKETING_ONLY_TO_LEAD, { name: "convertMarketingOnlyToLead" }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" })
)(MarketingOnlyTable);

export default MarketingOnlyContainer;