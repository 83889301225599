import gql from "graphql-tag";

const CREATE_LEAD = gql`
  mutation addLead($input: LeadInput!, $registration_id: Int) {
    addLead(input: $input, registration_id: $registration_id) {
      message
      code
      data {
        leadCommunities {
          id
          name
        }
        prospectCommunities {
          id
          name
        }
      }
    }
  }
`;

export default CREATE_LEAD;
