import gql from "graphql-tag";

const GET_OSC_COMMISSION_REPORT = gql`
  query getOscCommissionReport(
    $limit: Int
    $pageNum: Int
    $filter: getOscCommissionReportFilter
  ) {
    getOscCommissionReport(
      limit: $limit
      pageNum: $pageNum
      filter: $filter
    ) {
        commission_report{
            first_name_1,
            first_name_2,
            last_name_1,
            last_name_2,
            community{name},
            lot,
            purchased_date,
            osc{first_name, last_name},
            appointment_set_date
            is_deleted_appointment
          },
          total_count
    }
  }
`;
export default GET_OSC_COMMISSION_REPORT;
