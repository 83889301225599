import React from "react";
import { getIn } from 'formik';
import PropTypes from 'prop-types';
import "./fields.css";

import { EMAIL_MATCHED, PRIMARY_MATCH } from "../../utils/form-errors";
import { phoneDisplay } from '../../utils/helpers'

/**
 * Text field enables the user to write text into a field
 * @param {Object} props 
 * @param {String} props.placeholder placeholder value
 * @param {Boolean} props.required mark the current field mandatory  
 * @param {Boolean} props.isCellPhone if text field is being used for cell phone
 * @param {Array} props.refFields this contains other fields that are to show/hide error along with current field
 * @param {Boolean} props.disable to diable input field
 */
const TextField = props => {
  const {
    id,
    type,
    label,
    placeholder,
    field,
    field: { onChange, value, ...otherProps },
    required,
    isCellPhone,
    refFields,
    disable,
    displayName,
    form: { touched, errors, setFieldValue },
    ...rest
  } = props;

  const error = getIn(errors, field.name);
  const touch = getIn(touched, field.name);
  let errorMessage = undefined;
  if (error === EMAIL_MATCHED)
    errorMessage = (refFields[0].value[field.name]) && error;
  else if (error === PRIMARY_MATCH)
    errorMessage = (refFields[1].value[field.name]) && error;
  else
    errorMessage = (touch && error) && error;

  const handleOnChange = isCellPhone ? (e) => {
    let unFormattedValue = e.target.value.replace(/[^0-9]/g, "")
    if (unFormattedValue.length <= 10) {
      let formattedValue = phoneDisplay(unFormattedValue)
      onChange(e.target.value)
      setFieldValue(field.name, formattedValue, true)
      setFieldValue(field.name.substring(field.name.indexOf('_') + 1), unFormattedValue, true)
    }
  } : onChange

  const style = required ? { borderLeftColor: "red", borderLeftWidth: 2, background: "" } : { background: "" }
  return (
    <div id={id + "-form-group"} className="form-group">
      <label htmlFor={id + "-input"} className="control-label">
        {label}
      </label>
      <input
        style={disable? {...style, color:"#888",opacity:"0.5"}:style}
        id={id + "-input"}
        type={type}
        disabled={disable}
        placeholder={placeholder}
        className={'form-control' + (errorMessage ? ' is-invalid' : '')}
        onChange={handleOnChange}
        onClick={refFields ? e => refFields.forEach(refField => refField.setter({ [field.name]: true })) : null}
        value={displayName||value||""}
        {...otherProps}
        {...rest}
      />
      {errorMessage && <div className="invalid-feedback">{errorMessage}</div>}
    </div>
  );
};

TextField.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
}

export default TextField;
