import React from "react";
import { Accordion } from "react-bootstrap";
import {
  FaCog,
  FaFilter,
  FaHome,
  FaList,
  FaRedo,
  FaFileContract,
  FaStar,
  FaWarehouse,
  FaRegCalendarCheck,
  FaListAlt,
  FaEnvelopeOpenText,
  FaRegFileExcel,
  FaCogs,
  FaUser,
  FaAngleRight,
  FaBullhorn
} from "react-icons/fa";
import { BsPeopleFill } from "react-icons/bs"
import { NeedsEntitlement } from "../../rbac/rbac";
import { routes } from "../";
import SubNavigationItem from "../../components/nav-item/sub-navigation-item";
import NavigationItem from "../../components/nav-item/main-navigation-item";

const WrapperNavItem = ({ Component, ...props }) => {
  return props.navLink && props.arrow ? (
    <Component {...props} />
  ) : (
    <div
      onClick={() => {
        props.callback();
      }}
    >
      <Component {...props} />
    </div>
  );
};

/**
 * This component is responsible for rendering the whole side navigation of the application. It makes sure whether
 * to give access of a route to a user or notby wrapping the components into NeedsEntitlement. The structure
 * of the whole side-navigation is defined in this component.
 * @param {Object} props
 */
const SidePanel = (props) => {
  return (
    <Accordion id="side-panel-accordian" className="mt-3">
      <NeedsEntitlement reqs={["sb.dashboard.*"]}>
        <WrapperNavItem
          Component={NavigationItem}
          callback={props.callback}
          navKey="0"
          id="side-panel-nav-item"
          icon={<FaList />}
          navLink={routes.HOME}
          navTitle="Dashboard"
          exact
        />
      </NeedsEntitlement>
      <NeedsEntitlement reqs={["sb.webform.*"]}>
        <WrapperNavItem
          Component={NavigationItem}
          callback={props.callback}
          navKey="1"
          id="side-panel-nav-item"
          icon={<FaListAlt />}
          navLink={routes.WEB_FORM}
          chilrenNavLinks={[routes.WEB_FORM_OTHERS]}
          count={props.unrespondedCount}
          secondaryCount={props.unconvertedCount}
          navTitle="Web Forms"
          exact
          arrow={
            <NeedsEntitlement reqs={["sb.webformOthers.*"]}>
              <FaAngleRight />
            </NeedsEntitlement>
          }
        >
          <NeedsEntitlement reqs={["sb.webformOthers.*"]}>
            <WrapperNavItem
              Component={SubNavigationItem}
              callback={props.callback}
              icon={<FaList />}
              navLink={routes.WEB_FORM_OTHERS}
              navTitle="Others"
              nestedLink
            />
          </NeedsEntitlement>
        </WrapperNavItem>
      </NeedsEntitlement>
      <WrapperNavItem
        Component={NavigationItem}
        callback={props.callback}
        navKey="2"
        id="side-panel-nav-item-2"
        icon={<FaBullhorn />}
        navLink={routes.MARKETING_ONLY_DASHBOARD}
        navTitle="Marketing Only"
      />
      <WrapperNavItem
        Component={NavigationItem}
        callback={props.callback}
        navKey="3"
        id="side-panel-nav-item-3"
        icon={<FaFilter />}
        navLink={routes.LEAD_DASHBOARD}
        navTitle="Leads"
      />
      <WrapperNavItem
        Component={NavigationItem}
        callback={props.callback}
        navKey="4"
        id="side-panel-nav-item-4"
        icon={<FaStar />}
        navLink={routes.PROSPECT_DASHBOARD}
        navTitle="Prospects"
      />
      <WrapperNavItem
        Component={NavigationItem}
        callback={props.callback}
        navKey="5"
        id="side-panel-nav-item-5"
        icon={<FaWarehouse />}
        navLink={routes.HOME_OWNER}
        navTitle="Homeowners"
      />
      <WrapperNavItem
        Component={NavigationItem}
        callback={props.callback}
        navKey="6"
        id="side-panel-nav-item-6"
        icon={<BsPeopleFill />}
        navTitle="Realtors"
        navLink={routes.REALTOR_DASHBOARD}
        count={props.realtorClientRegistrationCount}
        secondaryCount={props.realtorRegistrationCount}
        chilrenNavLinks={[
          routes.AGENCIES_DASHBOARD,
          routes.CLIENT_REGISTRATION,
          routes.REALTOR_REGISTRATION
        ]}
        arrow={<FaAngleRight />}
      >
        <WrapperNavItem
          Component={SubNavigationItem}
          callback={props.callback}
          icon={<FaUser />}
          navLink={routes.CLIENT_REGISTRATION_DETAIL}
          navTitle="Client Registrations"
          nestedLink
        />
        <WrapperNavItem
          Component={SubNavigationItem}
          callback={props.callback}
          icon={<FaUser />}
          navLink={routes.REALTOR_REGISTRATION_DETAIL}
          navTitle="Realtor Registrations"
          nestedLink
        />
        <WrapperNavItem
          Component={SubNavigationItem}
          callback={props.callback}
          icon={<FaHome />}
          navLink={routes.AGENCIES_DASHBOARD}
          navTitle="Agencies"
          nestedLink
        />
      </WrapperNavItem>
      <NavigationItem
        navKey="7"
        id="side-panel-nav-item-7"
        icon={<FaFileContract />}
        navTitle="Reports"
        chilrenNavLinks={[
          routes.CONVERSION_REPORT,
          routes.ACTIVITY_REPORT,
          routes.OSC_COMMISSION_REPORT,
          routes.USAGE_REPORT
        ]}
        arrow={<FaAngleRight />}
      >
        <WrapperNavItem
          Component={SubNavigationItem}
          callback={props.callback}
          icon={<FaRedo />}
          navLink={routes.CONVERSION_REPORT}
          navTitle="Conversion"
          nestedLink
        />
        <WrapperNavItem
          Component={SubNavigationItem}
          callback={props.callback}
          icon={<FaRegCalendarCheck />}
          navLink={routes.ACTIVITY_REPORT}
          navTitle="Activity"
          nestedLink
        />
        <NeedsEntitlement reqs={["sb.oscReports.*"]}>
          <WrapperNavItem
            Component={SubNavigationItem}
            callback={props.callback}
            icon={<FaRegCalendarCheck />}
            navLink={routes.OSC_COMMISSION_REPORT}
            navTitle="OSC Commission"
            nestedLink
          />
        </NeedsEntitlement>
        <NeedsEntitlement reqs={["sb.usageReport.*"]}>
          <WrapperNavItem
            Component={SubNavigationItem}
            callback={props.callback}
            icon={<FaRegCalendarCheck />}
            navLink={routes.USAGE_REPORT}
            navTitle="Usage"
            nestedLink
          />
        </NeedsEntitlement>
      </NavigationItem>
      <NeedsEntitlement reqs={["sb.admin.*"]}>
        <NavigationItem
          navKey="8"
          id="side-panel-nav-item-8"
          icon={<FaCogs />}
          navTitle="Settings"
          chilrenNavLinks={[
            routes.ADMIN_CUSTOMIZATION,
            routes.EMAIL_TEMPLATES,
            routes.ARCHIVE_USERS,
          ]}
          arrow={<FaAngleRight />}
        >
          <NeedsEntitlement reqs={["sb.customizations.*"]}>
            <WrapperNavItem
              Component={SubNavigationItem}
              callback={props.callback}
              icon={<FaCog />}
              navLink={routes.ADMIN_CUSTOMIZATION}
              navTitle="Customizations"
              nestedLink
            />
          </NeedsEntitlement>
          <NeedsEntitlement reqs={["sb.emailTemplates.*"]}>
            <WrapperNavItem
              Component={SubNavigationItem}
              callback={props.callback}
              icon={<FaEnvelopeOpenText />}
              navLink={routes.EMAIL_TEMPLATES}
              navTitle="Email Templates"
              nestedLink
            />
          </NeedsEntitlement>
          <NeedsEntitlement reqs={["sb.submissionUpload.*"]}>
            <WrapperNavItem
              Component={SubNavigationItem}
              callback={props.callback}
              icon={<FaRegFileExcel />}
              navLink={routes.SUBMISSION_UPLOAD}
              navTitle="Submission Upload"
              nestedLink
            />
          </NeedsEntitlement>
          <NeedsEntitlement reqs={["sb.archiveUsers.*"]}>
            <WrapperNavItem
              Component={SubNavigationItem}
              callback={props.callback}
              icon={<FaUser />}
              navLink={routes.ARCHIVE_USERS}
              navTitle="Archived"
              nestedLink
            />
          </NeedsEntitlement>
        </NavigationItem>
      </NeedsEntitlement>
    </Accordion>
  );
};

export default SidePanel;
