import React from 'react';
import { Modal } from 'react-bootstrap';
import CustomButton from '../custom-button';
import "./dialog.css";

/**
 * This is a reusable component for dialog boxes which uses Modal to open a dialog box at the center of the screen. It has multiple configurations, it can be of different size, custom title, custom body, custom button names and actions etc. 
 * @param {Object} props
 * @param {Boolean} props.noCentered to position dialog box at the center of the screen
 * @param {Boolean} props.noHeader to show no header if passed as true
 * @param {String} props.size 'lg', 'sm' etc to variate the width of dialog box
 * @param {String} props.title title of teh dialog box
 * @param {Function} props.click action on clicking ok or yes button of the dialog box
 * @param {Function} props.onHide action on clicking cross or cancel button
 * @param {String} props.clickname text to show on click button
 * @param {String} props.closename text to show on close button
 * @param {Boolean} props.show to show hide dialog box
 * @param {Boolean} props.disabled to disable click button
 * @param {Boolean} props.isPermanentDelete to show permanent delete button at the very bottom
 * @param {Function} props.permanentDeleteClick action on clicking permanent delete
 */
export default class Dialog extends React.Component {
  render() {
    const { noCentered, noHeader, size, title, click, closename, clickname, show, onHide, disabled, backdrop, isPermanentDelete, permanentDeleteClick, disableCloseButton, dialogClassName } = this.props
    return (
      <Modal
        size={size}
        show={show}
        onHide={onHide}
        backdrop={backdrop ? backdrop : false}
        dialogClassName={dialogClassName}
        aria-labelledby="contained-modal-title-vcenter"
        centered={!noCentered}
      >
        {!noHeader && <Modal.Header closeButton={disableCloseButton ? false : true} >
          {title ? <Modal.Title id="contained-modal-title-vcenter" >
            {title}
          </Modal.Title> : null}
        </Modal.Header>
        }
        <Modal.Body>
          <div className="mb-0 modal-body">{this.props.body}</div>
        </Modal.Body>
        <Modal.Footer>
          {closename ? <CustomButton className='btn btn-default' color="black" onClick={onHide} btnValue={closename} /> : null}
          {clickname ? <CustomButton className='btn custom-btn' disabled={disabled} onClick={click} btnValue={clickname} /> : null}
        </Modal.Footer>
        {
          isPermanentDelete && (
            <div className="permanent-delete-body">
              <hr className="light footer-hr" />
              <p className="mb-3 mt-2">Click "Permanently Delete" if you want to merge two records together</p>
              <CustomButton className='btn btn-default' color="black" disabled={disabled} onClick={permanentDeleteClick} btnValue="Permanently Delete" />
            </div>
          )
        }
      </Modal>
    );
  }
}