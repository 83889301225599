import { compose, graphql } from "react-apollo";
import MarketingOnlyForm from "./create-edit-marketing-only-form";
import { CREATE_MARKETING_ONLY, CREATE_MARKETING_ONLY_DUPLICATE, UPDATE_MARKETING_ONLY } from "../../../graphql/mutations";
import {
  GET_DIVISIONS,
  GET_ALL_STATES,
  GET_CUSTOMER_ORIGINATION_AREA,
  GET_LOGGED_IN_COMMUNITIES,
  GET_COMMUNITIES
} from "../../../graphql/queries";
import { SET_NOTIFICATION_PROPS } from '../../../cacheql/mutations'

const CreateEditMarketingOnly = compose(
  graphql(GET_LOGGED_IN_COMMUNITIES, { name: "getLoggedInCommunities" }),
  graphql(GET_ALL_STATES, { name: "getAllStates" }),
  graphql(GET_COMMUNITIES, { name: "getAllCommunities" }),
  graphql(GET_DIVISIONS, { name: "getAllDivisions" }),
  graphql(GET_CUSTOMER_ORIGINATION_AREA, { name: "getOriginationAreas" }),
  graphql(CREATE_MARKETING_ONLY, { name: "addMarketingOnly" }),
  graphql(CREATE_MARKETING_ONLY_DUPLICATE, { name: "addMarketingOnlyDuplicate" }),
  graphql(UPDATE_MARKETING_ONLY, { name: "updateMarketingOnly" }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" })
)(MarketingOnlyForm);

export default CreateEditMarketingOnly;