import { graphql, compose } from "react-apollo";
import { SET_NOTIFICATION_PROPS } from "../../../cacheql/mutations";
import { ADD_PROSPECT_TO_EXISTING_CUSTOMER } from "../../../graphql/mutations";
import { GET_ALLRANKINGS } from "../../../graphql/queries";
import addCommunityForm from "./add-community-form";

const AddCommunityContainer = compose(
  graphql(ADD_PROSPECT_TO_EXISTING_CUSTOMER, {
    name: "addProspectToExistingCustomer",
  }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" }),
  graphql(GET_ALLRANKINGS, { name: "getAllRankings" })
)(addCommunityForm);

export default AddCommunityContainer;
