import { compose, graphql } from "react-apollo";

import { GET_REALTOR_DETAILS } from "../../../graphql/queries";
import { SET_NOTIFICATION_PROPS } from "../../../cacheql/mutations";
import RealtorDetailsView from './realtor-details'

export default compose(
    graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" }),
    graphql(GET_REALTOR_DETAILS, { name: "getRealtorDetails" , 
        options: props => {
            return {
                variables: {
                    id: parseInt(props.match.params.id)
                },
                fetchPolicy: 'cache-and-network'
            }
        }
    })
)(RealtorDetailsView);