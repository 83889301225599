import React, { useState, useEffect, useRef } from "react";
import { SUB_NEW_CLIENT_REGISTRATION_CHANGE } from "../../../graphql/subscriptions";
import { compose, graphql } from "react-apollo";
import { ButtonToolbar, Dropdown, DropdownButton } from "react-bootstrap";
import { LEAD, PROSPECT, REALTOR } from "../../../utils/constants";
import DetailsSectionTable from "../../../components/table/custom-details-table";
import ConvertProspectView from "../../web-form/convert-prospect-form";
import ConvertLeadView from "../../web-form/convert-lead-form";
import CustomButton from "../../../components/custom-button/index";
import ListPopup from "../../../components/list-popup/list-popup";
import Drawer from "../../../hoc/Drawer";
import {
  GET_NEW_REALTOR_REGISTRATIONS,
  GET_LOGGED_IN_COMMUNITIES,
} from "../../../graphql/queries";
import {
  formatActivityDate,
  inActiveMapper,
  phoneLink,
  checkDisabledEmailsLink,
} from "../../../utils/helpers";

/**
 * This component is used to render new realtor registration card details drawer. It is divided into 3 parts. On the top
 * it renders a selectBar component which contains convert to prospect button. Next we have  Right
 * below it, we have {@link DetailsSectionComponent} and then we have NotesComponent which displays the client notes.
 * @param {Object} props queries from the container are received as props
 */
const RegistrationDetail = ({
  toggleShowDrawer,
  showSideBar,
  convertBtn,
  data,
  getLoggedInUserCommunity,
  ...props
}) => {
  const [showLeadBar, setShowLeadBar] = useState(false);
  const [showProspectBar, setShowProspectBar] = useState(false);
  const [lead, setLead] = useState();
  const [prospect, setProspect] = useState();
  const [registration, setRegistration] = useState({});
  const [isSubscription, setIsSubscription] = useState(false);

  const handler = useRef(false);

  const convertHandler = (data, customerType) => {
    customerType === LEAD ? setShowLeadBar(true) : setShowProspectBar(true);

    const name = data.client_name ? data.client_name.split(" ") : "";
    const name2 = data.client_name_2 ? data.client_name_2.split(" ") : "";
    let customer = {
      firstname: name ? name[0] : "",
      lastname: name ? name[1] : "",
      email: data.client_email,
      phone: data.client_phone,
      firstname2: name2 && name2[0],
      lastname2: name2 && name2[1],
      email2: data.client_email_2,
      phone2: data.client_phone_2,
      realtor: { id: data.existing_realtor && data.existing_realtor.id },
      communities: data.communities,
      division: data.division,
    };

    customerType === LEAD ? setLead(customer) : setProspect(customer);
  };

  useEffect(() => {
    if (
      !data.loading &&
      data.getRealtorClientRegistrations &&
      data.getRealtorClientRegistrations.realtor_client_registration[0]
    ) {
      setRegistration(
        data.getRealtorClientRegistrations.realtor_client_registration[0]
      );
    }
  }, [data]);

  useEffect(() => {
    if (props.id) {
      handler.current = data.subscribeToMore({
        document: SUB_NEW_CLIENT_REGISTRATION_CHANGE,
        variables: { registration_id: props.id },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData) return prev;
          setIsSubscription(true);
          return prev;
        },
      });
    }

    return () => {
      handler.current && handler.current();
      handler.current = false;
    };
  }, [props.id]);

  useEffect(() => {
    if (isSubscription) {
      setIsSubscription(false);
      data.refetch();
    }
  }, [isSubscription]);

  return (
    <>
      <Drawer show={showSideBar} toggleSideBar={toggleShowDrawer}>
        <div className="form-horizontal">
          <div className="form_TitleContainer">
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ margin: "0 1.1rem" }}
            >
              <div>
                <div className="form_TitleBig">
                  <div className="d-flex">
                    <h2
                      style={{
                        position: "relative",
                        paddingRight: "0.5em",
                        marginTop: "4px",
                      }}
                    >
                      New Registration
                    </h2>
                  </div>
                </div>
              </div>
              <ButtonToolbar>
                <CustomButton
                  color="black"
                  onClick={toggleShowDrawer}
                  btnValue="CLOSE"
                />
                {registration.existing_realtor?.id ? (
                  <DropdownButton
                    alignRight
                    drop="down"
                    key="1"
                    id="dropdown-item-button"
                    title="CONVERT"
                  >
                    <Dropdown.Item
                      onSelect={() => convertHandler(registration, LEAD)}
                      key={0}
                    >
                      Lead
                    </Dropdown.Item>
                    <Dropdown.Item
                      onSelect={() => convertHandler(registration, PROSPECT)}
                      key={1}
                    >
                      Prospect
                    </Dropdown.Item>
                  </DropdownButton>
                ) : (
                  <CustomButton
                    onClick={() => props.addRealtor(registration)}
                    btnValue={"Add Realtor"}
                  />
                )}
              </ButtonToolbar>
            </div>
          </div>
          <div className="form_body">
            <DetailsSectionComponent registration={registration} />
            <div className="form-sub-heading">Messages</div>
            <div className="activity-history">
              <NotesComponent registration={registration} {...props} />
            </div>
          </div>
        </div>
      </Drawer>
      <ConvertLeadView
        showSideBar={showLeadBar}
        toggleShowDrawer={() => setShowLeadBar(false)}
        onSubmit={() => {
          setShowLeadBar(false);
          toggleShowDrawer();
          props.update();
        }}
        registration_id={props.id}
        data={lead}
        convertForm
        convertFormType="REALTOR REGISTRATION"
        registration={registration}
      />
      <ConvertProspectView
        showSideBar={showProspectBar}
        toggleShowDrawer={() => setShowProspectBar(false)}
        onSubmit={() => {
          setShowProspectBar(false);
          toggleShowDrawer();
          props.update();
        }}
        registration_id={props.id}
        userCommunities={getLoggedInUserCommunity.getLoggedInUserCommunities}
        convertForm
        convertFormType="REALTOR REGISTRATION"
        data={prospect}
        registration={registration}
      />
    </>
  );
};

/**
 * this component is used to render the notes in the new realtor registration card details drawer, below the basic informations.
 * @param {*} props
 * @param {object} registration registration info
 * @returns {JSX.Element}
 */
const NotesComponent = ({ registration, ...props }) => {
  const getListMessages = (data, item) => {
    return (
      <>
        <div
          className="activity-history-text"
          dangerouslySetInnerHTML={{
            __html: `<p>${item.note || ""}</p>`,
          }}
        />
      </>
    );
  };
  const { realtor_client_notes } = registration;
  return (
    <>
      {realtor_client_notes &&
        realtor_client_notes.map((item, index) => {
          return (
            <div
              style={{
                border: "1px solid #222",
                padding: "1rem 0.8rem 0 0.8rem",
                marginBottom: "0.5rem",
                borderRadius: "4px",
              }}
              key={index}
            >
              <div className="small text-muted mb-1" style={{ opacity: "0.6" }}>
                {formatActivityDate(registration.created_at)}
              </div>
              {getListMessages(realtor_client_notes, item)}
            </div>
          );
        })}
    </>
  );
};

/**
 * This component is used to render new realtor registration card details section on the right side of the page. It divides the details card into
 * 4 sections.Client 1, client 2, realtor and other details sections.
 * @param {Object} registration registration info
 * @returns {JSX.Element}
 */
export const DetailsSectionComponent = ({ registration }) => {
  const getData = (name, emailAccessor, data, accessor) => {
    const getEmails = (field) => {
      let emails = [field[0][emailAccessor]];
      return emails;
    };
    return {
      Name: name,
      Email: checkDisabledEmailsLink(
        data,
        emailAccessor,
        getEmails,
        "primary_email_status"
      ),
      Phone: phoneLink(data, accessor),
    };
  };

  const handleCommunities = (data) => {
    return <ListPopup data={data} mapper={inActiveMapper} />;
  };

  return (
    <div className="row sidebar-details-table">
      <div className="col-md-6">
        <DetailsSectionTable
          data={{
            ...getData(
              registration.client_name,
              "client_email",
              registration,
              "client_phone"
            ),
          }}
          tableHeader="Client 1"
        />
      </div>
      <div className="col-md-6">
        <DetailsSectionTable
          data={{
            ...getData(
              registration.client_name_2,
              "client_email_2",
              registration,
              "client_phone_2"
            ),
          }}
          tableHeader="Client 2"
        />
      </div>
      <hr style={{ width: "100%", marginBottom: 0 }} />
      <div className="col-md-6">
        <DetailsSectionTable
          data={
            registration.existing_realtor
              ? getData(
                  registration.existing_realtor.first_name +
                    " " +
                    (registration.existing_realtor.last_name
                      ? registration.existing_realtor.last_name
                      : ""),
                  "primary_email",
                  registration.existing_realtor,
                  "cell_phone"
                )
              : getData(
                  registration.realtor_name,
                  "realtor_email",
                  registration,
                  "realtor_phone"
                )
          }
          tableHeader={REALTOR}
        />
      </div>

      <div className="col-md-6">
        <DetailsSectionTable
          data={{
            Brokerage: registration.brokerage_name,
            Type: registration.type && registration.type.name,
            Division: registration.division && registration.division.name,
            Communities:
              registration.communities &&
              handleCommunities(registration.communities),
          }}
          tableHeader="Other Details"
        />
      </div>
    </div>
  );
};

export default compose(
  graphql(GET_NEW_REALTOR_REGISTRATIONS, {
    options: ({ id }) => {
      return {
        variables: {
          filter: { registration_id: id },
        },
        fetchPolicy: "cache-and-network",
      };
    },
  }),
  graphql(GET_LOGGED_IN_COMMUNITIES, { name: "getLoggedInUserCommunity" })
)(RegistrationDetail);
