import gql from "graphql-tag";

const GET_REALTORS_COUNT = gql`
  query getRealtors($input: RealtorFilter, $mode:RealtorsMode){
    getRealtors(limit: 1, filter: $input, mode:$mode) {
      total_count
    }
  }
`;

export default GET_REALTORS_COUNT;
