import React, { useEffect, useState } from "react";
import { useQuery } from "react-apollo-hooks";

import Drawer from "../../hoc/Drawer";
import GlobalWebFormTable from "./global-webform-table";
import GlobalProspectsTable from "./global-prospects-table";
import GlobalRealtorsTable from "./global-realtors-table";
import GlobalLeadsTable from "./global-leads-table";
import GlobalHomeownersTable from "./global-homeowners-table";
import emptySearch from "../../images/empty-search.png";

import "./global-search.css";
import { TableDebounceSearch } from "../../components/new-table/table-search-input";
import GlobalArchiveUsersTable from "./global-archive-users-table";
import MultiSelect from "../../components/multi-select/multi-select";
import MergeCustomersDialog from "../merge-customer/merge-customer-dialog";
import MergeRealtorsDialog from "../merge-realtor/merge-realtor-dialog"
import { GET_CUSTOMER_TO_MERGE } from "../../graphql/queries";

/**
 * This component is used to render the global search dialog box which contains the global search bar on the top and an empty body prompting a search term before the tables (containing only the top 5 results) are shown on the dialog box
 * @param {Object} props
 * @param {Boolean} props.showSideBar it contains a boolean check on weather or not to open the global search drawer
 * @param {Function} props.toggleShowDrawer function that closes the drawer
 */
const GlobalSearchDetails = ({ showSideBar, toggleShowDrawer }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [communityData, setCommunityData] = useState([])
  const [showMergeDialog, setShowMergeDialog] = useState(false)
  const [showHomeownerError, setShowHomeownerError] = useState(false)
  const [mergeData, setMergeData] = useState({ data: [], type: "" })

  /**
   * A function to obtain the customers ids from customers selected
   * @param {Array} data 
   */
  const getCustomerIds = (data) => {
    return data.length ? data.map(customer => {
      return customer.customer_id || customer.id
    }) : []
  }

  const { data } = useQuery(GET_CUSTOMER_TO_MERGE,
    {
      variables:
        { filter: { customer_ids: getCustomerIds(mergeData.data) } },
      skip: mergeData.type === "realtor"
    });

  useEffect(() => {

    if (data) {
      const hasHomeowner = data.getCustomerToMerge ? data.getCustomerToMerge.find(customer => customer.homeowner_communities.length > 0) ? true : false : false
      setShowHomeownerError(hasHomeowner)
      setCommunityData(data.getCustomerToMerge || [])
    }
  }, [data])

  /**
   * A callback function that updates the to merge customer list when the user performs an action on the list e.g. user clicks the
   * de-select icon on the customer chip
   * Function identifies the type, Customer or Realtor and updates the state accordingly
   * @param {Array} vals updated values from the multi-select component after an action has be performed 
   */
  const onChange = (vals) => {

    const defaultValues = vals ? vals.reduce((defaultVal, item) => {
      const label =
        mergeData &&
        mergeData
          .data
          .filter(
            (selectedValue) => selectedValue.id === item.id || selectedValue.id === item.value
          )
          .find((item) => item);

      if (label) {
        defaultVal.push(label);
      }
      return defaultVal;
    }, []) : []

    setMergeData({
      data: defaultValues,
      type: mergeData.type
    })
  }

  /**
   * A callback function to either enable/disable showing of the merge customer dialog box
   */
  const handleShowMergeDialog = () => {
    setShowMergeDialog(!showMergeDialog)
  }

  /**
   * A callback function to close both the merge customer dialog box as well as global search when customers are merged successfully
   */
  const onMergeSubmit = () => {
    handleShowMergeDialog()
    toggleShowDrawer()
  }

  return (
    <>
      <Drawer show={showSideBar} toggleSideBar={toggleShowDrawer}>
        <div className="form-horizontal">
          <div className="form_SearchBar">
            <TableDebounceSearch
              id={"global-search-input"}
              setSearchTerm={setSearchTerm}
              searchTerm={searchTerm}
              isGlobalSearch
              callback={toggleShowDrawer}
            />
          </div>
          <div className="form_body">
            <p className="top-caption">*Showing top 5 matches</p>

            {mergeData.data.length ?
              <MultiSelect
                selectedValues={mergeData.data}
                onChange={onChange}
                warningCondition={mergeData.data.length < 2 || showHomeownerError}
                warningMessage={mergeData.data.length < 2 && mergeData.type === "customer" ?
                  "Please Select another customer" :
                  mergeData.data.length < 2 && mergeData.type === "realtor" ?
                    "Please select another realtor" :
                    showHomeownerError ? "One of the customer selected is a homeowner, please update it in Heartbeat" :
                      ""}
                labelMapper={mergeData.type === "customer" ? (field) =>
                  `${field["first_name_1"]} ${field["last_name_1"]}` :
                  mergeData.type === "realtor" ? (field) =>
                    `${field["first_name"]} ${field["last_name"]}`
                    : null}
                onButtonClick={handleShowMergeDialog}
                buttonValue={mergeData.type === "customer" ? "Merge Customers" :
                  mergeData.type === "realtor" ? "Merge Realtors" : ""}
              />
              : null}

            {mergeData.type === "customer" ?
              <MergeCustomersDialog
                data={mergeData.data}
                communityData={communityData}
                show={showMergeDialog}
                onHide={handleShowMergeDialog}
                onSubmit={onMergeSubmit}
              /> :
              <MergeRealtorsDialog
                data={mergeData.data}
                show={showMergeDialog}
                onHide={handleShowMergeDialog}
                onSubmit={onMergeSubmit}
              />}

            {searchTerm ? (
              <>
                <GlobalWebFormTable
                  searchTerm={searchTerm}
                  handleDrawerClose={toggleShowDrawer}
                />
                <GlobalLeadsTable
                  searchTerm={searchTerm}
                  handleDrawerClose={toggleShowDrawer}
                  setMergeData={setMergeData}
                />
                <GlobalProspectsTable
                  searchTerm={searchTerm}
                  handleDrawerClose={toggleShowDrawer}
                  setMergeData={setMergeData}
                />
                <GlobalHomeownersTable
                  searchTerm={searchTerm}
                  handleDrawerClose={toggleShowDrawer}
                />
                <GlobalArchiveUsersTable
                  searchTerm={searchTerm}
                  handleDrawerClose={toggleShowDrawer}
                  setMergeData={setMergeData}
                />
                <GlobalRealtorsTable
                  searchTerm={searchTerm}
                  handleDrawerClose={toggleShowDrawer}
                  setMergeData={setMergeData}
                />
              </>
            ) : (
              <div className="d-flex align-content-center justify-content-center flex-column text-center">
                <img
                  src={emptySearch}
                  alt=""
                  style={{ width: "280px", margin: "0 auto" }}
                />
                <p style={{ marginLeft: "10rem", marginTop: "1rem" }}>
                  Please provide a search query above.
                </p>
              </div>
            )}
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default GlobalSearchDetails;
