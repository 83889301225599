import React from "react";
import PropTypes from "prop-types";
import { fetch } from 'whatwg-fetch'

import "./error-boundary.css";

/**
 * Error boundaries are React components that catch JavaScript errors anywhere in their child component tree, log those errors, and display a fallback UI instead of the component tree that crashed.
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    });

    fetch(process.env.REACT_APP_LOGGER_URL, {
      method: 'POST',
      body: JSON.stringify({ error: error, errorInfo: errorInfo }),
      headers: {
        "Content-Type": "application/json"
      }
    }).then(res => {
      console.log('RESPONSE: ', res)
    }).catch(err => console.log(err))
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div className="m-5">
          <h1>Oops! Something went wrong.</h1>
          <p>You may refresh the page or navigate to any other page.</p>
          <button
            className="btn custom-btn"
            onClick={() => this.props.history.push("/")}
          >
            Back to Dashboard
          </button>{" "}
          <button
            className="btn custom-btn"
            onClick={() => window.location.reload()}
          >
            Refresh
          </button>
          <details className="details">
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo && this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.object,
  history: PropTypes.object,
};

export default ErrorBoundary