import React from "react";
import "./custom-overlay-backdrop.css";

/**
 * This component shows backdrop with custom icon or image at the centre of the page
 * @param {Object} props component props 
 * @param {String} props.Image relative path of image you want to show on the backdrop
 * @param {JSX.Element} props.icon custom icon to show on the background
 * @param {String} props.message custom message to display below the image
 */
const OverlayBackdrop = props => {
  return (
    <div
      className="overlay-backdrop "
      style={{
        height: props.height,
        background: props.background ? "rgba(0, 0, 0, 0.6)" : "none"
      }}
    >
      <div className="content-empty">
        {props.Image && (
          <img
            src={props.Image}
            className="icon_backdrop"
            style={{ verticalAlign: "unset" }}
            alt=""
          ></img>
        )}
        {props.icon && (
          <props.icon
            style={{
              color: "#908F8F",
              height: "40",
              width: "40",
              right: "15px",
              position: "absolute"
            }}
            // className="justify-item-center"
          />
        )}
        <p className="mt-3 message_backdrop">{props.message}</p>
      </div>
      
      
    </div>
  );
};
export default OverlayBackdrop;
