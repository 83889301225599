import gql from "graphql-tag";

const GET_NOTIFICATIONS = gql`
  query getNotifications($filter: getNotificationsFilter, $limit: Int, $pageNum: Int) {
    getNotifications(filter: $filter, limit: $limit, pageNum: $pageNum) {
      total_count
      Notifications {
        id
        description
        url
        notification_datetime
        is_read
        creator {
          first_name
          last_name
          image_url
        }
      }
    }
  }
`;
export default GET_NOTIFICATIONS;
