import React from "react";
import { useMutation } from "react-apollo-hooks";
import { MARK_NOTIFICATION_READ } from "../../graphql/mutations";
import { formatNotificationDate } from "../../utils/helpers";
import logo from "../../images/hbcrm.png";

const Notification = ({ description, url, notification_datetime, is_read, id, ...props }) => {
  const [markNotificationsAsRead] = useMutation(MARK_NOTIFICATION_READ);

  const handleNotificationClick = async () => {
    await markNotificationsAsRead({
      variables: { ids: [id] }
    })

    window.location.assign(url)
    props.close()
  }

  return (
    <>
      <div className={`notification ${!is_read ? 'new-notification' : ''}`} onClick={handleNotificationClick}>
        <div className="notification-img">
          <img src={props.creator?.image_url || logo} />
        </div>
        <div className="notification-desc">
          <div className="d-flex justify-content-between align-items-center">
            <span title={description}> {description} </span>
          </div>
          <span className="notification-date"> {`${props.creator?.first_name} ${props.creator?.last_name} - ${formatNotificationDate(notification_datetime)}`} </span>
        </div>
      </div>
      <hr className="m-0 light" style={{ background: 'rgb(148, 211, 0, 0.4)' }} />
    </>
  )
}

export default Notification;