import React from "react";
import { FaChevronLeft } from "react-icons/fa";
import { SelectBar } from "../../../containers/";
import { goBack, getIssueEmail } from "../../../utils/helpers";
import { REALTOR } from "../../../utils/constants"
import NoDataPage from "../../no-data-page/no-data-page";
import LoadingWrapper from "../../../components/loading-wrapper";
import CustomButton from "../../../components/custom-button";
import Header from "../../../components/dashboard-title";
import RealtorDetailCard from "./realtor-detail-card";
import NoteReminderView from "../../common-lead-prospect-details/notes-card";
import RealtorDetailsTable from "./sales-registration-table";
import logo from "../../../images/graphic-no-prospect.png";

/**
 * This component is used to render all realtors, YOY sales and YOY registration cards details page. It is divided into four main parts. On the top
 * it renders a selectBar component to display realtor's name. Right
 * below it, we have YOY sales and YOY registration tables and next to it {@link RealtorDetailCard} card is rendered ti show basic 
 * information of the realtor and to edit it as well and note and reminders section. If the realtor is not available, no data page is displayed instead of details page. 
 * while the data is loading, a full page loader is rendered. 
 * @param {Object} props queries from the container are received as props 
 */
const RealtorDetailsView = props => {
  const { history, getRealtorDetails } = props;

  const realtorDetailsView = (id, data, loading, refetch) => {
    const { first_name, last_name } = data;
    const {
      notes,
      sales_count,
      registration_count,
      registrations,
      sales,
      ...details
    } = data;

    const submitHandler = message => {
      message &&
        props.setNotificationProps({
          variables: { open: true, message: message }
        });
      refetch();
    };

    return (
      <LoadingWrapper
        loading={loading}
        component={
          <div className="col-padding col-md-12 col-12">
            <div
              className="root-row z4"
              id="prospect-detail-header"
            >
              <SelectBar>
                <div className="d-flex">
                  <CustomButton
                    onClick={() => goBack(history)}
                    id="back-to-dashboard-btn"
                    color="dark"
                    btnIcon={<FaChevronLeft />}
                    className="btn-back"
                  />
                  <Header
                    title="Realtor"
                    subtitle={loading ? "" : first_name + " " + (last_name ? last_name : "")}
                  />
                </div>
              </SelectBar>
            </div>

            <div className="row-container main-responsive col-xl-12 row p-2 px-3">
              <div className="detail-view-main col-padding col-md-8 col-8 mt-2">
                <RealtorDetailsTable
                  data={sales}
                  type="Sales"
                  loading={loading}
                />
                <div className="mt-3">
                  <RealtorDetailsTable
                    data={registrations}
                    type="Registrations"
                    loading={loading}
                  />
                </div>
              </div>

              <div className="col-padding col-md-4 col-12 pb-2 pt-2 pr-0 pl-3 detail-view-side">
                <RealtorDetailCard
                  data={loading ? null : details}
                  refetch={refetch}
                />
                <div className="ml-md-2">
                  <NoteReminderView
                    {...props}
                    id={id}
                    type={REALTOR}
                    data={loading ? null : data}
                    loading={loading}
                    onSubmit={submitHandler}
                  />
                </div>
              </div>
            </div>
          </div>
        }
      />
    );
  };

  const { loading, refetch } = getRealtorDetails;
  return !loading && getRealtorDetails.getRealtors.realtors.length === 0 ? (
    <NoDataPage
      {...props}
      backgroundImage={logo}
      heading={"No Realtor Found."}
      content={<div>
        <p className="message-reportBug">The resource for this realtor was not found in our records.<br />If it's a bug, please report.<br /><a href={getIssueEmail()} target="_blank" rel="noopener noreferrer" className="btn btn-primary">Report</a></p>
      </div>}
    />
  ) : (
    realtorDetailsView(
      parseInt(props.match.params.id),
      loading ? {} : getRealtorDetails.getRealtors.realtors[0],
      loading,
      refetch
    )
  );
};

export default RealtorDetailsView;
