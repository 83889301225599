import React from 'react';

import Drawer from "../../../hoc/Drawer";
import LeadScheduleAppointmentForm from '../forms/lead-schedule-appointment-container';

/**
 * this component is used to render the schedule an appointment form drawer 
 * @param {*} param0 
 */
const LeadScheduleAppointmentView = ({ ...props }) => {

  return (
    <Drawer
      show={props.show}
      toggleSideBar={props.close}
    >
      <LeadScheduleAppointmentForm
        close={props.close}
        leadId={props.id}
        onSubmit={props.onSubmit}
        leadData={props.leadData}
      />
    </Drawer>
  )
}

export default LeadScheduleAppointmentView;