
import gql from 'graphql-tag';

const DELETE_REALTOR_NOTE = gql` 
  mutation deleteRealtorNote($input: RealtorNoteDeleteInput!) {
    deleteRealtorNote(input: $input) {
      message
      code
    }
  }
`;

export default DELETE_REALTOR_NOTE;