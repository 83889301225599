import { graphql, compose } from "react-apollo";
import Notification from "../../components/notification/notification";
import { GET_NOTIFICATION_PROPS } from "../../cacheql/queries";
import { SET_NOTIFICATION_PROPS } from '../../cacheql/mutations'

const NotificationContainer = compose(
    graphql(GET_NOTIFICATION_PROPS),
    graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" })
)(Notification);

export default NotificationContainer;
