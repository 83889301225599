import gql from "graphql-tag";

const GET_LOGGED_IN_DIVISIONS = gql`
  query getLoggedInUserDivisions {
    getLoggedInUserDivisions {
      id
      name
      description
    }
  }
`;

export default GET_LOGGED_IN_DIVISIONS;