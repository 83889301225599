import gql from 'graphql-tag';

const UPDATE_PROSPECT_FOLLOW_UP = gql` 
  mutation updateProspectFollowUp($input: ProspectFollowUpUpdateInput!) {
    updateProspectFollowUp(input: $input) {
      message
      code
    }
  }
`;

export default UPDATE_PROSPECT_FOLLOW_UP;