import React, { useEffect, useMemo, useContext, useRef } from "react";
import reducer, { getInitialState } from "../realtor-registrations-reducer";
import { MdMessage } from "react-icons/md";
import { DashboardContext } from "../../../context";
import { compose, graphql } from "react-apollo";
import { CreateEditRealtor } from "../realtor-form-container";
import { DELETE_CLIENT_REGISTRATION } from "../../../graphql/mutations";
import { GET_NEW_REALTOR_REGISTRATIONS } from "../../../graphql/queries";
import { FaUserPlus, FaFileAlt, FaTrashAlt } from "react-icons/fa";
import { SUB_NEW_CLIENT_REGISTRATION_CHANGE } from "../../../graphql/subscriptions";
import { SET_NOTIFICATION_PROPS, SET_TABLE_PARAMS } from "../../../cacheql/mutations";
import { GET_TABLE_PARAMS, GET_SELECTED_DIVISION } from "../../../cacheql/queries";
import Drawer from "../../../hoc/Drawer";
import FadeIn from "../../../components/fade-in";
import Dialog from "../../../components/dialog/dialog";
import CustomLoader from "../../../components/custom-loader";
import TwoPersonIcon from "../../../images/two-person-icon.svg"
import RegistrationDetail from "../details/registration-detail";
import {
  formatActivityDate,
  getCardParams,
  getUpdatedParams,
  phoneDisplay,
} from "../../../utils/helpers";
import TableView, {
  SortableHeader,
  stringComparator,
  EmailCell,
  ActionCell,
  TextHeader,
  PhoneCell,
  ListCell,
  iconTextCell,
  RespondLinkCell,
  TextCellWithMapper,
  dateTimeComparator,
  RespondCell
} from "../../../components/new-table";

/** @module ClientRealtorRegistrationTableModule */

/**
 * This component is a table used to render new client registrations card table. 
 * It contains pagination, search bar and export to csv options.
 * It shows multiple columns displaying basic information related to new client registrations. 
 * Table uses reducer to maintain its state. It also accesses store to check if table parameters are already stored or not.
 *
 * @param {Object} props it contains all the queries/mutations in the conatiner as props
 * @param {Object} props.data it contains array of new registrations to be shown on table
 * @param {Object} props.getTableParams it contains cards info stored inside cache
 * @returns {JSX.Element} It returns jsx containing the table
 */
const ClientRealtorRegistrationTable = ({
  data,
  markRead,
  deleteRealtorClientRegistration,
  getTableParams: { tableParams },
  ...props
}) => {
  const handler = useRef(false);
  const { currentCard } = useContext(DashboardContext);
  const cacheParams = getCardParams(currentCard.uniqueTitle, tableParams);
  const [state, dispatch] = React.useReducer(
    reducer,
    getInitialState({ loading: data.loading, cache: cacheParams })
  );

  const {
    showDetailsBar,
    showRealtorBar,
    realtors,
    drawerItem,
    registrationDetail,
    loading,
    render,
    userPageCount,
    pageSize,
    isSubscription,
    showDeleteConfirmation,
    delete_reg_id,
  } = state;

  const callDispatch = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  /**
   * updates the data in state based on subscribtion
   */
  useEffect(() => {
    handler.current = data.subscribeToMore({
      document: SUB_NEW_CLIENT_REGISTRATION_CHANGE,
      variables: { registration_id: null },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) return prev;
        callDispatch("UPDATE", { isSubscription: true, loading: true });
        return prev;
      },
    });
    return () => {
      handler.current && handler.current();
      handler.current = false;
    };
  }, [props.currentCard.uniqueTitle]);

  /** Refetch table data on any update */
  useEffect(() => {
    if (isSubscription || props.updated !== null) {
      callDispatch("UPDATE", { isSubscription: false });
      data.refetch();
    }
  }, [isSubscription, props.updated]);

  /**
   * Either set the data in state or set the loading to inform the table what to render.
   */
  useEffect(() => {
    if (
      !data.loading &&
      data.getRealtorClientRegistrations &&
      data.getRealtorClientRegistrations.realtor_client_registration
    )
      callDispatch("UPDATE", {
        render: true,
        loading: false,
        realtors: data.getRealtorClientRegistrations.realtor_client_registration,
        userPageCount: Math.ceil(
          data.getRealtorClientRegistrations.total_count / pageSize
        ),
      });
    if (data.loading) callDispatch("UPDATE", { loading: data.loading });
  }, [data]);

  const setShowRealtorBar = (toSetValue) =>
    callDispatch("UPDATE", { showRealtorBar: toSetValue });
  const setShowDetailsBar = (toSetValue) =>
    callDispatch("UPDATE", { showDetailsBar: toSetValue });

  const addHandler = (record) => {
    const name = record.realtor_name ? record.realtor_name.split(" ") : "";
    let realtor = {
      first_name: name ? name[0] : "",
      last_name: name ? name[1] : "",
      primary_email: record.realtor_email,
      cell_phone: record.realtor_phone,
      division: record.division,
      note: record.realtor_note
    };
    callDispatch("UPDATE", {
      showRealtorBar: true,
      drawerItem: (
        <CreateEditRealtor
          registration={record}
          realtor={realtor}
          onSubmit={() => {
            setShowRealtorBar(false);
            props.update();
          }}
          close={() => setShowRealtorBar(false)}
        />
      ),
    });
  };

  const detailsHandler = (data) => {
    callDispatch("UPDATE", { showDetailsBar: true, registrationDetail: data });
  };

  const deleteConfirmationBody = () => {
    return (
      <div>
        <p className="mb-0">Are you sure you want to delete this record?</p>
      </div>
    );
  };

  const handleDelete = (itemId) => {
    dispatch({
      type: "UPDATE",
      payload: {
        showDeleteConfirmation: !showDeleteConfirmation,
        delete_reg_id: itemId,
      },
    });
  };

  const hideDeleteDialog = () => {
    dispatch({
      type: "UPDATE",
      payload: { showDeleteConfirmation: !showDeleteConfirmation },
    });
  };

  const handleDeleteConfirmation = () => {
    dispatch({
      type: "UPDATE",
      payload: { showDeleteConfirmation: !showDeleteConfirmation },
    });
    deleteRealtorClientRegistration({
      variables: { client_registration_id: delete_reg_id },
    }).then((res) => {
      if (res.data.deleteRealtorClientRegistration.code === 200) {
        props.setNotificationProps({
          variables: {
            open: true,
            message: res.data.deleteRealtorClientRegistration.message,
          },
        });
      }
    });
  };

  /**
   * This defines columns of the table. It has multiple columns, 
   * displaying information of the new registrations. 
   * @constant
   * @memberof module:RealtorTableModule
   */
  const columns = useMemo(
    () => [
      {
        id: "0",
        header: "",
        accessor: "",
        component: TextHeader,
        cell: {
          component: RespondCell,
          icon: <img src={TwoPersonIcon} className="icon-img" alt=""></img>,
          hide: (field, _) => {
            return (
              !field.client_name_2 &&
              !field.client_email_2 &&
              !field.client_phone_2
            );
          },
        },
      },
      {
        id: "1",
        header: "Client Name",
        accessor: "client_name",
        sortName: "name",
        component: SortableHeader,
        comparator: stringComparator,
        cell: {
          component: RespondLinkCell,
          action: detailsHandler,
        },
      },
      {
        id: "2",
        header: "Client Email",
        accessor: "client_email",
        sortName: "email",
        component: SortableHeader,
        comparator: stringComparator,
        cell: { component: EmailCell },
      },
      {
        id: "3",
        header: "Client Phone",
        accessor: "client_phone",
        component: TextHeader,
        sortable: false,
        csvMapper: (field, accessor) => phoneDisplay(field[accessor]) || "",
        cell: { component: PhoneCell },
      },
      {
        id: "4",
        header: "Client Name 2",
        accessor: "client_name_2",
        hidden: true,
        cell: { component: null },
      },
      {
        id: "5",
        header: "Client Email 2",
        accessor: "client_email_2",
        hidden: true,
        cell: { component: null },
      },
      {
        id: "6",
        header: "Client Phone 2",
        accessor: "client_phone_2",
        hidden: true,
        csvMapper: (field, accessor) => phoneDisplay(field[accessor]) || "",
        cell: { component: null },
      },
      {
        id: "7",
        header: "Community(ies)",
        accessor: "communities",
        component: TextHeader,
        sortable: false,
        csvMapper: (field, accessor) =>
          field[accessor] ? field[accessor].map(({ name }) => name) : "",
        cell: {
          component: ListCell,
          mapper: (field) =>
            field.map((item) => <div key={item.id}>{item.name}</div>),
        },
      },
      {
        id: "8",
        header: "Message",
        accessor: "realtor_client_notes",
        component: TextHeader,
        csvMapper: (field, accessor) =>
          field[accessor][field[accessor].length - 1].note
            ? field[accessor][field[accessor].length - 1].note.replace(
              /<br>/gm,
              ""
            )
            : "",
        cell: {
          component: iconTextCell,
          action: detailsHandler,
          Icon: MdMessage,
          mapper: (field, accessor) =>
            field[accessor][field[accessor].length - 1].note &&
            field[accessor][field[accessor].length - 1].note.replace(
              /<br>/gm,
              ""
            ),
          check: (field, accessor) => field[accessor].length > 1,
        },
      },
      {
        id: "9",
        header: "Realtor Name",
        accessor: "realtor_name",
        component: TextHeader,
        sortable: false,
        cell: {
          component: iconTextCell,
          action: addHandler,
          Icon: FaUserPlus,
          check: (field) => !field["existing_realtor"],
          displayName: (field, accessor) =>
            field["existing_realtor"]
              ? `${field["existing_realtor"].first_name} ${field["existing_realtor"].last_name
                ? field["existing_realtor"].last_name
                : ""
              }`
              : field[accessor],
        },
      },
      {
        id: "10",
        header: "Registration Date",
        accessor: "created_at",
        component: SortableHeader,
        comparator: dateTimeComparator,
        csvMapper: (field, accessor) => formatActivityDate(field[accessor]),
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) => formatActivityDate(field[accessor]),
        },
      },
    ],
    []
  );

  /**
   * Array of objects containing different actions which can be performed on row hover.
   * @constant
   * @memberof module:RealtorTableModule
   */
  const hoverActions = useMemo(
    () => [
      {
        tooltip: "Realtor Registration Details",
        icon: FaFileAlt,
        component: ActionCell,
        action: detailsHandler,
      },
      {
        tooltip: "Delete",
        icon: FaTrashAlt,
        component: ActionCell,
        action: ({ id }) => {
          handleDelete(id);
        },
      }
    ],
    []
  );

  /**
   * This is an object that defines table title.
   *
   * @constant
   * @memberof module:RealtorTableModule
   */
  const tableOptions = {
    title: currentCard.uniqueTitle,
  };

  //Fetchmore and pagination functions
  /**
   * It is a callback function which is called on any table change like: pagination, page-size,search term.
   * Table passes its current state so that we can update the table parameters in store and state, which are used in
   * refetching the data with correct parameters.
   *
   * @function
   * @inner
   * @memberof module:RealtorTableModule
   * @see {@link module:RealtorTableModule~RealtorTable}
   */
  const fetchData = React.useCallback((tableProps) => {
    if (loading) {
      return;
    }

    const columnItem = columns.find(
      (item) => item.accessor === tableProps.sortColumn.name
    );

    const variables = {
      search: tableProps.searchTerm,
      limit: tableProps.pageSize,
      pageNum: tableProps.currentPage,

      order: {
        id: tableProps.sortColumn.id,
        name: (columnItem && columnItem.sortName) || tableProps.sortColumn.name,
        sort: tableProps.sortColumn.sort,
      },
    };

    if (tableProps.searchTerm !== state.search) {
      variables.pageNum = 0;
    }

    callDispatch("UPDATE", {
      ...variables,
      pageSize: tableProps.pageSize,
      loading: true,
    });

    const pageCount = Math.ceil(
      data.getRealtorClientRegistrations.total_count / tableProps.pageSize
    );
    if (pageCount !== userPageCount)
      callDispatch("UPDATE", {
        userPageCount: pageCount,
      });

    props
      .setTableParams({
        variables: {
          cardsParams: getUpdatedParams(tableParams.cardsParams, {
            cardTitle: currentCard.uniqueTitle,
            params: variables,
          }),
        },
      })
      .then((res) => {
        dispatch({ type: "UPDATE", payload: { loading: false } });
      });
  });

  return (
    <>
      <Dialog
        show={showDeleteConfirmation}
        onHide={hideDeleteDialog}
        title="Delete Confirmation"
        body={deleteConfirmationBody()}
        click={handleDeleteConfirmation}
        clickname="YES"
        closename="NO"
      />
      <FadeIn show={render}>
        <TableView
          columns={columns}
          data={realtors}
          tableOptions={tableOptions}
          loading={loading}
          hoverActions={hoverActions}
          userPageCount={userPageCount}
          userTotalCount={
            !loading && data.getRealtorClientRegistrations
              ? data.getRealtorClientRegistrations.total_count
              : null
          }
          fetchData={fetchData}
          currentPage={state.pageNum}
          pageSize={state.pageSize}
          searchTerm={state.search}
          sortColumn={state.order}
          controlled={true}
        />
      </FadeIn>
      {!render && <CustomLoader />}
      {showDetailsBar && (
        <RegistrationDetail
          showSideBar={showDetailsBar}
          toggleShowDrawer={() => setShowDetailsBar(false)}
          id={registrationDetail.id}
          addRealtor={addHandler}
          update={props.update}
        />
      )}

      <Drawer
        show={showRealtorBar}
        toggleSideBar={() => setShowRealtorBar(false)}
      >
        {drawerItem}
      </Drawer>
    </>
  );
};

export const NewClientRegistrations = compose(
  graphql(GET_SELECTED_DIVISION, { name: "selectedDivision" }),
  graphql(SET_TABLE_PARAMS, { name: "setTableParams" }),
  graphql(GET_TABLE_PARAMS, { name: "getTableParams" }),
  graphql(GET_NEW_REALTOR_REGISTRATIONS, {
    options: ({
      selectedDivision,
      currentCard: { uniqueTitle },
      getTableParams: { tableParams }
    }) => {
      let params = getCardParams(uniqueTitle, tableParams);
      return {
        variables: {
          pageNum: (params && params.pageNum) || 0,
          limit: (params && params.limit) || 10,
          sort:
            params && params.order && params.order.id !== -1
              ? [params.order.name, params.order.sort]
              : null,
          search: (params && params.search) || "",
          filter: {
            division_id: selectedDivision.selectedDivision.id,
          },
        },
        fetchPolicy: "cache-and-network",
      };
    },
  }),
  graphql(DELETE_CLIENT_REGISTRATION, { name: "deleteRealtorClientRegistration" }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" })
)(ClientRealtorRegistrationTable);

