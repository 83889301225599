import React, { useEffect, useRef } from "react";

import {
  formatActivityDate,
  getCardParams,
  getUpdatedParams,
  phoneDisplay,
} from "../../utils/helpers";
import TableView, {
  SortableHeader,
  stringComparator,
  EmailCell,
  TextHeader,
  TextCellWithMapper,
  PhoneCell,
  TooltipCell,
  DetailLinkCell,
} from "../../components/new-table";
import { SUB_FORM_SUBMISSION } from "../../graphql/subscriptions";
import { OTHER_TYPE_ID } from "./customer-types";
import routeConstants from "../layout/routes";

import reducer, { getInitialState } from "./webform-others-reducer";

/** @module WebFormOthersTableModule */

/**
 * This component is a table used to display formsubmission for other types. It contains pagination, searchbarand export
 * to csv options.
 *
 * @param {Object} props it contains all the mutations/queries in the conatiner as props
 * @param {Object} props.data it contains array of formsubmissions to be shown on table
 * @param {Object} props.getTableParams it contains cards info stored inside cache
 * @returns {JSX.Element} It returns jsx containing the table, dialog box and the side drawer to show formsubmission edit form.
 */
const WebFormOthersTable = (props) => {
  const {
    data,
    getTableParams: { tableParams },
  } = props;
  const cacheParams = getCardParams("Web Form Others", tableParams);

  const [state, dispatch] = React.useReducer(
    reducer,
    getInitialState({ loading: data.loading, cache: cacheParams })
  );

  const {
    isSubscription,
    userPageCount,
    formSubmission,
    pageSize,
    loading,
    ...params
  } = state;
  const handler = useRef(false);

  /**
   * Either set the data in state or set the loading to inform the table what to render.
   */
  useEffect(() => {
    if (!data.loading) {
      dispatch({
        type: "UPDATE",
        payload: {
          formSubmission: data.getFormSubmission.formSubmission,
          loading: false,
          userPageCount: Math.ceil(
            data.getFormSubmission.total_count / pageSize
          ),
        },
      });
    }
    if (data.loading) {
      dispatch({ type: "UPDATE", payload: { loading: data.loading } });
    }
  }, [data]);

  useEffect(() => {
    handler.current = data.subscribeToMore({
      document: SUB_FORM_SUBMISSION,
      variables: { customer_type_id: OTHER_TYPE_ID },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) return prev;
        dispatch({
          type: "UPDATE",
          payload: { isSubscription: true, loading: true },
        });
        return prev;
      },
    });
    return () => {
      handler.current && handler.current();
      handler.current = false;
    };
  }, []);

  /**
   * refetch table data if any update is performed
   */
  useEffect(() => {
    if (isSubscription) {
      dispatch({ type: "UPDATE", payload: { isSubscription: false } });
      data.refetch(params);
    }
  }, [isSubscription]);

  /**
   * It is a callback function which is called on any table change like: pagination, page-size, search term.
   * Table passes its current state so that we can update the table parameters in store and state, which are used in
   * refetching the data with correct parameters.
   *
   * @param {Object} tableProps current state of the table
   * @function
   * @inner
   * @memberof module:WebFormOthersTableModule
   * @see {@link module:WebFormOthersTableModule~WebFormOthersTable}
   */
  const fetchData = React.useCallback((tableProps) => {
    if (loading) {
      return;
    }

    const variables = {
      limit: tableProps.pageSize,
      pageNum: tableProps.currentPage,
      search: tableProps.searchTerm,
      order: {
        id: tableProps.sortColumn.id,
        name: tableProps.sortColumn.name,
        sort: tableProps.sortColumn.sort,
      },
    };

    if (tableProps.searchTerm !== state.search) {
      variables.pageNum = 0;
    }

    dispatch({
      type: "UPDATE",
      payload: { ...variables, pageSize: tableProps.pageSize, loading: true },
    });

    const pageCount = Math.ceil(
      data.getFormSubmission.total_count / tableProps.pageSize
    );
    if (pageCount !== userPageCount) {
      dispatch({
        type: "UPDATE",
        payload: { userPageCount: pageCount },
      });
    }

    props
      .setTableParams({
        variables: {
          cardsParams: getUpdatedParams(tableParams.cardsParams, {
            cardTitle: "Web Form Others",
            params: variables,
          }),
        },
      })
      .then((res) => {
        dispatch({ type: "UPDATE", payload: { loading: false } });
      });
  });

  /**
   * this function is used to map the responded at  cells value
   * @param {object} field rows of the table
   * @param {string} accessor object attribute name
   * @function
   * @inner
   * @memberof module:WebFormOthersTableModule
   * @returns {string}
   */
  const respondedAtMapper = (field, accessor) => {
    const details = field[accessor] && field[accessor].filter((val) => val);
    const allResponded = details.every((elem) => elem.responded_at);
    const sortedRespondedAt = details
      .map((elem) => elem.responded_at)
      .sort((a, b) => new Date(b) - new Date(a));
    return allResponded ? formatActivityDate(sortedRespondedAt[0]) : "";
  };

  /**
   * Columns of the table are memoized and are created again when current card changes, since columns vary according
   * to selected card
   * @constant
   * @memberof module:WebFormOthersTableModule
   */
  const columns = React.useMemo(() => [
    {
      id: "1",
      header: "First Name",
      accessor: "firstname",
      component: SortableHeader,
      comparator: stringComparator,
      cell: {
        component: DetailLinkCell,
        path: (field, accessor) => routeConstants.WEB_FORM_DETAIL,
      },
    },
    {
      id: "2",
      header: "Last Name",
      accessor: "lastname",
      component: SortableHeader,
      comparator: stringComparator,
      cell: {
        component: DetailLinkCell,
        path: (field, accessor) => routeConstants.WEB_FORM_DETAIL,
      },
    },
    {
      id: "3",
      header: "Email",
      accessor: "email",
      component: SortableHeader,
      comparator: stringComparator,
      cell: {
        component: EmailCell,
      },
    },
    {
      id: "4",
      header: "Phone",
      accessor: "phone",
      component: TextHeader,
      sortable: false,
      csvMapper: (field, accessor) => phoneDisplay(field[accessor]) || "",
      cell: { component: PhoneCell },
    },
    {
      id: "5",
      header: "Message",
      accessor: "interested_in",
      component: TextHeader,
      cell: {
        component: TooltipCell,
      },
    },
    {
      id: "6",
      header: "Type",
      accessor: "status",
      component: TextHeader,
      csvMapper: (field, accessor) => field[accessor].name,
      cell: {
        component: TextCellWithMapper,
        mapper: (field, accessor) => field[accessor].name,
      },
    },
    {
      id: "7",
      header: "Received",
      accessor: "created_at",
      component: SortableHeader,
      csvMapper: (field, accessor) => formatActivityDate(field[accessor]),
      cell: {
        component: TextCellWithMapper,
        mapper: (field, accessor) => formatActivityDate(field[accessor]),
      },
    },
    {
      id: "8",
      header: "Responded",
      accessor: "responded_at",
      component: TextHeader,
      csvMapper: (field, accessor) => formatActivityDate(field[accessor]),
      cell: {
        component: TextCellWithMapper,
        mapper: (field, accessor) => formatActivityDate(field[accessor]),
      },
    },
  ]);

  const tableOptions = {
    title: "Others",
  };

  return (
    <div>
      <div className="webform-others-table">
        <TableView
          columns={columns}
          data={formSubmission}
          tableOptions={tableOptions}
          loading={loading}
          userPageCount={userPageCount}
          userTotalCount={!loading ? data.getFormSubmission.total_count : null}
          fetchData={fetchData}
          currentPage={state.pageNum}
          pageSize={state.pageSize}
          searchTerm={state.search}
          sortColumn={state.order}
          controlled={true}
        />
      </div>

    </div>
  );
};

export default WebFormOthersTable;
