import gql from 'graphql-tag';

const MARK_HOMEOWNER_APPOINTMENT_DONE = gql` 
mutation markDoneHomeownerAppointment($input: HomeownerAppointmentMarkDoneInput!) {
    markDoneHomeownerAppointment(input: $input){
    message
    code
    }
}
`;

export default MARK_HOMEOWNER_APPOINTMENT_DONE;