
import gql from 'graphql-tag';

const CHANGE_CSM = gql` 
  mutation transferProspect($input: transferProspectInput!) {
    transferProspect(input: $input) {
      message
      code
    }
  }
`;

export default CHANGE_CSM;