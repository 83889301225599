import React from "react";
import { Form, Field, withFormik, FieldArray } from "formik";
import { FaTimes } from "react-icons/fa";
import { ButtonToolbar } from "react-bootstrap";

import { DefaultQuery } from "../../../graphql/default-query";
import {
  GET_AVAILABLE_FLOORPLANS,
  GET_AVAILABLE_HOMESITES,
  GET_AVAILABLE_OTHERS,
  GET_COMMUNITIES,
  GET_CSM,
  GET_HOMESITES,
  GET_OTHERVALUES,
} from "../../../graphql/queries";
import { addCommunityForm, addCommunitySchema } from "./add-community-schema";
import SelectField from "../../../components/fields/select-field";
import CustomButton from "../../../components/custom-button/index";
import ErrorFocus from "../../../components/error-focus/error-focus";
import { isNotNULL, getActiveCommunities } from "../../../utils/helpers";

import "./prospect-forms.css";
import MultiSelectField from "../../../components/fields/multi-select-field";

/**
 * This component is used to render add community form in prospect details card. It contains fields for community related attributes. 
 * @param {Object} props
 * @param {Object} props.values Values from the form
 * @param {Object} props.prospectData Details of the prospect whose community is being added in this form
 */
const AddCommunityForm = (props) => {
  const { values, prospectData } = props;

  let dropdown_communities = [];

  return (
    <Form className="form-horizontal">
      <div className="form_TitleContainer">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ margin: "0 1.1rem" }}
        >
          <div className="pl-1">
            <div className="form_Title">Add Community(s)</div>
            <div className="form_TitleBig">
              <div className="d-flex">
                <h2
                  style={{
                    position: "relative",
                    paddingRight: "0.5em",
                    marginTop: "4px",
                  }}
                >
                  {prospectData.first_name_1 + " " + prospectData.last_name_1}
                </h2>
              </div>
            </div>
          </div>

          <div className="d-flex">
            <ButtonToolbar>
              <CustomButton
                color="black"
                className={`form_CancelButton ml-0`}
                onClick={() => {
                  props.toggleSideBar(false);
                }}
                btnValue="CANCEL"
              />
              <CustomButton
                className={`form_SubmitButton`}
                type="submit"
                disabled={props.isSubmitting}
                btnValue={"CONVERT"}
              />
            </ButtonToolbar>
          </div>
        </div>
      </div>
      <div className="form_body">
        <div className="form-row">
          <div className="form-section">
            <h5>Community(s)</h5>
          </div>
        </div>
        <FieldArray
          name="Prospects"
          render={(arrayHelpers) => (
            <div>
              {values.Prospects &&
                typeof Array.isArray(values.Prospects) &&
                values.Prospects.map((pr, index) => (
                  <div className="form-community" key={index}>
                    <div className="row form_deleteButtonRow">
                      <CustomButton
                        className="icon-btn btn-icon"
                        onClick={() =>
                          values.Prospects.length > 1 &&
                          arrayHelpers.remove(index)
                        }
                      >
                        <FaTimes size={14} style={{ color: "white" }} />
                      </CustomButton>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <DefaultQuery
                          query={GET_COMMUNITIES}
                          variables={{
                            division_id: values.division_id
                              ? values.division_id
                              : null,
                          }}
                          fetchPolicy="network-only"
                        >
                          {({ data, loading, error }) => {
                            let defaultValue = loading
                              ? "Loading ..."
                              : error
                              ? error.message
                              : "Select...";

                            const dropdown_communities =
                              data?.getAllCommunities || [];
                            return (
                              <Field
                                name={`Prospects[${index}].community_id`}
                                label="Community"
                                id="prospect-community"
                                fieldsToClear={[
                                  {
                                    fieldName: `Prospects[${index}].home_sites`,
                                    value: [],
                                  },
                                  {
                                    fieldName: `Prospects[${index}].other_dropdown_values`,
                                    value: [],
                                  },
                                  {
                                    fieldName: `Prospects[${index}].csm_id`,
                                    value: null,
                                  },
                                  {
                                    fieldName: `Prospects[${index}].floor_plans`,
                                    value: [],
                                  },
                                ]}
                                required={true}
                                component={SelectField}
                              >
                                <option value="">{defaultValue}</option>
                                {dropdown_communities &&
                                  dropdown_communities.map(
                                    (community, communityIndex) => {
                                      let communityAlreadySelected = false;

                                      communityAlreadySelected = props.prospectData.all_communities.some(item => item.id === community.id)
                                      for (
                                        let i = 0;
                                        i < values.Prospects.length;
                                        i++
                                      ) {
                                        if (i !== index) {
                                          if (
                                            values.Prospects[i].community_id ===
                                            community.id
                                          ) {
                                            communityAlreadySelected = true;
                                            break;
                                          }
                                        }
                                      }
                                      return community.is_active ? (
                                        <option
                                          key={communityIndex}
                                          value={community.id}
                                          disabled={communityAlreadySelected}
                                          className={
                                            communityAlreadySelected
                                              ? "my-options"
                                              : ""
                                          }
                                        >
                                          {community.name}
                                        </option>
                                      ) : null;
                                    }
                                  )}
                              </Field>
                            );
                          }}
                        </DefaultQuery>
                      </div>
                      <div className="col-md-6">
                        <DefaultQuery
                          query={GET_CSM}
                          variables={{
                            community_ids: pr.community_id
                              ? [pr.community_id]
                              : null,
                          }}
                          fetchPolicy="network-only"
                        >
                          {({ data, loading, error }) => {
                            let defaultValue = loading
                              ? "Loading ..."
                              : error
                              ? error.message
                              : "Select...";

                            return (
                              <Field
                                name={`Prospects[${index}].csm_id`}
                                label="CSM"
                                id="csm"
                                disabled={
                                  values.Prospects[index].community_id
                                    ? false
                                    : true
                                }
                                component={SelectField}
                              >
                                <option value={null}>{defaultValue}</option>
                                {data && isNotNULL(data.getAllCSMs)
                                  ? data.getAllCSMs.map((csm, csmIndex) => (
                                      <option key={csmIndex} value={csm.id}>
                                        {csm.first_name} {csm.last_name}
                                      </option>
                                    ))
                                  : null}
                              </Field>
                            );
                          }}
                        </DefaultQuery>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 pr-md-0">
                        <DefaultQuery
                          query={
                            pr.community_id
                              ? GET_AVAILABLE_HOMESITES
                              : GET_HOMESITES
                          }
                          variables={{ community_ids: pr.community_id }}
                          fetchPolicy="network-only"
                        >
                          {({ data }) => {
                            let fetchType = pr.community_id
                              ? "getAvailableHome_Sites"
                              : "getAllHome_Sites";

                            return (
                              <Field
                                label="Home Sites"
                                name={`Prospects[${index}].home_sites`}
                                id="home-sites"
                                disabled={
                                  values.Prospects[index].community_id
                                    ? false
                                    : true
                                }
                                component={MultiSelectField}
                                children={data[fetchType]}
                                defaultValue={
                                  values.Prospects[index].home_sites || null
                                }
                              />
                            );
                          }}
                        </DefaultQuery>
                      </div>
                      <div className="col-md-3 pl-md-2 pr-md-0">
                        <DefaultQuery
                          query={GET_AVAILABLE_FLOORPLANS}
                          variables={{
                            community_ids: isNotNULL(pr.community_id)
                              ? pr.community_id
                              : [],
                          }}
                          fetchPolicy="network-only"
                        >
                          {({ data }) => {
                            let fetchType = "getAllFloor_Plans";
                            return (
                              <Field
                                label="Floor Plans"
                                name={`Prospects[${index}].floor_plans`}
                                id="floor-plans"
                                component={MultiSelectField}
                                children={data[fetchType]}
                                defaultValue={
                                  values.Prospects[index].floor_plans || null
                                }
                              />
                            );
                          }}
                        </DefaultQuery>
                      </div>
                      <div className="col-md-3 pr-md-0 pl-md-2">
                        <DefaultQuery
                          query={
                            pr.community_id
                              ? GET_AVAILABLE_OTHERS
                              : GET_OTHERVALUES
                          }
                          variables={{ community_ids: pr.community_id }}
                          fetchPolicy="network-only"
                        >
                          {({ data, loading, error }) => {
                            let fetchType = pr.community_id
                              ? "getAvailableOther_Dropdown_Values"
                              : "getAllOther_Dropdown_Values";
                            return (
                              <Field
                                disabled={
                                  values.Prospects[index].community_id
                                    ? false
                                    : true
                                }
                                label="Others"
                                name={`Prospects[${index}].other_dropdown_values`}
                                id="others"
                                component={MultiSelectField}
                                children={data[fetchType]}
                                defaultValue={
                                  values.Prospects[index]
                                    .other_dropdown_values || null
                                }
                              />
                            );
                          }}
                        </DefaultQuery>
                      </div>
                      <div className="col-md-3 pl-md-2">
                        <Field
                          name={`Prospects[${index}].rank_id`}
                          label="Ranking"
                          id="ranking"
                          component={SelectField}
                        >
                          <option value={null}>Select...</option>

                          {props.getAllRankings.getAllRankings &&
                            props.getAllRankings.getAllRankings.map(
                              (ranking, index) => (
                                <option key={index} value={ranking.id}>
                                  {ranking.name}
                                </option>
                              )
                            )}
                        </Field>
                      </div>
                    </div>
                  </div>
                ))}
              <div className="form-addCommunity mb-3">
                <CustomButton
                  color="black"
                  disabled={
                    dropdown_communities &&
                    props.values.Prospects &&
                    props.values.Prospects.length ===
                      dropdown_communities.length
                  }
                  className={"form_addCommunityButton"}
                  onClick={() => {
                    arrayHelpers.push(addCommunityForm.Prospects[0]);
                  }}
                >
                  Add Community
                </CustomButton>
              </div>
            </div>
          )}
        />
      </div>
      <ErrorFocus />
    </Form>
  );
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: () => {
    return {...addCommunityForm}
  },
  handleSubmit: (values, { props, setSubmitting }) => {
    props
      .addProspectToExistingCustomer({
        variables: {
          input: {
              customer_id: props.prospectData.customer_id,
              Prospects: values.Prospects
          },
        },
      })
      .then((res) => {
        setSubmitting(false);
        if (res.data.addProspectsToExistingCustomer.code === 200) {
          props.onSubmit();
          props.setNotificationProps({
            variables: {
              open: true,
              message: res.data.addProspectsToExistingCustomer.message,
            },
          });
        }
      });
  },
  validationSchema: addCommunitySchema,
  displayName: "add-community-form",
})(AddCommunityForm);
