import React from "react";
import { AtomicBlockUtils, EditorState, RichUtils } from "draft-js";
import { OrderedSet } from "immutable";
import { ModifierTextUtils } from "./editor-helpers";
import { addHttpToUrl } from "../../utils/helpers";

/**
 * this function returns anchor tag with hyperlink of specified url the entity key
 * @param {object} props component props
 */
export const Link = props => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return <a href={url}>{props.children}</a>;
};

/**
 * this function returns img tag with the src of specified url the entity key
 * @param {object} props component props
 */
export const Image = (props) => {
  const { src, height, width } = props.contentState.getEntity(props.entityKey).getData();
  return <img src={src} height={height} width={width} alt="Pasted" />;
};

/**
 * this function is used to render an atomic type blocks component
 * @param {object} props 
 * @param {object} contentState contains all the information related to the state and the blocks
 * @param {object} block contains information of block type 
 */
export const Media = props => {
  const entity = props.block.getEntityAt(0)
    && props.contentState.getEntity(props.block.getEntityAt(0));
  if (!entity) return <></>;
  const { src, height, width } = entity.getData();
  const type = entity.getType();

  let media;
  if (type === "IMAGE" || type === "image") {
    media = <img src={src} height={height} width={width} alt="" />;
  }

  return media;
};

export const toggleBlockType = (blockType, editorState, callback) => {
  callback(RichUtils.toggleBlockType(editorState, blockType));
};

/**
 * this function is used to add a placeholder
 * @param {string} text text to be displayed 
 * @param {object} editorState 
 * @param {function} callback 
 */
export const togglePlaceholderInsert = (text, editorState, callback) => {
  const contentState = editorState.getCurrentContent();
  const selectState = editorState.getSelection();

  const spaceState = ModifierTextUtils.replaceText(
    contentState,
    selectState,
    "  "
  );

  const newEditorStateWithSpace = EditorState.set(editorState, {
    currentContent: spaceState
  });

  const nextOffSet = selectState.getFocusOffset() + 1
  const newSelection = selectState.merge({
    focusOffset: nextOffSet,
    anchorOffset: nextOffSet
  });

  const editorWithNewSelection = EditorState.acceptSelection(newEditorStateWithSpace, newSelection)

  const state = ModifierTextUtils.replaceText(
    editorWithNewSelection.getCurrentContent(),
    editorWithNewSelection.getSelection(),
    `{${text}}`,
    OrderedSet.of("CODE")
  );

  const newEditorState = EditorState.set(editorState, {
    currentContent: state
  });
  callback(EditorState.forceSelection(newEditorState, newSelection.merge({
    focusOffset: nextOffSet + text.length + 3,
    anchorOffset: nextOffSet + text.length + 3
  })));
};

export const toggleInlineStyle = (inlineStyle, editorState, callback) => {
  callback(RichUtils.toggleInlineStyle(editorState, inlineStyle));
};


//adding an image
/**
 * this function is used for adding an image
 * @param {object} e e
 * @param {*} value 
 * @param {*} type 
 * @param {*} height 
 * @param {*} width 
 * @param {*} editorState 
 * @param {*} callback 
 */
export const _confirmMedia = (
  e,
  value,
  type,
  height,
  width,
  editorState,
  callback
) => {
  e.preventDefault();

  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(type, "IMMUTABLE", {
    src: value,
    height: height,
    width: width
  });
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newEditorState = EditorState.set(editorState, {
    currentContent: contentStateWithEntity
  });

  callback(AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " "));
};

/**
 * this making the text as link
 * @param {object} e event that contains all the necessary imformations
 * @param {string} value url value
 * @param {object} type type of the selected section
 * @param {object} editorState 
 * @param {function} callback 
 */
export const _confirmLink = (e, value, type, editorState, callback) => {
  // const selection = editorState.getSelection();
  /*  let url = "";
  if (!selection.isCollapsed()) {
    const contentState = editorState.getCurrentContent();
    const startKey = editorState.getSelection().getStartKey();
    const startOffset = editorState.getSelection().getStartOffset();
    const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
    const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

    if (linkKey) {
      const linkInstance = contentState.getEntity(linkKey);
      url = linkInstance.getData().url;
    }
  } */

  const contentState = editorState.getCurrentContent();
  const urlvalue = addHttpToUrl(value)
  const contentStateWithEntity = contentState.createEntity(type, "MUTABLE", {
    url: urlvalue
  });
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newEditorState = EditorState.set(editorState, {
    currentContent: contentStateWithEntity
  });

  callback(
    RichUtils.toggleLink(
      newEditorState,
      newEditorState.getSelection(),
      entityKey
    )
  );
};

/**
 * this function removes the hyprelink from text
 * @param {object} e event that contains all the necessary imformations
 * @param {object} editorState 
 * @param {function} callback 
 */
export const _removeLink = (e, editorState, callback) => {
  e.preventDefault();
  const selection = editorState.getSelection();
  if (!selection.isCollapsed()) {
    callback(RichUtils.toggleLink(editorState, selection, null));
  }
};
