import gql from "graphql-tag";

const SELECT_DIVISION = gql`
  mutation selectDivision($id: Int!, $name: String!) {
    selectDivision(id: $id, name: $name) @client {
      id
      name
    }
  }
`;

export default SELECT_DIVISION;
