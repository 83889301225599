import gql from 'graphql-tag';

const DELETE_HOMEOWNER_NOTE = gql` 
  mutation deleteHomeownerNote ($input: HomeownerNoteDeleteInput) {
    deleteHomeownerNote (input: $input) {
      message
      code
    }
  }
`;

export default DELETE_HOMEOWNER_NOTE;