import gql from "graphql-tag";

const GET_REALTOR_SUBSCRIPTION_HISTORY = gql`
  query getRealtorSubscriptionHistoryById(
    $realtor_id: Int
  ) {
    getRealtorSubscriptionHistoryById(
        realtor_id: $realtor_id
    ) {
        realtor_subscription_history{
            id,
            email,
            action,
            title,
            created_at
          }
    }
  }
`;
export default GET_REALTOR_SUBSCRIPTION_HISTORY;
