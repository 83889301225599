import React, { useEffect, useMemo, useContext } from "react";
import TableView, {
  SortableHeader,
  stringComparator,
  TextHeader,
  TextCellWithMapper,
  dateComparator,
  DetailLinkCell,
  PhoneCell,
  ActionCell, EmailCell,
  TextCell
} from "../../components/new-table";

import routeConstants from "../layout/routes";
import { formatActivityDate, getCardParams, getUpdatedParams, phoneDisplay, isBuyerTwo } from "../../utils/helpers";
import Dialog from "../../components/dialog/dialog";
import PermanentDeleteConfirmation from '../../components/dialog/permanent-delete'
import FadeIn from "../../components/fade-in";
import CustomLoader from "../../components/custom-loader";
import reducer, { getInitialState } from "./archive-users-reducer";
import { FaTrashAlt } from "react-icons/fa";
import { Auth } from "../../rbac/rbac";

/** @module ArchiveUsersTableModule */

/**
 * This component is a table used to display all the archived user. It contains pagination, searchbar, and export
 * to csv options. It has role-based hover-actions, only manager and div manager can view delete hover-action. It 
 * shows four columns for displaying user's basic information. Table uses auth context to access current role, and 
 * uses reducer to maintain its state. It also accesses store to check if table parameters are already stored or not.
 * 
 * @param {Object} props it contains all the mutations/queries in the conatiner as props
 * @param {Object} props.data it contains array of agencies to be shown on table
 * @param {Object} props.getTableParams it contains cards info stored inside cache
 * @returns {JSX.Element} It returns jsx containing the table, dialog box for delete confirmation 
 */
const ArchiveUsersTable = props => {
  const { is_manager, is_divmgr } = useContext(Auth);

  const {
    data,
    getTableParams: { tableParams }
  } = props;

  let cacheParams = getCardParams('Archived Users', tableParams);
  const [state, dispatch] = React.useReducer(
    reducer,
    getInitialState({ loading: data.loading, cache: cacheParams })
  );

  const {
    render,
    userPageCount,
    pageSize,
    archivedUsers,
    loading,
    showPermanentDeletConfirmation,
    deleteCustomerId,
    isPermDeleteSubmitted
  } = state;

  /**
   * Either set the data in state or set the loading to inform the table what to render.
   */
  useEffect(() => {
    if (!data.loading) {
      dispatch({
        type: "UPDATE",
        payload: {
          render: true,
          archivedUsers: data.getArchived_Customer.ArchivedCustomers,
          loading: false,
          userPageCount: Math.ceil(
            data.getArchived_Customer.total_count / pageSize
          )
        }
      });
    }
    if (data.loading) {
      dispatch({
        type: "UPDATE",
        payload: { loading: data.loading }
      });
    }
  }, [data]);

  /**
   * Columns of the table are memoized and are created one time only. It has four columns displaying basic information
   * of the archived users
   * @constant
   * @memberof module:ArchiveUsersTableModule
   */
  const columns = useMemo(
    () => [
      {
        id: "1",
        header: "Name",
        accessor: "first_name_1",
        component: SortableHeader,
        comparator: stringComparator,
        csvMapper: (field, accessor) =>
          field[accessor] ? field[accessor] + " " + field["last_name_1"] : "",
        cell: {
          component: DetailLinkCell,
          path: (field, accessor) => routeConstants.ARCHIVED_DETAILS,
          mapper: (field, accessor) =>
            field[accessor] + " " + field["last_name_1"]
        }
      },
      {
        id: "2",
        header: "Email",
        accessor: "primary_email_1",
        component: SortableHeader,
        comparator: stringComparator,
        cell: {
          component: EmailCell,
          check: 'primary_email_1_status'
        }
      },
      {
        id: "3",
        header: "Mailchimp Subscription",
        accessor: "primary_email_1_status",
        hidden: true,
        csvMapper: (field, accessor) => field[accessor] ? "Subscribed" : "Unsubscribed",
        cell: { component: null },
      },
      {
        id: "4",
        header: "Phone",
        accessor: "cell_phone_1",
        component: TextHeader,
        sortable: false,
        csvMapper: (field, accessor) => phoneDisplay(field[accessor]) || "",
        cell: { component: PhoneCell, clickable: false }
      },
      {
        id: "5",
        header: "First Name 2",
        accessor: "first_name_2",
        hidden: true,
        cell: { component: null },
      },
      {
        id: "6",
        header: "Last Name 2",
        accessor: "last_name_2",
        hidden: true,
        cell: { component: null },
      },
      {
        id: "7",
        header: "Primary Email 2",
        accessor: "primary_email_2",
        hidden: true,
        cell: { component: null },
      },
      {
        id: "8",
        header: "Mailchimp 2 Subscription",
        accessor: "primary_email_2_status",
        hidden: true,
        csvMapper: (field, accessor) => isBuyerTwo(field) ? field[accessor] ? "Subscribed" : "Unsubscribed" : "",
        cell: { component: null },
      },
      {
        id: "9",
        header: "Phone 2",
        accessor: "cell_phone_2",
        hidden: true,
        csvMapper: (field, accessor) => phoneDisplay(field[accessor]) || "",
        cell: { component: null },
      },
      {
        id: "10",
        header: "Last Activity Date",
        accessor: "archived_at",
        component: SortableHeader,
        comparator: dateComparator,
        csvMapper: (field, accessor) => formatActivityDate(field[accessor]),
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) => formatActivityDate(field[accessor])
        }
      },

    ],
    []
  );

  /**
   * It is a callback function which is called on any table change like: pagination, page-size, filters, search term.
   * Table passes its current state so that we can update the table parameters in store and state, which are used in 
   * refetching the data with correct parameters.  
   *  
   * @param {Object} tableProps current state of the table
   * @function
   * @inner
   * @memberof module:ArchiveUsersTableModule
   * @see {@link module:ArchiveUsersTableModule~ArchiveUsersTable}
   */
  const fetchData = React.useCallback(tableProps => {
    if (loading) {
      return;
    }

    //variables to be stored in cache/store
    const variables = {
      limit: tableProps.pageSize,
      pageNum: tableProps.currentPage,
      search: tableProps.searchTerm,
      order: {
        id: tableProps.sortColumn.id,
        name: tableProps.sortColumn.name,
        sort: tableProps.sortColumn.sort
      }
    };

    // if search term or filter is changed, bring the page to 0
    if (tableProps.searchTerm !== state.search) {
      variables.pageNum = 0;
    }

    // update the state
    dispatch({ type: "UPDATE", payload: { ...variables, pageSize: tableProps.pageSize, loading: true } });

    const pageCount = Math.ceil(
      data.getArchived_Customer.total_count / tableProps.pageSize
    );

    // update total page count in the state
    if (pageCount !== userPageCount) {
      dispatch({
        type: "UPDATE",
        payload: { userPageCount: pageCount }
      });
    }

    // update the store
    props
      .setTableParams({
        variables: {
          cardsParams: getUpdatedParams(tableParams.cardsParams, {
            cardTitle: "Archived Users",
            params: variables
          })
        }
      })
      .then(res => {
        dispatch({ type: "UPDATE", payload: { loading: false } });
      });
  });

  /**
   * It is used to set delete id and show hide the dialog box. 
   * @param {Number} id id of user to be deleted
   * @function
   * @inner
   * @memberof module:ArchiveUsersTableModule
   * @see {@link module:ArchiveUsersTableModule~ArchiveUsersTable}
   */
  const handleDelete = (id) => {
    dispatch({
      type: "UPDATE",
      payload: { showPermanentDeletConfirmation: true, deleteCustomerId: id }
    });
  };

  /**
   * It is used to toggle permanent delete dialog box
   * @function
   * @inner
   * @memberof module:ArchiveUsersTableModule
   * @see {@link module:ArchiveUsersTableModule~ArchiveUsersTable}
   */
  const handlePermanentDeleteConfirmation = () => {
    dispatch({ type: "UPDATE", payload: { isPermDeleteSubmitted: !isPermDeleteSubmitted } })
  }

  /**
   * On click of permanent delete confirmation, this function is called. It refetches the table data and shows 
   * notification containing the message from the backend on deletion. 
   * @param {String} message
   * @function
   * @inner
   * @memberof module:ArchiveUsersTableModule
   * @see {@link module:ArchiveUsersTableModule~ArchiveUsersTable}
   */
  const onPermanentDeletionSuccess = (message) => {
    dispatch({ type: "UPDATE", payload: { loading: true, showPermanentDeletConfirmation: false } });
    data.refetch();
    props.setNotificationProps({
      variables: {
        open: true,
        message: message
      }
    });
  }

  const reConfirmationBody = (deleteCustomerId, isPermDeleteSubmitted, onPermanentDeletionSuccess) => {
    return deleteCustomerId && <PermanentDeleteConfirmation customerId={deleteCustomerId} isSubmitted={isPermDeleteSubmitted} onSubmit={onPermanentDeletionSuccess} />
  };

  /**
   * Array of objects containing different actions which can be performed on row hover. On this table only delete action
   * can be performed, that too by manager and division manager only
   * @constant
   * @memberof module:ArchiveUsersTableModule
   */
  const hoverActions = React.useMemo(
    () => [
      {
        tooltip: "Delete",
        icon: FaTrashAlt,
        component: ActionCell,
        action: ({ id }) => {
          handleDelete(id);
        }
      }
    ],
    []
  );

  const tableOptions = {
    title: ""
  };

  return (
    <div>
      <Dialog
        show={showPermanentDeletConfirmation}
        onHide={() =>
          dispatch({
            type: "UPDATE",
            payload: { showPermanentDeletConfirmation: false }
          })
        }
        title="Re-Confirmation"
        body={reConfirmationBody(deleteCustomerId, isPermDeleteSubmitted, onPermanentDeletionSuccess)}
        click={handlePermanentDeleteConfirmation}
        clickname="Permanently Delete"
        closename="NO"
      />

      <FadeIn show={render}>
        <div className="table-archive-user">
          <TableView
            columns={columns}
            data={archivedUsers}
            tableOptions={tableOptions}
            hoverActions={(is_manager() || is_divmgr()) ? hoverActions : null}
            loading={loading}
            userPageCount={userPageCount}
            userTotalCount={
              !loading ? data.getArchived_Customer.total_count : null
            }
            fetchData={fetchData}
            currentPage={state.pageNum}
            pageSize={state.pageSize}
            searchTerm={state.search}
            sortColumn={state.order}
            controlled={true}
          />
        </div>
      </FadeIn>
      {!render && (
        <CustomLoader
          style={{ marginLeft: "auto", marginRight: "auto", zIndex: 9999 }}
        />
      )}
    </div>
  );
};
export default ArchiveUsersTable;
