import gql from 'graphql-tag';

const UPDATE_COMMUNITY_INFO = gql` 
  mutation updateCommunityInformation($input: CommunityInformationInput!) {
    updateCommunityInformation(input: $input) {
      message
      code
    }
  }
`;

export default UPDATE_COMMUNITY_INFO