import React, { useEffect, useMemo, useState } from "react";
import { compose, graphql } from "react-apollo";

import TableView, {
  ActionCell,
  buyerTwoIconCellObject,
  dateComparator,
  DetailLinkCell,
  PhoneCell,
  TextCell,
  TextCellWithMapper,
  TextHeader,
} from "../../components/new-table";
import FadeIn from "../../components/fade-in";
import { formatActivityDate, phoneDisplay } from "../../utils/helpers";
import routes_constants from "../layout/routes";
import { GET_ARCHIVED_USERS_BYID } from "../../graphql/queries";
import { FaUser } from "react-icons/fa";

/** @module GlobalArchiveUsersTableModule */
/**
 * This component is a table used to display archived users data based on the global search query. It contains only the top 5 results and further specific query needs to be made
 * to get the appropriate data. It also has a basic hover action on Name column which redirect to the archived user's details.
 *
 * @param {Object} props this contains the data from the graphql query and the function to close the drawer
 * @param {String} props.searchTerm it contains the search query from the GlobalSearchDetails component
 * @param {Function} props.handleDrawerClose function to close the drawer
 * @param {Object} props.data it contains array of prospects to be shown on table
 * @returns {JSX.Element} It returns jsx containing the table title and the table itself
 */
const GlobalArchiveUsersTable = ({ handleDrawerClose, data, setMergeData }) => {
  const [loading, setLoading] = useState(true);
  const [render, setRender] = useState(false);
  const [ArchivedCustomers, setArchivedCustomers] = useState({});

  /**
   * Either set the data in state or set the loading to inform the table what to render.
   */
  useEffect(() => {
    if (
      !data.loading &&
      data.getArchived_Customer &&
      data.getArchived_Customer.ArchivedCustomers
    ) {
      setLoading(false);
      setRender(true);
      setArchivedCustomers(data.getArchived_Customer.ArchivedCustomers);
    }
    if (data.loading) {
      setLoading(data.loading);
    }
  }, [data]);

  /**
   * This function adds customers to the merge customer list on clicking the merge button. If the customer selected is already
   * in the merge customers list then the customer is not added a second time.
   * @param {Object} archived archived users data which is to be added into the merged customer list
   */
  const updateMergeList = (archived) => {
    setMergeData(prevState => {
      const data = prevState.type === "realtor" ? [archived]
        : prevState.data?.find(customer => customer.id === archived.id)
          ? prevState.data
          : [...prevState.data, archived]
      return {
        data,
        type: "customer"
      }
    })
  }

  /**
   * Columns of the table containing a column to show an icon in case there is a second buyer, name, archived_at date, phone and email.
   * @constant
   * @memberof module:GlobalArchiveUsersTableModule
   */
  const columns = useMemo(() => [
    {
      id: "0",
      header: "",
      accessor: "",
      component: TextHeader,
      cell: buyerTwoIconCellObject,
    },
    {
      id: "1",
      header: "Name",
      accessor: "first_name_1",
      component: TextHeader,
      sortable: false,
      cell: {
        component: DetailLinkCell,
        path: (field, accessor) => routes_constants.ARCHIVED_DETAILS,
        mapper: (field, accessor) =>
          field[accessor] + " " + field["last_name_1"],
        action: handleDrawerClose,
      },
    },
    {
      id: "2",
      header: "Archived Date",
      accessor: "archived_at",
      component: TextHeader,
      sortable: false,
      comparator: dateComparator,
      csvMapper: (field, accessor) => formatActivityDate(field[accessor]),
      cell: {
        component: TextCellWithMapper,
        mapper: (field, accessor) => formatActivityDate(field[accessor]),
      },
    },
    {
      id: "3",
      header: "Phone",
      accessor: "cell_phone_1",
      component: TextHeader,
      csvMapper: (field, accessor) => phoneDisplay(field[accessor]) || "",
      sortable: false,
      cell: { component: PhoneCell, clickable: false },
    },
    {
      id: "4",
      header: "Email",
      accessor: "primary_email_1",
      component: TextHeader,
      sortable: false,
      cell: {
        component: TextCell,
      },
    },
  ]);

  /**
 * Array of objects containing different actions which can be performed on row hover.
 * @constant
 * @memberof module:GlobalArchiveUsersTable
 */
  const hoverActions = React.useMemo(
    () => [
      {
        textValue: 'Merge',
        component: ActionCell,
        action: (event) => {
          updateMergeList(event);
        },
      }
    ],
    []
  );

  return (
    <div>
      <div className="d-flex header-table">
        <FaUser className="header-icon" />
        <div className="d-flex align-self-center header-text">
          Archived {!ArchivedCustomers.length && "(No records Found)"}
        </div>
      </div>
      {ArchivedCustomers.length ? (
        <div className="results-table-search">
          <FadeIn show={render}>
            <TableView
              columns={columns}
              data={ArchivedCustomers}
              loading={loading}
              currentPage={0}
              hoverActions={hoverActions}
              isGlobalSearch={true}
              controlled={true}
            />
          </FadeIn>
        </div>
      ) : null}
    </div>
  );
};

export default compose(
  graphql(GET_ARCHIVED_USERS_BYID, {
    options: ({ searchTerm }) => {
      return {
        variables: {
          pageNum: 0,
          limit: 5,
          sort: null,
          search: searchTerm || "",
        },
        fetchPolicy: "cache-and-network",
      };
    },
  })
)(GlobalArchiveUsersTable);
