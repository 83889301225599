import { graphql, compose } from "react-apollo";
import ScheduleAppointmentForm from "./lead-schedule-appointment-form";
import withUserContext from '../../../hoc/with-user-context'
import { GET_CSM, GET_LOGGED_IN_COMMUNITIES, GET_APPOINTMENT_TYPES } from '../../../graphql/queries'
import { SCHEDULE_AND_CONVERT_LEAD } from '../../../graphql/mutations'

const ScheduleAppointmentMutation = compose(
  graphql(GET_CSM, { name: "getAllCSMs" }),
  graphql(SCHEDULE_AND_CONVERT_LEAD, { name: "scheduleAndConvertLead" }),
  graphql(GET_LOGGED_IN_COMMUNITIES, { name: "getLoggedInUserCommunity" }),
  graphql(GET_APPOINTMENT_TYPES, { name: "getAllProspectAppointmentTypes" }),
)(ScheduleAppointmentForm);

export default withUserContext(ScheduleAppointmentMutation);
