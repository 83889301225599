import React from "react";
import Loader from "react-loader-spinner";
import PropTpes from "prop-types"

/**
 * This component renders centered loader from react-loader-spinner. It can have custom type, color and size etc.
 * @param {Object} props
 * @param {String} props.type type of loader, which is default to "ThreeDots"
 * @param {String} props.color user can pass any custom color
 * @param {String} height
 * @param {String} width
 */
class CustomLoader extends React.Component {
  render() {
    return (
      <div className='d-flex justify-content-center loader-gif'>
        <Loader
          type={this.props.type}
          color={this.props.color}
          height={this.props.height}
          width={this.props.width}
        />
      </div>
    );
  }
}

CustomLoader.defaultProps = {
  type: "ThreeDots",
  color: "#ddd",
};
CustomLoader.propTypes = {
  style:PropTpes.object
};

export default CustomLoader;
