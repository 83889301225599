
import gql from 'graphql-tag';

const ADD_REALTOR_NOTE = gql` 
  mutation addRealtorNote($input: RealtorNoteInput) {
    addRealtorNote(input: $input) {
      message
      code
      data
    }
  }
`;

export default ADD_REALTOR_NOTE;