import * as React from "react";

/**
 * This component is used in {@link ActivityCard} to display count, date, percentage and title on the card
 * @param {Object} props Component props
 * @param {Number} props.count Count to be shown on card
 * @param {Number} props.secondCount Second count to be shown on card
 * @param {String} props.counter Card title
 * @param {String} props.date Date to be shown on card below the count
 * @param {Number} props.percentValue Percentage to be shown right next to card title
 */
const Counter = ({ counter, count, date, percentValue, secondCount }) => (
  <div
    xs={1}
    className="d-flex flex-column align-items-center card-inner"
    id="count-cont"
  >
    <h1 className="count center m-0" id="count">
      {secondCount === null || secondCount === undefined ? count : `${count}/${secondCount}`}
    </h1>
    <div className="counter" id="counter">
      {counter}
      {percentValue ? ` (${percentValue}%)` : null}
    </div>
    <div id="counter-date" className={date ? "date-activity" : "d-none"}>
      {date ? date : "Date is Missing"}
    </div>
  </div>
);

export default Counter;
