import gql from 'graphql-tag';

const GET_LEAD_FILTER_BYID = gql`
query getLeadFilters($input: Int){
    getLeadFilter(id:$input){
      id
      filter_name
      created_from
      created_to
      last_activity_from
      last_activity_to
      phone
      note
      State{
        id
        name
      }
      Customer_Origination_Area{
        id
        name
      }
    }
  }
 `;

export default GET_LEAD_FILTER_BYID;