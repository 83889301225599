
import gql from 'graphql-tag';

const DELETE_AGENCY = gql` 
  mutation deleteAgency($id: ID!) {
    deleteAgency(id: $id) {
      message
      code
    }
  }
`;

export default DELETE_AGENCY;