import React, { useState, useEffect } from "react";
import { useMutation } from "react-apollo-hooks";
import Notification from "./notification";
import { FormCheck } from "react-bootstrap";
import { SUB_UNREAD_COUNT } from "../../graphql/subscriptions";
import { MARK_NOTIFICATION_READ } from "../../graphql/mutations";
import { NOTIFICATION_FETCH_LIMIT } from "../../utils/constants";
import { MdClose } from "react-icons/md";
import CustomButton from "../../components/custom-button";

/**
 * This component is responsible for rendering the notification drawer
 * 
 * @param {Object}
 * @param {Object} props.data notification data
 * @param {Object} props.onLoadMore function called upon scrolling to fetch more notifications
 */
const NotificationBar = (props) => {
  const { data: getNotifications, onLoadMore, refetch, subscribeToMore } = props
  const [isCountFirstLoad, setIsCountFirstLoad] = useState(true)
  const [markNotificationsAsRead] = useMutation(MARK_NOTIFICATION_READ)
  const { showUnread, setShowUnread } = props

  const handleClick = () => setShowUnread(!showUnread)

  const handleScroll = ({ currentTarget }, onLoadMore) => {
    if (
      currentTarget.scrollTop + currentTarget.clientHeight >= currentTarget.scrollHeight &&
      getNotifications.Notifications?.length !== getNotifications.total_count
    ) {
      onLoadMore()
    }
  }

  useEffect(() => {
    if (isCountFirstLoad) {
      subscribeToMore({
        document: SUB_UNREAD_COUNT,
        fetchPolicy: "cache-and-network",
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData) return prev;
          refetch({
            filter: { is_unread: showUnread },
            limit: NOTIFICATION_FETCH_LIMIT
          })
          return prev
        }
      });
      setIsCountFirstLoad(false)
    }
  }, [getNotifications])

  const handleMarkAllNotifications = async () => await markNotificationsAsRead()

  return (
    <div
      className="notification-bar-container"
      onScroll={e => handleScroll(e, onLoadMore)}
    >
      <div className="notification-bar-header">
        <div className="d-flex w-100 align-items-center justify-content-between">
          <div className="d-flex flex-column">
            <h2>Notifications</h2>
            <div className="d-flex align-items-center mb-2">
              <div className="notification-bar-subtext">Only show unread</div>
              <FormCheck
                id="unread_switch"
                type="switch"
                onClick={handleClick}
              />
            </div>
          </div>
          <CustomButton btnValue="Mark all as Read" size="sm" onClick={handleMarkAllNotifications} />
          <MdClose
            className="notification-close-btn"
            onClick={props.close}
          />
        </div>
      </div>
      {
        getNotifications.Notifications?.length ?
          getNotifications.Notifications.map((noti, idx) =>
            <Notification
              key={idx}
              close={props.close}
              {...noti}
            />
          )
          : (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '80%' }}>
              <span> You have no notifications! </span>
            </div>
          )
      }
    </div>
  )
}

export default NotificationBar