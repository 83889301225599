import gql from "graphql-tag";

export const ADD_OTHER_STATUS = gql`
  mutation addOtherStatus($name: String!) {
    addStatus(name: $name) {
      code
      success
      message
    }
  }
`;

export const UPDATE_OTHER_STATUS = gql`
  mutation updateOtherStatus($id: Int!, $name: String!) {
    updateStatus(id: $id, name: $name) {
      code
      success
      message
    }
  }
`;

export const DELETE_OTHER_STATUS = gql`
  mutation deleteOtherStatus($id: Int!) {
    deleteStatus(id: $id) {
      code
      success
      message
    }
  }
`;
