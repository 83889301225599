import React, { Fragment } from 'react';
import { Container } from 'react-bootstrap';

/**
 * This component is used to wrap the whole page inside a container and render its child
 * components inside container.
 */
export default class PageContainer extends React.Component {
  render() {
    const { children, ...rest } = this.props
    return (
      <Fragment>
        <Container fluid {...rest}>
          {children}
        </Container>
      </Fragment>
    );
  }
}
