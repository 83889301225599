import React from "react";
import TableCell from "./table-cell";

/**
 * This component is used in {@link TableBody} to display the row of the body
 */
class TableRow extends React.Component {
/**
 * @param {object} rest component props
 * @param {HTMLBodyElement} tableRowData Rows of the body
 */
  render() {
    const { tableRowData, ...rest } = this.props;

    return (
      <tr {...rest} className="table-row">
        {tableRowData.map((prop, key) => {
          return (
            <TableCell tableCellData={prop} key={key} />
          );
        })}
      </tr>
    );

  }
}


export default TableRow;
