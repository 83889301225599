
import gql from 'graphql-tag';

const MARK_PROSPECT_APPOINTMENT_DONE = gql` 
mutation markDoneProspectAppointment($input: ProspectAppointmentMarkDoneInput!) {
    markDoneProspectAppointment(input: $input){
    message
    code
    }
}
`;

export default MARK_PROSPECT_APPOINTMENT_DONE;
