import React from 'react';
import './custom-backdrop.css';

/**
 * This component renders a faded backdrop and makes background disabled
 * @param {Object} props component props
 * @param {Function} props.onClick callback function that is called on clicking the backdrop
 */
const backdrop = props => (
    <div className="backdrop" onClick={props.onClick}></div>
);
export default (backdrop)