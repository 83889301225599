import gql from "graphql-tag";

const GET_APPOINTMENT_TYPES = gql`
  query {
    getAllProspectAppointmentTypes {
      id
      name
    }
  }
`;
export default GET_APPOINTMENT_TYPES;
