import React from 'react';
import { connect } from 'formik';
import {isObject, isNotNULL} from '../../utils/helpers'

/**
 * This component renders nothing, but focuses on first error field in forms. It receives an errors object which can be have nested objects, arrays kind of format. It creates string of the first error path, and uses that string to select the fiels and focus on it. 
 * For example: 
 *      A sample of error object: {prop1: [{propA: "First error field message"}], prop2: "Second error field message" }
 *      Path created from this: "prop1[0].propA", this path is actually the name of the field containing error
 */
class ErrorFocus extends React.Component {

    isArray(value) {
      return value && value instanceof Array;
    }

    isBaseCondition = (object) => {
      if (typeof object === 'string' || !isNotNULL(object))
        return true
      return false
    }
    
    /**
     * This function recursively breaks the error object, and creates path to reach the first possible error.
     * @method
     * @param {*} object  error object, array, string
     */
    getKeysRecursively = (object) => {
      // object is null undefined or a string then return
        if (this.isBaseCondition(object)) {
            return ''
        }

        if (isObject(object)){
          // if an object is received, pick the first key, and check if its value is array then do not add a "." after key, 
          // else add a "." after the key
            const currentKey = Object.keys(object)[0]
            if(this.isArray(object[currentKey]))
                return currentKey + this.getKeysRecursively(object[currentKey])
            return currentKey + "." + this.getKeysRecursively(object[currentKey])
        }else {
          // "object" contains array, then find the first non null value in the array, and conctenate that index to the path string
            const errorIndex = object.findIndex(item => item)
            return '['+ errorIndex + '].' + this.getKeysRecursively(object[errorIndex])
        }
    }

    componentDidUpdate(prevProps) {
        const { isSubmitting, isValidating, errors } = prevProps.formik;
        const {names} = this.props
        const keys = Object.keys(errors);

        // errors object has errors, form is submitting, and validation process is completed
        if (keys.length > 0 && isSubmitting && !isValidating) {
          let dynamicErrors = Object.assign({}, errors)
          let path = this.getKeysRecursively(dynamicErrors)
          path = path.slice(0,-1) // to remove an extra dot at the end

          //some fields error names are different from their field names, so we match path with the errorName and extract field name of that respective field to focus on it
          if(isNotNULL(names)){
            for(let i=0; i<names.length; i++){
              if (names[i].errorName === path){
                path = names[i].fieldName
                break;
              }
            }
          }
          //this block of code selects the field by its name, and scrolls to that view and focuses on that particular field
          const selector = `[name="${path}"]`;
          const element = document.querySelector(selector);
          if(element){
            element.scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center'
            });
            element.focus();
          }
        }
    }

    render() {
        return null;
    }
}

export default connect(ErrorFocus);