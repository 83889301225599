import React, { useState, useEffect } from "react";
import { useMutation } from "react-apollo-hooks";
import { Card, Col } from "react-bootstrap";
import { FaBan, FaPen } from "react-icons/fa";
import Select from "../../../components/select";
import Dialog from "../../../components/dialog/dialog";
import DetailsSectionTable from "../../../components/table/custom-details-table";
import DetailsLastSectionTable from "../../../components/table/custom-details-table/details-last-section";
import HistoryButton from "../../common-lead-prospect-details/history-button";
import SubscriptionHistoryContainer from "../../common-lead-prospect-details/subscription-history-button"
import { DefaultQuery } from "../../../graphql/default-query";
import { ALL_REALTORS_NAMES } from "../../../graphql/queries";
import { UPDATE_HOMEOWNER_REALTOR } from "../../../graphql/mutations";
import { ClickableIcon } from "../../admin/email-associations";
import { phoneLink, formatDate, checkDisabledEmailsLink, handleSecondaryEmails, getAllEmails } from "../../../utils/helpers";

/**
 * This component is used to render card details section on he right side of the page. It divides the details card into
 * four sections. Buyer 1, buyer 2, general, and other optional fields. It also contains buttons to view history,
 * subscription history and edit realtor of the homeowner. 
 * @param {Object} props 
 * @param {Object} props.data homeowner info 
 * @param {Number} props.id id of the homeowner
 */
const HomeownerDetail = ({ ...props }) => {
  const [showSideBar, setShowSideBar] = useState(false);
  const [showBuyer2, setShowBuyer2] = useState(true);
  const [realtorID, setRealtorID] = useState(null);
  const [buyer1, setBuyer1] = useState([]);
  const [buyer2, setBuyer2] = useState([]);
  const [general, setGeneral] = useState([]);
  const [optional, setOptional] = useState([]);
  const [otherNotes, setOtherNotes] = useState([]);

  const [updateHomeownerRealtor] = useMutation(UPDATE_HOMEOWNER_REALTOR);

  const handleRealtor = (realtorData) => {
    realtorData && setRealtorID(realtorData.id)
    return (
      <div className="d-flex align-items-center">
        {realtorData ? `${realtorData.first_name} ${realtorData.last_name ?? ""}` : "-"}
        <ClickableIcon
          tooltip="Update Realtor"
          className="custom-add-btn"
          icon={FaPen}
          callback={() => {
            setShowSideBar(!showSideBar)
          }}
        />
      </div>
    )
  }

  const formatOptions = (list) => {
    let vals = [{ value: null, label: 'None' }]
    list && list.forEach(item => { vals.push({ value: item.id, label: `${item.first_name} ${item.last_name ?? ""}` }) })

    return vals
  }

  const onChangeHandler = (e) => {
    setRealtorID(e.value)
  }

  const onClickHandler = async (homeowner_id) => {
    updateHomeownerRealtor({
      variables: {
        input: {
          id: homeowner_id,
          realtor_id: realtorID
        }
      }
    }).then((res) => {
      setShowSideBar(false)
      if (res.data.updateHomeownerRealtor.code === 200)
        props.onSubmit(res.data.updateHomeownerRealtor.message)
    })
  }

  useEffect(() => {
    if (props.data) {
      setBuyer1({
        "First Name": props.data.first_name_1,
        "Last Name": props.data.last_name_1,
        "Primary Email": checkDisabledEmailsLink(props.data, "primary_email_1", getAllEmails, "primary_email_1_status"),
        "Secondary Email(s)": props.data.secondary_emails_1.length > 0 ? handleSecondaryEmails(
          props.data,
          "secondary_emails_1",
          getAllEmails
        ) : null,
        "Cell#": phoneLink(props.data, "cell_phone_1")
      });

      setBuyer2({
        "First Name": props.data.first_name_2,
        "Last Name": props.data.last_name_2,
        "Primary Email": checkDisabledEmailsLink(props.data, "primary_email_2", getAllEmails, "primary_email_2_status"),
        "Secondary Email(s)": props.data.secondary_emails_2.length > 0 ? handleSecondaryEmails(
          props.data,
          "secondary_emails_2",
          getAllEmails
        ) : null,
        "Cell#": phoneLink(props.data, "cell_phone_2")
      });

      setOptional({
        "Stage": props.data.stage?.name,
        "Community": props.data.community ?
          <div className='d-flex align-items-center'>
            {!props.data.community.is_active && <FaBan className='td-warning' />}
            {props.data.community.name}
          </div>
          : null,
        "Lot": props.data.lot,
        "Brand Ambassador": <input type="checkbox" checked={props.data.brand_ambassador} disabled />,
        "Purchased Date": props.data.purchased_date && formatDate(props.data.purchased_date),
        "Settled Date": props.data.settled_date && formatDate(props.data.settled_date)
      });

      setGeneral({
        Address: props.data.street_address,
        City: props.data.city,
        State: props.data.state ? props.data.state.name : null,
        Zip: props.data.zip,
        Realtor: handleRealtor(props.data.realtor),
        "Source": props.data.customer_origination_area
          ? props.data.customer_origination_area.name
          : null,
        "OSC Notes": props.data.osc_notes
      });

      setOtherNotes({
        "Motivation What Changed": props.data.motivation_what_changed,
        "Future Promises": props.data.future_promise,
        "Current Dissatisfaction": props.data.current_dissatisfaction,
        "Other Notes": props.data.other_notes
      })
    }
    if (
      props.data &&
      (props.data.first_name_2 ||
        props.data.last_name_2 ||
        props.data.primary_email_2 ||
        props.data.secondary_emails_2.length > 0 ||
        props.data.cell_phone_2)
    ) {
      setShowBuyer2(true);
    } else {
      setShowBuyer2(false);
    }
  }, [props.data]);

  return (
    <Card className="details-card pt-2" as={Col}>
      <Card.Body style={{ padding: "0.5rem", overflow: "hidden" }}>
        <div className="d-flex justify-content-between">
          <h3>Homeowner Details</h3>
          <div className="d-flex justify-content-end">
            <SubscriptionHistoryContainer
              name={
                props.data
                  ? `${props.data.first_name_1} ${props.data.last_name_1}`
                  : ""
              }
              customer_id={props.data ? props.data.customer_id : null}
              primaryEmail1={props.data ? props.data.primary_email_1 : null}
              primaryEmail2={props.data ? props.data.primary_email_2 : null}
            />
            <HistoryButton
              name={
                props.data
                  ? `${props.data.first_name_1} ${props.data.last_name_1}`
                  : ""
              }
              id={props.data ? props.data.customer_id : null}
            />
          </div>
          <Dialog
            title="Add/Edit Homeowner Realtor"
            onHide={() => setShowSideBar(false)}
            show={showSideBar}
            body={
              <DefaultQuery
                query={ALL_REALTORS_NAMES}
                fetchPolicy="network-only"
              >
                {({ data, loading, error }) => {
                  const { realtor } = props.data
                  const realtorOptions = formatOptions(data?.getRealtorsData)
                  return !error || !loading ? (
                    <div className="backdrop-dark">
                      <Select
                        options={realtorOptions}
                        defaultValue={realtor ? {
                          value: realtor.id,
                          label: `${realtor.first_name} ${realtor.last_name ?? ""}`
                        } : null}
                        placeholder="Select a Realtor"
                        onChange={onChangeHandler}
                        isSearchable={true}
                      />
                    </div>
                  ) : null
                }}
              </DefaultQuery>
            }
            clickname="Submit"
            click={() => onClickHandler(props.data.id)}
          />
        </div>
        <div className="details-side-responsive">
          <div className="details-side-section1">
            <DetailsSectionTable data={buyer1} tableHeader="Buyer#1" />
          </div>
          {showBuyer2 && <div className="details-side-section2">
            <DetailsSectionTable data={buyer2} tableHeader="Buyer#2" />
          </div>}
          <div className="details-side-section3">
            <DetailsSectionTable data={general} />
          </div>
          <div className="details-side-section3">
            <DetailsSectionTable
              data={optional}
              tableHeader="Other Fields"
            />
          </div>
          <div className="details-side-section2 mt-2">
            <DetailsLastSectionTable
              data={otherNotes}
              type={props.data?.__typename}
            />
          </div>
          <div className="details-side-section3">
            <p><strong>Note:</strong> To update a Homeowner, go to Heartbeat.</p>
          </div>

        </div>
      </Card.Body>
    </Card>
  );
};

export default HomeownerDetail