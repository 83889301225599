import PropTypes from "prop-types";
import React from "react";
import { Table } from 'react-bootstrap';
import './custom-table.css';
import TableBody from "./table-body";
import TableHead from "./table-header";


/**
 * This component displays the information in the activity cards
 */
class CustomTable extends React.Component {
/**
 * @param {Array} tablehead Header of the table
 * @param {HTMLBodyElement} tableData body of the table
 */
  render() {
    const { tableHead, tableData} = this.props;
    return (
      <div className='table-root table-activities'>
        <Table>
          {tableHead !== undefined ? (
            <TableHead tableHeaderData={tableHead} />
          ) : null}
          <TableBody tableBodyData={tableData} />
        </Table>
      </div>
    );
  }
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray"
};

CustomTable.propTypes = {
  tableHead: PropTypes.arrayOf(PropTypes.string),
};

export default CustomTable;
