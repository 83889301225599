import gql from "graphql-tag";

const GET_SELECTED_DIVISION = gql`
  query {
    selectedDivision @client {
      id
      name
    }
  }
`;

export default GET_SELECTED_DIVISION;
