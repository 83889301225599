import React, { useState, useEffect } from 'react';
import Drawer from "../../../hoc/Drawer";
import LeadToMarketingOnlyForm from '../forms/lead-to-marketing-only-container';
import LeadToProspectForm from '../forms/lead-to-prospect-container';
import { MARKETING_ONLY } from '../../../utils/constants';

/**
 * this component is used to render the lead to marketing only/prospect form drawer 
 * @param {*} param0 
 */
const LeadConvertView = (props) => {
  const { show } = props
  const [showSideBar, setShowSideBar] = useState(false)

  const toggleSideBar = () => {
    setShowSideBar(false)
    props.close()
  }

  const onSubmit = (message) => {
    setShowSideBar(false)
    props.onSubmit(message)
  }

  useEffect(() => {
    show && setShowSideBar(true)
  }, [show])

  return (
    <Drawer
      show={showSideBar}
      toggleSideBar={toggleSideBar}
    >
      {props.type === MARKETING_ONLY ?
        <LeadToMarketingOnlyForm
          close={toggleSideBar}
          leadData={props.leadData}
          leadId={props.id}
          onSubmit={onSubmit}
        /> :
        <LeadToProspectForm
          close={toggleSideBar}
          leadData={props.leadData}
          leadId={props.id}
          onSubmit={onSubmit}
        />
      }
    </Drawer>
  )
}

export default LeadConvertView;