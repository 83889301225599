const routes_constants = {
  LEAD_DASHBOARD: "/lead-dashboard",
  WEB_FORM: "/web-form",
  WEB_FORM_DETAIL: "/web-form/web-form-detail",
  WEB_FORM_OTHERS: "/web-form-others",

  MARKETING_ONLY_DASHBOARD: "/marketing-only-dashboard",
  MARKETING_ONLY_DETAIL: "/marketing-only-dashboard/marketing-only-detail",

  PROSPECT_DASHBOARD: "/prospect-dashboard",
  REALTOR_DASHBOARD: "/realtor-dashboard",
  REALTOR_REGISTRATION: "/realtor-registration-dashboard",
  CLIENT_REGISTRATION: "/client-registration-dashboard",
  AGENCIES_DASHBOARD: "/agencies-dashboard",
  HOME_OWNER: "/homeowner-dashboard",
  HOME_OWNER_DETAIL: "/homeowner-dashboard/homeowner-detail",
  VERSION_HISTORY: '/version-history',
  HOME: "/",

  NEW_PROSPECTS: "/prospect-dashboard",
  PROSPECT_DETAIL: "/prospect-dashboard/prospect-detail",
  NEW_REALTOR: "/new-realtor",
  NEW_SALES_REP: "/new-sales-rep",

  ALL_LEADS: "/all-leads",
  ALL_PROSPECTS: "/all-prospects",

  LEAD_DETAIL: "/lead-dashboard/lead-detail",
  NEW_LEADS_TODAY: "/lead-dashboard/new-leads-today",
  NEW_LEADS: "/lead-dashboard",

  REALTOR_DETAIL: "/realtor-dashboard/realtor-detail",
  AGENCY_DETAIL: "/agency-dashboard/agency-detail",
  REALTOR_REGISTRATION_DETAIL: "/realtor-registration-dashboard/realtor-detail",
  CLIENT_REGISTRATION_DETAIL: "/client-registration-dashboard/client-detail",
  SALES_REP_DETAIL: "/sales-rep-detail",
  ADMIN_DETAIL: "/admin-detail",

  ADMIN_CUSTOMIZATION: "/admin-customization",
  // LOGIN: "/login",
  EMAIL_TEMPLATES: "/email-templates",
  CONVERSION_REPORT: "/conversion-report",
  ACTIVITY_REPORT: "/activity-report",
  OSC_COMMISSION_REPORT: "/osc_commission_report",
  USAGE_REPORT: '/last-login-report',

  ARCHIVED_DETAILS: "/archived-details",
  ARCHIVE_USERS: "/archive-users",
  SUBMISSION_UPLOAD: '/submission-upload',
  UNAUTHORIZED: "/unauthorized",
  SERVER_DOWN: '/server-down',

  isVisibleNewProspectButtonforRoute,
  isVisibleBackButtonforDasboardViewAllActivity,
  isVisibleCommunitySelectionForLeadView,
  isLeadCardonProspectDashboardView,
  isProspectDashboard
};
function isProspectDashboard(path) {
  let isprospectDB = false;
  if (
    path === routes_constants.HOME ||
    path === routes_constants.PROSPECT_DASHBOARD
  ) {
    isprospectDB = true;
  }
  return isprospectDB;
}
function isVisibleCommunitySelectionForLeadView(path, isProspectDashboard) {
  let isVisible = false;
  if (isProspectDashboard && path === routes_constants.NEW_LEADS) {
    isVisible = true;
  }
  return isVisible;
}

function isLeadCardonProspectDashboardView(toPath, currentPath) {
  let isprospectDB = false;
  if (
    toPath === routes_constants.NEW_LEADS &&
    isProspectDashboard(currentPath)
  ) {
    isprospectDB = true;
  }
  return isprospectDB;
}

function isVisibleNewProspectButtonforRoute(path) {
  let isVisible = false;
  if (
    path === routes_constants.HOME ||
    path === routes_constants.PROSPECT_DASHBOARD ||
    path === routes_constants.NEW_PROSPECTS_TODAY ||
    path === routes_constants.NEW_PROSPECTS ||
    path === routes_constants.A_RANKED_PROSPECTS ||
    path === routes_constants.ALL_PROSPECTS
  ) {
    isVisible = true;
  }
  return isVisible;
}

function isVisibleBackButtonforDasboardViewAllActivity(path) {
  let isVisible = false;
  if (
    path === routes_constants.NEW_LEADS_TODAY ||
    path === routes_constants.NEW_PROSPECTS_TODAY ||
    path === routes_constants.NEW_PROSPECTS ||
    path === routes_constants.A_RANKED_PROSPECTS ||
    path === routes_constants.NEW_LEADS
  ) {
    isVisible = true;
  }
  return isVisible;
}
export default routes_constants;
