import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FaAngleDown } from "react-icons/fa";
import { ListGroup, OverlayTrigger, Popover } from "react-bootstrap";

import { isNotNULL } from "../../utils/helpers";
import Header from "../dashboard-title";
import "./list-popup.css";

/**
 * This component is a single selection dropdown used in multiple headers in overall application. This dropdown is created using react-bootstrap components like OverlayTrigger etc.
 * @param {Object} props
 * @param {String} props.label first item label
 * @param {Array} props.list array of objects containing list of items
 * @param {JSX.Element} props.defaultItem JSX containing pre selected value
 * @param {Function} props.mapper function that renders list items
 */
const SelectionListPopup = ({ list, checkRole, mapper, defaultItem, ...props }) => {
  const [selectedItem, setSelectedItem] = useState("");
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions([
      ...props.optionsMapper ? props.optionsMapper(list) : [{list}]
    ])
  }, [list])

  const setSelection = item => {
    !props.selectedID && setSelectedItem(item);
    document.body.click();
  };

  const getLabelSelected = selected => {
    let label = props.label;
    if (isNotNULL(selected)) {
      label = selected;
    }
    return label;
  };
  
  const getUpdatedLabelSelected = () => {
    let label = props.label;
    const exists=list && list.filter(item => item.id===props.selectedID)
    
    if(exists.length===0){
      return label
    }
    
    return exists[0].name;
  };

  return (
    <div>
      <div className="toolbarSelect">
        <div
          aria-owns={/* show ?  */"list-popper" /* : null */}
          aria-haspopup="true"
        >
          <Header title={props.title} />
          <OverlayTrigger
            key="tooltip-key"
            placement="bottom-start"
            rootClose={true}
            trigger="click"
            overlay={
              <Popover id="list-popper" className="list-popover">
                <>
                  <ListGroup
                    className={"list-group"}
                    onClick={e => setSelection(e.target.textContent)}
                  >
                    {defaultItem}
                  </ListGroup>
                  {
                    options.map((item, index)=>{
                      return(
                        <div key={index}>
                          {item.label && <div className="list-item list-item-label">{item.label}</div>}
                          { 
                            <ListGroup
                              className={item.label? "list-group list-group-padding": "list-group"}
                              onClick={e => setSelection(e.target.textContent)}
                            >
                              {mapper(item.list)}
                            </ListGroup>
                          }
                        </div>
                      )
                    })
                  }
                </>
              </Popover>
            }
          >
            <div align="left" className="label-selected">
              <h3>{ !props.selectedID ?getLabelSelected(selectedItem):getUpdatedLabelSelected()}</h3>
              <FaAngleDown />
            </div>
          </OverlayTrigger>
        </div>
      </div>
    </div>
  );
};

SelectionListPopup.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  list: PropTypes.array
};

export default SelectionListPopup;
