import {OTHER_TYPE_ID} from './customer-types'
export const getInitialState = ({loading, cache}) => {

    return {
        input: {
            customer_type_id: OTHER_TYPE_ID
        },
        search: (cache && cache.search) || "",
        limit: (cache && cache.limit) || 10,
        pageNum: (cache && cache.pageNum) || 0,
        pageSize: (cache && cache.limit) || 10,
        order: (cache && cache.order) || {
            id: -1,
            name: "",
            comparator: "",
            sort: ""
        },
        isSubscription:  false,
        userPageCount: 0,
        formSubmission: [],
        loading: loading
    }
} 

export default function reducer(state, action) {
    switch (action.type) {
      case "UPDATE":
        return Object.assign({}, state, { ...action.payload });

      default:
        return state;
    }
}