import * as Yup from "yup";
import { REQUIRE_FIELD } from "../../../utils/form-errors";
import * as validations from "../../../utils/common-validations";

export const updateInformationForm = {
    id: null,
    title: "",
    email: "",
    desc: ""
};

export const updateInformationFormSchema = Yup.object().shape({
    id: Yup.number().required("Please select a division"),
    title: Yup.string(),
    email: Yup.string()
        .when('title', {
            is: 'Division',
            then: validations.email.required(REQUIRE_FIELD)
        }),
    desc: Yup.string().nullable()
});