import * as Yup from "yup";
import * as validations from "../../../utils/common-validations";
import * as errorMessages from "../../../utils/form-errors";

export const agencyForm = {
  name: "",
  address: "",
  city: "",
  state_id: null,
  zip: "",
};

export const agencySchema = Yup.object().shape({
  name: validations.isSpace
    .max(128, errorMessages.AGENCY_NAME_LONG)
    .required(errorMessages.REQUIRE_FIELD),
  address: Yup.string()
    .nullable()
    .max(512, errorMessages.AGENCY_ADDRESS_LONG),
  city: validations.isSpace.max(24, errorMessages.CITY_LONG).nullable(),
  state_id: Yup.string().default("Select...").nullable(),
  zip: validations.isSpace.max(10, errorMessages.ZIP_LONG).nullable(),
});
