

import gql from 'graphql-tag';

const ADD_REALTOR_DELETE_REQUEST = gql` 
  mutation addRealtorDeleteRequests($input: AddRealtorDeleteRequestInput!) {
    addRealtorDeleteRequests(input: $input) {
      message
      code
    }
  }
`;

export default ADD_REALTOR_DELETE_REQUEST;