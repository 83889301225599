

import { compose, graphql } from 'react-apollo';
import LeadFilter from './lead-filters';
import { CREATE_LEAD_FILTER } from '../../../graphql/mutations';
import { GET_ALL_LEAD_CLASSIFICATION_FILTERS, GET_ALL_STATES, GET_CUSTOMER_ORIGINATION_AREA, GET_LEAD_FILTERS, GET_LEAD_FILTER_BYID } from "../../../graphql/queries";

const CreateLeadFilterWithMutation = compose(
  graphql(GET_CUSTOMER_ORIGINATION_AREA, { name: "getOriginationAreas" }),
  graphql(GET_ALL_STATES, { name: "getAllStates" }),
  graphql(GET_LEAD_FILTERS, { name: "getLeadFilters" }),
  graphql(GET_LEAD_FILTER_BYID, {
    name: "getLeadFilterDetail",
    options: (props) => ({ variables: { input: null } })
  }),
  graphql(CREATE_LEAD_FILTER, { name: "addLeadFilter" }),
  graphql(GET_ALL_LEAD_CLASSIFICATION_FILTERS, { name: "getLeadClassificationFilters"})  
)(LeadFilter);

export default CreateLeadFilterWithMutation;