import React, { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { FaFilter, FaStar, FaUser } from "react-icons/fa";
import { getAllEmails, handleSecondaryEmails, phoneLink } from "../../utils/helpers";
import { LEAD } from "../../utils/constants"
import DetailsSectionTable from "../../components/table/custom-details-table";
import "./merge-customer.css";

/** @module CustomerDetailsCard */
/**
 * This component is used to render a card details section. It divides the details card into
 * four sections. Buyer 1, buyer 2, other information, and communities. 
 * 
 * @param {Object} props this contains the data from the graphql query and the function to close the drawer
 * @param {Object} props.data it contains data to be shown on card
 * @returns {JSX.Element} It returns jsx containing card title and card body
 */
const CustomerDetailsCard = ({ data, communityData }) => {
  const [buyerOne, setBuyerOne] = useState({});
  const [buyerTwo, setBuyerTwo] = useState({});
  const [otherInfo, setOtherInfo] = useState({});
  const [communities, setCommunities] = useState({
    lead_communities: [],
    prospect_communities: []
  })

  useEffect(() => {

    setBuyerOne({
      "First Name": data.first_name_1,
      "Last Name": data.last_name_1,
      "Primary Email": data.primary_email_1,
      "Secondary Email(s)":
        data.secondary_emails_1.length > 0
          ? handleSecondaryEmails(data, "secondary_emails_1", getAllEmails, null, false)
          : null,
      "Cell#": phoneLink(data, "cell_phone_1", false)
    });

    setBuyerTwo({
      "First Name": data.first_name_2 || null,
      "Last Name": data.last_name_2 || null,
      "Primary Email": data.primary_email_2 || null,
      "Secondary Email(s)":
        data.secondary_emails_2.length > 0
          ? handleSecondaryEmails(data, "secondary_emails_2", getAllEmails, null, false)
          : null,
      "Cell#": phoneLink(data, "cell_phone_2", false) || null
    });

    setOtherInfo({
      "Address": data.street_address || null,
      "City": data.city || null,
      "State": data.state ? data.state.name : null,
      "Zip": data.zip || null,
      "Realtor": data.realtor ? `${data.realtor.first_name} ${data.realtor.last_name}` : null,
      "Division": data['__typename'] === LEAD && data.divisions ? data.divisions.map(division => division.name).join(', ')
        : data.division ? data.division.name : null
    });

  }, [data]);

  useEffect(() => {
    let lead_communities = []
    let prospect_communities = []

    if (communityData) {
      communityData.prospect_communities.length && communityData.prospect_communities.map(community => prospect_communities.push(community.name))
      communityData.lead_communities.length && communityData.lead_communities.map(community => lead_communities.push(community.name))
    }

    setCommunities({ lead_communities, prospect_communities })
  }, [communityData])

  return (
    <Card className="details-card">
      <Card.Header>
        <FaUser /> {`${data.first_name_1} ${data.last_name_1}`}
      </Card.Header>
      <Card.Body>
        <div>
          <DetailsSectionTable data={buyerOne} tableHeader="Buyer# 1" />
        </div>
        <div>
          <DetailsSectionTable data={buyerTwo} tableHeader="Buyer# 2" />
        </div>
        <div>
          <DetailsSectionTable
            data={otherInfo}
            tableHeader="Other Information"
          />
        </div>
        <div>
          <Table borderless className="details-table small" style={{ fontSize: '86%' }}>
            <thead><tr><th className="p-2 pl-1" colSpan="2">Communities</th></tr></thead>
            <tbody>
              <tr>
                <td className="details-table-row p-1 pl-2" style={{ fontSize: '12px', width: '35%' }}><FaStar className='header-icon' /> Lead</td>
                <td className="details-table-row p-1" >{communities.lead_communities.length ? communities.lead_communities.join(', ') : "-"}</td>
              </tr>
              <tr>
                <td className="details-table-row p-1 pl-2" style={{ fontSize: '12px', width: '35%' }}><FaFilter className='header-icon' /> Prospect</td>
                <td className="details-table-row p-1" >{communities.prospect_communities.length ? communities.prospect_communities.join(', ') : "-"}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Card.Body>
    </Card>
  );
};

export default CustomerDetailsCard;
