import gql from "graphql-tag";

const SELECT_DEFAULT_VIEW = gql`
  mutation selectDefaultView($tableView: Boolean!) {
    selectDefaultView(tableView: $tableView) @client {
      tableView
    }
  }
`;

export default SELECT_DEFAULT_VIEW;
