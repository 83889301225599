import React from "react";
import { useLocation } from 'react-router-dom';

import { Accordion, NavItem } from 'react-bootstrap';
import SubNavigationItem from "./sub-navigation-item";
import './nav-item-styles.css';

/**
 * This component is a stateless component used to render Navigation Item. It can also contain sun-navigation items dynamically. On page load sub nav item remains selected and open.
 * @param {Object} props 
 * @param {JSX.Element} props.children rendering sub-navs
 * @param {String} props.navKey unique string to be used as Accordian key
 * @param {Array} props.chilrenNavLinks array containing path/route of sub-navs
 */
const NavigationItem = (props) => {
    let location = useLocation()

    const { children, navKey, chilrenNavLinks, ...rest } = props;
    const component = (
      <div className='custom-navitem'>
        <Accordion.Toggle id="accordian-toggle" as={NavItem} eventKey={navKey}>
          <SubNavigationItem
            {...rest}
          />
        </Accordion.Toggle>
        {children &&
          <Accordion.Collapse id="accordian-collapse" eventKey={navKey}>
            <div> {children}</div>
          </Accordion.Collapse>}
      </div>
    )

    return (
      <>
        {
          chilrenNavLinks ? <Accordion defaultActiveKey={chilrenNavLinks && chilrenNavLinks.includes(location.pathname) ? navKey : null}>
            {component}
          </Accordion> : component
        }
      </>
    )
}

export default NavigationItem;
