import React from "react";

import SubmissionUploadView from './submission-upload-view'
import DashboardSelectBar from "../dashboard/dashboard-select-bar";
import ContentGrid from "../../components/grid/content-grid";
import "./admin-styles.css";

/**
 * this component renders the webform submission upload page which contains a two select bars and a file dropzone component.
 * @param {Object} props 
 */
const SubmissionUpload = (props) => {
    return (
        <ContentGrid
          id="submission-upload"
          selectbar={
            <DashboardSelectBar
              pageTitle="Submission Upload"
              {...props}
            />
          }
          content={{
            data: <SubmissionUploadView/>
          }}
        />
      );
};

export default SubmissionUpload;
