import Dialog from "../dialog/dialog";
import Select from "react-select";

import React, { useState } from 'react'

/**
 * This component renders dialog box taking user's confirmation if he wants to make a phone call.
 * @param {Object} props 
 * @param {Boolean} props.show show or hide dialog box
 * @param {Function} props.onHide callback function which is called when no is pressed
 * @param {String} props.phoneNumber number on which user wants to call
 * @param {Function} props.makePhoneCall mutation to make a phone call
 * @param {Function} props.setNotificationProps local mutation to set notification message in the store and show on the screen
 * @param {Function} props.getClients query to get list of active zoom phone clients
 * @param {Boolean} props.isActive true if logged in user has a zoom phone number
 */
const CallDialog = ({ show, onHide, phoneNumber, makePhoneCall, setNotificationProps, isActive }) => {

    const onCall = async () => {
        const res = await makePhoneCall({
            variables: { phoneNumber }
        })
        setNotificationProps({ variables: { open: true, message: res.data.makePhoneCall.message } });

        onHide()
    }

    const dialogBody = <a href={`zoomphonecall://${phoneNumber}`} className="modal-link"> CALL </a>

    return (
        <Dialog
            show={show}
            onHide={onHide}
            title="Phone Call Confirmation"
            body={`Do you want to call the phone number ${phoneNumber}?`}
            click={isActive ? () => { } : onCall}
            clickname={isActive ? dialogBody : "CALL"}
            closename="CANCEL"
        />
    )
}

export default CallDialog
