import gql from "graphql-tag";

const GET_VERSION_HISTORY = gql`
    query getVersionHistory ($limit: Int, $pageNum: Int){
        getVersionHistory(limit: $limit, pageNum: $pageNum){
            version_history{
              version
              recording_embed_code
              release_date
              features{
                feature_id
                description
              }
              fixes{
                fix_id,
                description
              }
            }
            total_count
          }
    }
`;

export default GET_VERSION_HISTORY