import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import Header from "../dashboard-title";
import { makeStyles, withStyles } from '@material-ui/styles';
import { ROLE_CSM, ROLE_OSC } from "../../utils/constants"

/**
 * This component is being used in {@link ListComp}, it uses react select to create a multi selection dropdown for top select-bar. It accepts a list of objects containing id and name property to display list in the dropdown. The list can have categories as well, and user can select multiple values and remove them individually or all at once by clicking on the cross icon.
 * @param {Object} props 
 * @param {Boolean} props.showInactive list shows inactive communities/values when passed as true
 * @param {Boolean} props.isReportView to view both csm and osc categories in multi-selection dropdown
 * @param {Boolean} props.disableDropdown to disable selection and only show title
 * @param {String} props.title title of the multi-select 
 * @param {Array} props.list array of objects containing list of items
 * @param {String} props.label containing default value to show initially as a placeholder
 * @param {Array} props.defaultVal array of items that are to be pre-selected by default
 * @param {Function} props.handleMutiSelect callback function to perform action when a selection is made from the dropdown
 */
const MultiSelectionListPopup = ({
  list,
  defaultVal,
  handleMutiSelect,
  isLeadView,
  isReportView,
  disableDropdown,
  ...props
}) => {
  const [values, setValues] = useState([]);
  const [defaults, setDefaults] = useState([]);
  const [inactiveValues, setInactiveValues] = useState([]);
  const [csmUsers, setCsmUsers] = useState([]);
  const [oscUsers, setOscUsers] = useState([]);

  /** 
   * It accepts an array of strings of the items that are selected, finds them in the actual list of objects
   * and creates an acceptable structure which is {value: , label: , color: }
   * */
  const getDefaults = (vals) => {
    return vals.reduce((defaultVal, item) => {
      const label = list &&
        list.filter((listItem) =>
          listItem.id === item || listItem.id === item.value
        ).find((item) => item);

      if (label) {
        defaultVal.push({ value: label.id, label: label.name, color: "grey" });
      }
      return defaultVal;
    }, []);
  };

  const onChange = (data) => {
    let listItems = [];
    data && data.map((item) => listItems.push(item.value));

    setDefaults(getDefaults(listItems));
    handleMutiSelect(listItems);
  };

  //custom styles of multi select
  const customStyles = {
    control: () => ({
      minWidth: "80px",
      display: "flex",
      borderRadius: "4px",
      alignItems: 'center',
      " > div": {
        padding: "0",
        color: "transparent",
      },
      "&:hover": {
        cursor: "pointer",
      },
    }),
    indicatorsContainer: () => ({
      alignItems: 'end',
      display: 'flex'
    }),
    multiValue: () => ({
      backgroundColor: "#80b602",
      color: "#222",
      display: "flex",
      borderRadius: "3px",
      marginRight: "4px",
      fontWeight: "bold",
      marginBottom: "1px",
    }),
    multiValueRemove: () => ({
      "&:hover": {
        background: "rgba(0,0,0,.1)",
      },
    }),
    dropdownIndicator: () => ({
      padding: "3px",
      color: "white",
      visibility: disableDropdown ? "hidden" : "visible",
    }),
    menu: () => ({
      background: "#191b1d",
      position: "absolute",
      minWidth: "200px",
      border: "none",
      borderRadius: "3px",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: '0'
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    placeholder: () => ({
      fontSize: "16px",
      fontWeight: "400",
      opacity: "0.8",
      padding: "0",
      color: "white",
      marginTop: '0.4rem'
    }),
    dropdownIndicator: () => ({
      color: 'white',
      padding: '8px',
      " > svg": {
        width: '19px'
      },
    }),
    option: () => ({
      backgroundColor: "black",
      border: "none",
      padding: "0.5rem 0.8rem",
      color: "#d8d8d8",
      fontSize: "0.9em",
      "&:hover": {
        backgroundColor: "#333",
        color: "#80b602",
        cursor: "pointer",
      },
    }),
    group: () => ({
      paddingTop: "0px"
    }),
    groupHeading: (provided) => ({
      ...provided,
      padding: "10px 12px",
      margin: '0'
    })
  };

  // divide options into multiple categories like active/inactive communities etc
  const options = !props.showInactive
    ? values
    : [
      {
        label: `Active ${props.title}`,
        options: values,
      },
      {
        label: `Inactive ${props.title}`,
        options: inactiveValues,
      },
      {
        label: "CSM",
        options: csmUsers,
      },
      {
        label: "OSC",
        options: oscUsers,
      },
    ];

  useEffect(() => {
    const vals = [],
      inactive = [],
      csm = [],
      osc = [];

    list && list.forEach((item) => {
      const option = { label: item.name, value: item.id, color: "grey" };

      if (isReportView) {
        if (item.roles.includes(ROLE_CSM)) csm.push(option)
        if (item.roles.includes(ROLE_OSC)) osc.push(option)
      } else {
        item.is_active ? vals.push(option) : inactive.push(option)
      }
    });

    setValues(vals);
    setInactiveValues(inactive);
    setCsmUsers(csm);
    setOscUsers(osc);
  }, [list]);

  useEffect(() => {
    setDefaults(getDefaults(defaults.length ? defaults : defaultVal));
  }, [defaultVal]);

  return (
    <div>
      <div className="toolbarSelect">
        <div className="d-flex justify-content">
          <Header title={props.title} />
        </div>
        <div
          className="d-flex justify-content-between align-items-baseline"
        >
          <Select
            onChange={onChange}
            options={options}
            styles={customStyles}
            isMulti
            isSearchable={false}
            value={defaults}
            isDisabled={disableDropdown ? true : false}
            placeholder={<div>{props.label}</div>}
          />
        </div>
      </div>
    </div>
  );
};

MultiSelectionListPopup.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  list: PropTypes.array,
  defaultVal: PropTypes.node,
  handleMutiSelect: PropTypes.func,
};

export default withStyles(makeStyles)(MultiSelectionListPopup);
