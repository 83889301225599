import gql from "graphql-tag";

const GET_DASHBOARD_HOMEOWNERS = gql`
  query getDashboardHomeowners($input: HomeOwnersFilter) {
    getHomeowners:getAllHomeOwners(filter: $input, limit:1) {
      Homeowners:Home_Owner {
        id
        first_name_1
        last_name_1
        community {
          id
          name
        }
      }
      total_count
    }
  }
`;

export default GET_DASHBOARD_HOMEOWNERS;
