export const TOGGLE_ALL = "TOGGLE_ALL";
export const TOGGLE_ROW = "TOGGLE_ROW";
export const GOTO_PAGE = "GOTO_PAGE";
export const SET_PAGE = "SET_PAGE";
export const SORT_DATA = "SORT_DATA";
export const SET_SORT_COL = "SET_SORT_COL";
export const SET_SEARCH_TERM = "SET_SEARCH_TERM";
export const CLEAR_SEARCH_TERM = "CLEAR_SEARCH_TERM";
export const FILTERED_ROWS = "FILTERED_ROWS";
export const UPDATE_PAGE_ON_UPDATE = "UPDATE_PAGE_ON_UPDATE";
export const SET_FILTER = "SET_FILTER";
export const CLEAR_FILTER = "CLEAR_FILTER";
export const UPDATE = "UPDATE";