import gql from 'graphql-tag';

const MARK_HOMEOWNER_FOLLOW_UP_DONE = gql` 
mutation markDoneHomeownerFollowUp($input: HomeownerFollowUpMarkDoneInput!) {
  markDoneHomeownerFollowUp(input: $input){
      message
      code
    }
  }
`;

export default MARK_HOMEOWNER_FOLLOW_UP_DONE;