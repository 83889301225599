import React from "react";
import PropTypes from "prop-types";
import Pagination from "./pagination";
import PageSizer from "./page-size";
import TotalCount from "./total-count";

/**
 * this component renders the bottom of the tables which cover the pagination and page size
 * @param {boolean} canPreviousPage specifies if there is a previous page 
 * @param {boolean} canNextPage specifies if there is a next page 
 * @param {number} pageCount total number of pages
 * @param {function} gotoPage 
 * @param {function} nextPage
 * @param {function} previousPage
 * @param {number} pageIndex
 * @param {number} pageSize number of records to be shown on each page of the table.
 * @param {function} setPageSize
 * @param {number} totalRecords  
 */
export const Pager = ({
  canPreviousPage,
  canNextPage,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  pageIndex,
  pageSize,
  setPageSize,
  totalRecords
}) => {
  return (
    <div className="pagination-wrapper position-absolute d-flex w-100 justify-content-between">
      <div className="paginationLg d-flex align-items-center">
        <TotalCount
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRecords={totalRecords}
        />
        <PageSizer pageSize={pageSize} setPageSize={setPageSize} />
      </div>
      <div className="d-sm-flex justify-content-end align-items-sm-center w-100">
        <Pagination
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageCount={pageCount}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
          pageIndex={pageIndex}
        />
        <div className="pagesizeSm"><PageSizer pageSize={pageSize} setPageSize={setPageSize} /></div>
      </div>
    </div>
  );
};

Pager.propTypes = {
  canPreviousPage: PropTypes.bool.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  // pageCount: PropTypes.number.isRequired,
  gotoPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  totalRecords: PropTypes.number.isRequired
};

export default Pager;
