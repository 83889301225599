export const WEB_ORIGINATION_SOURCE = 'Web'
export const REALTOR_ORIGINATION_SOURCE = 'Realtor'
export const NOTIFICATION_FETCH_LIMIT = 15

export const ROLE_CSM = 'csm'
export const ROLE_OSC = 'osc'
export const ROLE_MANAGER = 'manager'
export const ROLE_DIVISION_MANAGER = 'divmgr'

export const appointmentTypeDurations = {
  'In-Person': [60, 120],
  'Virtual': [15, 30, 60],
  'Phone': [15, 30, 60]
};

export const LEAD = "Lead"
export const PROSPECT = "Prospect"
export const HOMEOWNER = "Homeowner"
export const MARKETING_ONLY = "Marketing Only"
export const REALTOR = "Realtor"

export const TIME_FORMAT_24 = 'HH:mm'
export const TIME_FORMAT_12 = 'hh:mm'

export const TEXT_OUT_ACTIVITY = 'Text Out'
