import React from "react";
import { getIn } from "formik";
import PropTypes from "prop-types";
import "./fields.css";

/**
 * This component is a select field which is used to select a single value from the dropdown
 * @param {Object} props 
 * @param {Boolean} props.required to mark the field mandatory
 * @param {Boolean} props.disabled to mark the field disabled
 * @param {Object} props.propsStyle to custom style select field
 * @param {String} props.classStyle to add a custom className
 * @param {Array} props.fieldsToClear contains the fields to clear on reselecting the current field
 */
const SelectField = props => {
  const {
    id,
    label,
    field,
    field: { onChange, value, name, ...otherProps },
    form: { touched, errors, values, setFieldValue },
    children,
    type,
    required,
    disabled,
    propsStyle,
    classStyle,
    isString,
    fieldsToClear = [],
    clearFieldsCallback,
    customOnChange,
    ...rest
  } = props;
  const error = getIn(errors, field.name);
  const touch = getIn(touched, field.name);
  const errorMessage = touch && error;
  const style = required && !disabled
    ? { borderLeftColor: "red", borderLeftWidth: 2, background: "black" }
    : { background: "" };

  const myOnChange = e => {
    // convert string ids to integers, or keep the string value
    let valToSet = !e.target.value
      ? null
      : isString
        ? e.target.value
        : isNaN(parseInt(e.target.value, 10))
          ? e.target.value
          : parseInt(e.target.value, 10);

    onChange(valToSet);
    customOnChange && customOnChange({ key: name, value: valToSet });
    setFieldValue(field.name, valToSet, true);

    // clear out the other field values depending on current field
    if (clearFieldsCallback) {
      const fields = clearFieldsCallback(values, valToSet);
      fields.forEach(item => {
        setFieldValue(item.fieldName, item.value, true);
      });
    } else {
      fieldsToClear.forEach(item => {
        setFieldValue(item.fieldName, item.value, true);
      });
    }
  };

  return (
    <div id={id + "-form-group"} className="form-group">
      <label htmlFor={id + "-input"} className="control-label">
        {label}
      </label>
      <select
        disabled={disabled}
        style={{ ...style, ...propsStyle }}
        onChange={myOnChange}
        id={id + "-input"}
        type={type}
        className={`${classStyle} form-control ${errorMessage && !disabled ? "is-invalid" : ""}`}
        value={value !== null && value !== undefined ? value : ""}
        {...otherProps}
        {...rest}
      >
        {children}
      </select>
      {error && !disabled && (
        <div className="invalid-feedback">{errorMessage}</div>
      )}
    </div>
  );
};

SelectField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
  // children: PropTypes.array
};

export default SelectField;
