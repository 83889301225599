import React, { useContext } from "react";
import ContentGrid from "../../components/grid/content-grid";
import DashboardSelectBar from "../dashboard/dashboard-select-bar";
import WebFormOthersTable from "./webform-others-container";
import { Auth } from "../../rbac/rbac";

/**
 * This component is used to render other type webform dashboard without cards and just a table. This dashboard contains division and communities dropdown,communities dropdown for all the rols and the division just for 
 * manager role only.
 * @param {*} props 
 */
const WebFormOthersDashboard = props => {
  const { is_manager, has_multiple_divisions } = useContext(Auth);

  return (
    <ContentGrid
      id="webform-others-dashboard"
      selectbar={
        <DashboardSelectBar
          type="WEB FORMS"
          withCommunity
          withDivision={is_manager() || has_multiple_divisions()}
          {...props}
        />
      }
      content={{
        data: <WebFormOthersTable />
      }}
    />
  );
};

export default WebFormOthersDashboard;
