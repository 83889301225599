
import gql from 'graphql-tag';

const UPDATE_LEAD_APPOINTMENT = gql` 
  mutation updateLeadAppointment($input: LeadAppointmentUpdateInput!) {
    updateLeadAppointment(input: $input) {
      message
      code
    }
  }
`;

export default UPDATE_LEAD_APPOINTMENT;
