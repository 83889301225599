import React from "react";
import PropTypes from "prop-types";

import { isNotNULL } from "../../../utils/helpers";

/**
 * This component is used in {@link TableRow} to display the cell of the row
 */
class TableCell extends React.Component {
  /**
   * @param {object} rest component props
   * @param {string} tableCellData Cell of the row
   */
  render() {
    const { tableCellData, ...rest } = this.props;

    return (
      <td
        colSpan={isNotNULL(tableCellData.cs) ? tableCellData.cs : 1}
        {...rest}
        className="table-col"
      >
        {isNotNULL(tableCellData.val) ? tableCellData.val : tableCellData}
      </td>
    );

  }
}


TableCell.propTypes = {
  tableCellData: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node
  ]).isRequired
};

export default TableCell;