import React, { useEffect, useContext, useRef, useMemo, useState } from "react";
import _ from "lodash";
import { Dropdown, DropdownButton } from "react-bootstrap";
import TableView, {
  SortableHeader,
  stringComparator,
  EmailCell,
  TextHeader,
  TextCellWithMapper,
  PhoneCell,
  TextObjectWithMapper,
  ActionCell,
  TooltipCell,
  DetailLinkCell,
  UnrespondedUnconvertedIconCell,
} from "../../components/new-table";
import { SUB_FORM_SUBMISSION } from "../../graphql/subscriptions";
import { DashboardContext } from "../../context";
import { getStringDescendantElementsWithEmptyVals } from "../../components/new-table/utils";
import WebformFilterForm from "./webform-filters";
import WebFormEditForm from "./edit-webform";
import {
  formatActivityDate,
  getCardParams,
  getUpdatedParams,
  phoneDisplay,
} from "../../utils/helpers";
import CustomLoader from "../../components/custom-loader";
import FadeIn from "../../components/fade-in";
import reducer, { getInitialState } from "./webform-reducer";
import { FaPen } from "react-icons/fa";
import routeConstants from "../layout/routes";
import Drawer from "../../hoc/Drawer";
import * as ids from "./customer-types";
import Dialog from "../../components/dialog/dialog";

/** @module WebFormTableModule */

/**
 * This component is a table used to display formsubmission. It contains pagination, searchbar, filtering and export
 * to csv options. It also has a basic hover action like edit option. It shows different columns for
 * different card selection. Table uses dashboard context to access current selected card, and uses reducer to
 * maintain its state.
 *
 * @param {Object} props it contains all the mutations/queries in the conatiner as props
 * @param {Object} props.data it contains array of formsubmissions to be shown on table
 * @param {Object} props.getTableParams it contains cards info stored inside cache
 * @returns {JSX.Element} It returns jsx containing the table, dialog box and the side drawer to show formsubmission edit form.
 */
const WebFormTable = ({ data, getTableParams: { tableParams }, ...props }) => {
  const [statusID, setStatusID] = useState();
  const [formIds, setFormIds] = useState([]);
  const [showConvertConfirmation, setShowConvertConfirmation] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);

  const { currentCard } = useContext(DashboardContext);
  const cacheParams = getCardParams(currentCard.title, tableParams);
  const [state, dispatch] = React.useReducer(
    reducer,
    getInitialState({ loading: data.loading, cache: cacheParams })
  );

  const {
    render,
    isSubscription,
    userPageCount,
    pageSize,
    formSubmission,
    dataField,
    loading,
  } = state;
  const handler = useRef(false);

  /**
   * On every card change, first check if that card is present inside store, if yes apply those paramters as initial
   * values, otherwise reset to initial card state where everything (search, filtering etc...) starts from scratch.
   */
  useEffect(() => {
    if (!cacheParams) dispatch({ type: "RESET", payload: { render: false } });
    else
      dispatch({
        type: "UPDATE",
        payload: { ...cacheParams, render: false, pageSize: cacheParams.limit },
      });

    handler.current = data.subscribeToMore({
      document: SUB_FORM_SUBMISSION,
      variables: { ...currentCard.filter },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) return prev;
        dispatch({
          type: "UPDATE",
          payload: { isSubscription: true, loading: true },
        });
        return prev;
      },
    });
    props.setPreviousCard({ variables: { title: currentCard.title } });
    return () => {
      handler.current && handler.current();
      handler.current = false;
    };
  }, [props.currentCard.title]);

  /**
   * refetch table data if any update is performed
   */
  useEffect(() => {
    if (isSubscription) {
      dispatch({ type: "UPDATE", payload: { isSubscription: false } });
      data.refetch();
    }
  }, [isSubscription]);

  /**
   * Either set the data in state or set the loading to inform the table what to render.
   */
  useEffect(() => {
    if (!data.loading && data.getFormSubmission && data.getFormSubmission.formSubmission) {
      dispatch({
        type: "UPDATE",
        payload: {
          render: true,
          formSubmission: data.getFormSubmission.formSubmission,
          loading: false,
          userPageCount: Math.ceil(data.getFormSubmission.total_count / pageSize),
        },
      });
    }
    if (data.loading) dispatch({ type: "UPDATE", payload: { loading: data.loading } });
  }, [data]);

  const handleEdit = (data) => {
    dispatch({
      type: "UPDATE",
      payload: { dataField: data },
    });
    setShowEditForm(true);
  };

  const handleSubmit = () => {
    data.refetch()
    setShowEditForm(false);
  };

  const getFormData = ({ firstname, lastname, phone, email, form_submission_id }) => {
    return { firstname, lastname, phone, email, form_submission_id };
  };

  const notify = (message) => {
    props.setNotificationProps({ variables: { open: true, message: message } });
  };

  const updateStatus = () => {
    props
      .updateFormSubmissionStatus({
        variables: {
          formSubmission_id: formIds,
          status_id: statusID,
        },
      })
      .then((res) => {
        if (res.data.updateFormSubmissionStatus.code === 200) {
          notify(res.data.updateFormSubmissionStatus.message);
          data.refetch();
          setShowConvertConfirmation(false);
        }
      });
  };

  /**
   * It is a callback function which is called on any table change like: pagination, page-size, filters, search term.
   * Table passes its current state so that we can update the table parameters in store and state, which are used in
   * refetching the data with correct parameters.
   *
   * @param {Object} tableProps current state of the table
   * @function
   * @inner
   * @memberof module:WebFormTableModule
   * @see {@link module:WebFormTableModule~WebFormTable}
   */
  const fetchData = React.useCallback((tableProps) => {
    if (loading) return;

    const variables = {
      filter: {
        ...tableProps.filter,
      },
      limit: tableProps.pageSize,
      pageNum: tableProps.currentPage,
      search: tableProps.searchTerm,
      sort: tableProps.sortColumn ? tableProps.sortColumn : state.sort,
    };
    const pageCount = Math.ceil(data.getFormSubmission.total_count / tableProps.pageSize);

    if (tableProps.searchTerm !== state.search || !_.isEqual(variables.filter, state.filter)) {
      variables.pageNum = 0;
    }
    if (tableProps.currentPage > pageCount - 1) {
      variables.pageNum = pageCount ? pageCount - 1 : 0;
    }

    dispatch({
      type: "UPDATE",
      payload: { ...variables, pageSize: tableProps.pageSize, loading: true },
    });
    if (pageCount !== userPageCount) {
      dispatch({
        type: "UPDATE",
        payload: { userPageCount: pageCount },
      });
    }

    props
      .setTableParams({
        variables: {
          cardsParams: getUpdatedParams(tableParams.cardsParams, {
            cardTitle: currentCard.title,
            params: variables,
          }),
        },
      })
      .then((res) => {
        dispatch({ type: "UPDATE", payload: { loading: false } });
      });
  });

  /**
   * It is used to render the mass dropdown actions which contains a single action.
   * @param {array} ids selected webform ids
   * @function
   * @inner
   * @memberof module:WebFormTableModule
   * @see {@link module:WebFormTableModule~WebFormTable}
   * @returns {JSX.Element}
   */
  const massDropDownActions = (ids) => {
    const allStatuses = props.getAllStatuses.getAllStatuses;
    return (
      <DropdownButton
        title="Convert to Others"
        drop="left"
        id="dropdown-item-button"
        variant="secondary"
        className="dropdown_button"
        style={{ border: "none" }}
      >
        {allStatuses &&
          allStatuses.map((status, index) => (
            <Dropdown.Item
              onSelect={() => {
                setStatusID(status.id);
                setShowConvertConfirmation(true);
                setFormIds(ids);
              }}
              key={index}
            >
              {status.name}
            </Dropdown.Item>
          ))}
      </DropdownButton>
    );
  };

  /**
   * this function sorts the array of object by the responded at attribute
   * @param {Array} arr array of formsubmissions
   * @function
   * @inner
   * @memberof module:WebFormTableModule
   */
  const sortByRespondedAt = (arr) => {
    return arr.sort(function (a, b) {
      return new Date(b.responded_at) - new Date(a.responded_at);
    });
  };

  /**
   * this function is used to map the redponded and responded by cells
   * @param {array} formSubmission array of formsubmissions
   * @param {string} accessor object attribute name
   * @param {function} valueGetter
   * @function
   * @inner
   * @memberof module:WebFormTableModule
   * @returns {string}
   */
  const respondedMapper = (formSubmission, accessor, valueGetter) => {
    let details = formSubmission[accessor].filter((val) => val);
    if (formSubmission.responded) {
      details = details.filter((d) => d.responded_at != null);
    }
    details = sortByRespondedAt(details);
    return formSubmission.responded ? valueGetter(details[0]) : "";
  };

  /**
   * this function is used to display the redponded cells value
   * @param {object} field rows of the table
   * @param {string} accessor object attribute name
   * @function
   * @inner
   * @returns {string}
   * @memberof module:WebFormTableModule
   */
  const respondedValueMapper = (field, accessor) => {
    const res = respondedMapper(field, accessor, (item) => item.responded_at);
    return res ? formatActivityDate(res) : "";
  };

  /**
   * this function is used to retrun responder name
   * @param {object} item contains information related to the responder
   * @function
   * @inner
   * @memberof module:WebFormTableModule
   * @returns {string}
   */
  const responderMapper = (item) =>
    item.responder ? item.responder.first_name + " " + item.responder.last_name : "";

  /**
   * this function is used to display the redponded By cells value
   * @param {object} field rows of the table
   * @param {string} accessor object attribute name
   * @function
   * @inner
   * @memberof module:WebFormTableModule
   * @returns {string}
   */
  const respondedByMapper = (field, accessor) => {
    return respondedMapper(field, accessor, responderMapper);
  };

  /**
   * this function is used to map the message cells value
   * @param {object} field rows of the table
   * @param {string} accessor object attribute name
   * @function
   * @inner
   * @memberof module:WebFormTableModule
   * @returns {string}
   */
  const messageMapper = (field, accessor) => {
    const sortedSubmissions = field.responded
      ? sortByRespondedAt(field[accessor].filter((val) => val))
      : field[accessor].filter((val) => val).reverse();
    const messages = sortedSubmissions.map((item) => {
      return item.interested_in ? item.interested_in.replace(/<br>/gm, "") : "";
    });
    return messages[0];
  };

  /**
   * this function is used to map the community/division and form type cells value
   * @param {object} field rows of the table
   * @param {string} accessor object attribute name
   * @param {string} type inner object attribute name
   * @function
   * @inner
   * @memberof module:WebFormTableModule
   * @returns {array}
   */
  const communityAndDivisionMapper = (field, accessor, type) => {
    const sortedSubmissions = field.responded
      ? sortByRespondedAt(field[accessor].filter((val) => val))
      : field[accessor].filter((val) => val).reverse();
    return getStringDescendantElementsWithEmptyVals(sortedSubmissions, type);
  };

  /**
   * Columns of the table are memoized and are created again when current card changes, since columns vary according
   * to selected card, like the existing community column is being removed or added in archived or new cards view.
   * @constant
   * @memberof module:WebFormTableModule
   */
  const columns = React.useMemo(() => [
    {
      id: "0",
      header: "",
      accessor: ["responded", "converted"],
      csvHeader: "Responded/Converted",
      component: TextHeader,
      sortable: false,
      csvMapper: (field, accessor) => (`${field[accessor[0]]}/${field[accessor[1]]}`),
      cell: {
        component: UnrespondedUnconvertedIconCell,
      }
    },
    {
      id: "1",
      header: "First Name",
      accessor: "firstname",
      component: SortableHeader,
      comparator: stringComparator,
      cell: {
        component: DetailLinkCell,
        path: (field, accessor) => routeConstants.WEB_FORM_DETAIL,
      },
    },
    {
      id: "2",
      header: "Last Name",
      accessor: "lastname",
      component: SortableHeader,
      comparator: stringComparator,
      cell: {
        component: DetailLinkCell,
        path: (field, accessor) => routeConstants.WEB_FORM_DETAIL,
      },
    },
    {
      id: "3",
      header: "Email",
      accessor: "email",
      component: SortableHeader,
      comparator: stringComparator,
      cell: {
        component: EmailCell,
      },
    },
    {
      id: "4",
      header: "Phone",
      accessor: "phone",
      component: TextHeader,
      csvMapper: (field, accessor) => phoneDisplay(field[accessor]) || "",
      sortable: false,
      cell: { component: PhoneCell },
    },
    {
      id: "5",
      header: "Message",
      accessor: "interested_in",
      component: TextHeader,
      cell: {
        component: TooltipCell,
      },
    },
    {
      id: "6",
      header: "Received",
      accessor: "created_at",
      component: SortableHeader,
      csvMapper: (field, accessor) => field[accessor] ? formatActivityDate(field[accessor]) : "",
      cell: {
        component: TextCellWithMapper,
        mapper: (field, accessor) => field[accessor] ? formatActivityDate(field[accessor]) : "",
      },
    },
    {
      id: "7",
      header: "Responded",
      accessor: "responded_at",
      component: TextHeader,
      csvMapper: (field, accessor) => field[accessor] ? formatActivityDate(field[accessor]) : "",
      cell: {
        component: TextCellWithMapper,
        mapper: (field, accessor) => field[accessor] ? formatActivityDate(field[accessor]) : "",
      },
    },
    {
      id: "8",
      header: "Responded By",
      accessor: "responder",
      component: TextHeader,
      csvMapper: (field, accessor) => responderMapper(field),
      cell: {
        component: TextCellWithMapper,
        mapper: (field, accessor) => responderMapper(field),
      },
    },
    {
      id: "9",
      header: "Form Type",
      accessor: "form_type",
      component: TextHeader,
      sortable: false,
      cell: {
        component: TooltipCell
      },
    },
    {
      id: "10",
      header: "Community",
      accessor: "community",
      component: TextHeader,
      sortable: false,
      csvMapper: (field, accessor) => field[accessor]?.name || "",
      cell: {
        component: TextCellWithMapper,
        mapper: (field, accessor) => field[accessor]?.name || "",
      },
    },
    {
      id: "11",
      header: "Division",
      accessor: "division",
      component: TextHeader,
      sortable: false,
      csvMapper: (field, accessor) => field[accessor]?.name || "",
      cell: {
        component: TextCellWithMapper,
        mapper: (field, accessor) => field[accessor]?.name || "",
      },
    },
    ...(!(
      currentCard.filter.customer_type_id === ids.DELETED_TYPE_ID ||
      currentCard.filter.customer_type_id === ids.NEW_TYPE_ID
    )
      ? [
        {
          id: "12",
          header: "Existing Communities",
          accessor: "customer_communities",
          component: TextHeader,
          sortable: false,
          csvMapper: (field, accessor) =>
            field[accessor] ? field[accessor].map(({ name }) => name) : "",
          cell: {
            component: TextObjectWithMapper,
            mapper: (field, accessor) => field[accessor],
          },
        },
      ]
      : []),
  ]);

  /**
   * Array of objects containing edit actions which can be performed on row hover.
   * @constant
   * @memberof module:WebFormTableModule
   */
  const hoverActions = useMemo(
    () => [
      {
        tooltip: "Edit",
        icon: FaPen,
        component: ActionCell,
        action: handleEdit,
      },
    ],
    []
  );

  const tableOptions = {
    title: currentCard.title,
    massActions: {
      render: (getSelectedRows) => {
        let ids = getSelectedRows().map((x) => x.id);
        return massDropDownActions(ids);
      },
    },
    FilterItems: WebformFilterForm,
  };

  return (
    <div>
      <Dialog
        show={showConvertConfirmation}
        onHide={() => setShowConvertConfirmation(false)}
        title="Webform Type Conversion"
        body="Are you sure you want to convert selected Webform(s)?"
        click={updateStatus}
        clickname="YES"
        closename="NO"
      />

      <Drawer show={showEditForm} toggleSideBar={() => setShowEditForm(false)}>
        <WebFormEditForm
          data={dataField && getFormData(dataField)}
          setNotificationProps={props.setNotificationProps}
          onSubmit={handleSubmit}
          close={() => setShowEditForm(false)}
        />
      </Drawer>

      <FadeIn show={render}>
        <div className="table-webform">
          <TableView
            columns={columns}
            data={formSubmission}
            tableOptions={tableOptions}
            loading={loading}
            userPageCount={userPageCount}
            hoverActions={hoverActions}
            userTotalCount={
              !loading && data.getFormSubmission ? data.getFormSubmission.total_count : null
            }
            fetchData={fetchData}
            currentPage={state.pageNum}
            pageSize={state.pageSize}
            searchTerm={state.search}
            sortColumn={state.sort}
            filter={state.filter}
            controlled={true}
          />
        </div>
      </FadeIn>
      {!render && <CustomLoader />}
    </div>
  );
};

export default WebFormTable;
