import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import { Observable } from "apollo-link";
import { createUploadLink } from 'apollo-upload-client';

import refreshToken from "../rbac/refresh-token";
import { getAccessToken, setAccessToken } from "../rbac/access-token";
import typeDefs from "./typeDef";
import defaults from "./defaults";
import resolvers from "./resolvers";

const try_login = (subscriber) => {
  fetch(process.env.REACT_APP_LOGIN, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  })
    .then(async x => {
      if (x.status !== 200) {
        return Promise.reject(x);
      }
      const { accessToken, user } = await x.json();
      setAccessToken(accessToken);

      const user_id = localStorage.getItem('user_id') && JSON.parse(localStorage.getItem('user_id'))
      if (user_id !== user.id) {
        localStorage.setItem('user_id', user.id);
        window.location.reload();
      }
      subscriber.next(accessToken);
      subscriber.complete();
    })
    .catch(err => {
      if (err.message === "Network request failed") {
        cache.writeData({ data: { authStatus: { status: 'network-failed', __typename: "AuthStatus" } } })
      } else {
        cache.writeData({ data: { authStatus: { status: 'unauthorized', __typename: "AuthStatus" } } })
      }
      subscriber.error(err);
    });
}

const promiseToObservable = promise =>
  new Observable(subscriber => {
    promise.then(
      value => {
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      async (err) => {
        if (err.message === "User is not logged in") {
          try_login(subscriber);
        }
        else {
          subscriber.error(err);
          cache.writeData({
            data: {
              authStatus: { status: "unauthorized", __typename: "AuthStatus" }
            }
          });
        }
      }
    );
    return subscriber; // this line can removed, as per next comment
  });

const cache = new InMemoryCache();

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_BACKEND_HOST_URL
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_WEB_SOCKET_URL,
  options: {
    reconnect: true,
    inactivityTimeout: 1,
    lazy: true,
    reconnectionAttempts: 3,
    connectionParams: () => {
      return { authToken: getAccessToken() };
    }
  }
});

const subscriptionMiddleware = {
  applyMiddleware: (options, next) => {
    options.authToken = getAccessToken();
    next();
  }
};

wsLink.subscriptionClient.use([subscriptionMiddleware]);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: getAccessToken()
    }
  };
});

const errorLink = onError(
  ({ networkError, graphQLErrors, forward, operation }) => {
    if (networkError && networkError.message === 'Failed to fetch') {
      cache.writeData({ data: { authStatus: { status: 'network-failed', __typename: "AuthStatus" } } })
    }
    if (
      graphQLErrors &&
      graphQLErrors[0].extensions &&
      graphQLErrors[0].extensions.code === "UNAUTHENTICATED"
    ) {
      //cache.writeData({ data: { authStatus: { status: 'unauthorized', __typename: "AuthStatus" } } })
      return promiseToObservable(refreshToken()).flatMap(() => {
        operation.setContext(({ headers = {} }) => ({
          headers: {
            // re-add old headers
            // ...headers,
            authorization: getAccessToken()
          }
        }));
        return forward(operation);
      });
    } else if (graphQLErrors && graphQLErrors[0].message) {
      cache.writeData({
        data: {
          notificationProps: {
            open: true,
            message: graphQLErrors[0].message,
            __typename: ""
          }
        }
      });
    }
  }
);

const link = errorLink.split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  authLink.concat(httpLink)
  /* ApolloLink.from([
    authLink, logoutLink, httpLink
  ]) */
);

const data = defaults;
cache.writeData({ data });

const client = new ApolloClient({
  link,
  cache,
  resolvers,
  typeDefs
});

export const clearCache = () => {
  // client.clearStore().then(()=>{
  client.cache.writeData({ data: data });
  // });
};

export default client;
