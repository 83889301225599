import gql from "graphql-tag";

const GET_DIVISIONS = gql`
  {
    getAllDivisions {
      id
      name
    }
  }
`;

export default GET_DIVISIONS;
