import React, { useState, useRef } from "react";
import { Button, OverlayTrigger, Overlay, Tooltip } from "react-bootstrap";
import { IoMdFunnel } from "react-icons/io";
import "./table-extras.css";

/**
 * this component render the filter button of the table
 * @param {*} props 
 * @returns {JSX.Element}
 */
export default function TableExtras(props) {
  const {
    FilterItems,
    filter,
    setFilter
  } = props;

  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <>
      <OverlayTrigger overlay={<Tooltip>Filters</Tooltip>}>
        <Button
          ref={target}
          variant="secondary"
          onClick={() => {
            setShow(!show);
          }}
          className="btn-icon"
        >
          <IoMdFunnel
            size={15}
            className="funnel"
            id="custom-funnel-icn"
            style={{ marginTop: -2 }}
          />
        </Button>
      </OverlayTrigger>

      <Overlay
        target={target.current}
        show={show}
        rootClose={true}
        onHide={() => {
          setShow(false);
        }}
        placement="bottom-end"
      >
        {({
          placement,
          scheduleUpdate,
          outOfBoundaries,
          arrowProps,
          show: _show,
          ...props
        }) => (
            <div
              className="p-3"
              {...props}
              style={{
                backgroundColor: "#191b1d",
                border: "1px solid black",
                borderRadius: 3,
                boxShadow: "0 0 20px black",
                zIndex: 50,
                ...props.style
              }}
            >
              <FilterItems setFilter={setFilter} filter={filter} setShow={setShow} />
            </div>
          )
        }
      </Overlay>
    </>
  );
}
