import { compose, graphql } from "react-apollo";
import LeadForm from "./create-edit-lead-form";
import { CREATE_LEAD, CREATE_LEAD_DUPLICATE, UPDATE_LEAD } from "../../../graphql/mutations";
import {
  GET_LEAD_DIVISIONS,
  GET_ALL_STATES,
  GET_CUSTOMER_ORIGINATION_AREA,
  GET_LOGGED_IN_COMMUNITIES,
  GET_COMMUNITIES
} from "../../../graphql/queries";
import { SET_NOTIFICATION_PROPS } from '../../../cacheql/mutations'

const CreateEditLeadWithMutation = compose(
  graphql(GET_LOGGED_IN_COMMUNITIES, { name: "getLoggedInCommunities" }),
  graphql(GET_ALL_STATES, { name: "getAllStates" }),
  graphql(GET_COMMUNITIES, { name: "getAllCommunities" }),
  graphql(GET_LEAD_DIVISIONS, { name: "getDivisions" }),
  graphql(GET_CUSTOMER_ORIGINATION_AREA, { name: "getOriginationAreas" }),
  graphql(CREATE_LEAD_DUPLICATE, { name: "addLeadDuplicate" }),
  graphql(CREATE_LEAD, { name: "addLead" }),
  graphql(UPDATE_LEAD, { name: "updateLead" }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" })
)(LeadForm);

export default CreateEditLeadWithMutation;
