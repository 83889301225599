import gql from "graphql-tag";

export const SEND_MASS_EMAIL = gql`
  mutation sendMassEmail($input: SendMassEmailInput) {
    sendMassEmail(input: $input) {
      message
      code
    }
  }
`;
