import React from "react";
import { FaRegEye } from "react-icons/fa";
import "./layout.css";

const ViewAsLayover = (props) => {
  return (
    <div className="view-as-warning">
      <FaRegEye style={{fontSize: "1.5rem"}}/>
      <span> You are viewing as {props.name} </span>
    </div>
  )
};

export default ViewAsLayover;
