import React, { useState } from "react";
import { OverlayTrigger, Tooltip, Popover } from "react-bootstrap";
import CustomButton from "../custom-button";

/**
 * @module ToolbarButtonModule 
 */
/**
 * this component is used to render the tools on the edit section 
 * @param {object} props component props
 * @param {string} customType defines the type of the cutom type tool (image, link, unlink) 
 * @param {string} style style of the block 
 */
const ToolbarButton = ({ ...props }) => {
  const [urlValue, setURLValue] = useState("");
  const [imgHeight, setImgHeight] = useState("20%");
  const [imgWidth, setImgWidth] = useState("60%");

  const onToggle = e => {
    e.preventDefault();
    if (props.customType === "unlink") {
      props.onToggle(e, "", props.customType);
    } else if (props.customType === "insert") {
      props.onToggle(props.label);
    } else {
      props.onToggle(props.style);
    }
  };

  let className = "RichEditor-styleButton";
  if (props.active) {
    className += " RichEditor-activeButton";
  }

  const handleAdd = e => {
    props.customType === "image"
      ? props.onToggle(e, urlValue, props.customType, imgHeight, imgWidth)
      : props.onToggle(e, urlValue, props.customType);
    document.body.click()
  };
  const btnValue = (
    <span className={className} onMouseDown={onToggle}>
      {props.tag ? (
        <props.tag>{props.label}</props.tag>
      ) : props.icon ? (
        <props.icon />
      ) : (
        props.label
      )}
    </span>
  );

  const overlayBtn = (
    <span>
    <OverlayTrigger
      trigger="click"
      key="down"
      placement="bottom"
      rootClose={true}
      overlay={
        <Popover id="editor-toolbar-btn-popover" className="custom-popover">
          <div className="small">
            URL:
            <input
              onChange={e => setURLValue(e.target.value)}
              type="text"
              value={urlValue}
              className="form-control"
              onKeyDown={e => {
                if (e.which === 13) {
                  handleAdd(e);
                }
              }}
            />
            <div className="d-flex align-items-end mt-2">
              {props.customType === "image" && (
                <>
                  <span>
                    Height:
                    <input
                      onChange={e => setImgHeight(e.target.value)}
                      type="text"
                      value={imgHeight}
                      className="form-control mr-2"
                    />
                  </span>
                  <span>
                    Width:
                    <input
                      onChange={e => setImgWidth(e.target.value)}
                      type="text"
                      value={imgWidth}
                      className="form-control mr-2"
                    />
                  </span>
                </>
              )}
              <CustomButton
                color="black"
                btnValue="Add"
                onMouseDown={handleAdd}
                className="ml-1"
              />
            </div>
          </div>
        </Popover>
      }
    >
      <div className={className}>{props.icon && <props.icon />}</div>
    </OverlayTrigger>
    </span>
  );

  return props.show ? (
    props.tooltip ? (
      <OverlayTrigger
        placement={props.customType === "insert" ? "right" : "top"}
        overlay={<Tooltip>{props.tooltip}</Tooltip>}
      >
        {props.overlay === true ? overlayBtn : btnValue}
      </OverlayTrigger>
    ) : (
      btnValue
    )
  ) : null;
};

export default ToolbarButton;
