import React from "react";
import { compose, graphql } from "react-apollo";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { FaChevronLeft } from "react-icons/fa";
import CustomButton from "../../components/custom-button";
import Header from "../../components/dashboard-title";
import { SelectBar } from "../../containers/";
import { UPDATE_FORM_SUBMISSION_STATUS } from "../../graphql/mutations";
import { goBack } from "../../utils/helpers";
import { SET_NOTIFICATION_PROPS } from "../../cacheql/mutations";
import SubscriptionHistoryContainer from "../common-lead-prospect-details/subscription-history-button";

/**
 * This component creates top bar of archived user's details page. It uses {@link Header} to show user's name. It has
 * a button to view subscription history rendered by {@link SubscriptionHistoryContainer} and a dropdown button
 * to convert the archived user back to lead/prospect. These both buttons are rendered at extreme right of the 
 * selectbar.
 * 
 * @param {Object} props 
 * @param {Object} props.archivedDetails user whose details page is opened
 * @param {Function} props.setShowLeadBar to toggle convert-to-lead side bar 
 * @param {Function} props.setShowProspectBar to toggle convert-to-prospect side bar 
 */
const ArchiveDetailSelectBar = (props) => {
  const { history } = props;

  return (
    <SelectBar>
      <div className="d-flex">
        <CustomButton
          onClick={() => goBack(history)}
          id="back-to-dashboard-btn"
          color="dark"
          btnIcon={<FaChevronLeft />}
          className="btn-back"
        />
        <Header title={"Archived"} subtitle={"User Details"} />
      </div>
      <div
        className="btn-container col-padding col-md-2 col-2"
        id="lead-dashboard-btn"
      >
        <div style={{ margin: "0 0.5rem 0 0", whiteSpace: "nowrap" }}>
          <SubscriptionHistoryContainer
            name={
              props.archivedDetails
                ? props.archivedDetails.first_name_1 +
                  " " +
                  props.archivedDetails.last_name_1
                : ""
            }
            customer_id={
              props.archivedDetails ? props.archivedDetails.id : null
            }
            primaryEmail1={
              props.archivedDetails
                ? props.archivedDetails.primary_email_1
                : null
            }
            primaryEmail2={
              props.archivedDetails
                ? props.archivedDetails.primary_email_2
                : null
            }
          />
        </div>
        <DropdownButton
          alignRight
          drop="down"
          key="1"
          id="dropdown-item-button"
          title={"RECOVER"}
          style={{margin: "2px 0rem 2px 0"}}
        >
          <Dropdown.Item
            onSelect={() => {
              props.setShowLeadBar(true);
            }}
            key={0}
          >
            Lead
          </Dropdown.Item>
          <Dropdown.Item
            onSelect={() => {
              props.setShowProspectBar(true);
            }}
            key={1}
          >
            Prospect
          </Dropdown.Item>
        </DropdownButton>
      </div>
    </SelectBar>
  );
};

export default compose(
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" }),
  graphql(UPDATE_FORM_SUBMISSION_STATUS, { name: "updateFormSubmissionStatus" })
)(ArchiveDetailSelectBar);
