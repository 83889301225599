import gql from 'graphql-tag';

const CONVERT_PROSPECT_TO_MARKETING_ONLY = gql` 
  mutation convertProspectToMarketingOnly($input: ConvertProspectInput!) {
    convertProspectToMarketingOnly(input: $input) {
      message
      code
      data {
        successData {
          id
          first_name_1
          last_name_1
        }
        failureData {
          id
          first_name_1
          last_name_1
        }
        communities {
          name
        }
      }
    }
  }
`;

export default CONVERT_PROSPECT_TO_MARKETING_ONLY;