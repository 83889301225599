import React, { useState, useEffect } from "react";
import ArchiveDetailSelectBar from "./archive-details-selectbar";
import ArchiveDetailsCard from "./archive-details-card";
import ArchivedDetailsTable from "./archive-details-table";
import { goBack, getIssueEmail } from "../../utils/helpers";
import ConvertLeadView from "../web-form/convert-lead-form";
import ConvertProspectView from "../web-form/convert-prospect-form";
import NoDataPage from "../no-data-page/no-data-page";
import logo from "../../images/graphic-no-lead.png";

/**
 * This component opens up on clicking any of the user from the archived table. This renders the details page of the
 * archived user which combines all the three pieces together to form a details page. It renders
 * {@link ArchiveDetailSelectBar} at the top, then {@link ArchiveDetailsCard} which displays basic information and
 * finally below it {@link ArchivedDetailsTable} which shows the whole lifecycle of that customer, for example: when
 * this customer was converted to lead and by whom.
 * @param {Object} props
 */
const ArchivedDetails = (props) => {
  const [archived, setArchived] = useState(null);
  const [showLeadBar, setShowLeadBar] = useState(false);
  const [showProspectBar, setShowProspectBar] = useState(false);
  const { data } = props;

  useEffect(() => {
    if (props.ArchivedUser.getArchived_Customer) {
      setArchived(props.ArchivedUser.getArchived_Customer.ArchivedCustomers[0]);
    }
  }, [props.ArchivedUser.getArchived_Customer]);
  const onSubmit = () => {
    setShowLeadBar(false);
    setShowProspectBar(false);
    goBack(props.history);
  };
  return (!props.ArchivedUser.loading &&
      props.ArchivedUser.getArchived_Customer?.ArchivedCustomers.length === 0) ? (
    <NoDataPage
      {...props}
      backgroundImage={logo}
      heading={"Archived User not found"}
      content={
        <div>
          <p className="message-reportBug">
            The resource for this prospect was not found in our records.
            <br />
            If it's a bug, please report.
            <br />
            <a
              href={getIssueEmail()}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-primary"
            >
              Report
            </a>
          </p>
        </div>
      }
    />
  ) : (
    <div className="col-padding col-md-12 col-12">
      <ConvertLeadView
        showSideBar={showLeadBar}
        toggleShowDrawer={() => setShowLeadBar(false)}
        onSubmit={onSubmit}
        recoverForm
        data={archived}
        convertFormType="ARCHIVED"
      />
      <ConvertProspectView
        showSideBar={showProspectBar}
        toggleShowDrawer={() => setShowProspectBar(false)}
        onSubmit={onSubmit}
        data={archived}
        recoverForm
        convertFormType="ARCHIVED"
      />
      <div className="row root-row" id="prospect-detail-header">
        <ArchiveDetailSelectBar
          {...props}
          archivedDetails={archived}
          setShowLeadBar={setShowLeadBar}
          setShowProspectBar={setShowProspectBar}
        />
      </div>
      <div>
        <ArchiveDetailsCard
          data={data.getCustomerLogById}
          archivedDetails={archived}
        />
      </div>
      <div>
        <ArchivedDetailsTable data={data} />
      </div>
    </div>
  );
};
export default ArchivedDetails;
