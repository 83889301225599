import gql from 'graphql-tag';

const UPDATE_LEAD_PHONE_CALL = gql` 
  mutation updateLeadPhoneCall($input: UpdateLeadPhoneCallInput!,$phone_call_id:ID!) {
    updateLeadPhoneCall(input: $input,phone_call_id:$phone_call_id) {
      message
      code
    }
  }
`;

export default UPDATE_LEAD_PHONE_CALL;
