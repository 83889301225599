import React from "react";
import PropTypes from 'prop-types';
import "./fields.css";

/**
 * This is a checkbox field which has label to the left of checkbox
 * @param {Object} props 
 * @param {String} props.label label left to the checkbox
 * @param {String} props.divClassName this is used for custom styling of the checkbox
 * @param {Function} props.customOnChange callback function to perform action on change.
 */
const CheckboxField = props => {
  const {
    id,
    label,
    type,
    field,
    form: { touched, errors },
    divClassName,
    customOnChange,
    revertValue,
    ...rest
  } = props;
  const error = touched[field.name] && errors[field.name];
  const {value, onChange, ...params} = field
  let checkValue=value===null || value===false || value===undefined ? false:true

  const myOnChange = (e) =>{
    onChange(e);
    customOnChange && customOnChange(e.target.checked)
  }
  return (
    <div id={id + "-form-group"} className={`form-group ${divClassName}`}>
      <div className="form-check">
        <label className="form-check-label">
          <input
            id={id + "-input"}
            type="checkbox"
            checked={revertValue ? !checkValue:checkValue}
            className={'form-check-input' + (error ? ' is-invalid' : '')}
            onChange={myOnChange}
            {...params}
            {...rest}
          />
          <span className='form-check-label-text'>{label}</span>
        </label>
        {error && <div className="invalid-feedback">{error}</div>}
      </div>
    </div>
  );
};

CheckboxField.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  // label: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
}
export default CheckboxField;
