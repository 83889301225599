import React, { useState, useEffect, useContext } from "react";
import { Form, Field, withFormik } from "formik";
import { ButtonToolbar } from "react-bootstrap";

import { addNoteSchema, noteForm } from "../../utils/note-form-schema";
import TextAreaField from "../../components/fields/text-area";
import CustomButton from "../../components/custom-button/index";
import ErrorFocus from "../../components/error-focus/error-focus";
import NoteAttachment from "./note-attachment";
import { Auth } from "../../rbac/rbac";
import './common-lead-prospect-forms.css';


/**
 * This form is used to create and update an notes. Its a pretty basic form containing 1 required text field. without filling it form cannot be submitted. On submission type of the form 
 * is checked and appropriate mutation is called to either create or edit notes. 
 * 
 * @param {Object} props 
 */
const NoteForm = props => {
  const { user } = useContext(Auth);
  const [filesToUpload, setFilesToUpload] = useState([{ file: null }]);

  useEffect(() => {
    props.setFieldValue("filesToUpload", filesToUpload)
  }, [filesToUpload])

  useEffect(() => {
    props.setFieldValue("creator_id", user.id)
  }, [])

  return (
    <Form className={"form-horizontal"} noValidate>
      <div className="form_TitleContainer">
        <div style={{ margin: '0 1.1rem' }}>
          <div className="form_Title">Notes & Reminders</div>
          <div className="form_TitleBig mt-1">
            <h2>{props.function !== 'UPDATE' ? "Create New" : "Edit"}</h2>
          </div>
        </div>
      </div>
      <div className="form_body">
        <div className="form-row">
          <div className="col-md-12">
            <Field
              label="Description"
              name="description"
              id="description"
              type="text"
              required
              rows={6}
              component={TextAreaField}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-12">
            <NoteAttachment
              setFilesToUpload={setFilesToUpload}
              {...props}
            />
          </div>
        </div>
        <div className="form-row">
          <ButtonToolbar>
            <CustomButton
              color="black"
              className={`form_CancelButton`}
              onClick={props.close}
              btnValue="CANCEL"
            />
            <CustomButton
              className={`form_SubmitButton`}
              type="submit"
              disabled={props.isSubmitting}
              btnValue={props.function}
            />
          </ButtonToolbar>
        </div>
        <ErrorFocus />
      </div>
    </Form>
  );
};

export const CreateProspectNote = withFormik({
  enableReinitialize: true,
  mapPropsToValues: props => ({ ...noteForm }),
  handleSubmit: (values, { props, setSubmitting }) => {
    const file_paths = values.filesToUpload.map(file => file.path)

    props.addProspectNote({
      variables: {
        input: {
          prospect_id: props.id,
          description: values.description,
          creator_id: values.creator_id,
          attached_file_paths: file_paths
        }
      }
    })
      .then(res => {
        setSubmitting(false)

        if (res.data.addProspectNote.code === 200 || res.data.addProspectNote.code === 500) {
          file_paths.length && props.addNoteAttachment({
            variables: {
              input: {
                file_paths,
                attachment_ids: res.data.addProspectNote.data
              }
            }
          })
            .then(res => {
              if (res.data.addNoteAttachments.code === 200) {
                values.filesToUpload.forEach((file, index) => {
                  fetch(res.data.addNoteAttachments.data[index], {
                    method: 'PUT',
                    body: file,
                  })
                })
              }
            })

          props.onSubmit(res.data.addProspectNote.message);
        }
      });
  },
  validationSchema: addNoteSchema,
  displayName: "new-note-form"
})(NoteForm);

export const UpdateProspectNote = withFormik({
  enableReinitialize: true,
  mapPropsToValues: props => ({ ...props.notes }),
  handleSubmit: (values, { props, setSubmitting }) => {
    const file_paths = values.filesToUpload.map(file => file.path)

    props.updateProspectNote({
      variables: {
        input: {
          id: props.notes.id,
          description: values.description,
          attached_file_paths: file_paths
        }
      }
    })
      .then(res => {
        setSubmitting(false)

        if (res.data.updateProspectNote.code === 200 || res.data.updateProspectNote.code === 500) {
          file_paths.length && props.addNoteAttachment({
            variables: {
              input: {
                file_paths,
                attachment_ids: res.data.updateProspectNote.data
              }
            }
          })
            .then(res => {
              if (res.data.addNoteAttachments.code === 200) {
                values.filesToUpload.forEach((file, index) => {
                  fetch(res.data.addNoteAttachments.data[index], {
                    method: 'PUT',
                    body: file,
                  })
                })
              }
            })

          props.onSubmit(res.data.updateProspectNote.message);
        }
      });
  },
  validationSchema: addNoteSchema,
  displayName: "new-note-form"
})(NoteForm);

export const CreateRealtorNote = withFormik({
  enableReinitialize: true,
  mapPropsToValues: props => ({ ...noteForm }),
  handleSubmit: (values, { props, setSubmitting }) => {
    const file_paths = values.filesToUpload.map(file => file.path)

    props.addRealtorNote({
      variables: {
        input: {
          realtor_id: props.id,
          description: values.description,
          creator_id: values.creator_id,
          attached_file_paths: file_paths
        }
      }
    })
      .then(res => {
        setSubmitting(false)

        if (res.data.addRealtorNote.code === 200 || res.data.addRealtorNote.code === 500) {
          file_paths.length && props.addNoteAttachment({
            variables: {
              input: {
                file_paths,
                attachment_ids: res.data.addRealtorNote.data
              }
            }
          })
            .then(res => {
              if (res.data.addNoteAttachments.code === 200) {
                values.filesToUpload.forEach((file, index) => {
                  fetch(res.data.addNoteAttachments.data[index], {
                    method: 'PUT',
                    body: file,
                  })
                })
              }
            })

          props.onSubmit(res.data.addRealtorNote.message);
        }
      });
  },
  validationSchema: addNoteSchema,
  displayName: "new-note-form"
})(NoteForm);

export const UpdateRealtorNote = withFormik({
  enableReinitialize: true,
  mapPropsToValues: props => ({ ...props.notes }),
  handleSubmit: (values, { props, setSubmitting }) => {
    const file_paths = values.filesToUpload.map(file => file.path)

    props.updateRealtorNote({
      variables: {
        input: {
          id: props.notes.id,
          description: values.description,
          attached_file_paths: file_paths
        }
      }
    })
      .then(res => {
        setSubmitting(false)

        if (res.data.updateRealtorNote.code === 200 || res.data.updateRealtorNote.code === 500) {
          file_paths.length && props.addNoteAttachment({
            variables: {
              input: {
                file_paths,
                attachment_ids: res.data.updateRealtorNote.data
              }
            }
          })
            .then(res => {
              if (res.data.addNoteAttachments.code === 200) {
                values.filesToUpload.forEach((file, index) => {
                  fetch(res.data.addNoteAttachments.data[index], {
                    method: 'PUT',
                    body: file,
                  })
                })
              }
            })

          props.onSubmit(res.data.updateRealtorNote.message);
        }
      });
  },
  validationSchema: addNoteSchema,
  displayName: "new-note-form"
})(NoteForm);

export const CreateHomeownerNote = withFormik({
  enableReinitialize: true,
  mapPropsToValues: props => ({ ...noteForm }),
  handleSubmit: (values, { props, setSubmitting }) => {
    const file_paths = values.filesToUpload.map(file => file.path)

    props.addHomeownerNote({
      variables: {
        input: {
          homeowner_id: props.id,
          description: values.description,
          creator_id: values.creator_id,
          attached_file_paths: file_paths
        }
      }
    })
      .then(res => {
        setSubmitting(false)

        if (res.data.addHomeownerNote.code === 200 || res.data.addHomeownerNote.code === 500) {
          file_paths.length && props.addNoteAttachment({
            variables: {
              input: {
                file_paths,
                attachment_ids: res.data.addHomeownerNote.data
              }
            }
          })
            .then(res => {
              if (res.data.addNoteAttachments.code === 200) {
                values.filesToUpload.forEach((file, index) => {
                  fetch(res.data.addNoteAttachments.data[index], {
                    method: 'PUT',
                    body: file,
                  })
                })
              }
            })

          props.onSubmit(res.data.addHomeownerNote.message);
        }
      });
  },
  validationSchema: addNoteSchema,
  displayName: "new-note-form"
})(NoteForm);

export const UpdateHomeownerNote = withFormik({
  enableReinitialize: true,
  mapPropsToValues: props => ({ ...props.notes }),
  handleSubmit: (values, { props, setSubmitting }) => {
    const file_paths = values.filesToUpload.map(file => file.path)

    props.updateHomeownerNote({
      variables: {
        input: {
          id: props.notes.id,
          description: values.description,
          attached_file_paths: file_paths
        }
      }
    })
      .then(res => {
        setSubmitting(false)

        if (res.data.updateHomeownerNote.code === 200 || res.data.updateHomeownerNote.code === 500) {
          file_paths.length && props.addNoteAttachment({
            variables: {
              input: {
                file_paths,
                attachment_ids: res.data.updateHomeownerNote.data
              }
            }
          })
            .then(res => {
              if (res.data.addNoteAttachments.code === 200) {
                values.filesToUpload.forEach((file, index) => {
                  fetch(res.data.addNoteAttachments.data[index], {
                    method: 'PUT',
                    body: file,
                  })
                })
              }
            })

          props.onSubmit(res.data.updateHomeownerNote.message);
        }
      });
  },
  validationSchema: addNoteSchema,
  displayName: "new-note-form"
})(NoteForm);


export const CreateLeadNote = withFormik({
  enableReinitialize: true,
  mapPropsToValues: props => ({ ...noteForm }),
  handleSubmit: (values, { props, setSubmitting }) => {
    const file_paths = values.filesToUpload.map(file => file.path)

    props.addLeadNote({
      variables: {
        input: {
          lead_id: props.id,
          description: values.description,
          creator_id: values.creator_id,
          attached_file_paths: file_paths
        }
      }
    })
      .then(res => {
        setSubmitting(false)

        if (res.data.addLeadNote.code === 200 || res.data.addLeadNote.code === 500) {
          file_paths.length && props.addNoteAttachment({
            variables: {
              input: {
                file_paths,
                attachment_ids: res.data.addLeadNote.data
              }
            }
          })
            .then(res => {
              if (res.data.addNoteAttachments.code === 200) {
                values.filesToUpload.forEach((file, index) => {
                  fetch(res.data.addNoteAttachments.data[index], {
                    method: 'PUT',
                    body: file,
                  })
                })
              }
            })

          props.onSubmit(res.data.addLeadNote.message);
        }
      });
  },
  validationSchema: addNoteSchema,
  displayName: "new-note-form"
})(NoteForm);

export const UpdateLeadNote = withFormik({
  enableReinitialize: true,
  mapPropsToValues: props => ({ ...props.notes }),
  handleSubmit: (values, { props, setSubmitting }) => {
    const file_paths = values.filesToUpload.map(file => file.path)

    props.updateLeadNote({
      variables: {
        input: {
          id: props.notes.id,
          description: values.description,
          attached_file_paths: file_paths
        }
      }
    })
      .then(res => {
        setSubmitting(false)

        if (res.data.updateLeadNote.code === 200 || res.data.updateLeadNote.code === 500) {
          file_paths.length && props.addNoteAttachment({
            variables: {
              input: {
                file_paths,
                attachment_ids: res.data.updateLeadNote.data
              }
            }
          })
            .then(res => {
              if (res.data.addNoteAttachments.code === 200) {
                values.filesToUpload.forEach((file, index) => {
                  fetch(res.data.addNoteAttachments.data[index], {
                    method: 'PUT',
                    body: file,
                  })
                })
              }
            })

          props.onSubmit(res.data.updateLeadNote.message);
        }
      });
  },
  validationSchema: addNoteSchema,
  displayName: "new-note-form"
})(NoteForm);
