import React from "react";
import {
  BLOCK_TYPES,
  INLINE_STYLES,
  LIST_TYPES,
  CUSTOM_TYPES
} from "./editor-constants";
import ToolbarButton from "./toolbar-button";
import { Dropdown } from "react-bootstrap";
import { MdTextFields } from "react-icons/md";



/**
 * this component is used to render and display the tools
 * @param {string} sectionType Type of selected section 
 * @param {object} placeholders Contains the names and the descptions of the personalization tokens
 * @param {object} editorState State of the editor
 */
export const ToolbarControls = ({
  placeholders,
  editorState,
  sectionType,
  ...props
}) => {
  const currentStyle = editorState.getCurrentInlineStyle();

  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  return (
    <div className="RichEditor-controls d-flex align-items-center justify-content-between mb-0">
      <div className="d-flex align-items-center">
        {["text", "header"].includes(sectionType) && (
          <Dropdown
            // alignLeft
            drop="down"
            variant="secondary"
            title="Styles"
            id="text-styles-btn-drop-down"
            key="down"
          >
            <Dropdown.Toggle>
              <MdTextFields size={20} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Header>Text Styles</Dropdown.Header>
              {BLOCK_TYPES.map((type, index) => {
                return (
                  <Dropdown.Item eventKey={index + 1} key={index + 1}>
                    <ToolbarButton
                      show={true}
                      key={type.label}
                      active={type.style === blockType}
                      label={type.label}
                      onToggle={props.toggleBlockType}
                      style={type.style}
                      tag={type.tag}
                    />
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        )}

        {LIST_TYPES.map((type, index) => (
          <div key={index}>
            <ToolbarButton
              show={type.show.includes(sectionType)}
              key={index + 1}
              active={type.style === blockType}
              tooltip={type.tooltip}
              icon={type.icon}
              onToggle={props.toggleBlockType}
              style={type.style}
            />
          </div>
        ))}
        {["text", "header"].includes(sectionType) && (
          <div className="vertical-line " />
        )}
        {INLINE_STYLES.map((type, index) => (
          <div key={index}>
            <ToolbarButton
              show={type.show.includes(sectionType)}
              keys={type.label}
              active={currentStyle.has(type.style)}
              tooltip={type.tooltip}
              icon={type.icon}
              onToggle={props.toggleInlineStyle}
              style={type.style}
            />
          </div>
        ))}
        {["text", "header"].includes(sectionType) && (
          <div className="vertical-line " />
        )}
        {CUSTOM_TYPES.map((type, index) => (
          <div key={index}>
            <ToolbarButton
              show={type.show.includes(sectionType)}
              tooltip={type.tooltip}
              icon={type.icon}
              customType={type.customType}
              onToggle={props.selectCustom}
              overlay={type.overlay}
            />
          </div>
        ))}
      </div>

      {(placeholders && (sectionType === "text" || sectionType === "subject")) && (
        <Dropdown
          // alignleft
          drop="down"
          variant="secondary"
          title="Styles"
          id="placeholder-btn-drop-down"
          key="down"
        >
          <Dropdown.Toggle className="btn-tokens">
            Personalization Tokens
          </Dropdown.Toggle>
          <Dropdown.Menu className="list-tokens">
            {placeholders.map((type, index) => (
              <Dropdown.Item eventKey={index + 1} key={index + 1}>
                <ToolbarButton
                  show={true}
                  key={type.name}
                  label={type.name}
                  customType={"insert"}
                  tooltip={type.description}
                  onToggle={props.togglePlaceholderInsert}
                />
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );
};

export default ToolbarControls;
