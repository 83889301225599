import gql from "graphql-tag";

const GET_DATE_RANGE = gql`
  query {
    selectedDateRange @client {
      startDate
      endDate
    }
  }
`;

export default GET_DATE_RANGE;
