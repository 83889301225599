
import gql from 'graphql-tag';

const CREATE_PROSPECT_DUPLICATE = gql`
mutation addProspectDuplicate($input:ProspectInput, $overwrite: Boolean = false, $registration_id: Int){
    addProspectDuplicate(input:$input, overwrite: $overwrite, registration_id: $registration_id){
      message
      code
    }
  }
`;

export default CREATE_PROSPECT_DUPLICATE;