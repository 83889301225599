import * as Yup from "yup";
import * as errorMessages from "../../../utils/form-errors";

export const marketingOnlyFilterForm = {
  customer_origination_area_id: undefined,
  state_id: undefined,
  cell_phone_1: "",
  other_notes: "",
  created_at: [null, null],
  filter_name: "",
  advanceFilters: false
};

export const marketingOnlyFilterSchema = Yup.object().shape({
  customer_origination_area_id: Yup.string().default("Select...").nullable(),
  state_id: Yup.string().default("Select...").nullable(),
  id: Yup.string().default("Select...").nullable(),
  cell_phone_1: Yup.string().nullable(),
  other_notes: Yup.string().nullable(),
  filter_name: Yup.string()
    .trim()
    .required(errorMessages.REQUIRE_FIELD)
});
