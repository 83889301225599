import React from "react";
import { Table as BootstrapTable } from "react-bootstrap";

import useTable from "./use-table";
import TableExtras from "./table-extras";
import Pager from "./pager";
import "./table.css";
import CustomLoader from "../custom-loader";
import { CheckboxHeader } from "./column-header";

/**
 * this component renders the entire table, it consists of 3 parts, the table extras, which contains, table title, search field , mass update , export, filter. the second main part is the table body with the header and the rows. 
 * and the last part is the pager.
 * @param {*} props 
 * @return {JSX.Element}
 */
const TableView = props => {
  const {
    columns,
    getColumnProperties,
    isSelectedAll,
    toggleSelectAll,
    toggleRowSelect,
    getSelectedRows,
    canAction,
    setSearchTerm,
    searchTerm,
    page,
    handleSort,
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    pageCount,
    pageSize,
    currentPage,
    setPageSize,
    rowsLength,
    userTotalCount,
    tableOptions,
    filter,
    setFilter,
    controlled,
    isGlobalSearch,
    removeSearchField
  } = useTable(props);
  return (
    <>
      <div className="table-wrapper-dark actions-table">
        {!isGlobalSearch &&
          <TableExtras
            searchTerm={searchTerm}
            tableOptions={tableOptions}
            controlled={controlled}
            getSelectedRows={getSelectedRows}
            columns={columns}
            setSearchTerm={setSearchTerm}
            filter={filter}
            setFilter={setFilter}
            canAction={canAction}
            removeSearchField={removeSearchField}
          ></TableExtras>}
        <div className="table-responsive">
          <BootstrapTable className="table table-dark" hover={true}>
            <thead>
              <tr>
                {!isGlobalSearch && <CheckboxHeader
                  isSelectedAll={isSelectedAll(page, pageSize)}
                  onChange={toggleSelectAll}
                />}
                {columns.map((column, i) => {
                  const {
                    component: Component,
                    sortable,
                    hidden,
                    ...rest
                  } = column;
                  // return (
                  if (!hidden && !sortable === false) {
                    return <Component header={column.header} key={i} />;
                  } else if (!hidden) {
                    return (
                      <Component
                        {...rest}
                        sort={getColumnProperties(i)}
                        key={i}
                        onClick={sort =>
                          handleSort(
                            sort,
                            i,
                            column.accessor,
                            column.comparator
                          )
                        }
                      />
                    );
                  }
                  return null;
                })
                  // );
                }
                {props.hoverActions && <th scope="col" />}
              </tr>
            </thead>
            <tbody>
              {page.length > 0 ? (
                page.map((row, i) => {
                  let classes = row.is_deleted_appointment ? "important-row" : "";
                  classes += row.isSelected ? " highlight" : "";
                  return (
                    <tr
                      className={classes.trim()}
                      key={row.uuid + "-" + i}
                      onClick={e => {
                        toggleRowSelect(row.uuid, !row.isSelected);
                      }}
                    >
                      {!isGlobalSearch &&
                        <td style={{ width: "15px" }}>
                          <input
                            type="checkbox"
                            checked={row.isSelected}
                            readOnly
                          />
                        </td>
                      }
                      {columns.map((column, i) => {
                        const { hidden, accessor, cell } = column;
                        const { component: Component, ...rest } = cell;
                        // return (
                        // <>

                        if (!hidden) {
                          return (
                            <Component
                              {...rest}
                              field={row}
                              accessor={accessor}
                              key={i}
                            />
                          );
                        }
                        return null;

                        // </>
                        // );
                      })}
                      {props.hoverActions && (
                        <td>
                          <div className="data-actions">
                            {props.hoverActions.map((action, i) => {
                              const { component: Component, ...rest } = action;
                              return (
                                <Component
                                  key={i + "action-item"}
                                  {...rest}
                                  field={row}
                                />
                              );
                            })}
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })
              ) : (
                <>
                  <tr>
                    <td
                      colSpan={columns.filter(col => !col.hidden).length + 2}
                      valign="top"
                      style={{ textAlign: "center" }}
                    >
                      {props.loading ? (
                        <CustomLoader />
                      ) : (
                        <div className="p-3">*No records found.</div>
                      )}
                    </td>
                  </tr>
                  <tr></tr>
                </>
              )}
            </tbody>
          </BootstrapTable>
        </div>
        {!isGlobalSearch &&
          <Pager
            gotoPage={gotoPage}
            previousPage={previousPage}
            nextPage={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
            pageSize={pageSize}
            pageIndex={currentPage}
            setPageSize={setPageSize}
            totalRecords={userTotalCount || rowsLength}
          />}
      </div>
    </>
  );
};

export default TableView;
