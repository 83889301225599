import React, { useEffect, useState, useMemo, useContext } from "react";
import { FaTrashAlt, FaPen } from "react-icons/fa";
import _ from "lodash";
import { CreateEditRealtor } from "./realtor-form-container";
import RealtorFilterForm from "./realtor-filter";
import Dialog from "../../components/dialog/dialog";
import Drawer from "../../hoc/Drawer";

import routeConstants from "../layout/routes";
import TableView, {
  SortableHeader,
  stringComparator,
  boolComparator,
  TextCell,
  EmailCell,
  ActionCell,
  CheckboxCell,
  TextHeader,
  PhoneCell,
  DetailLinkCell,
  TextCellWithMapper,
} from "../../components/new-table";
import { RealtorsMassUpdateItems } from "./mass-update-items";
import { DashboardContext } from "../../context";
import DeleteDialogBox from "../../components/dialog/delete-dialog-box";

import FadeIn from "../../components/fade-in";
import CustomLoader from "../../components/custom-loader";

import reducer, { getInitialState } from "./realtors-reducer";
import {
  getUpdatedParams,
  getCardParams,
  phoneDisplay,
} from "../../utils/helpers";

/** @module ProspectsTableModule */

/**
 * This component is a table used to render all reators, YOY sales and registrations cards tables. It contains pagination, searchbar, filtering,
 * mass-update and export to csv options. It has couple of hover-actions. It shows multiple columns displaying basic
 * information related to realtors. Table uses reducer to maintain its state. It also accesses store to check if
 * table parameters are already stored or not.
 *
 * @param {Object} props it contains all the mutations/queries in the conatiner as props
 * @param {Object} props.data it contains array of realtors to be shown on table
 * @param {Object} props.getTableParams it contains cards info stored inside cache
 * @returns {JSX.Element} It returns jsx containing the table
 */
const RealtorsTable = ({
  data,
  addRealtor,
  updateRealtor,
  addRealtorDeleteRequests,
  getTableParams: { tableParams },
  ...props
}) => {
  const { currentCard } = useContext(DashboardContext);
  const cacheParams = getCardParams(currentCard.title, tableParams);
  const [state, dispatch] = React.useReducer(
    reducer,
    getInitialState({
      currentCard,
      loading: data.loading,
      cache: cacheParams,
    })
  );
  const {
    render,
    userPageCount,
    pageSize,
    Realtors,
    showSideBar,
    showConfirmationDialog,
    deleteId,
    selectedRealtor,
    loading,
  } = state;

  const [note, setNote] = useState();

  /** Refetch table data on any update */

  useEffect(() => {
    if (props.updated !== null) {
      data.refetch();
    }
  }, [props.updated]);

  useEffect(() => {
    if (!cacheParams) {
      dispatch({ type: "RESET", payload: { render: false } });
    } else {
      dispatch({
        type: "UPDATE",
        payload: { ...cacheParams, pageSize: cacheParams.limit, render: false },
      });
    }
    props.setPreviousCard({ variables: { title: currentCard.title } });
  }, [props.currentCard.title]);

  /**
   * Either set the data in state or set the loading to inform the table what to render.
   */
  useEffect(() => {
    if (!data.loading && data.getRealtors && data.getRealtors.realtors) {
      dispatch({
        type: "UPDATE",
        payload: {
          render: true,
          Realtors: data.getRealtors.realtors,
          loading: false,
          userPageCount: Math.ceil(data.getRealtors.total_count / pageSize),
        },
      });
    }
    if (data.loading) {
      dispatch({ type: "UPDATE", payload: { loading: data.loading } });
    }
  }, [data]);

  const deleteHandler = (realtorId) => {
    dispatch({
      type: "UPDATE",
      payload: { showConfirmationDialog: true, deleteId: [realtorId] },
    });
  };

  const cancelDelete = () => {
    dispatch({
      type: "UPDATE",
      payload: { showConfirmationDialog: false, deleteId: undefined },
    });
    setNote();
  };

  const handleDelete = () => {
    addRealtorDeleteRequests({
      variables: {
        input: {
          realtor_ids: deleteId,
          note: note,
        },
      },
    }).then((res) => {
      if (res.data.addRealtorDeleteRequests.code === 200) {
        notify(res.data.addRealtorDeleteRequests.message);
        cancelDelete();
        setNote("");
        props.update(); // notifyRefetch();
      }
    });
  };
  const isHandleHidden = (title, comparatorList) => {
    return comparatorList.some((comparator) => comparator === title);
  };

  const isDetail = () => {
    return currentCard.isDetail ? DetailLinkCell : TextCell;
  };

  const toggleSideBar = () =>
    dispatch({ type: "UPDATE", payload: { showSideBar: !showSideBar } });

  const editHandler = (realtor) => {
    toggleSideBar();
    dispatch({ type: "UPDATE", payload: { selectedRealtor: realtor } });
  };

  const notify = (message) => {
    props.setNotificationProps({ variables: { open: true, message: message } });
  };

  //Fetchmore and pagination functions
  /**
   * It is a callback function which is called on any table change like: pagination, page-size,search term, filters.
   * Table passes its current state so that we can update the table parameters in store and state, which are used in
   * refetching the data with correct parameters.
   *
   * @function
   * @inner
   * @memberof module:RealtorTableModule
   * @see {@link module:RealtorTableModule~RealtorTable}
   */
  const fetchData = React.useCallback((tableProps) => {
    if (loading) {
      return;
    }
    const variables = {
      filter: {
        ...props.currentCard.filter,
        ...tableProps.filter,
        text: tableProps.searchTerm,
      },
      limit: tableProps.pageSize,
      pageNum: tableProps.currentPage,
      order: tableProps.sortColumn ? tableProps.sortColumn : state.order,
      mode: currentCard.mode,
    };

    if (
      tableProps.searchTerm !== state.filter.text ||
      !_.isEqual(getFilters(variables), getFilters(state))
    ) {
      variables.pageNum = 0;
    }

    dispatch({
      type: "UPDATE",
      payload: { ...variables, pageSize: tableProps.pageSize, loading: true },
    });
    const pageCount = Math.ceil(
      data.getRealtors.total_count / tableProps.pageSize
    );

    if (pageCount !== userPageCount) {
      dispatch({
        type: "UPDATE",
        payload: { userPageCount: pageCount },
      });
    }

    props
      .setTableParams({
        variables: {
          cardsParams: getUpdatedParams(tableParams.cardsParams, {
            cardTitle: currentCard.title,
            params: variables,
          }),
        },
      })
      .then((res) => {
        dispatch({ type: "UPDATE", payload: { loading: false } });
      });
  });

  const getFilters = (variables) => {
    const { text, ...filters } = variables.filter;
    const setCheck =
      filters.is_listing_agent_of_sb ||
      filters.has_client_reg ||
      filters.agency_name !== "" ||
      filters.sale_start_date ||
      filters.sale_end_date ||
      filters.register_start_date ||
      filters.register_end_date;

    return setCheck ? filters : {};
  };

  /**
   *  This defines columns of the table. It has multiple columns, displaying information of realtors.
   *  some columns are common for the 3 cards, and some columns are being added or removed from the table based on the cards.
   * @constant
   * @memberof module:RealtorTableModule
   */
  const columns = useMemo(
    () => [
      {
        id: "1",
        header: "First Name",
        accessor: "first_name",
        component: SortableHeader,
        comparator: stringComparator,
        cell: {
          component: isDetail(),
          path: (field, accessor) => routeConstants.REALTOR_DETAIL,
        },
      },
      {
        id: "2",
        header: "Last Name",
        accessor: "last_name",
        component: SortableHeader,
        comparator: stringComparator,
        cell: { component: TextCell },
      },
      {
        id: "3",
        header: "Email(s)",
        accessor: "primary_email",
        component: SortableHeader,
        comparator: stringComparator,
        cell: {
          component: EmailCell,
          check: "primary_email_status",
          callback: getEmails,
        },
      },
      {
        id: "4",
        header: "Mailchimp Subscription",
        accessor: "primary_email_status",
        hidden: true,
        csvMapper: (field, accessor) => field[accessor] ? "Subscribed" : "Unsubscribed",
        cell: { component: null },
      },
      {
        id: "5",
        header: "Phone",
        accessor: "cell_phone",
        component: TextHeader,
        sortable: false,
        csvMapper: (field, accessor) =>
          phoneDisplay(field[accessor]) || "",
        cell: { component: PhoneCell },
      },
      {
        id: "6",
        header: "Agency Name",
        accessor: "Agency.name",
        component: SortableHeader,
        csvMapper: (field, accessor) =>
          field["Agency"] ? field["Agency"].name : "",
        comparator: stringComparator,
        cell: { component: TextCell },
      },
      {
        id: "7",
        header: "Division",
        accessor: "division",
        component: TextHeader,
        csvMapper: (field, accessor) =>
          field[accessor] && field[accessor].name,
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) =>
            field[accessor] && field[accessor].name,
        },
      },
      ...(!isHandleHidden(currentCard.title, [
        "All Realtors",
        "YOY Sales",
      ])
        ? [
          {
            id: "8",
            header: "YOY Registrations",
            accessor: "registration_count",
            component: TextHeader,
            cell: { component: TextCell },
          },
        ]
        : []),
      ...(!isHandleHidden(currentCard.title, [
        "All Realtors",
      ])
        ? [
          {
            id: "9",
            header: "YOY Sales",
            accessor: "sales_count",
            component: TextHeader,
            sortable: false,
            cell: { component: TextCell },
          },
        ]
        : []),
      {
        id: "10",
        header: "Listing Agent",
        accessor: "is_listing_agent_of_sb",
        component: SortableHeader,
        csvMapper: (field, accessor) => (field[accessor] ? true : false),
        comparator: boolComparator,
        cell: { component: CheckboxCell },
      },
    ],
    [currentCard.title]
  );

  /**
   * Array of objects containing different actions which can be performed on row hover.
   * @constant
   * @memberof module:RealtorTableModule
   */
  const hoverActions = useMemo(
    () => [
      {
        tooltip: "Edit",
        icon: FaPen,
        component: ActionCell,
        action: ({ isSelected, uuid, ...row }) => {
          editHandler(row);
        },
      },
      {
        tooltip: "Delete",
        icon: FaTrashAlt,
        component: ActionCell,
        action: ({ id }) => {
          deleteHandler(id);
        },
      },
    ],
    []
  );
  /**
   * This is an object that defines table title and mass actions.
   *
   * @constant
   * @memberof module:RealtorTableModule
   */
  const tableOptions = {
    title: currentCard.title,
    massActions: {
      render: (getSelectedRows) => {
        let emails = getRealtorsSubscribedEmails(getSelectedRows());
        let ids = getSelectedRows().map((x) => x.id);
        return <RealtorsMassUpdateItems emails={emails} selectedIds={ids} />;
      },
    },
    FilterItems: RealtorFilterForm,
  };
  const deleteConfirmationBody = () => {
    return <DeleteDialogBox setFunc={setNote} />;
  };

  return (
    <div>
      <Dialog
        show={showConfirmationDialog}
        onHide={cancelDelete}
        title="Delete Confirmation"
        body={deleteConfirmationBody()}
        click={() => handleDelete()}
        clickname="YES"
        closename="NO"
      />

      <FadeIn show={render}>
        <div className="table-realtors">
          <TableView
            columns={columns}
            data={Realtors}
            tableOptions={tableOptions}
            loading={loading}
            hoverActions={hoverActions}
            userPageCount={userPageCount}
            userTotalCount={
              !loading && data.getRealtors ? data.getRealtors.total_count : null
            }
            fetchData={fetchData}
            currentPage={state.pageNum}
            pageSize={state.pageSize}
            filter={getFilters(state)}
            searchTerm={state.filter.text}
            sortColumn={state.order}
            controlled={true}
          />
        </div>
      </FadeIn>
      {!render && <CustomLoader />}

      <Drawer show={showSideBar} toggleSideBar={toggleSideBar}>
        <CreateEditRealtor
          realtor={selectedRealtor}
          onSubmit={() => {
            toggleSideBar();
            props.update();
          }}
          close={() =>
            dispatch({ type: "UPDATE", payload: { showSideBar: false } })
          }
        />
      </Drawer>
    </div>
  );
};
export function getEmails(data) {
  let emails = "";
  data &&
    data.forEach((x) => {
      x.Realtor_Other_Emails.length > 0 &&
        x.Realtor_Other_Emails.forEach((other_emails, index) => {
          if (index === x.Realtor_Other_Emails.length - 1)
            emails = emails + other_emails.email_address;
          else emails = emails + other_emails.email_address + "%3B%20";
        });
      emails = [emails, x.primary_email].filter(Boolean).join("%3B%20");
    });

  return emails;
}


export const getRealtorsSubscribedEmails = (data) => {
  let emails = "";
  data &&
    data.forEach((x) => {
      x.Realtor_Other_Emails.length > 0 &&
        x.Realtor_Other_Emails.forEach((other_emails, index) => {
          if (index === x.Realtor_Other_Emails.length - 1)
            emails = emails + other_emails.email_address;
          else emails = emails + other_emails.email_address + "%3B%20";
        });
      emails = [emails, x.primary_email_status && x.primary_email].filter(Boolean).join("%3B%20");
    });

  return emails;
}

export { RealtorsTable as default };
