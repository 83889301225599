import gql from "graphql-tag";

const GET_CSM_OUTLOOK_MEETINGS = gql`
  query getCsmOutlookMeetings($filter: getOutlookMeetingsFilter!, $start_dateTime: DateTime!, $end_dateTime: DateTime!) {
    getCsmOutlookMeetings(filter: $filter, start_dateTime: $start_dateTime, end_dateTime: $end_dateTime) {
      id
      first_name
      last_name
      email_address
      image_url
      outlookMeetings {
          subject
          start_dateTime
          end_dateTime
          isAllDay
          showAs
      }
    }
  }
`;

export default GET_CSM_OUTLOOK_MEETINGS;
