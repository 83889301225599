import gql from "graphql-tag";

const GET_LEADS_BY_FILTER = gql`
  query getLeadsByFilter(
    $limit: Int
    $pageNum: Int
    $filter: LeadFilter
    $search: String
    $sort: [String]
  ) {
    getLeads(
      limit: $limit
      pageNum: $pageNum
      filter: $filter
      search: $search
      sort: $sort
    ) {
      Leads {
        id
        customer_id
        first_name_1
        last_name_1
        primary_email_1
        secondary_emails_1
        cell_phone_1
        first_name_2
        last_name_2
        primary_email_2
        secondary_emails_2
        primary_email_1_status
        primary_email_2_status
        cell_phone_2
        street_address
        city
        osc_notes
        form_submissions{
          id
          interested_in
          other_data
          response_phone
          response_sms
          response_email
          created_at
          updated_at
          responded_at
          response_medium
          domain
          url
          lot
          form_type
          url
          community{
            name
            is_active
          }
          responder{
            first_name
            last_name
          }
          division {
            id
            name
          }
        }
        realtor{
          id
          first_name
          last_name
          primary_email
          primary_email_status
          cell_phone
        }
        customer_origination_area {
          id
          name
        }
        state {
          id
          name
        }
        zip
        other_notes
        quality_score
        engagement_score
        communities {
          id
          name
          is_active
          division_id
        }
        divisions {
          id
          name
        }
        notes {
          id
          description
          createdAt
          updatedAt
          creator {
            id
            first_name
            last_name
          }
          note_attachment {
            id
            file_path
            createdAt
            updatedAt
          }
        }
        appointments {
          id
          title
          details
          done
          start_datetime
          end_datetime
          zoom_meeting_link
          send_email
          createdAt
          creator {
            first_name
            last_name
          }
          Appointment_Type {
            id
            name
          }
          csm {
            id
            first_name
            last_name
            is_active
          }
          community {
            id
            name
            is_active
          }
          division {
            id
            name
            timezone
          }
        }
        phone_calls {
          phone_call_id
          session_id
          call_details
          call_duration
          call_datetime
          attachments {
            id
            name
            type
            download_url
           }
          call_type {
            id
            name
          }
          creator{
            first_name
            last_name
          }
          recording
        }
        follow_ups {
          id
          title
          details
          done
          createdAt
          creator {
            first_name
            last_name
          }
          csm {
            id
            first_name
            last_name
            is_active
          }
          community{
            id
            name
            is_active
          }
          follow_up_datetime
        }
        mailchimp_emails {
          mailchimp_email_id
          datetime
          subject
          email_addresses
          email_from
          content_url
          count
        }
        emails{
          id
          subject
          body
          body_preview
          date_time
          hasAttachments
          email_type
          to_recipients
          cc_recipients
          bcc_recipients
          isIndividual
          csms{
            id
            first_name
            last_name
            email_address
            cell_phone
            role
            createdAt
            updatedAt
            is_active
          }
        }
        lead_classification_filters {
          id 
          name
        }
        created_at
        updated_at
      }
      total_count
    }
  }
`;

export default GET_LEADS_BY_FILTER;
