import React, { useContext, useState } from "react";
import { Accordion, Card, useAccordionToggle, AccordionContext } from "react-bootstrap";
import "./style.css";

const CustomToggle = ({ eventKey, callback, children, ...props }) => {
  const currentEventKey = useContext(AccordionContext)

  const toggleOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey !== currentEventKey)
  )

  return (
    <Card.Header
      className="accordion-title"
      style={{ cursor: "pointer", ...props.style }}
      onClick={toggleOnClick}
    >
      {children}
    </Card.Header>
  )
}

/**
 * Accordions use Card components to provide styling of the Accordion components. It uses AccordionToggle to provide a button that switches between each AccordionCollapse component.
 * @param {Object} props Component props
 * @param {JSX.Element} props.component Child component containing toggle/collapse logic and view
 * @param {Object} props.style Style object for Accordion Toggle
 * @param {Boolean} props.toggleAction Specifies whether we need expansion information or not
 */
const AccordionView = (props) => {
  const { component: Component } = props
  const [isExpanded, setIsExpanded] = useState(false)
  const [currEventKey, setCurrEventKey] = useState("0")

  let index = 0
  return (
    <Accordion className="notes-card" defaultActiveKey={currEventKey}>
      <Component
        {...props}
        isExpanded={props.toggleAction && isExpanded}
        render={(toggle, collapse) => {
          index += 1
          isExpanded && setCurrEventKey(index.toString())

          return (
            <div key={index} >
              <CustomToggle
                eventKey={index.toString()}
                style={props.style}
                callback={props.toggleAction && setIsExpanded}
              >
                {toggle}
              </CustomToggle>

              {collapse &&
                <Accordion.Collapse
                  eventKey={index.toString()}
                  className="accordion-body"
                >
                  {collapse}
                </Accordion.Collapse>
              }
            </div>
          );
        }}
      />
    </Accordion>
  );
};
export default AccordionView;
