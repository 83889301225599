import gql from 'graphql-tag';

const GET_AVAILABLE_HOMESITES = gql`
query  getAvailableHomesitesById($community_ids: [Int!]){
  getAvailableHome_Sites(community_ids: $community_ids) {
  	id
    name
  }
 }
`;
export default GET_AVAILABLE_HOMESITES;

