import React, { useEffect, useMemo, useContext } from "react";
import reducer, { getInitialState } from "../all-registrations-reducer";
import { DashboardContext } from "../../../context";
import { RealtorsMassUpdateItems } from "../mass-update-items";
import FadeIn from "../../../components/fade-in";
import CustomLoader from "../../../components/custom-loader";
import AllRegistrationFilterForm from "../all-registraton-filter-form";
import _ from "lodash";
import TableView, {
  SortableHeader,
  TextCell,
  TextHeader,
  EmailCell,
  PhoneCell,
  TextCellWithMapper,
  stringComparator,
  boolComparator,
  CheckboxCell,
  DetailLinkCell
} from "../../../components/new-table";
import {
  getUpdatedParams,
  getCardParams,
  phoneDisplay,
  getSubscribedEmails,
  getAllEmails
} from "../../../utils/helpers";
import routeConstants from "../../layout/routes";

/** @module AllRegistrationsTableModule */

/**
 * This component is a table used to render all registrations card table. It contains pagination, searchbar, 
 * filter, mass update and export to csv options. It shows multiple columns displaying basic information 
 * related to registrations. Table uses reducer to maintain its state. 
 * It also accesses store to check if table parameters are already stored or not.
 *
 * @param {Object} props it contains all the queries in the conatiner as props
 * @param {Object} props.data it contains array of YOY sales or YOY registration to be shown on table
 * @param {Object} props.getTableParams it contains cards info stored inside cache
 * @returns {JSX.Element} It returns jsx containing the table
 */
export const AllRegistrationsTable = ({
  data,
  getTableParams: { tableParams },
  ...props
}) => {

  const { currentCard } = useContext(DashboardContext);
  const cacheParams = getCardParams(currentCard.uniqueTitle, tableParams);
  const [state, dispatch] = React.useReducer(
    reducer,
    getInitialState({
      loading: data.loading,
      cache: cacheParams,
    })
  );
  const { render, userPageCount, pageSize, registrations, loading } = state;

  useEffect(() => {
    if (!cacheParams) {
      dispatch({ type: "RESET", payload: { render: false } });
    } else {
      dispatch({
        type: "UPDATE",
        payload: { ...cacheParams, pageSize: cacheParams.limit, render: false },
      });
    }
  }, [props.currentCard.uniqueTitle]);

  /**
   * Either set the data in state or set the loading to inform the table what to render.
   */
  useEffect(() => {
    if (
      !data.loading &&
      data.getAllRealtorRegistrations &&
      data.getAllRealtorRegistrations.realtors
    ) {
      dispatch({
        type: "UPDATE",
        payload: {
          render: true,
          registrations: data.getAllRealtorRegistrations.realtors,
          loading: false,
          userPageCount: Math.ceil(
            data.getAllRealtorRegistrations.total_count / pageSize
          ),
        },
      });
    }
    if (data.loading) {
      dispatch({ type: "UPDATE", payload: { loading: data.loading } });
    }
  }, [data]);

  /**
   * It is a callback function which is called on any table change like: pagination, page-size, filters, search term.
   * Table passes its current state so that we can update the table parameters in store and state, which are used in
   * refetching the data with correct parameters.
   *
   * @function
   * @inner
   * @memberof module:AllRegistrationsTableModule
   * @see {@link module:AllRegistrationsTableModule~AllRegistrationsTable}
   */
  const fetchData = React.useCallback((tableProps) => {
    if (loading) {
      return;
    }

    const pageCount = Math.ceil(
      data.getAllRealtorRegistrations.total_count / tableProps.pageSize
    );

    const variables = {
      filter: {
        ...tableProps.filter,
      },
      search: tableProps.searchTerm,
      limit: tableProps.pageSize,
      pageNum: tableProps.currentPage,
      sort: tableProps.sortColumn ? tableProps.sortColumn : state.sort,
    };

    if (
      tableProps.searchTerm !== state.search ||
      !_.isEqual(variables.filter, state.filter)
    ) {
      variables.pageNum = 0;
    }
    if (tableProps.currentPage > pageCount - 1) {
      variables.pageNum = pageCount ? pageCount - 1 : 0;
    }
    dispatch({
      type: "UPDATE",
      payload: { ...variables, pageSize: tableProps.pageSize, loading: true },
    });

    if (pageCount !== userPageCount) {
      dispatch({
        type: "UPDATE",
        payload: { userPageCount: pageCount },
      });
    }

    props
      .setTableParams({
        variables: {
          cardsParams: getUpdatedParams(tableParams.cardsParams, {
            cardTitle: currentCard.uniqueTitle,
            params: variables,
          }),
        },
      })
      .then((res) => {
        dispatch({ type: "UPDATE", payload: { loading: false } });
      });
  });

  /**
   * This defines columns of the table. It has multiple columns, 
   * displaying information of all registrations.
   * @constant
   * @memberof module:AllRegistrationsTableModule
   */
  const columns = useMemo(
    () => [
      {
        id: "0",
        header: "First Name",
        accessor: "first_name",
        component: SortableHeader,
        comparator: stringComparator,
        cell: {
          component: DetailLinkCell,
          path: () => routeConstants.REALTOR_DETAIL,
        },
      },
      {
        id: "1",
        header: "Last Name",
        accessor: "last_name",
        component: SortableHeader,
        comparator: stringComparator,
        cell: {
          component: TextCell,
        },
      },
      {
        id: "2",
        header: "Email",
        accessor: "primary_email",
        component: SortableHeader,
        comparator: stringComparator,
        cell: {
          component: EmailCell,
          check: "primary_email_status",
          callback: getAllEmails,
        },
      },
      {
        id: "3",
        header: "Cell Phone",
        accessor: "cell_phone",
        component: TextHeader,
        csvMapper: (field, accessor) => phoneDisplay(field[accessor]) || "",
        cell: { component: PhoneCell },
      },
      {
        id: "7",
        header: "Division",
        accessor: "division",
        component: TextHeader,
        csvMapper: (field, accessor) =>
          field[accessor] && field[accessor].name,
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) =>
            field[accessor] && field[accessor].name,
        },
      },
      {
        id: "6",
        header: "Agency Name",
        accessor: "Agency.name",
        component: SortableHeader,
        csvMapper: (field) => field["Agency"]?.name ?? "",
        comparator: stringComparator,
        cell: { component: TextCell },
      },
      {
        id: "10",
        header: "Listing Agent",
        accessor: "is_listing_agent_of_sb",
        component: SortableHeader,
        csvMapper: (field, accessor) => (field[accessor] ? true : false),
        comparator: boolComparator,
        cell: { component: CheckboxCell },
      },
    ]
  );

  /**
   * This is an object that defines table title, and massActions and filters for the table.
   *
   * @constant
   * @memberof module:AllRegistrationsTableModule
   */
  const tableOptions = {
    title: currentCard.uniqueTitle,
    massActions: {
      render: (getSelectedRows) => {
        let emails = getSubscribedEmails(getSelectedRows());
        return <RealtorsMassUpdateItems emails={emails} />;
      },
    },
    FilterItems: AllRegistrationFilterForm,
  };

  return (
    <div>
      <FadeIn show={render}>
        <div className="table-realtors">
          <TableView
            columns={columns}
            data={registrations}
            tableOptions={tableOptions}
            loading={loading}
            userPageCount={userPageCount}
            userTotalCount={
              !loading && data.getAllRealtorRegistrations
                ? data.getAllRealtorRegistrations.total_count
                : null
            }
            fetchData={fetchData}
            currentPage={state.pageNum}
            pageSize={state.pageSize}
            filter={state.filter}
            searchTerm={state.search}
            sortColumn={state.sort}
            controlled={true}
          />
        </div>
      </FadeIn>
      {!render && <CustomLoader />}
    </div>
  );
};
