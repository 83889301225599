import React from "react";
import { Form, Field, withFormik, FieldArray } from "formik";
import { ButtonToolbar } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";

import SelectField from "../../../components/fields/select-field";
import CustomButton from "../../../components/custom-button/index";
import ErrorFocus from "../../../components/error-focus/error-focus";
import { getActiveCommunities, isNotNULL } from "../../../utils/helpers";
import { marketingOnlyToProspectForm, marketingOnlyToProspectSchema } from "./marketing-only-convert-schema";
import { DefaultQuery } from "../../../graphql/default-query";
import { GET_CSM } from "../../../graphql/queries";
import _ from "lodash";


/**
 * This component is used to render marketing only to prpspect form. It contains couple of select fields.
 * @param {Object} props 
 */
const MarketingOnlyToProspectConvertForm = ({ values, initialValues, ...props }) => {
  let allCommunities =
    props.getCommunities.getAllCommunities &&
    props.getCommunities.getAllCommunities.filter(item => {
      return !props.marketingOnlyData.communities.find(Item => {
        return item.id === Item.id;
      });
    });

  return (
    <Form className={"form-horizontal"}>
      <div className="form_TitleContainer">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ margin: "0 1.1rem" }}
        >
          <div className="pl-1">
            <div className="form_Title">Marketing Only Conversion</div>
            <div className="form_TitleBig">
              <div className="d-flex">
                <h2
                  style={{
                    position: "relative",
                    paddingRight: "0.5em",
                    marginTop: "4px"
                  }}
                >
                  {`${props.marketingOnlyData.first_name_1} ${props.marketingOnlyData.last_name_1}`}
                </h2>
              </div>
            </div>
          </div>

          <div className="d-flex">
            <ButtonToolbar>
              <CustomButton
                color="black"
                className={`form_CancelButton ml-0`}
                onClick={props.close}
                btnValue="CANCEL"
              />
              <CustomButton
                className={`form_SubmitButton`}
                type="submit"
                disabled={props.isSubmitting}
                btnValue={"CONVERT"}
              />
            </ButtonToolbar>
          </div>
        </div>
      </div>
      <div className="form_body">
        <div className="form-row">
          <div className="form-section">
            <h5>Community(s)</h5>
          </div>
        </div>
        <FieldArray
          name="input"
          render={arrayHelpers => (
            <div>
              {values.input &&
                _.isArray(values.input) &&
                values.input.map((pr, index) => (
                  <div className="form-community" key={index}>
                    <div className="row form_deleteButtonRow">
                      <CustomButton
                        className="icon-btn btn-icon"
                        onClick={() =>
                          values.input.length > 1 && arrayHelpers.remove(index)
                        }
                      >
                        <FaTimes size={14} style={{ color: "white" }} />
                      </CustomButton>
                    </div>
                    <div className="form-row">
                      <div className="col-md-6">
                        <Field
                          name={`input[${index}].community_id`}
                          label="Community"
                          id="input-community"
                          required={true}
                          disabled={!props.marketingOnlyData?.communities?.length && !allCommunities?.length}
                          component={SelectField}
                        >
                          <option value="">Select...</option>
                          {props.marketingOnlyData.communities?.length > 0 &&
                            <optgroup label="Marketing Only Communities">
                              {
                                props.marketingOnlyData.communities.map((community, communityIndex) => {
                                  let communityAlreadySelected = false;
                                  for (let i = 0; i < values.input.length; i++) {
                                    if (
                                      i !== index &&
                                      values.input[i].community_id === community.id
                                    ) {
                                      communityAlreadySelected = true;
                                      break;
                                    }
                                  }

                                  return community.is_active ? (
                                    <option
                                      key={communityIndex}
                                      value={community.id}
                                      disabled={communityAlreadySelected}
                                      className={
                                        communityAlreadySelected ? "my-options" : ""
                                      }
                                    >
                                      {community.name}
                                    </option>
                                  ) : null;
                                })
                              }
                            </optgroup>
                          }
                          {allCommunities?.length &&
                            <optgroup label="Other Communities">
                              {
                                allCommunities.map((community, communityIndex) => {
                                  let communityAlreadySelected = false;
                                  for (let i = 0; i < values.input.length; i++) {
                                    if (
                                      i !== index &&
                                      values.input[i].community_id === community.id
                                    ) {
                                      communityAlreadySelected = true;
                                      break;
                                    }
                                  }

                                  return community.is_active ? (
                                    <option
                                      key={communityIndex}
                                      value={community.id}
                                      disabled={communityAlreadySelected}
                                      className={
                                        communityAlreadySelected ? "my-options" : ""
                                      }
                                    >
                                      {community.name}
                                    </option>
                                  ) : null;
                                })
                              }
                            </optgroup>
                          }
                        </Field>
                      </div>
                      <div className="col-md-6">
                        <Field
                          name={`input[${index}].csm_id`}
                          label="CSM"
                          id="csm"
                          component={SelectField}
                        >
                          {isNotNULL(pr.community_id) ? (
                            <DefaultQuery
                              query={GET_CSM}
                              variables={{
                                community_ids: pr.community_id
                                  ? [pr.community_id]
                                  : []
                              }}
                              fetchPolicy="network-only"
                            >
                              {({ data, loading, error }) => {
                                let defaultValue = loading
                                  ? "Loading ..."
                                  : error
                                    ? error.message
                                    : "Select...";

                                return (
                                  <>
                                    <option value="">{defaultValue}</option>
                                    {data && isNotNULL(data.getAllCSMs)
                                      ? data.getAllCSMs.map((csm, index) => (
                                        <option key={index} value={csm.id}>
                                          {csm.first_name} {csm.last_name}
                                        </option>
                                      ))
                                      : null}
                                  </>
                                );
                              }}
                            </DefaultQuery>
                          ) : (
                            <option value="">Select...</option>
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>
                ))}
              <div className="form-addCommunity mb-3">
                <CustomButton
                  color="black"
                  btnValue="Add Community"
                  disabled={
                    values.input.length === (getActiveCommunities(props.marketingOnlyData.communities)?.length + getActiveCommunities(allCommunities)?.length)
                  }
                  className={"form_addCommunityButton"}
                  onClick={() => arrayHelpers.push(initialValues.input[0])}
                />
              </div>
            </div>
          )}
        />
      </div>
      <ErrorFocus />
    </Form>
  );
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: () => { return { ...marketingOnlyToProspectForm } },
  handleSubmit: (values, { props, setSubmitting }) => {
    props.convertMarketingOnlyToProspect({
      variables: {
        input: {
          marketing_only_id: props.marketingOnlyId,
          marketingOnlyToProspect: values.input
        }
      }
    }).then(res => {
      setSubmitting(false);
      if (res.data.convertMarketingOnlyToProspect.code === 200) {
        props.onSubmit(res.data.convertMarketingOnlyToProspect.message);
      } else if (res.data.convertMarketingOnlyToProspect.code === 449) {
        props.setNotificationProps({
          variables: {
            open: true,
            message: res.data.convertMarketingOnlyToProspect.message
          }
        })
      }
    });
  },
  validationSchema: marketingOnlyToProspectSchema,
  displayName: "marketing-only-to-lead-form"
})(MarketingOnlyToProspectConvertForm)