import moment from "moment";
import { isObject, getObjectRowValue, getDescendantProp } from "./utils";

export const text = (rows, id, filterValue) => {
  rows = rows.filter(row => {
    const rowValue = row[id];
    return String(rowValue)
      .toLowerCase()
      .includes(String(filterValue).toLowerCase());
  });
  return rows;
};

export const exactText = (rows, id, filterValue) => {
  return rows.filter(row => {
    const fieldData = getDescendantProp(row, id)
    const rowValue = isObject(fieldData)
      ? getObjectRowValue(fieldData, filterValue)
      : fieldData;
    const filter = isObject(filterValue)
      ? getObjectRowValue(filterValue, filterValue)
      : filterValue;
    return rowValue !== undefined
      ? String(rowValue).toLowerCase() === String(filter).toLowerCase()
      : true;
  });
};

export const exactTextCase = (rows, id, filterValue) => {
  return rows.filter(row => {
    const rowValue = row[id];
    return rowValue !== undefined
      ? String(rowValue) === String(filterValue)
      : true;
  });
};

export const boolText = (rows, id, filterValue) => {
  return rows.filter(row => {
    let rowValue;
    if (row[id]) {
      rowValue =
        row[id]["__typename"] === "Red_Flag" ? row["description"] : rowValue;
    } else {
      rowValue = row[id];
    }
    return rowValue !== undefined ? !filterValue && rowValue !== "" : true;
  });
};

export const includes = (rows, id, filterValue) => {
  return rows.filter(row => {
    const rowValue = row[id] || "";
    return rowValue.includes(filterValue);
  });
};

export const includesAll = (rows, id, filterValue) => {
  return rows.filter(row => {
    const rowValue = row[id];
    return filterValue.every(val => rowValue.includes(val));
  });
};

export const exact = (rows, id, filterValue) => {
  return rows.filter(row => {
    const rowValue = row[id];
    return rowValue === filterValue;
  });
};

export const equals = (rows, id, filterValue) => {
  return rows.filter(row => {
    const rowValue = row[id];
    return rowValue === filterValue;
  });
};

export const greaterThan = (rows, id, filterValue) => {
  return rows.filter(row => {
    const rowValue = row[id];
    return rowValue > filterValue;
  });
};
export const lessThan = (rows, id, filterValue) => {
  return rows.filter(row => {
    const rowValue = row[id];
    return rowValue < filterValue;
  });
};

export const between = (rows, id, filterValue) => {
  let [min, max] = filterValue || [];

  min = typeof min === "number" ? min : -Infinity;
  max = typeof max === "number" ? max : Infinity;

  if (min > max) {
    const temp = min;
    min = max;
    max = temp;
  }

  return rows.filter(row => {
    const rowValue = row[id];
    return rowValue >= min && rowValue <= max;
  });
};

export const betweenDates = (rows, id, filterValue) => {
  let [min, max] = filterValue || [];

  min = min? moment(min) : null;
  max = max? moment(max).add(1, "days") : null;

  return rows.filter(row => {
    let rowValue = row[id];
    if (Array.isArray(rowValue)) {
      rowValue = rowValue.length > 0 ? (rowValue = rowValue[0]) : null;
    }
    rowValue =
      (isObject(rowValue) && getObjectRowValue(rowValue, rowValue)) || rowValue;

    if (!rowValue) return false;
    return min && max
      ? moment(rowValue).isBetween(min, max)
      : (min && moment(rowValue).isSameOrAfter(min)) ||
      moment(rowValue).isBefore(max);
  });
};

export const filterForList = (rows, id, filterValue) => {
  return rows.filter(row => {
    return row[id].find(val => {
      const rowValue = isObject(val)
        ? getObjectRowValue(val, filterValue)
        : val;
      const filter = isObject(filterValue)
        ? getObjectRowValue(filterValue, filterValue)
        : filterValue;
      return rowValue !== undefined
        ? String(rowValue).toLowerCase() === String(filter).toLowerCase()
        : true;
    });
  });
};
