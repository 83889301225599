import gql from "graphql-tag";

const GET_CUSTOMER_TO_MERGE = gql`
  query getCustomerToMerge($filter: CustomerMergeFilter) {
    getCustomerToMerge(filter: $filter) {
      id
      lead_communities {
        id
        name
      }
      prospect_communities {
        id
        name
      }
      homeowner_communities {
        id
        name
      }
    }
  }
`;

export default GET_CUSTOMER_TO_MERGE;
