import * as Yup from "yup";
import * as errorMessages from "../../utils/form-errors";
import * as validations from "../../utils/common-validations";

export const webForm = {
    firstname: "",
    lastname: "",
    email: null,
    phone: null,
    formatted_phone: ""
};

Yup.addMethod(Yup.mixed, "validateCell", function(ref, message) {
    const msg = message;
    return this.test("equalTo", msg, function(value) {
      let refValue = this.resolve(ref);
      return (
        (refValue && /^[0-9]{10}$/.test(refValue)) ||
        refValue === null ||
        refValue === undefined ||
        refValue === ""
      );
    });
  });

export const webformSchema = Yup.object().shape({
    firstname: validations.name.required(errorMessages.REQUIRE_FIELD),
    lastname: validations.name.required(errorMessages.REQUIRE_FIELD),
    email: Yup.string().when(["formatted_phone"],{
        is: formatted_phone => !formatted_phone,
        then: validations.email
        .required(errorMessages.REQUIRE_FIELD),
        otherwise: validations.email
    }),
    formatted_phone: Yup.string().when(["email"],{
        is: email => !email,
        then: Yup.string()
        .nullable()
        .required(errorMessages.REQUIRE_FIELD),
        otherwise: Yup.string()
        .nullable()
    })
},["email", "formatted_phone"]);
