import gql from 'graphql-tag';

const ADD_MARKETING_ONLY_DELETE_REQUEST = gql` 
  mutation addMarketingOnlyDeleteRequests($input: AddMarketingOnlyDeleteRequestInput!) {
    addMarketingOnlyDeleteRequests(input: $input) {
      message
      code
    }
  }
`;

export default ADD_MARKETING_ONLY_DELETE_REQUEST;