import gql from 'graphql-tag';

const UPDATE_DIVISION_INFO = gql` 
  mutation updateDivisionInformation($input: DivisionInformationInput!) {
    updateDivisionInformation(input: $input) {
      message
      code
    }
  }
`;

export default UPDATE_DIVISION_INFO