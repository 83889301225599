import React, { useState } from "react";
import { getIssueEmail, inActiveMapper, formatDataForCalendar } from "../../../utils/helpers";
import { UpdateLeadActivityContainer } from "../../common-lead-prospect-forms/activity-container";
import { LEAD } from "../../../utils/constants"
import LoadingWrapper from "../../../components/loading-wrapper";
import LeadDetailSelectBar from "./lead-detail-select-bar";
import LeadDetailView from "./lead-details-card";
import LeadCallButton from "./lead-call-button";
import Calendar from "../../../components/calendar/calendar";
import CalendarPopover from "../../common-lead-prospect-details/calendar-popover";
import NoteReminderView from "../../common-lead-prospect-details/notes-card";
import PastActivitiesView from "../../common-lead-prospect-details/past-activities";
import NoDataPage from "../../no-data-page/no-data-page";
import logo from "../../../images/graphic-no-lead.png";
import ListPopup from "../../../components/list-popup/list-popup";
import ActivityButton from "../../../components/activity-button";
import "./lead-details.css";

/**
 * This component is used to render details page of the lead. It is divided into four main parts. On the top
 * it renders {@link LeadDetailSelectBar} component to display lead's community and his name and convert to prospect and schedule an appointment buttons. Right
 * below it, {@link BigCalendar} is displayed and next to it {@link LeadDetailView} card is rendered ti show basic 
 * information of the lead and to edit it as well. Right below Big calendar we have past-activities view and notes list view using
 * {@link NoteReminderView}. If the lead is not available, no data page is displayed instead of details page. 
 * while the data is loading, a full page loader is rendered. 
 * @param {Object} props queries from the container are received as props 
 */
const LeadDetailsView = ({
  getLeadDetail,
  ...props
}) => {
  const [activity, setActivity] = useState(null);
  const [showEditActivity, setShowEditActivity] = useState(false);
  const [showEditPhoneCall, setShowEditPhoneCall] = useState(false);

  const submitHandler = (refetch, message) => {
    message && props.setNotificationProps({ variables: { open: true, message: message } });
    refetch();
  };
  const toggleShowActivityDrawer = activity => {
    if (activity.__typename === "PhoneCall" || activity.__typename === "Text") {
      setShowEditPhoneCall(!showEditPhoneCall);
      setActivity(activity);
    } else {
      setShowEditActivity(!showEditActivity);
      setActivity(activity);
    }
  };
  const hideEditDrawer = () => {
    setShowEditPhoneCall(false);
    setShowEditActivity(false);
    setActivity(null);
  };
  const handleCommunities = data => {
    return (
      <div className='communities-collection'>
        <span>:&nbsp;</span>
        <ListPopup
          data={data.communities}
          mapper={inActiveMapper}
        />
      </div>
    );
  };

  const leadsDetailView = (id, data, loading, refetch) => {
    let name =
      !loading ?
        data.getLeads?.Leads &&
        `${data.getLeads.Leads[0].first_name_1} ${data.getLeads.Leads[0].last_name_1}` : ''
    name +=
      !loading && data.getLeads.Leads[0].first_name_2
        ? ` and ${data.getLeads.Leads[0].first_name_2} ${data.getLeads.Leads[0].last_name_2}`
        : "";
    let community = !loading
      ? data.getLeads.Leads[0]?.communities?.length > 0
        ? handleCommunities(data.getLeads.Leads[0])
        : ""
      : "";

    return (
      <div>
        <LoadingWrapper
          loading={loading}
          component={
            <div className="col-padding col-md-12 col-12">
              <div
                className="root-row row"
                id="prospect-detail-header"
              >
                <LeadDetailSelectBar
                  name={name}
                  community={community}
                  id={id}
                  data={!loading ? data.getLeads.Leads[0] : null}
                  setNotificationProps={props.setNotificationProps}
                  {...props}
                />
              </div>
              <div className="row-container main-responsive col-xl-12 p-2 px-3 row">
                <div className="col-padding col-12 col-md-8 detail-view-main">
                  <div
                    style={{
                      padding: "1rem",
                      marginTop: "0.5rem",
                      border: "1px solid #191b1d",
                      borderRadius: "0.25rem"
                    }}
                  >
                    <Calendar
                      id={id}
                      data={Object.assign(
                        {},
                        !loading ? data.getLeads.Leads[0] : {},
                      )}
                      onSubmit={submitHandler.bind(this, refetch)}
                      {...props}
                      popover={CalendarPopover}
                      editComp={toggleShowActivityDrawer}
                      leadCalendar
                      type={LEAD}
                    />
                    <PastActivitiesView
                      type={LEAD}
                      pastActivitiesData={
                        !loading ? formatDataForCalendar(data.getLeads.Leads[0]) : null
                      }
                      id={id}
                      onSubmit={submitHandler.bind(this, refetch)}
                    />
                  </div>

                  <NoteReminderView
                    {...props}
                    id={id}
                    type={LEAD}
                    data={!loading ? data.getLeads.Leads[0] : null}
                    loading={loading}
                    onSubmit={submitHandler.bind(this, refetch)}
                  />
                </div>

                <div className="col-padding col-md-4 col-12 pb-2 pt-2 pr-0 pl-3 detail-view-side">
                  {showEditActivity && (
                    <ActivityButton
                      type={LEAD}
                      function="UPDATE"
                      item={activity}
                      close={hideEditDrawer}
                      data={!loading
                        ? data.getLeads.Leads[0]
                        : null}
                      {...props}
                      component={UpdateLeadActivityContainer}
                      onSubmit={submitHandler.bind(this, refetch)}
                    />
                  )}
                  {showEditPhoneCall && (
                    <LeadCallButton
                      close={hideEditDrawer}
                      id={id}
                      item={activity}
                      showButton={false}
                      function={"UPDATE"}
                      onSubmit={submitHandler.bind(this, refetch)}
                      {...props}
                    />
                  )}
                  <LeadDetailView
                    data={!loading ? data.getLeads.Leads[0] : null}
                    id={id}
                    {...props}
                    onSubmit={submitHandler.bind(this, refetch)}
                  />
                </div>
              </div>
            </div>
          }
        />
      </div>
    );
  };

  const { loading, refetch } = getLeadDetail;

  return !loading && !getLeadDetail.getLeads?.Leads?.length ? (
    <NoDataPage
      backgroundImage={logo}
      heading={"Lead not found!"}
      content={
        <div>
          <p className="message-reportBug">The resource for this lead was not found in our records.<br />If it's a bug, please report.<br /><a href={getIssueEmail()} target="_blank" rel="noopener noreferrer" className="btn btn-primary">Report</a></p>
        </div>
      }
    />
  ) : (
    leadsDetailView(
      parseInt(props.match.params.id),
      getLeadDetail,
      loading,
      refetch
    )
  );
};
export default LeadDetailsView;
