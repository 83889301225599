import * as React from "react";
import { FaFlag, FaReply, FaSyncAlt } from "react-icons/fa";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./dashboard-title.css";

/**
 * This is a header component used on multiple pages. It shows title followed by community (if required). Below the title, it shows subtitle followed by flag icon if prospect is flagged.
 * @param {Object} props
 * @param {String} props.title title of the header
 * @param {String} props.subtitle subtitle of the header
 * @param {JSX.Element} props.community renders community or multiple communities
 * @param {Object} props.flag contains description of why user is flagged
 * @param {Object} props.converted true if webform belongs to converted user
 * @param {Object} props.responded true if webform has been responded to
 */
const Header = props => (
  <div id="header-container">
    <div className="d-flex align-items-end">
      <div className="caption" id="header-title">{props.title} </div>
      <div>{props.community}</div>
    </div>
    {props.subtitle && (
      <h3
        id="header-sub-title"
        className="m-0 mt-1"
        style={{ position: "relative", paddingRight: "2em", fontSize: '15px' }}
      >
        {props.subtitle}
        {props.flag && (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip style={{ zIndex: 1099 }}>
                {props.flag.description}
              </Tooltip>
            }
          >
            <span>
              {<FaFlag style={{ color: "red", marginLeft: "8px" }} />}
            </span>
          </OverlayTrigger>
        )}
        {props.responded && (
          <span>
            {<FaReply style={{ color: "#80B602", marginLeft: "8px" }} />}
          </span>
        )}
        {props.converted && (
          <span>
            {<FaSyncAlt style={{ color: "#80B602", marginLeft: "8px" }} />}
          </span>
        )}
      </h3>
    )}
  </div>
);

export default Header;
