import React from "react";
import { compose, graphql } from "react-apollo";

import Drawer from "../../../hoc/Drawer";
import { GET_MARKETING_ONLY } from "../../../graphql/queries";
import CustomButton from "../../../components/custom-button";
import CreateEditMarketingOnlyWithMutation from "../forms/create-edit-marketing-only-container";
import { useState } from "react";


/**
* This component is used to render the edit marketing only drawer which contains the create edit marketing only form
* and it takes the data and prepares the appropriate format to be passed to the create edit form.
* @param {*} props
* @param {object} props.marketingOnlyData contains information of the marketing only when called from details page
* @param {object} props.data contains information of the marketing only when called from the table
* @param {function} props.close closes the drawer
* @param {boolean} props.show boolean that defines wether to show the drawer or not
* @param {number} props.id id of the marketing only
*/
const EditMarketingOnlyView = (props) => {
  const { id, marketingOnlyData, data } = props
  const [showSideBar, setShowSideBar] = useState(props.show)

  const toggleShowDrawer = () => {
    if (props.textButton) {
      setShowSideBar(!showSideBar)
    } else {
      props.close();
    }
  }

  const onSubmit = (message) => {
    setShowSideBar(false)
    if (!props.textButton) props.close();
    props.update(message);
  }

  /**
  * this function prepares the data to be passed to the marketing only form, it takes the marketing only information and puts it in an appropriate marketing only format.
  * @param {object} marketingOnlyData contains information of the marketing only
  * @method
  * @returns {object}
  */
  const formatData = (marketingOnlyData) => {
    let dataToReturn = (({
      first_name_1,
      last_name_1,
      primary_email_1,
      cell_phone_1,
      secondary_emails_1,
      first_name_2,
      last_name_2,
      primary_email_2,
      cell_phone_2,
      secondary_emails_2,
      city,
      state,
      zip,
      street_address,
      customer_origination_area,
      id,
      osc_notes,
      primary_email_1_status,
      primary_email_2_status,
      division
    }) => {
      return {
        first_name_1,
        last_name_1,
        primary_email_1: primary_email_1,
        disable_primary_email_1: !primary_email_1_status,
        cell_phone_1,
        secondary_emails_1,
        first_name_2,
        last_name_2,
        primary_email_2: primary_email_2,
        disable_primary_email_2: !primary_email_2_status,
        cell_phone_2,
        secondary_emails_2,
        city,
        customer_origination_area_id:
          (customer_origination_area && customer_origination_area.id) ||
          undefined,
        state_id: state && state.id ? state.id : undefined,
        zip,
        street_address,
        osc_notes,
        id,
        division_id: division ? division.id : null
      };
    })(marketingOnlyData);

    dataToReturn.Marketing_Only = (({
      communities,
      other_notes
    }) => {
      return {
        communities: communities && communities.filter(item => item.is_active).map(item => item.id),
        other_notes: other_notes
      };
    })(marketingOnlyData);

    dataToReturn.communities = marketingOnlyData.communities;
    return dataToReturn
  }

  return (
    <div className="edit-button">
      {props.textButton && (
        <CustomButton
          size="sm"
          color="dark"
          btnValue={props.btnValue}
          onClick={toggleShowDrawer}
        />
      )}
      <Drawer show={showSideBar} toggleSideBar={toggleShowDrawer}>
        {props.textButton ? (
          <CreateEditMarketingOnlyWithMutation
            close={toggleShowDrawer}
            data={formatData(marketingOnlyData)}
            id={id}
            editForm
            update={onSubmit}
            isDetails={props.isDetails}
            history={props.history}
            inActiveCommunities={marketingOnlyData.communities.filter(item => !item.is_active)}
          />
        ) : data && data.getMarketingOnly ? (
          <CreateEditMarketingOnlyWithMutation
            close={toggleShowDrawer}
            data={formatData(data.getMarketingOnly.Marketing_Only[0])}
            id={id}
            editForm
            update={onSubmit}
            inActiveCommunities={data.getMarketingOnly.Marketing_Only[0].communities.filter(item => !item.is_active)}
          />
        ) : null}
      </Drawer>
    </div>
  );
}

export default EditMarketingOnlyView;

export const EditMarketingOnlyViewWithData = compose(
  graphql(GET_MARKETING_ONLY, {
    options: props => {
      return {
        variables: { filter: { id: props.id } },
        fetchPolicy: "network-only"
      };
    }
  })
)(EditMarketingOnlyView);