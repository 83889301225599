import React from "react";
import { FaPaperclip } from "react-icons/fa";

import * as util from "../../utils/helpers";
// import "./style.css";

/**
 * @module PastActivityMassEmailsListViewModule
 */
/**
 * this component is used to display the mass emails in the past activities
 * @param {object} pastActivitiesData contains information related to the mass emails
 */
const PastActivityMassEmailsListView = (props) => {
  const { pastActivitiesData } = props;

  /**
   * this function renders the toggle part of the mass emails in past activities
   * @function
   * @inner
   * @memberof module:PastActivityMassEmailsListViewModule
   * @param {object} item contains information related to the mass email
   * @returns {JSX.Element}
   */
  const toggle = (item) => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        {/* <div>
          {util.getIconByEvent(item.typeTitle)}
          {util.get5WordsForTitle(item.subject)}
        </div> */}
        <div className="d-flex align-items-center">
          <div
            className="d-flex align-items-center"
            style={{ marginRight: "2px" }}
          >
            {util.getIconByEvent(pastActivitiesData.typeTitle)}
            <span style={{ fontSize: "12px", display: "d-flex" }}>
              {pastActivitiesData.typeTitle}:
            </span>
          </div>
          {util.get5WordsForTitle(item.subject)}
        </div>
        <div className="d-flex align-items-center  toggle-date-time">
          {util.formatActivityDate(item.date_time)}
        </div>
      </div>
    );
  };

  /**
   * this function renders the collapse part of the mass emails in past activities
   * @function
   * @inner
   * @memberof module:PastActivityMassEmailsListViewModule
   * @param {object} item contains information related to the mass email
   * @returns {JSX.Element}
   */
  const collapse = (item) => {
    let csmNAMES = item.csms.map((csm) => csm.first_name + " " + csm.last_name);
    return (
      <div className="accordion-body-inner">
        <div className="d-flex align-items-center text-muted mb-1">
          <span className="small mr-2">SUBJECT:</span>{" "}
          <span className="small">{item.subject}</span>
        </div>
        <div className="d-flex align-items-center text-muted mb-1">
          <span className="small mr-2">DATE:</span>{" "}
          <span className="small">
            {util.formatActivityDate(item.date_time)}
          </span>
        </div>
        <div className="d-flex align-items-center text-muted mb-1">
          <span className="small mr-2">TYPE:</span>{" "}
          <span className="small" style={{ textTransform: "capitalize" }}>
            {item.email_type}
          </span>
        </div>
        <div className="d-flex align-items-center text-muted mb-1">
          <span className="small mr-2">BY:</span>{" "}
          <span className="small">{csmNAMES.join(", ")}</span>
        </div>
        <div className="d-flex mt-3 flex-column">
          {item.hasAttachments && (
            <span>
              <FaPaperclip /> Attachments
            </span>
          )}
          <span className="small mb-2 text-muted">MESSAGE:</span>
          <span
            className="email-message"
            dangerouslySetInnerHTML={{
              __html: item.body,
            }}
          />
        </div>
      </div>
    );
  };
  return (
    <div className="notes-card">
      {props.render(
        toggle(pastActivitiesData.email),
        collapse(pastActivitiesData.email)
      )}
    </div>
  );
};
export default PastActivityMassEmailsListView;
