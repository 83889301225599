import gql from 'graphql-tag';

export const GET_USER = gql`
{
  getUser {
    user {
      role
      division {
        id
      }
    }
    communities {
      id
      name
      is_active
    }
    titleCustomizations {
      name
      value
    }
  }
}
`;

export const GET_ALL_USERS = gql`
  query getAllUsers($division_ids: [Int], $active_only: Boolean) {
    getAllUsers(division_ids: $division_ids, active_only: $active_only) {
      id
      first_name
      last_name
      role
      secondary_role
      roles
    }
  }
`
  ;