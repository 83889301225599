
import gql from 'graphql-tag';

const TASK_CHANGE_CSM = gql` 
  mutation transferAppointments_And_Followups($input: TransferAppointmentsAndFollowupsInput!) {
    transferAppointments_And_Followups(input: $input) {
      message
      code
    }
  }
`;

export default TASK_CHANGE_CSM;