import React from 'react'

import { GraphQLCreatableSelect } from "../../containers/creatable-select-container";
import { ALL_AGENCIES, ALL_REALTORS_NAMES } from '../../graphql/queries';

const valueResolver = ({ __typename, ...value }) => value

export const AgenciesCreatableSelect = props => (
  <GraphQLCreatableSelect
    query={ALL_AGENCIES}
    listPath="getAgenciesData"
    labelResolver="name"
    valueResolver={valueResolver}
    {...props}
  />
);
export const SearchableRealtorSelect = props => (
  <GraphQLCreatableSelect
    query={ALL_REALTORS_NAMES}
    listPath="getRealtorsData"
    labelResolver={realtor => `${realtor.first_name} ${realtor.last_name || ""}`}
    valueResolver={valueResolver}
    {...props}
  />
);


