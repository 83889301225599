import { graphql, compose } from "react-apollo";
import leadToProspectForm from "./lead-to-prospect-form";
import { CONVERT_LEAD_TO_PROSPECT } from "../../../graphql/mutations";
import { GET_COMMUNITIES,GET_CSM } from '../../../graphql/queries'

const LeadToProspectMutation = compose(
  graphql(CONVERT_LEAD_TO_PROSPECT,{ name: "convertLeadToProspect" } ),
  graphql(GET_COMMUNITIES, { name: "getCommunities" }),
  graphql(GET_CSM, { name: "getAllCSMs" }),
)(leadToProspectForm);

export default LeadToProspectMutation;
