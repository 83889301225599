
import gql from 'graphql-tag';

const ADD_PROSPECT_DELETE_REQUEST = gql` 
  mutation addProspectDeleteRequests($input: AddProspectDeleteRequestInput!) {
    addProspectDeleteRequests(input: $input) {
      message
      code
    }
  }
`;

export default ADD_PROSPECT_DELETE_REQUEST;