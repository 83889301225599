import gql from "graphql-tag";

export default gql`
  query GetRealtorClientRegistrations(
    $filter: RealtorClientRegistrationFilter
    $sort: [String]
    $search: String
    $limit: Int
    $pageNum: Int
  ) {
    getRealtorClientRegistrations(
      filter: $filter
      sort: $sort
      search: $search
      limit: $limit
      pageNum: $pageNum
    ) {
      realtor_client_registration {
        id
        client_name
        client_email
        client_phone
        client_name_2
        client_email_2
        client_phone_2
        realtor_name
        realtor_email
        realtor_phone
        realtor_note
        existing_realtor{
          id
          first_name
          last_name
          primary_email
          primary_email_status
          cell_phone
        }
        communities {
          id
          name
          is_active
          division_id
        }
        realtor_client_notes {
          id
          note
          created_at
        }
        brokerage_name
        type {
          id
          name
        }
        division{
          id
          name
        }
        created_at
      }
      total_count
    }
  }
`;
