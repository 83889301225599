import gql from 'graphql-tag';

const UPDATE_PROSPECT_PHONE_CALL = gql` 
  mutation updateProspectPhoneCall($input: UpdateProspectPhoneCallInput!,$phone_call_id:ID!) {
    updateProspectPhoneCall(input: $input,phone_call_id:$phone_call_id) {
      message
      code
    }
  }
`;

export default UPDATE_PROSPECT_PHONE_CALL;
