import gql from "graphql-tag";

const GET_CUSTOMER_LOG = gql`
  query getCustomerLogById($customer_id: Int) {
    getCustomerLogById(customer_id: $customer_id) {
      id
      first_name_1
      first_name_2
      last_name_1
      last_name_2
      primary_email_1
      primary_email_2
      secondary_emails_1
      secondary_emails_2
      home_phone
      cell_phone_1
      cell_phone_2
      street_address
      city
      zip
      Customer_Logs {
        id
        description
        created_at
        Csm {
          id
          first_name
          last_name
          is_active
        }
      }
    }
  }
`;
export default GET_CUSTOMER_LOG;
