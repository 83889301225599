import React, { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import {
  Dashboard,
  HomeOwnerDashboardView,
  LeadDashboardView,
  ProspectDashboardView,
  WebFormDashboard,
  RealtorsRegistrationDashboardView,
  ClientsRegistrationDashboardView,
  AgenciesDashboardView,
  routes,
  AdminCustomization,
  ConversionReportDashboard,
  ActivityReportDashboard,
  OscCommissionReportDashboard,
  ProspectDetailsView,
  LeadDetailsView,
  MarketingOnlyDetailsView,
  RealtorDetailsView,
  NotFound,
  EmailTemplatesView,
  WebFormOthersDashboard,
  ArchivedDetailsView,
  ArchiveUsersView,
  WebformDetailsView,
  AgencyDetailsView,
  VersionHistoryView,
  UsageDashboard,
  SubmissionUploadDashboard,
  MarketingOnlyDashboardView,
  RealtorsDashboardView
} from "../index";

import ErrorBoundary from "../../components/error-boundry/error-boundry";
import "./layout.css";
import {
  AuthenticatedPage,
  Auth,
  ProtectedPage,
  Unauthorized,
  ServerDownPage,
  ServerDown,
} from "../../rbac/rbac";
import HomeownerDetailsView from "../homeowner/details/homeowner-details-container";
// import Login from "../../rbac/login";

/**
 * This is a wrapper component which checks for all the authorization possibilities and decides whether to render
 * the component or server down page or the unauthorized page. It also wraps the whole application into error boundary
 * to catch all the errors.
 * @param {Array} reqs 
 * @param {Boolean} isUnauthorizedView 
 * @param  {*} rest 
 */
const routeComponent = (reqs, isUnauthorizedView, ...rest) => (Component) => {
  const WrappedComponent = (props) => {
    return (
      <ErrorBoundary {...props}>
        <ServerDownPage>
          {isUnauthorizedView ? (
            <Component {...props} {...rest} />
          ) : (
            <ProtectedPage location={props.location}>
              <AuthenticatedPage reqs={reqs} location={props.location}>
                <Component {...props} {...rest} />
              </AuthenticatedPage>
            </ProtectedPage>
          )}
        </ServerDownPage>
      </ErrorBoundary>
    );
  };
  return WrappedComponent;
};

/**
 * This component is responsible for routing of the entire application. 
 * @param {Object} props 
 */
export default function (props) {
  // const { roles } = props;
  const { isAuthenticated } = useContext(Auth);

  return (
    <main
      role="main"
      className={
        !isAuthenticated
          ? "col-md-12 p-0"
          : "main-container col-md-9 ml-sm-auto col-lg-10 p-0"
      }
    >
      <Switch>
        <Route
          exact
          path={[routes.HOME]}
          component={routeComponent()(Dashboard)}
        />
        <Route
          exact
          path={[routes.VERSION_HISTORY]}
          component={routeComponent()(VersionHistoryView)}
        />
        <Route
          exact
          path={[routes.WEB_FORM]}
          component={routeComponent()(WebFormDashboard)}
        />

        <Route
          exact
          path={[routes.PROSPECT_DASHBOARD]}
          component={routeComponent()(ProspectDashboardView)}
        />
        <Route
          exact
          path={[routes.WEB_FORM_OTHERS]}
          component={routeComponent(["sb.webformOthers.read"])(
            WebFormOthersDashboard
          )}
        />
        <Route
          exact
          path={routes.ARCHIVED_DETAILS + "/:id"}
          component={routeComponent(["sb.archiveUsers.read"])(
            ArchivedDetailsView
          )}
        />
        <Route
          exact
          path={[
            routes.LEAD_DASHBOARD,
            routes.NEW_LEADS_TODAY,
            routes.NEW_LEADS,
          ]}
          component={routeComponent()(LeadDashboardView)}
        />
        <Route
          exact
          path={routes.MARKETING_ONLY_DASHBOARD}
          component={routeComponent()(MarketingOnlyDashboardView)}
        />
        <Route
          exact
          path={routes.MARKETING_ONLY_DETAIL + "/:id"}
          component={routeComponent()(MarketingOnlyDetailsView)}
        />
        <Route
          exact
          path={routes.LEAD_DETAIL + "/:id"}
          component={routeComponent()(LeadDetailsView)}
        />
        <Route
          exact
          path={routes.PROSPECT_DETAIL + "/:id"}
          component={routeComponent()(ProspectDetailsView)}
        />
        <Route
          exact
          path={routes.WEB_FORM_DETAIL + "/:id"}
          component={routeComponent()(WebformDetailsView)}
        />
        <Route
          exact
          path={routes.REALTOR_DETAIL + "/:id"}
          component={routeComponent()(RealtorDetailsView)}
        />
        <Route
          exact
          path={routes.AGENCY_DETAIL + "/:id"}
          component={routeComponent()(AgencyDetailsView)}
        />
        <Route
          exact
          path={routes.HOME_OWNER_DETAIL + "/:id"}
          component={routeComponent()(HomeownerDetailsView)}
        />
        <Route
          path={routes.REALTOR_REGISTRATION}
          component={routeComponent()(RealtorsRegistrationDashboardView)}
        />
        <Route
          path={routes.CLIENT_REGISTRATION}
          component={routeComponent()(ClientsRegistrationDashboardView)}
        />
        <Route
          path={routes.REALTOR_DASHBOARD}
          component={routeComponent()(RealtorsDashboardView)}
        />
        <Route
          path={routes.AGENCIES_DASHBOARD}
          component={routeComponent()(AgenciesDashboardView)}
        />
        <Route
          path={routes.HOME_OWNER}
          component={routeComponent()(HomeOwnerDashboardView)}
        />
        <Route
          path={routes.ADMIN_CUSTOMIZATION}
          component={routeComponent(["sb.customizations.read"])(
            AdminCustomization
          )}
        />
        <Route
          path={routes.CONVERSION_REPORT}
          component={routeComponent()(
            ConversionReportDashboard
          )}
        />
        <Route
          path={routes.ACTIVITY_REPORT}
          component={routeComponent()(
            ActivityReportDashboard
          )}
        />
        <Route
          path={routes.OSC_COMMISSION_REPORT}
          component={routeComponent(["sb.oscReports.read"])(
            OscCommissionReportDashboard
          )}
        />
        <Route
          path={routes.USAGE_REPORT}
          component={routeComponent(["sb.usageReport.read"])(
            UsageDashboard
          )}
        />
        <Route
          path={routes.EMAIL_TEMPLATES}
          component={routeComponent(["sb.emailTemplates.read"])(
            EmailTemplatesView
          )}
        />
        {/* <Route exact path={[routes.LOGIN, routes.LOGIN + '/:token']} component={Login} /> */}
        <Route
          exact
          path={routes.UNAUTHORIZED}
          component={routeComponent(undefined, true)(Unauthorized)}
        />
        <Route
          path={routes.SUBMISSION_UPLOAD}
          component={routeComponent(["sb.submissionUpload.read"])(SubmissionUploadDashboard)}
        />
        <Route
          path={routes.ARCHIVE_USERS}
          component={routeComponent(["sb.archiveUsers.read"])(ArchiveUsersView)}
        />
        <Route path={routes.SERVER_DOWN} component={ServerDown} />
        {/* This Route must always be the Last.Please add new routes above it... */}
        <Route path="*" component={NotFound} />
      </Switch>
    </main>
  );
}
