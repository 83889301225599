import { compose, graphql } from "react-apollo";

import ProspectsTable from "./prospects-table";
import { GET_PROSPECTS_FOR_ALL_PROSPECTS } from "../../../graphql/queries";
import {
  CONVERT_PROSPECT_TO_LEAD,
  DELETE_PROSPECT,
  ADD_PROSPECT_DELETE_REQUEST,
  CHANGE_CSM,
  CONVERT_PROSPECT_TO_MARKETING_ONLY
} from "../../../graphql/mutations";
import { SET_NOTIFICATION_PROPS, SET_TABLE_PARAMS, SET_PREVIOUS_CARD } from "../../../cacheql/mutations";
import {
  GET_SELECTED_COMMUNITY,
  GET_SELECTED_DIVISION,
  GET_TITLE_CUSTOMIZATIONS,
  GET_TABLE_PARAMS,
  GET_SELECTED_CSM
} from "../../../cacheql/queries";
import { getCommunityIds, getCardParams } from "../../../utils/helpers";

export default compose(
  graphql(GET_SELECTED_COMMUNITY, { name: "selectedCommunity" }),
  graphql(GET_SELECTED_DIVISION, { name: "selectedDivision" }),
  graphql(GET_SELECTED_CSM, { name: "selectedCsm" }),
  graphql(SET_PREVIOUS_CARD, { name: "setPreviousCard" }),
  graphql(SET_TABLE_PARAMS, { name: "setTableParams" }),
  graphql(GET_TABLE_PARAMS, { name: "getTableParams" }),
  graphql(GET_TITLE_CUSTOMIZATIONS, { name: "customizationText" }),
  graphql(GET_PROSPECTS_FOR_ALL_PROSPECTS, {
    name: "data",
    options: ({
      selectedCommunity,
      selectedDivision,
      currentCard: { tableTitle, filter },
      getTableParams: { tableParams },
      ...props
    }) => {
      let params = getCardParams(tableTitle, tableParams);
      if (params) {
        delete params.filter.filter_name
      }
      return {
        variables: {
          pageNum: (params && params.pageNum) || 0,
          limit: (params && params.limit) || 10,
          sort:
            params && params.order && params.order.id !== -1
              ? [params.order.name, params.order.sort]
              : null,
          search: (params && params.search) || "",
          filter: {
            ...filter,
            community_ids: getCommunityIds(selectedCommunity),
            division_id: selectedDivision.selectedDivision.id,
            csm_id: props.selectedCsm.selectedCsmIds.ids,
            ...(params && params.filter)
          }
        },
        fetchPolicy: "cache-and-network"
      };
    }
  }),
  graphql(CONVERT_PROSPECT_TO_LEAD, { name: "convertProspectToLead" }),
  graphql(CONVERT_PROSPECT_TO_MARKETING_ONLY, { name: "convertProspectToMarketingOnly" }),
  graphql(DELETE_PROSPECT, { name: "deleteProspect" }),
  graphql(ADD_PROSPECT_DELETE_REQUEST, { name: "addProspectDeleteRequests" }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" }),
  graphql(CHANGE_CSM, { name: "changeCSM" })
)(ProspectsTable);
