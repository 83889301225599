import gql from 'graphql-tag'

export const CREATE_LEAD_CLASSIFICATION_FILTER = gql`
mutation createLeadClassificationFilter($input: upsertLeadClassificationFilterInput){
    createLeadClassificationFilter(input: $input){
      code
      message
      success
    }
  }
`

export const UPDATE_LEAD_CLASSIFICATION_FILTER = gql`
mutation updateLeadClassificationFilter($id: Int!, $input: upsertLeadClassificationFilterInput){
    updateLeadClassificationFilter(id: $id, input: $input){
      code
      message
      success
    }
  }
`

export const DELETE_LEAD_CLASSIFICATION_FILTER = gql`
mutation deleteLeadClassificationFilter($id: Int!){
    deleteLeadClassificationFilter(id: $id){
      code
      message
      success
    }
  }
`