import gql from "graphql-tag";

const SET_SHOW_MY_APPOINTMENTS_ONLY = gql`
  mutation setShowMyAppointmentsOnly($show: Boolean) {
    setShowMyAppointmentsOnly(show: $show) @client {
      show
    }
  }
`;

export default SET_SHOW_MY_APPOINTMENTS_ONLY;
