import gql from 'graphql-tag'

export const CREATE_CUSTOMER_ORIGINATION_AREA = gql`
mutation createCustomerOriginationArea($input: upsertCustomerOriginationAreaInput) {
    createCustomerOriginationArea(input: $input) {
        message,
        code,
        success
    }
}
`

export const UPDATE_CUSTOMER_ORIGINATION_AREA = gql`
mutation updateCustomerOriginationArea($id: Int!, $input: upsertCustomerOriginationAreaInput) {
    updateCustomerOriginationArea(id: $id, input: $input) {
        message,
        code,
        success
    }
}
`

export const DELETE_CUSTOMER_ORIGINATION_AREA = gql`
mutation deleteCustomerOriginationArea($id: Int!) {
    deleteCustomerOriginationArea(id: $id) {
        message,
        code,
        success
    }
}
`