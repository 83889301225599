import React, { useCallback, useState, useEffect } from "react";
import { useQuery, useMutation } from "react-apollo-hooks";
import { useDropzone } from "react-dropzone";

import CustomButton from "../../components/custom-button/index";
import NotAccepted from "../../images/not-accepted-icon.svg";
import ExportExcel from "../../images/export-excel.svg";
import UploadFile from "../../images/upload-file.svg";
import Accepted from "../../images/accepted-icon.svg";
import { MdClose } from "react-icons/md";

import { removeElement } from "../../utils/helpers";
import { GET_NOTE_ATTACHMENT } from "../../graphql/queries";
import { SET_NOTIFICATION_PROPS } from "../../cacheql/mutations";
import { DELETE_NOTE_ATTACHMENT } from "../../graphql/mutations";
import "./styles.css";


/**
 * This form is used to upload an excel file containing web form data. Its a pretty basic form containing two select fields for webform type and community selection as
 * well as a dropzone where a user can either drag and drop a file or click the add file button to open a window's explorer and choose a file from there.
 * All fields are required, without filling them form cannot be submitted. The upload button will only become clickable when the user has uploaded a valid excel file.
 * On submission the form is checked and appropriate mutation is called to upload the file to the database.
 * @param {Object} props
 * @param {Object} props.values object containing activities info to be edited
 */
const NoteAttachments = ({ setFilesToUpload, ...props }) => {
  const { notes, type } = props
  const [filesAccepted, setFilesAccepted] = useState([])
  const [filesRejected, setFilesRejected] = useState([])
  const [preSelectedFiles, setPreSelectedFiles] = useState([])

  const { refetch } = useQuery(GET_NOTE_ATTACHMENT, { skip: true })
  const [removeAttachment] = useMutation(DELETE_NOTE_ATTACHMENT)
  const [setNotificationProps] = useMutation(SET_NOTIFICATION_PROPS)

  /**
   * Callback function called whenever a file is added to the drop zone component. In case the file is accepted, the file is added to the form and upload button becomes clickable.
   * If the file is rejected the error message is shown and the user will have to re-upload the file.
   * @param {Array} acceptedFiles An array of accepted files by dropzone component.
   * @param {Array} fileRejections An array of rejected files that do not meet the requirements set in dropzone.
   */
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (acceptedFiles.length) {
      const acceptedFileList = acceptedFiles.map(file => (file));
      setFilesAccepted(prevState => [...prevState, ...acceptedFileList]);
    }

    if (fileRejections.length) {
      const rejectedFileList = fileRejections.map(file => ({ file: file.file, errors: file.errors[0] }))
      setFilesRejected(rejectedFileList);
    }
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    maxFiles: 5,
    multiple: true,
    noClick: true,
    noKeyboard: true,
    onDrop,
    maxSize: 10000000,
    accept: "image/*,.pdf",
  });

  /**
   * A function that returns custom error message based on the error code received
   * @param {String} code The error code
   * @param {String} message Default message received by the dropzone component
   * @returns
   */
  const getErrorText = ({ code, message }) => {
    if (code === "file-too-large") {
      return "Exceeded size limit. Only files under 10 MB can be uploaded.";
    } else if (code === "file-invalid-type") {
      return "Wrong file added. Only images and pdf files are allowed.";
    } else if (code === "too-many-files") {
      return "Exceeded upload limit. Only 5 files can be uploaded at a time."
    }
    return message;
  };

  const handleRejectedFiles = (filesRejected) => {
    if (filesRejected.length <= 5 && filesRejected.every(file => file.errors.code === 'too-many-files')) {
      const acceptedFileList = filesRejected.map(file => (file.file));
      setFilesAccepted(prevState => [...prevState, ...acceptedFileList]);
      setFilesRejected([]);
    }
  }

  const getDownloadUrl = async (attachment_id, file_path) => {
    let { data } = await refetch({
      input: {
        attachment_id,
        file_path
      }
    })

    data && window.open(data.getNoteAttachment)
  }

  const deleteAttachment = async (attachment_id) => {
    removeAttachment({
      variables: {
        input: {
          attachment_id,
          note_id: notes.id,
          type
        }
      }
    }).then((res) => {
      if (res.data.deleteNoteAttachment.code === 200) {
        setNotificationProps({
          variables: {
            open: true,
            message: "Attachment removed successfully"
          }
        })
      }
    })
  }

  useEffect(() => {
    setFilesToUpload(filesAccepted)
  }, [filesAccepted])

  useEffect(() => {
    notes && setPreSelectedFiles(notes.note_attachment)
  }, [notes])

  return (
    <div className="form-row mb-2">
      <div className="col-md-12">
        <div className="dropzone-container" onClick={open}>
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <div className="content">
              <img
                src={UploadFile}
                alt=""
                className="icon"
              />
              {filesAccepted.length ? filesAccepted.map((file, index) => {
                return (
                  <div className="d-flex selected-content" key={index} onClick={e => e.stopPropagation()}>
                    <div className="d-flex selected-title">
                      <img src={ExportExcel} alt="" />
                      <div className="file-name">{file.path}</div>
                    </div>
                    <MdClose
                      onClick={() => setFilesAccepted(removeElement(filesAccepted, index))}
                    />
                  </div>
                )
              }) : (
                <p className="title">
                  {"Drag and drop the file here OR click add file"}
                </p>
              )}
              {preSelectedFiles.length ? preSelectedFiles.map((file, index) => {
                return (
                  <div className="d-flex selected-content" key={index} onClick={e => e.stopPropagation()}>
                    <div className="d-flex selected-title">
                      <img src={ExportExcel} alt="" />
                      <div
                        className="file-name"
                        onClick={() => getDownloadUrl(file.id, file.file_path)}
                      >
                        {file.file_path}
                      </div>
                    </div>
                    <MdClose
                      onClick={() => {
                        setPreSelectedFiles(removeElement(preSelectedFiles, index))
                        deleteAttachment(file.id)
                      }}
                    />
                  </div>
                )
              }) : null}
              {filesRejected.length ? filesRejected.map((file, index) => {
                return (
                  <div className="d-flex selected-content" key={index} onClick={e => e.stopPropagation()}>
                    <div className="d-flex selected-title">
                      <div>
                        <div className="file-name">{file.file.path}</div>
                        <div className="error-file-name">{getErrorText(file.errors)}</div>
                      </div>
                    </div>
                    <MdClose
                      onClick={() => {
                        const removedFiles = removeElement(filesRejected, index)
                        setFilesRejected(removedFiles)
                        handleRejectedFiles(removedFiles)
                      }}
                    />
                  </div>
                )
              }) : null}
              {filesAccepted.length < 5 &&
                <>
                  <CustomButton
                    type="file"
                    onClick={e => e.preventDefault()}
                    color="black"
                    btnValue="ADD FILE"
                    className="add-btn mt-2"
                  />
                  <p className="sub"> 5 files maximum (each up to 10MB) </p>
                </>

              }
              {filesAccepted.length ? (
                <p className="title" style={{ marginTop: "10px" }}>
                  {filesAccepted.length} file(s) have been added and are ready for upload.
                </p>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NoteAttachments