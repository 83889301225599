import React from "react";
import { BrowserRouter } from "react-router-dom";
import "whatwg-fetch"; //importing fetch polyfill

import { UserAuth } from "../rbac/rbac";
import Layout from "../views/layout";

import "./App.css";

const App = props => {
  return (
    <BrowserRouter>
      <UserAuth>
        <Layout />
      </UserAuth>
    </BrowserRouter>
  );
};

export default App;
