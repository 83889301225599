import gql from "graphql-tag";

const GET_LEADS_COUNT = gql`
  query getLeadsCount($input: LeadFilter){
    getLeads(limit: 1, filter: $input) {
      total_count
    }
  }
`;

export default GET_LEADS_COUNT;
