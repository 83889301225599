import React, { useState, useCallback, useEffect } from "react";
import Drawer from "../../hoc/Drawer";
import CreateEditLeadWithMutation from "../lead/forms/create-edit-lead-container";
import { phoneDisplay, removeDuplicates } from "../../utils/helpers";

// this is called for recover and convert to lead from webform drawer!

/**
 *@module  ConvertLeadViewModule
 */
/**
 * this component is used while form conversion/recover to a Lead, to render the edit lead drawer. It takes the customer information and puts it in lead format and pass it to the drawer
 * @param {*} props
 * @param {object} props.data contains information of the customer
 * @param {function} props.onSubmit
 * @param {function} props.toggleShowDrawer closes the drawer
 * @param {boolean} props.showSideBar boolean that defines wether to show the drawer or not
 * @param {boolean} props.convertForm boolean that defines if its conversion
 * @param {boolean} props.recoverForm boolean that defines if its recover
 * @param {string} props.convertFormType type of the page thats beeing called from
 */
const ConvertLeadView = (props) => {
  const {
    data,
    onSubmit,
    toggleShowDrawer,
    showSideBar,
    convertForm,
    convertFormType,
    recoverForm,
    registration,
  } = props;

  const [inActiveCommunities, setInactiveCommunities] = useState([]);
  const [communities, setCommunities] = useState([]);

  /**
   * this function takes the communities from the data and checks if they are active or inactive and sets them accordingly
   * @param {object} data contains information of the communities
   * @function
   * @inner
   * @memberof module:ConvertLeadViewModule
   */
  const getCommunities = useCallback(
    (data) => {
      let communities = [];
      let inActive = [];

      if (convertFormType === "WEBFORM") {
        const detailsCommunities = data.community && [data.community];
        communities = removeDuplicates(detailsCommunities, "id");
      } else if (convertFormType === "REALTOR REGISTRATION") {
        communities = data.communities;
      }

      communities.forEach(
        (community) => !community.is_active && inActive.push(community)
      );
      setInactiveCommunities(inActive);
      setCommunities(communities);
    },
    [convertFormType]
  );

  useEffect(() => {
    if (data) {
      getCommunities(data);
    }
  }, [data, getCommunities]);

  /**
   * this function prepares the data to be passed to the lead form, it takes the customer information and puts it in lead format.
   * @param {object} leadData contains information of the customer
   * @function
   * @inner
   * @memberof module:ConvertLeadViewModule
   * @returns {object}
   */
  const formatData = (leadData) => {
    let dataToReturn = (({
      firstname,
      lastname,
      email,
      phone,
      state,
      realtor,
      osc_notes,
    }) => {
      return {
        first_name_1: firstname || leadData.first_name_1,
        last_name_1: lastname || leadData.last_name_1,
        primary_email_1: email || leadData.primary_email_1,
        cell_phone_1: phone || leadData.cell_phone_1,
        formatted_cell_phone_1:
          (phone && phoneDisplay(phone)) ||
          (leadData.cell_phone_1 && phoneDisplay(leadData.cell_phone_1)),
        secondary_emails_1: leadData.secondary_emails_1 || [],
        first_name_2: "" || leadData.first_name_2,
        last_name_2: "" || leadData.last_name_2,
        primary_email_2: null || leadData.primary_email_2,
        cell_phone_2: null || leadData.cell_phone_2,
        formatted_cell_phone_2:
          "" || (leadData.cell_phone_2 && phoneDisplay(leadData.cell_phone_2)),
        secondary_emails_2: leadData.secondary_emails_2 || [],
        city: "" || leadData.city,
        state_id: state && state.id ? state.id : undefined,
        realtor_id: (realtor && realtor.id) || undefined,
        zip: "" || leadData.zip,
        street_address: "" || leadData.street_address,
        customer_origination_area_id:
          leadData.customer_origination_area?.id || undefined,
        osc_notes,
      };
    })(leadData);

    dataToReturn.Lead = {
      divisions: leadData.division ? [leadData.division.id] : [],
      communities: communities
        .filter((item) => item.is_active)
        .map((item) => item.id),
      other_notes: null,
      quality_score: undefined,
      engagement_score: undefined,
    };

    return dataToReturn;
  };

  return (
    <div>
      <Drawer show={showSideBar} toggleSideBar={toggleShowDrawer}>
        <CreateEditLeadWithMutation
          close={toggleShowDrawer}
          data={data && formatData(data)}
          onSubmit={onSubmit}
          convertForm={convertForm}
          recoverForm={recoverForm}
          convertFormType={convertFormType}
          inActiveCommunities={inActiveCommunities}
          registration={registration}
          registration_id={props.registration_id}
        />
      </Drawer>
    </div>
  );
};

export default ConvertLeadView;
