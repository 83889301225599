import * as Yup from "yup";
import * as errorMessages from "./form-errors";
import * as validations from "./common-validations";

export const noteForm = {
  description: "",
  creator_id: null,
};

export const addNoteSchema = Yup.object().shape({
  description: validations.isSpace
    .required(errorMessages.REQUIRE_FIELD)
    .max(1024, errorMessages.DETAILS_LONG)
    .nullable()
    .default("")
});
