import gql from 'graphql-tag';

const UPLOAD_WEBFORM = gql`
  mutation uploadWebForms($file: Upload!, $division_id: Int, $community_id: Int, $web_form_type: String!) {
    uploadWebForms(file: $file, division_id: $division_id community_id: $community_id, web_form_type: $web_form_type) {
      message
      code
      success
    }
  }
`;

export default UPLOAD_WEBFORM;