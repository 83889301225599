import gql from "graphql-tag";

const SELECT_DATE_RANGE = gql`
  mutation selectDateRange($startDate: String, $endDate: String) {
    selectDateRange(startDate: $startDate, endDate: $endDate) @client {
      startDate
      endDate
    }
  }
`;

export default SELECT_DATE_RANGE;
