import PropTypes from "prop-types";
import React, { Fragment, useEffect } from "react";
import Backdrop from "../components/custom-backdrop";
import SideDrawer from "../components/SideDrawer";

/**
 * This component is used to render side Drawer that covers right half of the screen, and the other half is backdrop.
 * The childred are rendered inside {@link SideDrawer}. On clicking the backdrop, the drawer closes
 * @param {Object} props 
 * @param {Boolean} props.show to show/hide the drawer
 * @param {Function} props.toggleSideBar to toggle show property on clicking the backdrop
 * @param {JSX.Element} props.children to show custom element inside drawer
 */
const Drawer = ({ show, toggleSideBar, children, ...props }) => {

  const drawer = show ? (
    <SideDrawer show={show} component={children} className={props.className} />
  ) : null;
  const backdrop = show ? <Backdrop onClick={toggleSideBar}></Backdrop> : null;

  useEffect(() => {
    if (show) {
      document.body.classList.add("drawer-open");
    } else {
      document.body.classList.remove("drawer-open");
    }
    // If someone does not notify drawer of close event we need to perform clean up. 
    // This will help in keeping the applicaiton working
    return () => {
      document.body.classList.remove("drawer-open");
    };
  }, [show]);

  return (
    <Fragment>
      {drawer}
      {backdrop}
    </Fragment>
  );
};

Drawer.propTypes = {
  show: PropTypes.bool,
  toggleSideBar: PropTypes.func,
  // children: PropTypes.element
};

export default Drawer;
