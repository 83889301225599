import gql from 'graphql-tag';

const GET_AVAILABLE_FLOORPLANS = gql`
query  getAllFloor_Plans($community_ids: [Int!]){
  getAllFloor_Plans(community_ids: $community_ids) {
  	id
    name
  }
 }
`;
 export default GET_AVAILABLE_FLOORPLANS;
