import React from "react";

import usePagination from "./use-pagination";
import useSorter from "./use-sorter";
import useRowsSelector from "./use-rows-select";
import useFilters from "./use-filters";
import useTableUpdate from "./use-table-update";
import reducer, { getInitialState } from "./table-reducer";

/**
 * it takes the props and pass them to reducer to manage the state.
 * this is a table custom hook which manages the state of the table's additional functionalities such as filters, sorting, row selection, pagination.
 * And updates the current table state with every action trigger.
 * @param {*} props
 */
export default function useTable(props) {
  const [state, dispatch] = React.useReducer(reducer, getInitialState(props));
  useTableUpdate(props, state, dispatch);

  return {
    ...state,
    ...useFilters(state, dispatch),
    ...useSorter(state, dispatch),
    ...useRowsSelector(state, dispatch),
    ...usePagination(state, dispatch),
  };
}
