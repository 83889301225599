import React, { useState } from "react";
import { compose, graphql } from "react-apollo";
import { default as SelectionListPopup } from "../../components/list-popup/selection-list-popup";
import {
  GET_ALL_STATUSES,
  GET_EMAILS_BY_STATUS_ID,
} from "../../graphql/queries";

import { isNotNULL } from "../../utils/helpers";
import { ListGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import LoadingWrapper from "../../components/loading-wrapper";
import { EmailAssociationsForm } from "./forms/email-associations-form.jsx";
import { DefaultQuery } from "../../graphql/default-query";
import Dialog from "../../components/dialog/dialog";
import { FaTrash, FaPen, FaPlus } from "react-icons/fa";
import CustomButton from "../../components/custom-button";
import {
  ADD_OTHER_STATUS,
  DELETE_OTHER_STATUS,
  UPDATE_OTHER_STATUS,
} from "../../graphql/mutations";
import { SET_NOTIFICATION_PROPS } from "../../cacheql/mutations";

/**
 *this function is used to render the icons inside the select dropdown list, used in select a status dropdown. Each icon has a tooltip ad functionality.
 * @param {object} Icon icon to be displayed
 * @param {string} tooltip tooltip to be shown
 * @param {number} id id of the status
 * @param {function} callback function to be called on click
 * @returns {JSX.Element}
 */
export const ClickableIcon = ({ icon: Icon, tooltip, id, callback, className }) => {
  return (
    <OverlayTrigger overlay={<Tooltip>{tooltip}</Tooltip>}>
      <CustomButton
        id={id}
        color="green"
        btnIcon={<Icon />}
        onClick={callback}
        className={className}
      />
    </OverlayTrigger>
  );
};

/**
 *@module EmailAssociationsModule
 */
/**
 * this component renders the webform status emails associations tab, which contains a dropdown of status where u can add/edit/delete a status and add status emails, edit reservation and video text labels.
 * @param {*} props
 */
const EmailAssociations = (props) => {
  const [statusID, setStatusID] = useState();
  const [loading, setLoading] = useState(false);

  const [newStatusValue, setNewStatusValue] = useState();
  const [editID, setEditID] = useState();
  const [dailogTitle, setDailogTitle] = useState("");
  const [showDialog, setShowDialog] = useState(false);

  /**
   * This function is used to set status id on selection
   *@param {object} item contains id of the status
   * @constant
   * @memberof module:EmailAssociationsModule
   */
  const handleOthersItemSelection = (item) => {
    const id = item.id;
    setStatusID(id);
  };

  /**
   * This function is used for add status mutation
   *@param {string} name name of status
   * @constant
   * @memberof module:EmailAssociationsModule
   */
  const addMutation = ({ name }) => {
    props
      .addStatus({
        variables: {
          name,
        },
      })
      .then((res) => {
        res.data.addStatus && closeDailog(res.data.addStatus.message);
      });
  };

  /**
   * This function is used for edit status mutation
   * @param {string} name name of status
   * @param {number} id id of status
   * @constant
   * @memberof module:EmailAssociationsModule
   */
  const updateMutation = ({ id, name }) => {
    props
      .updateStatus({
        variables: {
          id,
          name,
        },
      })
      .then((res) => {
        res.data.updateStatus && closeDailog(res.data.updateStatus.message);
      });
  };

  /**
   * This function is used for delete status mutation
   * @param {number} id id of status
   * @constant
   * @memberof module:EmailAssociationsModule
   */
  const deleteMutation = ({ id }) => {
    props
      .deleteStatus({
        variables: {
          id,
        },
      })
      .then((res) => {
        setStatusID(-1);
        res.data.deleteStatus && closeDailog(res.data.deleteStatus.message);
      });
  };

  const handleChange = (value) => {
    setNewStatusValue(value !== " " ? value : null);
  };

  /**
   * This function is used to render list item of select status dropdown which contains 2 clickable icons, edit and delete.
   *@param {object} item contains informations related to the status
   * @constant
   * @memberof module:EmailAssociationsModule
   * @returns {JSX.Element}
   */
  const getListItem = (item) => {
    return (
      <div className="d-flex justify-content-between webform-status-selection">
        <label className="mb-0">{item.name}</label>
        <div className="webform-email-association-controls">
          <ClickableIcon
            tooltip="Edit"
            id="edit-icn"
            icon={FaPen}
            callback={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setDailogTitle("Edit");
              setEditID(item.id);
              setNewStatusValue(item.name);
              setShowDialog(true);
            }}
          />
          <ClickableIcon
            tooltip="Delete"
            id="delete-icn"
            icon={FaTrash}
            callback={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setDailogTitle("Delete");
              setEditID(item.id);
              setShowDialog(true);
            }}
          />
        </div>
      </div>
    );
  };

  /**
   * This function is used to render the dialogs for add edit and delete.
   * @constant
   * @memberof module:EmailAssociationsModule
   * @returns {JSX.Element}
   */
  const dailogBody = () => {
    return (
      <div className="dailog-body">
        {dailogTitle === "Delete" ? (
          <>
            <p>
              Do you want to delete the status ? Please note all associated
              emails will be deleted as well.
            </p>
          </>
        ) : (
          <input
            type="text"
            value={newStatusValue}
            className="form-control"
            placeholder="Status title"
            onChange={(e) => handleChange(e.target.value)}
          />
        )}
      </div>
    );
  };

  const closeDailog = (message) => {
    setShowDialog(false);
    setNewStatusValue();
    setEditID();
    props.data.refetch();
    message &&
      props.setNotificationProps({ variables: { open: true, message } });
  };

  return (
    <div className="p-3 pr-4">
      <LoadingWrapper
        component={
          <div id="admin-email-associations-root">
            <div className="d-flex">
              <SelectionListPopup
                title="Web Form Others"
                label="Select a Status"
                selectedID={statusID}
                list={
                  isNotNULL(props.data.getAllStatuses)
                    ? props.data.getAllStatuses
                    : null
                }
                mapper={(field) => {
                  return (
                    isNotNULL(field) &&
                    field.map((item, key) => (
                      <ListGroup.Item
                        className="list-item"
                        onClick={() => handleOthersItemSelection(item)}
                        key={key}
                      >
                        {getListItem(item)}
                      </ListGroup.Item>
                    ))
                  );
                }}
                loading={(state) => setLoading(state)}
              />
              <div className="ml-3">
                <ClickableIcon
                  tooltip="Add Other Status"
                  id="add-icn"
                  icon={FaPlus}
                  callback={() => {
                    setDailogTitle("Create New Status");
                    setShowDialog(true);
                  }}
                />
              </div>
            </div>
            <DefaultQuery
              query={GET_EMAILS_BY_STATUS_ID}
              skip={statusID ? false : true}
              variables={{ statusId: statusID }}
              fetchPolicy="network-only"
            >
              {({ data, loading, error, refetch }) => {
                return (
                  <EmailAssociationsForm
                    statusId={statusID}
                    status={data && data.getStatusEmails ? data : null}
                    onSubmit={() => refetch()}
                  />
                );
              }}
            </DefaultQuery>
          </div>
        }
        loading={loading}
      />
      <Dialog
        size="sm"
        show={showDialog}
        onHide={() => closeDailog()}
        title={dailogTitle}
        body={dailogBody()}
        click={() => {
          editID
            ? dailogTitle === "Edit"
              ? updateMutation({ id: editID, name: newStatusValue })
              : deleteMutation({ id: editID })
            : addMutation({ name: newStatusValue });
        }}
        clickname="Ok"
        closename="Cancel"
      />
    </div>
  );
};

export default compose(
  graphql(GET_ALL_STATUSES),
  graphql(ADD_OTHER_STATUS, { name: "addStatus" }),
  graphql(UPDATE_OTHER_STATUS, { name: "updateStatus" }),
  graphql(DELETE_OTHER_STATUS, { name: "deleteStatus" }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" })
)(EmailAssociations);
