import React, { useState } from "react";
import { Card, Button } from "react-bootstrap";
import { FaTimes, FaEnvelope } from "react-icons/fa";
import { EMAIL_MATCHED } from "../../utils/form-errors";
import "./fields.css";

/**
 * This field is used to add emails n an input field, which start showing as a list in a box above the field as soon as add button is pressed. The items from the list can be removed by pressing cross on individual item.
 * @param {Object} props 
 * @param {String} props.label label of the field
 * @param {Array} props.initValue initial value of the field
 * @param {Array} props.refFields this contains other fields that are to show remove error along with secondary emails
 * @param {Function} props.addCallback this is a callback function that is called on clicking add button
 * @param {Function} props.deleteCallback this is a callback function that is called on clicking delete 
 */
const AddEmailField = props => {
  const {
    id,
    type,
    label,
    placeholder,
    field,
    initValue,
    form: { errors, setFieldValue, handleBlur, setFieldTouched },
    refFields,
    addCallback,
    deleteCallback,
    disabled = false,
    ...rest
  } = props;

  const computeError = () => {
    for (const refField of refFields) {
      if (refField.value[field.name + "-input"])
        return errors["hidden_" + field.name];
    }
    return undefined;
  };

  const [allEmails, setAllEmails] = useState(initValue);
  const [newEmail, setNewEmail] = useState("");
  let error =
    errors["hidden_" + field.name] === EMAIL_MATCHED
      ? computeError()
      : errors["hidden_" + field.name];

  return (
    <div tabIndex="0" name={field.name} id={id + "-form-group"} className="form-group">
      <label htmlFor={id + "-input"} className="control-label">
        {label}
      </label>
      <Card
        border="dark"
        className={"secondary-email-box form-control" + (error ? " is-invalid" : "")}
      >
        <div className="w-100">
          {allEmails.map((item, index) => (
            <div className="secondary-email-wrapper" key={index}>
              <p className="m-0 d-flex align-items-center"><FaEnvelope className="mr-2 green"/> {item}</p>
              <i>
                <Button
                  className={`btn-close`}
                  onClick={() => {
                    allEmails.splice(index, 1);
                    setAllEmails(allEmails);
                    setFieldValue(field.name, allEmails);
                    deleteCallback && deleteCallback();
                  }}
                >
                  <FaTimes />
                </Button>
              </i>
            </div>
          ))}
        </div>
      </Card>
      <br />

      <div className="add-email-container">
        <div
          style={{ paddingRight: 3, width: "90%" }}
          className="d-flex flex-column"
        >
          <input
            name={field.name+"-input"}
            id={id + "-input"}
            type="text"
            className={"form-control" + (error ? " is-invalid" : "")}
            value={newEmail}
            onChange={e => {
              setFieldTouched(field.name + "-input", true);
              setFieldValue("hidden_" + field.name, e.target.value);
              setNewEmail(e.target.value);
            }}
            onBlur={e => handleBlur(e)}
            onClick={e =>
              refFields &&
              refFields.forEach(refField =>
                refField.setter({ [field.name + "-input"]: true })
              )
            }
            disabled={disabled}
          />
          {error && (
            <div className="invalid-feedback">{error ? error : ""}</div>
          )}
        </div>
        <button
          type="button"
          disabled={
            errors["hidden_" + field.name] || newEmail === "" ? true : false
          }
          className="btn custom-btn btn-primary align-self-start"
          onClick={() => {
            const arrayToSet = [...allEmails, newEmail];
            setAllEmails(arrayToSet);
            setFieldValue(field.name, arrayToSet);
            setNewEmail("");
            setFieldValue("hidden_" + field.name, "");
            addCallback && addCallback(newEmail);
          }}
          {...field}
          {...rest}
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default AddEmailField;
