import React, { useEffect, useMemo, useState, useContext } from "react";
import { Dropdown, Col, DropdownButton, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import _ from "lodash";
import { FaUser, FaCheckCircle, FaRedo, FaCalendarCheck, FaTable, FaCalendarAlt, FaEye } from "react-icons/fa";
import { GET_CSM } from "../../graphql/queries";
import Dialog from "../../components/dialog/dialog";
import { DefaultQuery } from "../../graphql/default-query";
import routes_constants from "../../views/layout/routes";
import UpcomingFilter from "../../views/prospect/forms/upcoming-filters-container";
import {
  isNotNULL,
  getCardParams,
  getUpdatedParams,
} from "../../utils/helpers";
import "./dashboard.css";
import TableView, {
  SortableHeader,
  stringComparator,
  TextCell,
  TextHeader,
  ActionCell,
  FlagCell,
  TextCellWithMapper,
  PhoneCell,
  DateCell,
  DetailLinkCell,
  dateComparator,
  buyerTwoIconCellObject,
  iconCell, EmailCell
} from "../../components/new-table";
import FadeIn from "../../components/fade-in";
import CustomLoader from "../../components/custom-loader";
import { phoneDisplay, isBuyerTwo } from "../../utils/helpers";
import CalendarPopover from '../../views/common-lead-prospect-details/calendar-popover'
import { Auth } from "../../rbac/rbac";
import reducer, { getInitialState } from "./upcoming-tasks-reducer";
import Calendar from "../../components/calendar/calendar";
import LoadingWrapper from "../../components/loading-wrapper";

let csmTitle = "";
/** @module UpcomingTasksTableModule */

/**
 * This component is a table used to display all the upcoming appointments and followups. It contains pagination, 
 * searchbar, filtering, mass-update and export to csv options. It has hover-actions to mark the appointment as done,
 * and to change the csm. It shows multiple columns displaying appointment/followup information. It has a duedate 
 * column which shows "!" icon when due date has passed. Table uses reducer to maintain its state. It also accesses
 * store to check if table parameters are already stored or not.
 * 
 * @param {Object} props it contains all the mutations/queries in the conatiner as props
 * @param {Object} props.data it contains array of agencies to be shown on table
 * @param {Object} props.getTableParams it contains cards info stored inside cache
 * @returns {JSX.Element} It returns jsx containing the table, dialog box for change csm and mark as done confirmation 
 */
const UpcomingTasksTable = ({
  data,
  selectedCommunity,
  selectedDivision,
  selectedCsm,
  getDefaultView: { defaultView },
  getUpcomingTasksFilters,
  getTableParams: { tableParams },
  getShowMyAppointmentsOnly: { showMyAppointmentsOnly },
  ...props
}) => {
  const { is_osc } = useContext(Auth);
  const [myAppointmentsOnly, setMyAppointmentsOnly] = useState(showMyAppointmentsOnly.show)
  const [isTableView, setTableView] = useState(defaultView.tableView)
  const cacheParams = getCardParams("Upcoming Tasks", tableParams)
  const [state, dispatch] = React.useReducer(
    reducer,
    getInitialState({ loading: data.loading, cache: cacheParams })
  );

  const {
    render,
    userPageCount,
    pageSize,
    tasks,
    loading,
    showCsmDialog,
    csmId,
    disableChange,
    showConfirmation,
    selectedCommunityId,
    showMarkDoneConfirmation,
    appointment_ids,
    prospect_follow_up_ids,
    lead_follow_up_ids,
    lead_appointment_ids,
    calendarView
  } = state;

  /**
   * Either set the data in state or set the loading to inform the table what to render.
   */
  useEffect(() => {
    if (
      !data.loading &&
      data.getAllAppointments_And_Followups?.Appointment_And_Followup
    ) {
      dispatch({
        type: "UPDATE",
        payload: {
          render: true,
          tasks: data.getAllAppointments_And_Followups.Appointment_And_Followup,
          loading: false,
          userPageCount: Math.ceil(
            data.getAllAppointments_And_Followups.total_count / pageSize
          ),
        },
      });
    }
    if (data.loading) {
      dispatch({ type: "UPDATE", payload: { loading: data.loading } });
    }
  }, [data]);

  const handleMarkDoneCancelation = () => {
    resetTable()
  };

  /**
   * Reset the data arrays, and everything necessary. So that after one action is performed successfully, everything
   * starts from the initial values to avoid any ripples.
   * @function
   * @inner
   * @param {Object} props 
   * @memberof module:UpcomingTasksTableModule
   * @see {@link module:UpcomingTasksTableModule~UpcomingTasksTable}
   */
  const resetTable = (props) => {
    dispatch({
      type: "UPDATE",
      payload: {
        ...props,
        showMarkDoneConfirmation: false,
        appointment_ids: [],
        prospect_follow_up_ids: [],
        lead_follow_up_ids: [],
        activityType: null,
      },
    });
  }

  /**
   * Set the data arrays, and showMarkDoneConfirmation, so that on mark done click, all the ids are set categorized 
   * into appointment, lead-followup, and propspect-followup. And this will cause the confirmation dialog box to pop
   * up on the screen. 
   * 
   * @function
   * @inner
   * @param {Object} props 
   * @memberof module:UpcomingTasksTableModule
   * @see {@link module:UpcomingTasksTableModule~UpcomingTasksTable}
   */
  const handleMarkDone = ({ appointmentIds, prospectFollowupIds, leadFollowupIds, leadAppointmentIds }) => {
    if (appointmentIds.length || prospectFollowupIds.length || leadFollowupIds.length || leadAppointmentIds.length) {
      dispatch({
        type: "UPDATE",
        payload: {
          showMarkDoneConfirmation: true,
          appointment_ids: appointmentIds,
          lead_appointment_ids: leadAppointmentIds,
          prospect_follow_up_ids: prospectFollowupIds,
          lead_follow_up_ids: leadFollowupIds
        },
      });
    }
  };

  /**
   * It receieves an array of follow-ids which are to be marked as done. It calls markDoneLeadFollowUp call to backend
   * by passing ids as variables, and on success shows the notification, refetches the data and resets table.
   * 
   * @function
   * @inner
   * @param {Object} ids ids of lead-followups 
   * @memberof module:UpcomingTasksTableModule
   * @see {@link module:UpcomingTasksTableModule~UpcomingTasksTable}
   */
  const markLeadFollowUpDone = (ids) => {
    props.markDoneLeadFollowUp({
      variables: { input: { follow_up_ids: ids, done: true } },
    }).then((res) => {
      if (res.data.markDoneLeadFollowUp.code === 200)
        props.setNotificationProps({
          variables: {
            open: true,
            message: res.data.markDoneLeadFollowUp.message,
          },
        });

      data.refetch(/* params */);
      resetTable({ loading: true })
    }).catch((res) => {
      res.graphQLErrors.map((error) => {
        return error.message;
      });
    });
  }

  /**
   * It receieves an array of follow-ids which are to be marked as done. It calls markDoneProspectFollowUp call to 
   * backend by passing ids as variables, and on success shows the notification, refetches the data and resets arrays
   * 
   * @function
   * @inner
   * @param {Object} ids ids of prospect-followups 
   * @memberof module:UpcomingTasksTableModule
   * @see {@link module:UpcomingTasksTableModule~UpcomingTasksTable}
   */
  const markFollowUpDone = (ids) => {
    props.markDoneProspectFollowUp({
      variables: { input: { follow_up_ids: ids, done: true } },
    }).then((res) => {
      if (res.data.markDoneProspectFollowUp.code === 200)
        props.setNotificationProps({
          variables: {
            open: true,
            message: res.data.markDoneProspectFollowUp.message,
          },
        });

      data.refetch(/* params */);
      resetTable({ loading: true })
    }).catch((res) => {
      res.graphQLErrors.map((error) => {
        return error.message;
      });
    });
  };

  /**
   * It receieves an array of appointment-ids which are to be marked as done. It calls markDoneProspectAppointment 
   * call to backend by passing ids as variables, and on success shows the notification, refetches the data and 
   * resets arrays
   * 
   * @function
   * @inner
   * @param {Object} ids ids of appointments 
   * @memberof module:UpcomingTasksTableModule
   * @see {@link module:UpcomingTasksTableModule~UpcomingTasksTable}
   */
  const markAppointmentDone = (ids) => {
    props.markDoneProspectAppointment({
      variables: { input: { appointment_ids: ids, done: true } },
    }).then((res) => {
      if (res.data.markDoneProspectAppointment.code === 200)
        props.setNotificationProps({
          variables: {
            open: true,
            message: res.data.markDoneProspectAppointment.message,
          },
        });

      data.refetch(/* params */);
      resetTable({ loading: true })
    }).catch((res) => {
      res.graphQLErrors.map((error) => {
        return error.message;
      });
    });
  };

  /**
   * It receieves an array of appointment-ids which are to be marked as done. It calls markDoneLeadAppointment 
   * call to backend by passing ids as variables, and on success shows the notification, refetches the data and 
   * resets arrays
   * 
   * @function
   * @inner
   * @param {Object} ids ids of appointments 
   * @memberof module:UpcomingTasksTableModule
   * @see {@link module:UpcomingTasksTableModule~UpcomingTasksTable}
   */
  const markLeadAppointmentDone = (ids) => {
    props.markDoneLeadAppointment({
      variables: { input: { appointment_ids: ids, done: true } },
    }).then((res) => {
      if (res.data.markDoneLeadAppointment.code === 200)
        props.setNotificationProps({
          variables: {
            open: true,
            message: res.data.markDoneLeadAppointment.message,
          },
        });

      data.refetch(/* params */);
      resetTable({ loading: true })
    }).catch((res) => {
      res.graphQLErrors.map((error) => {
        return error.message;
      });
    });
  };

  const markDone = () => {
    if (appointment_ids.length) {
      markAppointmentDone(appointment_ids);
    }
    if (prospect_follow_up_ids.length) {
      markFollowUpDone(prospect_follow_up_ids);
    }
    if (lead_follow_up_ids.length) {
      markLeadFollowUpDone(lead_follow_up_ids);
    }
    if (lead_appointment_ids.length) {
      markLeadAppointmentDone(lead_appointment_ids);
    }
  };

  /**
   * It takes in array of objects and filter out ids of appointments and lead/propspect followups and forms an object
   * to return the values
   * 
   * @function
   * @inner
   * @param {Object} rows selected rows
   * @memberof module:UpcomingTasksTableModule
   * @returns {Object}
   * @see {@link module:UpcomingTasksTableModule~UpcomingTasksTable}
   */
  const getAppointmentAndFollowUp = (rows) => {
    let appointmentIds = [],
      prospectFollowupIds = [],
      leadFollowupIds = [],
      leadAppointmentIds = [];

    if (rows.length) {
      appointmentIds = rows.filter(x => x.prospect_id && !x.tentative).map((x) => x.appointment_id).filter((x) => x != null);
      leadAppointmentIds = rows.filter(x => x.lead_id).map((x) => x.appointment_id).filter((x) => x != null);
      prospectFollowupIds = rows.filter(x => x.prospect_id).map((x) => x.follow_up_id).filter((x) => x != null);
      leadFollowupIds = rows.filter(x => x.lead_id).map((x) => x.follow_up_id).filter((x) => x != null);
    }

    return {
      appointmentIds,
      leadAppointmentIds,
      prospectFollowupIds,
      leadFollowupIds
    };
  };

  /**
   * It is a small component that renders mass-update dropdown list which contains only one option to mark the 
   * appointment/follow-up as done. 
   * 
   * @function
   * @inner
   * @param {Function} getSelectedRows to get the array of selected rows 
   * @memberof module:UpcomingTasksTableModule
   * @see {@link module:UpcomingTasksTableModule~UpcomingTasksTable}
   */
  const massDropDownActions = (getSelectedRows) => {
    return (
      <div>
        <Dropdown.Item
          eventKey="5"
          id="dropdown-5"
          onClick={handleMarkDone.bind(
            this,
            getAppointmentAndFollowUp(getSelectedRows())
          )}
        >
          {" "}
          <FaCheckCircle id="drop-down-icn-5" /> Mark as Done
        </Dropdown.Item>
      </div>
    );
  };

  const handleShowCsm = () => {
    csmTitle = null;
    dispatch({
      type: "UPDATE",
      payload: {
        csmId: null,
        disableChange: true,
        showCsmDialog: !showCsmDialog,
      },
    });
  };

  const handleChangeConfirmation = () => {
    dispatch({
      type: "UPDATE",
      payload: { showConfirmation: !showConfirmation },
    });
  };

  const handleCsmChange = (val) => {
    csmTitle = `${val.first_name} ${val.last_name}`;
    dispatch({
      type: "UPDATE",
      payload: { csmId: val.id, disableChange: false },
    });
  };

  /**
   * It is a small component that renders body of the chnage csm dialogbox. A list of csm is acquired from the backend
   * depending on the community selected, and is shown in a select-field from which the csm can be selected to be 
   * used as the new csm for the appointment/followup. 
   * 
   * @function
   * @inner
   * @memberof module:UpcomingTasksTableModule
   * @see {@link module:UpcomingTasksTableModule~UpcomingTasksTable}
   */
  const handleCsm = () => {
    return (
      <DefaultQuery
        query={GET_CSM}
        variables={{ community_ids: [selectedCommunityId] }}
      >
        {({ data, loading, error }) => {
          let defaultValue = loading
            ? "Loading ..."
            : error
              ? error.message
              : null;
          return (
            <Form.Group as={Col} id="filterCSM">
              <DropdownButton
                alignRight
                drop="down"
                variant="secondary"
                id="filter-item-btn"
                key="down"
                title={csmTitle ? csmTitle : "Select..."}
                className="filter-item"
              >
                {defaultValue && <Dropdown.Item>{defaultValue}</Dropdown.Item>}
                {isNotNULL(data.getAllCSMs) &&
                  data.getAllCSMs.map((item) => {
                    return (
                      <Dropdown.Item
                        onSelect={() => handleCsmChange(item)}
                        key={item.id}
                        eventKey={item.id}
                      >
                        {item.first_name} {item.last_name}
                      </Dropdown.Item>
                    );
                  })}
              </DropdownButton>
            </Form.Group>
          );
        }}
      </DefaultQuery>
    );
  };

  /**
   * This function prepares the input and hides the dialog box and makes a call to the backend to change the csm of 
   * the appontment and followup
   * 
   * @function
   * @inner
   * @memberof module:UpcomingTasksTableModule
   * @see {@link module:UpcomingTasksTableModule~UpcomingTasksTable}
   */
  const handleCsmConfirmation = () => {
    let transferProspectInput = {
      csm_id: csmId,
      appointment_ids: appointment_ids,
      follow_up_ids: lead_follow_up_ids.length ? lead_follow_up_ids : prospect_follow_up_ids,
    };

    dispatch({
      type: "UPDATE",
      payload: {
        showConfirmation: false,
        showCsmDialog: false,
        disableChange: false,
      },
    });

    csmTitle = null;

    props.taskChangeCSM({
      variables: { input: transferProspectInput },
    }).then((res) => {
      if (res.data.transferAppointments_And_Followups.code === 200) {
        resetTable({ loading: true })
        data.refetch(/* params */);
        props.setNotificationProps({
          variables: {
            open: true,
            message: res.data.transferAppointments_And_Followups.message,
          },
        });
      }
    });
  };

  /**
   * It is a callback function which is called on any table change like: pagination, page-size, filters, search term.
   * Table passes its current state so that we can update the table parameters in store and state, which are used in 
   * refetching the data with correct parameters.  
   * 
   * @function
   * @inner
   * @memberof module:UpcomingTasksTableModule
   * @see {@link module:UpcomingTasksTableModule~UpcomingTasksTable}
   */
  const fetchData = React.useCallback((tableProps) => {
    if (loading) {
      return;
    }

    //variables to be stored in cache/store
    const variables = {
      filter: {
        ...tableProps.filter,
      },
      limit: tableProps.pageSize,
      pageNum: tableProps.currentPage,
      search: tableProps.searchTerm,
      order: {
        id: tableProps.sortColumn.id,
        name: tableProps.sortColumn.name,
        sort: tableProps.sortColumn.sort,
      },
    };

    // if search term or filter is changed, bring the page to 0
    if (
      tableProps.searchTerm !== state.search ||
      !_.isEqual(variables.filter, state.filter)
    ) {
      variables.pageNum = 0;
    }

    // update the state
    dispatch({
      type: "UPDATE",
      payload: { ...variables, pageSize: tableProps.pageSize, loading: true },
    });
    const pageCount = Math.ceil(
      data.getAllAppointments_And_Followups.total_count / tableProps.pageSize
    );

    // update total page count in the state
    if (pageCount !== userPageCount) {
      dispatch({
        type: "UPDATE",
        payload: { userPageCount: pageCount },
      });
    }

    // update the store
    props.setTableParams({
      variables: {
        cardsParams: getUpdatedParams(tableParams.cardsParams, {
          cardTitle: "Upcoming Tasks",
          params: variables,
        }),
      },
    }).then((res) => {
      dispatch({ type: "UPDATE", payload: { loading: false } });
    });
  });

  /**
   * This function defines the icon of each record, either an appointment or followup
   * @function
   * @inner
   * @memberof module:UpcomingTasksTableModule
   * @param {object} item contains information of each row
   */
  const handleIcon = (item) => {
    if (item.appointment_id) {
      return FaCalendarCheck;
    } else {
      return FaRedo;
    }
  };

  /**
   * This function sets the default view in the cache. It is used to toggle in between calendar and table view on the dashboard page.
   * @memberof module:UpcomingTasksTableModule
   */
  const handleChangeView = (toggleTableView) => {
    setTableView(toggleTableView)

    props.selectDefaultView({
      variables: {
        tableView: toggleTableView
      }
    }).then(res => {
      dispatch({ type: "UPDATE", payload: { loading: true, render: false } });
    })
  }

  /**
   * This function is used to refetch data and show a notification message when an operation is performed on the calendar.
   * @param {Function} refetch Function to refetch data from backend
   * @param {String} message Message to show in the notification window
   */
  const submitHandler = (refetch, message) => {
    message &&
      props.setNotificationProps({
        variables: { open: true, message: message }
      });
    refetch();
  };

  /**
   * This function is used refetch data on changing the calendar month
   * @param {Date} start The start date of the calendar month
   * @param {Date} end The end date of the calendar month
   */
  const onRangeChange = ({ start, end }) => {
    props.setCalendarDateRange({ variables: { start, end } })
  }

  /**
   * On clicking the icon "Show My Appointments Only", the current state 
   * is reversed and data is fetched accordingly. 
   */
  const toggleShowMyAppointmentsOnly = () => {
    setMyAppointmentsOnly(!myAppointmentsOnly)
    props.setShowMyAppointmentsOnly({ variables: { show: !myAppointmentsOnly } })
  }

  /**
   * This defines columns of the table. It has multiple columns, displaying information of the appointments and 
   * followups. Duedate column is marked with a red icon if due date has passed already. Buyer two icon shows up as
   * the second column if the lead/propscet has buyer 2 data as well. Most of the columns are sortable.
   * 
   * @constant
   * @memberof module:UpcomingTasksTableModule
   */
  const columns = useMemo(
    () => [
      {
        id: "0",
        header: "",
        accessor: "red_flag",
        csvHeader: "Red Flagged",
        component: TextHeader,
        sortable: false,
        csvMapper: (field, accessor) => (field[accessor] ? true : false),
        cell: {
          component: FlagCell,
        },
      },
      {
        id: "1",
        header: "",
        accessor: "",
        component: TextHeader,
        cell: buyerTwoIconCellObject,
      },
      {
        id: "2",
        header: "",
        accessor: "",
        component: TextHeader,
        cell: {
          component: iconCell,
          IconMapper: (field, accessor) => handleIcon(field),
        },
      },
      {
        id: "3",
        header: "First Name",
        accessor: "first_name_1",
        component: SortableHeader,
        comparator: stringComparator,
        cell: {
          component: DetailLinkCell,
          path: (field, accessor) => field.prospect_id ? routes_constants.PROSPECT_DETAIL : routes_constants.LEAD_DETAIL
        },
      },
      {
        id: "4",
        header: "Last Name",
        accessor: "last_name_1",
        component: SortableHeader,
        comparator: stringComparator,
        cell: { component: TextCell },
      },
      {
        id: "5",
        header: "Rank",
        accessor: "rank",
        component: TextHeader,
        sortable: false,
        csvMapper: (field, accessor) =>
          field[accessor] ? field[accessor].name : "",
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) =>
            field[accessor] ? field[accessor].name : "",
        },
      },
      {
        id: "6",
        header: "Title",
        accessor: "title",
        component: SortableHeader,
        comparator: stringComparator,
        cell: { component: TextCell },
      },
      {
        id: "7",
        header: "Primary Email",
        accessor: "primary_email_1",
        component: SortableHeader,
        comparator: stringComparator,
        cell: { component: EmailCell, check: "primary_email_1_status" },
      },
      {
        id: "8",
        header: "Mailchimp Subscription",
        accessor: "primary_email_1_status",
        hidden: true,
        csvMapper: (field, accessor) => field[accessor] ? "Subscribed" : "Unsubscribed",
        cell: { component: null },
      },
      {
        id: "9",
        header: "Phone",
        accessor: "cell_phone_1",
        component: TextHeader,
        sortable: false,
        csvMapper: (field, accessor) => phoneDisplay(field[accessor]) || "",
        cell: { component: PhoneCell },
      },
      {
        id: "10",
        header: "First Name 2",
        accessor: "first_name_2",
        hidden: true,
        component: SortableHeader,
        comparator: stringComparator,
        cell: { component: null },
      },
      {
        id: "11",
        header: "Last Name 2",
        accessor: "last_name_2",
        hidden: true,
        component: SortableHeader,
        comparator: stringComparator,
        cell: { component: null },
      },
      {
        id: "12",
        header: "Primary Email 2",
        accessor: "primary_email_2",
        hidden: true,
        cell: { component: null },
      },
      {
        id: "13",
        header: "Mailchimp 2 Subcription",
        accessor: "primary_email_2_status",
        hidden: true,
        csvMapper: (field, accessor) => isBuyerTwo(field) ? field[accessor] ? "Subscribed" : "Unsubscribed" : "",
        cell: { component: null },
      },
      {
        id: "14",
        header: "Phone 2",
        accessor: "cell_phone_2",
        hidden: true,
        csvMapper: (field, accessor) => phoneDisplay(field[accessor]) || "",
        cell: { component: null },
      },
      {
        id: "15",
        header: "Due Date",
        accessor: "due_date",
        component: SortableHeader,
        comparator: dateComparator,
        cell: { component: DateCell, check: (field) => field['follow_up_id'] },
      },
      {
        id: "16",
        header: "CSM Name",
        accessor: "csm",
        component: TextHeader,
        sortable: false,
        csvMapper: (field, accessor) =>
          field[accessor]
            ? `${field[accessor].first_name} ${field[accessor].last_name}`
            : "",
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) =>
            field[accessor]
              ? `${field[accessor].first_name} ${field[accessor].last_name}`
              : "",
        },
      },
      {
        id: "17",
        header: "Community",
        accessor: "community",
        component: TextHeader,
        sortable: false,
        csvMapper: (field, accessor) =>
          field[accessor] ? field[accessor].name : "",
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) => field[accessor]?.name,
          check: true,
        },
      },
    ],
    []
  );

  /**
   * This defines hover actions of the table. This specific table has two types of actions, change csm and mark as
   * done.
   * 
   * @constant
   * @memberof module:UpcomingTasksTableModule
   */
  const hoverActions = useMemo(
    () => [
      {
        tooltip: "Change CSM",
        icon: FaUser,
        component: ActionCell,
        removeAction: (field) => field.tentative,
        action: ({ id, ...row }) => {
          dispatch({
            type: "UPDATE",
            payload: {
              selectedCommunityId: row.community?.id,
              prospect_follow_up_ids: row.prospect_id && row.follow_up_id ? [row.follow_up_id] : [],
              lead_follow_up_ids: row.lead_id && row.follow_up_id ? [row.follow_up_id] : [],
              appointment_ids: row.appointment_id ? [row.appointment_id] : [],
            },
          });
          handleShowCsm();
        },
      },
      {
        tooltip: "Mark as Done",
        icon: FaCheckCircle,
        removeAction: (field) => field.tentative,
        component: ActionCell,
        action: ({ id, ...row }) => {
          handleMarkDone(getAppointmentAndFollowUp([row]));
        },
      },
    ],
    []
  );

  const tableOptions = {
    title: is_osc() ? "Appointments & Follow-ups" : "Upcoming Appointments & Follow-ups",
    massActions: {
      render: (getSelectedRows) => {
        return massDropDownActions(getSelectedRows);
      },
    },
    FilterItems: UpcomingFilter,
  };

  return (
    <div>
      <Dialog
        show={showCsmDialog}
        onHide={handleShowCsm}
        disabled={disableChange}
        size="sm"
        title="Change CSM"
        body={handleCsm()}
        click={handleChangeConfirmation}
        clickname="CHANGE"
        closename="CANCEL"
      />
      <Dialog
        title="Confirmation"
        show={showConfirmation}
        onHide={handleChangeConfirmation}
        body="Are you sure you want to change the CSM?"
        click={handleCsmConfirmation}
        clickname="YES"
        closename="NO"
      />
      <Dialog
        show={showMarkDoneConfirmation}
        onHide={handleMarkDoneCancelation}
        title="Mark Done Confirmation"
        body="Are you sure you want to mark the record as Done?"
        click={markDone}
        clickname="YES"
        closename="NO"
      />
      <div className="d-flex justify-content-end toggle-button">
        <div className="btn-group" role="group" aria-label="Basic example">
          <OverlayTrigger placement="top" overlay={<Tooltip>Show my appointments only</Tooltip>} >
            <button type="button" className={`btn ${myAppointmentsOnly ? "btn-primary" : "btn-secondary"}`} onClick={toggleShowMyAppointmentsOnly}><FaEye /></button>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip>Calendar View</Tooltip>} >
            <button type="button" className={`btn ${isTableView ? "btn-secondary" : "btn-primary"}`} onClick={() => isTableView && handleChangeView(false)}><FaCalendarAlt /></button>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip>Table View</Tooltip>} >
            <button type="button" className={`btn ${isTableView ? "btn-primary" : "btn-secondary"}`} onClick={() => !isTableView && handleChangeView(true)}><FaTable /></button>
          </OverlayTrigger>
        </div>
      </div>
      <FadeIn show={render}>
        <div className="table-upcoming-tasks">
          {isTableView == true && (
            <TableView
              columns={columns}
              data={tasks}
              tableOptions={tableOptions}
              loading={loading}
              hoverActions={hoverActions}
              userPageCount={userPageCount}
              userTotalCount={
                !loading && data.getAllAppointments_And_Followups
                  ? data.getAllAppointments_And_Followups.total_count
                  : null
              }
              fetchData={fetchData}
              currentPage={state.pageNum}
              pageSize={state.pageSize}
              filter={state.filter.activity_type || state.filter.rank_id ? state.filter : {}}
              searchTerm={state.search}
              sortColumn={state.order}
              controlled={true}
            />
          )}
          {isTableView == false && (
            <LoadingWrapper
              component={
                <Calendar
                  id='dashboard-calendar'
                  data={Object.assign([],
                    !loading ? data.getAllAppointments_And_Followups.Appointment_And_Followup
                      : [],
                  )}
                  onSubmit={submitHandler.bind(this, data.refetch)}
                  popover={CalendarPopover}
                  type="Dashboard"
                  onRangeChange={onRangeChange}
                />
              }
              loading={loading}
            />
          )}
        </div>
      </FadeIn>
      {!render && <CustomLoader />}
    </div>
  );
};

export default UpcomingTasksTable;
