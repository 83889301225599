import React, { useState } from "react";
import { Form, Field, withFormik } from "formik";
import { addHomeownerSchema } from "./homeowner-form-schema";
import { phoneDisplay } from "../../../utils/helpers";
import { SearchableRealtorSelect } from "../../../components/agencies-creatable-select";
import TextField from "../../../components/fields/text-field";
import AddEmailField from "../../../components/fields/add-email-field";
import SelectField from "../../../components/fields/select-field";
import CustomButton from "../../../components/custom-button/index";
import ErrorFocus from "../../../components/error-focus/error-focus";
import {
  ButtonToolbar,
} from "react-bootstrap";

//Edit homeowner functionality is currently disabled on frontend side as per client's requirement

/**
 * This component is responsible to render the edit form of the homeowner. It mainly has text-fields to show buyer 1
 * and buyer 2 information and some other address related fields etc. First name, last name and either primary or 
 * cell phone must be filled to submit the form. Primary emails of buyer one and 2 mst not be similar. Primary
 * email must not match any of the secondary emails. These are the most important validations of this form. On form
 * submit form-data is formatted back to the values expected by backend and edit-form mutation is called.
 * 
 * @param {Object} props formik-bag, and query/mutations passed from the container
 * @param {Function} props.close Function to close on submit/cancel
 * @param {Object} props.data data formatted in the form of schema
 * @param {Number} props.id id of the homeowner to be edited
 * @param {Function} props.onSubmit callback function
 */
const HomeownerForm = props => {
  const {
    values,
  } = props;

  const [primarySecondaryClicked1, setPrimarySecondaryClicked1] = useState({});
  const [primarySecondaryClicked2, setPrimarySecondaryClicked2] = useState({});
  const [primaryClicked, setPrimaryClicked] = useState({});

  return (
    <Form className={"form-horizontal"} noValidate>
      <div className="form_TitleContainer">
        <div>
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ margin: "0 1.1rem" }}
          >
            <div>
              <div className="form_Title">Homeowner</div>
              <div className="form_TitleBig">
                <div className="d-flex justify-content-center align-items-center">
                  <h2
                    style={{
                      position: "relative",
                      paddingRight: "0.5em"
                    }}
                  >
                    {values.first_name_1 + " " + values.last_name_1}
                  </h2>
                </div>
              </div>
            </div>
            <div className="d-flex pr-md-3">
              <ButtonToolbar>
                <CustomButton
                  color="black"
                  onClick={props.close}
                  btnValue="CANCEL"
                  className="ml-sm-0"
                />
                <CustomButton
                  type="submit"
                  disabled={props.isSubmitting}
                  btnValue={"UPDATE"}
                />
              </ButtonToolbar>
            </div>
          </div>
        </div>
      </div>

      <div className="form_body">
        <div className="form-row">
          <div className="form-section">
            <h5>Buyer#1</h5>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <Field
              label="First Name"
              name="first_name_1"
              id="first_name_1"
              type="text"
              required={true}
              component={TextField}
            />
          </div>
          <div className="col-md-6">
            <Field
              label="Last Name"
              name="last_name_1"
              id="last_name_1"
              type="text"
              required={true}
              component={TextField}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <div className="col-md-12 padding-less">
              <Field
                label="Primary Email"
                name="primary_email_1"
                id="primary_email_1"
                disable={props.data && props.data.display_primary_email_2}
                displayName={props.data && props.data.display_primary_email_2}
                type="text"
                refFields={[
                  {
                    value: primarySecondaryClicked1,
                    setter: setPrimarySecondaryClicked1
                  },
                  { value: primaryClicked, setter: setPrimaryClicked }
                ]}
                required={!values.formatted_cell_phone_1 || false}
                component={TextField}
              />
            </div>
            <div className="col-md-6 padding-less">
              <Field
                label="Cell #"
                name="formatted_cell_phone_1"
                id="formatted_cell_phone_1"
                isCellPhone={true}
                type="text"
                required={!values.primary_email_1 || false}
                component={TextField}
              />
            </div>
          </div>
          <div className="col-md-6">
            <Field
              label="Secondary Email(s)"
              name="secondary_emails_1"
              id="secondary_emails_1"
              type="text"
              initValue={values.secondary_emails_1}
              refFields={[
                {
                  value: primarySecondaryClicked1,
                  setter: setPrimarySecondaryClicked1
                }
              ]}
              component={AddEmailField}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-section">
            <h5>Buyer#2</h5>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <Field
              label="First Name"
              name="first_name_2"
              id="first_name_2"
              type="text"
              component={TextField}
            />
          </div>
          <div className="col-md-6">
            <Field
              label="Last Name"
              name="last_name_2"
              id="last_name_2"
              type="text"
              component={TextField}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <div className="col-md-12 padding-less">
              <Field
                label="Primary Email"
                name="primary_email_2"
                id="primary_email_2"
                disable={props.data && props.data.display_primary_email_2}
                displayName={props.data && props.data.display_primary_email_2}
                type="text"
                refFields={[
                  {
                    value: primarySecondaryClicked2,
                    setter: setPrimarySecondaryClicked2
                  },
                  { value: primaryClicked, setter: setPrimaryClicked }
                ]}
                component={TextField}
              />
            </div>
            <div className="col-md-6 padding-less">
              <Field
                label="Cell #"
                name="formatted_cell_phone_2"
                id="formatted_cell_phone_2"
                isCellPhone={true}
                type="text"
                component={TextField}
              />
            </div>
          </div>
          <div className="col-md-6">
            <Field
              label="Secondary Email(s)"
              name="secondary_emails_2"
              id="secondary_emails_2"
              type="text"
              initValue={values.secondary_emails_2}
              refFields={[
                {
                  value: primarySecondaryClicked2,
                  setter: setPrimarySecondaryClicked2
                }
              ]}
              component={AddEmailField}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <Field
              label="Address"
              name="street_address"
              id="street_address"
              type="text"
              component={TextField}
            />
          </div>
          <div className="col-md-3">
            <Field
              label="City"
              name="city"
              id="city"
              type="text"
              component={TextField}
            />
          </div>
          <div className="col-md-3">
            <Field
              label="State"
              name="state_id"
              id="state_id"
              type="text"
              component={SelectField}
            >
              <option value="">Select...</option>
              {props.getAllStates.getAllStates &&
                props.getAllStates.getAllStates.map((state, index) => (
                  <option key={index} value={state.id}>
                    {state.name}
                  </option>
                ))}
            </Field>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-2">
            <Field
              label="Zip"
              name="zip"
              id="zip"
              type="text"
              component={TextField}
            />
          </div>
          <div className="col-md-4">
            <Field
              label="Realtor"
              name="realtor_id"
              id="realtor_id"
              disabled={true}
              component={SearchableRealtorSelect}
            />
          </div>
          <div className="col-md-6">
            <Field
              label="Source"
              name="customer_origination_area_id"
              id="customer_origination_area_id"
              component={SelectField}
            >
              <option value="">Select...</option>
              {props.getOriginationAreas.getAllCustomer_Origination_Areas &&
                props.getOriginationAreas.getAllCustomer_Origination_Areas.map(
                  (option, index) => (
                    <option key={index} value={option.id}>
                      {option.name}
                    </option>
                  )
                )}
            </Field>
          </div>
        </div>

        <ErrorFocus
          names={[
            {
              fieldName: "secondary_emails_1-input",
              errorName: "hidden_secondary_emails_1"
            },
            {
              fieldName: "secondary_emails_2-input",
              errorName: "hidden_secondary_emails_2"
            }
          ]}
        />
      </div>
    </Form>
  );
};

const updateHomeownerSubmit = (values, props, setSubmitting) => {
  props
    .updateHomeowner({
      variables: { input: values }
    })
    .then(res => {
      setSubmitting(false);
      if (res.data.updateCustomer.code === 200) {
        props.onSubmit();
      } else if (res.data.updateCustomer.code === 409) {
        const selector = `[name="primary_email_1"]`;
        const element = document.querySelector(selector);
        element.focus();
      }
      props.setNotificationProps({
        variables: { open: true, message: res.data.updateCustomer.message }
      });
    });
};

/**
 * This function is used to format submitted data back to the form in which backend expects it in variables of
 * mutation. delete some properties, reassign string values to  null etc.
 * @param {Object} submitValues 
 */
const normalizeData = submitValues => {
  delete submitValues.realtor_id;
  delete submitValues.hidden_secondary_emails_2;
  delete submitValues.hidden_secondary_emails_1;
  delete submitValues.formatted_cell_phone_1;
  delete submitValues.formatted_cell_phone_2;
  delete submitValues.display_primary_email_1;
  delete submitValues.display_primary_email_2

  if (submitValues.primary_email_1 === "") {
    submitValues.primary_email_1 = null;
  }
  if (submitValues.cell_phone_1 === "") {
    submitValues.cell_phone_1 = null;
  }
  if (submitValues.primary_email_2 === "") {
    submitValues.primary_email_2 = null;
  }
  if (submitValues.cell_phone_2 === "") {
    submitValues.cell_phone_2 = null;
  }

  return submitValues;
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: props => {
    const { cell_phone_1, cell_phone_2 } = props.data;
    const formatted_cell_phone_1 = phoneDisplay(cell_phone_1);
    const formatted_cell_phone_2 = phoneDisplay(cell_phone_2);
    return { formatted_cell_phone_1, formatted_cell_phone_2, ...props.data };
  },
  handleSubmit: (values, { props, setSubmitting }) => {
    let submitValues = Object.assign({}, values);

    /*Normalize input state according to mutation input format*/
    submitValues = normalizeData(submitValues);

    updateHomeownerSubmit(submitValues, props, setSubmitting);
  },
  validationSchema: addHomeownerSchema,
  displayName: "edit-homeowner-form"
})(HomeownerForm);
