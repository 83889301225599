export const getInitialState = ({ loading, cache }) => {
    return {
      filter: {
        ...(cache && cache.filter)
      },
      report: [],
      limit: (cache && cache.limit) || 10,
      pageNum: (cache && cache.pageNum) || 0,
      pageSize: (cache && cache.limit) || 10,
      userPageCount: 0,
      loading: loading,
      render: !loading
    };
  };
  
  export default function reducer(state, action) {
    switch (action.type) {
      case "UPDATE":
        return Object.assign({}, state, { ...action.payload });
  
      default:
        return state;
    }
  }
  