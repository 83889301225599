
import gql from 'graphql-tag';

const DELETE_LEAD = gql` 
mutation deleteLead($input: DeleteLeadInput!) {
    deleteLead(input: $input) {
      message
      code
    }
  }
`;

export default DELETE_LEAD;