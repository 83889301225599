import React, { useEffect, useState } from "react";
import { Card, Col } from "react-bootstrap";

import CustomButton from "../../../components/custom-button";
import DetailsSectionTable from "../../../components/table/custom-details-table";
import Drawer from "../../../hoc/Drawer";
import AgencyForm from '../forms/create-update-agency'

/**
 * This compoent is used to show basic agency details along with edit agency button. On clicking the button agency
 * form opens up, to edit the information and on submit refetch is called to reflect the changes on the details page
 *
 * @param {Object} props 
 * @param {Object} props.data data containing details
 * @param {Function} props.refetch callback function to refetch the details query
 */
const AgencyDetail = props => {
  const [details, setDetails] = useState({});
  const [showSideBar, setShowSideBar] = useState(false);

  useEffect(() => {
    if (props.data) {
      setDetails({
        basic: {
          "Name": props.data.name,
          "Address": props.data.address,
          "City": props.data.city,
          "State": props.data.state?.name,
          "Zip": props.data.zip,
        }
      });
    }
  }, [props.data]);

  return (
    <Card className="details-card pt-2" as={Col}>
      <Card.Body style={{ padding: "0.5rem", overflow: "hidden" }}>
        <div className="d-flex justify-content-between">
          <h3>Agency Details</h3>
          <div className="edit-button">
            <CustomButton
              size="sm"
              color="green"
              btnValue="Edit"
              onClick={() => setShowSideBar(true)}
            />
          </div>
          <Drawer
            show={showSideBar}
            toggleSideBar={() => setShowSideBar(false)}
          >
            <AgencyForm
              agency={props.data}
              id={props.data?.name}
              onSubmit={() => {
                setShowSideBar(false);
                props.refetch();
              }}
              close={() => setShowSideBar(false)}
            />
          </Drawer>
        </div>

        <div className="details-side-responsive">
          <div className="details-side-section1">
            <DetailsSectionTable data={details.basic ? details.basic : {}} />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default AgencyDetail;
