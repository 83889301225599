import React from "react";
import PropTypes from "prop-types";

/**
 * displays the total records number and the index of the records showing.
 * @param {string} pageIndex 
 * @param {string} pageSize
 * @param {string} totalRecords
 */
const TotalCount = ({ pageIndex, pageSize, totalRecords }) => {
  const pageStart = pageSize * pageIndex;
  const pageEnd = pageStart + pageSize;
  return (
    <span className="help-block pull-left mr-2">{`Showing records ${
      totalRecords > 0 ? pageStart + 1 : 0
    } - ${
      pageEnd > totalRecords ? totalRecords : pageEnd
    } of ${totalRecords}`}</span>
  );
};

TotalCount.propTypes = {
  pageIndex: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalRecords: PropTypes.number.isRequired
};

export default TotalCount;
