import React from "react";
import { useQuery } from "react-apollo-hooks";

import ContentGrid from "../../components/grid/content-grid";
import DashboardSelectBar from "../dashboard/dashboard-select-bar";
import ActivityReport from "./activity-report-container";
import {
  GET_SELECTED_USER,
} from "../../cacheql/queries";

const ActivityReportDashboard = (props) => {
  const {
    data: { selectedUserIds },
  } = useQuery(GET_SELECTED_USER);

  return (
    <ContentGrid
      id="activity-report"
      selectbar={
        <DashboardSelectBar
          page_type="Report"
          withUser
          withDate
          selectedUserIds
          withCommunity
          withDivision
          {...props}
        />
      }
      content={{
        data: <ActivityReport selectedUserIds={selectedUserIds} />
      }}
    />
  );
};

export default ActivityReportDashboard;
