import React from 'react';
import { Navbar } from 'react-bootstrap';
import { FaRegEye } from "react-icons/fa";
import './layout.css';
import  ViewAsLayover  from "../../views/layout/view-as-layover"


export default class NavContainer extends React.Component {
  render() {
    const { children, ...rest } = this.props
    return (
        <Navbar sticky="top" bg="dark" className='sb-navbar-top' {...rest}>
          {children}
        </Navbar>
    );
  }
}
