import gql from "graphql-tag";

export const GET_AUTO_REPLY_WEBFORM_TYPES = gql`
{
    getAutoReplyWebFormTypes{
      id
      name
      description
      auto_reply
    }
  }
`;

export const GET_DEFAULT_OSC_WEBFORM_AUTO_REPLY = gql`
  query getWebFormAutoReplyDefaultOsc {
    getWebFormAutoReplyDefaultOsc {
      monday_osc {
        id
        first_name
        last_name
        email_address
      }
      tuesday_osc {
        id
        first_name
        last_name
        email_address
      }
      wednesday_osc {
        id
        first_name
        last_name
        email_address
      }
      thursday_osc {
        id
        first_name
        last_name
        email_address
      }
      friday_osc {
        id
        first_name
        last_name
        email_address
      }
      saturday_osc {
        id
        first_name
        last_name
        email_address
      }
      sunday_osc {
        id
        first_name
        last_name
        email_address
      }
    }
  }
`;

export const GET_DEFAULT_DIVISION_OSC_WEBFORM_AUTO_REPLY = gql`
  query getWebFormAutoReplyDefaultOscForDivision($division_id: Int!) {
    getWebFormAutoReplyDefaultOscForDivision(division_id: $division_id) {
      monday_osc {
        id
        first_name
        last_name
        email_address
      }
      tuesday_osc {
        id
        first_name
        last_name
        email_address
      }
      wednesday_osc {
        id
        first_name
        last_name
        email_address
      }
      thursday_osc {
        id
        first_name
        last_name
        email_address
      }
      friday_osc {
        id
        first_name
        last_name
        email_address
      }
      saturday_osc {
        id
        first_name
        last_name
        email_address
      }
      sunday_osc {
        id
        first_name
        last_name
        email_address
      }
    }
  }
`;
