import React, { useState, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { getIn } from "formik";
import { DropdownIndicator } from "../new-table";
import { getDescendantProp } from "../new-table/utils";
import "./fields.css";

const CreatableSelectField = props => {
  const {
    id,
    label,
    fieldValueConfig,
    isCreatableSelect,
    options,
    field: { name, value },
    form: { touched, errors, setFieldValue }
  } = props;

  const [defaultValue, setDefaultValue] = useState();

  const style = {
    border: "1px solid #242527",
    backgroundColor: "#121415",
    outline: "none",
    opacity: props.disabled? "0.7 !important": "1 !important"
  };

  const customStyles = {
    control: provided => ({
      ...provided,
      minHeight: '35px',
      ...style,
    }),
    menuList: provided => ({
      ...provided,
      backgroundColor: "black",
      border: "1px solid #292929",
      borderRadius: 4
    }),
    singleValue: provided => ({ ...provided, color: "white" }),
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      color:'inherit',
      backgroundColor: "inherit",
      opacity: isSelected ? "0.5": '1',
      "&:hover": {
        backgroundColor: "#333",
        color: "#80b602",
        cursor: "pointer"
      }
    }),
    input: provided => ({ ...provided, color: "white" }),
    indicatorContainer: provided => ({ ...provided, padding: "5px" }),
    indicatorSeparator: () => ({ display: "none" }),
  };
  

  const changeHandler = selected => {
    //This function handles values change for both CreatableSelect and Select component
    if (fieldValueConfig) {
      fieldValueConfig.forEach(({ field, property }) => {
        let fieldValue = selected ? getDescendantProp(selected.value, property) : "";
        if (selected && selected.__isNew__) {
          // This parts sets value for the fields agency_name or realtor_id if field matchs in the list value

          fieldValue = field === name ? selected.value : "";
        }
        setFieldValue(field, fieldValue, true);
      });
    } else {
      //Setting form field value if there is no match
      setFieldValue(name, selected ? selected.value?.id : "", true);
    }

    //setDefaultValue function sets whatever prop is passed to changedHandler on each value select or de-select.
    setDefaultValue(selected);
  };

  const error = getIn(errors, name);
  const touch = getIn(touched, name);
  const errorMessage = touch && error;

  const property =
    fieldValueConfig &&
    fieldValueConfig.find(({ field }) => field === name).property;
  const selectedValue = isCreatableSelect
    ? options.find(o =>
        property ? o.value[property] === value : o.value === value
      )
    : options.find(o => {
        if(!value) return null
        if (o.value.id === value) {
          return `${o.value.first_name} ${o.value.last_name}`;
        }
        if(o.value.name === value){
          return o.value.name
        }
        return null
      });

  useEffect(() => {
    props.form.values && setDefaultValue(selectedValue);
  }, [options]);
  return (
    <div id={id + "-form-group"} className="form-group">
      <label htmlFor={id + "-input"} className="control-label">
        {label}
      </label>
      {isCreatableSelect ? (
        <CreatableSelect
          id={id + "-creatable-select"}
          styles={customStyles}
          components={{ DropdownIndicator }}
          onChange={changeHandler}
          value={defaultValue}
          options={options}
          isClearable
          {...props}
          className={errorMessage ? " is-invalid" : ""}
        />
      ) : (
        <Select
          id={id + "-creatable-select"}
          styles={customStyles}
          components={{ DropdownIndicator }}
          onChange={changeHandler}
          value={defaultValue}
          options={options}
          isDisabled={props.disabled}
          isClearable
          {...props}
          className={errorMessage ? " is-invalid" : ""}
        />
      )}
      {error && (
        <div className="invalid-feedback" style={{ display: "block" }}>
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export { CreatableSelectField };
