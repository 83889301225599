import React from "react";

import * as actionTypes from "./action-types";

/**
 * this is a custom hook that handles pagination state on table.
 * it contains the getPage, nextPage, previousPage and pageSize handlers.
 * @param {object} state 
 * @param {function} dispatch 
 * @returns {object}
 */
export default function usePagniation(state, dispatch) {
  const {
    currentPage,
    pageSize,
    manualPagination,
    userPageCount,
    // userTotalCount,
    rows,
    rowsLength
  } = state;

  // const getControlledPage = ()=>{
  //   //current total number of page's records;
  //   const pageRecord  = Math.ceil(rowsLength/pageSize)
  //   const pageEnd = pageRecord * pageSize;
  //   const pageStart = pageEnd - pageSize;
    
  //   return rows.slice(pageStart, pageEnd);
  // }

 
  const getPage = () => {
    if(manualPagination) return rows;

    let page;
    const pageStart = pageSize * currentPage;
    const pageEnd = pageStart + pageSize;

    page = rows.slice(pageStart, pageEnd);
    return page;
  };

  const setPageOnDataUpdate = () => {
    const page = getPage();

    //only go to page '0' if current page does not have records
    if (page.length <= 0 && !manualPagination) {
      dispatch({
        type: actionTypes.UPDATE_PAGE_ON_UPDATE,
        payload: { currentPage: 0 }
      });
    }
    // dispatch({
    //   type: actionTypes.UPDATE_PAGE_ON_UPDATE,
    //   payload: { currentPage: 0 }
    // });
  };
  React.useMemo(() => setPageOnDataUpdate(), [rowsLength]);

  const pageCount = manualPagination
    ? userPageCount
    : Math.ceil(rows.length / pageSize);

    // const totalRecords = manualPagination ? userTotalCount : rowsLength;

  //const page = React.useMemo(() => getPage(rows), [pageSize, currentPage, state.sortColumn.id, state.sortColumn.sort, rows, rowsLength]);

  const page = getPage();
  const canPreviousPage = currentPage > 0;
  const canNextPage = pageCount === -1 || currentPage < pageCount - 1;

  const gotoPage = pageIndex => {
    if (pageIndex < 0 || pageIndex > pageCount - 1) {
      return;
    }
    dispatch({
      type: actionTypes.GOTO_PAGE,
      payload: { currentPage: pageIndex }
    });
  };
  const previousPage = () => {
    gotoPage(currentPage - 1);
  };

  const nextPage = () => {
    gotoPage(currentPage + 1);
  };

  const setPageSize = pageSize => {
    const topRowIndex = state.pageSize * state.currentPage;
    const currentPage = Math.floor(topRowIndex / pageSize);
    dispatch({
      type: actionTypes.SET_PAGE,
      payload: { currentPage, pageSize }
    });
  };

  return {
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    previousPage,
    nextPage,
    setPageSize
  };
}
