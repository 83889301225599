import * as Yup from "yup";

export const ActivityReportFilterForm = {
  type: "",
  traffic: false
};

export const ActivityReportSchema = Yup.object().shape({
  type: Yup.string()
    .default("All Activity Types")
    .nullable(),
  traffic: Yup.bool().default(false)
});