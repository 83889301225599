import React, { useState, useEffect } from "react";
import { defaults, editorClassName } from "./editor-constants";
import { EditorState } from "draft-js";
import { EditorControls } from "./editor-controls";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { isNotNULL, upperCaseFirstLetter } from "../../utils/helpers";
import {
  decorator,
  convertEditorToHTML,
  loadEditorFromHTML,
  getInitialTags,
  getInnerHTML,
  renderToStaticMarkup,
  replace
} from "./editor-helpers";
import TextEditor from "./editor";
import CustomButton from "../custom-button";
import LoadingWrapper from "../loading-wrapper";
import "./rich-text-editor.css";

/**
 * @module RichTextEditorModule
 */

/**
 * 
 * @param {object} props component props
 * @param {object} baseHtmlContent contains all the information related to component and the template.
 */
const RichTextEditor = ({ baseHtmlContent, ...props }) => {
  const [templateId, setTemplateId] = useState("");
  const [subject, setSubject] = useState("");
  const [placeholders, setPlaceholders] = useState([]);
  const [selectedSection, setSelectedSection] = useState(null);
  const [logoImage, setLogoImage] = useState(null);
  const [bodyHeader, setBodyHeader] = useState(null);
  const [bodyImage, setBodyImage] = useState(null);
  const [bodyContent, setBodyContent] = useState(null);
  const [bodyLink, setBodyLink] = useState(null);
  const [editorState, setEditorState] = useState(
    EditorState.createEmpty(decorator)
  );

  const [settings, setTemplateSettings] = useState({
    color: "black",
    textAlign: "left",
  });

  useEffect(() => {
    if (baseHtmlContent) {
      setSubject(baseHtmlContent.subject);
      setContent(baseHtmlContent.body);
      setPlaceholders(baseHtmlContent.placeholders);
      setTemplateId(baseHtmlContent.id);
      setEditorState(EditorState.createEmpty(decorator));
    } else reset();
  }, [baseHtmlContent]);


  const reset = () => {
    setSubject("");
    setSelectedSection(null);
    setLogoImage(null);
    setBodyHeader(null);
    setBodyImage(null);
    setBodyContent(null);
    setBodyLink(null);
    setEditorState(EditorState.createEmpty(decorator));
  };

  const getContentDivs = () => {
    return [logoImage, bodyHeader, bodyImage, bodyContent, bodyLink];
  };

  /**
   * this function updates the content and specifies the template settings
   * @function
   * @inner
   * @memberof module:TextEditorModule
   * @param {string} content contains the html body of the template
   */

  const setContent = content => {
    let node = document.getElementById("hiddenDivforEmailContent");
    node.innerHTML += content;
    let wrapperContentDivStyle =
      document.querySelectorAll("table.wrapper-content")[0] &&
      document.querySelectorAll("table.wrapper-content")[0].style;
    wrapperContentDivStyle &&
      handleWrapperSettings({
        background: wrapperContentDivStyle.backgroundColor,
        fontColor: wrapperContentDivStyle.color,
        width: wrapperContentDivStyle.width,
        align: wrapperContentDivStyle.textAlign,
        linkBg:
          document.querySelectorAll("td#body-link")[0] &&
          document.querySelectorAll("td#body-link")[0].style.backgroundColor
      });
    let divs = document.querySelectorAll("td.section");
    if (divs.length > 0) {
      divs.forEach(function (divItem) {
        updateContent(divItem.innerHTML, divItem.id);
      });

      node.innerHTML = "";
    } else {
      setContent(getInitialTags());
    }
  };

  const onChange = (editorState, type) => {
    setEditorState(editorState);
    if (type) handleUpdate(type, null, editorState);
  };

  const setHTMLContent = (html, type) => {
    setSelectedSection(type);
    loadEditorFromHTML(html, onChange, type);
  };

  const handleUpdate = (type, noDefault, estate) => {
    let content = convertEditorToHTML(estate ? estate : editorState);
    setSelectedSection(type);
    updateContent(content, null, noDefault, type);
  };

  const handleSave = type => {
    type && handleUpdate(type, true);
    props.save(replace(renderToStaticMarkup(getEmailContent())), subject);
    setEditorState(EditorState.createEmpty(decorator));
    setSelectedSection(null);
  };

  /**
   * this function updates the content and specifies the template settings
   * @function
   * @inner
   * @memberof module:TextEditorModule
   * @param {string} contentItem contains the html body of the template
   * @param {string} id contains the id of the eachsection 
   * @param {object} noDefault 
   * @param {object} sectionType it specifies the section type 
   */
  const updateContent = (contentItem, id, noDefault, sectionType) => {
    const type = id ? id.split("-") : sectionType;
    id = id ? id : sectionType[0] + "-" + sectionType[1];

    let styles =
      id === "body-link"
        ? {
          padding: "0px",
          background: settings.linkBg,
          display: "inline-block",
          width: "200px",
          textAlign: "center",
          color: "white",
          borderRadius: "4px",
          height: "auto !important"
          //whiteSpace: "nowrap"
        }
        : id === "body-text"
          ? {
            padding: "0"
          }
          : { padding: "0px", height: "auto !important" };

    switch (type[1]) {
      case "subject":
        contentItem = contentItem.replace(/<code>|<\/code>/gm, "");
        let span = document.createElement("span");
        span.innerHTML = contentItem;
        let subj = span.textContent || span.innerText;
        setSubject(subj);
        return;
      case "header":
        setBodyHeader(
          getSection(defaults[1], id, contentItem, styles, noDefault)
        );
        return;
      case "image":
        if (type[0] === "logo") {
          setLogoImage(
            getSection(defaults[0], id, contentItem, styles, noDefault)
          );
        } else if (type[0] === "body") {
          setBodyImage(
            getSection(defaults[0], id, contentItem, styles, noDefault)
          );
        }
        return;
      case "text":
        contentItem = contentItem.replace(/<code>|<\/code>/gm, "");
        setBodyContent(
          getSection(defaults[2], id, contentItem, styles, noDefault)
        );
        return;
      case "link":
        setBodyLink(
          getSection(defaults[3], id, contentItem, styles, noDefault)
        );
        return;
      default:
        return null;
    }
  };

  /**
 * this function is used for rendering the blocks 
 * @function
 * @inner
 * @memberof module:TextEditorModule
 * @param {string | object} divItem contains the html body of the template or object information regarding the body
 * @param {object} defaultItem 
 * @param {boolean} innerHtmlCheck checks for innerHtml 
 * @returns {JSX.Element}
 */
  const compare = (divItem, defaultItem, innerHtmlCheck) => {
    if (innerHtmlCheck) {
      divItem =
        divItem.props.id === "body-link"
          ? getInnerHTML(
            renderToStaticMarkup(divItem).replace(
              /<div>|<p>|<\/div>|<\/p>/gm,
              ""
            )
          )
          : divItem;
      let tempItem = divItem && divItem.props.children;
      tempItem = replace(renderToStaticMarkup(tempItem));
      let { children, ...rest } = divItem.props;
      let check;
      defaults.forEach(item => {
        if (item === tempItem) {
          check = true;
        }
      });
      let tdItem = <td {...rest}></td>;

      return check ? tdItem : divItem;
    } else {
      divItem = replace(divItem);

      if (divItem === "<p></p>" || divItem === "<h1></h1>" || divItem === " ")
        return true;
      if (defaultItem && defaultItem === divItem) return true;
      return false;
    }
  };

  /**
   * this function return the html element of the sections
   * @function
   * @inner
   * @memberof module:TextEditorModule
   * @param {string} divItem contains the default html element of specific section
   * @param {string} id contains the id of the eachsection 
   * @param {string} divItem contains the html of the selected section
   * @param {object} styles contains the style of each section
   * @param {object} noDefault checks for innerHtml 
   * @returns {JSX.Element}
   */
  const getSection = (defaultItem, id, divItem, styles, noDefault) => {
    let item = compare(divItem) ? defaultItem : divItem;
    if (noDefault && compare(divItem, defaultItem)) item = " ";
    return (
      <td id={id} className="section has-data" style={styles}>
        {item ? getInnerHTML(item) : item}
      </td>
    );
  };

  /**
   * this function is to render the email content
   * @function
   * @inner
   * @memberof module:TextEditorModule
   * @returns {JSX.Element}
   */
  const getEmailContent = () => {
    return (
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <table
          className="wrapper-content"
          style={{
            color: settings.color,
            textAlign: settings.textAlign,
            maxWidth: "800px"
          }}
        >
          {getContentDivs().map((divItem, key) => {
            let tdItem = compare(divItem, null, true);
            let rowItem = <tr key={key}>{tdItem}</tr>;
            return rowItem;
          })}
        </table>
      </div>
    );
  };

  const handleWrapperSettings = ({
    background,
    fontColor,
    align,
    width,
    linkBg
  }) => {
    setTemplateSettings({

      color: fontColor || settings.color,
      textAlign: align || settings.textAlign,
      width: width || settings.width,
      linkBg: linkBg || settings.linkBg
    });
  };

  return (
    <LoadingWrapper
      loading={props.loading}
      component={

        <div className="RichEditor-root d-flex flex-column">
          <div className="d-flex flex-sm-column-custom">
            <div className="w-100 mr-3">
              <EditorControls
                emailSubject={subject}
                emailContent={() => getEmailContent()}
                settings={settings}
                handleSettings={handleWrapperSettings}
                handleSave={handleSave}
                {...props}
              />
              <div className="editor-sections-panel">
                {baseHtmlContent && <h5 className="section-header">Subject</h5>}
                <WrapperEditOverlay
                  callback={setHTMLContent}
                  selectedClass={
                    baseHtmlContent && !isNotNULL(subject)
                      ? "error-section"
                      : "email-subject" === selectedSection &&
                        selectedSection[0] + "-" + selectedSection[1]
                        ? "selected-section"
                        : ""
                  }
                >
                  <div className="subject" id="email-subject">
                    <p>{subject}</p>
                  </div>
                </WrapperEditOverlay>
                {baseHtmlContent && !isNotNULL(subject) ? (
                  <span style={{ color: "red" }}>Subject is Required</span>
                ) : null}
                <br></br>
                {baseHtmlContent && <h5 className="section-header ">Body</h5>}
                {getContentDivs().map((divItem, key) => {
                  const selectedClass =
                    divItem && selectedSection && divItem.props.id
                      ? divItem.props.id ===
                      selectedSection[0] + "-" + selectedSection[1]
                      : false;
                  return (
                    <WrapperEditOverlay
                      key={key}
                      callback={setHTMLContent}
                      selectedClass={selectedClass ? "selected-section" : ""}
                    >
                      {divItem ? divItem : <div className="" id="default-component"></div>}
                    </WrapperEditOverlay>
                  );
                })}
              </div>
            </div>

            {/* Edit Panel */}
            <div id="text-editor-panel" className="w-100">
              <div className="d-flex justify-content-end w-100 mb-3">
                <CustomButton
                  btnValue={"Save & Close"}
                  color="black"
                  onClick={() => handleSave(selectedSection)}
                  className="btn custom-btn-green"
                  style={{
                    visibility:
                      baseHtmlContent && selectedSection ? "visible" : "hidden"
                  }}
                />
              </div>
              <TextEditor
                templateId={templateId}
                type={selectedSection}
                editorState={editorState}
                _onChange={onChange}
                editorClassName={editorClassName}
                valueSet={
                  baseHtmlContent !== null &&
                  selectedSection &&
                  selectedSection[1] !== "subject"
                }
                placeholders={placeholders}
              />
            </div>
          </div>
          <div hidden id="hiddenDivforEmailContent" />
        </div>
      }
    />

  );
};

const WrapperEditOverlay = ({
  children,
  callback,
  selectedClass,
  ...props
}) => {
  return (
    <OverlayTrigger
      placement="left"
      overlay={
        children && (
          <Tooltip>
            {upperCaseFirstLetter(children.props.id.replace(/-/gm, " "))}
          </Tooltip>
        )
      }
    >
      <div
        onMouseDown={e => {
          let temp = children.props.id.split("-");
          callback(renderToStaticMarkup(children.props.children), temp);
          e.stopPropagation();
        }}
        className={`section-wrapper ${selectedClass}`}
        id="section-wrapper"
        style={{ minHeight: "20px", marginBottom: "10px" }}
      >
        {children}
      </div>
    </OverlayTrigger>
  );
};

export default RichTextEditor;