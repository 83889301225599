import gql from 'graphql-tag';

const ADD_HOMEOWNER_PHONE_CALL = gql` 
  mutation addHomeownerPhoneCall($input: HomeownerPhoneCallInput!) {
    addHomeownerPhoneCall(input: $input) {
      message
      code
    }
  }
`;

export default ADD_HOMEOWNER_PHONE_CALL;