import gql from 'graphql-tag';

export default gql`
query GetRealtors($limit: Int, $pageNum: Int, $filter: RealtorFilter, $order: [String], $mode:RealtorsMode){
    getRealtors(limit: $limit, pageNum: $pageNum, filter: $filter, order: $order, mode:$mode){
      realtors {
        id
        first_name
        last_name
        primary_email
        primary_email_status
        Agency{
          name
          address
          city
          state{
            id
            name
          }
          zip
        }
        cell_phone
        is_listing_agent_of_sb
        sales_count
        registration_count
        Realtor_Other_Emails {
          email_address
        }
        Communities {
          id
          name
        }
        division {
          id
          name
        }
      }
      total_count
    }
  }
`

export const ALL_REALTORS_NAMES = gql`
query{
  getRealtorsData{
    id
    first_name
    last_name
  }
}
`
export const GET_SALES_TO_REALTOR_COUNT = gql`
query getSalesByRealtorsCount($input: DivisionFilter) {
  getSalesByRealtorsCount(filter: $input) {
    count
    percent
  }
}
`

export const GET_SALES_BY_COMMUNITY_COUNT = gql`
query getSalesByCommunityCount($input: DivisionFilter){
  getSalesByCommunityCount(filter: $input)
}
`

export const GET_YOY_REGISTRATIONS_COUNT = gql`
query getRegistrationsByRealtorsCount($input: DivisionFilter){
	getRegistrationsByRealtorsCount(filter: $input)
}
`

export const GET_ALL_REALTOR_CLIENT_REGISTRATIONS_COUNT = gql`
query getAllRealtorClientRegistrations($input: RegistrationsFilter){
	getAllRealtorClientRegistrations(filter: $input){
    total_count
  }
}
`

export const GET_SALES_BY_COMMUNITY = gql`
query getSalesByCommunities( $filter: DivisionFilter){
  getSalesByCommunities( filter: $filter) {
  	sales{
      community{
        id
        name
        is_active
      }
      division{
        id
        name
      }
      realtor_sales_count
      activeSellers{
        first_name
        last_name
        sales_count
      }
    }
  }
}
`

export const GET_ALL_REALTOR_CLIENT_REGISTRATIONS = gql`
query getAllRealtorClientRegistrations($limit: Int, $pageNum: Int, $filter: RegistrationsFilter, $search: String $sort: [String]){
	getAllRealtorClientRegistrations(limit: $limit, pageNum: $pageNum, filter: $filter, search: $search sort: $sort){
    realtor_client_registrations{
      customer_id
      first_name_1
      last_name_1
      primary_email_1
      primary_email_1_status
      cell_phone_1
      secondary_emails_1
      first_name_2
      last_name_2
      primary_email_2
      primary_email_2_status
      cell_phone_2
      secondary_emails_2
      created_at
      Realtor{
        id
        first_name
        last_name
        primary_email
        primary_email_status
        cell_phone
        Agency{
          name
        }
      }
    }
    total_count
  }
}
`
