import React from "react";
import { isNotNULL } from "../../utils/helpers";
import "./lists.css";

/**
 * This component is used to show a bulleted list with an icon, a list title(optional) and list Item
 * @param {Object} props
 * @param {string} props.header The heading of the list
 * @param {Object} props.icon The icon to show as the bullet
 * @param {Object} props.list The list to display
 * @param {string} prop.title_accessor (Optional) The accessor for the title of the text line
 * @param {string} props.text_accessor The accessor for the text line 
 */
const BulletedList = ({
  header,
  icon,
  list,
  title_accessor,
  text_accessor,
}) => {
  return (
    <>
      {list && list.length ? (
        <>
          <h2 className="list-header">{header}</h2>
          {list.map((item, key) => {
            return (
              <div className="list-body" key={key}>
                <div className="list-title">
                  <img src={icon} alt="" />
                  <span>
                  {/*If title_accessor is defined, only then the title should be applied */}
                  {isNotNULL(item[title_accessor])
                    ? item[title_accessor] + ": "
                    : null}
                    </span>
                </div>
                <p className="list-item">{item[text_accessor]}</p>
              </div>
            );
          })}
        </>
      ) : null}
    </>
  );
};

export default BulletedList;
