import gql from 'graphql-tag';

const DELETE_LEAD_PHONE_CALL = gql` 
  mutation deleteLeadPhoneCall($input: ID!) {
    deleteLeadPhoneCall(phone_call_id: $input) {
      message
      code
    }
  }
`;

export default DELETE_LEAD_PHONE_CALL;

