import gql from "graphql-tag";

export const GET_ALL_STATUSES = gql`
  query getAllStatuses {
    getAllStatuses {
      id
      name
    }
  }
`;
