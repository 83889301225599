import React, { useState, useEffect } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Form, Field, withFormik } from "formik";
import {
  leadFilterSchema,
  leadFilterForm
} from "../../../utils/lead-filter-form-schema";
import SelectField from "../../../components/fields/select-field";
import TextField from "../../../components/fields/text-field";
import DatePickerView from "../../../components/fields/date-picker";
import CustomButton from "../../../components/custom-button";
import ErrorFocus from "../../../components/error-focus/error-focus";
import {
  isEmpty,
  isNotNULL,
  sanitizeFilterObject,
  isDateInRange,
  isDateValid
} from "../../../utils/helpers";
import * as cardsType from "../dashboard/lead-card-types";
import "./lead-form-styles.css";
import moment from "moment";

/**
 * This component is used to render in the lead filter popup. It renders the actual leadt filter form
 * {@link LeadFilterForm} and the dropdown at the top to show the saved filters list. On selection of any saved
 * filter, its values are assigned to the homeowner filter form.
 * @param {Object} props
 */
const LeadFilter = (props) => {
  const [appliedFilterName, setAppliedFilterName] = useState(null);
  const [values, setValues] = useState({});
  const [isValid, setIsValid] = useState(true);

  const { currentCard } = props;
  const applySaveFilters = (filterId, filterName) => {
    setAppliedFilterName(filterName);
    if (!filterId) {
      setIsValid(true);
      setValues({});
    } else {
      props.getLeadFilterDetail.fetchMore({
        variables: { input: filterId },
        updateQuery: (prev, { fetchMoreResult }) => {
          const { created_from, created_to } = fetchMoreResult.getLeadFilter;
          if (currentCard.type === cardsType.THIS_MONTH) {
            if (
              !isDateInRange(
                created_from,
                currentCard.filter.create_start_date,
                currentCard.filter.create_end_date
              ) ||
              !isDateInRange(
                created_to,
                currentCard.filter.create_start_date,
                currentCard.filter.create_end_date
              )
            ) {
              setIsValid(false);
              setValues({ ...leadFilterForm });
            } else {
              setIsValid(true);
              setValues(fetchMoreResult.getLeadFilter);
            }
          } else if (currentCard.type === cardsType.TODAY) {
            if (
              !isDateValid(
                created_from,
                currentCard.filter.create_start_date,
                currentCard.filter.create_end_date
              )
            ) {
              setIsValid(false);
              setValues({ ...leadFilterForm });
            } else {
              setIsValid(true);
              setValues(fetchMoreResult.getLeadFilter);
            }
          } else {
            setValues(fetchMoreResult.getLeadFilter);
          }
        },
      });
    }
  };
  return (
    <div>
      <div className="form-row align-items-center mb-3">
        <div className="col-6">
          <h6 className="mb-0">Filters</h6>
        </div>
        <div className="col-6" style={{ textAlign: "right" }}>
          <DropdownButton
            variant="secondary"
            id="filter-item-btn"
            key="down"
            title={
              appliedFilterName ||
              props.filter.filter_name ||
              "My Saved Filters"
            }
            className="filters-my-saved"
          >
            {isNotNULL(props.getLeadFilters.getLeadFilters) &&
              props.getLeadFilters.getLeadFilters.map((item) => {
                return (
                  <Dropdown.Item
                    onSelect={() => applySaveFilters(item.id, item.filter_name)}
                    key={item.id}
                    eventKey={item.id}
                  >
                    {item.filter_name}
                  </Dropdown.Item>
                );
              })}
          </DropdownButton>
        </div>
      </div>
      {!isValid && (
        <div className="filter-error">
          <label className="filter-error-text">
            This filter is not applicable on current tab
          </label>
        </div>
      )}

      <LeadFilterFormIkForm
        savedValues={values}
        isDisabled={!isValid}
        appliedFilterName={appliedFilterName}
        {...props}
      />
    </div>
  );
};

/**
 * This component is used to render lead filter form. It contains created from-to, last activity from-to fields as well as state, phone, lead source and lead note in advance section. At the
 * bottom it contains an input field to name the filter and save it. Apply filter button remains disabled unless some
 * value on filter fields is selected. And advance filter will show some additional fields to add in the filter. On submit setFilter is called, which triggers table refetch.
 * @param {Object} props
 */
const LeadFilterForm = (props) => {
  const {
    values,
    setFilter,
    setShow,
    currentCard,
    isDisabled,
    appliedFilterName
  } = props;
  // const {filter_name: _, ...filterValue} = values;

  useEffect(() => {
    if (appliedFilterName !== null) {
      props.resetForm({ ...leadFilterForm });
    }
  }, [appliedFilterName]);

  const enableFormSubmit = ({ filter_name: _, ...valueCompareFrom }) => {
    const filters = sanitizeFilterObject(Object.assign({}, valueCompareFrom));
    return isEmpty(filters);
  };

  const handleFilters = () => {
    // const {filter_name: _, ...filterValue} = values;
    /*
    To enable front end filtering, do not call this function, simply uncomment above
    code and pass 'filterValue' to 'setFilter'.
    */
    setFilter(getLeadFilterInput(values, "APPLY", currentCard));
    setShow(false);
  };

  const advanceFilterHandler = () => {
    if (!values.advanceFilters) {
      props.setFieldValue("state.id", undefined);
      props.setFieldValue("cell_phone_1", "");
      props.setFieldValue("customer_origination_area.id", undefined);
      props.setFieldValue("lead_classification_filter.id", undefined)
    }
    props.setFieldValue("advanceFilters", !values.advanceFilters);
  };
  return (
    <Form className="filter-menu">
      <div className="form-row">
        <div className="col-6 col-md-6">
          <Field
            label="Created From"
            name="created_at[0]"
            id="created_at[0]"
            disabled={isDisabled}
            minDate={
              currentCard.type === cardsType.TODAY ||
                currentCard.type === cardsType.THIS_MONTH ||
                currentCard.type === cardsType.THIS_YEAR
                ? new Date(currentCard.filter.create_start_date)
                : null
            }
            maxDate={
              currentCard.type === cardsType.TODAY
                ? new Date(currentCard.filter.create_start_date)
                : currentCard.type === cardsType.THIS_MONTH ||
                  currentCard.type === cardsType.THIS_YEAR
                  ? new Date(currentCard.filter.create_end_date)
                  : new Date()
            }
            component={DatePickerView}
          />
        </div>
        <div className="col-6 col-md-6">
          <Field
            label="Created To"
            name="created_at[1]"
            id="created_at[1]"
            disabled={isDisabled}
            minDate={
              currentCard.type === cardsType.TODAY ||
                currentCard.type === cardsType.THIS_MONTH ||
                currentCard.type === cardsType.THIS_YEAR
                ? new Date(currentCard.filter.create_start_date)
                : null
            }
            maxDate={
              currentCard.type === cardsType.TODAY
                ? new Date(currentCard.filter.create_start_date)
                : currentCard.type === cardsType.THIS_MONTH ||
                  currentCard.type === cardsType.THIS_YEAR
                  ? new Date(currentCard.filter.create_end_date)
                  : new Date()
            }
            component={DatePickerView}
          />
        </div>

        <div className="col-6 col-md-6">
          <Field
            label="Last Activity From"
            name="updated_at[0]"
            id="updated_at[0]"
            maxDate={new Date()}
            disabled={isDisabled}
            component={DatePickerView}
          />
        </div>
        <div className="col-6 col-md-6">
          <Field
            label="Last Activity To"
            name="updated_at[1]"
            id="updated_at[1]"
            maxDate={new Date()}
            disabled={isDisabled}
            component={DatePickerView}
          />
        </div>
      </div>

      <div id="advanceFilters" hidden={!values.advanceFilters}>
        <div className="form-row">
          <div className="col-md-6">
            <Field
              name="state.id"
              label="State"
              id="state.id"
              classStyle="filter-select-field"
              disabled={
                isDisabled
                  ? true
                  : props.getAllStates.getAllStates
                    ? false
                    : true
              }
              component={SelectField}
            >
              <option value="">Select...</option>
              {props.getAllStates.getAllStates &&
                props.getAllStates.getAllStates.map((state) => (
                  <option key={state.id} value={state.id}>
                    {state.name}
                  </option>
                ))}
            </Field>
          </div>
          <div className="col-6 col-md-6">
            <Field
              label="Phone"
              name="cell_phone_1"
              id="cell_phone_1"
              type="text"
              disabled={isDisabled}
              component={TextField}
              className="form-control"
            />
          </div>
        </div>

        <div className="form-row">
          <div className="col-md-6">
            <Field
              label="Lead Source"
              name="customer_origination_area.id"
              id="customer_origination_area.id"
              classStyle="filter-select-field"
              disabled={isDisabled}
              component={SelectField}
              style={{ width: "100%" }}
            >
              <option value={null}>Select...</option>
              {props.getOriginationAreas.getAllCustomer_Origination_Areas &&
                props.getOriginationAreas.getAllCustomer_Origination_Areas.map(
                  (option, index) => (
                    <option key={index} value={option.id}>
                      {option.name}
                    </option>
                  )
                )}
            </Field>
          </div>
          <div className="col-md-6">
            <Field
              label="Lead Notes"
              name="other_notes"
              id="other_notes"
              type="text"
              disabled={isDisabled}
              component={TextField}
              className="form-control"
            />
          </div>
        </div>

        <div className="form-row">
          <div className="col-md-6">
            <Field
              label="Lead Classification"
              name="lead_classification_filter.id"
              id="lead_classification_filter.id"
              classStyle="filter-select-field"
              disabled={isDisabled}
              component={SelectField}
              style={{ width: "100%" }}
            >
              <option value="">Select...</option>
              {props.getLeadClassificationFilters.getAllLeadClassificationFilters &&
                props.getLeadClassificationFilters.getAllLeadClassificationFilters.map(
                  (option, index) => (
                    <option key={index} value={option.id}>
                      {option.name}
                    </option>
                  )
                )}
            </Field>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-12">
          <div className="form-row d-flex align-items-center pl-1 pr-1">
            <CustomButton
              btnValue="APPLY FILTERS"
              className="filter-apply-btn"
              disabled={isDisabled || enableFormSubmit(values)}
              onClick={handleFilters}
            />

            {/* <a
              onClick={advanceFilterHandler}
              href="javascript:void(0)"
              className="ml-2 d-block"
            >
              {values.advanceFilters ? "Basic Filters" : "Advance Filters"}
            </a>   */}

            <CustomButton
              onClick={advanceFilterHandler}
              className="d-block"
              style={{
                border: "none",
                background: "none",
                color: "#80b602",
                opacity: 1,
                fontWeight: 400,
                fontSize: "14px",
                textTransform: "none",
              }}
            >
              {values.advanceFilters ? "Basic Filters" : "Advance Filters"}
            </CustomButton>
          </div>
        </div>
      </div>
      <hr className="light" />
      <h6 className="mb-3">My Filters</h6>
      <div className="form-row px-1 align-content-center">
        <Field
          label="Filter Name"
          name="filter_name"
          id="filter_name"
          type="text"
          disabled={isDisabled}
          component={TextField}
          // className="form-control"
          style={{ borderColor: "rgba(255, 255, 255, .2)" }}
        />
        <div className="d-flex align-items-center ml-1 mt-1">
          <CustomButton
            btnValue="SAVE FILTER"
            type="submit"
            color="green"
            disabled={
              props.isSubmitting || isDisabled || enableFormSubmit(values)
            }
          />
        </div>
      </div>
      <ErrorFocus />
    </Form>
  );
};

/**
 * this function is used, when a filter is selected from the top dropdown, it checks if there are any advance filter values, if there are it shows the the field, otherwise it keeps them hidden.
 * @param {object} state
 * @param {string} phone
 * @param {string} note
 * @param {object} Customer_Origination_Area
 * @returns {boolean}
 */
const checkAddvanceFilter = (State, phone, note, Customer_Origination_Area, Lead_Classification_Filter) => {
  if (
    isNotNULL(State) ||
    isNotNULL(phone) ||
    isNotNULL(note) ||
    isNotNULL(Customer_Origination_Area) ||
    isNotNULL(Lead_Classification_Filter)
  ) {
    return true;
  }
  return false;
};

/* 
 This function formats form-values to the expected 'apply-filter' 
 and 'save-filter' input format. Filter Schema saves values 
 differently so we can easily apply front-end filtering if we want. 
*/
const getLeadFilterInput = (
  {
    customer_origination_area,
    lead_classification_filter,
    state,
    cell_phone_1: phone,
    other_notes: note,
    created_at,
    updated_at,
    filter_name
  },
  type,
  currentCard
) => {
  const common = {
    customer_origination_area_id:
      (customer_origination_area && customer_origination_area.id) || undefined,
    lead_classification_filter_id:
      (lead_classification_filter && lead_classification_filter.id) || undefined,
    state_id: (state && state.id) || undefined,
    filter_name: filter_name && filter_name.trim(),
    phone,
    note
  };
  return type === "APPLY"
    ? {
      ...common,
      create_start_date:
        (created_at ||
          currentCard.type === cardsType.TODAY ||
          currentCard.type === cardsType.THIS_MONTH) &&
        (created_at[0] || currentCard.filter?.create_start_date || null),
      create_end_date:
        (created_at ||
          currentCard.type === cardsType.TODAY ||
          currentCard.type === cardsType.THIS_MONTH) &&
        (created_at[1] || currentCard.filter?.create_end_date || null),
      update_start_date: updated_at && (updated_at[0] || null),
      update_end_date: updated_at && (updated_at[1] || null),
    }
    : {
      ...common,
      created_from:
        created_at &&
        (created_at[0] ? moment(created_at[0]).format("LL") : null),
      created_to:
        created_at &&
        (created_at[1] ? moment(created_at[1]).format("LL") : null),
      last_activity_from:
        updated_at &&
        (updated_at[0] ? moment(updated_at[0]).format("LL") : null),
      last_activity_to:
        updated_at &&
        (updated_at[1] ? moment(updated_at[1]).format("LL") : null),
    };
};

/* 
 This function formats filter/saved values to the 
 expected form-values input format. 
*/
const getLeadFormFilterInput = (toExtract, type) => {
  const { phone, note } = toExtract;
  const common = {
    ...leadFilterForm,
    other_notes: note,
    cell_phone_1: phone,
    filter_name: toExtract.filter_name
  };

  return type === "FILTER"
    ? {
      ...common,
      advanceFilters: checkAddvanceFilter(
        toExtract.state_id,
        phone,
        note,
        toExtract.customer_origination_area_id,
        toExtract.lead_classification_filter_id
      ),
      state: { id: toExtract.state_id },
      customer_origination_area: {
        id: toExtract.customer_origination_area_id,
      },
      lead_classification_filter: {
        id: toExtract.lead_classification_filter_id
      },
      created_at: [toExtract.create_start_date, toExtract.create_end_date],
      updated_at: [toExtract.update_start_date, toExtract.update_end_date],
    }
    : {
      ...common,
      advanceFilters: checkAddvanceFilter(
        toExtract.State,
        phone,
        note,
        toExtract.Customer_Origination_Area,
        toExtract.Lead_Classification_Filter
      ),
      state: { id: (toExtract.State && toExtract.State.id) || undefined },
      customer_origination_area: {
        id:
          (toExtract.Customer_Origination_Area &&
            toExtract.Customer_Origination_Area.id) ||
          undefined,
      },
      lead_classification_filter: {
        id: (toExtract.Lead_Classification_Filter && toExtract.Lead_Classification_Filter.id) || undefined
      },
      created_at: [
        toExtract.created_from &&
        moment(new Date(toExtract.created_from)).toDate(),
        toExtract.created_to &&
        moment(new Date(toExtract.created_to)).toDate(),
      ],
      updated_at: [
        toExtract.last_activity_from &&
        moment(new Date(toExtract.last_activity_from)).toDate(),
        toExtract.last_activity_to &&
        moment(new Date(toExtract.last_activity_to)).toDate(),
      ],
    };
};

const LeadFilterFormIkForm = withFormik({
  displayName: "lead-filter-form",
  validationSchema: leadFilterSchema,
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    const { filter, savedValues } = props;
    const initValues = { ...leadFilterForm };
    if (isNotNULL(savedValues) && Object.entries(savedValues).length > 0)
      return getLeadFormFilterInput(savedValues, "SAVED");
    if (isNotNULL(filter) && Object.entries(filter).length > 0)
      return getLeadFormFilterInput(filter, "FILTER");
    return initValues; /*to enable front-end filtering do not format filter values, simply use 'filter'*/
  },
  handleSubmit: (values, { props, setSubmitting, setStatus }) => {
    props
      .addLeadFilter({
        variables: {
          input: getLeadFilterInput(values, "SAVE", props.currentCard),
        },
      })
      .then((res) => {
        setSubmitting(false);
        props.onSaveFilter(res.data.addLeadFilter.message);
        if (res.data.addLeadFilter.code === 200) {
          props.getLeadFilters.refetch();
        }
      });
  },
})(LeadFilterForm);

export default LeadFilter;
