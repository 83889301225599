import gql from "graphql-tag";

const GET_SELECTED_SINGLE_COMMUNITY = gql`
  query {
    selectedSingleCommunityId @client {
      id
    }
  }
`;

export default GET_SELECTED_SINGLE_COMMUNITY;
