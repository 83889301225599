import gql from "graphql-tag";

const GET_HOME_OWNERS = gql`
  query GetAllHomeOwners(
    $limit: Int
    $pageNum: Int
    $filter: HomeOwnersFilter
    $search: String
    $order: [String]
  ) {
    getAllHomeOwners(
      limit: $limit
      pageNum: $pageNum
      filter: $filter
      search: $search
      order: $order
    ) {
      Home_Owner {
        id
        customer_id
        salesperson {
          id
          first_name
          last_name
        }
        first_name_1
        last_name_1
        first_name_2
        last_name_2
        cell_phone_1
        cell_phone_2
        primary_email_1
        primary_email_2
        secondary_emails_1
        secondary_emails_2
        primary_email_1_status
        primary_email_2_status
        community {
          id
          name
          is_active
        }
        purchased_date
        settled_date
        lot
        brand_ambassador
      }
      total_count
    }
  }
`;
export default GET_HOME_OWNERS;
