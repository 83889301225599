import React, { useState } from "react";
import PropTypes from "prop-types";
import { compose, graphql } from "react-apollo";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import {
  DISABLE_AUTO_REPLY_WEBFORM_TYPE,
  ENABLE_AUTO_REPLY_WEBFORM_TYPE
} from "../../graphql/mutations";
import OtherCustomizationCard from "../../components/customziation/other-customization-card";
import CustomizationOptionBox from "../../components/customziation/customizations-option-box";
import CustomButton from "../../components/custom-button";
import { isNotNULL } from "../../utils/helpers";

/**
 * This component is used to render manage auto reply section in the Web Form Reply Email Associations section where you can make a Web From type into auto-reply type.
 */
const AutoReplySelector = ({ ...props }) => {
  const [selectedFormTypes, setSelectedFormTypes] = useState([]);
  const [selectedAutoReplyTypes, setSelectedAutoReplyTypes] = useState([]);
  const defaultField = [
    {
      mutation: "disableAutoReplyWebformType",
      resMutation: "disableAutoReplyOnWebFormType",
      selctedId: selectedAutoReplyTypes,
      callback: setSelectedAutoReplyTypes,
    },
    {
      mutation: "enableAutoReplyWebformType",
      resMutation: "enableAutoReplyOnWebFormType",
      selctedId: selectedFormTypes,
      callback: setSelectedFormTypes,
    },
  ];

  /**
   * This function is used to handle click on either of manage auto-reply windows. A single click will add the clicked item into the selected list and a double-click will call the handleUpdate function
   * @param {Object} val Value selected on click
   * @param {Boolean} doubleClick A Boolean check to confirm if the click was a double click or not
   * @param {Object} field An instance of defaultField object containing mutation, response mutation, selected types and the callback function to handle the data
   */
  const handleSelection = (val, doubleClick, field) => {
    field.callback(val);
    if (doubleClick) {
      handleUpdate(field);
    }
  };

  /**
   * This function takes the selected items and sends a mutation to update the server with the selected types. If response is a success then a success message received from the mutation response is shown
   * @param {*} field An instance of defaultField object obtaining the mutation to run, response mutation, selected types of webform and the callback function to handle the data
   */
  const handleUpdate = (field) => {
    if (!isNotNULL(field.selctedId) && field.selctedId !== 0) return;
    props[field.mutation]({
      variables: {
        web_form_type_ids: field.selctedId,
      },
    }).then((res) => {
      if (res.data[field.resMutation].code === 200) {
        field.callback([]);
        props.update(res.data[field.resMutation].message);
      }
    });
  };

  return (
    <OtherCustomizationCard
      firstCardTitle="Webform Types"
      firstCardView={
        <CustomizationOptionBox
          selected={selectedFormTypes}
          list={props.unavailable}
          handleSelection={(val, e) => handleSelection(val, e, defaultField[1])}
        />
      }
      moveToFirstCardView={
        <CustomButton
          color="black"
          btnIcon={<FaChevronLeft />}
          className="mb-1 border-0 custom-btn-arrows arrow-up"
          onClick={() => handleUpdate(defaultField[0])}
          disabled={selectedAutoReplyTypes.length > 0 ? false : true}
        />
      }
      moveToSecondCardView={
        <CustomButton
          color="black"
          btnIcon={<FaChevronRight />}
          className="border-0 custom-btn-arrows arrow-down"
          onClick={() => handleUpdate(defaultField[1])}
          disabled={selectedFormTypes.length > 0 ? false : true}
        />
      }
      secondCardTitle="Auto Reply"
      secondCardView={
        <CustomizationOptionBox
          leftBox
          selected={selectedAutoReplyTypes}
          list={props.available}
          handleSelection={(val, e) => handleSelection(val, e, defaultField[0])}
        />
      }
    />
  );
};

AutoReplySelector.propTypes = {
  update: PropTypes.func,
  available: PropTypes.array,
  unavailable: PropTypes.array,
};

export default compose(
  graphql(ENABLE_AUTO_REPLY_WEBFORM_TYPE, {
    name: "enableAutoReplyWebformType",
  }),
  graphql(DISABLE_AUTO_REPLY_WEBFORM_TYPE, {
    name: "disableAutoReplyWebformType",
  })
)(AutoReplySelector);
