import gql from "graphql-tag";

const GET_DEFAULT_VIEW = gql`
  query {
    defaultView @client {
      tableView
    }
  }
`;

export default GET_DEFAULT_VIEW;
