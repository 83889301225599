import React, { useRef, useState, useEffect } from "react";
import {
  Editor,
  getDefaultKeyBinding,
  RichUtils,
  EditorState,
  Modifier
} from "draft-js";
import { Controlled as CodeMirror } from "react-codemirror2";
import ToolbarControls from "./editor-toolbar";
import {
  getBlockStyle,
  getBlockRenderer,
  loadEditorFromHTML,
  convertEditorToHTML
} from "./editor-helpers";
import { styleMap } from "./editor-constants";
import { FaCode, FaPencilAlt } from "react-icons/fa";
import {
  _confirmMedia,
  _removeLink,
  _confirmLink,
  toggleBlockType,
  toggleInlineStyle,
  togglePlaceholderInsert
} from "./editor-blocks";

// The following two imports are for the theme
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
// This import is for the language syntax highlighting
import "codemirror/mode/javascript/javascript.js";

/**
 * @module TextEditorModule
 */

/**
 * this component is used in {@link RichTextEditor} to render the editor toolbar. It contains the edit section of an email template
 * @param {object} props component props
 * @param {number} templateId Id of the selected template
 * @param {string} type Type of selected section 
 * @param {object} placeholders Contains the names and the descptions of the personalization tokens
 * @param {string} editorClassName Classname of the editor 
 * @param {string} category Category of the email (Mass Email or templates)
 * @param {object} editorState State of the editor
 */
const TextEditor = ({
  editorState,
  editorClassName,
  placeholders,
  type,
  templateId,
  category,
  ...props
}) => {
  const markupinput = useRef(null);
  const editorComp = useRef(null);
  const focusComp = () => editorComp.current.focus();

  const [codeMirrorValue, setCodeMirrorValue] = useState(convertEditorToHTML(editorState));
  const [showCodeMirror, setShowCodeMirror] = useState(false);

  const onChange = (editorState) => props._onChange(editorState, type);

  const handlePastedFiles = (files) => {
    let newState = editorState;

    if (files.length > 0) {
      // Handle pasted files (e.g., image files)
      newState = files.reduce((state, file) => {
        // Create a custom entity for each pasted image
        const contentStateWithEntity = state
          .getCurrentContent()
          .createEntity("IMAGE", "IMMUTABLE", {
            src: URL.createObjectURL(file)
          });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

        // Insert the image as an atomic block
        const contentStateWithAtomicBlock = Modifier.insertText(
          contentStateWithEntity,
          state.getSelection(),
          " ",
          null,
          entityKey
        );

        state = EditorState.push(
          state,
          contentStateWithAtomicBlock,
          "insert-fragment"
        );

        return EditorState.forceSelection(
          state,
          contentStateWithAtomicBlock.getSelectionAfter()
        );
      }, newState);
    }

    onChange(newState);
  };

  /**
   * this function updates the editor state based on the key commands 
   * @function
   * @inner
   * @memberof module:TextEditorModule
   * @param {string | boolean} command Command that updates the the editor state
   * @param {object} editorState The new editor state.
   */
  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return true;
    }
    return false;
  };

  /**
   * this function updates the editor state based on the key code 
   * @function
   * @inner
   * @memberof module:TextEditorModule
   * @param {object} e Event that contains all information related to the keycode
   */
  const mapKeyToEditorCommand = e => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(e, editorState, 4);
      if (newEditorState !== editorState) {
        onChange(newEditorState);
      }
      return false;
    }
    return getDefaultKeyBinding(e);
  };

  /**
   * this function handles linking or unlinking, or adding an image
   * @function
   * @inner
   * @memberof module:TextEditorModule
   * @param {*} e 
   * @param {*} value 
   * @param {string} type type of the action
   * @param {number} height height of the image
   * @param {number} width wigth of the image
   */
  const addCustom = (e, value, type, height, width) => {
    focusComp();
    switch (type) {
      case "image":
        _confirmMedia(e, value, type, height, width, editorState, onChange);
        return;
      case "LINK":
        _confirmLink(e, value, type, editorState, onChange);
        return;
      case "unlink":
        _removeLink(e, editorState, onChange);
        return;
      default:
        return;
    }
  };

  const loadCodeMirror = () => {
    setShowCodeMirror(true);
    setCodeMirrorValue(convertEditorToHTML(editorState));
  };

  const loadEditor = () => {
    setShowCodeMirror(false);
    loadEditorFromHTML(codeMirrorValue, onChange);
  };

  const updateCodeMirror = value => {
    setCodeMirrorValue(value);
    loadEditorFromHTML(value, onChange);
  };

  useEffect(() => {
    // updates the codeMirrorValue
    setCodeMirrorValue(convertEditorToHTML(editorState));
  }, [type, templateId]);

  return (
    <div className="text-editor-panel">
      <div className="rtd-wrapper">
        <div className="RichEditor-controls-container">
          {!showCodeMirror && (
            <ToolbarControls
              sectionType={type && type[1]}
              editorState={editorState}
              toggleBlockType={value =>
                toggleBlockType(value, editorState, onChange)
              }
              toggleInlineStyle={value =>
                toggleInlineStyle(value, editorState, onChange)
              }
              selectCustom={addCustom}
              togglePlaceholderInsert={value =>
                togglePlaceholderInsert(value, editorState, onChange)
              }
              placeholders={placeholders}
            />
          )}
          {props.valueSet && (
            <div className="html-editor-switch">
              {!showCodeMirror && (
                <button title="HTML View" onClick={loadCodeMirror}>
                  <FaCode />
                </button>
              )}
              {showCodeMirror && (
                <button title="WYSIWYG Editor" onClick={loadEditor}>
                  <FaPencilAlt />
                </button>
              )}
            </div>
          )}
        </div>
        {!showCodeMirror && (
          <div
            className={`${editorClassName}` + `-${category}`}
            onClick={() => focusComp()}
          >
            <Editor
              customStyleMap={styleMap}
              blockRendererFn={getBlockRenderer}
              blockStyleFn={getBlockStyle}
              editorState={editorState}
              handleKeyCommand={handleKeyCommand}
              keyBindingFn={mapKeyToEditorCommand}
              onChange={onChange}
              placeholder={category === "mass-email"
                ? `Please write the email body here..`
                : !((!type && !editorState.getCurrentContent().hasText()))
                  ? null
                  : (
                    <div style={{ textAlign: "center" }}>
                      <strong>
                        <h3>Select a component to edit</h3>
                      </strong>
                    </div>
                  )
              }
              ref={editorComp}
              spellCheck={true}
              readOnly={!type && !editorState.getCurrentContent().hasText()}
              handlePastedFiles={handlePastedFiles}
            />
          </div>
        )}
      </div>

      {showCodeMirror && (
        <div className="code-preview-panel">
          <CodeMirror
            onBeforeChange={(editor, data, codeMirrorValue) =>
              updateCodeMirror(codeMirrorValue)
            }
            ref={markupinput}
            options={{
              mode: "application/ld+json",
              matchBrackets: true,
              lineNumbers: true,
              lineWrapping: true,
              highlightSelectionMatches: true,
              indentUnit: 4,
              tabSize: 4,
              autoCloseBrackets: true
            }}
            value={codeMirrorValue}
          />
        </div>
      )}
    </div>
  );
};

export default TextEditor;
