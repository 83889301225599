import React from "react";
import { Form, Field, withFormik } from "formik";
import CustomButton from "../../../components/custom-button";
import SelectField from "../../../components/fields/select-field";
import CheckboxField from "../../../components/fields/checkbox-field";
import MultiSelectField from "../../../components/fields/multi-select-field";
import { upcomingFilterSchema, upcomingFilterForm } from "../../../utils/upcoming-filter-form-schema";
import { isEmpty } from "../../../utils/helpers";


/**
 * This component is used to render upcoming filter form. It contains 1 multi select field.  Apply filter button remains disabled unless some
 * value on filter fields is selected. On submit setFilter is called, which triggers table refetch. 
 * @param {Object} props 
 */
const UpcomingFilterForm = props => {
  const activity_options = [
    {
      id: "1",
      name: "Appointments",
      value: "Appointments"
    },
    {
      id: "2",
      name: "Follow-ups",
      value: "FollowUps"
    }
  ]

  return (
    <Form className="filter-menu">
      <div className="form-row">
        <div className="col-md-6">
          <h6>Filters</h6>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-12">
          <Field
            name="rank.ids"
            label="Rank"
            id="rank.ids"
            propsStyle={{ width: "200px" }}
            disabled={props.getAllRankings.getAllRankings ? false : true}
            component={MultiSelectField}
            children={props.getAllRankings.getAllRankings}
            defaultValue={
              props.values.rank.ids || null
            }
          />
          <Field
            name="activity_type"
            label="Activity Type"
            id="activity_type"
            propsStyle={{ width: "200px" }}
            component={SelectField}
          >
            <option value="">Select ...</option>
            {activity_options.map((activity_type) =>
              <option key={activity_type.id} value={activity_type.value}>
                {activity_type.name}
              </option>
            )}
          </Field>
          <Field
            id="past_activities"
            label="Past Activities"
            name="past_activities"
            component={CheckboxField}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-12">
          <CustomButton
            type="submit"
            btnValue="APPLY FILTERS"
            className="filter-apply-btn"
          />
        </div>
      </div>
    </Form>
  );
};

export const UpcomingFilterFormIkForm = withFormik({
  mapPropsToValues: props => {
    const {
      filter: { rank_id, activity_type, past_activities },
      filter
    } = props;

    return !isEmpty(filter)
      ? { rank: { ids: rank_id }, activity_type, past_activities }
      : upcomingFilterForm;
  },
  handleSubmit: (values, { props, setSubmitting }) => {
    const { setFilter, setShow } = props;
    setFilter({
      rank_id: values.rank && values.rank.ids,
      activity_type: values.activity_type,
      past_activities: values.past_activities
    });
    setShow(false);
    setSubmitting(false);
  },
  displayName: "upcoming-filter-form",
  enableReinitialize: true,
  validationSchema: upcomingFilterSchema
})(UpcomingFilterForm);
