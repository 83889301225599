import * as Yup from "yup";
import * as errorMessages from "../../../utils/form-errors";
import * as validations from "../../../utils/common-validations";

export const initialValues = {
  hidden_associated_emails: "",
  associated_emails: []
};

Yup.addMethod(Yup.mixed, 'stringInArray', function (ref, message) {
  const msg = message;
  return this.test('equalTo', msg, function (value) {
    let refValue = this.resolve(ref);
    return !refValue || !value || refValue.indexOf(value) === -1;
  })
})

export const EmailAssociationSchema = Yup.object().shape({
  hidden_associated_emails: validations.email.stringInArray(Yup.ref("associated_emails"), errorMessages.EMAIL_EXISTS),
  associated_emails: Yup.array()
});
