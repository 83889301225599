
import gql from 'graphql-tag';

const SCHEDULE_AND_CONVERT_LEAD = gql` 
    mutation scheduleAndConvertLead($input :ScheduleAndConvertLeadInput!){
        scheduleAndConvertLead(input: $input) {
            message
            code
        }
    }
`;

export default SCHEDULE_AND_CONVERT_LEAD;