import gql from "graphql-tag";

const GET_FORM_SUBMISSION_BY_ID = gql`
query getFormSubmissionByID(
  $input: FormSubmissionFilter
  $limit: Int
  $pageNum: Int
  $sort: [String]
  $search: String
) {
  getFormSubmission(
    filter: $input
    limit: $limit
    pageNum: $pageNum
    sort: $sort
    search: $search
  ) {
    total_count
    formSubmission {
      id
      form_submission_id
      firstname
      lastname
      email
      phone
      type {
        id
        name
      }
      responded
      updated_at
      status {
        id
        name
      }
      customer_communities {
        id
        name
        is_active
      }
      customer_id
      phone_calls {
        phone_call_id
        session_id
        call_details
        call_duration
        call_datetime
        attachments {
          id
          name
          type
          download_url
        }
        creator {
          id
          first_name
          last_name
        }
        call_type {
          id
          name
        }
        recording
      }
      emails{
        id
        subject
        body
        body_preview
        date_time
        hasAttachments
        email_type
        to_recipients
        cc_recipients
        bcc_recipients
        isIndividual
        csms{
          id
          first_name
          last_name
          email_address
          cell_phone
          role
          createdAt
          updatedAt
          is_active
        }
      }

      form_type
      community {
        id
        name
        is_active
        division_id
      }
      division {
        id
        name
      }
      url
      interested_in
      other_data
      response_phone
      response_sms
      response_email
      created_at
      updated_at
      responded_at
      responder{
        first_name
        last_name
      }
      converted
    }
  }
}
`;

export default GET_FORM_SUBMISSION_BY_ID;
