import React, { useEffect, useState, useRef } from "react";
import WebformDetailsCard from "./webform-details-card";
import LoadingWrapper from "../../../components/loading-wrapper";
import Calendar from "../../../components/calendar/calendar";
import NoDataPage from "../../no-data-page/no-data-page";
import logo from "../../../images/graphic-no-lead.png";
import { SUB_FORM_SUBMISSION_DETAIL } from "../../../graphql/subscriptions";
import {
  getIssueEmail,
  inActiveMapper,
  formatDataForCalendar,
} from "../../../utils/helpers";
import ListPopup from "../../../components/list-popup/list-popup";
import WebformDetailSelectBar from "./webform-details-select-bar";
import CalendarPopover from "../../common-lead-prospect-details/calendar-popover";
import { DELETED_TYPE_ID } from "../customer-types";
import PastActivitiesView from "../../common-lead-prospect-details/past-activities";
import { getStringDescendantElements } from "../../../components/new-table/utils";

/**
 *@module  WebformDetailsViewModule
 */
/**
 * This component is used to render the details page of webform. It renders select bar component on top to display
 * webform name, community and convert recover button. Just below it, page is divided into two parts to display a calendar and pass activities on the left part, and other webform details card on the right part. Also
 * if the webform of specific id is not found, No data page is rendered. When the data is being loaded from the
 * backend, in the meanwhile a loader is displayed on the whole screen.
 *
 * @param {Object} props it contains all the mutations/queries in the details-conatiner as props
 */
const WebformDetailsView = (props) => {
  const [isSubscription, setIsSubscription] = useState(false);

  const { getAllStatuses, getFormSubmissionById, ArchivedUser } = props;
  const handler = useRef(false);

  /**
   * sets setissubscription variable based on the conditions of subscriptionDAta and returns a handler
   */
  useEffect(() => {
    if (props.match.params.id) {
      handler.current = getFormSubmissionById.subscribeToMore({
        document: SUB_FORM_SUBMISSION_DETAIL,
        variables: { form_submission_id: parseInt(props.match.params.id) },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData) return prev;
          setIsSubscription(true);
          return prev;
        },
      });
    }

    return () => {
      handler.current && handler.current();
      handler.current = false;
    };
  }, [props.match.params.id]);

  /**
   * refetch table data if any update is performed
   */
  useEffect(() => {
    if (isSubscription) {
      setIsSubscription(false);
      getFormSubmissionById.refetch();
    }
  }, [isSubscription]);

  /**
   * this function handles the data and prepares the specific format that needs to be used for converting or recovering.
   * @param {object} data contains information of the customer
   * @param {boolean} loading defines if the data is ready or is still loading
   * @function
   * @inner
   * @memberof module:WebformDetailsViewModule
   * @returns {object}
   */
  const handleData = (loading, data) => {
    let returnData = {
      data: null,
      convertForm: true,
      title: "CONVERT",
      allStatuses: null,
    };

    if (!loading) {
      returnData.allStatuses = getAllStatuses?.getAllStatuses;
      if (data.getFormSubmission.formSubmission[0].type.id === DELETED_TYPE_ID) {
        returnData.data = ArchivedUser?.getArchived_Customer?.ArchivedCustomers[0];
        returnData.convertForm = false;
        returnData.title = "RECOVER";
      } else {
        returnData.data = data.getFormSubmission.formSubmission[0];
      }
    }

    return returnData;
  };

  /**
   * this function handles the communities of the data provides with specific mapper.
   * @param {object} data contains information of the customer
   * @function
   * @inner
   * @memberof module:WebformDetailsViewModule
   * @returns {JSX.Element}
   */
  const handleCommunities = (data) => {
    return (
      <div className="communities-collection">
        <span>:&nbsp;</span>
        <ListPopup
          data={getStringDescendantElements(
            data.Form_Submission_Details,
            "community"
          )}
          mapper={inActiveMapper}
        />
      </div>
    );
  };

  const webformDetailsView = (id, data, loading) => {
    let name = !loading
      ? data &&
        data.getFormSubmission &&
        data.getFormSubmission.formSubmission[0]
        ? `${data.getFormSubmission.formSubmission[0].firstname
          ? data.getFormSubmission.formSubmission[0].firstname
          : ""
        } ${data.getFormSubmission.formSubmission[0].lastname
          ? data.getFormSubmission.formSubmission[0].lastname
          : ""
        }`
        : ""
      : "";

    let community = !loading
      ? data &&
        data.getFormSubmission &&
        data.getFormSubmission.formSubmission[0]?.community
        ? data.getFormSubmission.formSubmission[0].community.name
        : ""
      : "";

    return (
      <LoadingWrapper
        loading={loading}
        component={
          <div className="col-padding col-md-12 col-12">
            <div
              className="root-row row"
              id="prospect-detail-header"
            >
              <WebformDetailSelectBar
                name={name}
                community={community}
                id={id}
                dataField={handleData(loading, data)}
                setNotificationProps={props.setNotificationProps}
                onSubmit={() => data.refetch()}
                {...props}
              />
            </div>
            <div className="row-container main-responsive col-xl-12 p-2 px-3 row">
              <div className="col-padding col-12 col-md-8 detail-view-main">
                <div
                  style={{
                    padding: "1rem",
                    marginTop: "0.5rem",
                    border: "1px solid #191b1d",
                    borderRadius: "0.25rem",
                  }}
                >
                  <Calendar
                    id={id}
                    data={Object.assign(
                      {},
                      !loading ? data.getFormSubmission.formSubmission[0] : {}
                    )}
                    {...props}
                    popover={CalendarPopover}
                    type="webform"
                  />
                  <PastActivitiesView
                    type="webform"
                    pastActivitiesData={
                      !loading
                        ? formatDataForCalendar(
                          data.getFormSubmission.formSubmission[0],
                          "webform"
                        )
                        : null
                    }
                    id={id}
                  />
                </div>
              </div>

              <div className="col-padding col-md-4 col-12 pb-2 pt-2 pr-0 pl-3 detail-view-side">
                <WebformDetailsCard
                  data={!loading ? data.getFormSubmission.formSubmission[0] : null}
                  id={id}
                  {...props}
                />
              </div>
            </div>
          </div>
        }
      />
    );
  };

  const loading =
    getFormSubmissionById.loading ||
    getAllStatuses.loading ||
    ArchivedUser?.loading;

  return !loading &&
    getFormSubmissionById.getFormSubmission.formSubmission.length === 0 ? (
    <NoDataPage
      backgroundImage={logo}
      heading={"Form Submission not found!"}
      content={
        <div>
          <p className="message-reportBug">
            The resource for this form submission was not found in our records.
            <br />
            If it's a bug, please report.
            <br />
            <a
              href={getIssueEmail()}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-primary"
            >
              Report
            </a>
          </p>
        </div>
      }
    />
  ) : (
    webformDetailsView(
      parseInt(props.match.params.id),
      getFormSubmissionById,
      loading
    )
  );
};
export default WebformDetailsView;
