import React, { useContext } from 'react';
import { compose, graphql } from "react-apollo";

import { GET_DASHBOARD_PROSPECTS, GET_UNIQUE_PROSPECTS_COUNT } from "../../../graphql/queries";
import ProspectsTable from './prospects-container';
import CreateProspectForm from '../forms/create-update-prospect-container'
import DashboardContextProvider from '../../../context/dashboard-context-provider';
import { FaStar } from 'react-icons/fa';
import { getCreateDateFilters } from '../../../utils/helpers';
import * as cardsType from "../../lead/dashboard/lead-card-types";
import { GET_PREVIOUS_CARD } from '../../../cacheql/queries';
import { Auth } from '../../../rbac/rbac';
import moment from 'moment';

const activityCards = [
  {
    type: cardsType.TODAY,
    title: "Today",
    tableTitle: "New Prospects Today",
    customDataQuery: GET_UNIQUE_PROSPECTS_COUNT,
    customPath: "getTotalProspectsCount",
    filter: getCreateDateFilters('today')
  },
  {
    type: cardsType.THIS_MONTH,
    title: moment().format('MMM'),
    tableTitle: "New Prospects This Month",
    customDataQuery: GET_UNIQUE_PROSPECTS_COUNT,
    customPath: "getTotalProspectsCount",
    filter: getCreateDateFilters('month'),
    hash: "#month",
    secondaryDataQuery: {
      filter: getCreateDateFilters("prev_year_month"),
      dataQuery: GET_UNIQUE_PROSPECTS_COUNT,
      name: 'getProspectCountLastYearMonth',
    }
  },
  {
    type: cardsType.THIS_YEAR,
    title: "YTD",
    tableTitle: "New Prospects This Year",
    customDataQuery: GET_UNIQUE_PROSPECTS_COUNT,
    customPath: "getTotalProspectsCount",
    filter: getCreateDateFilters('year'),
    hash: "#year",
    secondaryDataQuery: {
      filter: getCreateDateFilters("prev_year"),
      dataQuery: GET_UNIQUE_PROSPECTS_COUNT,
      name: 'getProspectCountLastYear',
    }
  },
  {
    title: "All",
    tableTitle: "All Prospects",
    customDataQuery: GET_UNIQUE_PROSPECTS_COUNT,
    customPath: "getTotalProspectsCount"
  },
  {
    title: "All A Ranked Prospects",
    tableTitle: "All A Ranked Prospects",
    filter: { rank_id: 1 },
    hidden: true,
    hash: "#rank-a"
  }
]

/**
 * This component is used to render prospect dashboard with 3 cards and a table. Each card has same query and its 
 * own filter. All the tables render the same table with data change only. This dahsboard contains community 
 * dropdown and division dropdown is there for manager role only. By default All prospects card is preselected.
 * @param {*} props 
 */
const ProspectsDashboard = props => {
  const { is_manager, has_multiple_divisions } = useContext(Auth);

  const getDefaultCard = () => {
    const cardTitle = props.getPreviousCard.previousCard.title;
    const index = activityCards.findIndex(item => item.title === cardTitle);
    if (index === -1) {
      return activityCards[3]
    }
    return activityCards[index];
  };

  return (
    <DashboardContextProvider
      type="Prospects"
      text="New Prospect"
      startingDefaultCard={getDefaultCard()}
      dataQuery={GET_DASHBOARD_PROSPECTS}
      activityCards={activityCards}
      formContent={CreateProspectForm}
      icon={FaStar}
      withCommunity
      withDivision={is_manager() || has_multiple_divisions()}
      withCsm
      tableView={<ProspectsTable />}
      {...props}
    />
  )
}


export default compose(
  graphql(GET_PREVIOUS_CARD, { name: "getPreviousCard" })
)(ProspectsDashboard)
