import gql from "graphql-tag";

export const GET_CSM_AVAILABILITY_TIMESLOTS = gql`
  query getCsmAvailabilityTimeSlots(
    $filter: getAvailabilityTimeSlotsFilter!
    $duration: Int!
    $appointment_date: String!
  ) {
    getCsmAvailabilityTimeSlots(
      filter: $filter
      duration: $duration
      appointment_date: $appointment_date
    ) {
      id
      first_name
      last_name
      image_url
      timeSlots {
        meetingStartDateTime
        meetingEndDateTime
      }
    }
  }
`;

export const GET_OSC_AVAILABILITY_TIMESLOTS = gql`
query getOscAvailabilityTimeSlots(
  $filter: getAvailabilityTimeSlotsFilter
  $duration: Int!
  $appointment_date: String!
) {
  getOscAvailabilityTimeSlots(
      filter: $filter
      duration: $duration
      appointment_date: $appointment_date
    ) {
      id
      first_name
      last_name
      timeSlots {
        meetingStartDateTime
        meetingEndDateTime
      }
    }
  } 
`;

