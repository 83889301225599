
import gql from 'graphql-tag';

const ADD_AGENCY = gql` 
  mutation addAgency($input: AgencyInput!) {
    addAgency(input: $input) {
      message
      code
    }
  }
`;

export default ADD_AGENCY;