import React from "react";
import { compose, graphql } from "react-apollo";
import Drawer from "../../../hoc/Drawer";

import { GET_LEAD_ON_EDIT } from "../../../graphql/queries";
import CustomButton from "../../../components/custom-button";
import CreateEditLeadWithMutation from "../forms/create-edit-lead-container";


/**
 * this component is used to render the edit lead drawer which contains the create edit lead form and it takes the data and prepares the appropriate format to be passed to the create edit form.
 */
class EditLeadView extends React.Component {
  /**
 * @param {*} props
 * @param {object} props.leadData contains information of the lead when called from details page
 * @param {object} props.data contains information of the lead when called from the table
 * @param {function} props.close closes the drawer
 * @param {boolean} props.show boolean that defines wether to show the drawer or not
 * @param {number} props.id id of the lead
 */
  constructor(props) {
    super(props);
    this.state = {
      showSideBar: this.props.show,
      Lead: {
        id: this.props.id
      }
    };
    this.toggleShowDrawer = this.toggleShowDrawer.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  toggleShowDrawer() {
    if (this.props.textButton) {
      this.setState({ showSideBar: !this.state.showSideBar });
    } else {
      this.props.close();
    }
  }

  onSubmit(message) {
    this.setState({
      showSideBar: false
    });
    if (!this.props.textButton) this.props.close();
    this.props.update(message);
  }

  /**
  * this function prepares the data to be passed to the lead form, it takes the lead information and puts it in an appropriate lead format.
  * @param {object} leadData contains information of the lead
  *@method
  * @returns {object}
  */
  formatData = leadData => {
    let dataToReturn = (({
      first_name_1,
      last_name_1,
      primary_email_1,
      cell_phone_1,
      secondary_emails_1,
      first_name_2,
      last_name_2,
      primary_email_2,
      cell_phone_2,
      secondary_emails_2,
      city,
      state,
      zip,
      street_address,
      realtor,
      customer_origination_area,
      id,
      osc_notes,
      primary_email_1_status,
      primary_email_2_status
    }) => {
      return {
        first_name_1,
        last_name_1,
        primary_email_1: primary_email_1,
        disable_primary_email_1: !primary_email_1_status,
        cell_phone_1,
        secondary_emails_1,
        first_name_2,
        last_name_2,
        primary_email_2: primary_email_2,
        disable_primary_email_2: !primary_email_2_status,
        cell_phone_2,
        secondary_emails_2,
        city,
        customer_origination_area_id:
          (customer_origination_area && customer_origination_area.id) ||
          undefined,
        state_id: state && state.id ? state.id : undefined,
        zip,
        realtor_id: realtor ? realtor.id : undefined,
        street_address,
        osc_notes,
        id
      };
    })(leadData);

    dataToReturn.Lead = (({
      divisions,
      communities,
      other_notes,
      quality_score,
      engagement_score,
      lead_classification_filters
    }) => {
      return {
        divisions: divisions && divisions.map(item => item.id),
        communities: communities && communities.filter(item => item.is_active).map(item => item.id),
        lead_classification_filter_ids: lead_classification_filters && lead_classification_filters.map(item => item.id),
        other_notes: other_notes,
        quality_score: quality_score ? quality_score : undefined,
        engagement_score: engagement_score ? engagement_score : undefined,
      };
    })(leadData);
    dataToReturn.communities = leadData.communities;
    return dataToReturn;
  };

  render() {
    const { showSideBar } = this.state;
    const { id, leadData, data } = this.props;
    return (
      <div className="edit-button">
        {this.props.textButton && (
          <CustomButton
            size="sm"
            color="dark"
            btnValue={this.props.btnValue}
            onClick={this.toggleShowDrawer}
          />
        )}
        <Drawer show={showSideBar} toggleSideBar={this.toggleShowDrawer}>
          {this.props.textButton ? (
            <CreateEditLeadWithMutation
              close={this.toggleShowDrawer}
              data={this.formatData(leadData)}
              id={id}
              editForm
              update={this.onSubmit}
              isDetails={this.props.isDetails}
              history={this.props.history}
              inActiveCommunities={leadData.communities.filter(item => !item.is_active)}
            />
          ) : data && data.getLeads ? (
            <CreateEditLeadWithMutation
              close={this.toggleShowDrawer}
              data={this.formatData(data.getLeads.Leads[0])}
              id={id}
              editForm
              update={this.onSubmit}
              inActiveCommunities={data.getLeads.Leads[0].communities.filter(item => !item.is_active)}
            />
          ) : null}
        </Drawer>
      </div>
    );
  }
}

export default EditLeadView;

export const EditLeadViewWithData = compose(
  graphql(GET_LEAD_ON_EDIT, {
    options: props => {
      return {
        variables: { filter: { id: props.id } },
        fetchPolicy: "network-only"
      };
    }
  })
)(EditLeadView);
