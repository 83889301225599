import React from "react";
import { DropdownButton, OverlayTrigger, Tooltip } from "react-bootstrap";
import { getMwcList, getScoreColorList, isNotNULL } from "../../../utils/helpers";
import "./custom-details-table.css";

/**
 * This component in used in Propsect and Home Owner Detail Card to display the last section
 * of the table. (Highlighted heading with table data displayed below)  
 * @param {tableData} tableData Contains the information to be displayed in the table 
 */
const DetailsLastSectionTable = tableData => {

  return (
    <>
      {Object.keys(tableData.data)
        .map((key, index) => {

          let color = null, list = []
          if (isNotNULL(tableData.data[key]?.score)) {
            list = tableData.data[key]?.isMwc ? getMwcList() : getScoreColorList(tableData.data[key].isInverse)
            color = list.find(obj => obj.eventKey === tableData.data[key].score)?.color;
          }

          return (
            <div key={index}>
              <div className="row">
                <div className="col-9">
                  <label style={{ opacity: 1 }}>{key}</label>

                  <p className="notes">{tableData.data[key] ? tableData.type === "Home_Owner" ?
                    tableData.data[key] : tableData.data[key].note || "-" : "-"}</p>

                  {tableData.type !== "Home_Owner" && tableData.data[key]?.isMwc &&
                    tableData.data[key].questions.map((item, index) => {
                      return (
                        <div key={index} className="form-check mb-1 ml-4">
                          <input
                            id={item.question}
                            type="checkbox"
                            checked={item.value}
                            className={'form-check-input'}
                            disabled
                          />
                          <label htmlFor={item.question} className="form-check-label details-form-check-label">
                            {item.question}
                          </label>
                        </div>
                      )
                    })}
                </div>

                {tableData.type !== "Home_Owner" &&
                  <div className="col-3">
                    <div className="section-rating d-flex justify-content-end">
                      {tableData.data[key] && isNotNULL(tableData.data[key].score) && (
                        <OverlayTrigger overlay={<Tooltip>Quality Score</Tooltip>}>
                          <span>
                            <DropdownButton
                              alignRight
                              drop="down"
                              variant="secondary"
                              id="passactivities-item-btn"
                              key="1"
                              style={{ pointerEvents: "none" }}
                              disabled
                              title={list.find(obj => obj.eventKey === tableData.data[key].score)?.value || "-"}
                              className={`last-section-dropdown-${color}`}
                            />
                          </span>
                        </OverlayTrigger>
                      )}
                    </div>
                  </div>
                }

              </div>
            </div>
          );
        })}
    </>
  );
}

export default DetailsLastSectionTable;