import React from "react";
import "./layout.css";

export default function SelectContainer({ children, className, ...props }) {
  const selectClasses =
    className !== undefined ? `${className} selectWrap` : "selectWrap";

  return (
    <div className="pr-3 pl-3 col-padding col-md-12" id="select-bar-cont">
      <div className={selectClasses} {...props} id="select-bar-slt-cont">
        {children}
      </div>
    </div>
  );
}
