import React, { useState, useContext } from "react";
import { Auth } from "../../rbac/rbac";
import { NavLink } from "react-router-dom";
import { components } from "react-select";
import { getDescendantProp } from "./utils";
import { LEAD, PROSPECT } from "../../utils/constants"
import CallDialog from "../call-dialog/call-dialog-container";
import CustomButton from "../custom-button";
import TwoPersonIcon from '../../images/two-person-icon.svg'
import UnsubscribedEmailIcon from '../../images/unsubscribed-email.svg'
import svg from "../../images/icon_point-down.svg";
import "./data-cells.css";
import {
  ButtonToolbar,
  OverlayTrigger,
  Popover,
  Tooltip
} from "react-bootstrap";
import {
  FaEllipsisH,
  FaFlag,
  FaBan,
  FaReply,
  FaSyncAlt
} from "react-icons/fa";
import {
  formatDate,
  phoneDisplay,
  formatActivityDate,
  isNotNULL,
  formatReportDateTime,
  InactivityIcon,
  isBuyerTwo,
} from "../../utils/helpers";


/**
 * it is used to render cells that redirects to a different page on click e.g. details page.
 * @param {function} path returns the path to redirect to
 * @param {object} field contains information related to the row
 * @param {string} accessor cell's attribute name
 * @param {function} mapper
 * @param {function} action allows further action if required on link click
 * @returns {JSX.Element}
 */
export const DetailLinkCell = ({ path, field, accessor, mapper, action }) => {
  const id = field.id || field.prospect_id || field.lead_id || field.name || field[accessor].id;
  const pathname = path(field, accessor);
  return (
    <td>
      <NavLink to={{ pathname: pathname + "/" + id, id: id }} onClick={action}>
        {mapper ? mapper(field, accessor) : field[accessor] || "———"}
      </NavLink>
    </td>
  );
};

/**
 * it is used to render cells that have specific action to be called on click like opening a drawer.
 * @param {function} action action to be called on click
 * @param {object} field contains information related to the row
 * @param {string} accessor cell's attribute name
 * @returns {JSX.Element}
 */
export const RespondLinkCell = ({ action, field, accessor }) => {
  return (
    <td>
      <span
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          action(field);
        }}
        className="td-link"
      >
        {field["lastname"]
          ? field[accessor] + " " + field["lastname"]
          : isNotNULL(field[accessor])
            ? field[accessor]
            : "———"}
      </span>
    </td>
  );
};

/**
 * it is used to render text cells with specific mapper which also contains an action to be perfomed on click.
 * @param {function} action action to be called on click
 * @param {object} field contains information related to the row
 * @param {string} accessor cell's attribute name
 * @param {function} mapper
 * @returns {JSX.Element}
 */
export const TextCellWithLinkAndMapper = ({
  action,
  field,
  accessor,
  mapper,
}) => {
  const accessedField = getDescendantProp(field, accessor);
  const value = mapper(field, accessor);
  return (
    <td>
      <span
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          action(field);
        }}
        className="td-link"
      >
        {accessedField
          ? accessedField.__typename === "Community"
            ? InactivityIcon(accessedField, value)
            : value
          : "-"}
      </span>
    </td>
  );
};

/**
 * it is used to render text cells with specific mapper.
 * @param {object} field contains information related to the row
 * @param {string} accessor cell's attribute name
 * @param {function} mapper
 * @returns {JSX.Element}
 */
export const TextCellWithMapper = ({ field, accessor, mapper }) => {
  const accessedField = getDescendantProp(field, accessor);
  const value = mapper(field, accessor);

  return (
    <td>
      {accessedField
        ? accessedField.__typename === "Community" ||
          accessedField.__typename === "csm"
          ? InactivityIcon(accessedField, value)
          : value
        : ""}
    </td>
  );
};

/**
 * it is used to render simple text cells.
 * @param {object} field contains information related to the row
 * @param {string} accessor cell's attribute name
 * @returns {JSX.Element}
 */
export const TextCell = ({ field, accessor }) => {
  const value = getDescendantProp(field, accessor);
  return <td>{value}</td>;
};

/**
 * it is used to render check box text cells.
 * @param {object} field contains information related to the row
 * @param {string} accessor cell's attribute name
 * @returns {JSX.Element}
 */
export const CheckboxCell = ({ field, accessor }) => {
  let value =
    field[accessor] === null || field[accessor] === false ? false : true;
  return (
    <td>
      <input type="checkbox" checked={value} readOnly />
    </td>
  );
};

/**
 * it is used to render text cells with object mapper.
 * @param {object} field contains information related to the row
 * @param {string} accessor cell's attribute name
 * @param {function} mapper
 * @returns {JSX.Element}
 */
export const TextObjectWithMapper = ({ field, accessor, mapper }) => {
  const values = mapper(field, accessor);

  return (
    <td>
      <div className="all-community-card">
        <div>
          {values && values.length
            ? values[0].__typename === "Community"
              ? InactivityIcon(values[0], values[0].name)
              : values[0]?.name || values[0]
            : " "}
        </div>
        {values && values.length > 1 ? (
          <ButtonToolbar
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <OverlayTrigger
              trigger="click"
              key="down"
              placement="bottom"
              rootClose={true}
              overlay={
                <Popover
                  id="popover-positioned-down"
                  className="details-all-community"
                >
                  {values.map((item, index) => {
                    return (
                      <div key={index} className="d-flex align-items-center">
                        {item.is_active === false ? (
                          <FaBan className="td-warning" />
                        ) : null}
                        {item.name || item}
                      </div>
                    );
                  })}
                </Popover>
              }
            >
              <div className="icon-btn btn-icon">
                <FaEllipsisH size={14} style={{ color: "#80B602" }} />
              </div>
            </OverlayTrigger>
          </ButtonToolbar>
        ) : null}
      </div>
    </td>
  );
};

/**
 * it is used to render phone cells with specific mapper.
 * @param {object} field contains information related to the row
 * @param {string} accessor cell's attribute name
 * @param {string} className
 * @param {boolean} clickable specifies if the cell is clickable and can performa a phonecall or just a simple text non clickable.
 * @param {boolean} details specifies if the function is being called fromt the details page card just to show "-" in case there is no phone number instead of showing empty like in the tables
 * @param {boolean} wrapper specifies if the phone number should be wrapped in a td tag or not.
 * @returns {JSX.Element}
 */
export const PhoneCell = ({
  field,
  accessor,
  clickable = true,
  className,
  wrapper = false,
  details = false,
}) => {
  const [showPhoneDialog, setShowPhoneDialog] = useState(false);
  const value = getDescendantProp(field, accessor);
  const { user } = useContext(Auth);

  const phoneNumber = value ? phoneDisplay(value) : details ? "-" : null;

  const onPhoneClick = () => {
    setShowPhoneDialog(true);
  };

  const onHide = () => setTimeout(() => setShowPhoneDialog(false), 1);
  const phoneWrapper = !clickable ? (
    phoneNumber
  ) : (
    <CustomButton onClick={onPhoneClick} className="d-block PhoneButton">
      {phoneNumber}
      {showPhoneDialog &&
        <CallDialog
          show={true}
          onHide={onHide}
          phoneNumber={phoneNumber}
          isActive={user?.zoom_phone} // true if logged in user has a zoom phone number
        />
      }
    </CustomButton>
  );
  return !wrapper ? (
    <td className={className}>{phoneWrapper}</td>
  ) : (
    phoneWrapper
  );
};

/**
 * it is used to render flag cells, used in prospect and upcoming task tables to show if the prospect is red flagged.
 * @param {object} field contains information related to the row
 * @param {string} accessor cell's attribute name
 * @returns {JSX.Element}
 */
export const FlagCell = ({ field, accessor }) => {
  return (
    <td>
      {field[accessor] && isNotNULL(field[accessor].description) && (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>{field[accessor].description}</Tooltip>}
        >
          <span className="center">
            {<FaFlag style={{ color: "#a90a0a" }} />}
          </span>
        </OverlayTrigger>
      )}
    </td>
  );
};

/**
 * it is used to render icon cells based on some specified checks.
 * @param {object} field contains information related to the row
 * @param {string} accessor cell's attribute name
 * @param {function} hide specifies wether to show or hide the icon
 * @param {object} icon icon to be shown
 * @returns {JSX.Element}
 */
export const RespondCell = ({ field, accessor, icon, hide }) => {
  return (
    <td>{!hide(field, accessor) ? <span className="center">{icon}</span> : <span style={{ opacity: "0" }}>{icon}</span>}</td>
  );
};

/**
 * it is used to render buyer to icon cells.
 * @returns {JSX.Element}
 */
export const buyerTwoIconCellObject = {
  component: RespondCell,
  icon: <img src={TwoPersonIcon} className="icon-img" alt=""></img>,
  hide: (field, _) => {
    return !isBuyerTwo(field);
  },
};

/**
 * it is used to render unconverted and unresponded cell, used in webforms to show if submission is unresponded and/or unconverted.
 * @param {object} field contains information related to the row
 * @param {string} accessor cell's attribute name
 * @returns {JSX.Element}
 */
export const UnrespondedUnconvertedIconCell = ({ field, accessor }) => {
  const converted = !field[accessor[1]] ? "#AFAEAE" : '#80B602'
  const responded = !field[accessor[0]] ? "#AFAEAE" : '#80B602'
  return (
    <td>
      <span className="center">
        {<FaReply style={{ color: responded }} size={14} className="mr-2" />}
        {<FaSyncAlt style={{ color: converted }} size={14} />}
      </span>
    </td>
  );
};

/**
 * This component is used to render the date if the check is true otherwise date with time. If the date is overdue 
 * then an icon is shown alongside the date.
 * @param {object} field contains information related to the row
 * @param {string} accessor cell's attribute name
 * @param {string} check check if the date is to be shown along with associated time or not 
 * @returns {JSX.Element}
 */
export const DateCell = ({ field, accessor, check }) => {
  return (
    <td>
      {check(field) ? formatDate(field[accessor]) : formatActivityDate(field[accessor])}
    </td>
  );
};

/**
 * it is used to render status cells.
 * @param {object} field contains information related to the row
 * @param {string} accessor cell's attribute name
 * @returns {JSX.Element}
 */
export const StatusCell = ({ field, accessor }) => {
  return <td>{field[accessor] ? "post-pending" : "pending"}</td>;
};

/**
 * it is used to render hover action  cells.
 * @param {object} field contains information related to the row
 * @param {array} actions hover actions
 * @returns {JSX.Element}
 */
export const HoverActionCell = ({ field, actions }) => {
  return (
    <td>
      <div className="data-actions">
        {actions.map((action, i) => {
          const { component: Component, ...rest } = action;
          return <Component key={i + "action-item"} {...rest} field={field} />;
        })}
      </div>
    </td>
  );
};

/**
 * it is used to render text cells with tooltip.
 * @param {object} field contains information related to the row
 * @param {string} accessor cell's attribute name
 * @param {function} mapper
 * @returns {JSX.Element}
 */
export const TooltipCell = ({ field, accessor, mapper, hasSub }) => {
  let tempValue = mapper ? mapper(field, accessor) : field[accessor];

  let slicedCellValue =
    isNotNULL(tempValue) && tempValue.length > 15
      ? tempValue.slice(0, 15).concat("...")
      : "";

  let tooltipValue = isNotNULL(slicedCellValue) ? (
    <OverlayTrigger
      name={tempValue}
      placement="top-start"
      delay={{ show: 250, hide: 400 }}
      overlay={(props) => (
        <div
          ref={props.ref}
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.85)",
            padding: "2px 10px",
            color: "white",
            borderRadius: 3,
            fontSize: "12px",
            ...props.style,
          }}
        >
          {tempValue}
        </div>
      )}
    >
      <span>{slicedCellValue}</span>
    </OverlayTrigger>
  ) : (
    tempValue
  );

  return hasSub ? tooltipValue : <td>{tooltipValue}</td>;
};

/**
 * it is used to render list cells.
 * @param {object} field contains information related to the row
 * @param {string} accessor cell's attribute name
 * @param {function} mapper
 * @returns {JSX.Element}
 */
export const ListCell = ({ field, accessor, mapper }) => {
  let data = field[accessor];
  let accessField, popoverList, type;

  if (field["__typename"] === "Customer_Delete_Request") {
    data = [];
    type = field["type"];

    if (field["type"] === PROSPECT) {
      accessField = field["current_community"];
    } else if (field["type"] === LEAD) {
      accessField = field["all_communities"].length > 0 ? field["all_communities"][0] : null;
    }

    field[accessor] && field[accessor].map((item, index) =>
      data.push({ id: index, name: item.name, is_active: item.is_active })
    );
  } else if (field["__typename"] === PROSPECT) {

    type = field["__typename"];
    accessField = field["community"] ? field["community"] : null;

  } else if (field["__typename"] === LEAD) {

    type = field["__typename"];
    if (accessor === "communities") accessField = field["communities"].length > 0 ? field["communities"][0] : null;
    if (accessor === "divisions") accessField = field["divisions"].length > 0 ? field["divisions"][0] : null;
  }

  if (accessField) {
    popoverList = data.filter((item) => item.name !== accessField.name);
  } else {
    popoverList = data.slice(1, data.length);
  }

  return (
    <td>
      <div className="all-community-card">
        <div>
          {accessField
            ? InactivityIcon(accessField, accessField.name)
            : isNotNULL(data[0])
              ? InactivityIcon(data[0], data[0].name)
              : " "}
        </div>
        {popoverList.length > 0 ? (
          <ButtonToolbar
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <OverlayTrigger
              trigger="click"
              key="down"
              placement="bottom"
              rootClose={true}
              overlay={
                <Popover
                  id="popover-positioned-down"
                  className="details-all-community"
                >
                  {accessField
                    ? InactivityIcon(
                      accessField,
                      accessField.name,
                      type === PROSPECT
                    )
                    : isNotNULL(data[0])
                      ? InactivityIcon(data[0], data[0].name)
                      : " "}
                  {popoverList.map((item, index) => {
                    return (
                      <div key={index} className="d-flex align-items-center">
                        {item.is_active === false && (
                          <FaBan size={10} className="td-warning" />
                        )}
                        {item.name}
                      </div>
                    );
                  })}
                </Popover>
              }
            >
              <div className="icon-btn btn-icon">
                <FaEllipsisH size={14} style={{ color: "#80B602" }} />
              </div>
            </OverlayTrigger>
          </ButtonToolbar>
        ) : null}
      </div>
    </td>
  );
};

/**
 * it is used to render date time cells.
 * @param {object} field contains information related to the row
 * @param {string} accessor cell's attribute name
 * @returns {JSX.Element}
 */
export const DateTimeCell = ({ field, accessor }) => {
  return <td>{formatReportDateTime(getDescendantProp(field, accessor))}</td>;
};

/**
 * it is used to render emails cells. If the email is disabled then it renders it disabled with an icon on the left
 * @param {object} field contains information related to the row
 * @param {string} accessor cell's attribute name
 * @param {boolean} check checks wether to disable the clicking the on cell.
 * @returns {JSX.Element}
 */
export const EmailCell = ({ field, accessor, callback, check, descendant = false }) => {
  const value = getDescendantProp(field, accessor)
  const emailStatus = descendant ? getDescendantProp(field, check) : field[check]
  return (
    <td>
      <a href={`mailto:${callback ? callback([field]) : value}`} id='email-cell' className={emailStatus === false ? "disabled" : ""}>
        {emailStatus === false && <img alt="" src={UnsubscribedEmailIcon} className="icon-unsubscribe" />}
        {value + " "}
      </a>
    </td>
  )
}

/**
 * it is used to render icon cells.
 * @param {object} field contains information related to the row
 * @param {string} accessor cell's attribute name
 * @param {function} IconMapper
 * @returns {JSX.Element}
 */
export const iconCell = ({ field, accessor, IconMapper }) => {
  let Icon = IconMapper(field, accessor)
  return (
    <td style={{ width: "15px" }}>
      <span>
        <Icon size={14} />
      </span>
    </td>
  )
}

/**
 * it is used to render icon cells with text.
 * @param {object} field contains information related to the row
 * @param {string} accessor cell's attribute name
 * @param {function} IconMapper
 * @returns {JSX.Element}
 */
export const iconTextCell = ({
  action,
  Icon,
  field,
  accessor,
  mapper,
  check,
  displayName,
}) => {
  return (
    <td>
      {check(field, accessor) && (
        <span onClick={() => action(field)} className="td-link">
          <Icon />
        </span>
      )}
      {mapper ? (
        <TooltipCell
          mapper={mapper}
          field={field}
          accessor={accessor}
          hasSub={true}
        />
      ) : (
        displayName(field, accessor)
      )}
    </td>
  );
};

export const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <img alt="" src={svg} />
      </components.DropdownIndicator>
    )
  );
};
