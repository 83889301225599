import React from "react";
import Drawer from "../../hoc/Drawer";
import { phoneDisplay } from "../../utils/helpers";
import { CreateEditRealtor as CreateEditRealtorWithMutation } from "../realtor/realtor-form-container";

/**
 * This component recieves the values for the form in props.
 * Sets the object according to the data required by the Realtor Form. 
 * Calls the Realtor Form component with the updated structure of
 * the data along with onSubmit and Drawer functions.
 * @param {*} props
 * @param {object} props.data contains information of the realtor
 * @param {function} props.onSubmit
 * @param {function} props.toggleShowDrawer closes the drawer
 * @param {boolean} props.showSideBar boolean that defines whether to show the drawer or not
 */
const ConvertRealtorView = props => {
  const {
    data,
    onSubmit,
    toggleShowDrawer,
    showSideBar
  } = props

  const formatData = (realtorData) => {
    return {
      first_name: realtorData.firstname || realtorData.first_name_1,
      last_name: realtorData.lastname || realtorData.last_name_1,
      primary_email: realtorData.email || realtorData.primary_email_1,
      cell_phone: realtorData.phone || realtorData.cell_phone_1,
      formatted_cell_phone: realtorData.phone && phoneDisplay(realtorData.phone),
      division: realtorData.division || realtorData.divisions?.[0] || null,
      Communities: realtorData.community
        ? [realtorData.community]
        : realtorData.communities?.length
          ? realtorData.communities
          : [],
      Realtor_Other_Emails: realtorData.secondary_emails_1?.map((email) => ({ email_address: email }))
    }
  }

  return (
    <Drawer show={showSideBar} toggleSideBar={toggleShowDrawer}>
      <CreateEditRealtorWithMutation
        close={toggleShowDrawer}
        realtor={data && formatData(data)}
        webformId={data?.form_submission_id}
        customerId={data?.customer_id}
        onSubmit={onSubmit}
      />
    </Drawer>
  );
}

export default ConvertRealtorView;