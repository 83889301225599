import gql from 'graphql-tag';

const GET_LEADS_FOR_ALL_LEADS = gql`
query getLeadsForAllLeads($limit: Int, $pageNum: Int, $filter: LeadFilter, $search: String, $sort: [String]) {
  getLeads(limit: $limit, pageNum: $pageNum, filter: $filter, search: $search, sort: $sort) {
    Leads {
      id
      customer_id
      first_name_1
      last_name_1
      first_name_2
      last_name_2
      primary_email_1
      primary_email_2
      secondary_emails_1
      secondary_emails_2
      primary_email_1_status
      primary_email_2_status
      cell_phone_1
      cell_phone_2
      other_notes
      osc_notes
      divisions {
        id
        name
      }
      communities {
        id
        name
        is_active
      }
      customer_origination_area {
        id
        name
      }
      state {
        id
        name
      }
      zip
      street_address
      city
      created_at
      updated_at
      last_activity
      last_activity_datetime
      realtor {
        id
        first_name
        last_name
      }
    }
    total_count
  }
}
`;

export default GET_LEADS_FOR_ALL_LEADS;