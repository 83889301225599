
import gql from 'graphql-tag';

const UPDATE_LEAD = gql`
mutation updateLead($input: UpdateLeadInput!) {
    updateLead(input: $input) {
    message
    code
    data{
      id
      name
    }
  }
}
`;

export default UPDATE_LEAD;