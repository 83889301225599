
import gql from 'graphql-tag';

const ADD_PROSPECT_FOLLOW_UP = gql` 
  mutation addProspectFollowUp($input: ProspectFollowUpInput!) {
    addProspectFollowUp(input: $input) {
      message
      code
    }
  }
`;

export default ADD_PROSPECT_FOLLOW_UP;