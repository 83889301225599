import gql from 'graphql-tag';

const UPDATE_TITLE_CUSTOMIZATIONS = gql`
  mutation updateTitleCustomizations($titleCustomizations:[TitleCustomization]){
    updateTitleCustomizations(titleCustomizations: $titleCustomizations) @client{
      titleCustomizations{
        name
        value
      }
    }
  }
`;


export default UPDATE_TITLE_CUSTOMIZATIONS;