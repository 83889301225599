import React, { useContext } from "react";
import { compose, graphql } from "react-apollo";

import CreateEditLeadWithMutation from "../forms/create-edit-lead-container";
import LeadsTable from "./leads-container";
import DashboardContextProvider from "../../../context/dashboard-context-provider";
import { GET_DASHBOARD_LEADS, GET_LEADS_COUNT } from "../../../graphql/queries";
import { FaFilter } from "react-icons/fa";
import { getCreateDateFilters } from "../../../utils/helpers";
import * as cardsType from "./lead-card-types";
import { GET_PREVIOUS_CARD } from "../../../cacheql/queries";
import { Auth } from "../../../rbac/rbac";
import moment from 'moment';

const activityCards = [
  {
    type: cardsType.TODAY,
    title: "Today",
    tableTitle: "New Leads Today",
    filter: getCreateDateFilters("today"),
  },
  {
    type: cardsType.THIS_MONTH,
    title: moment().format('MMM'),
    tableTitle: "New Leads This Month",
    filter: getCreateDateFilters("month"),
    hash: "#month",
    secondaryDataQuery: {
      filter: getCreateDateFilters("prev_year_month"),
      dataQuery: GET_LEADS_COUNT,
      name: "getLeadCountLastYearMonth",
    },
  },
  {
    type: cardsType.THIS_YEAR,
    title: "YTD",
    tableTitle: "New Leads This Year",
    filter: getCreateDateFilters("year"),
    hash: "#year",
    secondaryDataQuery: {
      filter: getCreateDateFilters("prev_year"),
      dataQuery: GET_LEADS_COUNT,
      name: "getLeadCountLastYear",
    },
  },
  {
    title: "All",
    tableTitle: "All Leads",
    customDataQuery: GET_LEADS_COUNT,
    customPath: "getLeads.total_count",
  },
];

/**
 * This component is used to render lead dashboard with 3 cards and a table. Each card has same query and its
 * own filter. All the tables render the same table with data change only. This dahsboard contains community
 * dropdown and division dropdown is there for manager role only. By default All leads card is preselected.
 * @param {*} props
 */
const LeadsDashboard = (props) => {
  const { is_manager, has_multiple_divisions } = useContext(Auth);

  const getDefaultCard = () => {
    const cardTitle = props.getPreviousCard.previousCard.title;
    const index = activityCards.findIndex((item) => item.title === cardTitle);
    if (index === -1) {
      return activityCards[3];
    }
    return activityCards[index];
  };

  return (
    <DashboardContextProvider
      type="Leads"
      text="New Lead"
      startingDefaultCard={getDefaultCard()}
      dataQuery={GET_DASHBOARD_LEADS}
      activityCards={activityCards}
      formContent={CreateEditLeadWithMutation}
      icon={FaFilter}
      withCommunity
      withDivision={is_manager() || has_multiple_divisions()}
      tableView={<LeadsTable />}
      {...props}
    />
  );
};

export default compose(graphql(GET_PREVIOUS_CARD, { name: "getPreviousCard" }))(LeadsDashboard);
