export const getInitialState = ({ cache }) => {
    return {
      pageNum: (cache && cache.pageNum) || 0,
      pageSize: (cache && cache.pageSize) || 10,
      searchTerm: (cache && cache.searchTerm) || "",
      showRealtorBar: false,
      loading: true,
      realtorSales: [],
      drawerItem: []
    };
  };
  
  export default function reducer(state, action) {
    switch (action.type) {
      case "UPDATE":
        return Object.assign({}, state, { ...action.payload });
  
      default:
        return state;
    }
  }
  