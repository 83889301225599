import gql from "graphql-tag";

const GET_DASHBOARD_LEADS = gql`
  query getDashboardLeads($input: LeadFilter) {
    getLeads(filter: $input, limit:1) {
      Leads {
        id
        first_name_1
        last_name_1
        communities {
          id
          name
          is_active
        }
      }
      total_count
    }
  }
`;

export default GET_DASHBOARD_LEADS;
