import { getCardParams } from "../../../utils/helpers";
import { compose, graphql } from "react-apollo";
import { SET_TABLE_PARAMS } from "../../../cacheql/mutations";
import { AllRegistrationsTable } from "./all-realtor-registrations-table";
import { GET_ALL_REALTOR_REGISTRATIONS } from "../../../graphql/queries"
import { GET_TABLE_PARAMS, GET_SELECTED_DIVISION } from "../../../cacheql/queries";

export default compose(
  graphql(SET_TABLE_PARAMS, { name: "setTableParams" }),
  graphql(GET_TABLE_PARAMS, { name: "getTableParams" }),
  graphql(GET_SELECTED_DIVISION, { name: "selectedDivision" }),
  graphql(GET_ALL_REALTOR_REGISTRATIONS, {
    options: ({
      currentCard: { filter, uniqueTitle },
      getTableParams: { tableParams },
      selectedDivision
    }) => {
      let params = getCardParams(uniqueTitle, tableParams);

      return {
        variables: {
          filter: {
            ...filter,
            ...(params && params.filter),
            division_id: selectedDivision.selectedDivision.id,
          },
          search: (params && params.search) || "",
          pageNum: (params && params.pageNum) || 0,
          limit: (params && params.limit) || 10,
          sort:
            params && params.sort && params.sort.id !== -1
              ? [params.sort.name, params.sort.sort]
              : null
        },
        fetchPolicy: "cache-and-network"
      };
    }
  }),
)(AllRegistrationsTable);
