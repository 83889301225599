import React, {useState} from 'react'
import {compose,graphql} from 'react-apollo'
import { FaPlus, FaPen, FaTrashAlt } from "react-icons/fa";

import { ClickableIcon } from "./email-associations";
import "./admin-styles.css";
import CustomTable from "../../components/table/custom-table";
import { ActionCell } from "../../components/new-table";
import { isNotNULL } from "../../utils/helpers";
import Dialog from "../../components/dialog/dialog";

import { CREATE_LEAD_CLASSIFICATION_FILTER, DELETE_LEAD_CLASSIFICATION_FILTER, UPDATE_LEAD_CLASSIFICATION_FILTER } from '../../graphql/mutations';
import { GET_ALL_LEAD_CLASSIFICATION_FILTERS } from '../../graphql/queries';

import { SET_NOTIFICATION_PROPS } from "../../cacheql/mutations";

const LeadFilterCustomization = ({getAllLeadFilters: {getAllLeadClassificationFilters, refetch}, ...props}) => {
    const [editID, setEditID] = useState();
    const [newLeadFilterValue, setNewLeadFilterValue] = useState();
    const [showDialog, setShowDialog] = useState(false);
    const [dailogTitle, setDailogTitle] = useState("");
  
    const closeDailog = (message) => {
      setShowDialog(false);
      setEditID();
      setNewLeadFilterValue()
      refetch();
      message &&
        props.setNotificationProps({ variables: { open: true, message } });
    };
  
    /**
     * This function is used for add status mutation
     *@param {string} name name of status
     * @constant
     * @memberof module:EmailAssociationsModule
     */
    const addMutation = ({ name }) => {
      props
        .createLeadFilter({
          variables: {
            input: {
              name,
            },
          },
        })
        .then((res) => {
          res.data.createLeadClassificationFilter &&
            closeDailog(res.data.createLeadClassificationFilter.message);
        });
    };
  
    /**
     * This function is used for edit status mutation
     * @param {string} name name of status
     * @param {number} id id of status
     * @constant
     * @memberof module:EmailAssociationsModule
     */
    const updateMutation = ({ id, name }) => {
      props
        .updateLeadFilter({
          variables: {
            id,
            input: {
              name,
            },
          },
        })
        .then((res) => {
          res.data.updateLeadClassificationFilter &&
            closeDailog(res.data.updateLeadClassificationFilter.message);
        });
    };
  
    /**
     * This function is used for delete status mutation
     * @param {number} id id of status
     * @constant
     * @memberof module:EmailAssociationsModule
     */
    const deleteMutation = ({ id }) => {
      props
        .deleteLeadFilter({
          variables: {
            id,
          },
        })
        .then((res) => {
          res.data.deleteLeadClassificationFilter &&
            closeDailog(res.data.deleteLeadClassificationFilter.message);
        });
    };
  
    /**
     * This function is used to handle the change in input field
     * @param {string} value Value of input
     */
    const handleChange = (value) => {
      setNewLeadFilterValue(value !== " " ? value : null);
    };
  
    /**
     * This function is used to render the dialogs for add edit and delete.
     * @constant
     * @memberof module:EmailAssociationsModule
     * @returns {JSX.Element}
     */
    const dailogBody = () => {
      return (
        <div className="dailog-body">
          {dailogTitle === "Delete" ? (
            <>
              <p>
                Do you want to delete the lead filter ?
              </p>
            </>
          ) : (
            <input
              type="text"
              value={newLeadFilterValue}
              className="form-control"
              placeholder="Customer source name"
              onChange={(e) => handleChange(e.target.value)}
            />
          )}
        </div>
      );
    };

    const getTableData = () => {
        const tableData = [];
    
        if (isNotNULL(getAllLeadClassificationFilters)) {
          getAllLeadClassificationFilters.forEach((tags) => {
            tableData.push([
              <span>{tags.name}</span>,
              <span>
                <div className="data-actions always-visible">
                  <ActionCell
                    tooltip="Edit"
                    icon={FaPen}
                    key={"action-item-1"}
                    action={() => {
                      setDailogTitle("Edit");
                      setEditID(tags.id);
                      setNewLeadFilterValue(tags.name);
                      setShowDialog(true);
                    }}
                  />
                  <ActionCell
                    tooltip="Delete"
                    icon={FaTrashAlt}
                    key={"action-item-2"}
                    action={() => {
                      setDailogTitle("Delete");
                      setEditID(tags.id);
                      setShowDialog(true);
                    }}
                  />
                </div>
              </span>,
            ]);
          });
        }
    
        return tableData;
      };

    return (
        <>
      <div className="col-padding col-md-6 col-lg-4 other-customization-root">
        <h3 className='category-header'>Leads</h3>
        <div className="customization-box customer-source-tag">
          <div className="d-flex">
            <h3>Lead Classification Filters</h3>
            <div className="ml-3">
              <ClickableIcon
                tooltip="Add Lead Filter"
                className="sources-add-btn"
                id="add-icn"
                icon={FaPlus}
                callback={() => {
                  setDailogTitle("Create New Lead Filter");
                  setShowDialog(true);
                }}
              />
            </div>
          </div>
          <div className="table-compact scrollable-table">
            <CustomTable tableData={getTableData()} />
          </div>
        </div>
      </div>
      <Dialog
        size="sm"
        show={showDialog}
        onHide={() => closeDailog()}
        title={dailogTitle}
        body={dailogBody()}
        click={() => {
          editID
            ? dailogTitle === "Edit"
              ? updateMutation({ id: editID, name: newLeadFilterValue })
              : deleteMutation({ id: editID })
            : addMutation({ name: newLeadFilterValue });
        }}
        clickname="Ok"
        closename="Cancel"
      />
    </>
    )
}

export default compose(
    graphql(GET_ALL_LEAD_CLASSIFICATION_FILTERS, { name: "getAllLeadFilters" }),
  graphql(CREATE_LEAD_CLASSIFICATION_FILTER, {
    name: "createLeadFilter",
  }),
  graphql(UPDATE_LEAD_CLASSIFICATION_FILTER, {
    name: "updateLeadFilter",
  }),
  graphql(DELETE_LEAD_CLASSIFICATION_FILTER, {
    name: "deleteLeadFilter",
  }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" })
)(LeadFilterCustomization)