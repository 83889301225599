import * as actionTypes from "./action-types";
import { copyByValue } from "./utils";

import _ from "lodash";

export const getInitialState = state => {
  const pageSize = 10;
  let data = copyByValue(state.data);
  data = data.map((obj, i) => ({
    ...obj,
    isSelected: false,
    uuid: i
  }));
  return Object.assign({}, state, {
    data,
    rows: copyByValue(data),
    rowsLength: data.length,
    pageSize,
    currentPage: state.currentPage,
    searchTerm: state.searchTerm || "",
    filter: state.filter || null,
    controlled: state.controlled,
    manualPagination: state.controlled,
    manualSorting: state.controlled,
    manualFilters: state.controlled,
    userPageCount: state.pageCount,
    userTotalCount: state.userTotalCount,
    sortColumn: state.sortColumn || {
      id: -1,
      name: "",
      comparator: "",
      sort: ""
    }
  });
};

export default function reducer(state, action) {
  switch (action.type) {
    case actionTypes.TOGGLE_ALL:
      return Object.assign({}, state, { ...action.payload });
    case actionTypes.TOGGLE_ROW:
      return Object.assign({}, state, { ...action.payload });
    case actionTypes.GOTO_PAGE:
      return Object.assign({}, state, { ...action.payload });
    case actionTypes.SET_PAGE:
      return Object.assign({}, state, { ...action.payload });
    case actionTypes.SORT_DATA:
      return Object.assign({}, state, { ...action.payload });
    case actionTypes.SET_SORT_COL:
      return Object.assign({}, state, { ...action.payload });
    case actionTypes.SET_SEARCH_TERM:
      return Object.assign({}, state, { ...action.payload });
    case actionTypes.SET_FILTER:
      return Object.assign({}, state, { ...action.payload });
    case actionTypes.FILTERED_ROWS:
      return Object.assign({}, state, { ...action.payload });
    case actionTypes.UPDATE_PAGE_ON_UPDATE:
      return Object.assign({}, state, { ...action.payload });
    case actionTypes.UPDATE:
      //We want to presver old table state after update
      //this will make sure old state is there
      const oldRecords = [...state.data];
      const oldSelectedRecords = oldRecords.filter(rec => rec.isSelected);

      const data = action.payload.state.data.map((obj, i) => ({
        ...obj,
        isSelected: (() => {
          const selectedRecords = oldSelectedRecords.filter(rec => {
            const { isSelected, uuid, ...recToMatchWith } = rec;
            return _.isEqual(obj, recToMatchWith) && isSelected;
          });
          return selectedRecords.length > 0 ? true : false;
        })(),
        uuid: i
      }));

      return Object.assign(
        {},
        state,
        { ...action.payload.state },
        {
          data,
          rows: copyByValue(data),
          rowsLength: data.length
        }
      );

    default:
      return state;
  }
}
