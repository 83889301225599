import gql from 'graphql-tag';

const DELETE_REALTOR = gql`
  mutation deleteRealtor($id: ID!) {
    deleteRealtor(id: $id) {
      message
      code
    }
  }
`;

export default DELETE_REALTOR;