import React, { useEffect, useState } from "react";
import { useQuery } from "react-apollo-hooks";
import CustomTable from "../table/custom-table";
import {
  formatLocalTime,
  getDateTimeAMPM,
  getHourDiff,
  getCurrentTimezone
} from "../../utils/helpers";
import personImg from "../../images/profile-placeholder.jpg";
import { GET_DIVISION_BY_COMMUNITY_ID } from "../../graphql/queries";

import "../lists/lists.css"
import "./schedule-chart.css";

/**
 * This component is used to show the schedules of all CSM's at the time of creating an appointment. If a specific CSM is selected from the form, then
 * that CSM's schedule is shown in the chart.
 * @param {Object} props
 * @param {Object} props.data Data of each CSM, containing information about the csm e.g. first name, last name and its Outlook Appointments
 * @param {Boolean} props.loading A boolean to identify if the data has loaded or not
 * @param {Int} props.csm_id The CSM's ID to show the data of that CSM
 * @param {DateTime} props.start_date The date on which to show the schedule
 * @param {Object} prop.times The start and end time for the appointment being set in the form
 * @param {string} props.setScheduleError Callback function to set an error in the form
 * @param {Function} props.handleSelection Callback function to handle selection in the list
 */
const ScheduleChart = ({
  data,
  loading,
  defaultValue,
  isEditing,
  value,
  timeFilter,
  handleSelection,
  errors,
  communityId,
  index
}) => {
  const [scheduleData, setScheduleData] = useState([])
  const [timezone, setTimezone] = useState()

  const { data: divisionData, loading: divisionDataLoading } = useQuery(GET_DIVISION_BY_COMMUNITY_ID, { variables: { community_id: communityId } });

  useEffect(() => {
    if (divisionData)
      setTimezone(divisionData.getDivisionByCommunityId.timezone)
  }, [divisionDataLoading])

  useEffect(() => {
    if (isEditing) {
      const { startTime, endTime, csm_id } = defaultValue
      const checkTimeFilterType = getDateTimeAMPM(startTime)
      const tempData = checkTimeFilterType === timeFilter ? data.map(csm => {
        //Check if timeslot has not already been added to data array in order to prevent duplication
        const alreadyExists = csm.timeSlots.some(slot => slot.meetingStartDateTime === startTime && slot.meetingEndDateTime === endTime)

        //Check if the durations match between the selected timeslot and appointment type selected in the form
        const durationMatch = csm.timeSlots.length && getHourDiff(startTime, endTime) === getHourDiff(csm.timeSlots[0].meetingStartDateTime, csm.timeSlots[0].meetingEndDateTime)

        if (csm.id === csm_id && durationMatch && !alreadyExists) {
          const index = csm.timeSlots.findIndex(slot => slot.meetingEndDateTime === startTime)

          csm.timeSlots.splice(index + 1, 0, {
            meetingStartDateTime: startTime,
            meetingEndDateTime: endTime
          })
          return { ...csm }
        }
        return csm
      }) : data

      setScheduleData(tempData)
    }
    if (!loading) {
      setScheduleData(data)
    }
  }, [loading])

  /**
   * A function that extracts the start and end times of the appointment
   * @param {Object} field Data from which to obtain the start and end time of the appointment
   */
  const getAppointmentTime = (field) => {
    const { meetingStartDateTime, meetingEndDateTime } = field;

    const start_time = formatLocalTime(meetingStartDateTime, timezone);
    const end_time = formatLocalTime(meetingEndDateTime, timezone);

    return `${start_time} - ${end_time}`;
  };

  /**
   * A function that checks if the current timeslot matches the value set in the form
   * @param {Object} timeslot Data containing the meeting start and end date time
   * @returns A boolean value specifying if they timeslots match
   */
  const checkTimesMatch = (timeslot) => {
    if (value) {
      const start_time = new Date(timeslot.meetingStartDateTime).getTime();
      const end_time = new Date(timeslot.meetingEndDateTime).getTime();

      return value.startTime.getTime() === start_time && value.endTime.getTime() === end_time ? true : false
    }
    return false
  }

  /**
   * A function that renders the table body to show in the chart. It contains of two columns, the first showing an image of the CSM and its name and the second column
   * consists of a list of CSM's appointments. 
   */
  const getTableBody = () => {
    const tableData = [];

    if (loading) {
      return tableData
    }

    scheduleData.length
      ? scheduleData.map((csm_data) => {
        tableData.push([
          <span>
            <div className='profile-img-container'>
              <img
                className="profile-img"
                src={csm_data.image_url || personImg}
              />
              {` ${csm_data.first_name} ${csm_data.last_name}`}
            </div>
            <div className='d-flex flex-md-wrap'>
              {csm_data.timeSlots.length ? csm_data.timeSlots.map((item, key) => (
                <div
                  className={csm_data.id === value.csm_id && checkTimesMatch(item) ? "chip-body chip-body-selected" : "chip-body"}
                  key={key}
                  onClick={() =>
                    handleSelection(item, csm_data, index)
                  }
                >
                  <div className="chip-text-align">
                    <span className="chip-title">
                      {getAppointmentTime(item)}
                    </span>
                  </div>
                </div>
              )) : (
                <div className="chip-body-disabled chip-text-align">
                  <span className="chip-title">
                    No Available Slots
                  </span>
                </div>
              )}
            </div>
          </span>,
        ])
      })
      : tableData.push([
        <span>
          No CSM is available on this day.
        </span>,
      ]);

    return tableData;
  };

  return (
    <div className="schedule-card">
      <div className='d-flex title-container'>
        <label>CSM and Time Slot Selection</label>
        {getCurrentTimezone() !== timezone && <span className="invalid-feedback">** Timeslots shown are according to the {timezone} timezone</span>}
      </div>
      <CustomTable tableData={getTableBody()} />
      <span className='invalid-feedback'>{errors}</span>
    </div>
  );
};

export default ScheduleChart;
