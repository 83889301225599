import * as Yup from "yup";

export const allRegistrationFilterForm = {
  expired: null,
  agency: "",
  realtor: "",
};

export const allRegistrationFilterSchema = Yup.object().shape({
  expired: Yup.boolean().default(null).nullable(),
  agency: Yup.string().default("").nullable(),
  realtor: Yup.string().default("").nullable(),
});
