import React, { useEffect, useMemo } from "react";
import _ from "lodash";
import TableView, {
  TextHeader,
  TextCellWithMapper,
  TextCell,
} from "../../components/new-table";
import FadeIn from "../../components/fade-in";
import {
  formatActivityDate,
  getCardParams,
  getUpdatedParams,
  formatDate,
} from "../../utils/helpers";
import reducer, { getInitialState } from "./osc-commission-report-reducer";
import CustomLoader from "../../components/custom-loader";


/** @module OscCommissionReportTableModule */

/**
 * This component is a table used to display the osc commission reports. This table does not contain any title or search
 * bar. It contains pagination and export to csv options. It has no hover-actions. It shows multiple 
 * columns displaying basic information related to commission. Table uses reducer to maintain its state. It also 
 * accesses store to check if table parameters are already stored or not.
 * 
 * @param {Object} props it contains all the mutations/queries in the conatiner as props
 * @param {Object} props.data it contains array of commissions to be shown on table
 * @param {Object} props.getTableParams it contains table info stored inside cache
 * @returns {JSX.Element} It returns jsx containing the table 
 */
const OscCommissionReportTable = ({
  data,
  getTableParams: { tableParams },
  ...props
}) => {
  const cacheParams = getCardParams("OSC Commission Report", tableParams);
  const [state, dispatch] = React.useReducer(
    reducer,
    getInitialState({ loading: data.loading, cache: cacheParams })
  );

  const { report, loading, render, userPageCount, pageSize } = state;

  const callDispatch = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  /**
   * Either set the data in state or set the loading to inform the table what to render.
   */
  useEffect(() => {
    if (!data.loading)
      callDispatch("UPDATE", {
        render: true,
        loading: false,
        report:
          data &&
          data.getOscCommissionReport &&
          data.getOscCommissionReport.commission_report,
        userPageCount: Math.ceil(data.getOscCommissionReport.total_count / pageSize),
      });
    if (data.loading) callDispatch("UPDATE", { loading: data.loading });
  }, [data]);

    /**
   * This defines columns of the table. It has multiple columns, displaying information of the commissions.
   * 
   * @constant
   * @memberof module:OscCommissionReportTableModule
   */
  const columns = useMemo(
    () => [
      {
        id: "0",
        header: "First Name",
        accessor: "first_name_1",
        component: TextHeader,
        cell: {
          component: TextCell,
        },
      },
      {
        id: "1",
        header: "Last Name",
        accessor: "last_name_1",
        component: TextHeader,
        cell: {
            component: TextCell,
        },
      },
      {
        id: "2",
        header: "Community",
        accessor: "community",
        component: TextHeader,
        csvMapper: (field, accessor) =>
          field[accessor]
            ? field[accessor].name
            : "",
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) =>
            field[accessor] &&
            field[accessor].name
        }
      },
      {
        id: "3",
        header: "Lot",
        accessor: "lot",
        component: TextHeader,
        cell: {
          component: TextCell,
        },
      },
      {
        id: "4",
        header: "Date Purchased",
        accessor: "purchased_date",
        component: TextHeader,
        csvMapper: (field, accessor) => formatDate(field[accessor]),
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) => formatDate(field[accessor]),
        },
      },
      {
        id: "6",
        header: "OSC Name",
        accessor: "osc",
        component: TextHeader,
        csvMapper: (field, accessor) =>
          field[accessor]
            ? field[accessor].first_name+" "+field[accessor].last_name
            : "",
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) =>
            field[accessor] &&
            field[accessor].first_name+" "+field[accessor].last_name
        }
      },
      {
        id: "7",
        header: "Appointment Set Date",
        accessor: "appointment_set_date",
        component: TextHeader,
        csvMapper: (field, accessor) => formatActivityDate(field[accessor]),
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) => formatActivityDate(field[accessor]),
        },
      },
    ],
    []
  );

  //Fetchmore and pagination functions
   /**
   * It is a callback function which is called on any table change like: pagination, page-size.
   * Table passes its current state so that we can update the table parameters in store and state, which are used in 
   * refetching the data with correct parameters.  
   * 
   * @function
   * @inner
   * @memberof module:OscCommissionReportTableModule
   * @see {@link module:OscCommissionReportTableModule~OscCommissionReportTable}
   */
  const fetchData = React.useCallback((tableProps) => {
    if (loading) {
      return;
    }
    const variables = {
      filter: {
        ...tableProps.filter,
      },
      limit: tableProps.pageSize,
      pageNum: tableProps.currentPage,
    };

    callDispatch("UPDATE", {
      ...variables,
      pageSize: tableProps.pageSize,
      loading: true,
    });

    const pageCount = Math.ceil(
      data.getOscCommissionReport.total_count / tableProps.pageSize
    );
    if (
      !_.isEqual(variables.filter, state.filter)
    ) {
      variables.pageNum = 0;
    }
    if (tableProps.currentPage > pageCount - 1) {
      variables.pageNum = pageCount ? pageCount - 1 : 0;
    }
    if (pageCount !== userPageCount)
      callDispatch("UPDATE", { userPageCount: pageCount });


    dispatch({ type: "UPDATE", payload: { ...variables, pageSize: tableProps.pageSize, loading: true } });
    props
      .setTableParams({
        variables: {
          cardsParams: getUpdatedParams(tableParams.cardsParams, {
            cardTitle: "OSC Commission Report",
            params: variables,
          }),
        },
      })
      .then((res) => {
        callDispatch("UPDATE", { loading: false });
      });
  });

  const tableOptions = {
    title: "",
  };
  return (
    <div>
      <FadeIn show={render}>
        <div>
          <TableView
            columns={columns}
            removeSearchField
            data={report}
            tableOptions={tableOptions}
            loading={loading}
            userPageCount={userPageCount}
            userTotalCount={
              !loading ? data.getOscCommissionReport.total_count : null
            }
            fetchData={fetchData}
            currentPage={state.pageNum}
            pageSize={state.pageSize}
            filter={state.filter}
            controlled={true}
          />
        </div>
      </FadeIn>
      {!render && (
        <CustomLoader
          style={{ marginLeft: "auto", marginRight: "auto", zIndex: 9999 }}
        />
      )}
    </div>
  );
};

export default OscCommissionReportTable;
