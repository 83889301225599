import React, { useState, useEffect } from "react";
import { compose, graphql } from "react-apollo";

import Drawer from "../../../hoc/Drawer";
import { GET_HOMEOWNER_ON_EDIT } from "../../../graphql/queries";
import { isNotNULL } from "../../../utils/helpers";
import EditHomeownerForm from '../forms/update-homeowner-container'

/**
 * The main purpose of this component is to open and close the sidebar to show/hide homeowner edit form. It loads the
 * data from backend, formats it according to the schema of the homeowner and passes the data to the homeowner form
 * 
 * @param {Object} props 
 */
const EditHomeowner = ({ getHomeOwner, ...props }) => {
  const [showSideBar, setShowSideBar] = useState(false);
  const [data, setData] = useState([]);

  const toggleShowDrawer = () => {
    props.close && props.close();
    setShowSideBar(false);
  };

  const onSubmit = () => {
    setShowSideBar(false);
    props.submitHandler();
  };

  const formatData = (data) => {
    let dataToReturn = (({
      first_name_1,
      last_name_1,
      primary_email_1,
      cell_phone_1,
      secondary_emails_1,
      first_name_2,
      last_name_2,
      primary_email_2,
      cell_phone_2,
      secondary_emails_2,
      city,
      state,
      customer_origination_area,
      zip,
      street_address,
      customer_id,
      realtor,
      primary_email_1_status,
      primary_email_2_status
    }) => {
      return {
        first_name_1,
        last_name_1,
        primary_email_1: primary_email_1,
        display_primary_email_1: primary_email_1,
        cell_phone_1,
        secondary_emails_1,
        first_name_2,
        last_name_2,
        primary_email_2: primary_email_2,
        display_primary_email_2: primary_email_2,
        cell_phone_2,
        secondary_emails_2,
        city,
        zip,
        street_address,
        id: customer_id,
        primary_email_1_status,
        primary_email_2_status,
        realtor_id: realtor ? realtor.id : undefined,
        customer_origination_area_id:
          (customer_origination_area && customer_origination_area.id) ||
          undefined,
        state_id: state && state.id ? state.id : undefined,
      };
    })(data);

    return dataToReturn;
  };

  useEffect(() => {

    if (isNotNULL(props.homeownerData)) {
      setShowSideBar(true);
    } else if (!getHomeOwner.loading &&
      getHomeOwner.getAllHomeOwners &&
      getHomeOwner.getAllHomeOwners.Home_Owner[0]
    ) {
      setData(getHomeOwner.getAllHomeOwners.Home_Owner[0]);
      setShowSideBar(true);
    }
  }, [getHomeOwner]);

  return (
    <Drawer show={showSideBar} toggleSideBar={toggleShowDrawer}>
      <EditHomeownerForm
        close={toggleShowDrawer}
        data={formatData(isNotNULL(props.homeownerData) ? props.homeownerData : data)}
        id={props.id}
        onSubmit={onSubmit}
      />
    </Drawer>
  );
};

const EditHomeownerView = compose(
  graphql(GET_HOMEOWNER_ON_EDIT, {
    name: "getHomeOwner",
    skip: props => isNotNULL(props.homeownerData),
    options: props => {
      return {
        variables: { filter: { id: props.id } },
        fetchPolicy: "network-only"
      };
    }
  })
)(EditHomeowner);

export default EditHomeownerView;
