
import gql from 'graphql-tag';

const CREATE_PROSPECT_FILTER = gql`
mutation addProspectFilter($input: AddProspectFilterInput!){
    addProspectFilter(input: $input) {
      message
      code
    }
  }
`;

export default CREATE_PROSPECT_FILTER;