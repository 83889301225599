
import gql from 'graphql-tag';

const DELETE_LEAD_NOTE = gql` 
  mutation deleteLeadNote($input: LeadNoteDeleteInput!) {
    deleteLeadNote(input: $input) {
      message
      code
    }
  }
`;

export default DELETE_LEAD_NOTE;