import gql from 'graphql-tag';

export const GET_MARKETING_ONLY = gql`
  query getMarketingOnly($filter: MarketingOnlyFilter, $sort: [String], $search: String, $limit: Int, $pageNum: Int) {
    getMarketingOnly(filter: $filter, sort: $sort, search: $search, limit: $limit, pageNum: $pageNum) {
      Marketing_Only {
        id
        customer_id
        first_name_1
        last_name_1
        first_name_2
        last_name_2
        primary_email_1
        primary_email_2
        secondary_emails_1
        secondary_emails_2
        primary_email_1_status
        primary_email_2_status
        cell_phone_1
        cell_phone_2
        other_notes
        osc_notes
        division {
        id
        name
        }
        communities {
          id
          name
          is_active
        }
        customer_origination_area {
          id
          name
        }
        state {
          id
          name
        }
        zip
        street_address
        city
        created_at
        updated_at
      }
      total_count
    }
  }
`;

export const GET_MARKETING_ONLY_COUNT = gql`
  query getMarketingOnlyCount($input: MarketingOnlyFilter) {
    getMarketingOnly(filter: $input, limit: 1) {
      total_count
    }
  }
`;

export const GET_DASHBOARD_MARKETING_ONLY = gql`
  query getDashboardMarketingOnly($input: MarketingOnlyFilter) {
    getMarketingOnly(filter: $input, limit: 1) {
      Marketing_Only {
        id
        first_name_1
        last_name_1
        communities {
          id
          name
          is_active
        }
      }
      total_count
    }
  }
`;

export const GET_MARKETING_ONLY_DETAIL = gql`
  query getMarketingOnlyByFilter($limit: Int, $pageNum: Int, $filter: MarketingOnlyFilter, $search: String, $sort: [String]) {
    getMarketingOnly(limit: $limit, pageNum: $pageNum, filter: $filter, search: $search, sort: $sort) {
      Marketing_Only {
        id
        customer_id
        first_name_1
        last_name_1
        primary_email_1
        secondary_emails_1
        cell_phone_1
        first_name_2
        last_name_2
        primary_email_2
        secondary_emails_2
        primary_email_1_status
        primary_email_2_status
        cell_phone_2
        street_address
        city
        osc_notes
        form_submissions{
          id
          interested_in
          other_data
          response_phone
          response_sms
          response_email
          created_at
          updated_at
          responded_at
          response_medium
          domain
          url
          lot
          form_type
          url
          community{
            name
            is_active
          }
          responder{
            first_name
            last_name
          }
          division {
            id
            name
          }
        }
        customer_origination_area {
          id
          name
        }
        state {
          id
          name
        }
        zip
        other_notes
        communities {
          id
          name
          is_active
          division_id
        }

        division {
          id
          name
        }
        mailchimp_emails {
          mailchimp_email_id
          datetime
          subject
          email_addresses
          email_from
          content_url
          count
        }
        created_at
        updated_at
      }
      total_count
    }
  }
`;

export const GET_MARKETING_ONLY_FILTERS = gql`
  query {
    getMarketingOnlyFilters {
      id
      filter_name
    }
  }
`;

export const GET_MARKETING_ONLY_FILTER_BY_ID = gql`
  query getMarketingOnlyFilters($input: Int) {
    getMarketingOnlyFilter(id:$input) {
      id
      filter_name
      created_from
      created_to
      phone
      note
      State {
        id
        name
      }
      Customer_Origination_Area {
        id
        name
      }
    }
  }
`;