import { compose, graphql } from 'react-apollo';
import MarketingOnlyFilter from './marketing-only-filters';
import { CREATE_MARKETING_ONLY_FILTER } from '../../../graphql/mutations';
import {
  GET_ALL_STATES,
  GET_CUSTOMER_ORIGINATION_AREA,
  GET_MARKETING_ONLY_FILTERS,
  GET_MARKETING_ONLY_FILTER_BY_ID
} from "../../../graphql/queries";

const CreateMarketingOnlyFilterWithMutation = compose(
  graphql(GET_CUSTOMER_ORIGINATION_AREA, { name: "getOriginationAreas" }),
  graphql(GET_ALL_STATES, { name: "getAllStates" }),
  graphql(GET_MARKETING_ONLY_FILTERS, { name: "getMarketingOnlyFilters" }),
  graphql(GET_MARKETING_ONLY_FILTER_BY_ID, {
    name: "getMarketingOnlyFilterDetail",
    options: (props) => ({ variables: { input: null } })
  }),
  graphql(CREATE_MARKETING_ONLY_FILTER, { name: "addMarketingOnlyFilter" })
)(MarketingOnlyFilter);

export default CreateMarketingOnlyFilterWithMutation;