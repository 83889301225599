import gql from 'graphql-tag';

export default gql`
query getAgenciesSalesByCommunities($limit: Int, $pageNum: Int, $filter: reportFilter){
    getAgenciesSalesByCommunities(limit: $limit, pageNum: $pageNum, filter: $filter){
      sales {
        community{
          id
          name
          is_active
        }
        division{
          id
          name
        }
        agency_sales_count
      }
      total_count
    }
  }
`

export const GET_AGENCY_SALES_DETAILS = gql`
query getAgencySalesDetails($filter: agencyFilter, $mode: AgenciesMode){
    getAgencies(filter: $filter, mode: $mode){
      agencies {
        name
        sales_count
      }
      total_count
    }
  }
`