import gql from "graphql-tag";

const GET_PROSPECTS_COUNT = gql`
    query getProspectsCount($input: ProspectFilter){
        getProspects(limit: 1, filter: $input) {
            total_count
        }
    }
`;

export default GET_PROSPECTS_COUNT;
