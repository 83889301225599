import React from "react";
import { ListGroup } from "react-bootstrap";
import { Form, Field, withFormik } from "formik";
import SelectionListPopup from "../../../components/list-popup/selection-list-popup";
import TextField from "../../../components/fields/text-field";
import TextAreaField from "../../../components/fields/text-area";
import CustomButton from "../../../components/custom-button";
import { isNotNULL } from "../../../utils/helpers";
import { updateInformationForm, updateInformationFormSchema } from "./update-information-form-schema";
import { useQuery } from "react-apollo-hooks";

/**
 * This component renders the update descriptions form which contains dropdown menu and a text field.
 *
 * @param {Object} props
 * @param {Array} list list to be displayed in the dropdown
 */
const UpdateInformationForm = ({ list, values, ...props }) => {
    const { refetch } = useQuery(props.dataQuery, { skip: true })

    const handleClick = async (id) => {
        const { data } = await refetch({ id })

        props.setFieldValue('id', id)
        props.setFieldValue('desc', data[`get${props.title}ById`].description)
        props.setFieldValue('title', props.title)
        props.title === "Division" && props.setFieldValue('email', data[`get${props.title}ById`].email)
    }

    return (
        <Form className={"form-horizontal"}>
            <h3 className='category-header mb-0'>{props.title}</h3>
            <SelectionListPopup
                label={`Select a ${props.title}`}
                list={isNotNULL(list) ? list : null}
                mapper={(field) => {
                    return (
                        isNotNULL(field) &&
                        field.map((item, key) => (
                            <ListGroup.Item
                                className="list-item"
                                onClick={() => handleClick(item.id)}
                                key={key}
                            >
                                {item.name}
                            </ListGroup.Item>
                        ))
                    );
                }}
            />
            {props.title === "Division" &&
                <div className="col-lg-4 pl-0">
                    <Field
                        label=""
                        placeholder="Email"
                        name="email"
                        id="email"
                        type="text"
                        disable={!values.id}
                        required={true}
                        component={TextField}
                    />
                </div>}
            <Field
                label=""
                placeholder="Write a description..."
                name="desc"
                id="desc"
                disable={!values.id}
                rows={6}
                component={TextAreaField}
            />
            <div className="flex-end">
                <CustomButton
                    type="submit"
                    btnValue={"SAVE"}
                    disabled={!values.id}
                />
            </div>
        </Form>
    );
};

export const UpdateInformationWithFormik = withFormik({
    enableReinitialize: true,
    mapPropsToValues: () => ({ ...updateInformationForm }),
    handleSubmit: (values, { props, setSubmitting }) => {
        const input = {
            id: values.id,
            description: values.desc,
        }
        if (props.title === "Division") input.email = values.email
        props.updateMutation({
            variables: { input }
        }).then((res) => {
            setSubmitting(false)
            const resObject = res.data[`update${props.title}Information`]
            if (resObject.code === 200) {
                props.setNotificationProps({
                    variables: {
                        open: true,
                        message: resObject.message
                    }
                })
            } else if (resObject.code === 400) {
                props.setNotificationProps({
                    variables: {
                        open: true,
                        message: resObject.message
                    }
                })
            }
        })
    },
    validationSchema: updateInformationFormSchema,
    displayName: "update-description-form",
})(UpdateInformationForm);