
import gql from 'graphql-tag';

const DELETE_PROSPECT_NOTE = gql` 
  mutation deleteProspectNote($input: ProspectNoteDeleteInput!) {
    deleteProspectNote(input: $input) {
      message
      code
    }
  }
`;

export default DELETE_PROSPECT_NOTE;