
import gql from 'graphql-tag';

const UPDATE_PROSPECT = gql` 
  mutation updateProspect($input: ProspectUpdateInput!) {
    updateProspect(input: $input) {
      message
      code
    }
  }
`;

export default UPDATE_PROSPECT;