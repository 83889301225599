import gql from 'graphql-tag';

const GET_CLIENTS = gql`
    query getPhoneClients {
        getPhoneClients {
            device_id
            device_name
        }
    }
`;
export default GET_CLIENTS;