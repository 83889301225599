import React from "react";

import NoDataPage from "../../no-data-page/no-data-page";
import logo from "../../../images/graphic-no-prospect.png";
import { getIssueEmail, goBack } from "../../../utils/helpers";
import LoadingWrapper from "../../../components/loading-wrapper";
import { SelectBar } from "../../../containers";
import CustomButton from "../../../components/custom-button";
import { FaChevronLeft } from "react-icons/fa";
import Header from "../../../components/dashboard-title";
import AgencyDetail from "./agency-details-card";
import AssociatedRealtorsTable from "./associated-realtors-table";

/**
 * This component is used to render the details page of Agency. It renders {@link Header} component on top to display 
 * agency name. Just below it, page is divided into two parts to display a table on the left part using 
 * {@link AssociatedRealtorsTable}, and other agency details on the right part using {@link AgencyDetail}. The table 
 * shows associated realtors information. This page also has a button in the details section to edit the agency. Also
 * if the agency of specific id is not found, No data page is rendered. When the data is being loaded from the 
 * backend, in the meanwhile a loader is displayed on the whole screen. 
 * 
 * @param {Object} props it contains all the mutations/queries in the details-conatiner as props
 */
const AgencyDetails = ({ getAgenciesById, history, ...props }) => {

  const agencyDetailsView = (id, data, loading, refetch) => {

    const {Realtors, __typename, ...details} = data

    return (
      <LoadingWrapper
        loading={loading}
        component={
          <div className="col-padding col-md-12 col-12">
            <div
              className="root-row z4"
              id="prospect-detail-header"
            >
              <SelectBar>
                <div className="d-flex">
                  <CustomButton
                    onClick={() => goBack(history)}
                    id="back-to-dashboard-btn"
                    color="dark"
                    btnIcon={<FaChevronLeft />}
                    className="btn-back"
                  />
                  <Header
                    title="Agency"
                    subtitle={loading ? "" : data.name}
                  />
                </div>
              </SelectBar>
            </div>

            <div className="row-container main-responsive col-xl-12 row p-2 px-3">
              <div className="detail-view-main col-padding col-md-8 col-8 mt-2">
                <AssociatedRealtorsTable
                  data={Realtors}
                  loading={loading}
                />
              </div>

              <div className="col-padding col-md-4 col-12 pb-2 pt-2 pr-0 pl-3 detail-view-side">
                <AgencyDetail
                  data={loading ? null : details}
                  refetch={refetch}
                />
              </div>
            </div>
          </div>
        }
      />
    );
  }

  const { loading, refetch } = getAgenciesById;
  return !loading && getAgenciesById.getAgencies.agencies.length === 0 ? (
    <NoDataPage
      {...props}
      backgroundImage={logo}
      heading={"No Realtor Found."}
      content={
        <div>
          <p className="message-reportBug">
            The resource for this agency was not found in our records.
            <br />
            If it's a bug, please report.
            <br />
            <a
              href={getIssueEmail()}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-primary"
            >
              Report
            </a>
          </p>
        </div>
      }
    />
  ) : (
    agencyDetailsView(
      props.match.params.id,
      loading ? {} : getAgenciesById.getAgencies.agencies[0],
      loading,
      refetch
    )
  );
};

export default AgencyDetails;
