import gql from "graphql-tag";

const GET_APPOINTMENTS_BY_OSC = gql`
  query getAllAppointments_By_OSC(
    $limit: Int
    $pageNum: Int
    $filter: AllAppointmentsByOSCFilter
    $search: String
    $sort: [String]
  ) {
    getAllAppointmentsByOSC(
      limit: $limit
      pageNum: $pageNum
      filter: $filter
      search: $search
      sort: $sort
    ) {
      Appointment {
        title
        details
        done
        Prospect {
          first_name_1
          last_name_1
          community {
            name
            is_active
          }
        }
        csm {
          id
          first_name
          last_name
          is_active
        }
        osc {
          id
          first_name
          last_name
          is_active
        }
        start_datetime
      }
      total_count
    }
  }
`;
export default GET_APPOINTMENTS_BY_OSC;
