import { compose, graphql } from "react-apollo";

import { GET_ACTIVITY_REPORT } from "../../graphql/queries";
import { GET_TABLE_PARAMS, GET_DATE_RANGE, GET_SELECTED_COMMUNITY, GET_SELECTED_DIVISION } from "../../cacheql/queries";
import { SET_TABLE_PARAMS } from "../../cacheql/mutations";
import { getCardParams, getCommunityIds } from "../../utils/helpers";
import ActivityReportTable from "./activity-report-table";

export default compose(
  graphql(GET_SELECTED_COMMUNITY, { name: "selectedCommunity" }),
  graphql(GET_SELECTED_DIVISION, { name: "selectedDivision" }),
  graphql(SET_TABLE_PARAMS, { name: "setTableParams" }),
  graphql(GET_TABLE_PARAMS, { name: "getTableParams" }),
  graphql(GET_DATE_RANGE, { name: "selectedDateRange" }),
  graphql(GET_ACTIVITY_REPORT, {
    options: ({ selectedCommunity, selectedDivision, getTableParams: { tableParams }, ...props }) => {
      const params = getCardParams("Activity Report", tableParams);

      return {
        variables: {
          filter: {
            csm_ids: props.selectedUserIds?.ids,
            activity_start_datetime: props.selectedDateRange.selectedDateRange.startDate,
            activity_end_datetime: props.selectedDateRange.selectedDateRange.endDate,
            community_ids: getCommunityIds(selectedCommunity),
            division_id: selectedDivision?.selectedDivision?.id,
            ...(params?.filter)
          },
          pageNum: (params?.pageNum) || 0,
          limit: (params && params.limit) || 10,
        },
        fetchPolicy: "no-cache"
      };
    }
  })
)(ActivityReportTable);
