import gql from 'graphql-tag';

const DELETE_HOMEOWNER_PHONE_CALL = gql` 
  mutation deleteHomeownerPhoneCall($input: ID!) {
    deleteHomeownerPhoneCall(phone_call_id: $input) {
      message
      code
    }
  }
`;

export default DELETE_HOMEOWNER_PHONE_CALL;

