import React from "react";
import { FaSms, FaPhone, FaEnvelope } from "react-icons/fa";
import {
  get5WordsForTitle,
  formatActivityDate,
  InactivityIcon,
  getIconByEvent,
  addHttpToUrl,
  splitIntoFields,
} from "../../utils/helpers";
import { ButtonToolbar } from "react-bootstrap";
import CustomButton from "../../components/custom-button";

/**
 * @module PastActivityWebformListViewModule
 */
/**
 * this component is used to display the formsubmissions in the past activities
 * @param {object} pastActivitiesData contains information related to the formsubmissions
 */
const PastActivityWebformListView = (props) => {
  const { pastActivitiesData } = props;

  /**
   * this function renders the toggle part of the formsubmissions in past activities
   * @function
   * @inner
   * @memberof module:PastActivityWebformListViewModule
   * @param {object} item contains information related to the formsubmission
   * @returns {JSX.Element}
   */
  const toggle = (item) => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <div
            className="d-flex align-items-center"
            style={{ marginRight: "2px" }}
          >
            {getIconByEvent(pastActivitiesData.typeTitle)}
            <span style={{ fontSize: "12px", display: "d-flex" }}>
              {pastActivitiesData.typeTitle}:
            </span>
          </div>
          {get5WordsForTitle(pastActivitiesData.form_type)}
        </div>
        <span>{formatActivityDate(item.created_at)}</span>
      </div>
    );
  };

  /**
   * this function renders the collapse part of the formsubmissions in past activities
   * @function
   * @inner
   * @memberof module:PastActivityWebformListViewModule
   * @param {object} item contains information related to the formsubmission
   * @returns {JSX.Element}
   */
  const collapse = (item) => {
    return (
      <>
        <div className="pt-3 d-flex justify-content-between flex-row-reverse">
          <div className="webform-response-medium d-flex mb-2">
            <ButtonToolbar
              className="d-flex"
              style={{ justifyContent: "flex-end" }}
            >
              <CustomButton
                color="dark"
                disabled={true}
                btnIcon={
                  <FaSms
                    size={14}
                    style={{
                      color: item.response_sms && "#80b602",
                    }}
                  />
                }
                className="icon-btn btn-icon"
                style={{ background: "none", pointerEvents: "none" }}
              />
              <CustomButton
                color="dark"
                disabled={true}
                btnIcon={
                  <FaPhone
                    size={13}
                    style={{
                      color: item.response_phone && "#80b602",
                    }}
                  />
                }
                className="icon-btn btn-icon b-none"
                style={{ background: "none", pointerEvents: "none" }}
              />
              <CustomButton
                color="dark"
                disabled={true}
                btnIcon={
                  <FaEnvelope
                    size={14}
                    style={{
                      color: item.response_email && "#80b602",
                    }}
                  />
                }
                className="icon-btn btn-icon"
                style={{ background: "none", pointerEvents: "none" }}
              />
            </ButtonToolbar>
          </div>
          <div className="d-flex align-items-center text-muted mb-1">
            <span className="small">RESPONDED BY:</span>
            {item.responded_at && (
              <span className="small ml-2">
                {" "}
                {item.responder
                  ? item.responder.first_name + " " + item.responder.last_name
                  : "- "}{" "}
                at {formatActivityDate(item.responded_at)}
              </span>
            )}
          </div>
        </div>
        <div className="d-flex align-items-center text-muted mb-1">
          <span className="small">FORM TYPE:</span>
          <span className="small ml-2"> {item.form_type}</span>
        </div>
        <div className="d-flex align-items-center text-muted mb-1">
          <span className="small">DIVISION:</span>
          <span className="small ml-2"> {item.division?.name || "-"}</span>
        </div>
        <div className="d-flex align-items-center text-muted mb-1">
          <span className="small">COMMUNITY:</span>
          <span className="small ml-2">
            {InactivityIcon(item.community, item.community?.name, null, 12)}
          </span>
        </div>
        <div className="d-flex align-items-center text-muted mb-1">
          <span className="small">URL:</span>
          {item.url && (
            <a
              className="small ml-2"
              style={{ color: "#80b602" }}
              target="_blank"
              rel="noopener noreferrer"
              href={addHttpToUrl(item.url)}
            >
              {" "}
              {item.url}
            </a>
          )}
        </div>
        <ul className="noted-descr-body pt-2 pb-3">
          <span className="small">DETAILS:</span>
          <div className="pl-2 pt-2">{splitIntoFields(item.interested_in)}</div>
        </ul>
        <ul className="noted-descr-body pt-2 pb-3">
          <span className="small">OTHER DATA:</span>
          <div className="pl-2 pt-2">{splitIntoFields(item.other_data)}</div>
        </ul>
      </>
    );
  };
  return (
    <div className="notes-card">
      {props.render(toggle(pastActivitiesData), collapse(pastActivitiesData))}
    </div>
  );
};
export default PastActivityWebformListView;
