import gql from 'graphql-tag';

const MERGE_FORM_SUBMISSION = gql`
  mutation mergeFormSubmission($input: FormSubmissionUpdateInput) {
    mergeFormSubmission(input: $input) {
      message
      code
    }
  }
`;

export default MERGE_FORM_SUBMISSION;