import React from "react";
import { getIn } from 'formik';
import PropTypes from 'prop-types';
import "./fields.css";
import { formatActivityDate } from "../../utils/helpers";

/**
 * This is multiline text input field. An enter would generally take you to the next line
 * @param {Object} props
 * @param {String} props.placeholder placeholder value
 * @param {Number} props.rows how wise text area would be
 * @param {Boolean} props.required mark the current field mandatory  
 */
const TextAreaField = props => {
  const {
    id,
    type,
    label,
    placeholder,
    rows,
    field,
    field: { onChange, value, ...otherProps },
    required,
    form: { touched, errors, setFieldValue },
    disable,
    updatedAt,
    ...rest
  } = props;

  const error = getIn(errors, field.name);
  const touch = getIn(touched, field.name);
  const errorMessage = (touch && error) && error;

  const handleOnChange = (e) => {
    onChange(e.target.value)
    setFieldValue(field.name, e.target.value)
  }

  const style = required
    ? { borderLeftColor: "red", borderLeftWidth: 2, background: "" }
    : { background: "" }

  return (
    <div id={id + "-form-group"} className="form-group">
      <div style={{ display: "flex", justifyContent: 'space-between' }}>
        <label htmlFor={id + "-input"} className="control-label">
          {label}
        </label>
        {updatedAt && (
          <label> Last updated: {formatActivityDate(updatedAt)} </label>
        )}
      </div>
      <textarea
        id={id + "-input"}
        type={type}
        required={required}
        placeholder={placeholder}
        className={'form-control' + (errorMessage ? ' is-invalid' : '')}
        rows={rows}
        onChange={handleOnChange}
        value={value || ""}
        style={disable ? { ...style, color: "#888", opacity: "0.5" } : style}
        disabled={disable}
        {...otherProps}
        {...rest}
      />
      {error && <div className="invalid-feedback">{errorMessage}</div>}
    </div>
  );
};

TextAreaField.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  rows: PropTypes.number.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
}
export default TextAreaField;
