import { compose, graphql } from "react-apollo";
import { RegisterUpdate, MergeRealtor } from "./realtor-form";
import { GET_DIVISIONS } from "../../graphql/queries";
import {
  ADD_REALTOR,
  UPDATE_REALTOR,
  MERGE_REALTOR
} from "../../graphql/mutations";
import { GET_PREVIOUS_CARD } from "../../cacheql/queries";
import { SET_NOTIFICATION_PROPS } from "../../cacheql/mutations";

export const CreateEditRealtor = compose(
  graphql(GET_DIVISIONS, { name: "getAllDivisions" }),
  graphql(ADD_REALTOR, { name: "addRealtor" }),
  graphql(UPDATE_REALTOR, { name: "updateRealtor" }),
  graphql(MERGE_REALTOR, { name: "mergeRealtors" }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" }),
  graphql(GET_PREVIOUS_CARD, { name: "getPreviousCard" })
)(RegisterUpdate);

export const MergeRealtorForm = compose(
  graphql(GET_DIVISIONS, { name: "getAllDivisions" }),
  graphql(MERGE_REALTOR, { name: "mergeRealtors" }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" }),
  graphql(GET_PREVIOUS_CARD, { name: "getPreviousCard" })
)(MergeRealtor);