import gql from 'graphql-tag';

const UPDATE_FORM_SUBMISSION = gql`
  mutation updateFormSubmission($input: FormSubmissionUpdateInput) {
    updateFormSubmission(input: $input) {
      message
      code
    }
  }
`;

export default UPDATE_FORM_SUBMISSION;