import gql from "graphql-tag";

const GET_PROSPECT_FILTER_BYID = gql`
  query getProspectFilterDetail($input: Int) {
    getProspectFilters(id: $input) {
      id
      filter_name
      reservation
      video_text
      red_flag
      all_realtor
      created_from
      created_to
      last_activity_from
      last_activity_to
      motivation
      State {
        id
        name
        abbreviation
      }
      Realtor {
        id
        first_name
        last_name
        primary_email
      }
      Rankings {
        id
        name
      }
      AssignedCsm {
        id
        first_name
        last_name
        email_address
        cell_phone
        role
        createdAt
        updatedAt
      }
      Home_Site {
        id
        name
      }
      Floor_Plan {
        id
        name
      }
      CustomFilter {
        id
        name
      }
    }
  }
`;

export default GET_PROSPECT_FILTER_BYID;
