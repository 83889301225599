import React, { useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { formatDate } from "../../utils/helpers";
import { LEAD, PROSPECT, HOMEOWNER } from "../../utils/constants"
import PastActivityAppointmentListView from "./past-activities-appointment";
import PastActivityFollowupListView from "./past-activities-followup";
import PastActivityPhoneCallsListView from "./past-activities-phonecall";
import PastActivityEmailsListView from "./past-activities-emails";
import PastActivityEbombsListView from "./past-activities-ebombs";
import PastActivityMassEmailsListView from "./past-activities-mass-emails";
import PastActivityPhoneSmsListView from "./past-activities-sms";
import PastActivityWalkInListView from "./past-activities-walk-in";
import AccordionView from "../../components/accordion/accordion";
import PastActivityWebformListView from "./past-activities-webforms";
import "./style.css";

/**
 * @module PastActivitiesViewModule
 */

/**
 * this comopnent is used to render the past activities
 * @param {object} pastActivitiesData contain all information regarding the activties
 * @param {string} type type of the details page
 * @param {function} onSubmit
 * @param {number} id
 */
const PastActivitiesView = ({ pastActivitiesData, id, onSubmit, type }) => {
  const [title, setTitle] = useState("All");
  const [value, setValue] = useState("All");

  const dropdownItems = {
    All: "All",
    CustomerFormSubmission: "Web Forms",
    PhoneCall: "Phone Calls",
    Text: "Texts",
    Email: "Emails",
    ...(type !== "webform"
      ? { MailchimpEmailType: "Ebombs", "Mass Email": "Mass Emails" }
      : {}),
    ...(type === PROSPECT
      ? { Appointment: "Appointments", Prospect_Follow_Up: "Follow-ups", Prospect_Walk_In: "Walk-Ins" }
      : {}),
    ...(type === HOMEOWNER
      ? { Appointment: "Appointments", Homeowner_Follow_Up: "Follow-ups" }
      : {}),
    ...(type === LEAD ? { Lead_Follow_Up: "Follow-ups" } : {}),
  };

  /**
   * this function counts all types of activities seperatly
   * @function
   * @inner
   * @memberof module:PastActivitiesViewModule
   * @param {string} date the selected date
   * @returns {string}
   */
  let countActivities = (date) => {
    const counts =
      pastActivitiesData &&
      pastActivitiesData.reduce((acc, cur) => {
        if (
          (cur.__typename === "Appointment" ||
            cur.__typename === "Lead_Follow_Up" ||
            cur.__typename === "Homeowner_Follow_Up" ||
            cur.__typename === "Prospect_Follow_Up") &&
          !cur.done
        )
          return acc;

        acc[cur.__typename] =
          formatDate(new Date(cur.start)) === date
            ? (acc[cur.__typename] || 0) + 1
            : acc[cur.__typename] || 0;
        return acc;
      }, {});

    switch (title) {
      case "All":
        return (
          counts &&
          (counts.PhoneCall ? counts.PhoneCall + " Phone Call(s), " : "") +
          (counts.Text ? counts.Text + " Text(s), " : "") +
          (counts.Email ? counts.Email + " Email(s), " : "") +
          (counts["Mass Email"]
            ? counts["Mass Email"] + " Mass Email(s), "
            : "") +
          (counts.MailchimpEmailType
            ? counts.MailchimpEmailType + " Ebomb(s), "
            : "") +
          (counts.Appointment
            ? counts.Appointment + " Appointment(s), "
            : "") +
          (counts.Lead_Follow_Up
            ? counts.Lead_Follow_Up + " Follow-up(s), "
            : "") +
          (counts.Prospect_Follow_Up
            ? counts.Prospect_Follow_Up + " Follow-up(s), "
            : "") +
          (counts.Prospect_Walk_In
            ? counts.Prospect_Walk_In + " Walk-In(s), "
            : "") +
          (counts.Homeowner_Follow_Up
            ? counts.Homeowner_Follow_Up + " Follow-up(s), "
            : "") +
          (counts.CustomerFormSubmission
            ? counts.CustomerFormSubmission + " Web Form(s) "
            : "")
        );
      case "Emails":
        return counts.Email ? counts.Email + " Email(s) " : "";
      case "Mass Emails":
        return counts["Mass Email"]
          ? counts["Mass Email"] + " Mass Email(s) "
          : "";
      case "Ebombs":
        return counts.MailchimpEmailType
          ? counts.MailchimpEmailType + " Ebomb(s) "
          : "";
      case "Phone Calls":
        return counts.PhoneCall ? counts.PhoneCall + " Phone Call(s) " : "";
      case "Texts":
        return counts.Text ? counts.Text + " Texts " : "";
      case "Appointments":
        return counts.Appointment
          ? counts.Appointment + " Appointment(s) "
          : "";
      case "Follow-ups":
        return counts.Lead_Follow_Up
          ? counts.Lead_Follow_Up + " Follow-up(s) "
          : counts.Prospect_Follow_Up
            ? counts.Prospect_Follow_Up + " Follow-up(s), "
            : counts.Homeowner_Follow_Up
              ? counts.Homeowner_Follow_Up + " Follow-up(s), "
              : "";
      case "Walk-Ins":
        return counts.Prospect_Walk_In
          ? counts.Prospect_Walk_In + " Walk-In(s), "
          : "";
      case "Web Forms":
        return counts.CustomerFormSubmission
          ? counts.CustomerFormSubmission + " Web Form(s) "
          : "";
      default:
        return;
    }
  };

  /**
   * this function renders the activities
   * @function
   * @inner
   * @memberof module:PastActivitiesViewModule
   * @param {object} item contains all information related to the activities
   * @param {string} __typename type of the activity
   * @returns {JSX.Element}
   */
  const renderPastActivities = (item) => {
    return (
      <React.Fragment>
        {item.__typename === "PhoneCall" && (
          <AccordionView
            component={PastActivityPhoneCallsListView}
            pastActivitiesData={item}
            onSubmit={onSubmit}
            type={type}
            toggleAction={true}
            style={{
              borderLeft: "3px solid #50E3C2",
              backgroundColor: "rgba(80, 227, 197, 0.3)",
              color: "white",
            }}
          />
        )}
        {item.__typename === "Text" && (
          <AccordionView
            component={PastActivityPhoneSmsListView}
            pastActivitiesData={item}
            onSubmit={onSubmit}
            type={type}
            toggleAction={true}
            style={{
              borderLeft: "3px solid #C48E48",
              backgroundColor: "rgb(87, 71, 49,0.3)",
              color: "white",
            }}
          />
        )}
        {item.__typename === "Email" && (
          <AccordionView
            component={PastActivityEmailsListView}
            pastActivitiesData={item}
            style={{
              borderLeft: "3px solid #FF7A00",
              backgroundColor: "rgba(255, 122, 0, 0.3)",
              color: "white",
            }}
          />
        )}
        {item.__typename === "MailchimpEmailType" && (
          <AccordionView
            component={PastActivityEbombsListView}
            pastActivitiesData={item}
            style={{
              borderLeft: "3px solid #D0021B",
              backgroundColor: "rgba(208, 2, 27, 0.3)",
              color: "white",
            }}
          />
        )}
        {item.__typename === "Mass Email" && (
          <AccordionView
            component={PastActivityMassEmailsListView}
            pastActivitiesData={item}
            style={{
              borderLeft: "3px solid #9400ff",
              backgroundColor: "rgba(148, 0, 255, 0.3)",
              color: "white",
            }}
          />
        )}
        {item.__typename === "Appointment" && item.done && (
          <AccordionView
            component={PastActivityAppointmentListView}
            pastActivitiesData={item}
            style={{
              borderLeft: "3px solid #92af00",
              backgroundColor: "rgba(183, 214, 135, 0.3)",
              color: "white",
            }}
          />
        )}
        {(item.__typename === "Lead_Follow_Up" ||
          item.__typename === "Homeowner_Follow_Up" ||
          item.__typename === "Prospect_Follow_Up") &&
          item.done && (
            <AccordionView
              component={PastActivityFollowupListView}
              pastActivitiesData={item}
              style={{
                borderLeft: "3px solid #4c7ef9",
                backgroundColor: "rgba(76, 126, 249, 0.3)",
                color: "white",
              }}
            />
          )}
        {item.__typename === "Prospect_Walk_In" && (
          <AccordionView
            component={PastActivityWalkInListView}
            pastActivitiesData={item}
            style={{
              borderLeft: "3px solid #A84264",
              backgroundColor: "rgba(168, 66, 100, 0.3)",
              color: "white",
            }}
          />
        )}
        {item.__typename === "CustomerFormSubmission" && (
          <AccordionView
            component={PastActivityWebformListView}
            pastActivitiesData={item}
            style={{
              borderLeft: "3px solid #FF5E5E",
              backgroundColor: "rgba(255, 94, 94, 0.3)",
              color: "white",
            }}
          />
        )}
      </React.Fragment>
    );
  };

  let prevDate = null;
  return (
    <div>
      <div className="d-flex justify-content-between past-activities-header">
        <h3 className="ml-0">Past Activities</h3>
        <div className="d-flex justify-content-end">
          <div className="past-activities-filters d-flex">
            <DropdownButton
              alignRight
              drop="down"
              variant="secondary"
              id="passactivities-item-btn"
              key="2"
              title={title}
              className="past-activity-dropdown"
            >
              {Object.keys(dropdownItems).map((key, index) => {
                return (
                  <Dropdown.Item
                    onSelect={() => {
                      setTitle(dropdownItems[key]);
                      setValue(key);
                    }}
                    key={index}
                  >
                    {dropdownItems[key]}
                  </Dropdown.Item>
                );
              })}
            </DropdownButton>
          </div>
        </div>
      </div>
      <div className="past-activities-wrapper">
        {pastActivitiesData &&
          pastActivitiesData.map((item, index) => {
            if (value === "All" || item.__typename === value) {
              let headerComponent = null,
                itemDate = formatDate(item.start);
              if (itemDate !== prevDate) {
                prevDate = itemDate;
                const count = countActivities(itemDate).replace(/,\s$/, "");
                headerComponent = count && (
                  <div key={index} className="mb-2">
                    <div className="d-flex justify-content-between small text-muted mb-2 mr-1">
                      <span>{itemDate}</span>
                      <span>{count}</span>
                    </div>
                  </div>
                );
              }
              return (
                <div key={index}>
                  {headerComponent}
                  {renderPastActivities(item)}
                </div>
              );
            }
            return null;
          })}
      </div>
    </div>
  );
};

export default PastActivitiesView;
