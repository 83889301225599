import gql from 'graphql-tag';

const ADD_NOTE_ATTACHMENT = gql` 
  mutation addNoteAttachments($input: AddNoteAttachmentInput!) {
    addNoteAttachments(input: $input) {
      message
      code
      data
    }
  }
`;

export default ADD_NOTE_ATTACHMENT;