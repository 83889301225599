import React, { useEffect, useMemo, useState } from "react";
import { compose, graphql } from "react-apollo";

import TableView, {
  ActionCell,
  buyerTwoIconCellObject,
  DetailLinkCell,
  EmailCell,
  filterForList,
  includes,
  ListCell,
  PhoneCell,
  stringComparator,
  TextHeader,
} from "../../components/new-table";
import FadeIn from "../../components/fade-in";
import { phoneDisplay } from "../../utils/helpers";
import routes_constants from "../layout/routes";
import { GET_LEADS_FOR_ALL_LEADS } from "../../graphql/queries";
import { FaFilter } from "react-icons/fa";

/** @module GlobalLeadsTableModule */
/**
 * This component is a table used to display leads data based on the global search query. It contains only the top 5 results and further specific query needs to be made
 * to get the appropriate data. It also has a basic hover action on Name column which redirect to the leads details.
 *
 * @param {Object} props this contains the data from the graphql query and the function to close the drawer
 * @param {String} props.searchTerm it contains the search query from the GlobalSearchDetails component
 * @param {Function} props.handleDrawerClose function to close the drawer
 * @param {Object} props.data it contains array of prospects to be shown on table
 * @returns {JSX.Element} It returns jsx containing the table title and the table itself
 */
const GlobalLeadsTable = ({ handleDrawerClose, data, setMergeData }) => {
  const [loading, setLoading] = useState(true);
  const [render, setRender] = useState(false);
  const [Leads, setLeads] = useState({});

  /**
   * Either set the data in state or set the loading to inform the table what to render.
   */
  useEffect(() => {
    if (!data.loading && data.getLeads && data.getLeads.Leads) {
      setLoading(false);
      setRender(true);
      setLeads(data.getLeads.Leads);
    }
    if (data.loading) {
      setLoading(data.loading);
    }
  }, [data]);

  /**
   * This function adds customers to the merge customer list on clicking the merge button. If the customer selected is already
   * in the merge customers list then the customer is not added a second time.
   * @param {Object} lead lead data which is to be added into the merged customer list
   */
  const updateMergeList = (lead) => {
    setMergeData(prevState => {
      const data = prevState.type === "realtor" ? [lead]
        : prevState.data?.find(customer => customer.customer_id === lead.customer_id)
          ? prevState.data
          : [...prevState.data, lead]
      return {
        data,
        type: "customer"
      }
    })
  }

  /**
   * Columns of the table containing a column to show an icon in case there is a second buyer, name, community, phone and email.
   * @constant
   * @memberof module:GlobalLeadsTableModule
   */
  const columns = useMemo(() => [
    {
      id: "0",
      header: "",
      accessor: "",
      component: TextHeader,
      cell: buyerTwoIconCellObject,
    },
    {
      id: "1",
      header: "Name",
      accessor: "first_name_1",
      component: TextHeader,
      sortable: false,
      cell: {
        component: DetailLinkCell,
        path: (field, accessor) => routes_constants.LEAD_DETAIL,
        mapper: (field, accessor) =>
          field[accessor] + " " + field["last_name_1"],
        action: handleDrawerClose,
      },
    },
    {
      id: "2",
      header: "Community",
      accessor: "communities",
      component: TextHeader,
      sortable: false,
      csvMapper: (field, accessor) => field[accessor].map(({ name }) => name),
      filter: filterForList,
      cell: {
        component: ListCell,
        mapper: (field) =>
          field.map((item) => <div key={item.id}>{item.name}</div>),
      },
    },
    {
      id: "3",
      header: "Phone",
      accessor: "cell_phone_1",
      component: TextHeader,
      sortable: false,
      filter: includes,
      csvMapper: (field, accessor) => phoneDisplay(field[accessor]) || "",
      cell: { component: PhoneCell },
    },
    {
      id: "4",
      header: "Email",
      accessor: "primary_email_1",
      component: TextHeader,
      sortable: false,
      comparator: stringComparator,
      cell: {
        component: EmailCell,
        check: "primary_email_1_status",
      },
    },
  ]);

  /**
   * Array of objects containing different actions which can be performed on row hover.
   * @constant
   * @memberof module:GlobalLeadsTable
   */
  const hoverActions = React.useMemo(
    () => [
      {
        textValue: 'Merge',
        component: ActionCell,
        action: (event) => {
          updateMergeList(event);
        },
      }
    ],
    []
  );


  return (
    <div>
      <div className="d-flex header-table">
        <FaFilter className="header-icon" />
        <div className="d-flex align-self-center header-text">
          Leads {!Leads.length && "(No records Found)"}
        </div>
      </div>
      {Leads.length ? (
        <div className="results-table-search">
          <FadeIn show={render}>
            <TableView
              columns={columns}
              data={Leads}
              loading={loading}
              hoverActions={hoverActions}
              currentPage={0}
              isGlobalSearch={true}
              controlled={true}
            />
          </FadeIn>
        </div>
      ) : null}
    </div>
  );
};

export default compose(
  graphql(GET_LEADS_FOR_ALL_LEADS, {
    options: ({ searchTerm }) => {
      return {
        variables: {
          pageNum: 0,
          limit: 5,
          sort: null,
          search: searchTerm || "",
        },
        fetchPolicy: "cache-and-network",
      };
    },
  })
)(GlobalLeadsTable);
