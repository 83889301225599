import React from "react";
import Search from "./search";

import * as actionTypes from "./action-types";
import * as filterTypes from "./filter-types";
import {
  isFunction,
  sanitizeFilterObject,
  copyByValue /* getDescendantProp, pickProp */
} from "./utils";


/**
 * this is a custom hook that handles filters and search states of the tables.
 * @param {object} state 
 * @param {function} dispatch 
 * @returns {object}
 */
export default function useFilter(state, dispatch) {
  const {
    searchTerm,
    manualFilters,
    data,
    columns,
    filter,
    rowsLength
  } = state;

  const setSearchTerm = searchTerm => {
    dispatch({ type: actionTypes.SET_SEARCH_TERM, payload: { searchTerm } });
  };

  const setFilter = filter => {
    dispatch({ type: actionTypes.SET_FILTER, payload: { filter } });
  };

  const searchBasedOnTerm = (searchTerm, columns, rows) => {
    let options = {
      keys: columns.filter(col => !col.hidden).map(col => col.accessor),
      valueMappers: columns
        .filter(col => !col.hidden)
        .map(col => col.csvMapper),
      location: 0,
      distance: 100,
      threshold: 0.05,
      multiSearch: false
    };

    const search = Object.create(Search);
    search.init(rows, options);

    return search.search(searchTerm.toLowerCase());
  };

  const searchBasedOnFiltter = (filter, data) => {
    //alert("sdsdsdsd")
    const filters = sanitizeFilterObject(Object.assign({}, filter));
    let filteredRows = data;

    filteredRows = Object.entries(filters).reduce(
      (filteredSoFar, [key, value]) => {
        const column = columns.find(col =>
          col.accessor.split(".").includes(key)
        );
        const filterMethod = getFilterMethod(column.filter, filterTypes);
        // Pass the rows, id, filterValue and column to the filterMethod
        // to get the filtered rows back
        return filterMethod(filteredSoFar, column.accessor, value);
      },
      data
    );

    return filteredRows;
  };

  const applySearchAndFilter = (searchTerm, columns, data, filter) => {
    //if we are doing filtering on server
    if (manualFilters) {
      return;
    }

    let records = copyByValue(data);
    if (filter) {
      records = searchBasedOnFiltter(filter, records);
    }
    records =
      searchTerm.length > 0
        ? searchBasedOnTerm(searchTerm, columns, records)
        : records;
    dispatch({
      type: actionTypes.FILTERED_ROWS,
      payload: { rows: records, rowsLength: records.length }
    });
  };
  React.useMemo(() => applySearchAndFilter(searchTerm, columns, data, filter), [
    searchTerm,
    filter,
    columns,
    data,
    rowsLength
  ]);

  return {
    setSearchTerm,
    setFilter
  };
}

function getFilterMethod(filter, filterTypes) {
  return isFunction(filter) || filterTypes.exactText;
}
