import { compose, graphql } from "react-apollo";
import { GET_HOME_OWNERS } from "../../../graphql/queries";
import {
  GET_SELECTED_COMMUNITY,
  GET_SELECTED_DIVISION,
  GET_TABLE_PARAMS
} from "../../../cacheql/queries";
import { SET_NOTIFICATION_PROPS, SET_TABLE_PARAMS } from "../../../cacheql/mutations";
import { getCardParams } from "../../../utils/helpers";
import HomeOwner from "./home-owner";

export default compose(
  graphql(GET_SELECTED_COMMUNITY, { name: "selectedCommunity" }),
  graphql(GET_SELECTED_DIVISION, { name: "selectedDivision" }),
  graphql(GET_TABLE_PARAMS, { name: "getTableParams" }),
  graphql(GET_HOME_OWNERS, {
    options: ({
      currentCard: { filter, title },
      getTableParams: { tableParams },
      selectedCommunity,
      selectedDivision,
      ...props
    }) => {
      let params = getCardParams(title, tableParams);
      if (params) {
        delete params.filter.filter_name
      }
      return {
        variables: {
          filter: {
            ...filter,
            community_ids: selectedCommunity.selectedCommunityIds.ids,
            division_id: selectedDivision.selectedDivision.id,
            ...(params && params.filter)
          },
          pageNum: (params && params.pageNum) || 0,
          limit: (params && params.limit) || 10,
          order:
            params && params.order && params.order.id !== -1
              ? [params.order.name, params.order.sort]
              : null,
          search: (params && params.search) || "",
        },
        fetchPolicy: "cache-and-network"
      }
    }
  }),
  graphql(SET_TABLE_PARAMS, { name: "setTableParams" }),
  graphql(SET_NOTIFICATION_PROPS, { name: "setNotificationProps" })
)(HomeOwner);
