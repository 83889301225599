import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-apollo-hooks";
import { get } from "lodash";

import { CreatableSelectField } from "../../components/fields/creatable-select";

/**
 * This container is used to make a query to backend to get list of options, create an array of select-items 
 * containing object like: {value: String, label: String}. It forms these objects with the help of resolver functions
 * passed as props
 * 
 * @param {Object} props
 * @param {Query} props.query query to show items in dropdown list
 * @param {String} props.listPath path to extract list out of data object
 * @param {String | Function} props.valueResolver to resolve value of each select option
 * @param  {String | Function} props.labelResolver to resolve label of each select option
 */
const GraphQLCreatableSelect = ({
  query,
  listPath = "query",
  valueResolver = "value",
  labelResolver = "label",
  ...props
}) => {
  const { loading, /*  error, */ data } = useQuery(query,{ fetchPolicy: "network-only" });

  const list = (data && get(data, listPath)) || [];
  const options = list.map(item =>
    getValueLabel(item, valueResolver, labelResolver)
  );

  return (
    <CreatableSelectField isLoading={loading} options={options} {...props} />
  );
};

const getValueLabel = (item, valueResolver, labelResolver) => {
  return {
    value:
      typeof valueResolver === "string"
        ? item[valueResolver]
        : typeof valueResolver === "function"
        ? valueResolver(item)
        : null,
    label:
      typeof labelResolver === "string"
        ? item[labelResolver]
        : typeof labelResolver === "function"
        ? labelResolver(item)
        : null
  };
};

GraphQLCreatableSelect.propTypes = {
  listPath: PropTypes.string,
  valueField: PropTypes.oneOf([PropTypes.string, PropTypes.func]),
  labelField: PropTypes.oneOf([PropTypes.string, PropTypes.func])
};

export { GraphQLCreatableSelect };
