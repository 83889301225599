import React, { useEffect, useMemo, useContext } from "react";
import { compose, graphql } from "react-apollo";

import TableView, {
  TextHeader,
  TextCellWithLinkAndMapper,
  TextCellWithMapper,
} from "../../../components/new-table";
import { SET_TABLE_PARAMS } from "../../../cacheql/mutations";
import {
  GET_TABLE_PARAMS,
  GET_SELECTED_DIVISION,
} from "../../../cacheql/queries";
import { GET_AGENCY_SALES_BY_COMMUNITY } from "../../../graphql/queries";
import { getUpdatedParams, getCardParams } from "../../../utils/helpers";
import { DashboardContext } from "../../../context";
import reducer, { getInitialState } from "./agency-sales-by-community-reducer";
import FadeIn from "../../../components/fade-in";
import CustomLoader from "../../../components/custom-loader";
import Drawer from "../../../hoc/Drawer";
import AgencySalesDetail from "./agency-sales-detail";

/**@module AgencySalesTableModule*/

/**
 * This component is a table used to display Agency sales. It contains pagination, and export to csv options. It also 
 * has no hover actions. It is used on selection of a specific card. Table uses dashboard context to access current 
 * selected card, and uses reducer to maintain its state. It also accesses store to check if cards parameters are
 * already stored or not.
 * 
 * @param {Object} props it contains all the mutations/queries in the conatiner as props
 * @param {Object} props.data it contains array of agencies to be shown on table
 * @param {Object} props.getTableParams it contains cards info stored inside cache
 * @returns {JSX.Element} It returns jsx containing the table and the side drawer to show community sales details
 */
const AgencySalesTable = ({
  data,
  getTableParams: { tableParams },
  ...props
}) => {
  const { currentCard } = useContext(DashboardContext);
  const cacheParams = getCardParams(currentCard.uniqueTitle, tableParams);
  const [state, dispatch] = React.useReducer(
    reducer,
    getInitialState({ cache: cacheParams })
  );

  const {
    showAgencyBar,
    loading,
    agencySales,
    drawerItem,
    userPageCount,
    pageSize,
    render,
  } = state;

  
  /**
   * Either set the data in state or set the loading to inform the table what to render.
   */
  useEffect(() => {
    if (
      !data.loading &&
      data.getAgenciesSalesByCommunities &&
      data.getAgenciesSalesByCommunities.sales
    ) {
      dispatch({
        type: "UPDATE",
        payload: {
          loading: false,
          render: true,
          agencySales: data.getAgenciesSalesByCommunities.sales,
          userPageCount: Math.ceil(
            data.getAgenciesSalesByCommunities.total_count / pageSize
          ),
        },
      });
    }
    if (data.loading) {
      dispatch({ type: "UPDATE", payload: { loading: data.loading } });
    }
  }, [data]);

  /**
   * It is used to set the sidebar to true, and also to set the drawer item that is to be shown on clicking community 
   * @param {Object} record data record on which user clicked
   * @function
   * @inner
   * @memberof module:AgencySalesTableModule
   * @see {@link module:AgencySalesTableModule~AgencySalesTable}
   */
  const drawerHandler = (record, row) => {
    dispatch({
      type: "UPDATE",
      payload: {
        showAgencyBar: true,
        drawerItem: (
          <AgencySalesDetail
            community={record.community}
            agency_sales_count={record.agency_sales_count}
            close={() => setShowAgencyBar(false)}
          />
        ),
      },
    });
  };

  /**
   * It is used to toggle the sidebar
   * @param {Boolean} showAgencyBar 
   * @function
   * @inner
   * @memberof module:AgencySalesTableModule
   * @see {@link module:AgencySalesTableModule~AgencySalesTable}
   */
  const setShowAgencyBar = (showAgencyBar) =>
    dispatch({ type: "UPDATE", payload: { showAgencyBar: showAgencyBar } });

  /**
   * Array of columns to be shown on table, which are memoized and are created one time.
   * @constant
   * @memberof module:AgencySalesTableModule
   */
  const columns = useMemo(
    () => [
      {
        id: "1",
        header: "Communities",
        accessor: "community",
        component: TextHeader,
        csvMapper: (field, accessor) =>
          field[accessor] ? field[accessor].name : "",
        cell: {
          component: TextCellWithLinkAndMapper,
          mapper: (field, accessor) => field[accessor] && field[accessor].name,
          action: drawerHandler,
        },
      },
      {
        id: "2",
        header: "Divisions",
        accessor: "division",
        component: TextHeader,
        csvMapper: (field, accessor) =>
          field[accessor] ? field[accessor].name : "",
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) => field[accessor] && field[accessor].name,
        },
      },
      {
        id: "3",
        header: "No. of Sales",
        accessor: "agency_sales_count",
        component: TextHeader,
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) => field[accessor],
        },
      },
    ],
    []
  );

  /**
   * It is a callback function which is called on any table change like: pagination or page-size. Table passes its 
   * current state so that we can update the table parameters in store and state, which are used in refetching the 
   * data with correct parameters.  
   *  
   * @param {Object} tableProps current state of the table
   * @function
   * @inner
   * @memberof module:AgencySalesTableModule
   * @see {@link module:AgencySalesTableModule~AgencySalesTable}
   */
  const fetchData = React.useCallback((tableProps) => {
    if (loading) {
      return;
    }

    //total pages
    const pageCount = Math.ceil(
      data.getAgenciesSalesByCommunities.total_count / tableProps.pageSize
    );

    //variables to be stored in cache/store
    const variables = {
      filter: {
        ...tableProps.filter,
      },
      limit: tableProps.pageSize,
      pageNum: tableProps.currentPage,
    };

    // update the state
    dispatch({
      type: "UPDATE",
      payload: { ...variables, pageSize: tableProps.pageSize, loading: true },
    });
    // update total page count in the state
    if (pageCount !== userPageCount) {
      dispatch({
        type: "UPDATE",
        payload: { userPageCount: pageCount },
      });
    }

    // update the store
    props
      .setTableParams({
        variables: {
          cardsParams: getUpdatedParams(tableParams.cardsParams, {
            cardTitle: currentCard.uniqueTitle,
            params: variables,
          }),
        },
      })
      .then((res) => {
        dispatch({ type: "UPDATE", payload: { loading: false } });
      });
  });

  const tableOptions = {
    title: currentCard.title,
  };

  return (
    <>
      <FadeIn show={render}>
        <div className="realtor-community-sales">
          <TableView
            removeSearchField
            columns={columns}
            data={agencySales}
            tableOptions={tableOptions}
            loading={loading}
            fetchData={fetchData}
            currentPage={state.pageNum}
            pageSize={state.pageSize}
            userPageCount={userPageCount}
            userTotalCount={
              !loading && data.getAgenciesSalesByCommunities
                ? data.getAgenciesSalesByCommunities.total_count
                : null
            }
            filter={state.filter}
            controlled={true}
          />
        </div>
      </FadeIn>
      {!render && <CustomLoader />}

      <Drawer
        show={showAgencyBar}
        toggleSideBar={() => setShowAgencyBar(false)}
      >
        {drawerItem}
      </Drawer>
    </>
  );
};

const TableComponent = compose(
  graphql(GET_SELECTED_DIVISION, { name: "selectedDivision" }),
  graphql(SET_TABLE_PARAMS, { name: "setTableParams" }),
  graphql(GET_TABLE_PARAMS, { name: "getTableParams" }),
  graphql(GET_AGENCY_SALES_BY_COMMUNITY, {
    options: ({
      selectedDivision,
      currentCard: { uniqueTitle },
      getTableParams: { tableParams },
      ...props
    }) => {
      let params = getCardParams(uniqueTitle, tableParams);
      return {
        variables: {
          filter: {
            division_id: selectedDivision.selectedDivision.id,
          },
          pageNum: (params && params.pageNum) || 0,
          limit: (params && params.limit) || 10,
        },
        fetchPolicy: "cache-and-network",
      };
    },
  })
)(AgencySalesTable);

export { TableComponent as AgencySalesByCommunity };
