import React, { useState, useEffect, useContext } from "react";
import { compose, graphql } from "react-apollo";
import { Form, Field, withFormik } from "formik";
import { Dropdown, DropdownButton } from "react-bootstrap";
import TextField from "../../../components/fields/text-field";
import DatePickerView from "../../../components/fields/date-picker";
import CheckboxField from "../../../components/fields/checkbox-field"
import SelectField from "../../../components/fields/select-field";
import ErrorFocus from "../../../components/error-focus/error-focus";
import CustomButton from "../../../components/custom-button";
import { homeownerFilterSchema, homeownerFilter } from "./homeowner-filter-schema";
import { isNotNULL, sanitizeFilterObject, isEmpty } from "../../../utils/helpers";
import { DefaultQuery } from "../../../graphql/default-query";
import { GET_HOMEOWNER_FILTER_BYID, GET_CSM } from "../../../graphql/queries";
import { CREATE_HOMEOWNER_FILTER } from "../../../graphql/mutations";
import { Auth } from '../../../rbac/rbac'
import moment from "moment";

/**
 * This component is used to render in the homeowner filter popup. It renders the actual hoemowner filter form 
 * {@link HomeOwnerListFilter} and the dropdown at the top to show the saved filters list. On selection of any saved
 * filter, its values are assigned to the homeowner filter form.
 * @param {Object} props 
 */
const HomeOwnerFilter = props => {
  const [appliedFilterName, setAppliedFilterName] = useState(null);
  const [values, setValues] = useState({});

  const applySaveFilters = (filterId, filterName) => {
    setAppliedFilterName(filterName);
    if (!filterId) setValues({});
    else {
      props.getHomeownerFilters.fetchMore({
        variables: { id: filterId },
        updateQuery: (prev, { fetchMoreResult }) => {
          setValues(fetchMoreResult.getHomeownerFilters[0]);
        }
      });
    }

  };
  return (
    <div>
      <div className="form-row">
        <div className="col-md-6">
          <h6>Filters</h6>
        </div>
        <div className="col-md-6" style={{ textAlign: "right" }}>
          <DropdownButton
            variant="secondary"
            id="filter-item-btn"
            key="down"
            title={appliedFilterName || props.filter.filter_name || "My Saved Filters"}
            className="filters-my-saved"
          >
            {isNotNULL(props.getHomeownerFilters.getHomeownerFilters) &&
              props.getHomeownerFilters.getHomeownerFilters.map(item => {
                return (
                  <Dropdown.Item
                    onSelect={() => applySaveFilters(item.id, item.filter_name)}
                    key={item.id}
                    eventKey={item.id}
                  >
                    {item.filter_name}
                  </Dropdown.Item>
                );
              })}
          </DropdownButton>
        </div>
      </div>
      <hr className="light" />
      <HomeOwnerFilterForm savedValues={values} appliedFilterName={appliedFilterName} {...props} />
    </div>
  );
};

/**
 * This component is used to render homeowner filter form. It contains two date ranges, purchased and settled. At the
 * bottom it contains an input field to name the filter and save it. Apply filter button remains disabled unless some
 * value on filter fields is selected. On submit setFilter is called, which triggers table refetch. 
 * @param {Object} props 
 */
const HomeOwnerListFilter = props => {
  const { values, setFilter, setShow, appliedFilterName } = props;
  const { user, is_csm } = useContext(Auth);

  const enableFormSubmit = ({ filter_name: _, ...valueCompareFrom }) => {
    const filters = sanitizeFilterObject(Object.assign({}, valueCompareFrom));
    return isEmpty(filters);
  };

  useEffect(() => {
    if (appliedFilterName !== null)
      props.resetForm({ ...homeownerFilter });
  }, [appliedFilterName])

  const handleFilters = () => {
    // const {filter_name: _, ...filterValue} = values;
    /*
    To enable front end filtering, do not call this function, simply uncomment above
    code and pass 'filterValue' to 'setFilter'.
    */
    setFilter(getHomeownerFilterInput(values));
    setShow(false);
  };
  return (
    <Form className="filter-menu">
      <div className="form-row">
        <div className="col-md-6">
          <Field
            label="Purchased From"
            name="purchase_start_date"
            id="purchase_start_date"
            component={DatePickerView}
          />
        </div>
        <div className="col-md-6">
          <Field
            label="Purchased To"
            name="purchase_end_date"
            id="purchase_end_date"
            component={DatePickerView}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-6">
          <Field
            label="Settled From"
            name="settled_start_date"
            id="settled_start_date"
            component={DatePickerView}
          />
        </div>
        <div className="col-md-6">
          <Field
            label="Settled To"
            name="settled_end_date"
            id="settled_end_date"
            component={DatePickerView}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-6">
          <DefaultQuery
            query={GET_CSM}
            variables={{
              community_ids: is_csm() ? user.Communities.map(c => c.id) : null
            }}
          >
            {({ data, loading, error }) => {
              let defaultValue = loading ? "Loading ..." : error ? error.message : "Select...";
              return (
                <Field
                  name="salesperson_id"
                  label="Salesperson"
                  id="salesperson_id"
                  classStyle="filter-select-field"
                  component={SelectField}
                >
                  <option value="">{defaultValue}</option>
                  {data && isNotNULL(data.getAllCSMs)
                    ? data.getAllCSMs.map((csm, index) => (
                      <option key={index} value={csm.id}>
                        {`${csm.first_name} ${csm.last_name}`}
                      </option>
                    ))
                    : null}
                </Field>
              );
            }}
          </DefaultQuery>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-6">
          <Field
            id="brand_ambassador"
            label="Brand Ambassador"
            name="brand_ambassador"
            component={CheckboxField}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="col-md-12">
          <div className="form-row d-flex align-items-center pl-1 pr-1">
            <CustomButton
              btnValue="APPLY FILTERS"
              className="filter-apply-btn"
              onClick={handleFilters}
              disabled={enableFormSubmit(values)}
            />
          </div>
        </div>
      </div>
      <hr className="light" />
      <h6 className="mb-3">My Filters</h6>
      <div className="form-row">
        <div className="col-md-12">
          <Field
            label="Filter Name"
            name="filter_name"
            id="filter_name"
            type="text"
            component={TextField}
            // className="form-control"
            style={{ borderColor: "rgba(255, 255, 255, .2)" }}
          />
        </div>
        <div className="col-md-6 p-0">
          <CustomButton
            btnValue="SAVE FILTER"
            type="submit"
            color="green"
            disabled={enableFormSubmit(values) || props.isSubmitting}
          />
        </div>
      </div>
      <ErrorFocus />
    </Form>
  );
};

/**
 * This function formats the form data according to the expected backend values 
 * while applying and saving the filter
 * 
 * @param {Object} param0 data to be formatted
 */
const getHomeownerFilterInput = (
  {
    purchase_start_date,
    purchase_end_date,
    settled_start_date,
    settled_end_date,
    filter_name,
    brand_ambassador,
    salesperson_id
  },
) => {
  return {
    settled_start_date: settled_start_date && moment(settled_start_date).format('LL'),
    settled_end_date: settled_end_date && moment(settled_end_date).format('LL'),
    filter_name: filter_name && filter_name.trim(),
    brand_ambassador: brand_ambassador,
    purchase_start_date: purchase_start_date &&
      moment(purchase_start_date).format('LL'),
    purchase_end_date: purchase_end_date &&
      moment(purchase_end_date).format('LL'),
    salesperson_id: salesperson_id
  }
};

const HomeOwnerFilterForm = withFormik({
  mapPropsToValues: props => {
    const { filter } = props;
    const { savedValues } = props;
    if (isNotNULL(savedValues) && Object.entries(savedValues).length > 0) {
      const {
        filter_name,
        settled_start_date,
        settled_end_date,
        purchase_start_date,
        purchase_end_date,
        brand_ambassador,
        salesperson_id
      } = savedValues;

      // Here we format values received in backend format to the expected form values
      const temp = {
        purchase_start_date: purchase_start_date,
        purchase_end_date: purchase_end_date,
        settled_start_date: settled_start_date,
        settled_end_date: settled_end_date,
        brand_ambassador: brand_ambassador,
        filter_name: filter_name,
        salesperson_id: salesperson_id
      };
      return { ...homeownerFilter, ...temp };
    }
    return isNotNULL(filter) ? filter : homeownerFilter;
  },
  handleSubmit: (values, { props, setSubmitting }) => {
    props
      .addHomeownerFilter({
        variables: { input: getHomeownerFilterInput(values) }
      })
      .then(res => {
        setSubmitting(false)
        props.onSaveFilter(res.data.addHomeownerFilter.message);
        if (res.data.addHomeownerFilter.code === 200) {
          props.getHomeownerFilters.refetch();
        }
      });
  },
  displayName: "homeowner-filter-form",
  enableReinitialize: true,
  validationSchema: homeownerFilterSchema
})(HomeOwnerListFilter);

export default compose(
  graphql(GET_HOMEOWNER_FILTER_BYID, {
    name: "getHomeownerFilters",
    options: props => ({ variables: { id: null } })
  }),
  graphql(CREATE_HOMEOWNER_FILTER, { name: "addHomeownerFilter" })
)(HomeOwnerFilter);
