import React from "react";
import { useQuery } from "react-apollo-hooks";

import ContentGrid from "../../components/grid/content-grid";
import DashboardSelectBar from "../dashboard/dashboard-select-bar";
import ConversionReport from './conversion-report-container'
import { GET_SELECTED_USER } from "../../cacheql/queries";

const ConversionReportDashboard = (props) => {
  const {
    data: { selectedUserIds }
  } = useQuery(GET_SELECTED_USER);

  return (
    <ContentGrid
      id="conversion-report"
      selectbar={
        <DashboardSelectBar 
          page_type="Report"
          withUser
          withDate
          selectedUserIds
          {...props} 
        />
      }
      content={{
        data: <ConversionReport selectedUserIds={selectedUserIds} />
      }}
    />
  );
};

export default ConversionReportDashboard;
