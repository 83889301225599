import React from "react";
import {
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { FaBan } from "react-icons/fa";
import CustomButton from "../../../components/custom-button/index";
import CustomTable from "../../../components/table/custom-table";
import { isNotNULL } from "../../../utils/helpers";
import CustomLoader from "../../../components/custom-loader";
import "./style.css";


/**
 * This component is used to render sales by community detail drawer. It is divided into 2 parts. On the top
 * it renders a selectBar component to display community's name and sale count. Right
 * below it, we have realtor sale details section.
 * @param {Object} props queries from the container are received as props 
 * @param {object} props.saleDetail contains sale details informations.
 */
const RegistrationSalesDetail = ({ saleDetail, close, ...props }) => {
  const communityNameResolver = community => `${community.name}`;
  const titleResolver = realtor => `${realtor.first_name} ${realtor.last_name}`;
  const saleResolver = realtor => `${realtor.sales_count}`;

  return (
    <div className="form-horizontal">
      <div className="form_TitleContainer">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ margin: "0 1.1rem" }}
        >
          <div>
            <div className="form_Title">Community</div>
            <div className="form_TitleBig">
              <div className="d-flex align-items-center">
              {saleDetail.community && saleDetail.community.is_active === false ? (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip style={{ zIndex: 2022 }}>
                        {"Inactive Community"}
                      </Tooltip>
                    }
                  >
                    <FaBan size={18} className="td-warning" />
                  </OverlayTrigger>
                ) : null}

                <h5
                  style={{
                    position: "relative",
                    paddingRight: "0.5em",
                    marginTop: "9px"
                  }}
                >
                  {`${communityNameResolver(saleDetail.community)} (${
                    saleDetail.realtor_sales_count
                  })`}
                </h5>
              </div>
            </div>
          </div>
          <CustomButton
            color="black"
            onClick={() => close()}
            btnValue="CLOSE"
          />
        </div>
      </div>
      <div className="form_body">
        <h2>Individual Realtor Sales</h2>
        <CustomTable
          tableData={getData(saleDetail.activeSellers, titleResolver,saleResolver)}
          tableHead={["Realtor Name", "No. of Sales", ""]}
        />
      </div>
    </div>
  );
};

export default RegistrationSalesDetail;

function getData(value, titleResolver,saleResolver) {
  let data = [];

  let items = value;

  if (isNotNULL(items)) {
    items.map((item, key) => {
      return data.push([
        <div
          style={{
            maxWidth: "180px",
            display: "block",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}
        >
          <span>{titleResolver(item)}</span>
        </div>,
        <span>{saleResolver(item)}</span>,
        <span></span>
      ]);
    });
  } else {
    let defaultValue =
      value.loading && !value.error ? (
        <CustomLoader />
      ) : value.error ? (
        value.error.message
      ) : (
        "* No records found"
      );
    data.push([<div style={{ textAlign: "center" }}>{defaultValue}</div>]);
  }
  return data;
}
