import React, { useEffect, useMemo, useContext } from "react";
import reducer, { getInitialState } from "../all-registrations-reducer";
import { DashboardContext } from "../../../context";
import { getDescendantProp } from "../../../components/new-table/utils";
import { RealtorsMassUpdateItems } from "../mass-update-items";
import FadeIn from "../../../components/fade-in";
import CustomLoader from "../../../components/custom-loader";
import AllRegistrationFilterForm from "../all-registraton-filter-form";
import _ from "lodash";
import TableView, {
  SortableHeader,
  TextCell,
  TextHeader,
  EmailCell,
  PhoneCell,
  TextCellWithMapper,
  DetailLinkCell,
} from "../../../components/new-table";
import {
  getUpdatedParams,
  getCardParams,
  expiryFormatDate,
  formatDate,
  phoneDisplay,
  isBuyerTwo,
  getSubscribedEmails,
  getAllEmails
} from "../../../utils/helpers";
import routeConstants from "../../layout/routes";

/** @module AllRegistrationsTableModule */

/**
 * This component is a table used to render all client registrations card table. 
 * It contains pagination, searchbar, filter, mass update and export to csv options.
 * It shows multiple columns displaying basic information related to registrations. 
 * Table uses reducer to maintain its state. 
 * It also accesses store to check if table parameters are already stored or not.
 *
 * @param {Object} props it contains all the queries in the conatiner as props
 * @param {Object} props.data it contains array of  YOY sales or YOY registration to be shown on table
 * @param {Object} props.getTableParams it contains cards info stored inside cache
 * @returns {JSX.Element} It returns jsx containing the table
 */
export const AllClientRegistrationsTable = ({
  data,
  getTableParams: { tableParams },
  ...props
}) => {
  const { currentCard } = useContext(DashboardContext);
  const cacheParams = getCardParams(currentCard.uniqueTitle, tableParams);
  const [state, dispatch] = React.useReducer(
    reducer,
    getInitialState({
      loading: data.loading,
      cache: cacheParams,
    })
  );
  const { render, userPageCount, pageSize, registrations, loading } = state;

  useEffect(() => {
    if (!cacheParams) {
      dispatch({ type: "RESET", payload: { render: false } });
    } else {
      dispatch({
        type: "UPDATE",
        payload: { ...cacheParams, pageSize: cacheParams.limit, render: false },
      });
    }
  }, [props.currentCard.uniqueTitle]);

  /**
   * Either set the data in state or set the loading to inform the table what to render.
   */
  useEffect(() => {
    if (
      !data.loading &&
      data.getAllRealtorClientRegistrations &&
      data.getAllRealtorClientRegistrations.realtor_client_registrations
    ) {
      dispatch({
        type: "UPDATE",
        payload: {
          render: true,
          registrations: data.getAllRealtorClientRegistrations.realtor_client_registrations,
          loading: false,
          userPageCount: Math.ceil(
            data.getAllRealtorClientRegistrations.total_count / pageSize
          ),
        },
      });
    }
    if (data.loading) {
      dispatch({ type: "UPDATE", payload: { loading: data.loading } });
    }
  }, [data]);

  /**
   * It is a callback function which is called on any table change like: pagination, page-size, filters, search term.
   * Table passes its current state so that we can update the table parameters in store and state, which are used in
   * refetching the data with correct parameters.
   *
   * @function
   * @inner
   * @memberof module:AllRegistrationsTableModule
   * @see {@link module:AllRegistrationsTableModule~AllRegistrationsTable}
   */
  const fetchData = React.useCallback((tableProps) => {
    if (loading) {
      return;
    }

    const pageCount = Math.ceil(
      data.getAllRealtorClientRegistrations.total_count / tableProps.pageSize
    );

    const variables = {
      filter: {
        ...tableProps.filter,
      },
      search: tableProps.searchTerm,
      limit: tableProps.pageSize,
      pageNum: tableProps.currentPage,
      sort: tableProps.sortColumn ? tableProps.sortColumn : state.sort,
    };

    if (
      tableProps.searchTerm !== state.search ||
      !_.isEqual(variables.filter, state.filter)
    ) {
      variables.pageNum = 0;
    }
    if (tableProps.currentPage > pageCount - 1) {
      variables.pageNum = pageCount ? pageCount - 1 : 0;
    }
    dispatch({
      type: "UPDATE",
      payload: { ...variables, pageSize: tableProps.pageSize, loading: true },
    });

    if (pageCount !== userPageCount) {
      dispatch({
        type: "UPDATE",
        payload: { userPageCount: pageCount },
      });
    }

    props
      .setTableParams({
        variables: {
          cardsParams: getUpdatedParams(tableParams.cardsParams, {
            cardTitle: currentCard.uniqueTitle,
            params: variables,
          }),
        },
      })
      .then((res) => {
        dispatch({ type: "UPDATE", payload: { loading: false } });
      });
  });

  /**
   *  This defines columns of the table. It has multiple columns, displaying information of all registrations.
   * 
   * @constant
   * @memberof module:AllRegistrationsTableModule
   */
  const columns = useMemo(
    () => [
      {
        id: "1",
        header: "First Name",
        accessor: "first_name_1",
        component: SortableHeader,
        cell: { component: TextCell },
      },
      {
        id: "2",
        header: "Last Name",
        accessor: "last_name_1",
        component: SortableHeader,
        cell: { component: TextCell },
      },
      {
        id: "3",
        header: "Email",
        accessor: "primary_email_1",
        component: SortableHeader,
        cell: {
          component: EmailCell,
          check: 'primary_email_1_status',
          callback: getAllEmails
        },
      },
      {
        id: "4",
        header: "Mailchimp Subscription",
        accessor: "primary_email_1_status",
        hidden: true,
        csvMapper: (field, accessor) => field[accessor] ? "Subscribed" : "Unsubscribed",
        cell: { component: null },
      },
      {
        id: "5",
        header: "Phone",
        accessor: "cell_phone_1",
        component: TextHeader,
        csvMapper: (field, accessor) => phoneDisplay(field[accessor]) || "",
        cell: { component: PhoneCell },
      },
      {
        id: "6",
        header: "First Name 2",
        accessor: "first_name_2",
        hidden: true,
        cell: { component: null },
      },
      {
        id: "7",
        header: "Last Name 2",
        accessor: "last_name_2",
        hidden: true,
        cell: { component: null },
      },
      {
        id: "8",
        header: "Primary Email 2",
        accessor: "primary_email_2",
        hidden: true,
        cell: { component: null },
      },
      {
        id: "9",
        header: "Mailchimp 2 Subscription",
        accessor: "primary_email_2_status",
        hidden: true,
        csvMapper: (field, accessor) => isBuyerTwo(field)
          ? field[accessor]
            ? "Subscribed"
            : "Unsubscribed"
          : "",
        cell: { component: null },
      },
      {
        id: "10",
        header: "Phone 2",
        accessor: "cell_phone_2",
        hidden: true,
        csvMapper: (field, accessor) => phoneDisplay(field[accessor]) || "",
        cell: { component: null },
      },
      {
        id: "11",
        header: "Registration Date",
        accessor: "created_at",
        component: SortableHeader,
        csvMapper: (field, accessor) => formatDate(field[accessor]),
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) => formatDate(field[accessor]),
        },
      },
      {
        id: "12",
        header: "Expiration Date",
        accessor: "created_at",
        component: SortableHeader,
        csvMapper: (field, accessor) =>
          field[accessor] ? expiryFormatDate(field[accessor]) : "",
        cell: {
          component: TextCellWithMapper,
          mapper: (field, accessor) =>
            field[accessor] ? expiryFormatDate(field[accessor]) : "",
        },
      },
      {
        id: "13",
        header: "Realtor Name",
        accessor: "Realtor",
        csvMapper: (field, accessor) =>
          `${field[accessor].first_name} ${field[accessor].last_name || ""}`,
        component: TextHeader,
        cell: {
          component: DetailLinkCell,
          path: () => routeConstants.REALTOR_DETAIL,
          mapper: (field, accessor) => `${field[accessor].first_name} ${field[accessor].last_name || ""}`,
        },
      },
      {
        id: "14",
        header: "Realtor Email",
        accessor: "Realtor.primary_email",
        csvMapper: (field, accessor) =>
          getDescendantProp(field, accessor),
        component: TextHeader,
        cell: {
          component: EmailCell,
          check: 'Realtor.primary_email_status',
          descendant: true
        }
      },
      {
        id: "15",
        header: "Realtor Mailchimp Subscription",
        accessor: "Realtor.primary_email_status",
        hidden: true,
        csvMapper: (field, accessor) => getDescendantProp(field, accessor) ? "Subscribed" : "Unsubscribed",
        cell: { component: null },
      },
      {
        id: "16",
        header: "Realtor Phone",
        accessor: "Realtor.cell_phone",
        csvMapper: (field, accessor) =>
          phoneDisplay(getDescendantProp(field, accessor)),
        component: TextHeader,
        cell: { component: PhoneCell },
      },
      {
        id: "17",
        header: "Realtor Agency",
        accessor: "Realtor.Agency.name",
        csvMapper: (field, accessor) =>
          getDescendantProp(field, accessor),
        component: TextHeader,
        cell: { component: TextCell },
      },
    ],
    [currentCard.uniqueTitle]
  );

  /**
   * This is an object that defines table title, and massActions and filters for the table.
   *
   * @constant
   * @memberof module:AllRegistrationsTableModule
   */
  const tableOptions = {
    title: currentCard.uniqueTitle,
    massActions: {
      render: (getSelectedRows) => {
        let emails = getSubscribedEmails(getSelectedRows());
        return <RealtorsMassUpdateItems emails={emails} />;
      },
    },
    FilterItems: AllRegistrationFilterForm,
  };

  return (
    <div>
      <FadeIn show={render}>
        <div className="table-realtors">
          <TableView
            columns={columns}
            data={registrations}
            tableOptions={tableOptions}
            loading={loading}
            userPageCount={userPageCount}
            userTotalCount={
              !loading && data.getAllRealtorClientRegistrations
                ? data.getAllRealtorClientRegistrations.total_count
                : null
            }
            fetchData={fetchData}
            currentPage={state.pageNum}
            pageSize={state.pageSize}
            filter={state.filter}
            searchTerm={state.search}
            sortColumn={state.sort}
            controlled={true}
          />
        </div>
      </FadeIn>
      {!render && <CustomLoader />}
    </div>
  );
};
