import * as Yup from "yup";
// import * as errorMessages from "./form-errors";

export const realtorFilterForm = {
  is_listing_agent_of_sb: false,
  has_client_reg: false,
  agency_name: "",
  sale_start_date:null,
  sale_end_date:null,
  register_start_date:null,
  register_end_date:null,
};

export const realtorFilterSchema = Yup.object().shape({
  is_listing_agent_of_sb: Yup.string()
    .default("Select...")
    .nullable(),
  has_client_reg: Yup.string()
    .default("Select...")
    .nullable(),
  agency_name: Yup.string()
    .default("")
    .nullable()
});
