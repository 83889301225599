
import gql from 'graphql-tag';

export const UPDATE_HOMEOWNER = gql`
mutation updateCustomer($input: CustomerUpdateInput!){
    updateCustomer(input: $input) {
      message
      code
    }
  }
`;

export const UPDATE_HOMEOWNER_REALTOR = gql`
mutation updateHomeownerRealtor($input: HomeownerRealtorInput!) {
  updateHomeownerRealtor(input: $input) {
      message
      code
    }
  }
`;

export const CREATE_HOMEOWNER_FILTER = gql`
mutation addHomeownerFilter($input: AddHomeownerFilterInput!){
    addHomeownerFilter(input: $input) {
      message
      code
    }
  }
`;