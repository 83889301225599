import React from "react";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import { IconContext } from "react-icons";

import './column-header.css'

/**
 * this function is used to render the sortable headers of the tables.
 * @param {string} header header of the column
 * @param {string} sort type of sort (asc, desc)
 * @param {function} onClick
 * @returns {JSX.Element}
 */
export const SortableHeader = ({ header, sort, onClick = () => {} }) => {
  return (
    <th
      className="cursor-pointer"
      onClick={e =>
        onClick(sort.length <= 0 ? "asc" : sort === "asc" ? "desc" : "asc")
      }
      scope="col"
    >
      <div className="d-flex align-items-center">
      {header}
      <span className="d-flex align-items-center">
        <IconContext.Provider value={{ color: sort === "asc" ? "green" : "" }}>
          <FaSortUp style={{marginTop:'5px',marginLeft:'2px'}} />
        </IconContext.Provider>
        <IconContext.Provider value={{ color: sort === "desc" ? "green" : "" }}>
          <FaSortDown style={{marginBottom:'5px',marginLeft:'-2px'}} />
        </IconContext.Provider>
      </span>
      </div>
    </th>
  );
};

/**
 * this function is used to render text type headers of the tables.
 * @param {string} header header of the column
 * @returns {JSX.Element}
 */
export const TextHeader = ({ header }) => {
  return (
    <th scope="col">
      {header}
    </th>
  );
};

/**
 * this function is used to render check box type headers of the tables.
 * @param {boolean} isSelectedAll boolean variable to specify if all the rows are selected
 * @returns {JSX.Element}
 */
export const CheckboxHeader = ({ isSelectedAll, onChange = () => {} }) => {
  return (
    <th scope="col">
      <input
        type="checkbox"
        checked={isSelectedAll}
        onChange={e => onChange(!isSelectedAll)}
      />
    </th>
  );
};
