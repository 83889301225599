import { setAccessToken } from "./access-token";

/**
 * This function is used to make a refresh token call to the backend. If an error occures throw error, else set the 
 * new token to the store.
 * @method refreshToken
 * @memberof module:RBAC
 */
export default async function refreshToken(){
 return fetch(process.env.REACT_APP_SESSION, {
    method: "POST",
    credentials: "include"
  })
    .then(async res => {
      if (res.status !== 200) {
        return Promise.reject(res);
      }
      const { accessToken } = await res.json();
      setAccessToken(accessToken);
    })
    .catch(async err => {
      const responseError = await err.json();
      throw responseError
    });
}

