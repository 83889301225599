import React, { useEffect } from "react";
import ActivityCard from "../../components/activity-card/activity-card";
import routes_constants from "../layout/routes";
import "./dashboard.css";

/**
 * This component renders three cards statically, which are used to display on dashboard page. On each card click
 * the page is redirected to some other page like lead/prospect. And on every update, all the card queries are
 * refetched to update the data.
 * @param {Object} props all the queries in the container form the props.
 */
const MonthlyActivity = ({ leadMonthly, leadPrevYearMonthly, prospectDataUpdated, monthly, prevYearMonthly, ranked, date, ...prop }) => {
  useEffect(() => {
    leadMonthly.refetch();
    monthly.refetch();
    ranked.refetch();
    leadPrevYearMonthly.refetch();
    prevYearMonthly.refetch();
  }, [prospectDataUpdated]);

  return (
    <div className="d-flex activity-cards-wrapper" id="activity-card">
      <ActivityCard
        counter="New Leads"
        date={date}
        count={!leadMonthly.loading && !leadMonthly.error ? leadMonthly.getLeads.total_count : 0}
        prevCount={!leadPrevYearMonthly.loading && !leadPrevYearMonthly.error ? leadPrevYearMonthly.getLeads?.total_count : undefined}
        route={{ pathname: routes_constants.NEW_LEADS, hash: "#month" }}
      />

      <ActivityCard
        counter="New Prospects"
        date={date}
        count={!monthly.loading && !monthly.error ? monthly.getTotalProspectsCount : 0}
        prevCount={!prevYearMonthly.loading && !prevYearMonthly.error ? prevYearMonthly?.getTotalProspectsCount : undefined}
        route={{ pathname: routes_constants.NEW_PROSPECTS, hash: "#month" }}
      />

      <ActivityCard
        counter="A Ranked Prospects"
        count={!ranked.loading && !ranked.error ? ranked.getProspects.total_count : 0}
        route={{
          pathname: routes_constants.NEW_PROSPECTS,
          hash: "#rank-a",
        }}
      />
    </div>
  );
};

export default MonthlyActivity;
