import React from "react";
import NoteListView from "./notes-list-container";
import NoteButton from "../../components/note-button";
import AccordionView from "../../components/accordion/accordion";

/**
 * this component is used to render the notes section on details pages
 * @param {object} props component props
 * @param {string} type type of the customer
 */
const NoteReminderView = (props) => {
  const { type } = props;

  return (
    <div className="mb-3 mt-md-3">
      <div className="d-flex justify-content-between">
        <h3 className="ml-0">Notes and Reminders</h3>
        <div className="d-flex justify-content-end">
          <NoteButton
            {...props}
            id={props.id}
            type={type}
            btnValue="ADD NOTE"
            function="CREATE"
          />
        </div>
      </div>
      <div className="notes-list-row">
        <AccordionView
          component={NoteListView}
          data={props.data}
          id={props.id}
          type={type}
          {...props}
        />
      </div>
    </div>
  );
};

export default NoteReminderView;