import gql from 'graphql-tag'

export const ENABLE_AUTO_REPLY_WEBFORM_TYPE = gql`
    mutation enableAutoReplyOnWebFormType($web_form_type_ids: [Int]!) {
        enableAutoReplyOnWebFormType(web_form_type_ids: $web_form_type_ids) {
            message,
            code,
            success
        }
    }

`

export const DISABLE_AUTO_REPLY_WEBFORM_TYPE = gql`
    mutation disableAutoReplyOnWebFormType($web_form_type_ids: [Int]!) {
        disableAutoReplyOnWebFormType(web_form_type_ids: $web_form_type_ids) {
            message,
            code,
            success
        }
    }

`

export const SET_DEFAULT_OSC_WEBFORM_AUTO_REPLY = gql`
    mutation setDefaultOscForWebFormAutoReply($osc_id: Int!, $day_of_week: String!) {
        setDefaultOscForWebFormAutoReply(osc_id: $osc_id, day_of_week: $day_of_week) {
            message,
            code,
            success
        }
    }
`