import gql from 'graphql-tag';

const GET_DELETE_REQUESTS = gql`
query getDeleteRequests($search: String, $order: String,$limit: Int,$pageNum: Int){
    getDeleteRequests(search:$search, order:$order,limit:$limit,pageNum: $pageNum) {
        delete_requests{
            type
            first_name_1
            last_name_1
            cell_phone_1
            primary_email_1
            deleted_at
            source_id
            approved
            request_date
            current_community{
                id
                name
                is_active
            }
            all_communities{
                id
                name
                is_active
            }
            submitter{
                first_name
                last_name
                is_active
            }
            note
        }
        total_count
  }
 }
 `;

export default GET_DELETE_REQUESTS;