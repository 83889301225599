import React from "react";
import { NavLink } from "react-router-dom";
import Counter from "./activity-counter";
import CountComparator from "./activity-comparator";
import Help from '../help'
import "./activity-card.css";

/**
 * This is a stateless component used to render Card showing Count at the center, title below it. If description prop is passed to this component it displays a help icon on top-right of the card along with the description in the tooltip. Similarly, to display percentage pass percentValue.
 * @param {Object} props Component props
 * @param {String} props.selectedCard Title of selected card
 * @param {String} props.counter Card title
 * @param {Number} props.count Count to be shown on card
 * @param {Number} props.secondaryCount Second count to be shown on card alongside main count
 * @param {Number} props.prevCount Count to compared with the current count
 * @param {String} props.route Redirect route on card click
 * @param {String} props.date Date to be shown on card below the count
 * @param {Function} props.onClick Click handler
 * @param {String} props.description Description explaining what this card is about in tooltip
 * @param {Number} props.percentValue Percentage to be shown right next to card title
 */
const ActivityCard = ({
  selectedCard,
  counter,
  count,
  secondaryCount,
  prevCount,
  route,
  date,
  onClick,
  description,
  percentValue,
  ...props
}) => {

  const handleClick = () => {
    if (selectedCard) {
      onClick()
    }
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  }
  const activityClass = selectedCard !== counter ? "activity-card-root" : "activity-card-root-selected"
  const displayPercent =  (counter === "YOY Sales" || counter === "YTD Sales") ? true : false

  return (<>
    <div id="activity-card-root"
      onClick={handleClick}
      className={'w-100 d-flex align-items-center ' + activityClass}
    >
      <NavLink id="sub-nav-link" to={route || '#'} className="activity-card-inner d-block w-100">
        {description ? <div className="card-help position-absolute">
          <Help description={description} />
        </div> : null}

        <div className="card-web-form">
          <Counter
            count={count}
            percentValue={displayPercent ? percentValue : null}
            counter={counter}
            date={date}
            secondCount={secondaryCount}
          />
        </div>
        {prevCount !== undefined && (<CountComparator count={count} prevCount={prevCount} />)}
      </NavLink>
    </div>
  </>
  )
}

export default ActivityCard;
