import gql from 'graphql-tag';

const DELETE_HOMEOWNER_FOLLOW_UP = gql` 
  mutation deleteHomeownerFollowUp($input: HomeownerFollowUpDeleteInput!) {
    deleteHomeownerFollowUp(input: $input) {
      message
      code
    }
  }
`;

export default DELETE_HOMEOWNER_FOLLOW_UP;