import gql from "graphql-tag";

const UPDATE_EMAIL_TEMPLATE = gql`
  mutation updateTemplate($id: ID!, $input: UpdateEmailTemplateInput!) {
    updateEmailTemplate(id: $id, input: $input) {
      code
      success
      message
    }
  }
`;
export default UPDATE_EMAIL_TEMPLATE;
