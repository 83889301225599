import gql from 'graphql-tag';

const ADD_PROSPECT_PHONE_CALL = gql` 
  mutation addProspectPhoneCall($input: ProspectPhoneCallInput!) {
    addProspectPhoneCall(input: $input) {
      message
      code
    }
  }
`;

export default ADD_PROSPECT_PHONE_CALL;