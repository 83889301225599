import gql from "graphql-tag";

const GET_TABLE_PARAMS = gql`
  query {
    tableParams @client {
      cardsParams
    }
  }
`;

export default GET_TABLE_PARAMS;
