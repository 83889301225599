import React, { useEffect, useMemo, useState } from "react";
import { compose, graphql } from "react-apollo";

import TableView, {
  buyerTwoIconCellObject,
  DetailLinkCell,
  EmailCell,
  PhoneCell,
  TextCellWithMapper,
  TextHeader,
  TextObjectWithMapper,
} from "../../components/new-table";
import FadeIn from "../../components/fade-in";
import { getStringDescendantElementsWithEmptyVals } from "../../components/new-table/utils";
import { phoneDisplay } from "../../utils/helpers";
import routes_constants from "../layout/routes";
import { GET_FORM_SUBMISSIONS } from "../../graphql/queries";
import { FaListAlt } from "react-icons/fa";

/** @module GlobalWebFormTableModule */
/**
 * This component is a table used to display formsubmission data based on the global search data. It contains only the top 5 results and further specific query needs to be made
 * to get the appropriate data. It also has a basic hover action on Name column which redirect to the form details.
 *
 * @param {Object} props this contains the data from the graphql query and the function to close the drawer
 * @param {String} props.searchTerm it contains the search query from the GlobalSearchDetails component
 * @param {Function} props.handleDrawerClose function to close the drawer
 * @returns {JSX.Element} It returns jsx containing the table title and the table itself
 */
const GlobalWebFormTable = ({ searchTerm, handleDrawerClose, data }) => {
  const [loading, setLoading] = useState(true);
  const [render, setRender] = useState(false);
  const [formSubmission, setFormSubmission] = useState({});

  /**
   * Either set the data in state or set the loading to inform the table what to render.
   */
  useEffect(() => {
    if (
      !data.loading &&
      data.getFormSubmission &&
      data.getFormSubmission.formSubmission
    ) {
      setLoading(false);
      setRender(true);
      setFormSubmission(data.getFormSubmission.formSubmission);
    }
    if (data.loading) {
      setLoading(data.loading);
    }
  }, [data]);

  /**
   * this function sorts the array of object by the responded at attribute
   * @param {Array} arr array of formsubmissions
   * @function
   * @inner
   * @memberof module:GlobalWebFormTableModule
   */
  const sortByRespondedAt = (arr) => {
    return arr.sort(function (a, b) {
      return new Date(b.responded_at) - new Date(a.responded_at);
    });
  };

  /**
   * this function is used to map the community and form type cells value
   * @param {object} field rows of the table
   * @param {string} accessor object attribute name
   * @param {string} type inner object attribute name
   * @function
   * @inner
   * @memberof module:GlobalWebFormTableModule
   * @returns {array}
   */
  const formCommunityMapper = (field, accessor, type) => {
    const sortedSubmissions = field.responded
      ? sortByRespondedAt(field[accessor].filter((val) => val))
      : field[accessor].filter((val) => val).reverse();
    return getStringDescendantElementsWithEmptyVals(sortedSubmissions, type);
  };

  /**
   * Columns of the table containing an empty column along with name, community, phone and email.
   * @constant
   * @memberof module:GlobalWebFormTableModule
   */
  const columns = useMemo(() => [
    {
      id: "0",
      header: "",
      accessor: "",
      component: TextHeader,
      cell: buyerTwoIconCellObject,
    },
    {
      id: "1",
      header: "Name",
      accessor: "firstname",
      component: TextHeader,
      sortable: false,
      cell: {
        component: DetailLinkCell,
        path: (field, accessor) => routes_constants.WEB_FORM_DETAIL,
        mapper: (field, accessor) => field[accessor] + " " + field["lastname"],
        action: handleDrawerClose,
      },
    },
    {
      id: "2",
      header: "Community",
      accessor: "community",
      component: TextHeader,
      sortable: false,
      csvMapper: (field, accessor) => field[accessor]?.name,
      cell: {
        component: TextCellWithMapper,
        mapper: (field, accessor) => field[accessor]?.name,
      },
    },
    {
      id: "3",
      header: "Phone",
      accessor: "phone",
      component: TextHeader,
      csvMapper: (field, accessor) => phoneDisplay(field[accessor]) || "",
      sortable: false,
      cell: { component: PhoneCell },
    },
    {
      id: "4",
      header: "Email",
      accessor: "email",
      component: TextHeader,
      sortable: false,
      cell: {
        component: EmailCell,
      },
    },
  ]);

  return (
    <div>
      <div className="d-flex header-table">
        <FaListAlt className="header-icon" />
        <div className="d-flex align-self-center header-text">
          Web Forms {!formSubmission.length && "(No records Found)"}
        </div>
      </div>
      {formSubmission.length ? (
        <div className="results-table-search">
          <FadeIn show={render}>
            <TableView
              columns={columns}
              data={formSubmission}
              loading={loading}
              currentPage={0}
              isGlobalSearch={true}
              controlled={true}
            />
          </FadeIn>
        </div>
      ) : null}
    </div>
  );
};

export default compose(
  graphql(GET_FORM_SUBMISSIONS, {
    options: ({ searchTerm }) => {
      return {
        variables: {
          pageNum: 0,
          limit: 5,
          sort: null,
          search: searchTerm || "",
        },
        fetchPolicy: "cache-and-network",
      };
    },
  })
)(GlobalWebFormTable);
