
import gql from 'graphql-tag';

const CREATE_LEAD_FILTER = gql`
mutation addLeadFilter($input: AddLeadFilterInput!){
    addLeadFilter(input: $input) {
      message
      code
    }
  }
`;

export default CREATE_LEAD_FILTER;