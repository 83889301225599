import React from "react";
import PropTypes from 'prop-types';
import "./no-data-page.css";

/**
 * This component is responsible to render no data page by taking in custom image, message and content
 * @param {Object} props 
 * @param {*} props.backgroundImage  Image that is to be shown at the center
 * @param {String} props.heading Message to show 
 * @param {JSX.Element} props.content custom content to display below the heading
 */
const NoDataPage = props => {
  const { heading, content, backgroundImage } = props;
  return (
    <div className="empty-message-wrapper">
      <div className="empty-message-inner">
      <img src={backgroundImage} alt="" className="empty-image"></img>
      <div>
        <h2 className="no-data-heading">{heading}</h2>
        <p className="">{content}</p>
      </div>
      </div>
    </div>
  );
};

NoDataPage.propTypes = {
  heading: PropTypes.string,
  content: PropTypes.string,
  backgroundImage: PropTypes.string,
};


export default NoDataPage;
