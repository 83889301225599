import React, {
  Component,
  useContext,
  useState,
  useEffect,
} from "react";
import { graphql, compose } from "react-apollo";
import { fetch } from "whatwg-fetch";
import { Redirect, withRouter } from "react-router-dom";

import logo from "../images/hbcrm.png";
import unreachableLogo from "../images/unavailable-hbcrm.png";
import { getAccessToken, setAccessToken } from "./access-token";
import { GET_AUTH_STATUS } from "../cacheql/queries";
import {
  UPDATE_TITLE_CUSTOMIZATIONS,
  SET_AUTH_STATUS
} from "../cacheql/mutations";
import "./access-denied.css";
import "./login.css";
import { clearCache } from "../app/apollo-client";
import { FaSignOutAlt } from "react-icons/fa";
import { routes } from "../views/index";
import { isNotNULL } from "../utils/helpers";
import { withCookies } from 'react-cookie';
import { ROLE_CSM, ROLE_DIVISION_MANAGER, ROLE_MANAGER, ROLE_OSC } from "../utils/constants";

/** @module RBAC */

/** 
 * There are a bunch of different ways this could be formulated, changing it would require changing the
 * implementation of UserAuth.can, UserAuth.can_any, UserAuth.entity_type_entitlements below. This contains
 * read writes of different pages by different users
 * @memberof module:RBAC
*/
const ENTITLEMENTS = {
  sb: {
    dashboard: {
      read: {
        manager: true,
        divmgr: true,
        csm: true,
        osc: true
      }
    },
    webform: {
      read: {
        manager: true,
        divmgr: true,
        csm: true,
        osc: true
      }
    },
    webformOthers: {
      read: {
        manager: true,
        divmgr: true,
        csm: true,
        osc: true,
      }
    },
    admin: {
      read: {
        manager: true,
        divmgr: true,
        csm: true,
        osc: true
      }
    },
    customizations: {
      read: {
        manager: true,
        divmgr: true,
        csm: true,
        osc: true
      }
    },
    emailTemplates: {
      read: {
        manager: true,
        divmgr: true,
        csm: false,
        osc: true
      }
    },
    submissionUpload: {
      read: {
        manager: true,
        divmgr: true,
        csm: false,
        osc: true
      }
    },
    archiveUsers: {
      read: {
        manager: true,
        divmgr: true,
        csm: true,
        osc: true
      }
    },
    oscReports: {
      read: {
        manager: true,
        divmgr: true,
        csm: false,
        osc: true
      }
    },
    usageReport: {
      read: {
        manager: true,
        divmgr: true,
        csm: false,
        osc: false
      }
    }
  }
};

let tabStatus = "ACTIVE"; //To keep track which browser tab is active

/** Authentication context */
export const Auth = React.createContext();

/**
 * This component is used at the top level to wrap the whole application. It wraps its children into Authentication
 * context to make all the RBAC related information and function readily available throughtout the application. It has
 * all the RBAC related functionalities, like login, logout etc, implemented in it. This component gets mounted as soon as the application loads 
 * @class
 */
class UserAuthWithApollo extends Component {
  /**
   * @param {Object} props 
   * @param {Function} props.setAuthStatus to set status of Authentication in store
   * @param {JSX.Element} props.children component tree which wants to have access to auth-context
   */
  constructor(props) {
    super(props);
    /**
     * @typedef {Object} UserAuthState
     * @property {Boolean} isAuthenticated is user is authenticated or not
     * @property {*} interval unique identifier of the timer which is used in clearing interval
     * @property {Object} user conatins information related to logged in person
     * @property {Object} entitlements read access of pages for different roles explained in {@link module:RBAC.ENTITLEMENTS} 
     * @property {Function} login to login the user
     * @property {Function} signout to logout the user
     * @property {Function} reinit to reinitialize all the necessary information
     * @property {Function} startSession starts interval for refresh token
     * @property {Function} can checks if current logged in user can perform an action 
     * @property {Function} can_any checks if user can perform "any" of the actions
     * @property {Function} entity_type_permissions 
     * @property {Function} is_csm checks if logged in person is csm
     * @property {Function} is_manager checks if logged in person is maneger
     * @property {Function} is_osc checks if logged in person is osc
     * @property {Function} is_divmgr checks if logged in person is division manager
     * @property {Function} set_login_error it is like reinit except that it does not clears cache
     * @property {Function} handle_login_as handles addition/removal of loginAs cookie to view application as different user
     * @property {Function} is_logged_in_as checks if logged in as different user
     * 
     * @memberof module:RBAC~UserAuthWithApollo 
    */

    /**
     * @memberof module:RBAC~UserAuthWithApollo 
     * @type {UserAuthState} 
    */
    this.state = {
      isAuthenticated: false,
      interval: undefined,
      user: { role: null, communities: [], email: "" },
      entitlements: ENTITLEMENTS,
      login: (role, cb) => this.login(role),
      signout: cb => this.signout(),
      reinit: () => this.reinit(),
      startSession: () => this.startSession(),
      sessionTimout: () => this.sessionTimout(),
      can: e => this.can(e),
      can_any: r => this.can_any(r),
      entity_type_permissions: e => this.entity_type_permissions(e),
      is_csm: () => this.is_csm(),
      is_manager: () => this.is_manager(),
      is_divmgr: () => this.is_divmgr(),
      is_osc: () => this.is_osc(),
      has_multiple_divisions: () => this.has_multiple_divisions(),
      set_login_error: err => this.set_login_error(err),
      handle_login_as: login_as_token => this.handle_login_as(login_as_token),
      is_logged_in_as: () => this.is_logged_in_as()
    };
  }

  /**
   * This method is called when the component mounts, it changes the current tabstatus that if it is active or 
   * inactive.
   */
  componentDidMount() {
    window.addEventListener("focus", () => {
      if (this.state.isAuthenticated) {
        tabStatus = "ACTIVE";
      }
    });
    window.addEventListener("blur", () => {
      tabStatus = "INACTIVE";
    });
  }

  /**
   * This method is called on unmounting of the component. This is the place where we clear out interval for refreshing
   * the token.
   */
  componentWillUnmount() {
    if (this.state.interval) {
      clearInterval(this.state.interval);
    }
  }

  /**
   * This fucntion is used to send login request to backend and handle responses. On successful login token is set 
   * using the global function "setAccessToken". User id is set inside local storage to make communication between 
   * tabs. On every login/logout/error, any such change which should be reflected in the other tabs as well, we
   * update the user_id in local storage which makes other tabs reload automatically. For example if user has opened
   * two tabs and logs-out of one tab, this change must be reflected in 2nd tab automatically. On error either auth
   * status is changed or set_login_error is called to re-initialize every thing. 
   */
  try_login() {
    fetch(process.env.REACT_APP_LOGIN, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(async x => {
        if (x.status !== 200) {
          return Promise.reject(x);
        }
        const { accessToken, user } = await x.json();
        setAccessToken(accessToken);
        localStorage.setItem("user_id", user.id);
        if (this.state.user.id !== user.id) window.location.reload();
      })
      .catch(async err => {
        if (err.message === "Network request failed") {
          this.props.setAuthStatus({ variables: { status: "network-failed" } });
        } else {
          const responseError = await err.json();
          this.set_login_error(responseError);
        }
      });
  }

  /**
   * This function is called on application load to start a timer upon successful login, to keep refreshing the token
   * or making session call when tab is not active to keep same token in all the tabs. On success we set the newly
   * received token in memory. On error either we set the network error or try to login if user is logged out or simply
   * set the error to redirect the user to unauthorized page.
   */
  startSession() {
    this.setState({
      interval: setInterval(async () => {
        const url =
          tabStatus === "ACTIVE"
            ? process.env.REACT_APP_REFRESH_TOKEN
            : process.env.REACT_APP_SESSION;
        fetch(url, {
          method: "POST",
          credentials: "include"
        })
          .then(async res => {
            if (res.status !== 200) {
              return Promise.reject(res);
            }
            const { accessToken } = await res.json();
            setAccessToken(accessToken);
          })
          .catch(async err => {
            if (err.message === "Network request failed") {
              this.props.setAuthStatus({
                variables: { status: "network-failed" }
              });
            } else {
              const responseError = await err.json();
              if (responseError.message === "User is not logged in") {
                this.try_login();
              } else {
                this.set_login_error(responseError);
              }
            }
          });
      }, 300000)
    }); //(5 min))
  }

  /**
   * set the logged in user to state/context and start the refresh token timer 
   * @param {Object} user logged in user 
   */
  login(user) {
    if (!this.state.interval) {
      this.startSession();
    }
    this.save_to_session({ isAuthenticated: true, user, error: {} });
  }

  /**
   * This function makes logout request to backend and clears the timer, token, localstorage and cache. It also resets
   * all the necessary properties in the context to display Unauthorized page
   */
  signout() {
    fetch(process.env.REACT_APP_LOGOUT, {
      method: "POST",
      headers: {
        authorization: getAccessToken()
      },
      credentials: "include"
    })
      .then(res => {
        this.state.interval && clearInterval(this.state.interval);
        setAccessToken("");
        localStorage.setItem("user_id", "");
        this.props.cookies.remove('loginAs');
        this.save_to_session({
          isAuthenticated: false,
          user: { role: null, secondary_role: null, communities: [], email: "", interval: null }
        });
        clearCache();
      })
      .catch(err => console.log("ERROR: ", err));
  }

  /**
   * It clears interval and localstorage. Also resets necessary information like isAuthenticated, user etc and sets
   * the error in state
   * @param {Object} err 
   */
  set_login_error(err) {
    this.state.interval && clearInterval(this.state.interval);
    localStorage.setItem("user_id", "");
    this.setState({
      isAuthenticated: false,
      user: {},
      error: err,
      interval: null
    });
  }

  /**
   * This function is similar to set_login_error except that it accepts no argument and it also clears the cache
   */
  reinit() {
    this.state.interval && clearInterval(this.state.interval);
    clearCache();
    localStorage.setItem("user_id", "");
    this.save_to_session({
      isAuthenticated: false,
      user: { role: null, secondary_role: null, communities: [], email: "" },
      interval: null
    });
  }

  /** Checks if the logged in person is a Manager */
  is_manager() {
    return this.state.user.role === ROLE_MANAGER || this.state.user.secondary_role === ROLE_MANAGER;
  }

  /** Checks if the logged in person is a Division Manager */
  is_divmgr() {
    return this.state.user.role === ROLE_DIVISION_MANAGER || this.state.user.secondary_role === ROLE_DIVISION_MANAGER;
  }

  /** Checks if the logged in person is a CSM */
  is_csm() {
    return this.state.user.role === ROLE_CSM && this.state.user.secondary_role === ROLE_CSM;
  }

  /** Checks if the logged in person is an OSC */
  is_osc() {
    return this.state.user.role === ROLE_OSC && this.state.user.secondary_role === ROLE_OSC;
  }

  /** Checks if the logged in person has more than 1 division */
  has_multiple_divisions() {
    return this.state.user.Divisions.length > 1
  }

  save_to_session(state) {
    this.setState(state);
  }

  /** returns true if the currently logged-in user can perform an action or access a field */
  can(e) {

    const {
      isAuthenticated,
      entitlements,
      user: { role, secondary_role }
    } = this.state;

    // early out if we're not logged in
    if (!isAuthenticated || (role === null && secondary_role === null)) {
      return false;
    }

    const [provider, entity, action] = e.split(".", 3);

    const action_entitlements = entitlements[provider][entity];

    if (action === "*") {
      // see if we can perform any action on the entity
      return Object.values(action_entitlements).some(v => v[role] || v[secondary_role]);
    }
    return action_entitlements[action][role] || action_entitlements[action][secondary_role];
  }

  /** returns true if the user can perform any of the actions in the array reqs. */
  can_any(reqs) {
    if (this.state.roll === null) {
      return false;
    } // if we're not logged in, we're not good.
    if (!reqs) {
      return true;
    } // if there are no requirements, we're good.
    return reqs.some(e => this.can(e)); // if there are requirements, and we have one or more of them, we're good.
  }

  entity_type_permissions(e) {
    const {
      isAuthenticated,
      entitlements,
      user: { role, secondary_role }
    } = this.state;

    // early out if we're not logged in
    if (!isAuthenticated || (role === null && secondary_role === null)) {
      return false;
    }

    const [provider, entity] = e.split(".", 3);

    const action_entitlements = entitlements[provider][entity];

    let result = {};
    Object.keys(action_entitlements).forEach(
      e => (result[e] = action_entitlements[e][role])
    );

    return result;
  }

  /**
   * @param login_as_token loginAs token containing user id
   * @param expiry_date expiry date for cookie
   */
  handle_login_as(login_as_token) {
    const { cookies } = this.props;
    const hostname = window.location.hostname;
    const domain = hostname.includes('localhost') ? 'localhost' : `.${hostname.split('.').slice(-2).join('.')}`
    if (login_as_token) {
      cookies.set('loginAs', login_as_token, { path: '/', domain });
    } else {
      cookies.remove('loginAs', { domain });
    }
    window.location.reload();
  }

  /**
   * @returns true if loginAs cookie is set
   */
  is_logged_in_as() {
    return this.props.cookies.get('loginAs') ? true : false;
  }

  /** Provides the whole state of this component to the context which can be accessible anywhere in the chilren tree
   */
  render() {
    return (
      <Auth.Provider value={this.state}>
        <RbacConcierge>{this.props.children}</RbacConcierge>
      </Auth.Provider>
    );
  }
}

export const UserAuth = withCookies(compose(
  graphql(SET_AUTH_STATUS, { name: "setAuthStatus" })
)(UserAuthWithApollo));

/**
 * This is gate keeper of pulse. It will always make sure that user is valid otherwise it will redirect user to 
 * unauthorized page. On component mount it makes a session call to check if the user is logged in, on success access
 * token is set and user is logged in by calling login method from context.
 * @param {*} props
 */
const RbacConciergeWithApollo = props => {
  const [loading, setLoading] = useState(true);
  const { login, set_login_error, isAuthenticated } = useContext(Auth);
  const [titleCustomizations, setTitleCustomizations] = useState({});
  const [isLogin, setIsLogin] = useState(false);
  const { children, setAuthStatus } = props;

  const toggleIsChange = e => {
    window.location.reload();
  };

  useEffect(() => {
    window.addEventListener("storage", toggleIsChange);

    fetch(process.env.REACT_APP_SESSION, {
      method: "POST",
      credentials: "include"
    })
      .then(async res => {
        if (res.status !== 200) {
          return Promise.reject(res);
        }
        const { accessToken, user, titleCustomizations } = await res.json();
        titleCustomizations.forEach(
          item => (item.__typename = "TitleCustomization")
        );
        localStorage.setItem("user_id", user.id);
        setAccessToken(accessToken);
        setTitleCustomizations(titleCustomizations);
        login(user);
        setLoading(false);
      })
      .catch(async err => {
        if (err.message === "Network request failed") {
          setAuthStatus({ variables: { status: "network-failed" } });
          setLoading(false);
        } else {
          const responseError = await err.json();
          if (responseError.message === "User is not logged in") {
            setIsLogin(true);
          } else {
            set_login_error(responseError);
            setLoading(false);
          }
        }
      });

    return () => {
      window.removeEventListener("storgae", toggleIsChange);
    };
  }, []);

  useEffect(() => {
    if (isLogin) {
      setIsLogin(false);
      fetch(process.env.REACT_APP_LOGIN, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        credentials: "include"
      })
        .then(async x => {
          if (x.status !== 200) {
            return Promise.reject(x);
          }
          const { accessToken, user, titleCustomizations } = await x.json();
          localStorage.setItem("user_id", user.id);
          setAccessToken(accessToken);
          setTitleCustomizations(titleCustomizations);
          setLoading(false);
          login(user);
        })
        .catch(async err => {
          if (err.message === "Network request failed") {
            setAuthStatus({ variables: { status: "network-failed" } });
            setLoading(false);
          } else {
            const responseError = await err.json();
            set_login_error(responseError);
            setLoading(false);
          }
        });
    }
  }, [isLogin]);

  if (loading) {
    return <div>loading...</div>;
  }

  if (isAuthenticated) {
    props.updateTitleCustomizations({
      variables: { titleCustomizations: titleCustomizations }
    });
  }

  return <>{children}</>;
};

const RbacConcierge = compose(
  graphql(UPDATE_TITLE_CUSTOMIZATIONS, { name: "updateTitleCustomizations" }),
  graphql(SET_AUTH_STATUS, { name: "setAuthStatus" })
)(RbacConciergeWithApollo);

export const NeedsEntitlement = ({ reqs, children }) => (
  <Auth.Consumer>
    {auth => (auth.can_any(reqs) ? children : null)}
  </Auth.Consumer>
);

export const EntityTypeEntitlements = ({ entity, children }) => (
  <Auth.Consumer>
    {auth => {
      return children(auth.entity_type_permissions(entity));
    }}
  </Auth.Consumer>
);

/**
 * It verifies if the user is logged in then checks if the user has access to that page only then it allows to view the
 * page, otherwise access denied page is shown. If the user is not authenticated, he is redirected to unauthorized 
 * page
 * @param {Object} props 
 */
export const AuthenticatedPage = ({ reqs, children, location }) => (
  <Auth.Consumer>
    {auth => {
      if (auth.isAuthenticated) {
        if (auth.can_any(reqs)) {
          return children;
        } else {
          return <Denied {...{ location }} />;
        }
      } else {
        return (
          <Redirect
            to={{
              pathname: routes.UNAUTHORIZED,
              state: location
            }}
          />
        );
      }
    }}
  </Auth.Consumer>
);

/**
 * it checks the auth status of the application is set unauthorized then user's everything must be reinitialized and
 * must be redirected to unauthorized page.
 * @param {Object} props 
 */
const ProtectedPageWithApollo = props => {
  const data = props.data;
  const { reinit, isAuthenticated } = useContext(Auth);

  if (data && data.authStatus.status === "unauthorized") {
    reinit();
    return (
      <Redirect
        to={{
          pathname: routes.UNAUTHORIZED,
          hash: "expired",
          state: { from: props.location }
        }}
      />
    );
  }

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: routes.UNAUTHORIZED,
          state: { from: props.location }
        }}
      />
    );
  }

  return props.children;
};

export const ProtectedPage = compose(graphql(GET_AUTH_STATUS))(
  ProtectedPageWithApollo
);

/**This wrapper component checks if server error has occured on any page then it should be redirected to the server
 * down page.
 */
const ServerDownPageWithApollo = props => {
  const { set_login_error } = useContext(Auth);
  const { data } = props;

  if (data && data.authStatus.status === "network-failed") {
    set_login_error();
    return (
      <Redirect
        to={{
          pathname: routes.SERVER_DOWN
        }}
      />
    );
  }

  return props.children;
};

export const ServerDownPage = compose(graphql(GET_AUTH_STATUS))(
  ServerDownPageWithApollo
);

/** This is logout button used in the top navigation of the application */
export const AuthButton = withRouter(({ history }) => (
  <Auth.Consumer>
    {auth =>
      auth.isAuthenticated ? (
        <button
          type="button"
          className="btn btn-link"
          onClick={() => {
            auth.signout();
          }}
        >
          <FaSignOutAlt />
        </button>
      ) : (
        <p>You must login</p>
      )
    }
  </Auth.Consumer>
));

/**This is access denied page */
const Denied = ({ location }) => (
  <div className="access_denied">
    <h1 className="code_status">403</h1>
    <h5 className="page">Access Denied</h5>
    <h6 className="msg">
      Sorry about that, but you do not have permission to access the requested
      resource.
    </h6>
    <p className="support-msg">
      if you require any assistance please{" "}
      <a href="https://trello.com/">contact support</a>
    </p>
  </div>
);

/**
 * Server down component. It is rendered when network request fails and unable to reach server
 */
const ServerDownWithApollo = props => {
  const { data } = props;

  if (data && !isNotNULL(data.authStatus.status)) {
    return (
      <Redirect
        to={{
          pathname: routes.HOME
        }}
      />
    );
  }

  return (
    <div className="login-wrapper-black">
      <div>
        <img src={unreachableLogo} className="empty-image-login" alt=""></img>
        <div className="server-down-logo-text mt-5">
          <strong>Couldn't Connect to Application</strong>
          <div className="mt-2">
            <small>
              {" "}
              Sorry, but there may be a problem with your internet connection
            </small>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ServerDown = compose(graphql(GET_AUTH_STATUS))(
  ServerDownWithApollo
);

/**
 * This page is only because we have login from heartbeat. If user lands on pulse without the token, it is redirected
 * to heartbeat automatically to login from there. If token is present and user is authenticated, then user is 
 * redirected to main dashboard. And if user is not authenticated, then unauthorized page is rendered, which displays
 * custom error messages according to different errors. 
 * @param {*} props
 * @method Unauthorized
 * @inner
 */
export const Unauthorized = props => (
  <Auth.Consumer>
    {auth =>
      auth.error && auth.error.message === "Token is missing!" ? (
        window.location.assign(
          process.env.REACT_APP_ENV !== "development"
            ? "https://heartbeat.schellbrothers.com"
            : process.env.REACT_APP_SCHELL_LOGIN
        )
      ) : (
        <>
          {auth.isAuthenticated && (
            <Redirect
              to={{
                pathname: "/"
              }}
            />
          )}
          <div className="login-wrapper">
            <div style={{ padding: "10px" }}>
              <img src={logo} className="empty-image-login" alt=""></img>
              <div className="logo-text">
                <strong>PULSE</strong>
                <div>
                  <small> by schell brothers</small>
                </div>
              </div>
              <div>
                <p>
                  {(auth.error && auth.error.message === "jwt expired") ||
                    props.location.hash === "#expired"
                    ? "Your session is expired, please login through heartbeat: "
                    : auth.error &&
                      auth.error.message === "Invalid Token or User"
                      ? "Unauthorized user, please login through heartbeat: "
                      : "You are not logged in, please login through heartbeat: "}
                  <a
                    href={
                      process.env.REACT_APP_ENV !== "development"
                        ? "https://heartbeat.schellbrothers.com"
                        : process.env.REACT_APP_SCHELL_LOGIN
                    }
                  >
                    Click here to Login
                  </a>
                </p>
              </div>

            </div>
          </div>
        </>
      )
    }
  </Auth.Consumer>
);
