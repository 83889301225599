import gql from 'graphql-tag';

const GET_OSC = gql`
  query getAllOSCs($division_ids: [Int], $is_active: Boolean) {
    getAllOSCs(division_ids: $division_ids, is_active: $is_active) {
      id
      first_name
      last_name
      role
      secondary_role
      roles
      is_active
      division {
        id
        name
      }
    }
  }
`;

export default GET_OSC;