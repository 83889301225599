import gql from "graphql-tag";

const SET_PREVIOUS_CARD = gql`
  mutation setPreviousCard($title: String) {
    setPreviousCard(title: $title) @client {
      title
    }
  }
`;

export default SET_PREVIOUS_CARD;
