
import gql from 'graphql-tag';

const DELETE_LEAD_FOLLOW_UP = gql` 
  mutation deleteLeadFollowUp($input: LeadFollowUpDeleteInput!) {
    deleteLeadFollowUp(input: $input) {
      message
      code
    }
  }
`;

export default DELETE_LEAD_FOLLOW_UP;
