import React from 'react';
import CustomLoader from "../custom-loader";
import './custom-loader-backdrop.css';

/**
 * This component displays {@link CustomLoader} along with a backdrop which disables the background
 * @see {@link CustomLoader}
 */
const LoaderBackdrop = () => {
  return (
    <div className="loader-backdrop">
      <CustomLoader />
    </div>
  )
}
export default LoaderBackdrop