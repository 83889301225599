import React, { useEffect, useState } from "react";

/**
 * @module FadeModule 
 */

/**
 * this component is used to add animation effect while showing the tables
 * @param {object} props component props
 * @param {boolean} show Show/Hide the children 
 * @param {HTMLBodyElement} children Children to be displayed after the animation
 */
const Fade = ({ show, children }) => {
  const [render, setRender] = useState(show);

  // Similar to componentDidMount:
  useEffect(() => {
    // it is used to update the the render variable on the first call
    if (show) setRender(true);
  }, [show]);

    /**
   * This function updates the render variable
   * @function
   * @inner
   * @memberof module:FadeModule
   * @see {@link module:FadeModule~Fade}
   */
  const onAnimationEnd = () => {
    if (!show) setRender(false);
  };

  return (
    render && (
      <div
        style={{ animation: `${show ? "fadeIn" : "fadeOut"}` }}
        onAnimationEnd={onAnimationEnd}
      >
        {show && children}
      </div>
    )
  );
};

export default Fade;