
import gql from 'graphql-tag';

const ADD_PROSPECT_APPOINTMENT = gql` 
  mutation addProspectAppointment($input: ProspectAppointmentInput!) {
    addProspectAppointment(input: $input) {
      message
      code
    }
  }
`;

export default ADD_PROSPECT_APPOINTMENT;