import React from "react";
import { Form, Field, withFormik } from "formik";
import { ButtonToolbar } from "react-bootstrap";
import "react-times/css/classic/default.css";
import { callSchema, phonecallForm } from "../../utils/phonecall-form-schema";
import TextField from "../../components/fields/text-field";
import TextAreaField from "../../components/fields/text-area";
import DatePickerView from "../../components/fields/date-picker";
import TimeAreaField from "../../components/fields/time-area-field";
import SelectField from "../../components/fields/select-field";
import CustomButton from "../../components/custom-button/index";
import ErrorFocus from "../../components/error-focus/error-focus";

import './common-lead-prospect-forms.css';
import { getTimeFromDate, formatCallDuration } from "../../utils/helpers";

/**
 * This form is used to create and update an calls. Its a pretty basic form containing multiple text fields, a select field,text area field, and also date and time fields. Call type field is required , without filling it form cannot be submitted. On submission type of the form 
 * is checked and appropriate mutation is called to either create or edit calls. 
 * 
 * @param {Object} props 
 * @param {Object} props.values object containing activities info to be edited
 */
const PhoneCallForm = props => {
  const { values } = props;
  return (
    <Form className={"form-horizontal"}>
      <div className="form_TitleContainer">
        <div style={{ margin: '0 1.1rem' }}>
          <div className="form_Title">Phone Calls</div>
          <div className="form_TitleBig mt-1">
            <h2>Add New</h2>
          </div>
        </div>
      </div>
      <div className="form_body">
        <div className="form-row">
          <div className="col-md-3">
            <Field
              label="Call Type"
              name="call_type_id"
              id="call_type_id"
              required
              component={SelectField}
            >

              {props.getAllCallTypes.getAllCallTypes &&
                props.getAllCallTypes.getAllCallTypes.map(
                  (CallTypes, index) => (
                    <option key={index + 1} value={index + 1}>
                      {CallTypes.name}
                    </option>
                  )
                )}
            </Field>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-12">
            <Field
              label={values.call_type_id < 4 ? "Call Details" : "Text Details"}
              name="call_details"
              id="call_details"
              type="text"
              rows={6}
              component={TextAreaField}
            />
          </div>
        </div>
        {values.call_type_id < 3 && <div className="form-row">
          <div className="col-md-3">
            <Field
              label="Call Duration"
              name="call_duration"
              id="call_duration"
              type="text"
              component={TextField}
            />
          </div>
        </div>}
        <div className="form-row">
          <div className="ml-1" style={{ maxWidth: '110px' }}>
            <Field
              label="Date"
              name="call_datetime"
              id="call_datetime"
              required
              maxDate={new Date()}
              value={values.call_datetime}
              component={DatePickerView}
            />
          </div>
          <div className="mx-2" style={{ maxWidth: '80px' }}>
            <Field
              label="Time"
              name="time"
              id="time"
              required
              value={values.time}
              component={TimeAreaField}
            />
          </div>
        </div>
        <div className="form-row">
          <ButtonToolbar>
            <CustomButton
              color="black"
              className={`form_CancelButton`}
              onClick={props.close}
              btnValue="CANCEL"
            />
            <CustomButton
              className={`form_SubmitButton`}
              type="submit"
              disabled={props.isSubmitting}
              btnValue={props.function}
            />
          </ButtonToolbar>
        </div>
        <ErrorFocus />
      </div>
    </Form>
  );
};
function initialiseValues(data) {
  const initialValues = Object.assign({}, data);
  initialValues.call_details = data.call_details;
  initialValues.call_duration = data.call_duration && formatCallDuration(data.call_duration);
  initialValues.call_datetime = new Date(data.call_datetime);
  initialValues.call_type_id = data.call_type?.id;
  initialValues.time = getTimeFromDate(data.call_datetime)

  return initialValues;
}
function setInputs(values) {
  let time = values.time.split(":");
  let date = values.call_datetime;
  date.setHours(time[0]);
  date.setMinutes(time[1]);
  const input = {};
  input.call_details = values.call_details;
  input.call_duration = (values.call_duration && values.call_type_id < 3) ? parseInt(values.call_duration) * 60 : null;
  input.call_datetime = date.toUTCString();
  input.call_type_id = values.call_type_id;
  return input;
}

export const CreateLeadPhoneCall = withFormik({
  enableReinitialize: true,
  mapPropsToValues: props => ({ ...phonecallForm() }),
  handleSubmit: (values, { props, setSubmitting }) => {
    const input = setInputs(values);
    input.lead_id = props.id;
    props
      .addLeadPhoneCall({
        variables: {
          input: input
        }
      })
      .then(res => {
        setSubmitting(false)
        if (res.data.addLeadPhoneCall.code === 200) {
          props.onSubmit(res.data.addLeadPhoneCall.message);
        }
      });
  },
  validationSchema: callSchema,
  displayName: "new-phone-call-form"
})(PhoneCallForm);

export const UpdateLeadPhoneCall = withFormik({
  enableReinitialize: true,
  mapPropsToValues: props => {
    return initialiseValues(props.item);
  },
  handleSubmit: (values, { props, setSubmitting }) => {
    const input = setInputs(values);

    props
      .updateLeadPhoneCall({
        variables: {
          /* lead_id: props.id, */
          phone_call_id: props.item.phone_call_id,
          input: input
        }
      })
      .then(res => {
        setSubmitting(false)
        if (res.data.updateLeadPhoneCall.code === 200) {
          props.onSubmit(res.data.updateLeadPhoneCall.message);
        }
      });
  },
  validationSchema: callSchema,
  displayName: "new-phone-call-form"
})(PhoneCallForm);

export const CreateProspectPhoneCall = withFormik({
  enableReinitialize: true,
  mapPropsToValues: props => ({ ...phonecallForm() }),
  handleSubmit: (values, { props, setSubmitting }) => {
    const input = setInputs(values);
    input.prospect_id = props.id;
    props
      .addProspectPhoneCall({
        variables: {
          input: input
        }
      })
      .then(res => {
        setSubmitting(false)
        if (res.data.addProspectPhoneCall.code === 200) {
          props.onSubmit(res.data.addProspectPhoneCall.message);
        }
      });
  },
  validationSchema: callSchema,
  displayName: "new-phone-call-form"
})(PhoneCallForm);

export const UpdateProspectPhoneCall = withFormik({
  enableReinitialize: true,
  mapPropsToValues: props => {
    return initialiseValues(props.item);
  },
  handleSubmit: (values, { props, setSubmitting }) => {
    const input = setInputs(values);
    props
      .updateProspectPhoneCall({
        variables: {
          /* prospect_id: props.id, */
          phone_call_id: props.item.phone_call_id,
          input: input
        }
      })
      .then(res => {
        setSubmitting(false)
        if (res.data.updateProspectPhoneCall.code === 200) {
          props.onSubmit(res.data.updateProspectPhoneCall.message);
        }
      });
  },
  validationSchema: callSchema,
  displayName: "new-phone-call-form"
})(PhoneCallForm);

export const CreateHomeownerPhoneCall = withFormik({
  enableReinitialize: true,
  mapPropsToValues: props => ({ ...phonecallForm() }),
  handleSubmit: (values, { props, setSubmitting }) => {
    const input = setInputs(values);
    input.homeowner_id = props.id;
    props
      .addHomeownerPhoneCall({
        variables: {
          input: input
        }
      })
      .then(res => {
        setSubmitting(false)
        if (res.data.addHomeownerPhoneCall.code === 200) {
          props.onSubmit(res.data.addHomeownerPhoneCall.message);
        }
      });
  },
  validationSchema: callSchema,
  displayName: "new-phone-call-form"
})(PhoneCallForm);

export const UpdateHomeownerPhoneCall = withFormik({
  enableReinitialize: true,
  mapPropsToValues: props => {
    return initialiseValues(props.item);
  },
  handleSubmit: (values, { props, setSubmitting }) => {
    const input = setInputs(values);
    props
      .updateHomeownerPhoneCall({
        variables: {
          phone_call_id: props.item.phone_call_id,
          input: input
        }
      })
      .then(res => {
        setSubmitting(false)
        if (res.data.updateHomeownerPhoneCall.code === 200) {
          props.onSubmit(res.data.updateHomeownerPhoneCall.message);
        }
      });
  },
  validationSchema: callSchema,
  displayName: "new-phone-call-form"
})(PhoneCallForm);