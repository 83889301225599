import * as React from "react";
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";

/**
 * This component is used in {@link ActivityCard} to display difference and percentage change between current and previous value provided
 * @param {Object} props Component props
 * @param {Number} props.count Current count
 * @param {String} props.prevCount Previous count
 */
const CountComparator = ({ count, prevCount }) => {
  const change = count - prevCount;
  const percentageChange = change === 0 ? 0 : (change / prevCount) * 100;
  const isPositive = change >= 0;
  return (
    <div xs={1} className="d-flex flex-column align-items-center" id="count-cont">
      <div className="d-flex comparator" id="comparator">
        {isPositive ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
        {`${isPositive ? "+" : ""}${change} ${
          percentageChange === Infinity
            ? "(N/A)"
            : `(${percentageChange.toFixed(1)}%)`
        }`}
      </div>
    </div>
  );
};

export default CountComparator;
